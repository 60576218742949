import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

export function useUpdateFlexWalkthroughReportSubmittedTime() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchFlexWalkthroughReport();
  const flexWalkthroughReportId = useFlexWalkthroughReportId();

  return useMutation(
    () => {
      return gqlClient.mutate({
        mutation: gql`
          mutation updateFlexWalkthroughReport($flexWalkthroughReport: [UpdateFlexWalkthroughReportInput]) {
            updateFlexWalkthroughReport(flexWalkthrough: $flexWalkthroughReport) {
              count
            }
          }
        `,
        variables: {
          flexWalkthroughReport: [
            {
              flexWalkthroughReportId,
              submittedTime: new Date().toISOString(),
            },
          ],
        },
      });
    },
    {
      async onSettled() {
        await refetch();
      },
    }
  );
}
