import { Flex } from "@chakra-ui/react";
import { ExclamationIcon } from "@doorstead/components/icons/ExclamationIcon";
import { useOpenInstruction, useViewConfig } from "../../hooks/rentReady/useDisplayInstruction";
import { View } from "../../hooks/rentReady/useView";

type Props = {
  view: View;
};

export function InstructionIcon({ view }: Props) {
  const { isSupported } = useViewConfig(view);
  const openInstruction = useOpenInstruction(view);

  if (!isSupported) return null;

  return (
    <Flex justifyContent="center" alignItems="center" onClick={openInstruction}>
      <ExclamationIcon size={20} />
    </Flex>
  );
}
