import { Box, HStack, VStack } from "@chakra-ui/react";
import { TextField } from "@doorstead/components/fields";
import { KeyStorage } from "../../../../../@types/RentReady/KeyStorage";
import { useIsReadOnly } from "../../../../../hooks/rentReady/useIsReadOnly";
import { RequiredLabel } from "../../../RequiredLabel";
import { QuestionIcon } from "../QuestionIcon";
import { useOnLockboxCodeClick } from "./useOnLockboxCodeClick";

type Props = {
  value: KeyStorage["lockbox"];
  onChange: (field: keyof KeyStorage["lockbox"], value: any) => void;
};

export function LockboxCodeBox({ value, onChange }: Props) {
  const onHintClick = useOnLockboxCodeClick();
  const isReadOnly = useIsReadOnly();

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack spacing={1}>
        <RequiredLabel hasValue={value.code.trim() !== ""}>Lockbox Code</RequiredLabel>
        <QuestionIcon onClick={onHintClick} />
      </HStack>
      <Box>
        <TextField
          label=""
          id="lockbox-location-code"
          placeholder="ex: 1234"
          value={value.code}
          disabled={isReadOnly}
          onChange={({ target: { value } }) => onChange("code", value)}
        />
      </Box>
    </VStack>
  );
}
