import { EventProperties, Options } from "@segment/analytics-next";
import { useCallback, useMemo } from "react";
import { useAnalytics } from "src/contexts/analytics";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export interface ApplicantAnalytics {
  track: (eventName: string, properties?: any, options?: Options) => void;
}

export function useApplicantAnalytics(): ApplicantAnalytics {
  const analytics = useAnalytics();
  const { data } = useFetchApplicationMe();

  const applicantId = useMemo(() => {
    return data?.applicantById.applicantId;
  }, [data]);

  const track = useCallback(
    (eventName: string, properties?: EventProperties, options?: Options) => {
      analytics?.track(eventName, { ...properties, applicantId }, options);
    },
    [applicantId]
  );

  return {
    track,
  };
}
