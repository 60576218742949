import { FlexProps } from "@chakra-ui/react";
import { CalendarIcon } from "@doorstead/components";
import { useMonthlyRentDueDate } from "../../../hooks/useMonthlyRentDueDate";
import { IconBox } from "./IconBox";

type Props = FlexProps;

export function PaymentDueDateIconBox({ ...flexProps }: Props) {
  const paymentDueDate = useMonthlyRentDueDate();

  return (
    <IconBox
      {...flexProps}
      icon={<CalendarIcon size={20} color="gray.700" />}
      title={paymentDueDate}
      subTitle="Payment due date"
    />
  );
}
