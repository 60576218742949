import { Flex } from "@chakra-ui/react";
import { DoorsteadLogo } from "@doorstead/components";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useRRProjectId } from "../../../hooks/rr-project/useRRProjectId";

export function Header() {
  const inspectionReportId = useRRProjectId();
  const isMobile = useIsMobile();

  return (
    <Flex as="header" py={5} px={10} justifyContent={isMobile ? "center" : "flex-start"} alignItems="center">
      <DoorsteadLogo width={200} href={`/project/${inspectionReportId}`} />
    </Flex>
  );
}
