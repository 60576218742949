import { Box, Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { TickIcon } from "@doorstead/components/icons/TickIcon";
import { useNavigate } from "react-router-dom";
import { useLogClickEvent } from "src/hooks/useLogClickEvent";
import { getNegotiatedDateFormattedDateString } from "src/utils/getNegotiatedDateFormattedDateString";
// import { useTrackClickEvent } from "src/hooks/useTrackClickEvent";
import { useAddress } from "../../hooks/applicants/useAddress";
import { useApplicantPageView } from "../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicant } from "../../hooks/applicants/useFetchedApplicant";
import { useGoogleMapKey } from "../../hooks/useGoogleMapKey";
import { getSegmentedAddress } from "../../hooks/useSegmentedAddress";
// import { formatDate } from "../../utils/formatDate";
import { padding, width } from "../applicants/ApplicantOneSteps/config";
import { ApplicantPortalProvider } from "../applicants/ApplicantPortalProvider";

function Content() {
  useApplicantPageView("TP_LEASE_RENEGOTIATION_COMPLETE_VIEWED");
  const isMobile = useIsMobile();
  const {
    applicantId,
    doorsteadUser: { email: doorsteadUserEmail },
    applicationRequest: { property, negotiatedMoveInDate, negotiatedRent, appSubmittedAt },
  } = useFetchedApplicant();
  const googleMapKey = useGoogleMapKey();
  const address = useAddress();
  const [seg1, seg2] = getSegmentedAddress(property);
  const Layout = isMobile ? VStack : HStack;
  const navigate = useNavigate();
  const logClickEvent = useLogClickEvent();

  const handleButtonClick = () => {
    logClickEvent({
      email: doorsteadUserEmail || "",
      propertyId: property.propertyId || "",
      data: {
        applicantId,
      },
      action: "LEASE_NEGOTIATION_CONTINUE_APPLICATION_CLICKED",
      surface: "APPLICANT_PORTAL",
    });
    navigate(`/application/${applicantId}`);
  };

  return (
    <Flex justifyContent="center" w="100%">
      <Card padding={padding} width={width} maxWidth="800px">
        <Layout w="100%" alignItems="flex-start">
          <Stack direction={isMobile ? "column" : "row"} w="100%" alignItems="center" justifyContent="center" gap={10}>
            <Box w="fit-content" h="100%">
              <GoogleMapImage
                borderRadius={6}
                width={isMobile ? 322 : 240}
                height={isMobile ? 220 : 220}
                address={address}
                googleKey={googleMapKey}
              />
            </Box>
            <Flex padding={isMobile ? 0 : 5} w="100%" h="100%" alignItems="center">
              <VStack spacing={2} alignItems="flex-start" w="100%">
                <HStack>
                  <TickIcon color="teal.500" />
                  <Box fontWeight="bold" color="teal.400">
                    Your selection has been updated:
                  </Box>
                </HStack>
                <VStack spacing={0} alignItems="flex-start" fontWeight="bold" fontSize="3xl" color="gray.700">
                  <Box>{seg1}</Box>
                  <Box>{seg2}</Box>
                </VStack>
                <VStack
                  w={isMobile ? "100%" : "fit-content"}
                  spacing={0}
                  alignItems="flex-start"
                  fontWeight="bold"
                  fontSize="l"
                  color="#5A458E"
                  background="purple.200"
                  borderRadius={6}
                  p={5}
                >
                  {negotiatedMoveInDate && (
                    <Box>Lease start date: {getNegotiatedDateFormattedDateString(negotiatedMoveInDate)}</Box>
                  )}
                  <Box>Monthly rent: {moneyString(negotiatedRent, false)}</Box>
                </VStack>
                {!appSubmittedAt && (
                  <PrimaryButton isFullWidth={isMobile} onClick={handleButtonClick}>
                    Continue Application
                  </PrimaryButton>
                )}
              </VStack>
            </Flex>
          </Stack>
        </Layout>
      </Card>
    </Flex>
  );
}

export function LeaseNegotiationOneCompletePage() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
