import produce from "immer";
import { useMutation } from "react-query";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { useInspectionReportId } from "./useInspectionReportId";
import { ComponentUpdateState, useUpdateComponentCache } from "./useUpdateComponentCache";
import { useUpdateComponentToServer } from "./useUpdateComponentToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(componentState: ComponentUpdateState): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  componentState.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.Components;
    photos.push(photo as PhotoWithPrefix);
  });
  return photos;
}

function useGetUploadedComponent() {
  const upload = useUpload();

  return async (componentState: ComponentUpdateState): Promise<ComponentUpdateState> => {
    return produce(componentState, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveComponentSubPage() {
  const inspectionReportId = useInspectionReportId();
  const updateComponentCache = useUpdateComponentCache();
  const updateComponentToServer = useUpdateComponentToServer();
  const getUploadedComponent = useGetUploadedComponent();

  return useMutation(async ({ componentState }: { componentState: ComponentUpdateState }) => {
    await updateComponentCache(componentState);

    if (isOnline()) {
      const newComponentState = await getUploadedComponent(componentState);
      const isRepair = newComponentState.isRepair === true;
      const isReplace = newComponentState.isRepair === false;
      const selectedRepairOptions = isRepair ? newComponentState.types.filter((e) => e.selected).map((e) => e.id) : [];
      const selectedReplaceOptions = isReplace
        ? newComponentState.types.filter((e) => e.selected).map((e) => e.id)
        : [];
      const selectedComponentOptions = newComponentState.options.filter((e) => e.selected).map((e) => e.id);

      const {
        data: {
          updateInspectionReportComponent: { success, merged, data: returnedData },
        },
      } = await updateComponentToServer({
        componentDataId: newComponentState.id,
        notes: newComponentState.note,
        photoUrlsJson: mapPhotosToPhotoUrlsJson(newComponentState.photos) || "[]",
        selectedComponentOptions,
        selectedRepair: isRepair,
        selectedRepairOptions,
        selectedReplace: isReplace,
        selectedReplaceOptions,
        updatedDate: newComponentState.updatedDate,
      });

      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          //save back to cache
          const newCache = produce(cache, (draft) => {
            draft.inspectionReport.data?.forEach((areaCache) => {
              areaCache?.itemsData?.forEach((itemCache) => {
                itemCache.componentsData?.forEach((componentCache) => {
                  if (componentCache && componentCache.componentDataId === componentState.id) {
                    componentCache.componentNotes = returnedData.notes;
                    componentCache.componentSelectedComponentOptions = returnedData.selectedComponentOptions || [];
                    componentCache.componentSelectedRepair = returnedData.selectedRepair || false;
                    componentCache.componentSelectedRepairOptions = returnedData.selectedRepairOptions || [];
                    componentCache.componentSelectedReplace = returnedData.selectedReplace || false;
                    componentCache.componentSelectedReplaceOptions = returnedData.selectedReplaceOptions || [];
                    componentCache.componentPhotoUrlsJson = returnedData.photoUrlsJson;
                    componentCache.componentDataUpdatedAt = returnedData.componentDataUpdatedAt;
                  }
                });
              });
            });
          });

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, newCache);
        }
      }
    }
  });
}
