import { Box } from "@chakra-ui/react";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export function ArrowOpenIcon({ isOpen, onClick }: Props) {
  return (
    <Box as="button" onClick={onClick}>
      <ArrowIcon color="gray.700" size={2} direction={isOpen ? Direction.Top : Direction.Bottom} />
    </Box>
  );
}
