import { StackProps } from "@chakra-ui/react";
import { OutlineButton, useIsMobile } from "@doorstead/components";
import React from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useRemovePaymentMethod } from "src/hooks/useRemovePaymentMethod";
import { useCreateACHPaymentMethodFromPlaid } from "../../hooks/useCreateACHPaymentMethodFromPlaid";
import { useCreatePlaidLinkToken } from "../../hooks/useCreatePlaidLinkToken";
import { useFetchedMe } from "../../hooks/useFetchedMe";
import { useOpenPlaidTrustWindow } from "../../hooks/useOpenPlaidTrustWindow";
import { useRefetchPaymentMethods } from "../../hooks/useRefetchPaymentMethods";

export type Props = StackProps & {
  paymentMethodId: string;
  onDone: () => void;
};

export function VerifyACHButton({ paymentMethodId, onDone }: Props) {
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = React.useState(false);
  const { refetchPaymentMethods } = useRefetchPaymentMethods();
  const me = useFetchedMe();
  const { createPlaidToken } = useCreatePlaidLinkToken();
  const openPlaidTrustWindow = useOpenPlaidTrustWindow();
  const createACHPaymentMethodFromPlaid = useCreateACHPaymentMethodFromPlaid();
  const analytics = useGroupAnalytics();
  const { removePaymentMethod } = useRemovePaymentMethod();

  return (
    <OutlineButton
      size="sm"
      disabled={isLoading}
      isLoading={isLoading}
      onClick={async () => {
        try {
          setIsLoading(true);
          analytics?.track("TP_VERIFY_PAYMENT_METHOD_STARTED");
          const plaidLinkToken = await createPlaidToken(me.doorsteadUserId);
          const { metadata, publicToken } = await openPlaidTrustWindow(plaidLinkToken.linkToken, () => {
            setIsLoading(false);
          });
          await createACHPaymentMethodFromPlaid({ publicToken, accountId: metadata.accounts[0].id });
          onDone();
        } catch (e: any) {
          console.error(e);
          analytics?.track("TP_VERIFY_PAYMENT_METHOD_FAILED");

          if (e.err && e.err.error_code === "TOO_MANY_VERIFICATION_ATTEMPTS") {
            // Remove the payment method if the user has too many verification attempts
            await removePaymentMethod(paymentMethodId);
          }
        } finally {
          await refetchPaymentMethods();
          setIsLoading(false);
        }
      }}
      isFullWidth={isMobile}
    >
      Verify
    </OutlineButton>
  );
}
