import { Item } from "../../@types/RentReady/Item";
import { isItemCabinets } from "../../utils/rentReady/isItemCabinets";
import { isItemCosmeticDefectPaint } from "../../utils/rentReady/isItemCosmeticDefectPaint";
import { useIsAreaPainting } from "./useIsAreaPainting";

export function useIsFullPaintAreaItem(item: Item) {
  const isCosmeticDefectPaint = isItemCosmeticDefectPaint(item);
  const isCabinets = isItemCabinets(item);
  const isAreaPainting = useIsAreaPainting();

  return isAreaPainting && !isCabinets && isCosmeticDefectPaint;
}
