import { State } from "../../components/rent-ready/views/ComponentEditView/ComponentEditView";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapComponentStateFromRRGraphQLResultCache } from "./useMapComponentStateFromRRGraphQLResultCache";

export type ComponentUpdateState = State & { id: string; isRepair: boolean | null };

export function useUpdateComponentCache() {
  const inspectionReportId = useInspectionReportId();
  const mapComponentStateFromRRGraphQLResultCache = useMapComponentStateFromRRGraphQLResultCache();

  return async (state: ComponentUpdateState) => {
    const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
    if (graphQLCacheResult !== null) {
      const result = mapComponentStateFromRRGraphQLResultCache(graphQLCacheResult, state);
      await writeRRGraphQLResult(inspectionReportId, result);
    }
  };
}
