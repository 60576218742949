import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AddCotenantsAndGuarantorsPage } from "src/components/applicants/AddCotenantsAndGuarantorsPage";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { useIsPrimaryApplicant } from "src/hooks/applicants/useIsPrimaryApplicant";
import { AddCotenantsAndGuarantorsPageV2 } from "../../components/applicants/AddCotenantsAndGuarantorsPageV2";
import {
  ApplicantTypeV2,
  NewApplicantWithIdV2,
} from "../../components/applicants/AddCotenantsAndGuarantorsPageV2/NewApplicantsCardV2/typings";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";
import { useFetchApplicationMeApplicants } from "../../hooks/applicants/useFetchApplicationMeApplicants";
import { useFetchedApplicant } from "../../hooks/applicants/useFetchedApplicant";
import { useIsCoTenantsInviteV2Feature } from "../../hooks/applicants/useIsCoTenantsInviteV2Feature";
import { useLogConversionEvent } from "../../hooks/useLogConversionEvent";
import { useTrackImpressionEvent } from "../../hooks/useTrackImpressionEvent";

function Content() {
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const applicant = useFetchedApplicant();
  const applicantId = useApplicantId();
  const navigate = useNavigate();
  const { isLoading, data } = useFetchApplicationMeApplicants();
  const isCoTenantsInviteV2Feature = useIsCoTenantsInviteV2Feature();
  const email = applicant.doorsteadUser.email || "";
  const propertyId = applicant.applicationRequest.property?.propertyId || "";
  const surface = "APPLICANT_PORTAL";
  const logConversionEvent = useLogConversionEvent();
  useTrackImpressionEvent({
    data: {
      applicantId,
    },
    email,
    propertyId,
    surface,
    action: "TP_APPLICANT_INVITE_COTENANT_STAND_ALONE_VIEWED",
  });

  useEffect(() => {
    if (!isPrimaryApplicant) {
      navigate(`/application/${applicantId}`);
    }
  }, [isPrimaryApplicant]);

  if (isLoading || !isPrimaryApplicant) {
    return null;
  } else {
    if (isCoTenantsInviteV2Feature) {
      const applicants: NewApplicantWithIdV2[] = (data?.applicantById.applicationRequest?.applicants?.edges || [])
        .map((e) => e.node)
        .filter((e) => e.type !== null)
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .map(({ doorsteadUser, applicantId: id, type, createdAt }) => {
          return {
            id,
            type: type!,
            email: doorsteadUser?.email || "",
            firstName: doorsteadUser?.firstName || "",
            lastName: doorsteadUser?.lastName || "",
            disabled: true,
            removable: false,
            createdAt,
          };
        });
      const initCoTenants: NewApplicantWithIdV2[] = applicants
        .filter((a) => a.type === ApplicantTypeV2.TENANT)
        .map((e) => ({
          ...e,
          createdAt: `${e.createdAt}Z`,
        }));
      const initGuarantors: NewApplicantWithIdV2[] = applicants
        .filter((a) => a.type === ApplicantTypeV2.GUARANTOR)
        .map((e) => ({
          ...e,
          createdAt: `${e.createdAt}Z`,
        }));
      return (
        <AddCotenantsAndGuarantorsPageV2
          initCoTenants={initCoTenants}
          initGuarantors={initGuarantors}
          onSuccess={({ coTenants, guarantors }) => {
            try {
              logConversionEvent({
                action: "TP_APPLICANT_INVITE_COTENANT_STAND_ALONE_SUBMITTED",
                email,
                propertyId,
                surface,
                data: {
                  applicantId: applicant.applicantId,
                  coTenants,
                  guarantors,
                },
              });
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }
            window.location.href = `/application/${applicantId}?invited=true`;
          }}
        />
      );
    } else {
      return <AddCotenantsAndGuarantorsPage />;
    }
  }
}

export default function ApplicationInviteRoute() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
