import { useEffect } from "react";

const useIntersectionObserver = (
  rootMargin: string,
  threshold: number,
  prefix: string,
  onIntersect: (entry: IntersectionObserverEntry) => void
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect(entry);
          }
        });
      },
      {
        rootMargin,
        threshold,
      }
    );

    const elements = document.querySelectorAll("[id^=\\/]");
    elements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [rootMargin, threshold, prefix, onIntersect]);
};

export default useIntersectionObserver;
