import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type Props = {
  value: boolean;
  setValue: (value: boolean) => void;
};

const context = createContext<Props>({
  value: false,
  setValue() {
    console.log("");
  },
});

export function Provider({ children, value: initialValue }: { children: ReactNode; value: boolean }) {
  const [value, setValue] = useState(false);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <context.Provider
      value={{
        value,
        setValue,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useIsEditable() {
  return useContext(context).value;
}
