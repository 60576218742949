import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useStartApplicant() {
  const applicantId = useApplicantId();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    () =>
      gqlClient.mutate({
        mutation: gql`
          mutation ($applicantId: UUID) {
            startApplicant(applicantId: $applicantId) {
              result
            }
          }
        `,
        variables: {
          applicantId,
        },
      }),
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
