import { VStack } from "@chakra-ui/react";
import { Spinner } from "@doorstead/components/Spinner";
import HelloSign from "hellosign-embedded";
import { useState } from "react";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useLogConversionEvent } from "src/hooks/useLogConversionEvent";
import { useLogImpressionEvent } from "src/hooks/useLogImpressionEvent";
import { useRenderOnce } from "src/hooks/useRenderOnce";

const clientId = "3e592e506ceb495fbfbf7f09a2a0f61c";

interface LoadHelloSignToContentProps {
  container: HTMLElement;
  embeddedUrl: string;
  testMode: boolean;
  setIsLoading: (loading: boolean) => void;
  onReady: (data: HelloSign.HelloSign.ReadyEventPayload) => void;
  onError: (data: HelloSign.HelloSign.ErrorEventPyload) => void;
  onSign: (data: HelloSign.HelloSign.SignEventPayload) => void;
}

function LoadHelloSignToContent({
  container,
  embeddedUrl,
  testMode,
  setIsLoading,
  onReady,
  onError,
  onSign,
}: LoadHelloSignToContentProps) {
  useRenderOnce(async () => {
    setIsLoading(true);
    const client = new HelloSign({
      clientId,
    });

    client.open(embeddedUrl, {
      testMode,
      allowCancel: false,
      debug: testMode,
      skipDomainVerification: true,
      locale: HelloSign.locales.EN_US,
      container,
    });

    client.on("ready", onReady);
    client.on("sign", onSign);
    client.on("error", (data) => {
      console.error(data);
      console.error(`Something went wrong! Please try again. Error code: ${data?.code || ""}`, data?.signatureId || "");
      onError(data);
    });
  });
  return null;
}

interface HelloSignBoxProps {
  embeddedUrl: string;
  testMode: boolean;
  onSigned: (data: { signatureId: string }) => void;
}

export function HelloSignBox({ embeddedUrl, testMode, onSigned }: HelloSignBoxProps) {
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const applicant = useFetchedApplicant();
  const logImpressionEvent = useLogImpressionEvent();
  const logConversionEvent = useLogConversionEvent();

  return (
    <VStack w="100%" alignItems="center">
      <div style={{ width: "100%", height: "800px", display: isLoading ? "none" : "block" }} ref={setContainer} />
      {isLoading && <Spinner />}
      {!isLoading && errorMessage && <div>{errorMessage}</div>}
      {container && (
        <LoadHelloSignToContent
          setIsLoading={setIsLoading}
          testMode={testMode}
          embeddedUrl={embeddedUrl}
          container={container}
          onSign={(data) => {
            if (data?.signatureId) {
              logConversionEvent({
                email: applicant.doorsteadUser.email || "",
                propertyId: applicant.applicationRequest.property?.propertyId || "",
                action: "TP_EMBEDDED_LEASE_SIGNED_RDS",
                surface: "APPLICATION_PORTAL",
                data: {
                  applicantId: applicant.applicantId,
                  applicationRequestId: applicant.applicationRequest.applicationRequestId,
                },
              });
              onSigned({ signatureId: data.signatureId });
            }
          }}
          onReady={() => {
            setIsLoading(false);
            logImpressionEvent({
              email: applicant.doorsteadUser.email,
              propertyId: applicant.applicationRequest.property?.propertyId,
              data: {
                applicantId: applicant.applicantId,
                applicationRequestId: applicant.applicationRequest.applicationRequestId,
                applicationRequest: applicant.applicationRequest,
              },
              action: "TP_EMBEDDED_LEASE_SIGNING_IFRAME_VIEWED_RDS",
              surface: "APPLICATION_PORTAL",
              userAgent: window.navigator.userAgent,
            });
          }}
          onError={(error) => {
            console.error(error);
            setErrorMessage("Something went wrong! Please try again. Error: " + error);
            setIsLoading(false);
          }}
        />
      )}
    </VStack>
  );
}
