import { Komponent } from "../../@types/RentReady/Komponent";
import { useComponentId } from "./useComponentId";
import { useItem } from "./useItem";

export function useComponent() {
  const item = useItem();
  const { value } = useComponentId();

  if (item === null || value.componentId === null) return null;

  return item.components.find((e: Komponent) => e.id === value.componentId) || null;
}
