import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { usePaymentMethods } from "./usePaymentMethods";
import { useRefetchPaymentMethods } from "./useRefetchPaymentMethods";
import { useSuccess } from "./useSuccess";

const MUTATION = gql`
  mutation ($paymentMethods: [UpdatePaymentMethod]) {
    updatePaymentMethods(paymentMethods: $paymentMethods) {
      count
    }
  }
`;

export function useSetPaymentMethodDefault() {
  const { gqlClient } = useAuth();
  const success = useSuccess();
  const currentPaymentMethods = usePaymentMethods();
  const { refetchPaymentMethods, isLoading: isPaymentMethodsLoading } = useRefetchPaymentMethods();
  const mutation = useMutation(
    async (paymentMethodId: string) => {
      const paymentMethods = currentPaymentMethods.map(({ paymentMethodId: id }) => ({
        paymentMethodId: id,
        isDefault: id === paymentMethodId,
      }));

      await gqlClient.mutate({
        mutation: MUTATION,
        variables: { paymentMethods },
      });
      return refetchPaymentMethods();
    },
    {
      onSuccess() {
        success({ title: "Payment method set as default" });
      },
    }
  );

  return { setPaymentMethodDefault: mutation.mutate, loading: mutation.isLoading || isPaymentMethodsLoading };
}
