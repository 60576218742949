import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicantLeaseNegotiationStartDatePage } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage";
import { ApplicantLeaseStartDatePage } from "src/components/applicants/ApplicantLeaseStartDatePage";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { useIsNegotiated } from "src/hooks/applicants/useIsNegotiated";
import { useIsPrimaryApplicant } from "src/hooks/applicants/useIsPrimaryApplicant";
import { useLeaseNegotiationEnabled } from "src/hooks/applicants/useLeaseNegotiationEnabled";
import { useRequireNegotiation } from "src/hooks/applicants/useRequireNegotiation";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";

function Content() {
  const applicantId = useApplicantId();
  const requireLeaseNegotiation = useRequireNegotiation();
  const isNegotiated = useIsNegotiated();
  const leaseNegotiationEnabled = useLeaseNegotiationEnabled();
  const showLeaseNegotiation = requireLeaseNegotiation && leaseNegotiationEnabled;
  const navigate = useNavigate();
  const isPrimaryApplicant = useIsPrimaryApplicant();

  useEffect(() => {
    if (isNegotiated || !isPrimaryApplicant) {
      navigate(`/application/${applicantId}`);
    }
  }, [isNegotiated, isPrimaryApplicant]);

  if (showLeaseNegotiation) {
    return <ApplicantLeaseNegotiationStartDatePage />;
  } else {
    return <ApplicantLeaseStartDatePage />;
  }
}

export default function ApplicantLeaseStartDateRoute() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
