import { Box, Flex, Image, Spacer, Text, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { useNavigate } from "react-router-dom";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { getLabel } from "../../../@types/AutoPay/getLabel";
import { AutoPayDate } from "../../../@types/AutoPay/typings";
import { useAutoPay } from "../../../hooks/useAutoPay";

function BoldText({ children }: { children: React.ReactNode }) {
  return (
    <Box as="span" fontWeight="bold">
      {children}
    </Box>
  );
}

export function DesktopAutoPayBox() {
  const autoPay = useAutoPay();
  const navigate = useNavigate();
  const analytics = useGroupAnalytics();

  if (autoPay === null) return null;

  return (
    <Flex
      justifyContent="space-between"
      w="100%"
      backgroundImage="/resources/bg.svg"
      borderRadius={20}
      paddingX={10}
      paddingY={8}
      pos="relative"
    >
      <VStack w="70%" alignItems="flex-start" spacing={3}>
        <Text color="gray.700" fontSize="xl" fontWeight="bold">
          You’re all set! Autopay has got you covered.
        </Text>
        <Text mb={1}>
          You’ve set a payment for
          <BoldText>
            {" "}
            {autoPay.date !== AutoPayDate.LAST ? "the" : ""} {getLabel(autoPay.date)}
          </BoldText>
          .
        </Text>
        <Spacer />
        <OutlineButton
          size="sm"
          onClick={() => {
            analytics?.track("TP_HP_MANAGE_AUTOPAY_CLICKED");
            navigate("/autopay");
          }}
        >
          Manage
        </OutlineButton>
      </VStack>
      <Flex justifyContent="center" alignItems="center" w="30%">
        <Image src="/resources/autopay.svg" />
      </Flex>
    </Flex>
  );
}
