import { DollarField, Props as DollarFieldProps } from "@doorstead/components/fields/DollarField";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getClampedValue } from "./getClampedValue";

export type Props = Omit<DollarFieldProps, "value" | "onChange" | "isValid"> & {
  defaultValue: number | null;
  min: number;
  max: number;
  getIsValid?: (value: number) => boolean;
  onSyncToServer: (value: number) => void;
  hasDollarSign?: boolean;
  validateOnBlur?: boolean;
  validator?: (value: number) => boolean;
};

export function ApplicantDollarField({
  defaultValue,
  min,
  max,
  id,
  onSyncToServer,
  getIsValid,
  hasDollarSign = true,
  validateOnBlur = true,
  validator,
  ...textProps
}: Props) {
  const [value, setValue] = useState<number>(defaultValue || 0);
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, 1500), [onSyncToServer]);
  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  const [isValid, setIsValid] = useState<boolean>(true);

  return (
    <DollarField
      {...textProps}
      {...Object.assign(textProps, getIsValid ? { isValid: getIsValid(value) } : { isValid: true })}
      id={id}
      value={value}
      hasDollarSign={hasDollarSign}
      onChange={(value) => {
        setValue(getClampedValue(value, min, max));
        debouncedSyncToServer(value);
      }}
      isValid={isValid}
      onBlur={() => {
        // run validation on blur if set
        if (validateOnBlur && validator) {
          const result = validator(value);
          setIsValid(!!result);
        }
      }}
    />
  );
}
