import { gql } from "@apollo/client";
import { useQuery } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useRRProjectId } from "./useRRProjectId";

const queryKey = "useFetchRRProject.ts";

const query = gql`
  query getRepairs($rrProjectId: UUID!) {
    rrProject(rrProjectId: $rrProjectId, ignoreArchivedRepairs: true) {
      rrProjectId
      rrProjectName
      type
      evaluationDate
      templateId
      status
      surveyCreatedAt
      property {
        fullAddress
        propertyId
        propertyHash
        owner {
          doorsteadUser {
            email
            firstName
            lastName
          }
        }
      }
      ownerRepairReports {
        edges {
          node {
            ownerRepairReportId
            repairIds
          }
        }
      }
      repairs {
        edges {
          node {
            repairId
            stage
            section
            item
            description
            type
            isGrouped
            isArchived
            photoUrlsJson
            responsibleParty
            repairRequirement
            vendorTags
            ownerDecision
            ownerNotes
            tenantNotes
            estimateNotes
            chosenVendorRepairId
            chosenVendorRepair {
              vendorRepairId
              cannotEstimate
              lowEstimateCostCents
              highEstimateCostCents
              estimateApproved
            }
            repairStatus
            vendorRepairs {
              edges {
                node {
                  lowEstimateCostCents
                  highEstimateCostCents
                  jobStatus
                }
              }
            }
            repairQas {
              edges {
                node {
                  qaPhotoUrlsJson
                }
              }
            }
          }
        }
      }
    }
  }
`;

type PropertyNode = {
  fullAddress: string | null;
  propertyId: string | null;
  propertyHash: string | null;
  owner: {
    doorsteadUser: {
      email: string | null;
      firstName: string | null;
      lastName: string | null;
    };
  };
};

type OwnerRepairReportNode = {
  ownerRepairReportId: string;
  repairIds: string[] | null;
};

type ChosenVendorRepairNode = {
  vendorRepairId: string;
  cannotEstimate: boolean | null;
  lowEstimateCostCents: number | null;
  highEstimateCostCents: number | null;
  estimateApproved: boolean | null;
};

type VendorRepairNode = {
  lowEstimateCostCents: number | null;
  highEstimateCostCents: number | null;
  jobStatus: string | null;
};

export enum RepairRequirement {
  OwnerRequired = "Owner-Required",
  OwnerRecommended = "Owner-Recommended",
  OwnerOptional = "Owner-Optional",
  TenantResponsible = "Tenant-Responsible",
  TenantOwnerSplit = "Tenant/Owner Split",
}

export enum OwnerDecision {
  DIY = "DIY",
  Yes = "Yes",
  No = "No",
}

export function getOwnerDecisionLabel(ownerDecision: OwnerDecision): string {
  switch (ownerDecision) {
    case OwnerDecision.DIY:
      return "DIY";
    case OwnerDecision.Yes:
      return "Get Estimates";
    case OwnerDecision.No:
      return "Deny Repair";
  }
}

export enum RepairStage {
  NoRepairNeeded = "No Repair Needed",
  PendingOwnerApproval = "Pending Owner Approval",
  OwnerRejectedRepair = "Owner Rejected Repair",
  // AutomaticallyApproved: "Automatically Approved",
  PendingVendorEstimates = "Pending Vendor Estimates",
  PendingEstimateApproval = "Pending Estimate Approval",
  OwnerEstimatesApproved = "Owner Estimates Approved",
  OwnerEstimatesRejected = "Owner Estimates Rejected",
  VendorDispatched = "Vendor Dispatched",
  WorkInProgress = "Work In Progress",
  WorkComplete = "Work Complete",
  PendingQA = "Pending QA",
  Completed = "Completed",
}

export enum RepairType {
  CLEAN = "Clean",
  DETAIL = "Detail",
  PAINT = "Paint",
  REMOVE = "Remove",
  REPAIR = "Repair",
  REPLACE = "Replace",
  APPLIANCE = "Appliance",
  CARPET_CLEAN = "Carpet Clean",
  CAULK = "Caulk",
  DOOR = "Door",
  DOORSTEP = "Doorstop",
  ELECTRICAL = "Electrical",
  EVALUATE = "Evaluate",
  HVAC = "HVAC",
  INSTALL = "Install",
  LANDSCAPING = "Landscaping",
  LIGHTBULB = "Lightbulb",
  MOLD = "Mold",
  NOTED = "Noted",
  OUTLETS_AND_SWITCHES = "Outlets & Switches",
  PESTS = "Pests",
  PLUMBING = "Plumbing",
  POWER_WASH = "Power Wash",
  WINDOW_AND_SCREENS = "Window & Screens",
  MOVE_IN_CLEANING = "Move-In Cleaning",
  OTHER = "Other",
}

export type RepairNode = {
  chosenVendorRepair: ChosenVendorRepairNode | null;
  chosenVendorRepairId: string | null;
  description: string | null;
  estimateNotes: string | null;
  isArchived: boolean | null;
  isGrouped: boolean | null;
  item: string | null;
  ownerDecision: OwnerDecision | null;
  ownerNotes: string | null;
  photoUrlsJson: string | null;
  repairId: string;
  repairQas: { edges: { node: { qaPhotoUrlsJson: string | null } }[] };
  repairRequirement: RepairRequirement | null;
  repairStatus: string | null;
  responsibleParty: string | null;
  section: string | null;
  stage: RepairStage | null;
  tenantNotes: string | null;
  type: RepairType | null;
  vendorRepairs: {
    edges: {
      node: VendorRepairNode;
    }[];
  };
  vendorTags: string | null;
};

export enum RRProjectNodeStatus {
  Start = "Start",
  Guideline = "Guideline",
  RepairList = "RepairList",
}

export enum ProjectNodeType {
  MoveOut = "Move Out",
  TurnOver = "Turn Over",
}

type RRProjectNode = {
  rrProjectId: string;
  rrProjectName: string;
  status: RRProjectNodeStatus | null;
  type: ProjectNodeType | null;
  evaluationDate: string;
  templateId: string;
  surveyCreatedAt: string | null;
  ownerRepairReports: { edges: { node: OwnerRepairReportNode }[] } | null;
  property: PropertyNode | null;
  repairs: { edges: { node: RepairNode }[] } | null;
};

type Result = {
  rrProject: RRProjectNode | null;
};

export function useFetchRRProject() {
  const rrProjectId = useRRProjectId();
  const { gqlClient } = useAuth();
  return useQuery<unknown, unknown, Result>(queryKey, async () => {
    return (await gqlClient.query({ query, variables: { rrProjectId } }).then((e) => e.data)) as Result;
  });
}
