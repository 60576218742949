import { isNullOrEmptyString } from "src/utils/isNullOrEmptyString";
import { ApplicantOccupantNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";
import { ApplicantTextField } from "../../../../ApplicantTextField";

export type Props = {
  value: ApplicantOccupantNode;
  onChange: (value: {
    applicantOccupantId: ApplicantOccupantNode["applicantOccupantId"];
    fullName: ApplicantOccupantNode["fullName"];
  }) => void;
};

export function Content({ value: { applicantOccupantId, fullName }, onChange }: Props) {
  const disabled = !useIsApplicantEditable();

  return (
    <ApplicantTextField
      id="name"
      label="Full name"
      disabled={disabled}
      defaultValue={fullName}
      status={getTextRequiredStatus(fullName)}
      validator={(fullName) => !isNullOrEmptyString(fullName)}
      onSyncToServer={(fullName) =>
        onChange({
          applicantOccupantId,
          fullName,
        })
      }
    />
  );
}
