import { useItemId } from "./useItemId";
import { useItems } from "./useItems";

export function useItem() {
  const { itemId } = useItemId();
  const items = useItems();
  if (items.length === 0) return null;

  return items.find((e) => e.id === itemId) || null;
}
