import { useEffect } from "react";
import { useNextStep } from "src/contexts/StepContext/useNextStep";
import { useIsHoldingDepositPaid } from "src/hooks/useIsHoldingDepositPaid";
import { PayHoldingDepositCard } from "./PayHoldingDepositCard";

export function PayHoldingDepositStep() {
  const isHoldingDepositPaid = useIsHoldingDepositPaid();
  const { nextStep, isLoading } = useNextStep();

  useEffect(() => {
    if (isHoldingDepositPaid) {
      nextStep();
    }
  }, [isHoldingDepositPaid]);

  if (isLoading) {
    return null;
  }

  return <PayHoldingDepositCard />;
}
