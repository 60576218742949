import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = BoxProps & {
  hasValue: boolean;
  children: ReactNode;
};

export function RequiredLabel({ hasValue, children, ...boxProps }: Props) {
  const isError = !hasValue;

  return (
    <Box {...boxProps} color={isError ? "red.500" : "gray.900"} fontSize="14px" fontWeight="bold">
      {children}*
    </Box>
  );
}
