export function fileToDataUrl(file: File): Promise<string | null> {
  const reader = new FileReader();

  return new Promise<string | null>((resolve, reject) => {
    reader.onload = () => {
      const dataUrl = reader.result as string;
      resolve(dataUrl);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
}
