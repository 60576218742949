import produce, { enableMapSet } from "immer";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

enableMapSet();

//key is `group-fileName`
type Key = string;
type Value = Map<Key, number>;

type Props = {
  value: Value;
  setValue: Dispatch<SetStateAction<Value>>;
};

const context = createContext<Props>({
  value: new Map(),
  setValue(value) {
    console.log(value);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [value, setValue] = useState<Value>(new Map());

  return (
    <context.Provider
      value={{
        value,
        setValue,
      }}
    >
      {children}
    </context.Provider>
  );
}

function getGroupKey({ group, fileName }: { group: string; fileName: string }): string {
  return `${group}-${fileName}`;
}

export function useFileGroupProgress({ group }: { group: string }): { progress: number; isUploading: boolean } {
  const { value } = useContext(context);
  const prefix = `${group}-`;

  const arr: number[] = [];
  value.forEach((progress, key) => {
    if (key.startsWith(prefix)) {
      arr.push(progress);
    }
  });
  if (arr.length === 0)
    return {
      isUploading: false,
      progress: 0,
    };

  const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const progress = sum / arr.length;
  return {
    isUploading: progress < 100,
    progress,
  };
}

export function useOnFileProgress() {
  const { setValue } = useContext(context);

  return ({ group, fileName, progress }: { group: string; fileName: string; progress: number }) => {
    setValue((value: Value) => {
      return produce(value, (draft) => {
        const key: Key = getGroupKey({ group, fileName });
        draft.set(key, progress);

        if (progress === 100) {
          draft.delete(key);
        }
      });
    });
  };
}

export function useOnFileUploadFailed() {
  const { value, setValue } = useContext(context);

  return ({ group, fileName }: { group: string; fileName: string }) => {
    setValue(
      produce(value, (draft) => {
        const key: Key = getGroupKey({ group, fileName });
        draft.delete(key);
      })
    );
  };
}
