import { GrayText } from "@doorstead/components";
import { AddPaymentMethodBox } from "src/components/AddPaymentMethodBox";
import { useAddress } from "../../../../../hooks/useAddress";

export function AddFirstPaymentMethodCard() {
  const address = useAddress();

  return (
    <>
      <GrayText mt={3}>{address}</GrayText>
      <AddPaymentMethodBox />
    </>
  );
}
