export enum OptionType {
  EARLY = "early",
  NORMAL = "",
  LATER = "later",
}

export type Option = {
  id: string;
  startDate: string;
  endDate: string;
  price: number;
  listingPrice: number;
};

export type Props = Option & {
  selected: boolean;
  onClick: () => void;
  onSelect: (date: string, price: number) => void;
};
