import { Box, Flex, VStack } from "@chakra-ui/react";
import { Repair } from "../../../@types/RepairList/Repair";
import { Photos } from "../Photos";
import { RepairChip } from "../RepairChip";

export function DIYRepairCard({ uiTitle, repairRequirement, description, photos }: Repair) {
  return (
    <VStack w="100%" color="gray.700">
      <Flex justifyContent="space-between" w="100%">
        <Box fontWeight="bold">{uiTitle}</Box>
        <RepairChip repairRequirement={repairRequirement} />
      </Flex>
      <Flex justifyContent="flex-start" w="100%">
        {description}
      </Flex>
      <Flex w="100%" justifyContent="flex-start" gap={3} overflowX="scroll">
        <Photos urls={photos} mostShown={4} />
      </Flex>
    </VStack>
  );
}
