import { Tooltip } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function CardTransactionFeeTooltip({ children }: PropsWithChildren) {
  return (
    <Tooltip label="This is the interchange processing fee charged by the credit card company ($0.30 + 2.9%). You can avoid this fee by paying with ACH.">
      {children}
    </Tooltip>
  );
}
