import { Box, Flex, VStack } from "@chakra-ui/react";
import { DoorsteadLogo } from "@doorstead/components";
import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { LinkProvider } from "@doorstead/components/hooks/useReactRouterLink";
import { Link, LinkProps } from "react-router-dom";
import { db } from "../../databases/RentReadyDatabase";
import { useFetchInspectionReport } from "../../hooks/rentReady/useFetchInspectionReport";
import { useInspectionReportCache } from "../../hooks/rentReady/useInspectionReportCache";
import { useInspectionReportId } from "../../hooks/rentReady/useInspectionReportId";
import { useIsOnline } from "../../hooks/useIsOnline";

export function clearCache() {
  if (
    window.confirm(
      "Are you certain that you want to clear all of your data? This action will result in the removal of any non-uploaded photos you have."
    )
  ) {
    db.rr_graphql_result.clear();
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName).then(console.log);
        });
      });
    }
    window.location.reload();
  }
}

export default function RentReadyDebugPage() {
  const isOnline = useIsOnline();
  const { data: inspectionReportServer } = useFetchInspectionReport();
  const inspectionReportCache = useInspectionReportCache();
  const inspectionReportId = useInspectionReportId();

  return (
    <LinkProvider
      value={{
        Link(linkProps: any) {
          return <Link {...(linkProps as LinkProps)} />;
        },
      }}
    >
      <DoorsteadProvider>
        <VStack alignItems="flex-start" w="100%" p={5}>
          <Flex w="100%" justifyContent="center" alignItems="center">
            <DoorsteadLogo width={200} href={""} />
          </Flex>
          <Box>Inspection report Id: {inspectionReportId}</Box>
          <Box>Online status: {isOnline ? "YES" : "NO"}</Box>
          <Box>Now: {new Date().toISOString()}</Box>
          <Box>Local cache version: {inspectionReportCache?.updatedAt}</Box>
          <Box>Server cache version: {inspectionReportServer?.inspectionReport.updatedAt}</Box>
          <Flex w="100%" gap={5}>
            <OutlineButton
              isFullWidth
              onClick={() => {
                window.location.href = `/rent-ready/${inspectionReportId}`;
              }}
            >
              Go back
            </OutlineButton>
            <PrimaryButton isFullWidth onClick={() => clearCache()}>
              Clear Cache
            </PrimaryButton>
          </Flex>
        </VStack>
      </DoorsteadProvider>
    </LinkProvider>
  );
}
