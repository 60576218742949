import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import produce from "immer";
import React from "react";
import {
  CleanSelectedOption,
  ThroughoutPropertyCleaning,
} from "../../../../@types/RentReady/ThroughoutPropertyCleaning";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { RequiredLabel } from "../../RequiredLabel";

type Props = {
  state: ThroughoutPropertyCleaning;
  setState: React.Dispatch<React.SetStateAction<ThroughoutPropertyCleaning>>;
};

export function CleaningSelects({ state, setState }: Props) {
  const isReadOnly = useIsReadOnly();

  return (
    <Box w="100%">
      {state.cleanIsNeeded && (
        <>
          <RequiredLabel hasValue={state.cleanSelectedOptions.length !== 0} mb={2}>
            Select, type of cleaning needed
          </RequiredLabel>
          <Divider />
          <VStack alignItems="flex-start" w="100%">
            <HStack h={10}>
              <Checkbox
                id="ligntClean"
                value={state.cleanSelectedOptions.includes(CleanSelectedOption.Light)}
                disabled={isReadOnly}
                onChange={(value) => {
                  if (value) {
                    setState((state) => {
                      return produce(state, (draft) => {
                        draft.cleanSelectedOptions = [CleanSelectedOption.Light];
                      });
                    });
                  } else {
                    setState((state) => {
                      return produce(state, (draft) => {
                        draft.cleanSelectedOptions = [];
                      });
                    });
                  }
                }}
              />
              <Box as="label" htmlFor="ligntClean">
                Light clean
              </Box>
            </HStack>
            <Divider />
            <HStack h={10}>
              <Checkbox
                id="deepClean"
                value={state.cleanSelectedOptions.includes(CleanSelectedOption.Deep)}
                disabled={isReadOnly}
                onChange={(value) => {
                  if (value) {
                    setState((state) => {
                      return produce(state, (draft) => {
                        draft.cleanSelectedOptions = [CleanSelectedOption.Deep];
                      });
                    });
                  } else {
                    setState((state) => {
                      return produce(state, (draft) => {
                        draft.cleanSelectedOptions = [];
                      });
                    });
                  }
                }}
              />
              <Box as="label" htmlFor="deepClean">
                Deep clean
              </Box>
            </HStack>
          </VStack>
        </>
      )}
    </Box>
  );
}
