import { Box } from "@chakra-ui/react";
import { useDoorsteadManagedRepairsInstructionModal } from "../../../hooks/rr-project/useDoorsteadManagedRepairsInstructionModal";

export function DoorsteadManagedRepairsGuidelines() {
  const { onOpen } = useDoorsteadManagedRepairsInstructionModal();

  return (
    <Box color="gray.700" as="button" textDecoration="underline" onClick={onOpen}>
      Instructions
    </Box>
  );
}
