import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { appServerUrl, auth0Config } from "../../config";
import { useApplicantPageView } from "../../hooks/applicants/useApplicantPageView";
import { useApplicantWebAuth } from "../../hooks/applicants/useApplicantWebAuth";

export default function ApplicantLogoutPage() {
  const [searchParams] = useSearchParams();
  const applicantId = searchParams.get("applicantId");
  const webAuth = useApplicantWebAuth(applicantId!);
  useApplicantPageView("TP_APPLICANT_LOGOUT_VIEWED");

  useEffect(() => {
    const { clientID } = auth0Config();
    webAuth.logout({
      clientID,
      returnTo: `${appServerUrl()}/application/logout/callback?applicantId=${applicantId}`,
    });
  }, []);

  return null;
}
