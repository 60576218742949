import { VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useState } from "react";
import { Item } from "../../../../@types/RentReady/Item";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { normalizeItems, useItems } from "../../../../hooks/rentReady/useItems";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { useSuccess } from "../../../../hooks/useSuccess";
import { getNowISOStringWithoutZ } from "../../../../utils/getNowISOStringWithoutZ";
import { getUuid } from "../../../../utils/getUuid";
import { FullScreen } from "../../FullScreen";
import { Row } from "../../Row";
import { ItemList } from "./ItemList";
import { OptionDropdownList } from "./OptionDropdownList";
import { useAncillaryItems } from "./hooks/useAncillaryItems";
import { useCoreItems } from "./hooks/useCoreItems";
import { useDiff } from "./hooks/useDiff";
import { useIsSaveEnabled } from "./hooks/useIsSaveEnabled";
import { useOnSaveItemList } from "./hooks/useOnSaveItemList";

const backView = View.AreaOneView;

export function ItemListEditView() {
  const success = useSuccess();
  const isV2 = useIsV2UiFeatures();
  const { setView } = useView();
  const initialItems = useItems();
  const { isLoading, mutate } = useOnSaveItemList();
  const [items, setItems] = useState<Item[]>(initialItems);
  const coreItems = useCoreItems(items);
  const ancillaryItems = useAncillaryItems(items);
  const isReadOnly = useIsReadOnly();
  const diff = useDiff(initialItems, items);
  const isOnline = useIsOnline();
  const isDataChanged = diff.length !== 0;
  const isSaveEnabled = useIsSaveEnabled({ isReadOnly, isLoading, isDataChanged });
  useDisplayInstruction(View.ItemListEditView);

  const onChange = (uiId: string, isPresent: boolean | null) => {
    const newItems = produce(items, (draft) => {
      draft.forEach((e) => {
        if (e.uiId === uiId) {
          e.isPresent = isPresent;
        }
      });
    });
    setItems(normalizeItems(newItems));
  };

  const onDelete = (uiId: string) => {
    const newItems = produce(items, (draft) => {
      draft.splice(
        draft.findIndex((e) => e.uiId === uiId),
        1
      );
    });

    setItems(normalizeItems(newItems));
  };

  return (
    <FullScreen
      headerName="Edit Item List"
      backView={backView}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(backView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isOnline && (
          <PrimaryButton
            isFullWidth
            disabled={!isSaveEnabled}
            isLoading={isLoading}
            onClick={() => {
              mutate(
                {
                  diff,
                  items,
                  initialItems,
                },
                {
                  onSuccess() {
                    success({ title: "Updated successfully!" });
                    setView(backView);
                  },
                }
              );
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <Row color="white" bgColor="#515D6E" left="Add Item" />
      <OptionDropdownList
        items={items}
        onAdd={(option) => {
          const newItems = produce(items, (draft) => {
            draft.push({
              id: option.value,
              uiId: getUuid(),
              name: option.name,
              cosmeticDefect: "",
              cosmeticOptions: [],
              selectionType: "single",
              photos: [],
              note: "",
              components: [],
              isPresent: true,
              isCore: false,
              uiName: option.name,
              status: null,
              isMissingNeedsEstimate: false,
              createDate: new Date(),
              updatedDate: getNowISOStringWithoutZ(),
            });
          });
          setItems(normalizeItems(newItems));
        }}
      />
      <VStack spacing={0} w="100%">
        <Row color="white" bgColor="#515D6E" left="Edit Item" />
        {isV2 ? (
          <ItemList
            name=""
            items={items}
            isShowDelete={(item) => !item.isCore}
            onChange={onChange}
            onDelete={onDelete}
          />
        ) : (
          <>
            <ItemList
              name="Core items"
              items={coreItems}
              isShowDelete={() => false}
              onChange={onChange}
              onDelete={onDelete}
            />
            <ItemList
              name="Ancillary items"
              items={ancillaryItems}
              isShowDelete={() => true}
              onChange={onChange}
              onDelete={onDelete}
            />
          </>
        )}
      </VStack>
    </FullScreen>
  );
}
