import { useMutation } from "react-query";
import {
  readFlexWalkthroughGraphQLResultCache,
  writeFlexWalkthroughGraphQLResult,
} from "src/databases/FlexWalkthroughDatabase";
import { isOnline } from "src/utils/isOnline";
import { refreshPage } from "src/utils/refreshPage";
import { shouldInvalidateCache } from "src/utils/rentReady/shouldInvalidateCache";
import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { fetchFlexWalkthroughGraphQLResultServer } from "./useFetchFlexWalkthroughReport/fetchFlexWalkthroughGraphQLResultServer";
import { useMergeCacheWithServer } from "./useFetchFlexWalkthroughReport/useMergeCacheWithServer";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

export function useSubmitFlexWalkthroughReport() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();
  const { refetch } = useFetchFlexWalkthroughReport();
  const mergeCacheWithServer = useMergeCacheWithServer();

  return useMutation(async () => {
    if (isOnline()) {
      //1. get the latest cache and server data to compare if there is any difference
      const flexWalkthroughGraphQLResultCache = await readFlexWalkthroughGraphQLResultCache(flexWalkthroughReportId);
      const flexWalkthroughGraphQLResultServer = await fetchFlexWalkthroughGraphQLResultServer(flexWalkthroughReportId);

      if (flexWalkthroughGraphQLResultCache === null) {
        refreshPage();
      } else if (
        shouldInvalidateCache({
          cacheTime: flexWalkthroughGraphQLResultCache.flexWalkthroughReport.submittedTime || null,
          serverTime: flexWalkthroughGraphQLResultServer.flexWalkthroughReport.submittedTime,
        })
      ) {
        console.log("Do nothing. The server is more recent than the local database.");
      } else {
        // 2. if the server is more recent, we invalidate the cache and sync the whole data
        await mergeCacheWithServer(flexWalkthroughGraphQLResultCache, flexWalkthroughGraphQLResultServer);

        //update the latest timestamp
        const cache = await readFlexWalkthroughGraphQLResultCache(flexWalkthroughReportId);
        if (cache !== null) {
          cache.flexWalkthroughReport.submittedTime = new Date().toISOString();
          await writeFlexWalkthroughGraphQLResult(flexWalkthroughReportId, cache);
        }
        await refetch();
      }
    }
  });
}
