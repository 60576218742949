import { Decision, Estimate } from "../../../../@types/RentReady/Estimate";
import { isEstimateDiff } from "../../../../utils/rentReady/isEstimateDiff";
import { isFromPriceInvalid } from "./isFromPriceInvalid";
import { isToPriceInvalid } from "./isToPriceInvalid";

type Args = {
  initialEstimate: Estimate;
  estimate: Estimate;
  isReadOnly: boolean;
  isLoading: boolean;
};

export function isSaveButtonEnabled({ initialEstimate, estimate, isLoading, isReadOnly }: Args): boolean {
  const hasDataChanged = isEstimateDiff(initialEstimate, estimate);

  if (isReadOnly || isLoading || !hasDataChanged) {
    return false;
  }

  switch (estimate.decision) {
    case Decision.AddEstimates: {
      return !isFromPriceInvalid(estimate) && !isToPriceInvalid(estimate);
    }
    case Decision.CanNotEstimate: {
      return estimate.isOutOfScope || estimate.isToBeDetermined;
    }
    case null:
      return false;
  }
}
