import { PhotosField } from "src/components/rent-ready/fields/PhotosField";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useSyncWalkthroughItemPhotosToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncWalkthroughItemPhotosToCacheAndServer";
import { useUpdatePartialWalkthroughItemToCache } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughItemToCache";
import { ArgsItem } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughItemToServer";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { mapPhotosToPhotoUrlsJson } from "../../../../utils/rentReady/mapPhotosToPhotoUrlsJson";

type Props = {
  item: FlexWalkthroughItemNode;
  isValid?: boolean;
  prefix: Prefix;
  values: Photo[];
  cacheField: keyof FlexWalkthroughItemNode;
  serverField: keyof ArgsItem;
};
export function ItemAutoSyncPhotosField({ item, isValid = true, prefix, cacheField, serverField, values }: Props) {
  const isOnline = useIsOnline();
  const syncWalkthroughPhotosToCacheAndServer = useSyncWalkthroughItemPhotosToCacheAndServer();
  const updatePartialWalkthroughItemToCache = useUpdatePartialWalkthroughItemToCache();

  return (
    <PhotosField
      label=""
      prefix={prefix}
      values={values}
      isValid={isValid}
      onChange={async (values) => {
        if (isOnline) {
          await syncWalkthroughPhotosToCacheAndServer(item, cacheField, serverField, prefix, values);
        } else {
          await updatePartialWalkthroughItemToCache({
            id: item.itemDataId,
            field: cacheField,
            value: mapPhotosToPhotoUrlsJson(values),
          });
        }
      }}
    />
  );
}
