import { HStack, VStack } from "@chakra-ui/react";
import { Status, Type } from "@doorstead/components/fields/TextField";
import { getTextRequiredStatus } from "src/utils/getTextRequiredStatus";
import { isValidEmail } from "src/utils/isValidEmail";
import { replaceAllowedChars } from "../../ApplicantOneSteps/ApplicantInCompleteCard/YourInformationSection/NameAndContactInformationCard/replaceAllowedChars";
import { ApplicantTextField } from "../../ApplicantTextField";
import { useDirty } from "../useDirty";
import { useFailureEmails } from "../useFailureEmails";
import { NewApplicantV2 } from "./typings";

export type Props = {
  value: NewApplicantV2;
  onChange: (applicant: Partial<NewApplicantV2>) => void;
  isDuplicateEmail: boolean;
};

export const emailErrorClassName = "email_error";

export function Content({
  value: { firstName, lastName, email, disabled = false },
  onChange,
  isDuplicateEmail,
}: Props) {
  const { isDirty } = useDirty();
  const { failureEmails } = useFailureEmails();

  return (
    <VStack width="100%" className={failureEmails.includes(email) || isDuplicateEmail ? emailErrorClassName : ""}>
      <HStack w="100%">
        <ApplicantTextField
          id="first_name"
          label="First Name"
          disabled={disabled}
          defaultValue={firstName}
          transform={replaceAllowedChars}
          getStatus={(status) => (disabled || !isDirty ? Status.Default : getTextRequiredStatus(status))}
          onSyncToServer={(firstName) =>
            onChange({
              firstName,
            })
          }
          debounce={0}
        />
        <ApplicantTextField
          id="last_name"
          label="Last Name"
          disabled={disabled}
          defaultValue={lastName}
          getStatus={(status) => (disabled || !isDirty ? Status.Default : getTextRequiredStatus(status))}
          transform={replaceAllowedChars}
          onSyncToServer={(lastName) =>
            onChange({
              lastName,
            })
          }
          debounce={0}
        />
      </HStack>
      <ApplicantTextField
        id="email"
        type={Type.Email}
        disabled={disabled}
        label="Email"
        defaultValue={email}
        getStatus={(email) => {
          if (disabled || !isDirty) return Status.Default;
          if (isDuplicateEmail) {
            return Status.Error;
          }
          return !isValidEmail(email) || failureEmails.includes(email) ? Status.Error : getTextRequiredStatus(email);
        }}
        errorMessage={
          isDirty && (failureEmails.includes(email) || isDuplicateEmail)
            ? "Duplicate email detected. Each applicant must use a unique email address."
            : ""
        }
        onSyncToServer={(email) =>
          onChange({
            email,
          })
        }
        transform={(email) => email.toLowerCase()}
        debounce={0}
      />
    </VStack>
  );
}
