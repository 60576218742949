import { Box } from "@chakra-ui/react";
import { useDIYGuidelinesModal } from "../../../hooks/rr-project/useDIYGuidelinesModal";

export function DIYGuidelines() {
  const { onOpen } = useDIYGuidelinesModal();

  return (
    <Box color="gray.700" as="button" textDecoration="underline" onClick={onOpen}>
      Instructions
    </Box>
  );
}
