import { gql } from "@apollo/client";
import { Item } from "../../@types/RentReady/Item";
import { useAuth } from "../../contexts/auth";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { GoodMissingFixStatus } from "./useFetchInspectionReport/InspectionReportNode";

export type Args = {
  itemDataId: string;
  notes: string;
  photoUrlsJson: string | null;
  status: GoodMissingFixStatus | null;
  selectedCosmeticOptions: string[];
  updatedDate: string | null;
};

type Result = {
  data: {
    updateInspectionReportItem: {
      success: boolean;
      merged: boolean;
      itemData: {
        itemDataId: string;
        notes: string | null;
        selectedCosmeticOptions: string[] | null;
        photoUrlsJson: string | null;
        status: GoodMissingFixStatus | null;
        itemDataUpdatedAt: string | null;
      };
    };
  };
};

export function mapItemToArgs(item: {
  id: string;
  cosmeticOptions: Item["cosmeticOptions"];
  note: Item["note"];
  photos: Item["photos"];
  status: Item["status"];
  updatedDate: Item["updatedDate"];
}): Args {
  return {
    itemDataId: item.id,
    selectedCosmeticOptions: item.cosmeticOptions.filter((e) => e.selected).map((e) => e.id),
    notes: item.note,
    photoUrlsJson: mapPhotosToPhotoUrlsJson(item.photos),
    status: item.status,
    updatedDate: item.updatedDate,
  };
}

export function useUpdateItemToServer() {
  const { gqlClient } = useAuth();

  return async ({ itemDataId, notes, status, photoUrlsJson, selectedCosmeticOptions, updatedDate }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $itemDataId: UUID!
          $notes: String
          $status: String
          $photoUrlsJson: String
          $selectedCosmeticOptions: [String]
          $itemDataUpdatedAt: DateTime
        ) {
          updateInspectionReportItem(
            itemDataId: $itemDataId
            notes: $notes
            photoUrlsJson: $photoUrlsJson
            status: $status
            selectedCosmeticOptions: $selectedCosmeticOptions
            itemDataUpdatedAt: $itemDataUpdatedAt
          ) {
            success
            merged
            itemData {
              itemDataId
              notes
              selectedCosmeticOptions
              status
              photoUrlsJson
              itemDataUpdatedAt
            }
          }
        }
      `,
      variables: {
        itemDataId,
        notes,
        photoUrlsJson,
        selectedCosmeticOptions,
        status,
        itemDataUpdatedAt: updatedDate,
      },
    })) as Result;
  };
}
