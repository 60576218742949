import { Box, Grid, GridItem, HStack, VStack } from "@chakra-ui/react";
import { Repair } from "../../../@types/RepairList/Repair";
import { Photos } from "../Photos";
import { RepairChip } from "../RepairChip";
import { templateColumns } from "./DIYRepairs";

export function DIYTableRow({ uiTitle, repairRequirement, photos }: Repair) {
  return (
    <Grid templateColumns={templateColumns} w="100%" gap={4}>
      <GridItem color="black">
        <HStack w="100%">
          <VStack alignItems="flex-start">
            <HStack>
              <HStack color="gray.800" justifyContent="center" alignItems="center" spacing={2}>
                <Box>{uiTitle}</Box>
              </HStack>
              <RepairChip repairRequirement={repairRequirement} />
            </HStack>
          </VStack>
        </HStack>
      </GridItem>
      <GridItem color="gray.500">
        <Photos urls={photos} mostShown={3} />
      </GridItem>
    </Grid>
  );
}
