import { getStepIndex } from "./getStepIndex";
import { useGetStep } from "./useGetStep";
import { useSetStep } from "./useSetStep";
import { useStep } from "./useStep";
import { useSteps } from "./useSteps";

export function useNextStep() {
  const steps = useSteps();
  const { step } = useStep();
  const { setStep, isLoading } = useSetStep();
  const getStep = useGetStep();

  async function nextStep() {
    const stepIndex = getStepIndex(steps, step);
    await setStep(getStep(stepIndex + 1));
  }

  return {
    nextStep,
    isLoading,
  };
}
