import { Box, Divider, Flex, HStack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Item } from "../../../../@types/RentReady/Item";
import { writeRRItemOpen } from "../../../../databases/RentReadyDatabase";
import { useClearItemAndComponentsPhotosAndNotes } from "../../../../hooks/rentReady/useClearItemAndComponentsPhotosAndNotes";
import { useFetchInspectionReport } from "../../../../hooks/rentReady/useFetchInspectionReport";
import { GoodMissingFixStatus } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useIsAreaPainting } from "../../../../hooks/rentReady/useIsAreaPainting";
import { useSyncSingleItemFieldToCacheAndServer } from "../../../../hooks/rentReady/useSyncSingleItemFieldToCacheAndServer";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { useStatusFixConfirmationDrawer } from "../../../../hooks/rr-project/useStatusFixConfirmationDrawer";
import { isShowItemRequiredPhotosAndOptionalNotesAndHideComponents } from "../../../../utils/rentReady/isShowItemRequiredPhotosAndOptionalNotesAndHideComponents";
import { isV2ItemComplete } from "../../../../utils/rentReady/isV2ItemComplete";
import { isV2ItemDefaultOpen } from "../../../../utils/rentReady/isV2ItemDefaultOpen";
import { ArrowOpenIcon } from "../../../ArrowOpenIcon";
import { RequiredLabel } from "../../RequiredLabel";
import { ComponentRowsV2 } from "./ComponentRowsV2";
import { GoodMissingFixButtons } from "./GoodMissingFixButtons";
import { ItemAutoSyncPhotosField } from "./ItemAutoSyncPhotosField";
import { ItemAutoSyncTextField } from "./ItemAutoSyncTextField";
import { NonInlineItemRowV2 } from "./ItemBar/NonInlineItemRowV2";
import { SpecifyWhichComponentsToFixText } from "./SpecifyWhichComponentsToFixText";

type Props = {
  item: Item;
  defaultOpen: boolean;
};

export function useUpdateItemStatus() {
  const syncSingleItemFieldToCacheAndServer = useSyncSingleItemFieldToCacheAndServer();

  return useMutation(({ item, status }: { item: Item; status: GoodMissingFixStatus }) => {
    return syncSingleItemFieldToCacheAndServer(item, "itemDataStatus", "status", status, status);
  });
}

export function ItemRowV2({ item, defaultOpen }: Props) {
  const { refetch } = useFetchInspectionReport();
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();
  const clearItemAndComponentsPhotosAndNotes = useClearItemAndComponentsPhotosAndNotes();
  const { onOpen: openStatusConfirmation } = useStatusFixConfirmationDrawer();
  const { id, isCore, isMissingNeedsEstimate, components, uiName, photos, note, status: defaultStatus } = item;
  const { mutate: updateItemStatus } = useUpdateItemStatus();
  const [status, setStatus] = useState<GoodMissingFixStatus | null>(defaultStatus);
  const [isOpen, setOpen] = useState(defaultOpen);
  const isAreaFullPaint = useIsAreaPainting();
  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const onStatusChanged = async (status: GoodMissingFixStatus) => {
    setOpen(
      isV2ItemDefaultOpen({
        status,
        isCore,
        isMissingNeedsEstimate,
        photos,
        shouldUseSimplifiedTemplate,
      })
    );
    setStatus(status);

    await clearItemAndComponentsPhotosAndNotes({
      itemId: item.id,
      components: item.components.map(({ id: componentDataId }) => ({ componentDataId })),
    });
    await refetch();
  };

  const showItemRequiredPhotosAndOptionalNotesAndHideComponents =
    isShowItemRequiredPhotosAndOptionalNotesAndHideComponents({
      isCore,
      isMissingNeedsEstimate,
      status,
      shouldUseSimplifiedTemplate,
    });
  const hideComponents = showItemRequiredPhotosAndOptionalNotesAndHideComponents;

  return (
    <>
      <VStack w="100%" p={4} id={item.id}>
        <Flex w="100%" justifyContent="space-between">
          <Flex justifyContent="center" alignItems="center" fontSize="sm" fontWeight="bold">
            <RequiredLabel
              hasValue={isV2ItemComplete({
                item: { ...item, status },
                isAreaFullPaint,
                shouldUseSimplifiedTemplate,
              })}
            >
              {uiName}
            </RequiredLabel>
          </Flex>
          <HStack>
            <GoodMissingFixButtons
              value={status}
              onChange={async (toStatus) => {
                if (status === GoodMissingFixStatus.Missing && toStatus !== GoodMissingFixStatus.Missing) {
                  openStatusConfirmation(item, status, toStatus, () => onStatusChanged(toStatus));
                } else if (status === GoodMissingFixStatus.Fix && toStatus !== GoodMissingFixStatus.Fix) {
                  openStatusConfirmation(item, status, toStatus, () => onStatusChanged(toStatus));
                } else {
                  await updateItemStatus({ item, status: toStatus });
                  await onStatusChanged(toStatus);
                }
              }}
            />
            <ArrowOpenIcon
              isOpen={isOpen}
              onClick={() => {
                setOpen((isOpen) => {
                  const toIsOpen = !isOpen;
                  writeRRItemOpen(item.id, toIsOpen);
                  return toIsOpen;
                });
              }}
            />
          </HStack>
        </Flex>
      </VStack>
      {showItemRequiredPhotosAndOptionalNotesAndHideComponents ? (
        <Box px={4} display={isOpen ? "flex" : "none"} w="100%" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <ItemAutoSyncPhotosField
              prefix={`${Prefix.Items}_${id}` as Prefix}
              item={item}
              isValid={photos.length > 0}
              values={photos}
              cacheField="itemPhotoUrlsJson"
              serverField="photoUrlsJson"
            />
          </Box>
          <Box>
            <ItemAutoSyncTextField item={item} defaultValue={note} cacheField="itemNotes" serverField="notes" />
          </Box>
        </Box>
      ) : (
        shouldUseSimplifiedTemplate &&
        status === GoodMissingFixStatus.Missing && (
          <Box
            px={4}
            display={isOpen ? "flex" : "none"}
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <ItemAutoSyncTextField item={item} defaultValue={note} cacheField="itemNotes" serverField="notes" />
            </Box>
          </Box>
        )
      )}
      {!shouldUseSimplifiedTemplate && (
        <SpecifyWhichComponentsToFixText isAreaFullPaint={isAreaFullPaint} isOpen={isOpen} item={item} />
      )}
      {isOpen && !hideComponents && !shouldUseSimplifiedTemplate && (
        <VStack w="100%" alignItems="flex-start" px={5}>
          <NonInlineItemRowV2 {...item} />
          <Divider />
          <ComponentRowsV2 item={item} components={components} />
        </VStack>
      )}
    </>
  );
}
