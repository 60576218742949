import { Box, Center, HStack } from "@chakra-ui/react";
import { HintBox } from "@doorstead/components";
import { ExclamationFilledIcon } from "@doorstead/components/icons/ExclamationFilledIcon";
import { AutoPayDate } from "src/@types/AutoPay/typings";
import { PaymentMethodType } from "src/hooks/useFetchMe";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { AutopayAmountField } from "../AutopayAmountField";
import { CardTransactionFeeTooltip } from "../CardTransactionFeeTooltip";
import { PayOnField } from "../PayOnField";
import { PaymentMethodField } from "../PaymentMethodField";

// Set autopay amount to a large number so that it covers the entire balance
export const PAY_ACCOUNT_BALANCE_AMOUNT = 999999;

export type Props = {
  paymentMethodId: string | null;
  setPaymentMethodId: (value: string) => void;
  payOn: AutoPayDate | "";
  setPayOn: (value: AutoPayDate | "") => void;
};

export function SetupAutopayBox({ paymentMethodId, setPaymentMethodId, payOn, setPayOn }: Props) {
  const paymentMethod = useSelectedPaymentMethod(paymentMethodId);

  return (
    <>
      <HStack spacing={3} bgColor="gray.100" borderRadius={6} px={3} py={2}>
        <Center p={0} transform="rotate(180deg)">
          <ExclamationFilledIcon />
        </Center>
        <Box fontSize="sm" color="gray.500">
          Autopay pays your account balance each month, including monthly rent and any reoccurring or one-off charges
          (such as utilities) that get billed to your account.
        </Box>
      </HStack>
      <PaymentMethodField
        id="payment_method"
        label="Payment method"
        value={paymentMethodId || ""}
        onChange={setPaymentMethodId}
      />
      {paymentMethod?.type_ === PaymentMethodType.Card && (
        <HintBox>
          <Box fontSize="sm">
            <CardTransactionFeeTooltip>
              <u>Card transaction fees</u>
            </CardTransactionFeeTooltip>
            <Box as="span" color="gray.500">
              {" "}
              apply when paying by credit/debit card.
            </Box>
          </Box>
        </HintBox>
      )}
      <PayOnField id="pay_on" label="Pay on" value={payOn} onChange={setPayOn} />
      <AutopayAmountField />
    </>
  );
}
