import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchInspectionReport } from "./useFetchInspectionReport";
import { useInspectionReportId } from "./useInspectionReportId";

export function useUpdateInspectionReportSubmittedTime() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchInspectionReport();
  const inspectionReportId = useInspectionReportId();
  const nowString = new Date().toISOString();

  return useMutation(
    () => {
      return gqlClient.mutate({
        mutation: gql`
          mutation updateInspectionReport($inspectionReport: [UpdateInspectionReportData]) {
            updateInspectionReport(inspectionReport: $inspectionReport) {
              count
            }
          }
        `,
        variables: {
          inspectionReport: [
            {
              inspectionReportId,
              submittedTime: nowString,
              completedEstimatesTime: nowString,
            },
          ],
        },
      });
    },
    {
      async onSettled() {
        await refetch();
      },
    }
  );
}
