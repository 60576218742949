import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { isAtLeastOneComponentsOrItemFilledIn } from "./isAtLeastOneComponentsOrItemFilledIn";

type Args = {
  item: Item;
  isAreaFullPaint: boolean;
  isOpen: boolean;
  shouldUseSimplifiedTemplate: boolean;
};

type Returned = {
  color: "black" | "red";
  show: boolean;
};

export function getSpecifyingComponentsTextConfig({
  item,
  isAreaFullPaint,
  isOpen,
  shouldUseSimplifiedTemplate,
}: Args): Returned {
  switch (item.status) {
    case GoodMissingFixStatus.Fix: {
      if (!isAtLeastOneComponentsOrItemFilledIn({ item, isAreaFullPaint, shouldUseSimplifiedTemplate })) {
        return {
          color: "red",
          show: true,
        };
      } else {
        return {
          color: "black",
          show: isOpen,
        };
      }
    }
    default: {
      return {
        show: false,
        color: "black",
      };
    }
  }
}
