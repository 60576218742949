import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { moneyString, useIsMobile } from "@doorstead/components";
import { useEffect } from "react";
import { useNextStep } from "src/contexts/StepContext/useNextStep";
import { useIsPreExistingTenantGroup } from "src/hooks/useIsPreExistingTenantGroup";
import { useIsWalkedThrough } from "src/hooks/useIsWalkedThrough";
import { useSecurityDeposit } from "src/hooks/useSecurityDeposit";

export function PendingWalkthroughStep() {
  const securityDeposit = useSecurityDeposit();
  const isMobile = useIsMobile();
  const isWalkedThrough = useIsWalkedThrough();
  const isPreExisting = useIsPreExistingTenantGroup();
  const { nextStep, isLoading } = useNextStep();

  useEffect(() => {
    if (isWalkedThrough || isPreExisting) {
      nextStep();
    }
  }, [isWalkedThrough, isPreExisting]);

  if (isLoading) {
    return null;
  }

  return (
    <Flex w="100%" minH="370px" bgColor="white" borderRadius={20} padding={10}>
      <Flex h="100%" w="100%" flexDirection="column">
        <Flex w="100%" flexGrow={1} flexDirection={isMobile ? "column-reverse" : "row"}>
          <Box w={isMobile ? "100%" : "65%"}>
            <Box color="gray.700" fontSize="3xl" fontWeight="bold">
              Thanks! We&apos;ll reach out to schedule a walkthrough
            </Box>
            <Box color="gray.500" mt={5}>
              We’ll send you an email to schedule a walkthrough.
              <br />
              <br />
              Once your walkthrough is complete you can finalize your lease by paying your security deposit.
            </Box>
          </Box>
          <Flex justifyContent="center" alignItems="center" w={isMobile ? "100%" : "35%"}>
            <img alt="walkthrough" src="https://doorstead-components.s3.us-west-2.amazonaws.com/walkthrough.svg" />
          </Flex>
        </Flex>
        <VStack>
          <Divider mt={5} mb={5} />
          <Flex color="gray.700" lineHeight="24px" w="100%" justifyContent="space-between">
            <Box>Security deposit:</Box>
            <Box fontWeight="bold">{moneyString(securityDeposit)}</Box>
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
}
