import { Box, Flex, HStack, Link, ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { MessageFilledIcon } from "@doorstead/components/icons/MessageFilledIcon";
import { ReactNode } from "react";

function TitleContent({ title, contents }: { title: ReactNode; contents: string[] }) {
  const isMobile = useIsMobile();
  return (
    <Box w="100%">
      <Box fontWeight="bold" color="black">
        {title}
      </Box>
      <Box mt={3}>
        <UnorderedList ml={isMobile ? 7 : 10} spacing={3}>
          {contents.map((e) => (
            <ListItem key={e}>{e}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  );
}

type Props = {
  submit: ReactNode;
};

export function Guideline({ submit }: Props) {
  const isMobile = useIsMobile();
  return (
    <VStack spacing={5} w="100%" p={5} bgColor="white" borderRadius={20} mt={5}>
      <Flex
        w="100%"
        justifyContent="center"
        alignItems="center"
        fontWeight="bold"
        fontSize={isMobile ? "2xl" : "3xl"}
        color="gray.700"
      >
        Repairs Approval Guidelines
      </Flex>
      <Box color="gray.500">
        To prepare your property for rental, we&apos;ve compiled a repairs list from your Initial Evaluation, following
        our
        <Link
          mx={1}
          target="_blank"
          href="https://doorstead.notion.site/Doorstead-Properties-Policy-Guides-24455a2fc2d2442c98d05ee854b00d9f"
        >
          Property Standards Guide.
        </Link>
        We urge you to perform the steps below as soon as you can.
      </Box>
      <VStack spacing={5} color="gray.500">
        <TitleContent
          title={
            <HStack>
              <Box>For required repairs:</Box>
              <Chip size="sm" variant={Variant.Red} label="Required" />
            </HStack>
          }
          contents={[
            "Select 'Get Estimates' for Doorstead to obtain estimates, manage and complete your repairs. This is the preferred route since it’s often quicker, less costly and most efficient for owners.",
            "To handle the repairs independently or through your own vendor, you can opt for 'DIY'.",
          ]}
        />
        <TitleContent
          title={
            <HStack>
              <Box>For recommended or optional repairs:</Box>
              <Chip size="sm" variant={Variant.Purple} label="Recommended" />
              <Chip size="sm" variant={Variant.Gray} label="Optional" />
            </HStack>
          }
          contents={[
            "These repairs are optional, but may become required if requested by a tenant.",
            "These improvements typically boost property value and aid in securing the best tenants.",
          ]}
        />
        <TitleContent
          title={
            <HStack>
              <Box>Adding notes</Box>
              <MessageFilledIcon size={16} color="purple.500" />
            </HStack>
          }
          contents={["Use the `Notes` section for any extra details we should know (e.g., location of spare parts)."]}
        />
        <TitleContent
          title={
            <HStack>
              <Box>Submitting approval:</Box>
            </HStack>
          }
          contents={[
            "Click 'Submit' after approving the repairs. We`ll then source quotes for your review before commencing any work.",
          ]}
        />
        <Box>
          Remember, while the Initial Evaluation captures most key repairs, vendors might spot extra issues during their
          work. We will consult you if the scope of the approved work changes.
        </Box>
      </VStack>
      {submit}
    </VStack>
  );
}
