import { useKeyStorageCache } from "../useKeyStorageCache";

export function useIsLockboxComplete(): boolean {
  const keyStorage = useKeyStorageCache();

  if (keyStorage === null) {
    return false;
  }
  const {
    lockbox: { code },
    lockboxLocation: { location, photos: locationPhotos },
  } = keyStorage;

  return code.trim() !== "" && location.trim() !== "" && locationPhotos.length !== 0;
}
