import { Box, HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import React, { useContext } from "react";
import { Item } from "../../@types/RentReady/Item";
import { useUpdateItemStatus } from "../../components/rent-ready/views/AreaOneView/ItemRowV2";
import { useDrawer } from "../rentReady/useDrawer";
import { GoodMissingFixStatus } from "../rentReady/useFetchInspectionReport/InspectionReportNode";

type ContextProps = {
  isOpen: boolean;
  onOpen: (item: Item, status: GoodMissingFixStatus, toStatus: GoodMissingFixStatus, onDone: () => void) => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

function Action({
  item,
  status,
  onClose,
  onDone,
}: {
  item: Item;
  status: GoodMissingFixStatus;
  onClose: () => void;
  onDone: () => void;
}) {
  const { mutate: updateItemStatus, isLoading } = useUpdateItemStatus();

  return (
    <HStack>
      <OutlineButton isFullWidth disabled={isLoading} onClick={onClose}>
        Close
      </OutlineButton>
      <PrimaryButton
        isFullWidth
        isLoading={isLoading}
        onClick={() =>
          updateItemStatus(
            { item, status },
            {
              onSuccess() {
                onDone();
              },
              onSettled() {
                onClose();
              },
            }
          )
        }
      >
        Confirm
      </PrimaryButton>
    </HStack>
  );
}

export function StatusFixConfirmationDrawerProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDrawer();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen(item, fromStatus, toStatus, onDone) {
          onOpen({
            header: "Confirm Change",
            body: <Box>Please confirm that you would like to change the status.</Box>,
            action: <Action item={item} status={toStatus} onClose={onClose} onDone={onDone} />,
          });
        },
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useStatusFixConfirmationDrawer() {
  return useContext(context);
}
