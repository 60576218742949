import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { TextField } from "@doorstead/components/fields";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useState } from "react";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useApplicantUserName } from "src/hooks/useApplicantUserName";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { useAddress } from "../../../../../../hooks/applicants/useAddress";
import { useApplicantAnalytics } from "../../../../../../hooks/applicants/useApplicantAnalytics";
import { useSignReleaseForm } from "../../../../../../hooks/applicants/useSignReleaseForm";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
};

function Row({ field, value }: { field: string; value: React.ReactNode }) {
  return (
    <Flex width="100%" justifyContent="space-between">
      <GrayText>{field}</GrayText>
      <Box width="75%" maxWidth={300} textAlign="right">
        {value}
      </Box>
    </Flex>
  );
}

export const ReleaseFormCard = React.forwardRef(({ padding, width, ...props }: Props, ref) => {
  const isMobile = useIsMobile();
  const scrollIntoView = useScrollIntoView();
  const name = useApplicantUserName();
  const address = useAddress();
  const { releaseFormSignature } = useFetchedApplicant();
  const { mutate: signReleaseForm, isLoading } = useSignReleaseForm();
  const applicantAnalytics = useApplicantAnalytics();
  const [fullName, setFullName] = useState<string | null>(releaseFormSignature);
  const isSigned = releaseFormSignature !== null && releaseFormSignature.trim() !== "";

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <Box width="100%" fontSize="2xl" fontWeight={600} textAlign="left">
          Release form for landlords and employers
        </Box>
        <Row field="Name" value={name} />
        <Divider />
        <Row field="Property" value={<GrayText>{address}</GrayText>} />
        <Divider />
        <GrayText fontSize="medium">
          By putting my full name in the below, which will act as my signature, hereby give Doorstead Inc. permission to
          communicate with my current and former landlord or property manager or my current and former employer for the
          purpose of discussing any and all of the facts and circumstances of my current or former tenancy or current or
          former employment, as well as the other information listed on my application with no limitations or
          restrictions regarding what may be discussed or revealed to Doorstead Inc.
        </GrayText>
        <TextField
          id="fullName"
          label="Full Name"
          value={fullName || ""}
          disabled={isSigned}
          onChange={(e) => setFullName(e.target.value)}
        />
        {!isSigned && (
          <PrimaryButton
            isLoading={isLoading}
            disabled={fullName === null || fullName.trim() === ""}
            isFullWidth={isMobile}
            onClick={async () => {
              if (fullName !== null) {
                applicantAnalytics?.track("TP_RELEASE_FORM_SIGNED");
                signReleaseForm(fullName.trim(), {
                  onSuccess: () => {
                    scrollIntoView("/3/children/1");
                  },
                });
              }
            }}
          >
            Sign
          </PrimaryButton>
        )}
      </VStack>
    </Card>
  );
});
