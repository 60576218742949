import { gql } from "@apollo/client";
import React from "react";
import { useAuth } from "../contexts/auth";
import { useSuccess } from "./useSuccess";

const CREATE_CARD_PAYMENT_METHOD_FROM_STRIPE = gql`
  mutation ($doorsteadUserId: UUID!, $stripeSetupIntentId: String!) {
    createCardPaymentMethodFromStripe(doorsteadUserId: $doorsteadUserId, stripeSetupIntentId: $stripeSetupIntentId) {
      paymentMethodId
      stripePaymentMethodId
      cardBrand
      cardFunding
      cardLastFourDigits
    }
  }
`;

type CreateCardPaymentMethodFromStripeType = {
  paymentMethodId: string;
};

type ReturnType = {
  createCardPaymentMethodFromStripe: (
    doorsteadUserId: string,
    stripeSetupIntentId: string
  ) => Promise<CreateCardPaymentMethodFromStripeType>;
  isOpening: boolean;
};

export function useCreateCardPaymentMethodFromStripe(): ReturnType {
  const [isOpening, setIsOpening] = React.useState(false);
  const { gqlClient } = useAuth();
  const success = useSuccess();

  const createCardPaymentMethodFromStripe = async (
    doorsteadUserId: string,
    stripeSetupIntentId: string
  ): Promise<CreateCardPaymentMethodFromStripeType> => {
    setIsOpening(true);
    const res = await gqlClient.mutate({
      mutation: CREATE_CARD_PAYMENT_METHOD_FROM_STRIPE,
      variables: {
        doorsteadUserId,
        stripeSetupIntentId,
      },
    });
    setIsOpening(false);
    success({ title: "Payment method added" });
    return res.data?.createCardPaymentMethodFromStripe;
  };

  return {
    createCardPaymentMethodFromStripe,
    isOpening,
  };
}
