import { Item } from "../../../../../@types/RentReady/Item";
import { useIsItemCrossIcon } from "../../../../../hooks/rentReady/useIsItemCrossIcon";
import { useRemoveItemClick } from "../../../../../hooks/rentReady/useRemoveItemClick";
import { isHidingRepairAndReplaceBox } from "../../../../../utils/rentReady/isHidingRepairAndReplaceBox";
import { Bar } from "../Bar";
import { ItemBoxV2 } from "./ItemBoxV2";

export function NonInlineItemRowV2(item: Item) {
  const removeItemClick = useRemoveItemClick(item);
  const isShowIcon = useIsItemCrossIcon(item);
  const hidingRepairAndReplaceBox = isHidingRepairAndReplaceBox({ status: item.status });

  return (
    <Bar
      title={item.uiName}
      p={2}
      isShowAction={isShowIcon}
      onActionClick={removeItemClick}
      fontSize="sm"
      color="gray.900"
      fontWeight="bold"
    >
      {!hidingRepairAndReplaceBox && <ItemBoxV2 {...item} />}
    </Bar>
  );
}
