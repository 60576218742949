import { ApolloClient, gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";

export async function removeOccupant(gqlClient: ApolloClient<any>, applicantOccupantId: string) {
  await gqlClient.mutate({
    mutation: gql`
      mutation ($applicantOccupantId: UUID) {
        deleteApplicantOccupant(applicantOccupantId: $applicantOccupantId) {
          result
        }
      }
    `,
    variables: {
      applicantOccupantId,
    },
  });
}

export function useRemoveOccupant() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (applicantOccupantId: string) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        const index = draft.applicantById.occupants.edges.findIndex(
          (e) => e.node.applicantOccupantId === applicantOccupantId
        );
        draft.applicantById.occupants.edges.splice(index, 1);
      })
    );

    return await removeOccupant(gqlClient, applicantOccupantId);
  });
}
