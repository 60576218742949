type Data = {
  name: string;
};

const BuildingAndYardLabel = "Building & Yard";

export function moveBuildingAndYardToTheFirst<T extends Data>(dataList: T[]): T[] {
  const buildingAndYard = dataList.filter((e) => e.name.startsWith(BuildingAndYardLabel));
  const otherAreas = dataList.filter((e) => !e.name.startsWith(BuildingAndYardLabel));

  return [...buildingAndYard, ...otherAreas];
}
