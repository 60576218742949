export enum AutoPayDate {
  FIRST = "1st",
  SECOND = "2nd",
  THIRD = "3rd",
  TWENTY_EIGHTH = "28th",
  LAST = "Last day of month",
}

export enum AutoPayAmount {
  ACCOUNT_BALANCE = "Account Balance",
}

export type AutoPay = {
  amount: number;
  paymentMethodId: string;
  date: string;
};
