import { Item } from "../../../../../@types/RentReady/Item";
import { Komponent } from "../../../../../@types/RentReady/Komponent";
import { useComponentActionClick } from "../../../../../hooks/rentReady/useComponentActionClick";
import { isHidingRepairAndReplaceBox } from "../../../../../utils/rentReady/isHidingRepairAndReplaceBox";
import { Bar } from "../Bar";
import { ComponentRepairBox } from "./ComponentRepairBox";
import { ComponentReplaceBox } from "./ComponentReplaceBox";

type Props = {
  item: Item;
  component: Komponent;
};

export function ComponentBarV2({ item, component }: Props) {
  const componentActionClick = useComponentActionClick({ component });
  const { id: componentId, name, repairType, replaceType, isShowReplaceOptions, isShowRepairOptions } = component;
  const repairTypeSelected = repairType.selected;
  const replaceTypeSelected = replaceType.selected;
  const isShowEditIcon = repairTypeSelected || replaceTypeSelected;
  const hidingRepairAndReplaceBox = isHidingRepairAndReplaceBox({ status: item.status });

  if (!isShowRepairOptions && !isShowReplaceOptions) {
    return null;
  }

  return (
    <Bar
      key={componentId}
      p={2}
      fontSize="sm"
      color="gray.900"
      fontWeight="bold"
      id={componentId}
      title={name}
      isShowAction={isShowEditIcon}
      onActionClick={componentActionClick}
    >
      {!hidingRepairAndReplaceBox && isShowRepairOptions && <ComponentRepairBox item={item} component={component} />}
      {!hidingRepairAndReplaceBox && isShowReplaceOptions && <ComponentReplaceBox item={item} component={component} />}
    </Bar>
  );
}
