import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { AreaNode } from "../../../../../hooks/rentReady/useFetchInspectionReport/AreaNode";
import { useSyncAreaTextToCacheAndServer } from "../../../../../hooks/rentReady/useSyncAreaTextToCacheAndServer";
import { useUpdatePartialAreaToCache } from "../../../../../hooks/rentReady/useUpdatePartialAreaToCache";
import { ArgsArea } from "../../../../../hooks/rentReady/useUpdatePartialAreaToServer";
import { useIsOnline } from "../../../../../hooks/useIsOnline";
import { AreaNotesField } from "../AreaNotesField";

type Props = {
  isValid?: boolean;
  value: string;
  cacheField: keyof AreaNode;
  serverField: keyof ArgsArea;
};

export function AreaAutoSyncTextField({ isValid = true, value: defaultValue, cacheField, serverField }: Props) {
  const [value, setValue] = useState(defaultValue);
  const syncTextToCacheAndServer = useSyncAreaTextToCacheAndServer();
  const updatePartialAreaToCache = useUpdatePartialAreaToCache();
  const isOnline = useIsOnline();

  return (
    <Box
      w="100%"
      onBlur={async () => {
        if (defaultValue !== value) {
          if (isOnline) {
            await syncTextToCacheAndServer(cacheField, serverField, value, value);
          } else {
            await updatePartialAreaToCache({
              field: cacheField,
              value,
            });
          }
        }
      }}
    >
      <AreaNotesField value={value} isValid={isValid} onChange={setValue} disabled={useIsReadOnly()} />
    </Box>
  );
}
