import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { Mode } from "./Mode";

export type Props = {
  mode: Mode;
  setMode: (mode: Mode) => void;
  prefix: Prefix | null;
  photos: Photo[];
  setPhotos?: Dispatch<SetStateAction<Photo[]>>;
  isOpen: boolean;
  onDelete?: (photo: Photo) => void;
  onClose: () => void;
};

const context = createContext<Props>({
  prefix: null,
  photos: [],
  isOpen: false,
  onClose() {
    console.log("onClose");
  },
  mode: Mode.THUMBNAILS,
  setMode() {
    console.log("setMode");
  },
});

export function Provider({ children, value }: { children: ReactNode; value: Props }) {
  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useInternalState() {
  return useContext(context);
}
