import { Box, Divider, Flex, Link, VStack } from "@chakra-ui/react";
import { LeftRightBox, PrimaryButton, moneyString, useIsMobile } from "@doorstead/components";
import { useEffect, useState } from "react";
import { SubtotalSection } from "src/components/SubtotalSection";
import { TotalChargeSection } from "src/components/TotalChargeSection";
import { Step } from "src/contexts/StepContext";
import { useSetStep } from "src/contexts/StepContext/useSetStep";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { usePageView } from "src/hooks/usePageView";
import { useSecurityAmountDue } from "src/hooks/useSecurityAmountDue";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { useDefaultPaymentMethodId } from "../../../../../hooks/useDefaultPaymentMethodId";
import { useFetchMe } from "../../../../../hooks/useFetchMe";
import { usePaidSecurityDeposit } from "../../../../../hooks/usePaidSecurityDeposit";
import { usePaySecurityDeposit } from "../../../../../hooks/usePaySecurityDeposit";
import { useSecurityDeposit } from "../../../../../hooks/useSecurityDeposit";
import { useSecurityDepositDueDate } from "../../../../../hooks/useSecurityDepositDueDate";
import { getCents } from "../../../../../utils/getCents";
import { MoneyText } from "../../../../MoneyText";
import { PayNowDisclaimerText } from "../../../../PayNowDisclaimerText";
import { PaymentAmountField } from "../../../../PaymentAmountField";
import { PaymentMethodField } from "../../../../PaymentMethodField";
import { StepCard } from "../../../StepCard";
import { DueDateBox } from "../DueDateBox";

export function PaySecurityDepositCard() {
  const securityDeposit = useSecurityDeposit();
  const securityAmountDue = useSecurityAmountDue();
  const securityDepositDueDate = useSecurityDepositDueDate();
  const isMobile = useIsMobile();
  const { paySecurityDeposit, loading: isPayingSecurityDeposit } = usePaySecurityDeposit();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(defaultPaymentMethodId);
  const selectedPaymentMethod = useSelectedPaymentMethod(paymentMethodId);
  const { refetch, isRefetching } = useFetchMe();
  const paidSecurityDeposit = usePaidSecurityDeposit();
  const analytics = usePageView("TP_OB_PAY_SECDEP_VIEWED");
  const [amount, setAmount] = useState(securityAmountDue);
  const isAmountInRange = 0.5 <= amount && amount <= securityAmountDue;
  const { chargeAmount, cardTransactionFee, isFetching } = useChargeBreakdown(amount, selectedPaymentMethod?.type_);
  const { setStep } = useSetStep();

  useEffect(() => {
    setAmount(securityAmountDue);
  }, [securityAmountDue]);

  return (
    <StepCard title="Pay security deposit">
      <VStack spacing={3} w="100%" paddingTop={10}>
        <VStack spacing={3} w="100%">
          <LeftRightBox left="Security deposit" right={<MoneyText>{moneyString(securityDeposit)}</MoneyText>} />
          <DueDateBox>{securityDepositDueDate}</DueDateBox>
        </VStack>
        <Divider mt={3} mb={3} />
        <SubtotalSection subTotalDue={securityAmountDue} paid={-1 * paidSecurityDeposit} />
        <Flex w="100%" justifyContent="flex-end">
          <Link
            textDecoration="underline"
            color="gray.700"
            fontSize="sm"
            onClick={() => {
              setStep(Step.PAYMENT_METHODS);
            }}
          >
            Change payment method
          </Link>
        </Flex>
        <VStack w="100%" spacing={5} alignItems="flex-start">
          <PaymentMethodField
            id="payment_method"
            label="Payment method"
            value={paymentMethodId || ""}
            onChange={setPaymentMethodId}
          />
          <PaymentAmountField min={0.5} max={securityAmountDue} value={amount} onChange={setAmount} />
        </VStack>
        <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} />
        <Box w="100%">
          <PrimaryButton
            isFullWidth={isMobile}
            disabled={!isAmountInRange || !paymentMethodId || isPayingSecurityDeposit || isRefetching || isFetching}
            isLoading={isPayingSecurityDeposit || isRefetching || isFetching}
            onClick={async () => {
              if (paymentMethodId !== null) {
                analytics?.track("TP_OB_PAY_SECDEP_CLICKED", {
                  paymentMethodId,
                });
                await paySecurityDeposit(
                  {
                    amountInCents: getCents(chargeAmount),
                    paymentMethodId,
                  },
                  {
                    async onSuccess() {
                      await refetch();
                    },
                  }
                );
              }
            }}
          >
            Pay now
          </PrimaryButton>
        </Box>
        <PayNowDisclaimerText />
      </VStack>
    </StepCard>
  );
}
