import { Select } from "@doorstead/components";
import produce from "immer";
import { Item } from "../../../../@types/RentReady/Item";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

type Props = {
  values: Item["cosmeticOptions"];
  onChange: (values: Item["cosmeticOptions"]) => void;
};

export function ItemSingleTypeSelect({ values, onChange }: Props) {
  const isReadOnly = useIsReadOnly();

  const options = [
    {
      name: "Select",
      value: "",
    },
  ].concat(
    values.map(({ name, id }) => ({
      name: name,
      value: id,
    }))
  );
  const value = values.find((value) => value.selected) || null;

  return (
    <Select
      id="item-type-select"
      disabled={isReadOnly}
      label=""
      value={value?.id || ""}
      options={options}
      onChange={(e) => {
        const id = e.target.value;
        onChange(
          produce(values, (draft) => {
            draft.forEach((value) => {
              value.selected = value.id === id;
            });
          })
        );
      }}
    />
  );
}
