import { OutlineButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useGroupAnalytics } from "../../hooks/useGroupAnalytics";

export function TenantRequestFormLink() {
  const analytics = useGroupAnalytics();
  const isMobile = useIsMobile();

  return (
    <OutlineButton
      size={isMobile ? "sm" : "md"}
      onClick={() => {
        analytics?.track("TP_TENANT_REQUEST_FORM_CLICKED");
        window.location.href = "/help";
      }}
    >
      Get Help
      <svg
        style={{ marginLeft: 12 }}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="12px"
        width="12px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
      </svg>
    </OutlineButton>
  );
}
