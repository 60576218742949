import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

export type ArgsReport = {
  flexWalkthroughReportId: string;
  propertyIsPhotoReady: boolean;
  propertyOverviewPhotoUrlsJson: string;
  propertyOverviewNotes: string;
  smokeDetectorPhotoUrlsJson: string;
  smokeDetectorIsAtLeastOneInsideEachBedroom: boolean;
  smokeDetectorIsAtLeastOneInsideEachBedroomNotes: string;
  smokeDetectorIsAtLeastOneOutsideOfBedrooms: boolean;
  smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes: string;
  smokeDetectorIsAtLeastOneEachFloor: boolean;
  smokeDetectorIsAtLeastOneEachFloorNotes: string;
  coDetectorPhotoUrlsJson: string;
  coDetectorIsAtLeastOneOutsideOfBedrooms: boolean;
  coDetectorIsAtLeastOneOutsideOfBedroomsNotes: string;
  coDetectorIsAtLeastOneEachFloor: boolean;
  coDetectorIsAtLeastOneEachFloorNotes: string;
};

export type Args = {
  data: (Partial<ArgsReport> & {
    flexWalkthroughReportId: string;
  })[];
};

type Result = {
  data: {
    updateFlexWalkthroughReport: {
      count: number;
    };
  };
};

export function useUpdatePartialWalkthroughReportToServer() {
  const { gqlClient } = useAuth();

  return async ({ data }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation ($data: [UpdateFlexWalkthroughReportInput]) {
          updateFlexWalkthroughReport(flexWalkthrough: $data) {
            count
          }
        }
      `,
      variables: {
        data,
      },
    })) as Result;
  };
}
