import { Box } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "./useDrawer";

export function useOpenInstruction(text: string) {
  const { onOpen, onClose } = useDrawer();

  return () => {
    onOpen({
      body: <Box maxW="450px">{text}</Box>,
      action: <PrimaryButton onClick={onClose}>Close</PrimaryButton>,
    });
  };
}
