import { Item } from "../../@types/RentReady/Item";
import { isComponentDone } from "./isComponentDone";
import { isItemComplete } from "./isItemComplete";

type Args = {
  item: Item;
  isAreaFullPaint: boolean;
  shouldUseSimplifiedTemplate: boolean;
};

export function isAtLeastOneComponentsOrItemFilledIn({
  item,
  isAreaFullPaint,
  shouldUseSimplifiedTemplate,
}: Args): boolean {
  return isItemComplete(item, isAreaFullPaint, shouldUseSimplifiedTemplate) || item.components.some(isComponentDone);
}
