import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";

export function isNoteValid(status: GoodMissingFixStatus | null, note: string): boolean {
  switch (status) {
    case GoodMissingFixStatus.Good:
      return true;
    case GoodMissingFixStatus.Missing:
    case GoodMissingFixStatus.Fix:
      return note.trim() !== "";
    case null:
      return true;
  }
}
