import { Estimate } from "../../@types/RentReady/Estimate";
import { isDateDiff } from "../isDateDiff";
import { isPhotosDiff } from "./isPhotosDiff";

export function isEstimateDiff(data1: Estimate, data2: Estimate): boolean {
  // Compare the properties of the estimates
  if (
    data1.id !== data2.id ||
    data1.uiName !== data2.uiName ||
    data1.description !== data2.description ||
    isDateDiff(data1.createDate, data2.createDate) ||
    data1.isGrouped !== data2.isGrouped ||
    data1.throughoutPropertyType !== data2.throughoutPropertyType ||
    data1.dataType !== data2.dataType ||
    data1.decision !== data2.decision ||
    data1.isRange !== data2.isRange ||
    data1.fromPrice !== data2.fromPrice ||
    data1.toPrice !== data2.toPrice ||
    data1.note !== data2.note ||
    data1.isOutOfScope !== data2.isOutOfScope ||
    data1.isToBeDetermined !== data2.isToBeDetermined
  ) {
    return true;
  }

  // Compare the photos in the estimates
  return isPhotosDiff(data1.photos, data2.photos);
}
