import { useAppendHashTag } from "../useAppendHashTag";

export function useAppendPositionToHashTag(key: string) {
  const appendHashTag = useAppendHashTag();

  return () => {
    const position = document.documentElement.scrollTop;
    appendHashTag({ key, value: position.toString() });
  };
}
