import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Area = {
  areaDataId: string;
  selectedCleaningOptions: string[];
  photoUrlsJson: string;
  notes: string;
  selectedPaintingOptions: string[];
  cleaningNotes: string;
  paintingNotes: string;
  selectedItemRemoval: boolean | null;
  selectedOdors: boolean | null;
  itemRemovalNotes: string;
  itemRemovalPhotoUrlsJson: string;
  smokeDetectorIsOneWorkingDetectorFound: boolean | null;
  smokeDetectorNotes: string;
  smokeDetectorStatus: string;
  smokeDetectorPhotoUrlsJson: string;
  heatSourceNotes: string;
  heatSourceStatus: string;
  heatSourcePhotoUrlsJson: string;
  updatedAt: string;
};

export type ArgsArea = Partial<Area> & {
  areaDataId: string;
};

export type Args = {
  areas: ArgsArea[];
};

type Result = {
  data: {
    updateInspectionReportAreas: {
      count: number;
    };
  };
};

export function useUpdatePartialAreaToServer() {
  const { gqlClient } = useAuth();

  return async ({ areas }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation ($areas: [UpdateInspectionReportAreasOption]) {
          updateInspectionReportAreas(areas: $areas) {
            count
          }
        }
      `,
      variables: {
        areas,
      },
    })) as Result;
  };
}
