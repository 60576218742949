import { Type } from "../../@types/RentReady/Komponent";

export function sortTypesById(types: Type[]): Type[] {
  return [...types].sort((a, b) => a.id.localeCompare(b.id));
}

export function isTypesDiff(types1: Type[], types2: Type[]): boolean {
  // Check if the lengths of the arrays are different
  if (types1.length !== types2.length) {
    return true;
  }

  // Iterate over each type in the sorted arrays
  for (let i = 0; i < types1.length; i++) {
    const type1 = types1[i];
    const type2 = types2[i];

    // Compare the properties of the types
    if (!areTypesEqual(type1, type2)) {
      return true;
    }
  }

  return false;
}

function areTypesEqual(type1: Type, type2: Type): boolean {
  // Compare the name and selected properties of the types
  return type1.id === type2.id && type1.name === type2.name && type1.selected === type2.selected;
}
