import { Flex } from "@chakra-ui/react";
import { TickIcon } from "@doorstead/components/icons";

export function CompleteIcon() {
  return (
    <Flex justifyContent="center" alignItems="center" bgColor="green.300" w="20px" h="20px" borderRadius="50%">
      <TickIcon color="white" />
    </Flex>
  );
}
