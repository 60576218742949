import { Box, Flex } from "@chakra-ui/react";
import { ApprovalsSubmittedModalProvider } from "../../../hooks/rr-project/useApprovalsSubmittedModal";
import { DIYGuidelinesModalProvider } from "../../../hooks/rr-project/useDIYGuidelinesModal";
import { DIYModalProvider } from "../../../hooks/rr-project/useDIYModal";
import { DoorsteadManagedRepairsInstructionModalProvider } from "../../../hooks/rr-project/useDoorsteadManagedRepairsInstructionModal";
import { EstimatesGuidelinesModalProvider } from "../../../hooks/rr-project/useEstimatesGuidelinesModal";
import { useFetchRRProject } from "../../../hooks/rr-project/useFetchRRProject";
import { InstructionGuidelinesProvider } from "../../../hooks/rr-project/useInstructionGuidelinesModal";
import { RejectEstimateModalProvider } from "../../../hooks/rr-project/useRejectEstimateModal";
import { ShareDIYOrRejectThoughtModalProvider } from "../../../hooks/rr-project/useShareSurveyModal";
import { View, ViewProvider, useView } from "../../../hooks/rr-project/useView";
import { MainBox } from "../MainBox";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Main } from "./Main";
import { getInitialView } from "./getInitialView";

function Content() {
  const { view } = useView();

  return (
    <Flex w="100%" minH="100vh" flexDirection="column" bgColor="#f9f8ff">
      <Header />
      <MainBox as="main" flex={1} pb={5}>
        <Main />
      </MainBox>
      {(view === View.RepairListView || view === View.HowItWorksView) && <Footer />}
    </Flex>
  );
}

export function ProjectLayout() {
  const { isLoading, data } = useFetchRRProject();

  if (isLoading) {
    return <Box>Loading...</Box>;
  } else if (!data?.rrProject) {
    return <Box>Sorry, we can not find this data, please contact your administrator</Box>;
  }

  return (
    <ViewProvider view={getInitialView(data.rrProject.status)}>
      <RejectEstimateModalProvider>
        <DIYModalProvider>
          <DIYGuidelinesModalProvider>
            <InstructionGuidelinesProvider>
              <DoorsteadManagedRepairsInstructionModalProvider>
                <ApprovalsSubmittedModalProvider>
                  <ShareDIYOrRejectThoughtModalProvider>
                    <EstimatesGuidelinesModalProvider>
                      <Content />
                    </EstimatesGuidelinesModalProvider>
                  </ShareDIYOrRejectThoughtModalProvider>
                </ApprovalsSubmittedModalProvider>
              </DoorsteadManagedRepairsInstructionModalProvider>
            </InstructionGuidelinesProvider>
          </DIYGuidelinesModalProvider>
        </DIYModalProvider>
      </RejectEstimateModalProvider>
    </ViewProvider>
  );
}
