import produce from "immer";
import { FlexWalkthroughGraphQLResultCache } from "../../../databases/FlexWalkthroughDatabase";
import { arrayToMap } from "../../../utils/arrayToMap";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { FlexWalkthroughItemNode } from "./FlexWalkthroughItemNode";
import { FlexWalkthroughGraphQLResult } from "./useFetchFlexWalkthroughReport";

export function mergeFlexWalkthroughItemsCache(
  flexWalkthroughGraphQLResultCache: FlexWalkthroughGraphQLResultCache,
  flexWalkthroughGraphQLResultServer: FlexWalkthroughGraphQLResult
): FlexWalkthroughGraphQLResultCache {
  return produce(flexWalkthroughGraphQLResultCache, (cache) => {
    const cacheItems: FlexWalkthroughItemNode[] = cache.flexWalkthroughReport.items || [];
    const serverItemsMap = arrayToMap(
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.items || [],
      "itemDataId"
    );

    const toRemoveIds: Set<string> = new Set();
    cacheItems.forEach((cacheItem) => {
      if (serverItemsMap.has(cacheItem.itemDataId)) {
        //if both cache and server area exists, we merge the new area if needed
        const serverItem = serverItemsMap.get(cacheItem.itemDataId)!;
        if (
          shouldInvalidateCache({
            cacheTime: cacheItem.itemUpdatedAt,
            serverTime: serverItem.itemUpdatedAt,
          })
        ) {
          cacheItem.response = serverItem.response;
          cacheItem.photoUrlsJson = getMergedPhotos(cacheItem.photoUrlsJson, serverItem.photoUrlsJson);
          cacheItem.notes = serverItem.notes;
          cacheItem.itemUpdatedAt = serverItem.itemUpdatedAt;
        }

        //if the area config is changed, we update the config cache
        if (
          shouldInvalidateCache({
            cacheTime: cacheItem.itemConfigUpdatedAt,
            serverTime: serverItem.itemConfigUpdatedAt,
          })
        ) {
          cacheItem.itemText = serverItem.itemText;
          cacheItem.category = serverItem.category;
          cacheItem.section = serverItem.section;
          cacheItem.tooltip = serverItem.tooltip;
          cacheItem.positiveActionText = serverItem.positiveActionText;
          cacheItem.negativeActionText = serverItem.negativeActionText;
          cacheItem.itemConfigUpdatedAt = serverItem.itemConfigUpdatedAt;
        }
      } else {
        //if cache area does not exist in server, we can remove it
        toRemoveIds.add(cacheItem.itemDataId);
      }
    });

    cache.flexWalkthroughReport.items = cacheItems;
  });
}
