import { Spacer, Text } from "@chakra-ui/react";
import { PrimaryButton, TextField, moneyString, useIsMobile } from "@doorstead/components";
import { Status } from "@doorstead/components/fields/TextField";
import { PaymentMethod } from "@stripe/stripe-js";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { BaseCard } from "src/components/Onboarding/steps/MakePaymentStep/BaseCard";
import { PayNowDisclaimerText } from "src/components/PayNowDisclaimerText";
import { PaymentAmountField } from "src/components/PaymentAmountField";
import { TotalChargeSection } from "src/components/TotalChargeSection";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { PaymentMethodType } from "src/hooks/useFetchMe";
import { useOpenPaymentFailureModal } from "src/hooks/useOpenPaymentFailureModal";
import { useOpenPaymentSuccessModal } from "src/hooks/useOpenPaymentSuccessModal";
import { usePageView } from "src/hooks/usePageView";
import { usePayOneTime } from "src/hooks/usePayOneTime";
import { getCents } from "src/utils/getCents";
import { isValidEmail } from "src/utils/isValidEmail";
import { isValidRoutingNumber } from "src/utils/isValidRoutingNumber";
import { PaymentOption } from "../AddPaymentMethodBox/PaymentOption";
import { PaymentCardBox } from "../PaymentCardBox";

export function OneTimePayment() {
  const minAmount = 0.5;
  const maxAmount = 999999;
  const analytics = usePageView("ONE_TIME_PAY_VIEWED");
  const isMobile = useIsMobile();
  const { payOneTime, loading } = usePayOneTime();
  const openPaymentSuccessModal = useOpenPaymentSuccessModal();
  const openPaymentFailureModal = useOpenPaymentFailureModal();
  const [amount, setAmount] = useState(100);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(PaymentMethodType.ACH);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [routingNumberError, setRoutingNumberError] = useState<string>("");
  const [stripePaymentMethod, setStripePaymentMethod] = useState<PaymentMethod | null>(null);
  const { chargeAmount, cardTransactionFee, isFetching } = useChargeBreakdown(amount, paymentMethodType);

  const isAmountInRange = minAmount <= amount && amount <= maxAmount;
  const isPaymentMethodValid =
    paymentMethodType === PaymentMethodType.ACH
      ? isValidRoutingNumber(routingNumber) && !!accountNumber
      : paymentMethodType === PaymentMethodType.Card
      ? !!stripePaymentMethod
      : false;

  return (
    <BaseCard title="Submit one-time payment">
      <Helmet>
        <title>Doorstead one-time payment</title>
        <meta name="description" content="Doorstead one-time payment" />
      </Helmet>
      <TextField
        id="name"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        status={name ? Status.Correct : Status.Default}
      />
      <TextField
        id="email"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          if (!isValidEmail(e.target.value)) {
            setEmailError("Invalid email");
          } else {
            setEmailError("");
          }
        }}
        status={email ? (emailError ? Status.Error : Status.Correct) : Status.Default}
      />
      <TextField
        id="address"
        label="Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        status={address ? Status.Correct : Status.Default}
      />
      <TextField
        id="comments"
        label="Comments (Optional)"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        status={comments ? Status.Correct : Status.Default}
      />
      <Text color="gray.600" fontSize="md" pt={6}>
        Payment Method
      </Text>
      <PaymentOption value={paymentMethodType} onChange={setPaymentMethodType} hideSubLabel horizontal />
      {paymentMethodType === PaymentMethodType.ACH && (
        <>
          <TextField
            id="accountNumber"
            label="Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            status={accountNumber ? Status.Correct : Status.Default}
          />
          <TextField
            id="routingNumber"
            label="Routing Number"
            value={routingNumber}
            onChange={(e) => {
              setRoutingNumber(e.target.value);
              if (!isValidRoutingNumber(e.target.value)) {
                setRoutingNumberError("Invalid routing number");
              } else {
                setRoutingNumberError("");
              }
            }}
            errorMessage={routingNumberError}
            status={routingNumber ? (routingNumberError ? Status.Error : Status.Correct) : Status.Default}
          />
        </>
      )}
      {paymentMethodType === PaymentMethodType.Card && (
        <PaymentCardBox
          onDone={(paymentMethod) => {
            setStripePaymentMethod(paymentMethod);
          }}
        />
      )}
      <Spacer pt={3} />
      <PaymentAmountField min={minAmount} max={maxAmount} value={amount} onChange={setAmount} />
      <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} />
      <PrimaryButton
        disabled={
          loading ||
          isFetching ||
          !name ||
          !email ||
          !!emailError ||
          !address ||
          !isAmountInRange ||
          !isPaymentMethodValid
        }
        isLoading={loading || isFetching}
        isFullWidth={isMobile}
        onClick={async () => {
          if (paymentMethodType && window.confirm(`Are you sure to pay ${moneyString(chargeAmount)}?`)) {
            analytics?.track("ONE_TIME_PAY_CLICKED", {
              name,
              email,
              address,
              comments,
              paymentMethodType,
            });
            await payOneTime(
              {
                name,
                email,
                address,
                comments,
                amountInCents: getCents(chargeAmount),
                paymentMethodType,
                accountNumber,
                routingNumber,
                stripePaymentMethodId: stripePaymentMethod?.id || null,
              },
              {
                onSuccess() {
                  analytics?.track("ONE_TIME_PAY_SUCCESS", {
                    name,
                    email,
                    address,
                    comments,
                    paymentMethodType,
                  });
                  openPaymentSuccessModal({
                    amount: chargeAmount,
                    paymentMethod: {
                      paymentMethodId: "",
                      type_: paymentMethodType,
                      isDefault: false,
                      bankAccountMask: accountNumber.slice(-4),
                      bankAccountName: paymentMethodType,
                      microDepositStatus: null,
                      cardBrand: paymentMethodType,
                      cardLastFourDigits: stripePaymentMethod?.card?.last4 || "",
                    },
                    doneText: "Done",
                    suggestAutoPay: false,
                  });
                  setPaymentMethodType(PaymentMethodType.ACH);
                },
                onError(e: any) {
                  console.error(e);
                  analytics?.track("ONE_TIME_PAY_FAILED", {
                    name,
                    email,
                    address,
                    comments,
                    paymentMethodType,
                  });
                  const errorMessage =
                    e.graphQLErrors && e.graphQLErrors.length !== 0 ? e.graphQLErrors[0].message : null;
                  openPaymentFailureModal({
                    amount: chargeAmount,
                    paymentMethod: {
                      paymentMethodId: "",
                      type_: paymentMethodType,
                      isDefault: false,
                      bankAccountMask: accountNumber.slice(-4),
                      bankAccountName: paymentMethodType,
                      microDepositStatus: null,
                      cardBrand: paymentMethodType,
                      cardLastFourDigits: "",
                    },
                    errorMessage,
                  });
                },
              }
            );
          }
        }}
      >
        Pay now
      </PrimaryButton>
      <PayNowDisclaimerText />
    </BaseCard>
  );
}
