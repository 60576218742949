import { Komponent, RepairOrReplaceType, Type } from "../../../@types/RentReady/Komponent";
import { OtherOption } from "../../../components/rent-ready/otherOption";
import { mapPhotoUrlsJsonToPhotos } from "../../../utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { ComponentNode } from "../useFetchInspectionReport/ComponentNode";

function getRepairOrReplaceType(
  componentNode: ComponentNode,
  isRepair: boolean,
  selected: boolean
): RepairOrReplaceType {
  const selectedOptions =
    (isRepair ? componentNode.componentSelectedRepairOptions : componentNode.componentSelectedReplaceOptions) || [];
  const types: Type[] = [];

  if (isRepair && componentNode.componentHasRepairOption && componentNode.componentRepairOptions !== null) {
    componentNode.componentRepairOptions.forEach((e) => {
      types.push({
        id: e,
        name: e,
        selected: selectedOptions.includes(e),
      });
    });
    types.push({ ...OtherOption, selected: selectedOptions.includes(OtherOption.id) });
  }

  if (!isRepair && componentNode.componentHasReplaceOption && componentNode.componentReplaceOptions !== null) {
    componentNode.componentReplaceOptions.forEach((e) => {
      types.push({
        id: e,
        name: e,
        selected: selectedOptions.includes(e),
      });
    });
    types.push({ ...OtherOption, selected: selectedOptions.includes(OtherOption.id) });
  }

  return {
    photos: mapPhotoUrlsJsonToPhotos(componentNode.componentPhotoUrlsJson),
    selected,
    note: componentNode.componentNotes || "",
    types,
  };
}

export function mapComponentNodeToComponent(componentNode: ComponentNode): Komponent {
  const selectedOptions = componentNode.componentSelectedComponentOptions || [];

  const options =
    componentNode.componentOptions === null
      ? []
      : componentNode.componentOptions
          .map((e) => ({
            id: e,
            name: e,
            selected: selectedOptions.includes(e),
          }))
          .concat([{ ...OtherOption, selected: selectedOptions.some((e) => e === OtherOption.id) }]);

  return {
    id: componentNode.componentDataId,
    name: componentNode.componentName,
    optionSelectionType: componentNode.componentOptionSelectionType === "single-select" ? "single" : "multiple",
    options,
    repairType: getRepairOrReplaceType(componentNode, true, componentNode.componentSelectedRepair || false),
    replaceType: getRepairOrReplaceType(componentNode, false, componentNode.componentSelectedReplace || false),
    isShowReplaceOptions: componentNode.componentOptionShowReplaceOptions || false,
    isShowRepairOptions: componentNode.componentOptionShowRepairOptions || false,
    updatedDate: componentNode.componentDataUpdatedAt,
  };
}
