import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { RequiredLabel } from "../../RequiredLabel";
import { YesNoButtons } from "../../YesNoButtons";
import { PhotosField } from "../../fields/PhotosField";

type Props = {
  title: string;
  prefix: Prefix;
  photos: Photo[];
  onChangePhotos: (photos: Photo[]) => void;
  yesNo1Title: string;
  yesNo1Value: boolean | null;
  onChangeYesNo1: (value: boolean | null) => void;

  yesNo2Title: string;
  yesNo2Value: boolean | null;
  onChangeYesNo2: (value: boolean | null) => void;
};

export function DetectorBox({
  title,
  prefix,
  onChangePhotos,
  onChangeYesNo2,
  onChangeYesNo1,
  yesNo1Title,
  yesNo1Value,
  yesNo2Title,
  yesNo2Value,
  photos,
}: Props) {
  return (
    <VStack w="100%" alignItems="flex-start">
      <RequiredLabel hasValue={photos.length > 0 && yesNo1Value !== null && yesNo2Value != null}>{title}</RequiredLabel>
      <Divider />
      <VStack w="100%" alignItems="flex-start" spacing={5}>
        <PhotosField label="" prefix={prefix} values={photos} onChange={onChangePhotos} />
        <Flex w="100%" justifyContent="space-between">
          <Box>{yesNo1Title}</Box>
          <Box>
            <YesNoButtons value={yesNo1Value} onChange={onChangeYesNo1} />
          </Box>
        </Flex>
        <Flex w="100%" justifyContent="space-between">
          <Box>{yesNo2Title}</Box>
          <Box>
            <YesNoButtons value={yesNo2Value} onChange={onChangeYesNo2} />
          </Box>
        </Flex>
      </VStack>
    </VStack>
  );
}
