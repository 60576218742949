import { HStack, VStack } from "@chakra-ui/react";
import { GrayText, useIsMobile } from "@doorstead/components";
import { useNavigate } from "react-router-dom";
import { usePageView } from "src/hooks/usePageView";
import { useEverGreenTransactionHistories } from "../../../hooks/useEverGreenTransactionHistories";
import { useIsAutoPaySetup } from "../../../hooks/useIsAutoPaySetup";
import { useTenantUserName } from "../../../hooks/useTenantUserName";
import { TransactionHistoryTable } from "../../TransactionHistoryTable";
import { DesktopAutoPayBox } from "./DesktopAutoPayBox";
import { Title } from "./Title";
import { AutoPaySection } from "./sections/AutoPaySection";
import { MonthlySection } from "./sections/MonthlySection";
import { PropertyDetailSection } from "./sections/PropertyDetailSection";

const sectionHeight = "218px";

export function EverGreenHome() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const userName = useTenantUserName();
  const everGreenTransactionHistories = useEverGreenTransactionHistories();
  const isAutoPaySetup = useIsAutoPaySetup();
  const analytics = usePageView("TP_HP_VIEWED");

  return (
    <VStack
      w="100%"
      alignItems="left"
      sx={{
        "--section-height": sectionHeight,
      }}
    >
      <GrayText w="100%">Home</GrayText>
      <VStack gap={5}>
        <Title text={`Hello, ${userName}`} />
        <PropertyDetailSection />
        <Title text="Payment" area="title" />
        {isAutoPaySetup ? (
          <DesktopAutoPayBox />
        ) : (
          <HStack w="100%" spacing={10}>
            <MonthlySection />
            {!isMobile && <AutoPaySection />}
          </HStack>
        )}
        <TransactionHistoryTable
          fontSize="xl"
          rows={everGreenTransactionHistories}
          onViewClick={() => {
            analytics?.track("TP_HP_VIEW_TXN_HISTORY_CLICKED");
            navigate("/transaction-history");
          }}
        />
      </VStack>
    </VStack>
  );
}
