import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { useFetchFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useSyncSingleWalkthroughItemFieldToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncSingleWalkthroughItemFieldToCacheAndServer";
import { mapPhotoUrlsJsonToPhotos } from "src/utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { ActionButtons } from "./ActionButtons";
import { InstructionIcon } from "./InstructionIcon";
import { ItemAutoSyncPhotosField } from "./ItemAutoSyncPhotosField";
import { ItemAutoSyncTextField } from "./ItemAutoSyncTextField";

type Props = {
  item: FlexWalkthroughItemNode;
};

export function useUpdateItemStatus() {
  const syncSingleWalkthroughItemFieldToCacheAndServer = useSyncSingleWalkthroughItemFieldToCacheAndServer();

  return useMutation(({ item, response }: { item: FlexWalkthroughItemNode; response: string }) => {
    return syncSingleWalkthroughItemFieldToCacheAndServer(item, "response", "response", response, response);
  });
}

export function WalkthroughItemRow({ item }: Props) {
  const { refetch } = useFetchFlexWalkthroughReport();
  const {
    itemDataId,
    itemText,
    response: defaultResponse,
    positiveActionText,
    negativeActionText,
    notes,
    photoUrlsJson,
  } = item;
  const [response, setResponse] = useState<string | null>(defaultResponse);
  const { mutate: updateItemStatus } = useUpdateItemStatus();

  let photos: Photo[] = [];

  if (photoUrlsJson) {
    photos = mapPhotoUrlsJsonToPhotos(photoUrlsJson);
  }

  const onResponseChanged = async (response: string) => {
    setResponse(response);
    await refetch();
  };

  return (
    <>
      <VStack w="100%" p={4} id={item.itemDataId}>
        <Flex w="100%" justifyContent="space-between">
          <Flex flex={1} fontSize="md">
            <HStack>
              <Box>{itemText}</Box>
              <InstructionIcon text={item.tooltip} />
            </HStack>
          </Flex>
          <HStack>
            <ActionButtons
              response={response}
              positiveActionText={positiveActionText}
              negativeActionText={negativeActionText}
              onChange={async (toResponse) => {
                await updateItemStatus(
                  {
                    item,
                    response: toResponse,
                  },
                  {
                    onSuccess() {
                      onResponseChanged(toResponse);
                    },
                  }
                );
              }}
            />
          </HStack>
        </Flex>
      </VStack>
      {(response === negativeActionText || (response && itemText == "Two copies of all keys")) && (
        <Box px={4} display={"flex"} w="100%" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <ItemAutoSyncPhotosField
              prefix={`${Prefix.WalkthroughItem}_${itemDataId}` as Prefix}
              item={item}
              isValid={item.photosRequired ? photos.length > 0 : true}
              values={photos}
              cacheField="photoUrlsJson"
              serverField="photoUrlsJson"
            />
          </Box>
          <Box>
            <ItemAutoSyncTextField item={item} defaultValue={notes || ""} cacheField="notes" serverField="notes" />
          </Box>
        </Box>
      )}
    </>
  );
}
