export function isFromPriceInvalid({
  isRange,
  fromPrice,
  toPrice,
}: {
  isRange: boolean;
  fromPrice: number;
  toPrice: number;
}): boolean {
  if (!isRange) return false;
  return fromPrice > toPrice;
}
