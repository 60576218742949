export const query = `
  query ($inspectionReportId: UUID!) {
    inspectionReportAreaOptions {
      areaOptionId
      areaOptionName
    }
    inspectionReportItemOptions {
      itemOptionId
      itemOptionName
      itemCanHaveMultiple
    }
    inspectionReport(inspectionReportId: $inspectionReportId) {
      inspectionType
      expirationDate
      isThroughoutPropertyFeature
      isV2UiFeature
      isKeyDetailsAdditionalKeysFeature
      isKeyStorageFullTenantSetsFeature
      isKeyStorageAdditionalKeyStorageFeature
      propertyAddress
      propertyType
      propertyId
      submittedTime
      startTime
      propertyDetailsInstructionTime
      completedEstimatesTime
      completedEvalTime
      keyStorageAndKeyDetailsInstructionTime
      estimationInstructionTime
      keyStorageInstructionTime
      areaListInstructionTime
      areaOverviewInstructionTime
      itemListAddRemoveInstructionTime
      itemListTypeInstructionTime
      keyDetailsInstructionTime
      completedPropertyDetailsTime
      propertyTypeNotes
      bedrooms
      bedroomNotes
      fullBathrooms
      fullBathroomNotes
      halfBathrooms
      halfBathroomNotes
      sqFt
      sqFtNotes
      parking
      parkingNotes
      frontDoorKeys
      frontDoorKeysWorking
      frontDoorKeysPhotoUrlsJson
      frontDoorKeysNotes
      mailboxKeys
      mailboxKeysWorking
      mailboxKeysPhotoUrlsJson
      mailboxKeysNotes
      communityKeys
      communityKeysWorking
      communityKeysNotes
      communityKeysPhotoUrlsJson
      garageRemotes
      garageRemotesWorking
      garageRemotesPhotoUrlsJson
      garageRemotesNotes
      keypads
      keypadsWorking
      keypadsPhotoUrlsJson
      keypadsNotes
      
      additionalKeys
      additionalKeysWorking
      additionalKeysPhotoUrlsJson
      additionalKeysNotes
      
      additionalKeyStoragePhotoUrlsJson
      additionalKeyStorageNotes
      
      fullTenantSets
      fullTenantSetsPhotoUrlsJson
      lockboxCode
      lockboxCodePhotoUrlsJson
      lockboxLocation
      lockboxLocationPhotoUrlsJson
      estimateStartDate
      estimateJobTimeInDays
      updatedAt
      cleanIsNeeded
      cleanNotes
      cleanIsOdorRemoval
      cleanSelectedOptions
      detectorSmokePhotoUrlsJson
      detectorSmokeIsAtLeastOneOutsideBedrooms
      detectorSmokeIsAtLeastOneEachFloor
      detectorCmPhotoUrlsJson
      detectorCmIsAtLeastOneCoOutsideBedrooms
      detectorCmIsAtLeastOneCoEachFloor
      updatedAtAreas
      updatedAtKeyDetail
      updatedAtKeyStorage
      updatedAtThroughoutPropertyCleaning
      updatedAtThroughoutPropertyDetector
      shouldShowEstimatesStep
      estimatesData {
        estimateId
        estimateSection
        estimateItem
        estimateDescription
        estimatePhotoUrlsJson
        estimateThroughoutPropertyType
        lowEstimateCostCents
        highEstimateCostCents
        cannotEstimate
        cannotEstimateReason
        estimateNotes
        isGrouped
        estimateCreatedAt
        estimateDataType
      }

      data {
        areaDataId
        areaUpdatedAt
        areaName
        areaNotes
        areaShowInThroughoutProperty
        areaSmokeDetectorIsOneWorkingDetectorFound
        areaSmokeDetectorNotes
        areaSmokeDetectorStatus
        areaSmokeDetectorPhotoUrlsJson
        
        areaHeatSourceNotes
        areaHeatSourceStatus
        areaHeatSourcePhotoUrlsJson
        areaPhotoUrlsJson
        areaSelectedCleaningOptions
        areaSelectedPaintingOptions
        areaCleaningNotes
        areaPaintingNotes
        areaDataCreatedAt
        areaDataUpdatedAt
        areaDataItemsUpdatedAt
        areaSelectedItemRemoval
        areaSelectedOdors
        areaItemRemovalNotes
        areaItemRemovalPhotoUrlsJson
        itemsData {
          itemDataId
          itemShouldRender
          itemIsMissingNeedsEstimate
          itemDataStatus
          itemName
          itemUpdatedAt
          itemSelectedCosmeticOptions
          itemNotes
          itemPhotoUrlsJson
          itemCosmeticDefect
          itemCosmeticOptions
          itemCosmeticOptionSelectionType
          itemRequirements
          itemDataCreatedAt
          itemDataUpdatedAt
          itemIsItemPresent
          itemCategory
          componentsData {
            componentUpdatedAt
            componentDataId
            componentName
            componentSelectedComponentOptions
            componentSelectedRepair
            componentSelectedReplace
            componentSelectedRepairOptions
            componentSelectedReplaceOptions
            componentPhotoUrlsJson
            componentNotes
            componentOptions
            componentOptionSelectionType
            componentHasRepairOption
            componentHasReplaceOption
            componentRepairOptions
            componentReplaceOptions
            componentOptionShowReplaceOptions
            componentOptionShowRepairOptions
            componentDataUpdatedAt
            componentShouldRender
          }
        }
      }
    }
  }
`;
