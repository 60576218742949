import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";

export function useTrackAutopayEditClicked() {
  const analytics = useGroupAnalytics();

  return ({ autopayId, paymentMethodId }: { autopayId: string; paymentMethodId: string }) => {
    analytics?.track("TP_AUTOPAY_EDIT_CLICKED", {
      autopayId,
      paymentMethodId,
    });
  };
}
