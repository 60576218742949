import { Center, CenterProps } from "@chakra-ui/react";

type Props = CenterProps & {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export function ScaleBox({ label, value, onChange, ...centerProps }: Props) {
  return (
    <Center
      {...centerProps}
      bgColor="white"
      role="button"
      borderRadius={11}
      color="gray.700"
      border={`solid ${value ? 3 : 0}px #9F7AEA`}
      h="60px"
      fontWeight="bold"
      onClick={() => onChange(!value)}
    >
      {label}
    </Center>
  );
}
