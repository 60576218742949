import { Repair } from "../../@types/RepairList/Repair";
import { OwnerDecision } from "../../hooks/rr-project/useFetchRRProject";

export function getEstimateDollarStr(repair: Repair): string {
  const { chosenVendorRepair, ownerDecision } = repair;

  const lowEstimateCostCents = chosenVendorRepair?.lowEstimateCostCents || null;
  const highEstimateCostCents = chosenVendorRepair?.highEstimateCostCents || null;
  const noChosenEstimate = !chosenVendorRepair;
  const nullEstimate = lowEstimateCostCents === null;
  const singlePriceEstimate = lowEstimateCostCents === highEstimateCostCents;

  if (ownerDecision !== OwnerDecision.No) {
    if (chosenVendorRepair?.cannotEstimate) {
      return "TBD";
    } else if (noChosenEstimate || nullEstimate) {
      return "Estimates Pending";
    } else if (singlePriceEstimate) {
      return `$${lowEstimateCostCents / 100}`;
    } else if (highEstimateCostCents !== null) {
      return `$${lowEstimateCostCents / 100} - $${highEstimateCostCents / 100}`;
    }
  }

  return "";
}
