import { HStack } from "@chakra-ui/react";
import { Variant } from "@doorstead/components/Chip";
import { StatusButton } from "src/components/rent-ready/views/AreaOneView/StatusButton";
import { useIsReadOnly } from "src/hooks/flexWalkthrough/useIsReadOnly";

type Props = {
  response: string | null;
  positiveActionText: string;
  negativeActionText: string;
  onChange: (status: string) => void;
};

export function ActionButtons({ response, positiveActionText, negativeActionText, onChange }: Props) {
  return (
    <HStack spacing={2}>
      <StatusButton
        label={positiveActionText}
        isSelecting={response === positiveActionText}
        variant={Variant.Green}
        onSelect={() => {
          if (response !== positiveActionText) {
            onChange(positiveActionText);
          }
        }}
        isReadOnly={useIsReadOnly()}
      />
      <StatusButton
        label={negativeActionText}
        variant={Variant.Red}
        isSelecting={response === negativeActionText}
        onSelect={() => {
          if (response !== negativeActionText) {
            onChange(negativeActionText);
          }
        }}
        isReadOnly={useIsReadOnly()}
      />
    </HStack>
  );
}
