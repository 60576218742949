import { Box, Center, HStack, Image, VStack } from "@chakra-ui/react";
import { Modal } from "@doorstead/components";
import { OutlineButton } from "@doorstead/components/buttons";
import { AutoPayNode } from "../../../../../hooks/useFetchMe";
import { useGroupAnalytics } from "../../../../../hooks/useGroupAnalytics";
import { useRemoveAutoPay } from "../../../../../hooks/useRemoveAutoPay";

type Props = {
  autopay: AutoPayNode;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onBack: () => void;
};

export function ConfirmDisableAutopayModal({ autopay, isOpen, onClose, onSuccess, onBack }: Props) {
  const analytics = useGroupAnalytics();
  const { isLoading: isRemovingAutoPay, removeAutoPay } = useRemoveAutoPay();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VStack alignItems="center" paddingX={4} paddingY={8} spacing={5}>
        <Image
          w={70}
          alt="Are you sure to disable autopay?"
          src="https://doorstead-static.s3.us-west-2.amazonaws.com/images/tenants/yellow_warning.png"
        />
        <Box fontSize="xl" fontWeight="bold">
          Are you sure you want to disable autopay?
        </Box>
        <Box color="gray.500">
          A reminder that payments are due by the 1st of every month. Late payments are subject to a late fee.
          Additional daily late fees may apply depending on local laws. Please check your lease for specific terms.
        </Box>
        <Center>
          <HStack w="100%" spacing={10}>
            <Center
              px={6}
              py={2}
              color="white"
              borderRadius={6}
              bgColor="red.500"
              role="button"
              onClick={() => {
                if (!isRemovingAutoPay) {
                  removeAutoPay(autopay.autopayId, {
                    async onSuccess() {
                      analytics?.track("TP_OB_AUTOPAY_REMOVED", {
                        autopayId: autopay.autopayId,
                        paymentMethodId: autopay.paymentMethod.paymentMethodId,
                      });
                      onSuccess();
                    },
                  });
                }
              }}
            >
              {isRemovingAutoPay ? "Disabling..." : "Confirm"}
            </Center>
            {!isRemovingAutoPay && (
              <OutlineButton onClick={onBack} isFullWidth={false} size="md">
                Back to autopay settings
              </OutlineButton>
            )}
          </HStack>
        </Center>
      </VStack>
    </Modal>
  );
}
