import { getRepairTitle } from "../../utils/rr-project/getRepairTitle";
import { ProjectNodeType, RepairNode, RepairRequirement, RepairType } from "./useFetchRRProject";
import { useFetchedRRProject } from "./useFetchedRRProject";

function compareRepairs(repairA: RepairNode, repairB: RepairNode) {
  const typeA = repairA.type ?? "";
  const typeB = repairB.type ?? "";
  if (typeA === RepairType.MOVE_IN_CLEANING) {
    return 1;
  } else if (typeB === RepairType.MOVE_IN_CLEANING) {
    return -1;
  }

  return getRepairTitle(repairA).localeCompare(getRepairTitle(repairB));
}

export function useRepairNodes(): RepairNode[] {
  const rrProject = useFetchedRRProject();
  let repairNodes: RepairNode[] = rrProject.repairs?.edges.map((e) => e.node) || [];

  repairNodes = repairNodes.filter((repair) => {
    if (
      rrProject.type !== null &&
      [ProjectNodeType.MoveOut, ProjectNodeType.TurnOver].includes(rrProject.type) &&
      repair.repairRequirement !== null &&
      [
        RepairRequirement.OwnerOptional,
        RepairRequirement.OwnerRequired,
        RepairRequirement.OwnerRecommended,
        RepairRequirement.TenantOwnerSplit,
        RepairRequirement.TenantResponsible,
      ].includes(repair.repairRequirement)
    ) {
      return true;
    }

    return rrProject.type === null || ![ProjectNodeType.MoveOut, ProjectNodeType.TurnOver].includes(rrProject.type);
  });

  repairNodes = repairNodes.sort(compareRepairs);

  const groupNames: (RepairType | null)[] = repairNodes
    .filter((repair) => repair.isGrouped)
    .map((repair) => repair.type as RepairType);

  groupNames.sort((nameA, nameB) => (nameA || "").localeCompare(nameB || ""));

  return repairNodes.filter((repair) => repair.isGrouped || !groupNames.includes(repair.type));
}
