export enum Scale {
  NotProvided = "",
  NA = "N/A",
  Zero = "0",
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
}

export function getScaleString(scale: Scale): string {
  switch (scale) {
    case Scale.Zero:
      return "0";
    case Scale.One:
      return "1";
    case Scale.Two:
      return "2";
    case Scale.Three:
      return "3";
    case Scale.Four:
      return "4";
    case Scale.NA:
      return "N/A";
    case Scale.NotProvided:
    default:
      return "";
  }
}
