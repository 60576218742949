import { Item } from "../../@types/RentReady/Item";
import { isItemComplete } from "../../utils/rentReady/isItemComplete";
import { useIsAreaPainting } from "./useIsAreaPainting";
import { useShouldUseSimplifiedTemplate } from "./useShouldUseSimplifiedTemplate";

export function useIsItemPurpleCircle(item: Item) {
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();
  const isAreaPainting = useIsAreaPainting();
  return isItemComplete(item, isAreaPainting, shouldUseSimplifiedTemplate);
}
