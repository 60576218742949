import * as jsonPatch from "fast-json-patch";
import { Item } from "../../../../../@types/RentReady/Item";
import { arrayToRecord } from "../../../../../utils/arrayToRecord";

export type NormalizeItem = {
  uiId: string;
  id: string;
  isPresent: boolean | null;
  createDate: string;
};

function normalize(items: Item[]): NormalizeItem[] {
  return items.map((e) => ({
    id: e.id,
    uiId: e.uiId,
    isPresent: e.isPresent,
    createDate: e.createDate.toISOString(),
  }));
}

export function useDiff(initialItems: Item[], items: Item[]) {
  const initialMap = arrayToRecord(normalize(initialItems), "uiId");
  const updatedMap = arrayToRecord(normalize(items), "uiId");

  return jsonPatch.compare(initialMap, updatedMap);
}
