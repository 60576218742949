import { Option } from "../../@types/RentReady/Komponent";
import { OtherOption } from "../../components/rent-ready/otherOption";

export function isSelectionDisabled({ options, hasNote }: { options: Option[]; hasNote: boolean }): boolean {
  if (options.length !== 0) {
    //If has options, but no options selected, disabled
    const selectedOptions = options.filter((e) => e.selected);

    //no options selected, disabled
    if (selectedOptions.length === 0) {
      return true;
    } else {
      //if the selected option is other, but no note, disabled
      if (selectedOptions.some((e) => e.id === OtherOption.id)) {
        return !hasNote;
      }
    }
  }

  return false;
}
