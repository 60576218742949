import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useError } from "../useError";
import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { useFetchedApplicationRequest } from "./useFetchedApplicationRequest";

const mutation = gql`
  mutation ($applicationRequestId: UUID!, $negotiatedMoveInDate: Date!) {
    addMoveInDateApplicationRequest(
      applicationRequestId: $applicationRequestId
      negotiatedMoveInDate: $negotiatedMoveInDate
    ) {
      result
    }
  }
`;

export function useAddMoveInDateApplicationRequest() {
  const { refetch } = useFetchApplicationMe();
  const error = useError();
  const { gqlClient } = useAuth();
  const applicationRequest = useFetchedApplicationRequest();

  return useMutation(
    ({ negotiatedMoveInDate }: { negotiatedMoveInDate: string }) =>
      gqlClient.mutate({
        mutation,
        variables: {
          applicationRequestId: applicationRequest.applicationRequestId,
          negotiatedMoveInDate,
        },
      }),
    {
      async onSuccess() {
        await refetch();
      },
      async onError() {
        error({ title: "Failed to submit lease start date", description: "" });
      },
    }
  );
}
