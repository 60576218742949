import { OutlineButton, Table } from "@doorstead/components";
import { getLabel } from "../../../@types/AutoPay/getLabel";
import { useAutoPay } from "../../../hooks/useAutoPay";
import { getAutopayNextDateLabel } from "../../../utils/getAutopayNextDateLabel";
import { getPaymentMethodLabel } from "../../../utils/getPaymentMethodLabel";
import { EditValue, ListValue } from "./typings";
import { useTrackAutopayEditClicked } from "./useTrackAutopayEditClicked";

type Props = {
  onOpen: () => void;
  setValue: (value: EditValue | null) => void;
};

export function DesktopView({ onOpen, setValue }: Props) {
  const trackAutopayEditClicked = useTrackAutopayEditClicked();
  const autoPay = useAutoPay();

  return (
    <Table<ListValue>
      headers={[
        {
          label: "Autopay account",
          isNumeric: false,
        },
        {
          label: "Recurring date",
          isNumeric: false,
        },
        {
          label: "Next pay date",
          isNumeric: false,
        },
        {
          label: "",
          isNumeric: false,
        },
      ]}
      cells={[
        ({ paymentMethod }) => paymentMethod,
        ({ payOn }) => getLabel(payOn),
        ({ nextScheduleDate }) => getAutopayNextDateLabel(nextScheduleDate),
        (value) => (
          <OutlineButton
            size="sm"
            onClick={() => {
              trackAutopayEditClicked({ autopayId: value.id, paymentMethodId: value.paymentMethodId });
              setValue(value);
              onOpen();
            }}
          >
            Edit
          </OutlineButton>
        ),
      ]}
      rows={
        autoPay === null
          ? []
          : [
              {
                id: autoPay.autopayId,
                paymentMethodId: autoPay.paymentMethod.paymentMethodId,
                paymentMethod: `${getPaymentMethodLabel({
                  type: autoPay.paymentMethod.type_,
                  bankAccountName: autoPay.paymentMethod.bankAccountName,
                  bankAccountMask: autoPay.paymentMethod.bankAccountMask,
                  cardBrand: autoPay.paymentMethod.cardBrand,
                  cardLastFourDigits: autoPay.paymentMethod.cardLastFourDigits,
                })}`,
                payOn: autoPay.date,
                nextScheduleDate: autoPay.nextPaymentDate,
              },
            ]
      }
    />
  );
}
