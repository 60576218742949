import { Box, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { ReactNode, useEffect } from "react";
import { useDrawer } from "./useDrawer";
import { InspectionReportNode, InspectionReportType } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReport } from "./useInspectionReport";
import { useIsAreaListInstructionViewed } from "./useIsAreaListInstructionViewed";
import { useIsAreaOverviewInstructionViewed } from "./useIsAreaOverviewInstructionViewed";
import { useIsEstimationInstructionViewed } from "./useIsEstimationInstructionViewed";
import { useIsItemListAddRemoveInstructionViewed } from "./useIsItemListAddRemoveInstructionViewed";
import { useIsItemListTypeInstructionViewed } from "./useIsItemListTypeInstructionViewed";
import { useIsKeyDetailsInstructionViewed } from "./useIsKeyDetailsInstructionViewed";
import { useIsKeyStorageAndKeyDetailsInstructionViewed } from "./useIsKeyStorageAndKeyDetailsInstructionViewed";
import { useIsKeyStorageInstructionViewed } from "./useIsKeyStorageInstructionViewed";
import { useIsPropertyDetailsInstructionViewed } from "./useIsPropertyDetailsInstructionViewed";
import { useIsV2UiFeatures } from "./useIsV2UiFeatures";
import { useUpdateInspectionReport } from "./useUpdateInspectionReport";
import { View } from "./useView";

type Config = {
  isViewed: boolean;
  instructionField: keyof InspectionReportNode;
  header: string;
  body: ReactNode;
  isSupported: boolean;
};

export function useViewConfig(view: View): Config {
  const isV2 = useIsV2UiFeatures();
  const { inspectionType } = useInspectionReport();

  switch (view) {
    case View.PropertyDetailView:
      return {
        isSupported: true,
        isViewed: useIsPropertyDetailsInstructionViewed(),
        instructionField: "propertyDetailsInstructionTime",
        header: "Instructions",
        body: "Review the property details and use the ‘Edit’ button to adjust any information that is incorrect.",
      };
    case View.AreaListView:
      return {
        isSupported: true,
        isViewed: useIsKeyStorageAndKeyDetailsInstructionViewed(),
        instructionField: "keyStorageAndKeyDetailsInstructionTime",
        header: "Instructions",
        body:
          inspectionType === InspectionReportType.MOVE_IN_INSPECTION ? (
            <VStack alignItems="flex-start">
              <Box>
                1. Area list should match the number of areas in the home. Add or remove areas by using &apos;Edit Area
                List&apos;.
              </Box>
              <Box>
                2. Once section is completed and required detail added, section should display green check mark. Some
                sections may already display green check mark if no additional info is required.
              </Box>
              <Box>3. Once all sections are completed, you will be able to submit.</Box>
            </VStack>
          ) : isV2 ? (
            <VStack alignItems="flex-start">
              <Box>
                1. Area list should match the number of areas in the home. Add or remove areas by using &apos;Edit Area
                List&apos;.
              </Box>
              <Box>
                2. Once section is completed and required details added, section should display green check mark.
              </Box>
              <Box>3. Once all sections are completed, you will be able to move to the next stage.</Box>
            </VStack>
          ) : (
            <VStack alignItems="flex-start">
              <Box>1. Fill out &apos;Key Details&apos; and &apos;Key Storage&apos;.</Box>
              <Box>
                2. Area list should match the number of areas in the home; add or remove areas by clicking on the
                &apos;Edit Area List&apos;
              </Box>
              <Box>3. Click on each area to complete its evaluation</Box>
            </VStack>
          ),
      };
    case View.KeyDetailsView:
      return {
        isSupported: true,
        isViewed: useIsKeyDetailsInstructionViewed(),
        instructionField: "keyDetailsInstructionTime",
        header: "Instructions",
        body: "Fill out all key types. Use ‘N/A’ if key type does not exist, and use ‘0’ if no keys are found. Mark if any key is not working, and take photos of keys when prompted.",
      };
    case View.KeyStorageView:
      return {
        isSupported: true,
        isViewed: useIsKeyStorageInstructionViewed(),
        instructionField: "keyStorageInstructionTime",
        header: "Instructions",
        body: (
          <VStack alignItems="flex-start">
            <Box>1. Only one set of access keys should remain in the lockbox.</Box>
            <Box>2. Photograph all other keys left in a drawer in the kitchen.</Box>
            <Box>3. Photograph and note lockbox code and location on property.</Box>
          </VStack>
        ),
      };
    case View.AreaListEditView:
      return {
        isSupported: true,
        isViewed: useIsAreaListInstructionViewed(),
        instructionField: "areaListInstructionTime",
        header: "Adjusting area list",
        body: (
          <Box maxW="250px">
            The area list should match the number and type of areas in the home. Room numbers will re-calculate based on
            the updated number of rooms after you save.
          </Box>
        ),
      };
    case View.AreaOverviewView:
      return {
        isSupported: true,
        isViewed: useIsAreaOverviewInstructionViewed(),
        instructionField: "areaOverviewInstructionTime",
        header: "Instructions",
        body: (
          <VStack alignItems="flex-start">
            <Box>1. Take overview photos of the area (required)</Box>
            <Box>2. If cleaning is needed, mark the cleaning type.</Box>
            <Box>
              3. If full-area painting is needed, mark it here. Skip full-paint if only specific items require painting;
              mark those items instead in the next step.
            </Box>
          </VStack>
        ),
      };
    case View.AreaOneView:
      return {
        isSupported: true,
        isViewed: useIsItemListAddRemoveInstructionViewed(),
        instructionField: "itemListAddRemoveInstructionTime",
        header: "Instructions",
        body:
          inspectionType === InspectionReportType.MOVE_IN_INSPECTION ? (
            <VStack alignItems="flex-start">
              <Box>
                1. If items are not present or the condition must be noted, mark them and fill out required details.
              </Box>
              <Box>2. If items need to be added to the list, use &apos;+Add Items&apos;</Box>
              <Box>
                3. If full-area painting is needed, mark &apos;Needed&apos;. If only partial area painting is needed,
                select &apos;fix&apos; in the individual item list.
              </Box>
            </VStack>
          ) : isV2 ? (
            <VStack alignItems="flex-start">
              <Box>1. Take overview photos of the area (required)</Box>
              <Box>2. If items are missing or need fixing, mark them and fill out required details.</Box>
              <Box>3. If items need to be added to the list, use &apos;+Add Items&apos;</Box>
              <Box>
                4. If full-area painting is needed, mark &apos;Needed&apos;. If only partial area painting is needed,
                select &apos;fix&apos; in the individual item list.
              </Box>
            </VStack>
          ) : (
            <VStack alignItems="flex-start">
              <Box>
                1. Item list should match the items in this area; add or remove items by clicking on the ‘Edit Item
                List’
              </Box>
              <Box>
                2. If any items need to be repaired or replaced, click on the item dropdown and mark which item
                components need repair or replacement
              </Box>
            </VStack>
          ),
      };
    case View.ItemListEditView:
      return {
        isSupported: true,
        isViewed: useIsItemListTypeInstructionViewed(),
        instructionField: "itemListTypeInstructionTime",
        header: "Adjusting item list",
        body: "The item list should match the number and type of items in this area. Core items can not be deleted, so mark them as ‘Missing’ if not found. You can add or delete any ancillary items.",
      };
    case View.EstimateListView:
      return {
        isSupported: true,
        isViewed: useIsEstimationInstructionViewed(),
        instructionField: "estimationInstructionTime",
        header: "Instructions",
        body: "Provide estimates for each repair item or group. If you cannot estimate, mark it as 'Cannot Estimate' in the detailed view. Submit the estimates list once all items are filled out.",
      };
    default:
      return {
        isSupported: false,
        isViewed: false,
        instructionField: "keyStorageAndKeyDetailsInstructionTime",
        header: "",
        body: null,
      };
  }
}

export function useOpenInstruction(view: View) {
  const { onOpen, onClose } = useDrawer();
  const { header, body, isSupported } = useViewConfig(view);

  return () => {
    if (isSupported) {
      onOpen({
        header,
        body,
        action: <PrimaryButton onClick={onClose}>Close</PrimaryButton>,
      });
    }
  };
}

export function useDisplayInstruction(view: View) {
  const { isSupported, isViewed, instructionField } = useViewConfig(view);
  const { mutate } = useUpdateInspectionReport();
  const openInstruction = useOpenInstruction(view);

  useEffect(() => {
    if (isSupported && !isViewed) {
      openInstruction();
      mutate({
        [instructionField]: new Date().toISOString(),
      });
    }
  }, [isSupported, isViewed]);
}
