import { PrimaryButton } from "@doorstead/components/buttons";
import { useApprovalsSubmittedModal } from "../../../hooks/rr-project/useApprovalsSubmittedModal";
import { useOnSubmit } from "./useOnSubmit";

export function Submit() {
  const { onOpen } = useApprovalsSubmittedModal();
  const { mutate: onSubmit, isLoading } = useOnSubmit();

  return (
    <PrimaryButton
      size="lg"
      isLoading={isLoading}
      onClick={() =>
        onSubmit(undefined, {
          onSuccess() {
            onOpen();
          },
        })
      }
    >
      Submit
    </PrimaryButton>
  );
}
