import { Box, Divider, Stack, StackProps, Text, VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton, useIsMobile } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import _ from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { NewApplicant, useInviteCoTenantsAndGuarantors } from "src/hooks/applicants/useInviteCoTenantsAndGuarantors";
import { useError } from "src/hooks/useError";
import { isNullOrEmptyString } from "src/utils/isNullOrEmptyString";
import { isValidEmail } from "src/utils/isValidEmail";
import { CardTitle } from "../../CardTitle";
import { NewApplicantWithId } from "../NewApplicantsCard/typings";

type Props = {
  coTenants: NewApplicantWithId[];
  guarantors: NewApplicantWithId[];
  borderRadius?: number;
} & StackProps;

const filterApplicants = (applicants: NewApplicantWithId[]) => {
  return applicants.filter(
    ({ firstName, lastName, email }) =>
      !isNullOrEmptyString(firstName) &&
      !isNullOrEmptyString(lastName) &&
      !isNullOrEmptyString(email) &&
      isValidEmail(email)
  );
};

export function InviteCard({ coTenants, guarantors, ...stackProps }: Props) {
  const applicantAnalytics = useApplicantAnalytics();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const applicantId = useApplicantId();
  const error = useError();
  const { mutate: inviteCoTenantsAndGuarantors, isLoading } = useInviteCoTenantsAndGuarantors();

  const filteredCoTenants = useMemo(() => filterApplicants(coTenants), [coTenants]);
  const filteredGuarantors = useMemo(() => filterApplicants(guarantors), [guarantors]);
  const newApplicants = useMemo(() => {
    const applicantsWithId = [...filteredCoTenants, ...filteredGuarantors];
    const applicants: NewApplicant[] = applicantsWithId.map((x) => _.omit(x, "id"));
    return applicants;
  }, [filteredCoTenants, filteredGuarantors]);

  return (
    <Card {...stackProps} padding={7} width="100%">
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Send invite</CardTitle>
        <Text mt={3} color="gray.500" fontWeight="medium" fontSize="md">
          Please check that the below information is accurate. We will send each individual an email with a link to
          their own application to fill out.
        </Text>
        {filteredCoTenants.length > 0 && (
          <>
            <Divider pt={6} />
            <Text mt={2} fontSize="xl" color="gray.800" fontWeight="semibold">
              Co-tenants
            </Text>
            {filteredCoTenants.map(({ id, firstName, lastName, email }) => (
              <Box key={id}>
                <Text mt={2} color="gray.800" fontWeight="medium" fontSize="md">
                  {firstName} {lastName}
                </Text>
                <Text color="gray.500" fontWeight="normal" fontSize="sm">
                  {email}
                </Text>
              </Box>
            ))}
          </>
        )}
        {filteredGuarantors.length > 0 && (
          <>
            <Divider pt={6} />
            <Text mt={2} fontSize="xl" color="gray.800" fontWeight="semibold">
              Guarantors
            </Text>
            {filteredGuarantors.map(({ id, firstName, lastName, email }) => (
              <Box key={id}>
                <Text mt={2} color="gray.800" fontWeight="medium" fontSize="md">
                  {firstName} {lastName}
                </Text>
                <Text color="gray.500" fontWeight="normal" fontSize="sm">
                  {email}
                </Text>
              </Box>
            ))}
          </>
        )}
        <Stack pt={8} direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
          <PrimaryButton
            isLoading={isLoading}
            isFullWidth={isMobile}
            disabled={isLoading || newApplicants.length === 0}
            onClick={() => {
              inviteCoTenantsAndGuarantors(newApplicants, {
                onSuccess: () => {
                  applicantAnalytics?.track("TP_COTENANTS_AND_GUARANTORS_INVITED", { newApplicants });
                  navigate(`/application/${applicantId}?invited=true`);
                },
                onError: () => {
                  error({ title: "Failed to invite co-tenants and guarantors", description: "" });
                },
              });
            }}
          >
            Invite & continue
          </PrimaryButton>
          <OutlineButton
            disabled={isLoading}
            isFullWidth={isMobile}
            onClick={() => {
              applicantAnalytics?.track("TP_INVITE_COTENANTS_AND_GUARANTORS_CANCELLED");
              navigate(`/application/${applicantId}`);
            }}
          >
            Cancel
          </OutlineButton>
        </Stack>
      </VStack>
    </Card>
  );
}
