import { useFetchMe } from "./useFetchMe";
import { useIsPropertyWA } from "./useIsPropertyWA";

export function useIsSkipWAHoldingDeposit(): boolean {
  const { isLoading, data } = useFetchMe();
  const isPropertyWA = useIsPropertyWA();
  if (isLoading) {
    return false;
  }
  const property = data?.me.tenant.activeLease.property || null;

  if (property === null || !isPropertyWA) return false;
  return !!property.unifiedSecdepCollectionProcess;
}
