import { ThroughoutPropertyDetector } from "../../../../@types/RentReady/ThroughoutPropertyDetector";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { isComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyDetectorComplete";
import { useThroughoutPropertyDetectorCache } from "../../../../hooks/rentReady/useThroughoutPropertyDetectorCache";

type Args = {
  value: ThroughoutPropertyDetector;
};

export function useIsThroughoutPropertyDetectorDisabled({ value }: Args): boolean {
  const initThroughoutPropertyDetector = useThroughoutPropertyDetectorCache();
  const isReadOnly = useIsReadOnly();
  const isThroughoutPropertyDetectorEqual = JSON.stringify(initThroughoutPropertyDetector) === JSON.stringify(value);

  if (isThroughoutPropertyDetectorEqual || isReadOnly) return true;

  return !isComplete(value);
}
