import { Stack, Text } from "@chakra-ui/react";
import { Chip } from "@doorstead/components/Chip";
import { OptionType } from "./typings";
import { getOptionDescriptionString, getOptionTagVariant } from "./utils";

export const OptionDescription = ({
  type,
  price,
  listingPrice,
}: {
  type: OptionType;
  price: number;
  listingPrice: number;
}) => {
  const { highlightedText, description } = getOptionDescriptionString(price, listingPrice);

  return (
    <Stack spacing={0} gap={2} direction="row" flexWrap="wrap" alignItems="center">
      <Chip label={highlightedText} variant={getOptionTagVariant(type)} size="md" />
      <Text color="gray.700" fontWeight="medium" fontSize="md">
        {description}
      </Text>
    </Stack>
  );
};
