import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useInspectionReportId } from "./useInspectionReportId";

type Result = {
  data: {
    createInspectionReportEstimatesData: {
      success: boolean;
      completedEvalTime: string;
    };
  };
};

export function useCreateInspectionReportEstimatesData() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return () => {
    return gqlClient.mutate({
      mutation: gql`
        mutation createInspectionReportEstimatesData($inspectionReportId: UUID!) {
          createInspectionReportEstimatesData(inspectionReportId: $inspectionReportId) {
            success
            completedEvalTime
          }
        }
      `,
      variables: {
        inspectionReportId,
      },
    }) as unknown as Result;
  };
}
