import { useFetchedLease } from "./useFetchedLease";
import { useIsPropertyWA } from "./useIsPropertyWA";
import { useIsSkipWAHoldingDeposit } from "./useIsSkipWAHoldingDeposit";

export function useSecurityDepositDueDate(): string {
  const isPropertyWA = useIsPropertyWA();
  const isSkipWAHoldingDeposit = useIsSkipWAHoldingDeposit();
  if (isPropertyWA && !isSkipWAHoldingDeposit) {
    return "after walkthrough is completed";
  }

  return useFetchedLease().securityDepositDueDate;
}
