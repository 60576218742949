import { Box } from "@chakra-ui/react";
import { getLabel } from "../../hooks/rentReady/useFetchInspectionReport/PropertyType";
import { useInspectionReport } from "../../hooks/rentReady/useInspectionReport";
import { numberToCommaString } from "../../utils/numberToCommaString";

export function InspectionReportPropertyBox() {
  const { propertyType, bedrooms, fullBathrooms, halfBathrooms, sqFt, parking } = useInspectionReport();
  return (
    <>
      <Box color="gray.500">Type: {getLabel(propertyType)}</Box>
      <Box color="gray.500">Bedrooms: {numberToCommaString(bedrooms || 0)}</Box>
      <Box color="gray.500">Full Bathrooms: {numberToCommaString(fullBathrooms || 0)}</Box>
      <Box color="gray.500">Half Bathrooms: {numberToCommaString(halfBathrooms || 0)}</Box>
      <Box color="gray.500">Sqft: {numberToCommaString(sqFt || 0)}</Box>
      <Box color="gray.500">Parking Spots: {numberToCommaString(parking || 0)}</Box>
    </>
  );
}
