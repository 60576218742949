import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { isThroughoutPropertyItemRequirePhotos } from "./isThroughoutPropertyItemRequirePhotos";

type Args = {
  name: Item["name"];
  photos: Item["photos"];
  status: Item["status"];
  note: Item["note"];
  shouldUseSimplifiedTemplate: boolean;
};

export function isThroughoutPropertyItemDefaultOpen({
  name,
  photos,
  status,
  note,
  shouldUseSimplifiedTemplate,
}: Args): boolean {
  switch (status) {
    case GoodMissingFixStatus.Missing:
    case GoodMissingFixStatus.Fix:
      return note.trim().length === 0;
    case GoodMissingFixStatus.Good:
      if (
        isThroughoutPropertyItemRequirePhotos({
          name,
          status,
          shouldUseSimplifiedTemplate,
        })
      ) {
        return photos.length === 0;
      }
      return false;
    default:
      return false;
  }
}
