import { Box, HStack, Heading, Link, VStack } from "@chakra-ui/react";
import { GrayText, useIsMobile } from "@doorstead/components";
import { InformationFilledIcon } from "@doorstead/components/icons/InformationFilledIcon";
import produce from "immer";
import { useState } from "react";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { useSuccess } from "src/hooks/useSuccess";
import { getUuid } from "src/utils/getUuid";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { useLogConversionEvent } from "../../../hooks/useLogConversionEvent";
import { InviteCardV2 } from "./InviteCardV2/InviteCardV2";
import { NewApplicantsCardV2 } from "./NewApplicantsCardV2";
import { ApplicantTypeV2, NewApplicantWithIdV2 } from "./NewApplicantsCardV2/typings";
import { getDuplicatedEmails } from "./getDuplicatedEmails";
import { DirtyProvider } from "./useDirty";
import { FailureEmailsProvider, useFailureEmails } from "./useFailureEmails";

type Props = {
  initCoTenants: NewApplicantWithIdV2[];
  initGuarantors: NewApplicantWithIdV2[];
  haveGuarantor?: boolean;
  howManyAdults?: number;
  onBackClick?: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => void;
  onSuccess: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => void;
  isShowSubmitButton?: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => boolean;
};

function Content({
  initCoTenants,
  initGuarantors,
  haveGuarantor,
  howManyAdults,
  onBackClick = console.log,
  onSuccess,
  isShowSubmitButton = () => true,
}: Props) {
  useApplicantPageView("TP_ADD_COTENANTS_AND_GUARANTORS_VIEWED");
  const applicationMe = useFetchedApplicationMe();
  const isMobile = useIsMobile();
  const { failureEmails, setFailureEmails } = useFailureEmails();
  const applicantId = useApplicantId();
  const applicant = useFetchedApplicant();
  const [coTenants, setCoTenants] = useState<NewApplicantWithIdV2[]>(initCoTenants);
  const [guarantors, setGuarantors] = useState<NewApplicantWithIdV2[]>(initGuarantors);
  const duplicatedEmails = getDuplicatedEmails({ coTenants, guarantors });
  const logConversionEvent = useLogConversionEvent();

  const navigate = useNavigate();
  const success = useSuccess();

  const onRemoveEmail = ({ email }: { email: string }) => {
    if (email !== null && failureEmails.includes(email)) {
      setFailureEmails((prev) => {
        return produce(prev, (draft) => {
          draft.splice(draft.indexOf(email), 1);
        });
      });
    }
  };

  return (
    <>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <Link as={RouterLink} to={`/application/${applicantId}`} color="purple.600" fontSize={16} fontWeight={500}>
          &lt; Back
        </Link>
        {isMobile ? (
          <Box fontFamily="inter" fontSize="24px" fontWeight="600">
            Add co-tenants and guarantors
          </Box>
        ) : (
          <Heading variant="h3" fontSize={36} fontWeight={600} mt={2}>
            Add co-tenants and guarantors
          </Heading>
        )}
      </VStack>
      <NewApplicantsCardV2
        id="/0"
        mt={8}
        eventName="COTENANT"
        title="Co-tenants"
        addLabel={coTenants.length === 0 ? "Add tenant" : "Add another tenant"}
        numberLabel="Co-tenant"
        duplicatedEmails={duplicatedEmails}
        subTitle={
          <VStack spacing={4} width="100%" alignItems="flex-start" px={0}>
            <Box fontFamily="inter" fontSize="16px" fontWeight="500" color="#718096">
              Will you have other tenants living with you?
            </Box>
            <VStack spacing={0} width="100%" alignItems="flex-start" bgColor="purple.100" borderRadius={6}>
              <HStack px={2} pt={2}>
                <InformationFilledIcon color="purple.400" size={30} />
                <Box fontFamily="inter" fontSize="14px" fontWeight="500" color="purple.800">
                  Important
                </Box>
              </HStack>
              <Box fontFamily="inter" fontSize="14px" fontWeight="600" color="#44337AB2" p={2}>
                Important: If you have co-tenants — anyone age 18 or older who plans to live with you — each person must
                submit their own application.
              </Box>
            </VStack>
          </VStack>
        }
        values={produce(coTenants, (draft) => {
          const meIndex = draft.findIndex((e) => e.id === applicationMe.applicantById.applicantId);
          if (meIndex !== -1) {
            return [draft[meIndex], ...draft.slice(0, meIndex), ...draft.slice(meIndex + 1)];
          } else {
            return draft;
          }
        })}
        onAdd={() => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantTypeV2.TENANT,
                disabled: false,
                removable: true,
                createdAt: new Date().toISOString(),
              });
            });
          });
        }}
        onChange={(applicant, id) => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              const index = draft.findIndex((applicant) => applicant.id === id);
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(id) => {
          const index = coTenants.findIndex((applicant) => applicant.id === id);
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
          if (index < coTenants.length && coTenants[index].email !== null) {
            onRemoveEmail({ email: coTenants[index].email });
          }
        }}
      />
      <NewApplicantsCardV2
        id="/1"
        mt={8}
        eventName="GUARANTOR"
        title="Guarantors"
        addLabel={guarantors.length === 0 ? "Add guarantor" : "Add another guarantor"}
        numberLabel="Guarantor"
        duplicatedEmails={duplicatedEmails}
        subTitle={<GrayText>Do you need to add a guarantor to co-sign your application?</GrayText>}
        values={guarantors}
        onAdd={() => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantTypeV2.GUARANTOR,
                disabled: false,
                removable: true,
                createdAt: new Date().toISOString(),
              });
            });
          });
        }}
        onChange={(applicant, id) => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              const index = draft.findIndex((applicant) => applicant.id === id);
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(id) => {
          const index = guarantors.findIndex((applicant) => applicant.id === id);
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
          if (index < guarantors.length && guarantors[index].email !== null) {
            onRemoveEmail({ email: guarantors[index].email });
          }
        }}
      />
      <InviteCardV2
        id="/2"
        mt={8}
        coTenants={coTenants}
        guarantors={guarantors}
        onBackClick={() => {
          onBackClick({ coTenants, guarantors });
          // tracking back button click
          logConversionEvent({
            email: applicant.doorsteadUser.email || "",
            propertyId: applicant.applicationRequest.property?.propertyId || "",
            action: "TP_APPLICANT_INVITE_COTENANT_ADD_BACK_CLICKED",
            surface: "APPLICANT_PORTAL",
            data: {
              applicantId: applicantId,
              howManyAdults,
              haveGuarantor,
            },
          });
          navigate(`/application/${applicantId}`);
        }}
        onSuccess={() => {
          onSuccess({ coTenants, guarantors });
          // tracking continue button click
          logConversionEvent({
            email: applicant.doorsteadUser.email || "",
            propertyId: applicant.applicationRequest.property?.propertyId || "",
            action: "TP_APPLICANT_INVITE_COTENANT_SUBMITTED",
            surface: "APPLICANT_PORTAL",
            data: {
              applicantId: applicantId,
              howManyAdults,
              haveGuarantor,
              coTenants,
              guarantors,
            },
          });
          // delay 1 second to show the success toast
          setTimeout(() => {
            success({ title: "Co-applicants invited" });
          }, 1000);
        }}
        isShowSubmitButton={isShowSubmitButton}
      />
    </>
  );
}

export function AddCotenantsAndGuarantorsPageV2(props: Props) {
  let { initCoTenants, initGuarantors } = props;
  const [searchParams] = useSearchParams();
  const howManyAdults: number = parseInt(searchParams.get("total_cotenants") ?? "1", 10);
  const haveGuarantor: boolean = searchParams.get("have_guarantor") === "true";
  const fromSrc: string = searchParams.get("from") ?? "";
  const applicantId = useApplicantId();
  const applicant = useFetchedApplicant();
  if (fromSrc === "get_started") {
    initCoTenants = produce<NewApplicantWithIdV2[]>([], (draft) => {
      if (howManyAdults !== null) {
        for (let i = 1; i <= howManyAdults; i++) {
          if (i === 1) {
            draft.push({
              id: applicantId,
              type: ApplicantTypeV2.TENANT,
              email: applicant.doorsteadUser.email,
              firstName: applicant.doorsteadUser.firstName,
              lastName: applicant.doorsteadUser.lastName,
              disabled: true,
              removable: false,
              createdAt: applicant.createdAt,
            });
          } else {
            draft.push({
              id: getUuid(),
              type: ApplicantTypeV2.TENANT,
              email: "",
              firstName: "",
              lastName: "",
              disabled: false,
              removable: true,
              createdAt: new Date().toISOString(),
            });
          }
        }
      }
    });
    initGuarantors = produce<NewApplicantWithIdV2[]>([], (draft) => {
      if (haveGuarantor) {
        draft.push({
          id: getUuid(),
          type: ApplicantTypeV2.GUARANTOR,
          email: "",
          firstName: "",
          lastName: "",
          disabled: false,
          removable: true,
          createdAt: new Date().toISOString(),
        });
      }
    });
  }
  return (
    <FailureEmailsProvider>
      <DirtyProvider>
        <Content
          {...props}
          initCoTenants={initCoTenants}
          initGuarantors={initGuarantors}
          haveGuarantor={haveGuarantor}
          howManyAdults={howManyAdults}
        />
      </DirtyProvider>
    </FailureEmailsProvider>
  );
}
