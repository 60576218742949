export const query = `
  query ($flexWalkthroughReportId: UUID!) {
    flexWalkthroughReport(flexWalkthroughReportId: $flexWalkthroughReportId) {
      flexWalkthroughReportId
      propertyId
      propertyAddress
      startTime
      submittedTime
      propertyIsPhotoReady
      propertyFurnishedType
      propertyOverviewPhotoUrlsJson
      propertyOverviewNotes
      smokeDetectorPhotoUrlsJson
      smokeDetectorIsAtLeastOneInsideEachBedroom
      smokeDetectorIsAtLeastOneInsideEachBedroomNotes
      smokeDetectorIsAtLeastOneOutsideOfBedrooms
      smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes
      smokeDetectorIsAtLeastOneEachFloor
      smokeDetectorIsAtLeastOneEachFloorNotes
      coDetectorPhotoUrlsJson
      coDetectorIsAtLeastOneOutsideOfBedrooms
      coDetectorIsAtLeastOneOutsideOfBedroomsNotes
      coDetectorIsAtLeastOneEachFloor
      coDetectorIsAtLeastOneEachFloorNotes
      createdAt
      updatedAt
      items {
        itemDataId
        itemText
        category
        section
        tooltip
        response
        photoUrlsJson
        notes
        positiveActionText
        negativeActionText
        itemConfigUpdatedAt
        itemUpdatedAt
        order
        photosRequired
        notesRequired
      }
    }
  }
`;
