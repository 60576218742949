import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../../../../contexts/auth";
import { Result, queryKey } from "../../../../../hooks/applicants/useFetchApplicationMe";
import { useError } from "../../../../../hooks/useError";
import { useSuccess } from "../../../../../hooks/useSuccess";

export function useRemoveFile() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();
  const success = useSuccess();
  const error = useError();

  return useMutation(
    async ({ applicantFileId }: { applicantFileId: string }) => {
      try {
        return await gqlClient.mutate({
          mutation: gql`
            mutation ($applicantFileId: UUID) {
              deleteApplicantFile(applicantFileId: $applicantFileId) {
                result
              }
            }
          `,
          variables: {
            applicantFileId,
          },
        });
      } catch (e) {
        error({
          title: "Error",
          description: "Oops, something wrong happens. Please try another file.",
        });
      }
    },
    {
      onSuccess(ignored, { applicantFileId }) {
        queryClient.setQueryData(
          queryKey,
          produce(queryClient.getQueryData(queryKey), (draft: Result) => {
            draft.applicantById.files.edges.forEach((file, fileIndex) => {
              if (file.node.applicantFileId === applicantFileId) {
                draft.applicantById.files.edges.splice(fileIndex, 1);
              }
            });
          })
        );
        success({ title: "File removed" });
      },
    }
  );
}
