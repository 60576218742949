import { SelectField } from "@doorstead/components";
import { usePaymentOptions } from "../../hooks/usePaymentMethods";

export type PaymentMethod = {
  value: string;
  name: string;
};

export type Props = {
  id: string;
  label: string;
  value: PaymentMethod["value"];
  onChange?: (value: PaymentMethod["value"]) => void;
  disabled?: boolean;
};

export function PaymentMethodField({ id, label, value, onChange, disabled = false }: Props) {
  const { values } = usePaymentOptions();
  return (
    <SelectField
      disabled={disabled}
      id={id}
      label={label}
      options={values}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
}
