import { Flex } from "@chakra-ui/react";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Item } from "../../../../@types/RentReady/Item";
import { getSpecifyingComponentsTextConfig } from "../../../../utils/rentReady/getSpecifyingComponentsTextConfig";
import { RequiredLabel } from "../../RequiredLabel";

type Props = {
  isAreaFullPaint: boolean;
  item: Item;
  isOpen: boolean;
};

export function SpecifyWhichComponentsToFixText({ item, isOpen, isAreaFullPaint }: Props) {
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();
  const { color, show } = getSpecifyingComponentsTextConfig({
    isOpen,
    item,
    isAreaFullPaint,
    shouldUseSimplifiedTemplate,
  });
  if (!show) return null;

  return (
    <Flex w="100%" justifyContent="flex-start" pl={7}>
      <RequiredLabel hasValue={color === "black"}>Specify which component(s) to fix</RequiredLabel>
    </Flex>
  );
}
