import { Area } from "../../@types/RentReady/Area";
import { isNoteValid } from "./isNoteValid";
import { isSmokeDetectorsPhotoValid } from "./isSmokeDetectorsPhotoValid";

type Args = {
  smokeDetectorNotes: Area["smokeDetectorNotes"];
  smokeDetectorStatus: Area["smokeDetectorStatus"];
  smokeDetectorPhotos: Area["smokeDetectorPhotos"];
};

export function isSmokeDetectorComplete({ smokeDetectorNotes, smokeDetectorStatus, smokeDetectorPhotos }: Args) {
  return (
    smokeDetectorStatus !== null &&
    isSmokeDetectorsPhotoValid(smokeDetectorStatus, smokeDetectorPhotos) &&
    isNoteValid(smokeDetectorStatus, smokeDetectorNotes)
  );
}
