import { useFetchedMe } from "../../hooks/useFetchedMe";
import { getStepIndex } from "./getStepIndex";
import { Step } from "./typings";
import { useGetStep } from "./useGetStep";
import { useSteps } from "./useSteps";

export function useStep() {
  const steps = useSteps();
  const me = useFetchedMe();
  const getStep = useGetStep();
  const step = getStep(getStepIndex(steps, me.tenant.onboardingStage as Step));

  return {
    step,
    isDone: step === Step.DONE,
  };
}
