import { sortByField } from "../../utils/rentReady/sortByField";
import { useFetchAreaItemOptions } from "./useFetchAreaItemOptions";

export function useItemOptions() {
  const { isLoading, data } = useFetchAreaItemOptions();
  if (isLoading || !data) {
    return [];
  } else {
    return sortByField(data.inspectionReportItemOptionsByAreaId, "name");
  }
}
