import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { IdIcon } from "@doorstead/components";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { Status, StatusIcon } from "@doorstead/components/icons/StatusIcon";
import Persona from "persona";
import React, { useCallback } from "react";
import { ApplicantDateField } from "src/components/applicants/ApplicantDateField";
import { ApplicantTextField } from "src/components/applicants/ApplicantTextField";
import { personaEnvironmentId } from "src/config";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useIsApplicantEditable } from "src/hooks/applicants/useIsApplicantEditable";
import { useUpdateApplicantFromPersona } from "src/hooks/applicants/useUpdateApplicantFromPersona";
import { useUpdateDoorsteadUserField } from "src/hooks/applicants/useUpdateDoorsteadUserField";
import { formatSSN, isValidSSN } from "src/utils/isValidSSN";
import { CardTitle } from "../../../../CardTitle";
import { Labels } from "../../../labels";
import { getBirthdayFromYear } from "../BackgroundCheckCard/getBirthdayFromYear";
import { getBirthdayToYear } from "../BackgroundCheckCard/getBirthdayToYear";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
  onContinue: () => void;
};

export const IdentityVerificationCard = React.forwardRef(({ padding, width, onContinue, ...props }: Props, ref) => {
  const applicantAnalytics = useApplicantAnalytics();
  const isMobile = useIsMobile();
  const {
    doorsteadUser: { doorsteadUserId, dayOfBirthTemp, ssnTemp },
    personaInquiryId,
  } = useFetchedApplicant()!;

  const disabled = !useIsApplicantEditable();
  const { mutate: updateApplicantFromPersona, isLoading: updateApplicantFromPersonaLoading } =
    useUpdateApplicantFromPersona();
  const isPersonaCompleted = personaInquiryId !== null;
  const { mutate: updateDoorsteadUserField } = useUpdateDoorsteadUserField();

  const createPersonaClient = useCallback(() => {
    return new Persona.Client({
      templateId: "itmpl_1CbvGw4eY6MDnsDcWMYF2iLk",
      environmentId: personaEnvironmentId(),
      referenceId: doorsteadUserId,
      onLoad: () => {
        applicantAnalytics?.track("TP_PERSONA_ON_LOAD");
      },
      onReady: () => {
        applicantAnalytics?.track("TP_PERSONA_ON_READY");
      },
      onComplete: ({ inquiryId, status, fields }) => {
        console.log("Persona onComplete", { inquiryId, status, fields });
        updateApplicantFromPersona({ inquiryId });
        applicantAnalytics?.track("TP_PERSONA_ON_COMPLETE", { inquiryId, status });
      },
      onCancel: ({ inquiryId }) => {
        console.log("Persona onCancel", { inquiryId });
        applicantAnalytics?.track("TP_PERSONA_ON_CANCEL", { inquiryId });
      },
      onError: (error) => {
        console.log("Persona onError", error);
        applicantAnalytics?.track("TP_PERSONA_ON_ERROR", { error });
      },
    });
  }, [doorsteadUserId]);

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Identity verification</CardTitle>
        <GrayText>Upload a valid identification card such as a driver&apos;s license or passport.</GrayText>
        <Flex
          w="100%"
          bgColor="gray.50"
          color="gray.800"
          lineHeight="24px"
          padding={5}
          borderRadius="6px"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack spacing={3}>
            <IdIcon />
            <Box>Valid ID card</Box>
          </HStack>
          {isPersonaCompleted ? (
            <StatusIcon status={Status.Correct} />
          ) : (
            <PrimaryButton
              isLoading={updateApplicantFromPersonaLoading}
              isFullWidth={isMobile}
              onClick={() => {
                applicantAnalytics?.track("TP_PHOTO_ID_CARD_UPLOAD_CLICKED");
                createPersonaClient().open();
              }}
            >
              Start verification
            </PrimaryButton>
          )}
        </Flex>

        {isPersonaCompleted && (
          <>
            <GrayText>Please enter and verify the following information:</GrayText>
            <Box w="100%">
              <ApplicantDateField
                id="birthday"
                label={Labels.Birthday}
                disabled={disabled}
                value={dayOfBirthTemp}
                fromYear={getBirthdayFromYear()}
                toYear={getBirthdayToYear()}
                onChange={(dayOfBirthTemp) => {
                  updateDoorsteadUserField({
                    dayOfBirthTemp,
                  });
                }}
              />
            </Box>
            <ApplicantTextField
              id="ssn"
              label={Labels.SSN}
              disabled={disabled}
              defaultValue={ssnTemp}
              getStatus={(ssn) => {
                const sanitizedSSN = ssn.replace(/\D/g, "");
                return !sanitizedSSN ? Status.Default : isValidSSN(sanitizedSSN) ? Status.Correct : Status.Error;
              }}
              validator={(ssn) => {
                const sanitizedSSN = ssn.replace(/\D/g, "");
                return isValidSSN(sanitizedSSN);
              }}
              transform={formatSSN}
              onSyncToServer={async (ssn) => {
                const sanitizedSSN = ssn.replace(/\D/g, "");
                updateDoorsteadUserField({
                  ssnTemp: sanitizedSSN,
                });
              }}
            />
          </>
        )}
        <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
          Continue
        </PrimaryButton>
      </VStack>
    </Card>
  );
});
