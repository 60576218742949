export type ErrorCode = {
  code: number;
  title: string;
  subTitle: string;
};

export const noPage: ErrorCode = {
  code: 10001,
  title: "Page not found",
  subTitle: "Sorry, we can’t find the page you’re looking for. (10001 error)",
};

export const noRecord: ErrorCode = {
  code: 10002,
  title: "Page not available",
  subTitle: "Sorry, we can’t load the page you’re looking for. (10002 error)",
};

export const noMeRecord: ErrorCode = {
  code: 10003,
  title: "Account not found",
  subTitle: "Sorry, we were unable to find this account. (10003 error)",
};

export const noTenant: ErrorCode = {
  code: 10004,
  title: "Account not found",
  subTitle: "Sorry, we were unable to find this account. (10004 error)",
};

export const noLease: ErrorCode = {
  code: 10005,
  title: "Lease information not found",
  subTitle: "Sorry, we couldn’t find lease information associated with this account. (10005 error)",
};

export const noTenantGroup: ErrorCode = {
  code: 10006,
  title: "Lease information not found",
  subTitle: "Sorry, we couldn’t find lease information associated with this account. (10006 error)",
};

export const unexpectedError: ErrorCode = {
  code: 10007,
  title: "Unexpected error",
  subTitle: "Sorry, an unexpected error has occurred. (10007 error)",
};

export const noTenantGroupProperty: ErrorCode = {
  code: 10008,
  title: "Lease's property information not found",
  subTitle: "Sorry, we couldn’t find lease property information associated with this account. (10008 error)",
};
