import { writeFlexWalkthroughGraphQLResult } from "../../databases/FlexWalkthroughDatabase";
import { fetchFlexWalkthroughGraphQLResultServer } from "./useFetchFlexWalkthroughReport/fetchFlexWalkthroughGraphQLResultServer";
import { FlexWalkthroughGraphQLResult } from "./useFetchFlexWalkthroughReport/useFetchFlexWalkthroughReport";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

export function useSyncServerToLocalDb() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();
  return async () => {
    const res: FlexWalkthroughGraphQLResult = await fetchFlexWalkthroughGraphQLResultServer(flexWalkthroughReportId);
    await writeFlexWalkthroughGraphQLResult(flexWalkthroughReportId, {
      ...res,
      flex_walkthrough_report_id: flexWalkthroughReportId,
    });
  };
}
