import React from "react";

export type Value = string;

export const context = React.createContext<Value>("");

export const Provider = context.Provider;

export function useGoogleMapKey() {
  return React.useContext(context);
}
