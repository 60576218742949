import { Item } from "../../../@types/RentReady/Item";
import { mapPhotoUrlsJsonToPhotos } from "../../../utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { ItemNode } from "../useFetchInspectionReport/ItemNode";
import { normalizeItems } from "../useItems";
import { mapComponentNodeToComponent } from "./mapComponentNodeToComponent";
import { mapCosmeticOptions } from "./mapCosmeticOptions";

export function mapItemNodesToItems(itemNodes: ItemNode[] | null): Item[] {
  if (itemNodes === null) return [];

  return normalizeItems(
    itemNodes
      .filter(({ itemShouldRender }) => itemShouldRender)
      .map((itemNode) => {
        const item: Item = {
          id: itemNode.itemDataId,
          uiId: itemNode.itemDataId,
          name: itemNode.itemName,
          cosmeticDefect: itemNode.itemCosmeticDefect || "",
          cosmeticOptions: mapCosmeticOptions(itemNode),
          selectionType: itemNode.itemCosmeticOptionSelectionType === "Single-select" ? "single" : "multiple",
          photos: mapPhotoUrlsJsonToPhotos(itemNode.itemPhotoUrlsJson),
          note: itemNode.itemNotes || "",
          components:
            itemNode.componentsData === null
              ? []
              : itemNode.componentsData.filter((e) => e.componentShouldRender).map(mapComponentNodeToComponent),
          isPresent: itemNode.itemIsItemPresent,
          createDate: itemNode.itemDataCreatedAt === null ? new Date() : new Date(itemNode.itemDataCreatedAt),
          updatedDate: itemNode.itemDataUpdatedAt,
          status: itemNode.itemDataStatus,
          isCore: itemNode.itemCategory === "Core",
          isMissingNeedsEstimate: itemNode.itemIsMissingNeedsEstimate || false,
          uiName: "",
        };
        return item;
      })
  );
}
