export function getUserName({ firstName, lastName }: { firstName?: string | null; lastName?: string | null }): string {
  if (!firstName && !lastName) {
    return "";
  } else if (firstName && !lastName) {
    return firstName || "";
  } else if (!firstName && lastName) {
    return lastName || "";
  }

  return (firstName + " " + lastName).trim();
}
