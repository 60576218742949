export function isToPriceInvalid({
  isRange,
  fromPrice,
  toPrice,
}: {
  isRange: boolean;
  fromPrice: number;
  toPrice: number;
}): boolean {
  if (!isRange) return false;
  return toPrice < fromPrice;
}
