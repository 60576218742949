import { gql } from "@apollo/client";
import React from "react";
import { useAuth } from "../contexts/auth";

const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation ($doorsteadUserId: UUID!) {
    createStripeSetupIntent(doorsteadUserId: $doorsteadUserId) {
      setupIntentId
      clientSecret
    }
  }
`;

type CreateStripeSetupIntentType = {
  setupIntentId: string;
  clientSecret: string;
};

type ReturnType = {
  createStripeSetupIntent: (doorsteadUserId: string) => Promise<CreateStripeSetupIntentType>;
  isOpening: boolean;
};

export function useCreateStripeSetupIntent(): ReturnType {
  const [isOpening, setIsOpening] = React.useState(false);

  const { gqlClient } = useAuth();

  const createStripeSetupIntent = async (doorsteadUserId: string): Promise<CreateStripeSetupIntentType> => {
    setIsOpening(true);
    const res = await gqlClient.mutate({
      mutation: CREATE_STRIPE_SETUP_INTENT,
      variables: {
        doorsteadUserId,
      },
    });
    setIsOpening(false);
    return res.data?.createStripeSetupIntent;
  };

  return {
    createStripeSetupIntent,
    isOpening,
  };
}
