import { Flex, HStack, VStack } from "@chakra-ui/react";
import { TextArea } from "@doorstead/components";
import { KeyStorage } from "../../../../../@types/RentReady/KeyStorage";
import { useIsReadOnly } from "../../../../../hooks/rentReady/useIsReadOnly";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { RequiredLabel } from "../../../RequiredLabel";
import { PhotosField } from "../../../fields/PhotosField";

type Props = {
  value: KeyStorage["lockboxLocation"];
  onChange: (field: keyof KeyStorage["lockboxLocation"], value: any) => void;
};

export function LockboxLocationBox({ value, onChange }: Props) {
  const isReadOnly = useIsReadOnly();

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack spacing={1}>
        <RequiredLabel hasValue={value.location.trim() !== "" && value.photos.length !== 0}>
          Lockbox Location
        </RequiredLabel>
      </HStack>
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <PhotosField
          prefix={Prefix.LockBoxLocation}
          values={value.photos}
          label=""
          onChange={(value) => onChange("photos", value)}
        />
      </Flex>
      <Flex w="100%">
        <TextArea value={value.location} disabled={isReadOnly} onChange={(value) => onChange("location", value)} />
      </Flex>
    </VStack>
  );
}
