import { Flex } from "@chakra-ui/react";

type Props = {
  children: string;
};

export function DueDateBox({ children }: Props) {
  return (
    <Flex w="100%" justifyContent="flex-end" fontWeight="normal" fontSize="sm" color="gray.700">
      Due {children}
    </Flex>
  );
}
