import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { Switch } from "@doorstead/components";
import produce from "immer";
import { Estimate } from "../../../../@types/RentReady/Estimate";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { TextAreaField } from "../../fields/TextAreaField";
import { WholeDollarField } from "../../fields/WholeDollarField";
import { isFromPriceInvalid } from "./isFromPriceInvalid";
import { isToPriceInvalid } from "./isToPriceInvalid";

type Props = {
  estimate: Estimate;
  onChange: (estimate: Estimate) => void;
};

export function AddEstimatesBox({ estimate, onChange }: Props) {
  const readOnly = useIsReadOnly();
  const { isRange, fromPrice, toPrice, note } = estimate;

  return (
    <VStack alignItems="flex-start" w="100%">
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Box fontWeight="bold">Estimate</Box>
        <HStack alignItems="center" spacing={3}>
          <Box>Range </Box>
          <Switch
            value={isRange}
            disabled={readOnly}
            onChange={(isRange) => {
              onChange(
                produce(estimate, (draft) => {
                  draft.isRange = isRange;
                })
              );
            }}
          />
        </HStack>
      </Flex>
      <HStack spacing={5} w="100%" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <WholeDollarField
            value={fromPrice}
            disabled={readOnly}
            isInvalid={isFromPriceInvalid(estimate)}
            onChange={(value) => {
              onChange(
                produce(estimate, (draft) => {
                  draft.fromPrice = value;
                })
              );
            }}
          />
        </Box>
        {isRange && (
          <>
            <Box>-</Box>
            <Box flex={1}>
              <WholeDollarField
                disabled={readOnly}
                value={toPrice}
                isInvalid={isToPriceInvalid(estimate)}
                onChange={(value) => {
                  onChange(
                    produce(estimate, (draft) => {
                      draft.toPrice = value;
                    })
                  );
                }}
              />
            </Box>
          </>
        )}
      </HStack>
      <Box fontWeight="bold">Estimation notes</Box>
      <TextAreaField
        label=""
        value={note}
        onChange={(value) => {
          onChange(
            produce(estimate, (draft) => {
              draft.note = value;
            })
          );
        }}
        disabled={readOnly}
      />
    </VStack>
  );
}
