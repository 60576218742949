import { PrimaryButton } from "@doorstead/components/buttons";
import { Decision, Estimate } from "../../../../@types/RentReady/Estimate";
import { CannotEstimateReason } from "../../../../hooks/rentReady/useAreasCache/mapEstimateNodesToEstimates";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { useUpdateInspectionReportEstimatesData } from "../../../../hooks/rentReady/useUpdateInspectionReportEstimatesData";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useSuccess } from "../../../../hooks/useSuccess";
import { getCents } from "../../../../utils/getCents";
import { isSaveButtonEnabled } from "./isSaveButtonEnabled";

type Props = {
  initialEstimate: Estimate;
  estimate: Estimate;
};

function getEstimateReason(estimate: Estimate): string {
  if (estimate.isOutOfScope) {
    return CannotEstimateReason.OutOfScope;
  } else if (estimate.isToBeDetermined) {
    return CannotEstimateReason.ToBeDetermined;
  }
  return "";
}

export function SaveButton({ initialEstimate, estimate }: Props) {
  const success = useSuccess();
  const { setView } = useView();
  const isReadOnly = useIsReadOnly();
  const { mutate, isLoading } = useUpdateInspectionReportEstimatesData();
  const disabled = !isSaveButtonEnabled({ initialEstimate, estimate, isReadOnly, isLoading });

  return (
    <PrimaryButton
      isFullWidth
      isLoading={isLoading}
      disabled={disabled}
      onClick={() => {
        if (estimate.decision === Decision.AddEstimates) {
          mutate(
            {
              cannotEstimate: false,
              cannotEstimateReason: "",
              estimateNotes: estimate.note,
              estimatesDataId: estimate.id,
              lowEstimateCostCents: getCents(estimate.fromPrice),
              highEstimateCostCents: estimate.isRange ? getCents(estimate.toPrice) : getCents(estimate.fromPrice),
            },
            {
              onSuccess() {
                success({ title: "Estimate updated" });
                setView(View.EstimateListView);
              },
            }
          );
        } else if (estimate.decision === Decision.CanNotEstimate) {
          mutate(
            {
              cannotEstimate: true,
              estimateNotes: estimate.note,
              cannotEstimateReason: getEstimateReason(estimate),
              estimatesDataId: estimate.id,
              lowEstimateCostCents: 0,
              highEstimateCostCents: 0,
            },
            {
              onSuccess() {
                success({ title: "Estimate updated" });
                setView(View.EstimateListView);
              },
            }
          );
        }
      }}
    >
      Save
    </PrimaryButton>
  );
}
