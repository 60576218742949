import { Box, Flex, VStack } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useRef, useState } from "react";
import { getValue } from "src/@types/AutoPay/getValue";
import { SetupAutopayBox } from "src/components/SetupAutopayBox";
import { PAY_ACCOUNT_BALANCE_AMOUNT } from "src/components/SetupAutopayBox/SetupAutopayBox";
import { Action } from "src/components/evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { useAutopayMessage } from "src/hooks/useAutopayMessage";
import { useHasAgreedAutopayDisclaimer } from "src/hooks/useHasAgreedAutopayDisclaimer";
import { usePageView } from "src/hooks/usePageView";
import { AutoPayDate } from "../../../../@types/AutoPay/typings";
import { useNextStep } from "../../../../contexts/StepContext/useNextStep";
import { useCreateAutoPay } from "../../../../hooks/useCreateAutoPay";
import { useDefaultPaymentMethodId } from "../../../../hooks/useDefaultPaymentMethodId";
import { useIsAutoPaySetup } from "../../../../hooks/useIsAutoPaySetup";
import { AutopayDisclaimerText } from "../../../AutopayDisclaimerText";
import { Banner } from "./Banner";

export function AutoPayStep() {
  const analytics = usePageView("TP_OB_AUTOPAY_VIEWED");
  const [payOnDate, setPayOnDate] = useState<AutoPayDate | "">(AutoPayDate.TWENTY_EIGHTH);
  const isMobile = useIsMobile();
  const { nextStep, isLoading: isStepLoading } = useNextStep();
  const { createAutoPay, isLoading: isCreatingAutoPay } = useCreateAutoPay();
  const isAutoPaySetup = useIsAutoPaySetup();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string>(defaultPaymentMethodId || "");
  const autopayMessage = useAutopayMessage();
  const disclaimerRef = useRef<HTMLAnchorElement>(null);
  const hasAgreedAutopayDisclaimer = useHasAgreedAutopayDisclaimer();
  const [continueToSaving, setContinueToSaving] = useState(false);

  const saveAutopay = async () => {
    await createAutoPay(
      {
        amount: PAY_ACCOUNT_BALANCE_AMOUNT,
        paymentMethodId,
        date: getValue(payOnDate as AutoPayDate),
      },
      {
        async onSuccess() {
          analytics?.track("TP_OB_AUTOPAY_ADDED", {
            paymentMethodId,
          });
          autopayMessage(Action.Create);
          await nextStep();
        },
        onError() {
          analytics?.track("TP_OB_AUTOPAY_FAILED", {
            paymentMethodId,
          });
          autopayMessage(Action.Create, true);
        },
      }
    );
  };

  return (
    <>
      <Box w="100%" h="100%">
        <Banner />
        <Box w="100%" bgColor="white" padding={8} borderBottomRadius={20}>
          {isAutoPaySetup ? (
            <PrimaryButton
              isLoading={isStepLoading}
              disabled={isStepLoading}
              onClick={() => {
                analytics?.track("TP_OB_AUTOPAY_CONTINUED");
                nextStep();
              }}
            >
              Continue
            </PrimaryButton>
          ) : (
            <>
              <Box color="gray.700" fontSize="2xl" fontWeight="medium">
                Autopay details
              </Box>
              <VStack spacing={5} mt={8} alignItems="flex-start">
                <SetupAutopayBox
                  paymentMethodId={paymentMethodId}
                  setPaymentMethodId={setPaymentMethodId}
                  payOn={payOnDate}
                  setPayOn={setPayOnDate}
                />
                <Flex w="100%" justifyContent="space-between" gap={3} flexWrap="wrap">
                  <PrimaryButton
                    isFullWidth={isMobile}
                    disabled={payOnDate === "" || !paymentMethodId || isCreatingAutoPay || isStepLoading}
                    isLoading={isCreatingAutoPay || isStepLoading}
                    onClick={async () => {
                      if (!hasAgreedAutopayDisclaimer) {
                        disclaimerRef.current?.click();
                        setContinueToSaving(true);
                        return;
                      }

                      await saveAutopay();
                    }}
                  >
                    Save & continue
                  </PrimaryButton>
                  <OutlineButton
                    disabled={isStepLoading}
                    isLoading={isStepLoading}
                    isFullWidth={isMobile}
                    onClick={() => {
                      analytics?.track("TP_OB_AUTOPAY_SKIPPED");
                      nextStep();
                    }}
                  >
                    Skip for now
                  </OutlineButton>
                </Flex>
                <Flex w="100%" justifyContent="space-between" gap={3} flexWrap="wrap">
                  <AutopayDisclaimerText
                    ref={disclaimerRef}
                    onClick={() => setContinueToSaving(false)}
                    onAgree={() => continueToSaving && saveAutopay()}
                  />
                </Flex>
              </VStack>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
