import { useLiveQuery } from "dexie-react-hooks";
import { RRItemOpen, db } from "../../databases/RentReadyDatabase";

export const queryKey = "useRRItemOpenCache";

let prevData: RRItemOpen[] | null = null;
function useCallLocalDatabase(item_data_ids: string[]) {
  return useLiveQuery((): Promise<RRItemOpen[] | null | undefined> => {
    return db.rr_item_open.where("item_data_id").anyOf(item_data_ids).toArray();
  });
}

export function useRRItemsOpenCache(item_data_ids: string[]) {
  const data = useCallLocalDatabase(item_data_ids);

  if (data === undefined) {
    return prevData;
  } else {
    prevData = data || null;
    return prevData;
  }
}
