import { db } from "src/databases/FlexWalkthroughDatabase";

export function clearCache() {
  if (
    window.confirm(
      "Are you certain that you want to clear all of your data? This action will result in the removal of any non-uploaded photos you have."
    )
  ) {
    db.flex_walkthrough_report_graphql_result.clear();
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName).then(console.log);
        });
      });
    }
    window.location.reload();
  }
}
