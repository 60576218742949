import { ReactNode, createContext, useContext } from "react";

export type Props = {
  index: number;
  setIndex: (index: number) => void;
};

const context = createContext<Props>({
  index: 0,
  setIndex() {
    console.log("setIndex");
  },
});

export function Provider({ children, value }: { children: ReactNode; value: Props }) {
  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useGalleryIndex() {
  return useContext(context);
}
