import { PlaidLinkOnSuccessMetadata, PlaidLinkOptions } from "react-plaid-link";
import { useGroupAnalytics } from "./useGroupAnalytics";

export function useOpenPlaidTrustWindow() {
  const analytics = useGroupAnalytics();

  return (
    token: string,
    onExit: () => void
  ): Promise<{ publicToken: string; metadata: PlaidLinkOnSuccessMetadata }> => {
    return new Promise((resolve, reject) => {
      const config: PlaidLinkOptions = {
        token,
        onSuccess(publicToken, metadata) {
          resolve({
            publicToken,
            metadata,
          });
        },
        onExit(err, metadata) {
          console.log("onExit", err, metadata);
          onExit();
          if (err !== null) {
            reject({ err, metadata });
          }
        },
        onEvent(eventName, metadata) {
          console.log("onEvent", eventName, metadata);
          analytics?.track(
            `TP_PLAID_EVENT_${eventName.toUpperCase()}`,
            { eventName, metadata },
            // The onEvent callback is not guaranteed to fire exactly at the time of a
            // user action in Link, so we pass the timestamp from the metadata object
            // to ensure that the event is attributed to the correct time.
            // https://plaid.com/docs/link/web/#onevent
            { timestamp: metadata.timestamp }
          );
        },
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      window.Plaid.create(config).open();
    });
  };
}
