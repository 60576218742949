import { Flex, VStack } from "@chakra-ui/react";
import { moneyString, useIsMobile } from "@doorstead/components";
import { Title } from "./Title";

type Props = {
  title: string;
  money: number;
  dueDate: string;
  isShowDueDate?: boolean;
};

export function MoneyBox({ title, money, dueDate, isShowDueDate = true }: Props) {
  const isMobile = useIsMobile();

  return (
    <VStack w="100%" spacing={2}>
      <Flex justifyContent="space-between" w="100%">
        <Title>{title}</Title>
        <Title>{moneyString(money)}</Title>
      </Flex>
      {isShowDueDate && (
        <Flex w="100%" color="gray.700" fontSize="md" justifyContent={isMobile ? "flex-start" : "flex-end"}>
          Due {dueDate}
        </Flex>
      )}
    </VStack>
  );
}
