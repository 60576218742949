import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { getSpecifyingComponentsTextConfig } from "./getSpecifyingComponentsTextConfig";
import { isShowItemRequiredPhotosAndOptionalNotesAndHideComponents } from "./isShowItemRequiredPhotosAndOptionalNotesAndHideComponents";

export function isV2ItemComplete({
  item,
  isAreaFullPaint,
  shouldUseSimplifiedTemplate,
}: {
  item: Item;
  isAreaFullPaint: boolean;
  shouldUseSimplifiedTemplate: boolean;
}): boolean {
  switch (item.status) {
    case GoodMissingFixStatus.Good:
      return true;
    case GoodMissingFixStatus.Missing:
    case GoodMissingFixStatus.Fix: {
      if (
        isShowItemRequiredPhotosAndOptionalNotesAndHideComponents({
          ...item,
          shouldUseSimplifiedTemplate,
        }) &&
        item.photos.length === 0
      ) {
        return false;
      }
      const { show, color } = getSpecifyingComponentsTextConfig({
        item,
        isAreaFullPaint,
        isOpen: true,
        shouldUseSimplifiedTemplate,
      });
      return !(show && color === "red");
    }
    case null:
      return false;
  }
}
