import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { mapInspectionReportNodeToThroughoutPropertyDetector } from "../../utils/rentReady/mapInspectionReportNodeToThroughoutPropertyDetector";
import { useInspectionReportCache } from "./useInspectionReportCache";

export function useThroughoutPropertyDetectorCache(): ThroughoutPropertyDetector | null {
  const inspectionReportCache = useInspectionReportCache();

  if (inspectionReportCache === null) return null;

  return mapInspectionReportNodeToThroughoutPropertyDetector(inspectionReportCache);
}
