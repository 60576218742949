import Compressor from "compressorjs";

export function compressImageFile(file: File, quality = 0.3): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      success: (result) => {
        resolve(result as File);
      },
      error: (err) => {
        reject(err);
      },
    });
  });
}
