import { gql } from "@apollo/client";
import { useAuth } from "../contexts/auth";
import { useFetchedMe } from "./useFetchedMe";
import { useSuccess } from "./useSuccess";

const CREATE_ACH_PAYMENT_METHOD_FROM_PLAID = gql`
  mutation ($doorsteadUserId: UUID!, $publicToken: String!, $accountId: String!) {
    createAchPaymentMethodFromPlaid(
      doorsteadUserId: $doorsteadUserId
      publicToken: $publicToken
      accountId: $accountId
    ) {
      doorsteadUser {
        paymentMethods {
          edges {
            node {
              paymentMethodId
              type_
              isDefault
              bankAccountMask
              bankAccountName
            }
          }
        }
      }
    }
  }
`;

export function useCreateACHPaymentMethodFromPlaid() {
  const { gqlClient } = useAuth();
  const me = useFetchedMe();
  const success = useSuccess();

  return async ({ publicToken, accountId }: { publicToken: string; accountId: string }) => {
    await gqlClient.mutate({
      mutation: CREATE_ACH_PAYMENT_METHOD_FROM_PLAID,
      variables: {
        doorsteadUserId: me.doorsteadUserId,
        publicToken,
        accountId,
      },
    });
    success({
      title: "Payment method added!",
    });
  };
}
