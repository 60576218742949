import produce from "immer";
import { useMutation } from "react-query";
import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { useInspectionReportId } from "./useInspectionReportId";
import { useUpdateThroughoutPropertyDetectorCache } from "./useUpdateThroughoutPropertyDetectorCache";
import {
  useMapToArgs,
  useUpdateThroughoutPropertyDetectorToServer,
} from "./useUpdateThroughoutPropertyDetectorToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(throughoutPropertyDetector: ThroughoutPropertyDetector): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  throughoutPropertyDetector.detectorCmPhotos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.CarbonMonoxideDetectors;
    photos.push(photo as PhotoWithPrefix);
  });
  throughoutPropertyDetector.detectorSmokePhotos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.SmokeDetector;
    photos.push(photo as PhotoWithPrefix);
  });
  return photos;
}

function useGetUploadedThroughoutPropertyDetector() {
  const upload = useUpload();

  return async (throughoutPropertyDetector: ThroughoutPropertyDetector): Promise<ThroughoutPropertyDetector> => {
    return produce(throughoutPropertyDetector, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveThroughoutPropertyDetectorSubPage() {
  const updateThroughoutPropertyDetectorCache = useUpdateThroughoutPropertyDetectorCache();
  const updateThroughoutPropertyDetectorToServer = useUpdateThroughoutPropertyDetectorToServer();
  const mapToArgs = useMapToArgs();
  const inspectionReportId = useInspectionReportId();
  const getUploadedThroughoutPropertyDetector = useGetUploadedThroughoutPropertyDetector();

  return useMutation(async (throughoutPropertyDetector: ThroughoutPropertyDetector) => {
    await updateThroughoutPropertyDetectorCache(throughoutPropertyDetector);

    if (isOnline()) {
      const uploadedThroughoutPropertyDetector = await getUploadedThroughoutPropertyDetector(
        throughoutPropertyDetector
      );
      const {
        data: {
          updateInspectionReportThroughoutPropertyDetector: {
            success,
            merged,
            throughoutPropertyDetector: returnedThroughoutPropertyDetector,
          },
        },
      } = await updateThroughoutPropertyDetectorToServer(mapToArgs(uploadedThroughoutPropertyDetector));
      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          //save back to cache
          const newCache = produce(cache, (draft) => {
            if (draft !== null) {
              draft.inspectionReport.detectorCmIsAtLeastOneCoEachFloor =
                returnedThroughoutPropertyDetector.detectorCmIsAtLeastOneCoEachFloor;
              draft.inspectionReport.detectorCmIsAtLeastOneCoOutsideBedrooms =
                returnedThroughoutPropertyDetector.detectorCmIsAtLeastOneCoOutsideBedrooms;
              draft.inspectionReport.detectorCmPhotoUrlsJson =
                returnedThroughoutPropertyDetector.detectorCmPhotoUrlsJson;
              draft.inspectionReport.detectorSmokeIsAtLeastOneEachFloor =
                returnedThroughoutPropertyDetector.detectorSmokeIsAtLeastOneEachFloor;
              draft.inspectionReport.detectorSmokeIsAtLeastOneOutsideBedrooms =
                returnedThroughoutPropertyDetector.detectorSmokeIsAtLeastOneOutsideBedrooms;
              draft.inspectionReport.detectorSmokePhotoUrlsJson =
                returnedThroughoutPropertyDetector.detectorSmokePhotoUrlsJson;
              draft.inspectionReport.updatedAtThroughoutPropertyDetector =
                returnedThroughoutPropertyDetector.updatedAtThroughoutPropertyDetector;
            }
          });

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, newCache);
        }
      }
    }
  });
}
