import { Chip as DoorsteadChip } from "@doorstead/components";
import { Row, Status } from "./typing";

const { Chip } = DoorsteadChip;

type Props = {
  size?: "sm" | "md";
  value: Row["status"];
};

export function StatusChip({ value, size = "md" }: Props) {
  if (value === null) return null;

  let variant: DoorsteadChip.Variant;
  switch (value) {
    case Status.Successful:
      variant = DoorsteadChip.Variant.Green;
      break;
    case Status.Failed:
      variant = DoorsteadChip.Variant.Red;
      break;
    case Status.Pending:
    default:
      variant = DoorsteadChip.Variant.Gray;
      break;
  }

  return <Chip label={value} variant={variant} size={size} />;
}
