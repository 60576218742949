import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { useApplicantId } from "../../../hooks/applicants/useApplicantId";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";

export function StartButton() {
  const isMobile = useIsMobile();
  const applicantId = useApplicantId();
  const navigate = useNavigate();
  const applicant = useFetchedApplicant();

  return (
    <PrimaryButton
      isFullWidth={isMobile}
      onClick={() => {
        const applicantUserEmail = applicant.doorsteadUser.email;

        if (applicant.doorsteadUser.status === "active") {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append("email", applicantUserEmail);
          urlSearchParams.append("applicantId", applicantId);
          navigate(`/application/${applicantId}/login?${urlSearchParams.toString()}`);
        } else {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append("email", applicantUserEmail);
          navigate(`/application/${applicantId}/signup?${urlSearchParams.toString()}`);
        }
      }}
    >
      Get started
    </PrimaryButton>
  );
}
