import produce from "immer";
import { Option, Type } from "../../../../@types/RentReady/Komponent";
import { CheckboxesField } from "../../fields/CheckboxesField";

type Props = {
  values: Type[];
  onChange: (values: Type[]) => void;
};

export function ComponentMultipleTypeSelect({ values, onChange }: Props) {
  return (
    <CheckboxesField<Option>
      id="types"
      values={values}
      onChange={(types) => {
        onChange(
          produce(values, () => {
            return types;
          })
        );
      }}
    />
  );
}
