import { Box, Flex, VStack } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import { TickIcon } from "@doorstead/components/icons/TickIcon";
import { useApplicantPageView } from "../../../../hooks/applicants/useApplicantPageView";
import { PropertyInfoCard } from "../PropertyInfoCard";
import { CongratsCard } from "./CongratsCard";

export function LeaseSignedCard() {
  useApplicantPageView("TP_LEASE_SIGNED_VIEWED");
  const isMobile = useIsMobile();

  return (
    <VStack spacing={10}>
      {!isMobile && <CongratsCard />}
      <PropertyInfoCard
        action={
          <Flex w={120} alignItems="center" justifyContent="space-between" padding={2}>
            <TickIcon color="teal.500" />
            <Box fontWeight="bold" color="teal.400">
              Signed
            </Box>
          </Flex>
        }
      />
    </VStack>
  );
}
