import { Text } from "@chakra-ui/react";
import React from "react";

export function Title({ children }: { children: React.ReactNode }) {
  return (
    <Text color="gray.700" fontSize={20} fontFamily="Inter" fontStyle="normal" fontWeight={700} lineHeight="34px">
      {children}
    </Text>
  );
}
