import { Flex } from "@chakra-ui/react";
import { ScaleBox } from "./ScaleBox";

type Props = {
  value: number | null;
  onChange: (value: number) => void;
};

export function ScaleField({ value, onChange }: Props) {
  return (
    <Flex w="100%" gap={3} flexWrap="wrap" justifyContent="flex-start">
      {[1, 2, 3, 4, 5].map((scale) => (
        <ScaleBox
          key={`scale_${scale}`}
          label={scale <= 4 ? scale.toString() : "5+"}
          value={value === scale}
          w="calc(20% - 10px)"
          onChange={(value) => {
            if (value) {
              onChange(scale);
            }
          }}
        />
      ))}
    </Flex>
  );
}
