import { PrimaryButton } from "@doorstead/components/buttons";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { useIsShouldShowEstimatesStep } from "../../../../../hooks/rentReady/useIsShouldShowEstimatesStep";
import { useSubmitAreaList } from "../../../../../hooks/rentReady/useSubmitAreaList";
import { useError } from "../../../../../hooks/useError";
import { useIsOffline } from "../../../../../hooks/useIsOffline";
import { useSuccess } from "../../../../../hooks/useSuccess";
import { useIsAreaListSubmitDisabled } from "../useIsAreaListSubmitDisabled";

export function AreaListSubmitEstimateButton() {
  const { mutate: submitAreaList, isLoading } = useSubmitAreaList();
  const success = useSuccess();
  const error = useError();
  const isOffline = useIsOffline();
  const disabled = useIsAreaListSubmitDisabled();
  const shouldShowEstimatesStep = useIsShouldShowEstimatesStep();

  if (isOffline) return null;

  return (
    <PrimaryButton
      isFullWidth
      isLoading={isLoading}
      disabled={disabled}
      onClick={async () => {
        if (!isLoading) {
          submitAreaList({} as any, {
            onSuccess() {
              success({
                title: "Submitted Successfully",
              });
            },
            onError() {
              error({ title: "Failed to create inspection report estimates data", description: "" });
            },
          });
        }
      }}
    >
      {shouldShowEstimatesStep ? (
        <>
          Continue to estimates
          <ArrowIcon color="white" size={2} direction={Direction.Right} />
        </>
      ) : (
        "Submit"
      )}
    </PrimaryButton>
  );
}
