import { HStack } from "@chakra-ui/react";
import { urlsToPhotos } from "../../utils/rr-project/urlsToPhotos";
import { Photo } from "./Photo";

type Props = {
  urls: string[];
  mostShown: number;
};

export function Photos({ urls, mostShown }: Props) {
  return (
    <HStack w="100%" spacing={1}>
      {urls.slice(0, mostShown).map((url, index) => (
        <Photo
          key={url}
          index={index}
          photos={urlsToPhotos(urls)}
          url={url}
          isLast={index === mostShown - 1}
          remainingNumber={urls.length - mostShown}
        />
      ))}
    </HStack>
  );
}
