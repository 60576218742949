import { gql } from "@apollo/client";
import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { useAuth } from "../../contexts/auth";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReportCache } from "./useInspectionReportCache";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  detectorCmIsAtLeastOneCoEachFloor: InspectionReportNode["detectorCmIsAtLeastOneCoEachFloor"];
  detectorCmIsAtLeastOneCoOutsideBedrooms: InspectionReportNode["detectorCmIsAtLeastOneCoOutsideBedrooms"];
  detectorCmPhotoUrlsJson: InspectionReportNode["detectorCmPhotoUrlsJson"];
  detectorSmokeIsAtLeastOneEachFloor: InspectionReportNode["detectorSmokeIsAtLeastOneEachFloor"];
  detectorSmokeIsAtLeastOneOutsideBedrooms: InspectionReportNode["detectorSmokeIsAtLeastOneOutsideBedrooms"];
  detectorSmokePhotoUrlsJson: InspectionReportNode["detectorSmokePhotoUrlsJson"];
  updatedAtThroughoutPropertyDetector: InspectionReportNode["updatedAtThroughoutPropertyCleaning"];
};

type Result = {
  data: {
    updateInspectionReportThroughoutPropertyDetector: {
      success: boolean;
      merged: boolean;
      throughoutPropertyDetector: Args;
    };
  };
};

export function useMapToArgs() {
  const inspectionReportCache = useInspectionReportCache();

  return (throughoutPropertyDetector: ThroughoutPropertyDetector): Args => {
    return {
      detectorCmIsAtLeastOneCoEachFloor: throughoutPropertyDetector.detectorCmIsAtLeastOneCoEachFloor,
      detectorCmIsAtLeastOneCoOutsideBedrooms: throughoutPropertyDetector.detectorCmIsAtLeastOneCoOutsideBedrooms,
      detectorCmPhotoUrlsJson: mapPhotosToPhotoUrlsJson(throughoutPropertyDetector.detectorCmPhotos),
      detectorSmokeIsAtLeastOneEachFloor: throughoutPropertyDetector.detectorSmokeIsAtLeastOneEachFloor,
      detectorSmokeIsAtLeastOneOutsideBedrooms: throughoutPropertyDetector.detectorSmokeIsAtLeastOneOutsideBedrooms,
      detectorSmokePhotoUrlsJson: mapPhotosToPhotoUrlsJson(throughoutPropertyDetector.detectorSmokePhotos),
      updatedAtThroughoutPropertyDetector: inspectionReportCache!.updatedAtThroughoutPropertyDetector,
    };
  };
}

export function useUpdateThroughoutPropertyDetectorToServer() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return async (args: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $detectorCmIsAtLeastOneCoEachFloor: Boolean
          $detectorCmIsAtLeastOneCoOutsideBedrooms: Boolean
          $detectorCmPhotoUrlsJson: String
          $detectorSmokeIsAtLeastOneEachFloor: Boolean
          $detectorSmokeIsAtLeastOneOutsideBedrooms: Boolean
          $detectorSmokePhotoUrlsJson: String
          $inspectionReportId: UUID!
          $updatedAtThroughoutPropertyDetector: DateTime
        ) {
          updateInspectionReportThroughoutPropertyDetector(
            detectorCmIsAtLeastOneCoEachFloor: $detectorCmIsAtLeastOneCoEachFloor
            detectorCmIsAtLeastOneCoOutsideBedrooms: $detectorCmIsAtLeastOneCoOutsideBedrooms
            detectorCmPhotoUrlsJson: $detectorCmPhotoUrlsJson
            detectorSmokeIsAtLeastOneEachFloor: $detectorSmokeIsAtLeastOneEachFloor
            detectorSmokeIsAtLeastOneOutsideBedrooms: $detectorSmokeIsAtLeastOneOutsideBedrooms
            detectorSmokePhotoUrlsJson: $detectorSmokePhotoUrlsJson
            inspectionReportId: $inspectionReportId
            updatedAtThroughoutPropertyDetector: $updatedAtThroughoutPropertyDetector
          ) {
            success
            merged
            throughoutPropertyDetector {
              detectorCmIsAtLeastOneCoEachFloor
              detectorCmIsAtLeastOneCoOutsideBedrooms
              detectorCmPhotoUrlsJson
              detectorSmokeIsAtLeastOneEachFloor
              detectorSmokeIsAtLeastOneOutsideBedrooms
              detectorSmokePhotoUrlsJson
              inspectionReportId
              updatedAtThroughoutPropertyDetector
            }
          }
        }
      `,
      variables: {
        ...args,
        inspectionReportId,
      },
    })) as Result;
  };
}
