import { HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "./useDrawer";

type Args = {
  onConfirm: () => void;
};

export function useOpenChangeDefectTypeDrawer() {
  const { onOpen: onDrawerOpen, onClose } = useDrawer();

  return ({ onConfirm }: Args) => {
    onDrawerOpen({
      header: "Change defect type?",
      body: "Changing the defect type will erase all of the current defect’s data. Each item component can only have one reported defect.",
      action: (
        <HStack w="100%" spacing={5}>
          <OutlineButton isFullWidth onClick={onClose}>
            Cancel
          </OutlineButton>
          <PrimaryButton
            isFullWidth
            onClick={async () => {
              await onConfirm();
              onClose();
            }}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      ),
    });
  };
}
