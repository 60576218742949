import { db } from "../../databases/RentReadyDatabase";
import { useAreaId } from "./useAreaId";
import { AreaNode } from "./useFetchInspectionReport/AreaNode";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  field: keyof AreaNode;
  value: any;
};

export function useUpdatePartialAreaToCache() {
  const { areaId } = useAreaId();
  const inspectionReportId = useInspectionReportId();

  return async ({ field, value }: Args) => {
    return db.transaction("rw", db.rr_graphql_result, async () => {
      await db.rr_graphql_result
        .where("inspection_report_id")
        .equals(inspectionReportId)
        .modify(function (ignored, ref) {
          const areaNode = ref.value.inspectionReport.data?.find((e) => e.areaDataId === areaId);
          if (areaNode) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            areaNode[field] = value;
          }
        });
    });
  };
}
