import { Flex } from "@chakra-ui/react";
import { Photo } from "../../@types/RentReady/Photo";
import { numberToCommaString } from "../../utils/numberToCommaString";
import { getPhotoSrc } from "../../utils/rentReady/getPhotoSrc";

type Props = {
  photo: Photo;
  onClick: () => void;
  number: number;
};

export function PhotoThumbnailWithNumber({ photo, number, onClick }: Props) {
  const src = getPhotoSrc(photo, "thumbnail");

  return (
    <Flex
      cursor="pointer"
      w="75px"
      h="75px"
      onClick={onClick}
      bgSize="cover"
      bgImage={`url(${src})`}
      bgPosition="center"
      justifyContent="center"
      alignItems="center"
      fontWeight="bold"
      borderRadius="5px"
      boxShadow="inset 0 0 0 2px #CFD6E1"
      color="black"
      fontSize="xl"
      textShadow="-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white"
    >
      {numberToCommaString(number)}
    </Flex>
  );
}
