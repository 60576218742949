import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useScrollToTop } from "../../useScrollToTop";

export enum View {
  StartView = "StartView",
  ExpiredView = "ExpiredView",
  PropertyDetailView = "PropertyDetailView",
  AreaListView = "AreaListView",
  AreaOneView = "AreaOneView",
  AreaOverviewView = "AreaOverviewView",
  ItemListEditView = "ItemListEditView",
  AreaListEditView = "AreaListEditView",
  ItemEditView = "ItemEditView",
  ComponentEditView = "ComponentEditView",
  KeyDetailsView = "KeyDetailsView",
  KeyStorageView = "KeyStorageView",
  PropertyDetailEditView = "PropertyDetailEditView",
  EstimateListView = "EstimateListView",
  EstimateEditView = "EstimateEditView",
  ThroughoutPropertyCleaningEditView = "ThroughoutPropertyCleaningEditView",
  ThroughoutPropertyDetectorEditView = "ThroughoutPropertyDetectorEditView",
}

type Props = {
  view: View;
  setView: (view: View) => void;
};

const context = createContext<Props>({
  view: View.AreaListView,
  setView: console.log,
});

export function Provider({ view: propsView, children }: { view: View; children: ReactNode }) {
  const scrollToTop = useScrollToTop();
  const [view, setView] = useState(propsView);

  useEffect(() => {
    setView(propsView);
  }, [propsView]);

  return (
    <context.Provider
      value={{
        view,
        setView(view) {
          setView(view);
          scrollToTop();
        },
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useView() {
  return useContext(context);
}
