export const GOOGLE_MAP_KEY = "AIzaSyDQiGK9qhtXjqPk86guu3bOEfPye4Qtkzw";

export const SENTRY_DSN = "https://758526b5fcfc4c8eb48aa18574d8f2de@o321322.ingest.sentry.io/4504763974221824";

export const bugReportLink = "https://airtable.com/apphtL4MCS25cNGQP/tblLo1o3kMzXXSXYO/viwRSLKzyptWGcXTA?blocks=hide";

export function getDoorsteadEnv() {
  return process.env.REACT_APP_DOORSTEAD_ENV;
}

export const apiServerUrl = () => {
  switch (getDoorsteadEnv()) {
    case "prod":
      return "https://api.doorstead.com";
    case "localhost_prod_simulator":
      return "https://api.doorstead.com";
    case "stage":
      return "https://mars-api.doorstead.com";
    case "int":
      return "https://int-api.doorstead.com";
    default:
      return "http://localhost:5005";
  }
};

export function stripePublishableKey() {
  switch (getDoorsteadEnv()) {
    case "prod":
    case "localhost_prod_simulator":
    case "stage":
      return "pk_live_51NVzw7CutH68IjivbSUlpFV8Q4azCur2gUf4a7BQNzxpcnf7zWGx6VBtnnXRvGLBYr2J1vxxSzlDr40SAmEdwN1H00AtppXNCj";
    case "int":
    default:
      return "pk_test_51NVzw7CutH68IjivL4y4eEXEv5r85KNU26KEywUYtMdcj0hErlP3TIDh3kPvGbS4k8UExmGHYM0oaVztTb8ZGTiT00hf5UZOde";
  }
}

export const appServerUrl = () => {
  switch (getDoorsteadEnv()) {
    case "prod":
      return "https://tenants.doorstead.com";
    case "localhost_prod_simulator":
      return "https://tenants.doorstead.com";
    case "stage":
      return "https://mars-tenants.doorstead.com";
    case "int":
      return "https://int-tenants.doorstead.com";
    default:
      return `http://localhost:3000`;
  }
};

export type Auth0Config = {
  domain: string;
  clientID: string;
  audience: string;
  dbConnection: string;
};

export const auth0Config = (): Auth0Config => {
  switch (getDoorsteadEnv()) {
    case "prod":
    case "localhost_prod_simulator":
    case "stage":
      return {
        domain: "login.doorstead.com",
        clientID: "Ub6y3pTfNeozT4xMxDDmakQ6WUjlD0Pj",
        audience: "https://api.doorstead.com/",
        dbConnection: "Username-Password-Authentication",
      };
    case "int":
    default:
      return {
        domain: "int-login.doorstead.com",
        clientID: "67myIlsLlyaXPLg3l1TUHM7122vDTzf8",
        audience: "https://int-api.doorstead.com/",
        dbConnection: "Username-Password-Authentication",
      };
  }
};

export const segmentWriteKey = () => {
  return getDoorsteadEnv() === "prod" ? "yP6FZ2P1Cj73cazYd197FBSGSmFl46YT" : "AD7wUnT872akbWYzwPVEIBmuJ4htgyeq";
};

export const statsigSdkKey = "client-ImaKKyjuW2u5cXYmllllNAh0ieiR1O81VLo17DhoQe4";

export const statsigEnvironment = () => {
  switch (getDoorsteadEnv()) {
    case "prod":
    case "localhost_prod_simulator":
      return "production";
    case "stage":
      return "staging";
    case "int":
    default:
      return "development";
  }
};

export const personaEnvironmentId = () => {
  switch (getDoorsteadEnv()) {
    case "prod":
    case "localhost_prod_simulator":
    case "stage":
      return "env_Pgrijwe27XdeBMZa57PQWmXC";
    case "int":
    default:
      return "env_z4ZcxMQqtFxXUhYuwfS7qSh7";
  }
};
