import { CleanSelectedOption } from "../../../@types/RentReady/ThroughoutPropertyCleaning";
import { AreaNode } from "./AreaNode";
import { EstimateNode } from "./EstimateNode";
import { PropertyType } from "./PropertyType";

export enum GoodMissingFixStatus {
  Good = "Good",
  Missing = "Missing",
  Fix = "Fix",
}

export enum InspectionReportType {
  MOVE_IN_INSPECTION = "move-in inspection",
  TRANSFER_EVALUATION = "transfer evaluation",
  ANNUAL_EVALUATION = "annual inspection",
  PRE_MOVE_OUT_EVALUATION = "pre-move out evaluation",
}

export type InspectionReportNode = {
  inspectionType: string;
  expirationDate: string | null;
  propertyAddress: string;
  isThroughoutPropertyFeature: boolean;
  isV2UiFeature: boolean;
  isKeyDetailsAdditionalKeysFeature: boolean;
  isKeyStorageFullTenantSetsFeature: boolean;
  isKeyStorageAdditionalKeyStorageFeature: boolean;
  propertyId: string;
  submittedTime: string | null;
  startTime: string | null;
  propertyDetailsInstructionTime: string | null;
  keyStorageAndKeyDetailsInstructionTime: string | null;
  areaListInstructionTime: string | null;
  areaOverviewInstructionTime: string | null;
  itemListAddRemoveInstructionTime: string | null;
  itemListTypeInstructionTime: string | null;
  estimationInstructionTime: string | null;
  keyDetailsInstructionTime: string | null;
  keyStorageInstructionTime: string | null;
  propertyType: PropertyType;
  propertyTypeNotes: string;
  completedPropertyDetailsTime: string | null;
  completedEstimatesTime: string | null;
  completedEvalTime: string | null;
  bedrooms: number | null;
  bedroomNotes: string;
  fullBathrooms: number | null;
  fullBathroomNotes: string;
  halfBathrooms: number | null;
  halfBathroomNotes: string;
  sqFt: number | null;
  sqFtNotes: string;
  parking: number | null;
  parkingNotes: string;
  frontDoorKeys: string | null;
  frontDoorKeysWorking: boolean | null;
  frontDoorKeysPhotoUrlsJson: string | null;
  frontDoorKeysNotes: string | null;
  mailboxKeys: string | null;
  mailboxKeysWorking: boolean | null;
  mailboxKeysPhotoUrlsJson: string | null;
  mailboxKeysNotes: string | null;
  communityKeys: string | null;
  communityKeysWorking: boolean | null;
  communityKeysNotes: string | null;
  communityKeysPhotoUrlsJson: string | null;
  garageRemotes: string | null;
  garageRemotesWorking: boolean | null;
  garageRemotesPhotoUrlsJson: string | null;
  garageRemotesNotes: string | null;
  keypads: string | null;
  keypadsWorking: boolean | null;
  keypadsPhotoUrlsJson: string | null;
  keypadsNotes: string | null;

  additionalKeys: string | null;
  additionalKeysWorking: boolean | null;
  additionalKeysPhotoUrlsJson: string | null;
  additionalKeysNotes: string | null;

  additionalKeyStoragePhotoUrlsJson: string | null;
  additionalKeyStorageNotes: string | null;

  fullTenantSets: string | null;
  fullTenantSetsPhotoUrlsJson: string | null;
  lockboxCode: string | null;
  lockboxCodePhotoUrlsJson: string | null;
  lockboxLocation: string | null;
  lockboxLocationPhotoUrlsJson: string | null;
  estimateStartDate: string | null;
  estimateJobTimeInDays: number | null;

  cleanIsNeeded: boolean | null;
  cleanSelectedOptions: CleanSelectedOption[] | null;
  cleanIsOdorRemoval: boolean | null;
  cleanNotes: string | null;

  detectorSmokePhotoUrlsJson: string | null;
  detectorSmokeIsAtLeastOneOutsideBedrooms: boolean | null;
  detectorSmokeIsAtLeastOneEachFloor: boolean | null;
  detectorCmPhotoUrlsJson: string | null;
  detectorCmIsAtLeastOneCoOutsideBedrooms: boolean | null;
  detectorCmIsAtLeastOneCoEachFloor: boolean | null;
  shouldShowEstimatesStep: boolean | null;

  updatedAt: string | null;
  updatedAtAreas: string | null;
  updatedAtKeyDetail: string | null;
  updatedAtKeyStorage: string | null;
  updatedAtThroughoutPropertyCleaning: string | null;
  updatedAtThroughoutPropertyDetector: string | null;
  data: AreaNode[] | null;
  estimatesData: EstimateNode[] | null;
};
