import isEmpty from "lodash/isEmpty";
import { useFetchedMe } from "./useFetchedMe";

type Owner = {
  fullName: string | null;
  phone: string | null;
  email: string | null;
};

export function useOwnerInfo(): Owner | null {
  const tenantMe = useFetchedMe();
  const ownerBasicInfo = tenantMe?.tenant?.activeLease?.property?.publicOwnerBasicInfo;

  if (!ownerBasicInfo) return null;

  return {
    fullName: ownerBasicInfo.fullName,
    phone: isEmpty(ownerBasicInfo.phone) ? null : ownerBasicInfo.phone,
    email: isEmpty(ownerBasicInfo.email) ? null : ownerBasicInfo.email,
  };
}
