import { Area } from "../../../@types/RentReady/Area";
import { mapPhotoUrlsJsonToPhotos } from "../../../utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { AreaNode } from "../useFetchInspectionReport/AreaNode";
import { mapItemNodesToItems } from "./mapItemNodesToItems";

export enum CleaningOptions {
  Light = "Light",
  Deep = "Deep",
  Odors = "Odors",
}
export enum PaintingOptions {
  FullPainting = "Full Painting",
}

function mapAreaNodeToArea(areaNode: AreaNode): Area {
  const areaSelectedCleaningOptions = areaNode.areaSelectedCleaningOptions || [];
  const areaSelectedPaintingOptions = areaNode.areaSelectedPaintingOptions || [];

  return {
    id: areaNode.areaDataId,
    uiName: areaNode.areaName || "",
    name: areaNode.areaName || "",
    isThroughoutProperty: areaNode.areaShowInThroughoutProperty || false,
    notes: areaNode.areaNotes || "",
    photos: mapPhotoUrlsJsonToPhotos(areaNode.areaPhotoUrlsJson),
    cleanLight: areaSelectedCleaningOptions.includes(CleaningOptions.Light),
    cleanDeep: areaSelectedCleaningOptions.includes(CleaningOptions.Deep),
    cleanOdors: areaNode.areaSelectedOdors || false,
    cleanPersonalItemRemoval: areaNode.areaSelectedItemRemoval || false,
    cleaningNotes: areaNode.areaCleaningNotes || "",
    painting: areaSelectedPaintingOptions.includes(PaintingOptions.FullPainting),
    paintingNotes: areaNode.areaPaintingNotes || "",
    itemRemovalNotes: areaNode.areaItemRemovalNotes || "",
    createDate: areaNode.areaDataCreatedAt === null ? new Date() : new Date(areaNode.areaDataCreatedAt),
    updateDate: areaNode.areaDataUpdatedAt,
    itemRemovalPhotos: mapPhotoUrlsJsonToPhotos(areaNode.areaItemRemovalPhotoUrlsJson),
    smokeDetectorIsOneWorkingDetectorFound: areaNode.areaSmokeDetectorIsOneWorkingDetectorFound,
    smokeDetectorNotes: areaNode.areaSmokeDetectorNotes || "",
    smokeDetectorStatus: areaNode.areaSmokeDetectorStatus,
    smokeDetectorPhotos: mapPhotoUrlsJsonToPhotos(areaNode.areaSmokeDetectorPhotoUrlsJson || "[]"),

    heatSourceNotes: areaNode.areaHeatSourceNotes || "",
    heatSourceStatus: areaNode.areaHeatSourceStatus,
    heatSourcePhotos: mapPhotoUrlsJsonToPhotos(areaNode.areaHeatSourcePhotoUrlsJson || "[]"),
    items: mapItemNodesToItems(areaNode.itemsData),
  };
}

export function mapAreaNodesToAreas(areaNodes: AreaNode[]): Area[] {
  return areaNodes.map(mapAreaNodeToArea);
}
