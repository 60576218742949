import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchInspectionReport } from "./useFetchInspectionReport";
import { useInspectionReportId } from "./useInspectionReportId";

export function useCreateInspectionReportData() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();
  const { refetch } = useFetchInspectionReport();

  return useMutation(
    ({
      numBathrooms,
      numBedrooms,
      numHalfBathrooms,
    }: {
      numBathrooms: number;
      numBedrooms: number;
      numHalfBathrooms: number;
    }) => {
      return gqlClient.mutate({
        mutation: gql`
          mutation ($inspectionReportId: UUID!, $numBathrooms: Int!, $numBedrooms: Int!, $numHalfBathrooms: Int!) {
            createInspectionReportData(
              inspectionReportId: $inspectionReportId
              numBathrooms: $numBathrooms
              numBedrooms: $numBedrooms
              numHalfBathrooms: $numHalfBathrooms
            ) {
              success
            }
          }
        `,
        variables: {
          inspectionReportId,
          numBathrooms,
          numBedrooms,
          numHalfBathrooms,
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
