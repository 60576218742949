import { ReactNode, createContext, useContext } from "react";

type Context = {
  propertyState: string;
};

const context = createContext<Context>({
  propertyState: "",
});

export function usePropertyState(): string {
  return useContext(context).propertyState;
}

export function Provider({ children, propertyState }: { children: ReactNode; propertyState: string }) {
  return <context.Provider value={{ propertyState }}>{children}</context.Provider>;
}
