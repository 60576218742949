import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
  estimateId: string;
  setEstimateId: (estimateId: string) => void;
};

const context = createContext<Props>({
  estimateId: "",
  setEstimateId(estimateId) {
    console.log(estimateId);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [estimateId, setEstimateId] = useState("");

  return (
    <context.Provider
      value={{
        estimateId,
        setEstimateId,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useEstimateId() {
  return useContext(context);
}
