function getNegotiatedDateFormattedDateString(dateStr: string | null): string {
  if (dateStr === null) {
    return "";
  }
  const [year, month, day] = dateStr.split("-");
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = months[parseInt(month) - 1];
  return `${monthName} ${parseInt(day)}, ${year}`;
}

export { getNegotiatedDateFormattedDateString };
