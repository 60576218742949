import { VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRRProjectId } from "../../../hooks/rr-project/useRRProjectId";
import { DIYRepairs } from "../DIYRepairs";
import { DoorsteadManagedRepairs } from "../DoorsteadManagedRepairs";
import { EstimatesToApproveRepairs } from "../EstimatesToApproveRepairs";
import { ToApproveRepairs } from "../ToApproveRepairs";
import { useToUpdateRepairEstimateApprovedStore } from "../stores/useToUpdateRepairEstimateApprovedStore";
import { useToUpdateRepairOwnerDecisionsStore } from "../stores/useToUpdateRepairOwnerDecisionsStore";

export function RepairListView() {
  const rrProjectId = useRRProjectId();
  const { clear: clearOwnerDecisionsStore } = useToUpdateRepairOwnerDecisionsStore();
  const { clear: clearEstimateApprovedStore } = useToUpdateRepairEstimateApprovedStore();

  useEffect(() => {
    return () => {
      clearOwnerDecisionsStore();
      clearEstimateApprovedStore();
    };
  }, [rrProjectId]);

  return (
    <VStack w="100%" spacing={5}>
      <ToApproveRepairs />
      <EstimatesToApproveRepairs />
      <DoorsteadManagedRepairs />
      <DIYRepairs />
    </VStack>
  );
}
