import { Box, Flex, VStack } from "@chakra-ui/react";
import { DoorsteadProvider, Link } from "@doorstead/components";
import { Form as DoorsteadResetPasswordForm } from "@doorstead/components/screens/ResetPasswordScreen";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ApplicantLayout } from "src/components/applicants/ApplicantPortalProvider/ApplicantLayout";
import { processAuthError, useAuth } from "src/contexts/auth";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { usePageView } from "src/hooks/usePageView";
import { useRedirectIfAuthenticated } from "src/hooks/useRedirectIfAuthenticated";
import { ApplicantAuthWhiteBox } from "./ApplicantAuthWhiteBox";

function ApplicantForgotPasswordPage() {
  const applicantId = useApplicantId();
  useRedirectIfAuthenticated({ to: `/application/${applicantId}` });
  const [params] = useSearchParams();
  const initialEmail = params.get("email");
  const [email, setEmail] = useState(initialEmail || "");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { changePassword } = useAuth();
  usePageView("TP_APPLICANT_FORGOT_PASSWORD_VIEWED");
  const loginLink = `/application/${applicantId}/login?email=${encodeURIComponent(email)}`;

  const resetPassword = async () => {
    try {
      setLoading(true);
      await changePassword({ email });
      setEmailSent(true);
    } catch (err: any) {
      const authError = processAuthError(err);
      setEmailError(authError.email || "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DoorsteadProvider>
      <ApplicantLayout>
        <ApplicantAuthWhiteBox>
          {emailSent ? (
            <VStack spacing={6} display="flex" alignItems="flex-start" width="100%">
              <Flex alignItems="center" fontSize="3xl" fontWeight={600}>
                Email Sent
              </Flex>
              <Box color="black" fontSize="md" fontWeight={400}>
                We sent you an email to reset your password.
              </Box>
            </VStack>
          ) : (
            <DoorsteadResetPasswordForm
              email={email}
              onChange={setEmail}
              isSubmitting={loading}
              onReset={resetPassword}
              emailErrorMessage={emailError}
            />
          )}
          <Flex mt={30}>
            <Link href={loginLink}>Back to login</Link>
          </Flex>
        </ApplicantAuthWhiteBox>
      </ApplicantLayout>
    </DoorsteadProvider>
  );
}

export default ApplicantForgotPasswordPage;
