import { Box, Link, Text, VStack } from "@chakra-ui/react";
import { DateField, HintBox, PrimaryButton } from "@doorstead/components";
import { addBusinessDays, addDays } from "date-fns/esm";
import { useState } from "react";
import { useAddMoveInDateApplicationRequest } from "src/hooks/applicants/useAddMoveInDateApplicationRequest";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useApplicantPageView } from "src/hooks/applicants/useApplicantPageView";
import { useIsTPO } from "src/hooks/applicants/useIsTPO";
import { getYearMonthAndDateFromDateString } from "../../../utils/getYearMonthAndDateFromDateString";
import { toPrefixZero } from "../ApplicantDateField/toPrefixZero";

export function ApplicantLeaseStartDatePage() {
  useApplicantPageView("TP_APPLICATION_LEASE_START_DATE_VIEWED");
  const analytics = useApplicantAnalytics();
  const { mutate: addMoveInDate, isLoading: isAddingMoveInDate } = useAddMoveInDateApplicationRequest();
  const isTPO = useIsTPO();
  const today = new Date();
  const enabledFromDate = addBusinessDays(today, 5);
  const enabledToDate = isTPO ? addDays(enabledFromDate, 60) : addDays(enabledFromDate, 10);
  const formattedDate = `${enabledFromDate.getFullYear()}-${String(enabledFromDate.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(enabledFromDate.getDate()).padStart(2, "0")}`;

  const [selectedDate, setSelectedDate] = useState<string | null>(formattedDate);
  return (
    <VStack gap={6} mb={8}>
      <Text color="gray.800" fontSize="5xl" fontWeight="extrabold">
        Select lease start date
      </Text>
      <Text color="gray.500" fontSize="md" fontWeight="medium">
        Applications with earlier lease start dates receive priority.
      </Text>
      <Box w="100%">
        <DateField
          id="move-in"
          label="Lease start date"
          enabledFromDate={{
            year: enabledFromDate.getFullYear(),
            month: enabledFromDate.getMonth() + 1,
            date: enabledFromDate.getDate(),
          }}
          enabledToDate={{
            year: enabledToDate.getFullYear(),
            month: enabledToDate.getMonth() + 1,
            date: enabledToDate.getDate(),
          }}
          value={getYearMonthAndDateFromDateString(selectedDate)}
          onChange={(value) => {
            if (value !== null) {
              setSelectedDate(`${value.year}-${toPrefixZero(value.month)}-${toPrefixZero(value.date)}`);
            }
          }}
        />
      </Box>
      <HintBox>
        <Box fontSize="sm" color="gray.700">
          To change lease start date after submission, please email
          <Link
            color="gray.700"
            target="_blank"
            ml={1}
            href="mailto:prospects@doorstead.com"
            _hover={{
              color: "purple.500",
              textDecoration: "underline",
            }}
          >
            prospects@doorstead.com
          </Link>
        </Box>
      </HintBox>
      <PrimaryButton
        isLoading={isAddingMoveInDate}
        onClick={() => {
          if (selectedDate) {
            analytics.track("TP_APPLICATION_LEASE_START_DATE_SUBMITTED", {
              selectedDate,
            });
            addMoveInDate({
              negotiatedMoveInDate: selectedDate,
            });
          }
        }}
      >
        Continue
      </PrimaryButton>
    </VStack>
  );
}
