import { gql } from "@apollo/client";
import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { useAuth } from "../../contexts/auth";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReportCache } from "./useInspectionReportCache";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  frontDoorKeys: InspectionReportNode["frontDoorKeys"];
  frontDoorKeysWorking: InspectionReportNode["frontDoorKeysWorking"];
  frontDoorKeysNotes: InspectionReportNode["frontDoorKeysNotes"];
  frontDoorKeysPhotoUrlsJson: InspectionReportNode["frontDoorKeysPhotoUrlsJson"];
  mailboxKeys: InspectionReportNode["mailboxKeys"];
  mailboxKeysWorking: InspectionReportNode["mailboxKeysWorking"];
  mailboxKeysNotes: InspectionReportNode["mailboxKeysNotes"];
  mailboxKeysPhotoUrlsJson: InspectionReportNode["mailboxKeysPhotoUrlsJson"];
  communityKeys: InspectionReportNode["communityKeys"];
  communityKeysWorking: InspectionReportNode["communityKeysWorking"];
  communityKeysNotes: InspectionReportNode["communityKeysNotes"];
  communityKeysPhotoUrlsJson: InspectionReportNode["communityKeysPhotoUrlsJson"];
  garageRemotes: InspectionReportNode["garageRemotes"];
  garageRemotesWorking: InspectionReportNode["garageRemotesWorking"];
  garageRemotesNotes: InspectionReportNode["garageRemotesNotes"];
  garageRemotesPhotoUrlsJson: InspectionReportNode["garageRemotesPhotoUrlsJson"];
  keypads: InspectionReportNode["keypads"];
  keypadsWorking: InspectionReportNode["keypadsWorking"];
  keypadsNotes: InspectionReportNode["keypadsNotes"];
  keypadsPhotoUrlsJson: InspectionReportNode["keypadsPhotoUrlsJson"];
  additionalKeys: InspectionReportNode["additionalKeys"];
  additionalKeysWorking: InspectionReportNode["additionalKeysWorking"];
  additionalKeysNotes: InspectionReportNode["additionalKeysNotes"];
  additionalKeysPhotoUrlsJson: InspectionReportNode["additionalKeysPhotoUrlsJson"];
  updatedAtKeyDetail: InspectionReportNode["updatedAtKeyDetail"];
};

type Result = {
  data: {
    updateInspectionReportKeyDetail: {
      success: boolean;
      merged: boolean;
      keyDetail: Args;
    };
  };
};

export function useMapKeyDetailToArgs() {
  const inspectionReportCache = useInspectionReportCache()!;

  return (keyDetail: KeyDetail): Args => {
    return {
      frontDoorKeys: keyDetail.frontDoorKeys.number,
      frontDoorKeysWorking: keyDetail.frontDoorKeys.isWorking,
      frontDoorKeysNotes: keyDetail.frontDoorKeys.note,
      frontDoorKeysPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.frontDoorKeys.photos),

      mailboxKeys: keyDetail.mailboxKeys.number,
      mailboxKeysWorking: keyDetail.mailboxKeys.isWorking,
      mailboxKeysNotes: keyDetail.mailboxKeys.note,
      mailboxKeysPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.mailboxKeys.photos),

      communityKeys: keyDetail.communityKeys.number,
      communityKeysWorking: keyDetail.communityKeys.isWorking,
      communityKeysNotes: keyDetail.communityKeys.note,
      communityKeysPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.communityKeys.photos),

      garageRemotes: keyDetail.garageRemotes.number,
      garageRemotesWorking: keyDetail.garageRemotes.isWorking,
      garageRemotesNotes: keyDetail.garageRemotes.note,
      garageRemotesPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.garageRemotes.photos),

      keypads: keyDetail.keyPads.number,
      keypadsWorking: keyDetail.keyPads.isWorking,
      keypadsNotes: keyDetail.keyPads.note,
      keypadsPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.keyPads.photos),

      additionalKeys: keyDetail.additionalKeys.number,
      additionalKeysWorking: keyDetail.additionalKeys.isWorking,
      additionalKeysNotes: keyDetail.additionalKeys.note,
      additionalKeysPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyDetail.additionalKeys.photos),

      updatedAtKeyDetail: inspectionReportCache.updatedAtKeyDetail,
    };
  };
}

export function useUpdateKeyDetailToServer() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return async (args: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $communityKeys: String
          $communityKeysNotes: String
          $communityKeysPhotoUrlsJson: String
          $communityKeysWorking: Boolean
          $frontDoorKeys: String
          $frontDoorKeysNotes: String
          $frontDoorKeysPhotoUrlsJson: String
          $frontDoorKeysWorking: Boolean
          $garageRemotes: String
          $garageRemotesNotes: String
          $garageRemotesPhotoUrlsJson: String
          $garageRemotesWorking: Boolean
          $inspectionReportId: UUID!
          $keypads: String
          $keypadsNotes: String
          $keypadsPhotoUrlsJson: String
          $keypadsWorking: Boolean
          $additionalKeys: String
          $additionalKeysNotes: String
          $additionalKeysPhotoUrlsJson: String
          $additionalKeysWorking: Boolean
          $mailboxKeys: String
          $mailboxKeysNotes: String
          $mailboxKeysPhotoUrlsJson: String
          $mailboxKeysWorking: Boolean
          $updatedAtKeyDetail: DateTime
        ) {
          updateInspectionReportKeyDetail(
            communityKeys: $communityKeys
            communityKeysNotes: $communityKeysNotes
            communityKeysPhotoUrlsJson: $communityKeysPhotoUrlsJson
            communityKeysWorking: $communityKeysWorking
            frontDoorKeys: $frontDoorKeys
            frontDoorKeysNotes: $frontDoorKeysNotes
            frontDoorKeysPhotoUrlsJson: $frontDoorKeysPhotoUrlsJson
            frontDoorKeysWorking: $frontDoorKeysWorking
            garageRemotes: $garageRemotes
            garageRemotesNotes: $garageRemotesNotes
            garageRemotesPhotoUrlsJson: $garageRemotesPhotoUrlsJson
            garageRemotesWorking: $garageRemotesWorking
            inspectionReportId: $inspectionReportId
            keypads: $keypads
            keypadsNotes: $keypadsNotes
            keypadsPhotoUrlsJson: $keypadsPhotoUrlsJson
            keypadsWorking: $keypadsWorking
            mailboxKeys: $mailboxKeys
            mailboxKeysNotes: $mailboxKeysNotes
            mailboxKeysPhotoUrlsJson: $mailboxKeysPhotoUrlsJson
            mailboxKeysWorking: $mailboxKeysWorking
            additionalKeys: $additionalKeys
            additionalKeysNotes: $additionalKeysNotes
            additionalKeysPhotoUrlsJson: $additionalKeysPhotoUrlsJson
            additionalKeysWorking: $additionalKeysWorking
            updatedAtKeyDetail: $updatedAtKeyDetail
          ) {
            success
            merged
            keyDetail {
              additionalKeys
              additionalKeysNotes
              additionalKeysPhotoUrlsJson
              additionalKeysWorking
              frontDoorKeys
              frontDoorKeysWorking
              frontDoorKeysNotes
              frontDoorKeysPhotoUrlsJson
              mailboxKeys
              mailboxKeysWorking
              mailboxKeysNotes
              mailboxKeysPhotoUrlsJson
              communityKeys
              communityKeysWorking
              communityKeysNotes
              communityKeysPhotoUrlsJson
              garageRemotes
              garageRemotesWorking
              garageRemotesNotes
              garageRemotesPhotoUrlsJson
              keypads
              keypadsWorking
              keypadsNotes
              keypadsPhotoUrlsJson
              updatedAtKeyDetail
            }
          }
        }
      `,
      variables: {
        ...args,
        inspectionReportId,
      },
    })) as Result;
  };
}
