import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../contexts/auth";
import { MeResult, PaymentMethodEdgeNode, paymentMethodsFragment, queryKey as useFetchMeQueryKey } from "./useFetchMe";

type Result = {
  me: {
    paymentMethods: {
      edges: { node: PaymentMethodEdgeNode }[];
    };
  };
};

const queryKey = "useRefetchPaymentMethods";
const query = gql`
  query refetchPaymentMethods {
    me {
      ${paymentMethodsFragment}
    }
  }
`;

export function useRefetchPaymentMethods() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: refetchPaymentMethods } = useMutation<Result>(
    queryKey,
    () => gqlClient.query({ query }).then((e) => e.data),
    {
      onSuccess(result) {
        queryClient.setQueryData(
          useFetchMeQueryKey,
          produce(queryClient.getQueryData(useFetchMeQueryKey), (draft: MeResult) => {
            draft.me.paymentMethods = result.me.paymentMethods;
          })
        );
      },
    }
  );

  return {
    refetchPaymentMethods,
    isLoading,
  };
}
