import { Repair } from "../../@types/RepairList/Repair";
import { OwnerDecision, RepairRequirement } from "./useFetchRRProject";
import { useIsMoveOut } from "./useIsMoveOut";
import { useIsTurnOver } from "./useIsTurnOver";
import { useRepairs } from "./useRepairs";
import { useToApproveRepairs } from "./useToApproveRepairs";

export function useEstimateToApproveRepairs(): Repair[] {
  const toApproveRepairIds = new Set(useToApproveRepairs().map((e) => e.id));
  const repairNodes = useRepairs().filter((e) => !toApproveRepairIds.has(e.id));
  const isMoveOut = useIsMoveOut();
  const isTurnOver = useIsTurnOver();

  return repairNodes.filter(({ ownerDecision, repairRequirement }) => {
    // the only things that aren't in the "main table" are the in-progress DIY repairs
    if (ownerDecision === OwnerDecision.DIY) {
      return false;
    }
    // filtering the repair requirement in Owner Turn Over
    if (isTurnOver && repairRequirement !== null && repairRequirement === RepairRequirement.TenantResponsible) {
      return false;
    }

    // filtering the repair requirement in Owner Move Out
    return !(
      isMoveOut &&
      repairRequirement !== null &&
      [RepairRequirement.OwnerRecommended, RepairRequirement.OwnerOptional, RepairRequirement.OwnerRequired].includes(
        repairRequirement
      )
    );
  });
}
