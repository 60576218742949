import { gql } from "@apollo/client";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { LeaseNegotiationOneCompletePage } from "../../components/LeaseNegotiationOneCompletePage";
import { useAuth } from "../../contexts/auth";
import { LinkExpiredPage } from "./NegotiationStatusPage/LinkExpiredPage";
import { LinkInvalidPage } from "./NegotiationStatusPage/LinkInvalidPage";

type ResponseNode = {
  data: {
    validateNegotiationLink: { status: "enabled" | "invalid" | "expired" | "submitted" };
  };
};

function useQueryValidateToken({ applicantId }: { applicantId: string }) {
  const { gqlClient } = useAuth();

  return useQuery<ResponseNode>("validateToken", () => {
    return gqlClient.mutate({
      mutation: gql`
        mutation validateNegotiationLink($applicantId: UUID) {
          validateNegotiationLink(applicantId: $applicantId) {
            status
          }
        }
      `,
      variables: {
        applicantId,
      },
    }) as Promise<ResponseNode>;
  });
}

function LinkExpired() {
  return <LinkExpiredPage />;
}

function AlreadySubmitted() {
  return <LeaseNegotiationOneCompletePage />;
}

function LinkInvalid() {
  return <LinkInvalidPage />;
}

function LinkEnabled({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

function Content({ applicantId, children }: { applicantId: string; children: ReactNode }) {
  const { isLoading, data } = useQueryValidateToken({ applicantId });
  console.log("fetchTokenValidData", data?.data.validateNegotiationLink?.status);
  if (isLoading) return null;

  const status = data?.data?.validateNegotiationLink?.status;

  switch (status) {
    case "enabled":
      return <LinkEnabled>{children}</LinkEnabled>;
    case "invalid":
      return <LinkInvalid />;
    case "expired":
      return <LinkExpired />;
    case "submitted":
      return <AlreadySubmitted />;
    default:
      return <>Unknown status</>;
  }
}

export function NegotiationLinkProvider({ children }: { children: ReactNode }) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const applicantId = urlSearchParams.get("applicantId") || "";

  return <Content applicantId={applicantId}>{children}</Content>;
}
