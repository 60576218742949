import type { Operation } from "fast-json-patch";
import { useMutation } from "react-query";
import { Item } from "../../../../../@types/RentReady/Item";
import { ItemOption, useAddOptions } from "../../../../../hooks/rentReady/useAddOptions";
import { useRemoveOptions } from "../../../../../hooks/rentReady/useRemoveOptions";
import { useSyncServerToLocalDb } from "../../../../../hooks/rentReady/useSyncServerToLocalDb";
import { useError } from "../../../../../hooks/useError";
import { arrayToRecord } from "../../../../../utils/arrayToRecord";
import { NormalizeItem } from "./useDiff";
import { Value, useUpdateOptions } from "./useUpdateOptions";

type Args = { diff: Operation[]; initialItems: Item[]; items: Item[] };

export function useOnSaveItemList() {
  const error = useError();
  const updateOptions = useUpdateOptions();
  const addOptions = useAddOptions();
  const removeOptions = useRemoveOptions();
  const serverToLocalDb = useSyncServerToLocalDb();

  return useMutation(
    async ({ diff, initialItems, items }: Args) => {
      const replaceOperations = diff.filter((e) => e.op === "replace");
      const removeOperations = diff.filter((e) => e.op === "remove");
      const addOperations = diff.filter((e) => e.op === "add");
      const initialItemMap = arrayToRecord<Item>(initialItems, "uiId");
      const toUpdateItemMap = arrayToRecord<Item>(items, "uiId");

      if (replaceOperations.length > 0) {
        const values: Value[] = [];
        replaceOperations.forEach(({ path }) => {
          const arr = path.split("/").slice(1);
          if (arr.length >= 2) {
            const uiId = arr[0];
            const attr = arr[1];

            //we only want to update isPresent
            if (attr === "isPresent") {
              const item = toUpdateItemMap[uiId];
              values.push({
                itemDataId: item.id,
                isItemPresent: item.isPresent,
              });
            }
          }
        });
        await updateOptions({ values });
      }
      //delete
      if (removeOperations.length > 0) {
        const itemDataIds: string[] = [];

        removeOperations.forEach(({ path }) => {
          const arr = path.split("/").slice(1);
          if (arr.length >= 1) {
            const uiId = arr[0];
            const { id } = initialItemMap[uiId];
            itemDataIds.push(id);
          }
        });
        await removeOptions({ itemDataIds });
      }

      if (addOperations.length > 0) {
        const itemOptions: ItemOption[] = [];
        addOperations.forEach((operation) => {
          const { path, value } = operation as any as { path: string; value: NormalizeItem };
          const arr = path.split("/").slice(1);
          if (arr.length >= 1) {
            const uiId = arr[0];
            const { id } = toUpdateItemMap[uiId];
            itemOptions.push({
              optionId: id,
              isPresent: value.isPresent,
            });
          }
        });
        await addOptions({ itemOptions });
      }
    },
    {
      onSuccess: serverToLocalDb,
      async onError() {
        error({ title: "Error saving changes", description: "" });
      },
    }
  );
}
