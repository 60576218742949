import { OutlineButton } from "@doorstead/components";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useAutoPay } from "../../hooks/useAutoPay";
import { PaymentMethodEdgeNode } from "../../hooks/useFetchMe";
import { useRemovePaymentMethod } from "../../hooks/useRemovePaymentMethod";

export function RemoveButton({ paymentMethodId }: { paymentMethodId: PaymentMethodEdgeNode["paymentMethodId"] }) {
  const autoPay = useAutoPay();
  const autoPayPaymentMethodId = autoPay === null ? null : autoPay.paymentMethod.paymentMethodId;
  const { removePaymentMethod, loading } = useRemovePaymentMethod();
  const isThisPaymentMethodBeingAutoPayUsed = autoPay === null ? false : autoPayPaymentMethodId === paymentMethodId;
  const analytics = useGroupAnalytics();

  return (
    <OutlineButton
      colorScheme="red"
      size="sm"
      disabled={loading}
      isLoading={loading}
      onClick={async () => {
        analytics?.track("TP_REMOVE_PAYMENT_METHOD_CLICKED", {
          paymentMethodId,
        });
        if (isThisPaymentMethodBeingAutoPayUsed) {
          window.alert(
            "This payment method is being used for autopay. To remove it you'll first need to disable autopay."
          );
        } else if (window.confirm("Are you sure to remove this payment method?")) {
          await removePaymentMethod(paymentMethodId, {
            onSuccess() {
              analytics?.track("TP_PAYMENT_METHOD_REMOVED", {
                paymentMethodId,
              });
            },
            onError(err) {
              analytics?.track("TP_REMOVE_PAYMENT_METHOD_FAILED", {
                paymentMethodId,
              });
              window.alert(err);
            },
          });
        }
      }}
    >
      Remove
    </OutlineButton>
  );
}
