import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth";

export function useRedirectIfAuthenticated({ to }: { to: string }) {
  const { loading, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user) {
      navigate(to);
    }
  }, [loading, user]);

  if (loading || user) {
    return null;
  }
}
