const regExp = /^[a-zA-Z\-\s]*$/; // allow alpha, hyphens and spaces only

export function isValid(str: string | null) {
  if (str === null) return true;

  return regExp.test(str);
}

export function replaceAllowedChars(str: string): string {
  if (!isValid(str)) {
    return str.replace(/[^a-zA-Z\-\s]/g, ""); // remove disallowed characters
  } else {
    return str;
  }
}
