import { DateField, Props as DateFieldProps, Format } from "@doorstead/components/fields/DateField";
import { getYearMonthAndDateFromDateString } from "../../../utils/getYearMonthAndDateFromDateString";
import { toPrefixZero } from "./toPrefixZero";

type Props = Omit<DateFieldProps, "format" | "value" | "onChange"> & {
  fromYear: number;
  toYear: number;
  value: string | null;
  onChange: (value: string | null) => void;
};

export function ApplicantDateField({ value, onChange, fromYear, toYear, ...props }: Props) {
  return (
    <DateField
      {...props}
      fromYear={fromYear}
      toYear={toYear}
      value={getYearMonthAndDateFromDateString(value)}
      format={Format["MM/dd/yyyy"]}
      onChange={(value) => {
        if (value === null) {
          onChange(null);
        } else {
          onChange(`${value.year}-${toPrefixZero(value.month)}-${toPrefixZero(value.date)}`);
        }
      }}
    />
  );
}
