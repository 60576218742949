import { Photo } from "src/@types/RentReady/Photo";
import { mapPhotosToPhotoUrlsJson } from "src/utils/rentReady/mapPhotosToPhotoUrlsJson";
import { useIsUploading } from "../rentReady/useIsUploading";
import { PhotoWithPrefix, Prefix, useUpload } from "../rentReady/useUpload";
import { FlexWalkthroughReportNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useSyncSingleWalkthroughReportFieldToCacheAndServer } from "./useSyncSingleWalkthroughReportFieldToCacheAndServer";
import { ArgsReport } from "./useUpdatePartialWalkthroughReportToServer";

export function useSyncWalkthroughReportPhotosToCacheAndServer() {
  const upload = useUpload();
  const syncSingleWalkthroughReportFieldToCacheAndServer = useSyncSingleWalkthroughReportFieldToCacheAndServer();
  const { setIsUploading } = useIsUploading();

  return async (
    cacheField: keyof FlexWalkthroughReportNode,
    serverField: keyof ArgsReport,
    prefix: Prefix,
    photos: Photo[]
  ) => {
    setIsUploading(true);
    const newPhotos = photos.map((photo) => ({ ...photo, prefix } as PhotoWithPrefix));
    await Promise.all(await upload(newPhotos));
    setIsUploading(false);

    const photosToPhotoUrlsJson = mapPhotosToPhotoUrlsJson(newPhotos);
    await syncSingleWalkthroughReportFieldToCacheAndServer(
      cacheField,
      serverField,
      photosToPhotoUrlsJson,
      photosToPhotoUrlsJson
    );
  };
}
