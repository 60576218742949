import { Item } from "../../../../../@types/RentReady/Item";
import { useOnInCompleteItemClick } from "../../../../../hooks/rentReady/useOnInCompleteItemClick";
import { GrayCircle } from "../../../GrayCircle";

type Props = {
  item: Item;
};

export function ItemInCompleteCircle({ item }: Props) {
  const onInCompleteItemClick = useOnInCompleteItemClick(item);

  return <GrayCircle name={item.cosmeticDefect} onClick={onInCompleteItemClick} />;
}
