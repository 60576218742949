import { Flex, StackProps } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethod, loadStripe } from "@stripe/stripe-js";
import { stripePublishableKey } from "src/config";
import { PaymentForm } from "./PaymentForm";

export type Props = StackProps & {
  onDone: (paymentMethod: PaymentMethod) => void;
};

export function PaymentCardBox({ onDone, ...stackProps }: Props) {
  const stripePromise = loadStripe(stripePublishableKey());

  return (
    <Flex w="100%" flexDirection="column" gap={6} alignItems="flex-start" {...stackProps}>
      <Elements
        options={{
          mode: "payment",
          currency: "usd",
          amount: 1000,
          paymentMethodTypes: ["card"],
          paymentMethodCreation: "manual",
          appearance: {
            theme: "stripe",
            labels: "floating",
          },
        }}
        stripe={stripePromise}
      >
        <PaymentForm onDone={onDone} />
      </Elements>
    </Flex>
  );
}
