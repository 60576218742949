import { Box, Image, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components";
import { useRef, useState } from "react";
import { useDefaultPaymentMethodId } from "src/hooks/useDefaultPaymentMethodId";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useHasAgreedAutopayDisclaimer } from "src/hooks/useHasAgreedAutopayDisclaimer";
import { AutoPayDate } from "../../../@types/AutoPay/typings";
import { useAutopayMessage } from "../../../hooks/useAutopayMessage";
import { AutopayDisclaimerText } from "../../AutopayDisclaimerText";
import { CardText } from "../../CardText";
import { EverGreenCard } from "../EverGreenCard";
import { EverGreenModal } from "../EverGreenModal/EverGreenModal";
import { UpsertAutoPayCard } from "./UpsertAutoPayCard";

function Money({ money }: { money: number }) {
  return (
    <Text as="span" color="gray.700" fontWeight="bold" fontSize="md">
      ${money}
    </Text>
  );
}

export function NoData() {
  const { isOpen: isOpenAutopayModal, onOpen: onOpenAutopayModal, onClose: onCloseAutopayModal } = useDisclosure();
  const autopayMessage = useAutopayMessage();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const analytics = useGroupAnalytics();
  const disclaimerRef = useRef<HTMLAnchorElement>(null);
  const hasAgreedAutopayDisclaimer = useHasAgreedAutopayDisclaimer();
  const [continueToSaving, setContinueToSaving] = useState(false);

  return (
    <>
      <EverGreenModal
        title="Add autopay"
        isOpen={isOpenAutopayModal}
        onClose={() => {
          analytics?.track("TP_AUTOPAY_DISMISSED");
          onCloseAutopayModal();
        }}
      >
        <UpsertAutoPayCard
          isEdit={false}
          initValue={{
            paymentMethodId: defaultPaymentMethodId || "",
            payOn: AutoPayDate.TWENTY_EIGHTH,
            nextScheduleDate: "",
          }}
          onDone={async (action) => {
            autopayMessage(action);
            onCloseAutopayModal();
          }}
        />
      </EverGreenModal>
      <EverGreenCard display="flex" alignItems="center" justifyContent="center">
        <VStack gap={3} w="500px">
          <Image boxSize={140} src="/resources/autopay.svg" />
          <Box>NEVER PAY A LATE FEE</Box>
          <CardText>Set up autopay</CardText>
          <Box color="gray.700" fontSize="md">
            If your rent payment is late, you’ll be charged a late fee every day until it’s paid. You will be charged{" "}
            <Money money={50} /> when you miss your due date and <Money money={15} /> for every additional day.
          </Box>
          <PrimaryButton
            size="lg"
            onClick={() => {
              analytics?.track("TP_AUTOPAY_SETUP_CLICKED");
              if (!hasAgreedAutopayDisclaimer) {
                disclaimerRef.current?.click();
                setContinueToSaving(true);
                return;
              }
              onOpenAutopayModal();
            }}
          >
            Set up
          </PrimaryButton>
          <AutopayDisclaimerText
            ref={disclaimerRef}
            onClick={() => setContinueToSaving(false)}
            onAgree={() => continueToSaving && onOpenAutopayModal()}
          />
        </VStack>
      </EverGreenCard>
    </>
  );
}
