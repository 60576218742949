import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react";

export type Props = {
  howManyAdults: number | null;
  setHowManyAdults: Dispatch<SetStateAction<number | null>>;
  haveGuarantor: boolean | null;
  setHaveGuarantor: Dispatch<SetStateAction<boolean | null>>;
};

const context = createContext<Props>({
  howManyAdults: null,
  setHowManyAdults: console.log,
  haveGuarantor: null,
  setHaveGuarantor: console.log,
});

export function InternalStateProvider({ children, value }: { children: ReactNode; value: Props }) {
  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useInternalState() {
  return useContext(context);
}
