import { ChosenVendorRepair } from "../../@types/RepairList/Repair";
import { useToUpdateRepairEstimateApprovedStore } from "../../components/rr-project/stores/useToUpdateRepairEstimateApprovedStore";
import { isRepairPendingEstimateApproval } from "../../utils/rr-project/isRepairPendingEstimateApporval";
import { useEstimateToApproveRepairs } from "./useEstimateToApproveRepairs";

export function useGetToEstimatesCount() {
  const repairs = useEstimateToApproveRepairs().filter(isRepairPendingEstimateApproval);
  const { hasEstimateApprove, getEstimateApprove } = useToUpdateRepairEstimateApprovedStore();

  return (estimateApproved: ChosenVendorRepair["estimateApproved"]): number => {
    let count = 0;
    repairs.forEach((repair) => {
      if (hasEstimateApprove(repair.id)) {
        if (getEstimateApprove(repair.id) === estimateApproved) {
          count++;
        }
      } else if (repair.chosenVendorRepair?.estimateApproved === estimateApproved) {
        count++;
      }
    });
    return count;
  };
}
