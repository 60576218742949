import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { mapKeyDetailFromRRGraphQLResultCache } from "./mapKeyDetailFromRRGraphQLResultCache";

export async function updateKeyDetailCache(inspectionReportId: string, keyDetail: KeyDetail) {
  const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
  if (graphQLCacheResult !== null) {
    const result = mapKeyDetailFromRRGraphQLResultCache(graphQLCacheResult, keyDetail);
    await writeRRGraphQLResult(inspectionReportId, result);
    return result;
  }
  return null;
}
