import { CosmeticOption } from "../../@types/RentReady/Item";

export function sortCosmeticOptionsById(cosmeticOptions: CosmeticOption[]): CosmeticOption[] {
  return [...cosmeticOptions].sort((a, b) => a.id.localeCompare(b.id));
}

export function isCosmeticOptionsDiff(cosmeticOptions1: CosmeticOption[], cosmeticOptions2: CosmeticOption[]): boolean {
  const sortedCosmeticOptions1 = sortCosmeticOptionsById(cosmeticOptions1);
  const sortedCosmeticOptions2 = sortCosmeticOptionsById(cosmeticOptions2);

  // Check if the lengths of the arrays are different
  if (sortedCosmeticOptions1.length !== sortedCosmeticOptions2.length) {
    return true;
  }

  // Iterate over each cosmetic option in the sorted arrays
  for (let i = 0; i < sortedCosmeticOptions1.length; i++) {
    const option1 = sortedCosmeticOptions1[i];
    const option2 = sortedCosmeticOptions2[i];

    // Compare the properties of the cosmetic options
    if (!areCosmeticOptionsEqual(option1, option2)) {
      return true;
    }
  }

  return false;
}

function areCosmeticOptionsEqual(option1: CosmeticOption, option2: CosmeticOption): boolean {
  // Compare the name and selected properties of the cosmetic options
  return option1.id === option2.id && option1.name === option2.name && option1.selected === option2.selected;
}
