import { Box, Flex, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { InformationFilledIcon } from "@doorstead/components/icons/InformationFilledIcon";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { PaymentMethod } from "src/components/PaymentMethod";
import { PaymentMethodEdgeNode } from "src/hooks/useFetchMe";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { usePaymentMethods } from "src/hooks/usePaymentMethods";
import { AddPaymentMethodModal } from "./AddPaymentMethodModal";
import { context } from "./useInternalState";

type Props = {
  setPaymentMethodId: (paymentMethodId: string | null) => void;
};

export function PaymentMethodSection(props: Props) {
  const { setPaymentMethodId } = props;
  const paymentMethods = usePaymentMethods();

  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState<PaymentMethodEdgeNode | null>(
    getDefaultPaymentMethod(paymentMethods)
  );

  const analytics = useGroupAnalytics();

  function getDefaultPaymentMethod(paymentMethods: Array<PaymentMethodEdgeNode | null>) {
    if (paymentMethods.length === 0) {
      return null as PaymentMethodEdgeNode | null;
    }
    let defaultPaymentMethod = paymentMethods[0];
    for (let i = 1; i < paymentMethods.length; i++) {
      if (paymentMethods[i]?.isDefault) {
        defaultPaymentMethod = paymentMethods[i];
        break;
      }
    }
    return defaultPaymentMethod;
  }

  useEffect(() => {
    const currentPaymentMethod = getDefaultPaymentMethod(paymentMethods);
    if (!isEmpty(currentPaymentMethod)) {
      setCurrentPaymentMethod(currentPaymentMethod);
      setPaymentMethodId(currentPaymentMethod?.paymentMethodId);
    } else {
      setCurrentPaymentMethod(null);
      setPaymentMethodId(null);
    }
  }, [paymentMethods]);

  const openAddPaymentMethodModal = () => {
    analytics?.track("TP_ADD_PAYMENT_METHOD_CLICKED");
    onOpen();
  };

  const renderPaymentMethods = () => {
    // no payment methods, show add payment method box
    if (!currentPaymentMethod) {
      return (
        <Flex
          direction="row"
          justifyContent="space-between"
          bgColor="gray.50"
          px="5"
          py="6"
          width="100%"
          borderRadius="6px"
          borderBottom="1px solid #E2E8F0"
          boxShadow="1px 1px #E2E8F0"
        >
          <Box>
            <Flex direction="row" alignItems="center" height="40px">
              <InformationFilledIcon color="gray.400" size={24} />
              <Text lineHeight="24px" ml="10px" color="black">
                Add payment method to continue
              </Text>
            </Flex>
          </Box>
          <PrimaryButton size="md" onClick={openAddPaymentMethodModal}>
            Add payment method
          </PrimaryButton>
        </Flex>
      );
    }
    return <PaymentMethod {...currentPaymentMethod} hideIsDefaultArea={true} />;
  };

  return (
    <context.Provider value={{ loading, setLoading, isOpen, onOpen, onClose }}>
      <VStack w="100%">
        <Box width="100%" fontWeight="bold" fontSize="lg" textAlign="left" lineHeight="24px" mb="5">
          Payment method
        </Box>
        <AddPaymentMethodModal />
        {renderPaymentMethods()}
      </VStack>
    </context.Provider>
  );
}
