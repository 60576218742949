import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";
import { removeOccupant } from "./useRemoveOccupant";

export function useClearOccupants() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async () => {
    const ids: string[] = [];
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        draft.applicantById.occupants.edges.forEach((edge) => {
          ids.push(edge.node.applicantOccupantId);
        });

        draft.applicantById.occupants.edges = [];
      })
    );
    await Promise.all(ids.map((id) => removeOccupant(gqlClient, id)));
  });
}
