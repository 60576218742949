import { Box, Flex, HStack, Link, VStack } from "@chakra-ui/react";
import { DoorsteadLogoWithoutText } from "@doorstead/components/DoorsteadLogoWithoutText";
import { DebugIcon } from "@doorstead/components/icons/DebugIcon";
import { OfflineIcon } from "@doorstead/components/icons/OfflineIcon";
import { RefreshIcon } from "@doorstead/components/icons/RefreshIcon";
import { ReactNode } from "react";
import { AreaOneView } from "src/components/rent-ready/views/AreaOneView/AreaOneView";
import { StatusFixConfirmationDrawerProvider } from "src/hooks/rr-project/useStatusFixConfirmationDrawer";
import { Error } from "../../components/Error";
import { InstructionIcon } from "../../components/rent-ready/InstructionIcon";
import { AreaListEditView } from "../../components/rent-ready/views/AreaListEditView";
import { AreaListView } from "../../components/rent-ready/views/AreaListView";
import { AreaOverviewView } from "../../components/rent-ready/views/AreaOverviewView";
import { ComponentEditView } from "../../components/rent-ready/views/ComponentEditView";
import { EstimateEditView } from "../../components/rent-ready/views/EstimateEditView";
import { EstimateListView } from "../../components/rent-ready/views/EstimateListView";
import { ItemEditView } from "../../components/rent-ready/views/ItemEditView";
import { ItemListEditView } from "../../components/rent-ready/views/ItemListEditView";
import { KeyDetailsView } from "../../components/rent-ready/views/KeyDetailsView";
import { KeyStorageView } from "../../components/rent-ready/views/KeyStorageView";
import { PropertyDetailEditView } from "../../components/rent-ready/views/PropertyDetailEditView";
import { PropertyDetailView } from "../../components/rent-ready/views/PropertyDetailView";
import { StartView } from "../../components/rent-ready/views/StartView";
import { ExpiredView } from "../../components/rent-ready/views/StartView/ExpiredView";
import { ThroughoutPropertyCleaningEditView } from "../../components/rent-ready/views/ThroughoutPropertyCleaningEditView";
import { ThroughoutPropertyDetectorEditView } from "../../components/rent-ready/views/ThroughoutPropertyDetectorEditView";
import { bugReportLink } from "../../config";
import { Provider as AreaIdProvider } from "../../hooks/rentReady/useAreaId";
import { Provider as ComponentIdProvider } from "../../hooks/rentReady/useComponentId";
import { Provider as DrawerProvider } from "../../hooks/rentReady/useDrawer";
import { Provider as EstimateIdProvider } from "../../hooks/rentReady/useEstimateId";
import { useFetchInspectionReport } from "../../hooks/rentReady/useFetchInspectionReport";
import { useInitialView } from "../../hooks/rentReady/useInitialView";
import { useIsDebugMode } from "../../hooks/rentReady/useIsDebugMode";
import { Provider as IsEditableProvider } from "../../hooks/rentReady/useIsEditable";
import { Provider as IsUploadingProvider } from "../../hooks/rentReady/useIsUploading";
import { Provider as ItemIdProvider } from "../../hooks/rentReady/useItemId";
import { Provider as UploadProgressProvider } from "../../hooks/rentReady/useUploadProgress";
import { View, Provider as ViewProvider, useView } from "../../hooks/rentReady/useView";
import { getHasHeader } from "../../hooks/rentReady/useView/getHasHeader";
import { useIsOffline } from "../../hooks/useIsOffline";
import { clearCache } from "./RentReadyDebugPage";

function FetchInspectionReport({ children }: { children: ReactNode }) {
  const { isFetching, isFetched, data } = useFetchInspectionReport();
  if (isFetching && !isFetched) {
    return null;
  } else if (!data || data.inspectionReport === null) {
    return <Error title="Can not find this property" />;
  }

  return <IsEditableProvider value={data.inspectionReport.submittedTime === null}>{children}</IsEditableProvider>;
}

function Main() {
  const { view } = useView();

  switch (view) {
    case View.StartView:
      return <StartView />;
    case View.ExpiredView:
      return <ExpiredView />;
    case View.PropertyDetailView:
      return <PropertyDetailView />;
    case View.PropertyDetailEditView:
      return <PropertyDetailEditView />;
    case View.AreaListView:
      return <AreaListView />;
    case View.AreaOneView:
      return <AreaOneView />;
    case View.ItemListEditView:
      return <ItemListEditView />;
    case View.ComponentEditView:
      return <ComponentEditView />;
    case View.AreaOverviewView:
      return <AreaOverviewView />;
    case View.ItemEditView:
      return <ItemEditView />;
    case View.AreaListEditView:
      return <AreaListEditView />;
    case View.KeyDetailsView:
      return <KeyDetailsView />;
    case View.KeyStorageView:
      return <KeyStorageView />;
    case View.EstimateListView:
      return <EstimateListView />;
    case View.EstimateEditView:
      return <EstimateEditView />;
    case View.ThroughoutPropertyCleaningEditView:
      return <ThroughoutPropertyCleaningEditView />;
    case View.ThroughoutPropertyDetectorEditView:
      return <ThroughoutPropertyDetectorEditView />;
    default:
      return null;
  }
}

function InitialView({ children }: { children: ReactNode }) {
  const view = useInitialView();
  return <ViewProvider view={view}>{children}</ViewProvider>;
}

function Title() {
  const { view } = useView();

  return (
    <HStack>
      <Box>
        {view === View.PropertyDetailView && "1. Review property details"}
        {view === View.AreaListView && "2. Complete evaluation"}
        {view === View.EstimateListView && "3. Add estimates"}
      </Box>
      <InstructionIcon view={view} />
    </HStack>
  );
}

function LoadingLayout({ children }: { children: ReactNode }) {
  const { data, isLoading } = useFetchInspectionReport();
  const { view } = useView();
  const hasHeader = getHasHeader(view);
  const isOffline = useIsOffline();
  const isDebugMode = useIsDebugMode();

  return (
    <VStack minH="100vh" spacing={0} padding={0} alignItems="flex-start">
      {hasHeader && (
        <VStack as="header" w="100%" p={4} alignItems="flex-start">
          <Flex alignItems="center" justifyContent="space-between" minH="24px" w="100%">
            <DoorsteadLogoWithoutText size={24} />
            <Flex
              h="24px"
              flex={1}
              fontSize="12px"
              color="gray.500"
              fontWeight="bold"
              px={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              {isLoading ? "" : data?.inspectionReport.propertyAddress || ""}
            </Flex>
            <Box onClick={clearCache}>
              <RefreshIcon size={24} color="black" />
            </Box>
          </Flex>
          <HStack fontSize="2xl" fontWeight="bold" spacing={4}>
            <Title />
            {isOffline && <OfflineIcon />}
            {isDebugMode && (
              <>
                <Link target="_blank" href={bugReportLink}>
                  <DebugIcon size={32} color="black" />
                </Link>
              </>
            )}
          </HStack>
        </VStack>
      )}
      <Flex as="main" flex={1} alignItems="stretch" w="100%" h="100%">
        {children}
      </Flex>
    </VStack>
  );
}

export function RentReadyLayout() {
  return (
    <UploadProgressProvider>
      <IsUploadingProvider>
        <AreaIdProvider>
          <ItemIdProvider>
            <EstimateIdProvider>
              <ComponentIdProvider>
                <DrawerProvider>
                  <StatusFixConfirmationDrawerProvider>
                    <FetchInspectionReport>
                      <InitialView>
                        <LoadingLayout>
                          <Main />
                        </LoadingLayout>
                      </InitialView>
                    </FetchInspectionReport>
                  </StatusFixConfirmationDrawerProvider>
                </DrawerProvider>
              </ComponentIdProvider>
            </EstimateIdProvider>
          </ItemIdProvider>
        </AreaIdProvider>
      </IsUploadingProvider>
    </UploadProgressProvider>
  );
}
