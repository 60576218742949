import { Box, Flex, Image } from "@chakra-ui/react";

export function AutoPayBox() {
  return (
    <Flex backgroundImage="/resources/bg.svg" w="100%" h={130} borderRadius={6} padding={6}>
      <Flex justifyContent="center" alignItems="center" w="30%">
        <Image height="100%" src="/resources/autopay.svg" />
      </Flex>
      <Flex direction="column" flex={1} color="gray.700">
        <Box fontWeight="bold">Save time with autopay</Box>
        <Box>Life can get busy. Save a little time by setting up automatic payments.</Box>
      </Flex>
    </Flex>
  );
}
