import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { ApplicantFileNodeType } from "../../../../../hooks/applicants/useFetchApplicationMe/ApplicantFileNode";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { ToUploadButton } from "./ToUploadButton";
import { UploadedView } from "./UploadedView";
import { UploadingView } from "./UploadingView";
import { Provider, useInternalState } from "./useInternalState";

export type Props = {
  applicantFileType: ApplicantFileNodeType;
  title: React.ReactNode;
};

function Content({ title, applicantFileType }: Props) {
  const applicantNode = useFetchedApplicant();
  const { uploadingFilesMap } = useInternalState();
  const applicantFiles =
    applicantNode.files.edges.filter((e) => e.node.type === applicantFileType)?.map((e) => e.node) || [];

  return (
    <Box w="100%" display="grid" gridTemplateColumns="repeat(2, 1fr)" gridTemplateRows="auto auto" gridRow="1 / span 1">
      <Box gridColumn="1 / span 1" alignSelf="center">
        {title}
      </Box>
      <Box gridColumn="2 / span 1" textAlign="right">
        <ToUploadButton />
      </Box>
      <VStack spacing={3} gridRow="2 / span 1" gridColumn="1 / -1" mt={4}>
        {applicantFiles.map((applicantFile) => (
          <UploadedView
            key={applicantFile.applicantFileId}
            applicantFileType={applicantFileType}
            applicantFile={applicantFile}
          />
        ))}
        {Object.values(uploadingFilesMap).map(({ id, file, progress, cancelTokenSource }) => (
          <UploadingView
            key={id}
            id={id}
            fileName={file.name}
            progress={progress}
            cancelTokenSource={cancelTokenSource}
          />
        ))}
      </VStack>
    </Box>
  );
}

export function UploadButton(props: Props) {
  return (
    <Provider applicantFileType={props.applicantFileType}>
      <Content {...props} />
    </Provider>
  );
}
