import produce from "immer";
import { useQuery, useQueryClient } from "react-query";
import { db, readFlexWalkthroughGraphQLResultCache } from "../../../databases/FlexWalkthroughDatabase";
import { isOffline } from "../../../utils/isOffline";
import { useFlexWalkthroughReportId } from "../useFlexWalkthroughReportId";
import { FlexWalkthroughReportNode } from "./FlexWalkthroughReportNode";
import { fetchFlexWalkthroughGraphQLResultServer } from "./fetchFlexWalkthroughGraphQLResultServer";
import { useMergeCacheWithServer } from "./useMergeCacheWithServer";

export const queryKey = "useFetchFlexWalkthroughReport";

export type FlexWalkthroughGraphQLResult = {
  flexWalkthroughReport: FlexWalkthroughReportNode;
};

export function useFetchFlexWalkthroughReport() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();
  const mergeCacheWithServer = useMergeCacheWithServer();

  return useQuery<unknown, unknown, FlexWalkthroughGraphQLResult>(queryKey, async () => {
    if (isOffline()) {
      return readFlexWalkthroughGraphQLResultCache(flexWalkthroughReportId);
    } else {
      const flexWalkthroughGraphQLResultCache = await readFlexWalkthroughGraphQLResultCache(flexWalkthroughReportId);
      const flexWalkthroughGraphQLResultServer = await fetchFlexWalkthroughGraphQLResultServer(flexWalkthroughReportId);

      if (flexWalkthroughGraphQLResultCache === null) {
        //if the local db is not inserted, just insert the server data
        await db.flex_walkthrough_report_graphql_result.put({
          ...flexWalkthroughGraphQLResultServer,
          flex_walkthrough_report_id: flexWalkthroughReportId,
        });
      } else {
        await mergeCacheWithServer(flexWalkthroughGraphQLResultCache, flexWalkthroughGraphQLResultServer);
      }
      return flexWalkthroughGraphQLResultServer;
    }
  });
}

export function useUpdateFlexWalkthroughReportData() {
  const queryClient = useQueryClient();

  return (partialFlexWalkthroughReport: Partial<FlexWalkthroughReportNode>): FlexWalkthroughGraphQLResult => {
    const newData = produce(queryClient.getQueryData(queryKey), (draft: FlexWalkthroughGraphQLResult) => {
      draft.flexWalkthroughReport = Object.assign(draft.flexWalkthroughReport, partialFlexWalkthroughReport);
    });
    queryClient.setQueryData(queryKey, newData);

    return newData as FlexWalkthroughGraphQLResult;
  };
}
