import { ItemEditState } from "../../components/rent-ready/views/ItemEditView/ItemEditView";
import { db, readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapItemStateFromRRGraphQLResultCache } from "./useMapItemStateFromRRGraphQLResultCache";

export type ItemState = ItemEditState & { id: string };

export function useUpdateItemCache() {
  const inspectionReportId = useInspectionReportId();
  const mapItemStateFromRRGraphQLResultCache = useMapItemStateFromRRGraphQLResultCache();

  return async (state: ItemState) => {
    return db.transaction("rw", db.rr_graphql_result, async () => {
      const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
      if (graphQLCacheResult === null) return null;

      const result = mapItemStateFromRRGraphQLResultCache(graphQLCacheResult, state);
      await writeRRGraphQLResult(inspectionReportId, result);
      return result;
    });
  };
}
