import { Image, Photo } from "../../@types/RentReady/Photo";

function isObjectValid(object: Record<string, any>, keys: (keyof Image)[]) {
  return object && keys.every((key) => key in object);
}

export function mapPhotoUrlsJsonToPhotos(json: string | null): Photo[] {
  if (json === null) return [];

  try {
    const unknownObject: any = JSON.parse(json);
    if (!Array.isArray(unknownObject)) return [];

    const photoKeys: (keyof Photo)[] = ["id", "src", "thumbnail"];
    const imageKeys: (keyof Image)[] = ["bucket", "key", "fileName"];

    return unknownObject
      .filter((e) => photoKeys.every((key) => key in e))
      .filter((e: Photo) => isObjectValid(e.src, imageKeys) && isObjectValid(e.thumbnail, imageKeys))
      .map((e) => {
        if (!e.isUploaded && e.toUploadFile && e.toUploadFile.dataUrl) {
          return {
            ...e,
            isUploaded: false,
            toUploadFile: {
              dataUrl: e.toUploadFile.dataUrl,
            },
          };
        } else {
          return {
            ...e,
            isUploaded: true,
            toUploadFile: null,
          };
        }
      });
  } catch (e) {
    console.error(e);
    return [];
  }
}
