import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { BinIcon } from "@doorstead/components/icons/BinIcon";
import { Fragment } from "react";
import { attachCounting } from "../../../../utils/rentReady/attachCounting";
import { moveBuildingAndYardToTheFirst } from "../../../../utils/rentReady/moveBuildingAndYardToTheFirst";
import { sortByField } from "../../../../utils/rentReady/sortByField";
import { Record } from "./Record";

type Props = {
  values: Record[];
  onDelete: (id: string) => void;
  hasDeleted: boolean;
};

export function RecordList({ values, onDelete, hasDeleted }: Props) {
  return (
    <VStack w="100%" p={4}>
      {moveBuildingAndYardToTheFirst(sortByField(attachCounting(values, "name", "name"), "name")).map(
        ({ id, name }) => (
          <Fragment key={id}>
            <HStack w="100%">
              <Box flex={1} color="gray.500" fontWeight="bold">
                {name}
              </Box>
              {hasDeleted && (
                <Box onClick={() => onDelete(id)}>
                  <BinIcon size={24} color="gray.500" />
                </Box>
              )}
            </HStack>
            <Divider />
          </Fragment>
        )
      )}
    </VStack>
  );
}
