import { Flex } from "@chakra-ui/react";
import { ExclamationIcon } from "@doorstead/components/icons/ExclamationIcon";
import { useOpenInstruction } from "src/hooks/flexWalkthrough/useDisplayInstruction";

type Props = {
  text: string;
};

export function InstructionIcon({ text }: Props) {
  const openInstruction = useOpenInstruction(text);

  return (
    <Flex justifyContent="center" alignItems="center" onClick={openInstruction}>
      <ExclamationIcon size={20} />
    </Flex>
  );
}
