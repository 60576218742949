import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeInspectionReportCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    cache.inspectionReport.isThroughoutPropertyFeature =
      rrGraphQLResultServer.inspectionReport.isThroughoutPropertyFeature;
    cache.inspectionReport.isV2UiFeature = rrGraphQLResultServer.inspectionReport.isV2UiFeature;
    cache.inspectionReport.isKeyDetailsAdditionalKeysFeature =
      rrGraphQLResultServer.inspectionReport.isKeyDetailsAdditionalKeysFeature;
    cache.inspectionReport.isKeyStorageFullTenantSetsFeature =
      rrGraphQLResultServer.inspectionReport.isKeyStorageFullTenantSetsFeature;
    cache.inspectionReport.isKeyStorageAdditionalKeyStorageFeature =
      rrGraphQLResultServer.inspectionReport.isKeyStorageAdditionalKeyStorageFeature;
    cache.inspectionReport.shouldShowEstimatesStep = rrGraphQLResultServer.inspectionReport.shouldShowEstimatesStep;

    cache.inspectionReport.startTime = rrGraphQLResultServer.inspectionReport.startTime;
    cache.inspectionReport.completedEvalTime = rrGraphQLResultServer.inspectionReport.completedEvalTime;
    cache.inspectionReport.completedEstimatesTime = rrGraphQLResultServer.inspectionReport.completedEstimatesTime;
    cache.inspectionReport.submittedTime = rrGraphQLResultServer.inspectionReport.submittedTime;

    //sync instructions
    cache.inspectionReport.propertyDetailsInstructionTime =
      rrGraphQLResultServer.inspectionReport.propertyDetailsInstructionTime;
    cache.inspectionReport.keyStorageAndKeyDetailsInstructionTime =
      rrGraphQLResultServer.inspectionReport.keyStorageAndKeyDetailsInstructionTime;
    cache.inspectionReport.areaListInstructionTime = rrGraphQLResultServer.inspectionReport.areaListInstructionTime;
    cache.inspectionReport.areaOverviewInstructionTime =
      rrGraphQLResultServer.inspectionReport.areaOverviewInstructionTime;
    cache.inspectionReport.itemListAddRemoveInstructionTime =
      rrGraphQLResultServer.inspectionReport.itemListAddRemoveInstructionTime;
    cache.inspectionReport.itemListTypeInstructionTime =
      rrGraphQLResultServer.inspectionReport.itemListTypeInstructionTime;
    cache.inspectionReport.estimationInstructionTime = rrGraphQLResultServer.inspectionReport.estimationInstructionTime;
    cache.inspectionReport.keyDetailsInstructionTime = rrGraphQLResultServer.inspectionReport.keyDetailsInstructionTime;
    cache.inspectionReport.keyStorageInstructionTime = rrGraphQLResultServer.inspectionReport.keyStorageInstructionTime;
  });
}
