import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { TextArea } from "@doorstead/components";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";

export type Props = {
  id: string;
  defaultValue: string | null;
};

function onSyncToServer({
  repairId,
  ownerNotes,
  gqlClient,
}: {
  repairId: string;
  ownerNotes: string;
  gqlClient: ApolloClient<NormalizedCacheObject>;
}) {
  gqlClient.mutate({
    mutation: gql`
      mutation update($repairs: [UpdateRepairData]) {
        updateRepairs(repairs: $repairs) {
          count
        }
      }
    `,
    variables: {
      repairs: [
        {
          repairId,
          ownerNotes,
        },
      ],
    },
  });
}

export function NoteField({ id, defaultValue }: Props) {
  const { gqlClient } = useAuth();
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, 500), [onSyncToServer]);
  const [value, setValue] = useState<string>(defaultValue || "");

  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  return (
    <TextArea
      placeholder="Add notes...(Optional)"
      value={value}
      onChange={(value) => {
        setValue(value);
        debouncedSyncToServer({
          repairId: id,
          ownerNotes: value,
          gqlClient,
        });
      }}
    />
  );
}
