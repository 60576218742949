import produce from "immer";
import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";

export function useMapThroughoutPropertyCleaningFromRRGraphQLResultCache() {
  return (cache: RRGraphQLResultCache, state: ThroughoutPropertyCleaning): RRGraphQLResultCache => {
    return produce(cache, (draft) => {
      draft.inspectionReport.cleanIsNeeded = state.cleanIsNeeded;
      draft.inspectionReport.cleanSelectedOptions = state.cleanSelectedOptions;
      draft.inspectionReport.cleanIsOdorRemoval = state.cleanIsOdorRemoval;
      draft.inspectionReport.cleanNotes = state.cleanNotes;
    });
  };
}
