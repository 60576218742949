import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
  itemId: string;
  setItemId: (itemId: string) => void;
};

const context = createContext<Props>({
  itemId: "",
  setItemId(itemId) {
    console.log(itemId);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [itemId, setItemId] = useState("");

  return (
    <context.Provider
      value={{
        itemId,
        setItemId,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useItemId() {
  return useContext(context);
}
