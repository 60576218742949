import { useEffect } from "react";
import { useDebounce } from "./useDebounce";
import { PaymentMethodType } from "./useFetchMe";
import { useFetchStripePaymentAmount } from "./useFetchStripePaymentAmount";

export function useChargeBreakdown(
  amountDue: number,
  paymentMethodType: PaymentMethodType | null | undefined
): { cardTransactionFee: number; chargeAmount: number; isFetching: boolean } {
  const debouncedAmountDue = useDebounce(amountDue, 250);
  const { data, refetch, isFetching } = useFetchStripePaymentAmount(Math.round(debouncedAmountDue * 100));

  useEffect(() => {
    refetch();
  }, [debouncedAmountDue]);

  if (isFetching) {
    return {
      cardTransactionFee: 0,
      chargeAmount: 0,
      isFetching,
    };
  }

  if (paymentMethodType === PaymentMethodType.Card && data) {
    return {
      cardTransactionFee: data.stripePaymentAmount.cardTransactionFeeInCents / 100,
      chargeAmount: data.stripePaymentAmount.totalChargeInCents / 100,
      isFetching,
    };
  }

  return {
    cardTransactionFee: 0,
    chargeAmount: debouncedAmountDue,
    isFetching,
  };
}
