import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { useAreasCache } from "../../../../hooks/rentReady/useAreasCache";
import { useIsKeyDetailComplete } from "../../../../hooks/rentReady/useIsKeyDetailComplete";
import { useIsKeyStorageComplete } from "../../../../hooks/rentReady/useIsKeyStorageComplete";
import { useIsThroughoutPropertyCleaningComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyCleaningComplete";
import { useIsThroughoutPropertyDetectorComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyDetectorComplete";
import { useIsThroughoutPropertyFeature } from "../../../../hooks/rentReady/useIsThroughoutPropertyFeature";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { useSubmitAreaList } from "../../../../hooks/rentReady/useSubmitAreaList";
import { isAreaComplete } from "../../../../utils/rentReady/isAreaComplete";

function useIsCleaningComplete() {
  const isThroughoutPropertyFeature = useIsThroughoutPropertyFeature();
  const isThroughoutPropertyCleaningComplete = useIsThroughoutPropertyCleaningComplete();
  const isThroughoutPropertyDetectorComplete = useIsThroughoutPropertyDetectorComplete();

  if (!isThroughoutPropertyFeature) {
    return true;
  }

  return isThroughoutPropertyCleaningComplete && isThroughoutPropertyDetectorComplete;
}

export function useIsAreaListSubmitDisabled() {
  const { isLoading } = useSubmitAreaList();
  const areas = useAreasCache();
  const isV2 = useIsV2UiFeatures();
  const isKeyDetailComplete = useIsKeyDetailComplete();
  const isKeyStorageComplete = useIsKeyStorageComplete();
  const isCleaningComplete = useIsCleaningComplete();
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  if (shouldUseSimplifiedTemplate) {
    return (
      !areas.every((area) => isAreaComplete(area, isV2, shouldUseSimplifiedTemplate)) ||
      !isKeyDetailComplete ||
      !isKeyStorageComplete ||
      isLoading
    );
  }

  return (
    !areas.every((area) => isAreaComplete(area, isV2, shouldUseSimplifiedTemplate)) ||
    !isKeyDetailComplete ||
    !isKeyStorageComplete ||
    !isCleaningComplete ||
    isLoading
  );
}
