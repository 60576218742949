import { AutoPayDate } from "./typings";

export function getLabel(date: AutoPayDate): string {
  switch (date) {
    case AutoPayDate.FIRST:
      return `1st of every month`;
    case AutoPayDate.SECOND:
      return `2nd of every month`;
    case AutoPayDate.THIRD:
      return `3rd of every month`;
    case AutoPayDate.TWENTY_EIGHTH:
      return `28th of every month`;
    case AutoPayDate.LAST:
      return `Month-end`;
    default:
      return "";
  }
}
