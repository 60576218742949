import { Box } from "@chakra-ui/react";
import { HintBox } from "@doorstead/components/containers/HintBox";
import { moneyString } from "@doorstead/components/utils";
import { useMonthlyRent } from "../../hooks/useMonthlyRent";

export function MonthlyChargesHintBox() {
  const monthlyCharges = useMonthlyRent();
  if (monthlyCharges === null) {
    return null;
  }

  return (
    <HintBox>
      <Box fontSize="sm">
        <Box as="span" color="gray.500" mr={1}>
          Your monthly charges are
        </Box>
        {moneyString(monthlyCharges)}/month
      </Box>
    </HintBox>
  );
}
