import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapThroughoutPropertyCleaningFromRRGraphQLResultCache } from "./useMapThroughoutPropertyCleaningFromRRGraphQLResultCache";

export function useUpdateThroughoutPropertyCleaningCache() {
  const inspectionReportId = useInspectionReportId();
  const mapThroughoutPropertyCleaningFromRRGraphQLResultCache =
    useMapThroughoutPropertyCleaningFromRRGraphQLResultCache();

  return async (throughoutPropertyCleaning: ThroughoutPropertyCleaning) => {
    const graphQLCacheResultCache = await readRRGraphQLResultCache(inspectionReportId);

    if (graphQLCacheResultCache === null) return null;

    const cacheResult = mapThroughoutPropertyCleaningFromRRGraphQLResultCache(
      graphQLCacheResultCache,
      throughoutPropertyCleaning
    );
    await writeRRGraphQLResult(inspectionReportId, cacheResult);

    return cacheResult;
  };
}
