import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { GoodMissingFixStatus } from "./useFetchInspectionReport/InspectionReportNode";

export type ArgsItem = {
  itemDataId: string;
  status: GoodMissingFixStatus;
  notes: string;
  photoUrlsJson: string;
};

export type Args = {
  items: (Partial<ArgsItem> & {
    itemDataId: string;
  })[];
};

type Result = {
  data: {
    updateInspectionReportItems: {
      count: number;
    };
  };
};

export function useUpdatePartialItemToServer() {
  const { gqlClient } = useAuth();

  return async ({ items }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation ($items: [UpdateInspectionReportItemsOption]) {
          updateInspectionReportItems(items: $items) {
            count
          }
        }
      `,
      variables: {
        items,
      },
    })) as Result;
  };
}
