import { PrimaryButton } from "@doorstead/components/buttons";
import { RRProjectNodeStatus } from "../../../hooks/rr-project/useFetchRRProject";
import { useUpdateRRProjectStatus } from "../../../hooks/rr-project/useUpdateRRProjectStatus";
import { View, useView } from "../../../hooks/rr-project/useView";
import { Guideline } from "../Guideline";

export function GuidelineView() {
  const { setView } = useView();
  const { mutate, isLoading } = useUpdateRRProjectStatus();

  return (
    <Guideline
      submit={
        <PrimaryButton
          isLoading={isLoading}
          onClick={() => {
            mutate(RRProjectNodeStatus.RepairList, {
              onSuccess() {
                setView(View.RepairListView);
              },
            });
          }}
        >
          See the repair list
        </PrimaryButton>
      }
    />
  );
}
