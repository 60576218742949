import { useArea } from "./useArea";
import { AreaNode } from "./useFetchInspectionReport/AreaNode";
import { useUpdatePartialAreaToCache } from "./useUpdatePartialAreaToCache";
import { ArgsArea, useUpdatePartialAreaToServer } from "./useUpdatePartialAreaToServer";

export function useSyncSingleAreaFieldToCacheAndServer() {
  const area = useArea()!;
  const updatePartialAreaToServer = useUpdatePartialAreaToServer();
  const updatePartialAreaToCache = useUpdatePartialAreaToCache();

  return async (cacheField: keyof AreaNode, serverField: keyof ArgsArea, cacheValue: any, serverValue: any) => {
    await updatePartialAreaToCache({
      field: cacheField,
      value: cacheValue,
    });
    await updatePartialAreaToServer({
      areas: [
        {
          areaDataId: area.id,
          [serverField]: serverValue,
        },
      ],
    });
  };
}
