import { gql } from "@apollo/client";
import { useQuery } from "react-query";
import { useAuth } from "../contexts/auth";

export const queryKey = "useFetchStripePaymentAmount";

export type StripePaymentAmountNode = {
  goalAmountInCents: number;
  cardTransactionFeeInCents: number;
  totalChargeInCents: number;
};

export type StripePaymentAmountResult = {
  stripePaymentAmount: StripePaymentAmountNode;
};

const query = gql`
  query GetStripePaymentAmount($goalAmountInCents: Int!) {
    stripePaymentAmount(goalAmountInCents: $goalAmountInCents) {
      goalAmountInCents
      cardTransactionFeeInCents
      totalChargeInCents
    }
  }
`;

export function useFetchStripePaymentAmount(goalAmountInCents: number) {
  const { gqlClient } = useAuth();
  return useQuery<unknown, unknown, StripePaymentAmountResult>(queryKey, async () => {
    return await gqlClient.query({ query, variables: { goalAmountInCents } }).then((e) => e.data);
  });
}
