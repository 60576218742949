import { VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useEffect, useState } from "react";
import { KeyStorage } from "../../../../@types/RentReady/KeyStorage";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsKeyStorageAdditionalKeyStorageFeature } from "../../../../hooks/rentReady/useIsKeyStorageAdditionalKeyStorageFeature";
import { useIsKeyStorageFullTenantSetsFeature } from "../../../../hooks/rentReady/useIsKeyStorageFullTenantSetsFeature";
import { useKeyStorageCache } from "../../../../hooks/rentReady/useKeyStorageCache";
import { useSaveKeyStorageSubPage } from "../../../../hooks/rentReady/useSaveKeyStorageSubPage";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { AdditionalKeyStorageBox } from "./AdditionalKeyStorageBox/AdditionalKeyStorageBox";
import { FullTenantSetsBox } from "./FullTenantSetBox/FullTenantSetsBox";
import { LockboxCodeBox } from "./LockboxCodeBox/LockboxCodeBox";
import { LockboxLocationBox } from "./LockboxLocationBox/LockboxLocationBox";
import { useDiff } from "./useDiff";

function Content({ keyStorage }: { keyStorage: KeyStorage }) {
  const { setView } = useView();
  const [state, setState] = useState<KeyStorage>(keyStorage);
  const diff = useDiff(keyStorage, state);
  const success = useSuccess();
  const error = useError();
  const { mutate: updateKeyStorage, isLoading } = useSaveKeyStorageSubPage();
  const isEditable = useIsEditable();
  const isKeyStorageFullTenantSetsFeature = useIsKeyStorageFullTenantSetsFeature();
  const isKeyStorageAdditionalKeyStorageFeature = useIsKeyStorageAdditionalKeyStorageFeature();
  useEffect(() => {
    setState(keyStorage);
  }, [JSON.stringify(keyStorage)]);
  useDisplayInstruction(View.KeyStorageView);

  const { lockbox, lockboxLocation, additionalKeyStorage, fullTenants } = state;

  const onChange = (
    key: keyof KeyStorage,
    field:
      | keyof KeyStorage["additionalKeyStorage"]
      | keyof KeyStorage["lockbox"]
      | keyof KeyStorage["lockboxLocation"]
      | keyof KeyStorage["fullTenants"],
    value: any
  ) => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft[key][field] = value;
      })
    );
  };

  return (
    <FullScreen
      headerName="Key Storage"
      backView={View.AreaListView}
      backButton={
        <OutlineButton
          isFullWidth
          disabled={isLoading}
          onClick={() => {
            setView(View.AreaListView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isLoading || diff.length === 0}
            onClick={() => {
              updateKeyStorage(
                {
                  ...state,
                },
                {
                  onSuccess() {
                    success({ title: "Key storage updated!" });
                    setView(View.AreaListView);
                  },
                  onError() {
                    error({
                      title: "Error updating key storage",
                      description: "There was an error updating the key storage. Please try again.",
                    });
                  },
                }
              );
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <VStack w="100%" padding={4}>
        {isKeyStorageFullTenantSetsFeature && (
          <FullTenantSetsBox
            value={fullTenants}
            onChange={(field, value) => {
              onChange("fullTenants", field, value);
            }}
          />
        )}
        <LockboxCodeBox
          value={lockbox}
          onChange={(field, value) => {
            onChange("lockbox", field, value);
          }}
        />
        <LockboxLocationBox
          value={lockboxLocation}
          onChange={(field, value) => onChange("lockboxLocation", field, value)}
        />
        {isKeyStorageAdditionalKeyStorageFeature && (
          <AdditionalKeyStorageBox
            value={additionalKeyStorage}
            onChange={(field, value) => {
              onChange("additionalKeyStorage", field, value);
            }}
          />
        )}
      </VStack>
    </FullScreen>
  );
}

export function KeyStorageView() {
  const keyStorage = useKeyStorageCache();
  if (keyStorage === null) {
    return null;
  }
  return <Content keyStorage={keyStorage} />;
}
