import { Item } from "../../@types/RentReady/Item";
import { Photo } from "../../@types/RentReady/Photo";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { ItemNode } from "./useFetchInspectionReport/ItemNode";
import { useIsUploading } from "./useIsUploading";
import { useSyncSingleItemFieldToCacheAndServer } from "./useSyncSingleItemFieldToCacheAndServer";
import { ArgsItem } from "./useUpdatePartialItemToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

export function useSyncItemPhotosToCacheAndServer() {
  const upload = useUpload();
  const { setIsUploading } = useIsUploading();
  const syncSingleFieldToCacheAndServer = useSyncSingleItemFieldToCacheAndServer();

  return async (
    item: Item,
    cacheField: keyof ItemNode,
    serverField: keyof ArgsItem,
    prefix: Prefix,
    photos: Photo[]
  ) => {
    setIsUploading(true);
    const newPhotos = photos.map((photo) => ({ ...photo, prefix } as PhotoWithPrefix));
    await Promise.all(await upload(newPhotos));
    setIsUploading(false);

    const photosToPhotoUrlsJson = mapPhotosToPhotoUrlsJson(newPhotos);
    await syncSingleFieldToCacheAndServer(item, cacheField, serverField, photosToPhotoUrlsJson, photosToPhotoUrlsJson);
  };
}
