import { NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
  disabled?: boolean;
  value: number;
  onChange: (value: number) => void;
  isInvalid: boolean;
};

export function WholeDollarField({ disabled = false, value: initialValue, onChange, isInvalid }: Props) {
  const format = (val: string) => {
    const num = Number.parseInt(val, 10);
    if (Number.isNaN(num)) return `$0`;

    return `$` + num.toLocaleString();
  };
  const parse = (val: string) => val.replace(/^\$/, "");
  const [value, setValue] = useState<string>(initialValue.toString());

  useEffect(() => {
    onChange(Number.parseInt(value));
  }, [value]);

  return (
    <NumberInput
      precision={0}
      onChange={(valueString) => setValue(parse(valueString))}
      value={format(value)}
      keepWithinRange={false}
      isInvalid={isInvalid}
    >
      <NumberInputField disabled={disabled} />
      <NumberInputStepper />
    </NumberInput>
  );
}
