import { Item } from "../../@types/RentReady/Item";
import { useAppendPositionToHashTag } from "./useAppendPositionToHashTag";
import { useIsFullPaintAreaItem } from "./useIsFullPaintAreaItem";
import { useItemId } from "./useItemId";
import { useOpenItemWithPaintedAreaSelected } from "./useOpenItemWithPaintedAreaSelected";
import { View, useView } from "./useView";

export function useOnCompleteItemClick(item: Item) {
  const { setItemId } = useItemId();
  const { setView } = useView();
  const appendPositionToHashTag = useAppendPositionToHashTag("area_one");
  const isFullPaintAreaItem = useIsFullPaintAreaItem(item);
  const openItemWithPaintedAreaSelected = useOpenItemWithPaintedAreaSelected();

  return () => {
    if (isFullPaintAreaItem) {
      openItemWithPaintedAreaSelected();
    } else {
      appendPositionToHashTag();
      setItemId(item.id);
      setView(View.ItemEditView);
    }
  };
}
