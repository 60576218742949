import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { TextArea } from "@doorstead/components";
import { ReactNode } from "react";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

type Props = {
  label: string;
  control: ReactNode;
  note: string;
  onNoteChange: (note: string) => void;
};

export function ControlBox({ label, control, note, onNoteChange }: Props) {
  const readOnly = useIsReadOnly();
  return (
    <VStack w="100%">
      <Divider />
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Box color="gray.500">{label}</Box>
        <Box>{control}</Box>
      </Flex>
      <Box w="100%">
        <TextArea placeholder="Notes..." disabled={readOnly} value={note} onChange={onNoteChange} />
      </Box>
    </VStack>
  );
}
