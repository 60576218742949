import { EventProperties, Options } from "@segment/analytics-next";
import { useCallback, useMemo } from "react";
import { useAnalytics } from "src/contexts/analytics";
import { useFetchMe } from "./useFetchMe";

export interface GroupAnalytics {
  track: (eventName: string, properties?: any, options?: Options) => void;
}

export function useGroupAnalytics(): GroupAnalytics {
  const analytics = useAnalytics();
  const { data } = useFetchMe();

  const tenantGroupId = useMemo(() => {
    return data?.me?.tenant?.activeLease?.tenantGroup?.tenantGroupId;
  }, [data]);

  const track = useCallback(
    (eventName: string, properties?: EventProperties, options?: Options) => {
      analytics?.track(eventName, { ...properties, tenant_group_id: tenantGroupId }, options);
    },
    [tenantGroupId]
  );

  return {
    track,
  };
}
