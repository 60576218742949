import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Args = {
  itemDataIds: string[];
};

export function useRemoveOptions() {
  const { gqlClient } = useAuth();

  return ({ itemDataIds }: Args) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($itemDataIds: [UUID]!) {
          deleteInspectionReportItems(itemDataIds: $itemDataIds) {
            count
          }
        }
      `,
      variables: {
        itemDataIds,
      },
    });
  };
}
