import { Flex } from "@chakra-ui/react";
import { ExclamationFilledIcon } from "@doorstead/components/icons";

export function InCompleteIcon() {
  return (
    <Flex justifyContent="center" alignItems="center" bgColor="white" w="20px" h="20px" borderRadius="50%">
      <ExclamationFilledIcon bgColor="white" color="#ffaa41" />
    </Flex>
  );
}
