import { isCosmeticOptionsDiff } from "../../../../utils/rentReady/isCosmeticOptionsDiff";
import { isPhotosDiff } from "../../../../utils/rentReady/isPhotosDiff";
import { ItemEditState } from "./ItemEditView";

export function isItemEditStateDiff(state1: ItemEditState, state2: ItemEditState): boolean {
  if (isPhotosDiff(state1.photos, state2.photos)) {
    return true;
  }
  if (state1.note !== state2.note) {
    return true;
  }
  return isCosmeticOptionsDiff(state1.cosmeticOptions, state2.cosmeticOptions);
}
