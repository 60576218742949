import { MessageFilledIcon } from "@doorstead/components/icons/MessageFilledIcon";
import { MessageIcon } from "@doorstead/components/icons/MessageIcon";

type Props = {
  value: string;
};

export function RepairOwnerNoteIcon({ value }: Props) {
  if (value.trim() === "") {
    return <MessageIcon size={16} color="gray.700" />;
  } else {
    return <MessageFilledIcon size={16} color="purple.400" />;
  }
}
