import { Divider, Flex, FlexProps, VStack } from "@chakra-ui/react";
import { Fragment } from "react";
import { TitleRow } from "src/components/rent-ready/TitleRow";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { WalkthroughItemRow } from "./WalkthroughItemRow";

type Props = FlexProps & {
  section: string;
  items: FlexWalkthroughItemNode[];
};

export function FlexSection({ section, items }: Props) {
  const filteredItems = items.filter((item) => item.section === section);

  return (
    <>
      <TitleRow text={section} />
      <VStack w="100%" spacing={5}>
        <Flex flexDirection="column" w="100%">
          <VStack spacing={1}>
            {filteredItems
              .sort((a, b) => a.order - b.order)
              .map((item) => {
                return (
                  <Fragment key={item.itemDataId}>
                    <WalkthroughItemRow item={item} />
                    <Divider />
                  </Fragment>
                );
              })}
          </VStack>
        </Flex>
      </VStack>
    </>
  );
}
