import { apiServerUrl } from "../../../config";

const query = `
  query ($areaId: UUID!) {
    inspectionReportItemOptionsByAreaId(areaId: $areaId) {
      id
      name
      canHaveMultiple
    }
  }
`;

export type Result = {
  inspectionReportItemOptionsByAreaId: {
    id: string;
    name: string;
    canHaveMultiple: boolean | null;
  }[];
};

export function fetchInspectionReportItemOptionsByAreaId(areaId: string): Promise<Result> {
  return fetch(`${apiServerUrl()}/gql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { areaId },
    }),
  })
    .then((e) => e.json())
    .then((e) => e.data);
}
