import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { DoorsteadLogo } from "@doorstead/components";
import { InspectionReportType } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useInspectionReport } from "../../../../hooks/rentReady/useInspectionReport";
import { InspectionReportPropertyBox } from "../../InspectionReportPropertyBox";

export function ExpiredView() {
  const { propertyAddress, inspectionType } = useInspectionReport();

  return (
    <Flex flexDirection="column" w="100%" pos="relative">
      <VStack flex={1} w="100%" p={5}>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <DoorsteadLogo width={200} href="" />
        </Flex>
        <VStack pt={10}>
          <Box fontWeight="bold" fontSize="xl">
            {inspectionType == InspectionReportType.MOVE_IN_INSPECTION
              ? "Move In Evaluation"
              : "Property Evaluation & Estimates Tool"}
          </Box>
          <Box>for</Box>
          <Box fontWeight="semibold">{propertyAddress}</Box>
          <Box>
            <Divider />
          </Box>
          <InspectionReportPropertyBox />
        </VStack>
      </VStack>
      <VStack as="footer" pos="sticky" bottom={0} spacing={0}>
        <Box color="red.400" fontWeight="bold" p={5}>
          This report has expired.
        </Box>
      </VStack>
    </Flex>
  );
}
