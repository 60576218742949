import { Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useContext } from "react";
import { Guideline } from "../../components/rr-project/Guideline";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function InstructionGuidelinesProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);
  const isMobile = useIsMobile();

  return (
    <Modal size={isMobile ? "full" : "4xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Guideline
          submit={
            <PrimaryButton size="lg" onClick={onClose}>
              Proceed with list
            </PrimaryButton>
          }
        />
      </ModalContent>
    </Modal>
  );
}

export function useInstructionGuidelinesModal() {
  return useContext(context);
}
