import { VStack } from "@chakra-ui/react";
import React from "react";
import { StepCard } from "../../StepCard";

type Props = {
  title?: string;
  children: React.ReactNode;
};

export function BaseCard({ title = "", children }: Props) {
  return (
    <StepCard title={title}>
      <VStack spacing={3} w="100%" paddingTop={10} alignItems="flex-start">
        {children}
      </VStack>
    </StepCard>
  );
}
