import produce from "immer";
import { useMutation } from "react-query";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { useInspectionReportId } from "./useInspectionReportId";
import { ItemState, useUpdateItemCache } from "./useUpdateItemCache";
import { mapItemToArgs, useUpdateItemToServer } from "./useUpdateItemToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(itemState: ItemState): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  itemState.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.Items;
    photos.push(photo as PhotoWithPrefix);
  });
  return photos;
}

function useGetUploadedItem() {
  const upload = useUpload();

  return async (itemState: ItemState): Promise<ItemState> => {
    return produce(itemState, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveItemSubPage() {
  const inspectionReportId = useInspectionReportId();
  const updateItemCache = useUpdateItemCache();
  const updateItemToServer = useUpdateItemToServer();
  const getUploadedItem = useGetUploadedItem();

  return useMutation(async (itemState: ItemState) => {
    await updateItemCache(itemState);
    if (isOnline()) {
      const newItemState = await getUploadedItem(itemState);
      const {
        data: {
          updateInspectionReportItem: { success, merged, itemData: returnedData },
        },
      } = await updateItemToServer(mapItemToArgs(newItemState));
      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          //save back to cache
          const newCache = produce(cache, (draft) => {
            draft.inspectionReport.data?.forEach((areaCache) => {
              areaCache?.itemsData?.forEach((itemCache) => {
                if (itemCache && itemCache.itemDataId === itemState.id) {
                  itemCache.itemNotes = returnedData.notes;
                  itemCache.itemDataStatus = returnedData.status;
                  itemCache.itemSelectedCosmeticOptions = returnedData.selectedCosmeticOptions;
                  itemCache.itemPhotoUrlsJson = returnedData.photoUrlsJson;
                  itemCache.itemDataUpdatedAt = returnedData.itemDataUpdatedAt;
                }
              });
            });
          });

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, newCache);
        }
      }
    }
  });
}
