import { gql } from "@apollo/client";
import { Box, Center, VStack } from "@chakra-ui/react";
import { Spinner } from "@doorstead/components/Spinner";
import { useEffect, useState } from "react";
import { getDoorsteadEnv } from "src/config";
import { useAuth } from "src/contexts/auth";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useTrackImpressionEvent } from "src/hooks/useTrackImpressionEvent";
import { useApplicantPageView } from "../../../../hooks/applicants/useApplicantPageView";
import { LeaseSignedCard } from "../LeaseSignedCard";
import { HelloSignBox } from "./HelloSignBox";

interface EmbeddedLeaseSignLinkByEmailResult {
  embeddedLeaseSignLinkByEmail: string | null;
}

export function EmbeddedLeaseSignCard() {
  const { gqlClient } = useAuth();
  const applicant = useFetchedApplicant();
  const applicantEmail = applicant.doorsteadUser.email || "";
  const signatureRequest = applicant.applicationRequest.currentSignatureRequest;
  const [isLoading, setIsLoading] = useState(true);
  const [isSigned, setIsSigned] = useState(false);
  const [embeddedLink, setEmbeddedLink] = useState<string | null>(null);

  useApplicantPageView("TP_EMBEDDED_LEASE_SIGNING_VIEWED");
  useTrackImpressionEvent({
    data: {
      applicantId: applicant.applicantId,
      applicationRequestId: applicant.applicationRequest.applicationRequestId,
      applicationRequest: applicant.applicationRequest,
    },
    propertyId: applicant.applicationRequest.property.propertyId,
    email: applicant.doorsteadUser.email,
    action: "TP_EMBEDDED_LEASE_SIGNING_PAGE_VIEWED_RDS",
    surface: "APPLICATION_PORTAL",
  });
  const fetchEmbeddedLeaseSignLinkByEmail = async (signatureRequestId: string, signerEmail: string) => {
    const { data, errors } = await gqlClient.query<EmbeddedLeaseSignLinkByEmailResult | null>({
      query: gql`
        query ($signatureRequestId: UUID!, $signerEmail: String!) {
          embeddedLeaseSignLinkByEmail(signatureRequestId: $signatureRequestId, signerEmail: $signerEmail)
        }
      `,
      variables: { signatureRequestId, signerEmail },
    });
    if (data?.embeddedLeaseSignLinkByEmail) {
      setEmbeddedLink(data.embeddedLeaseSignLinkByEmail);
    } else if (errors && errors[0].message?.includes("This request has already been signed")) {
      // Signature request was signed but applicant status hasn't been updated yet
      setIsSigned(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (signatureRequest?.signatureRequestId) {
      fetchEmbeddedLeaseSignLinkByEmail(signatureRequest.signatureRequestId, applicantEmail);
    } else {
      throw new Error(`No signature request found for applicant ${applicant.applicationRequest.applicationRequestId}`);
    }
  }, [signatureRequest, applicantEmail]);

  if (isLoading) {
    return (
      <VStack w="100%" justifyContent="center" alignItems="center">
        <Box fontSize="2xl" fontWeight="bold" fontFamily="Inter">
          Processing Lease...
        </Box>
        <Center>
          <Spinner />
        </Center>
      </VStack>
    );
  }

  if (isSigned) {
    return <LeaseSignedCard />;
  }

  return (
    <VStack w="100%" justifyContent="center" alignItems="center">
      <Box fontSize="34px" fontWeight="600" fontFamily="Inter">
        Your new home awaits — sign your lease today
      </Box>
      <Center fontSize="16px" fontWeight="400" fontFamily="Inter" textAlign="center" color="#718096">
        Please promptly sign your lease. Make sure to have all applicants sign the lease and to submit your security
        deposit to finish securing your new home.
      </Center>
      {embeddedLink && (
        <HelloSignBox
          embeddedUrl={embeddedLink}
          testMode={getDoorsteadEnv() !== "prod"}
          onSigned={() => {
            window.location.reload();
          }}
        />
      )}
    </VStack>
  );
}
