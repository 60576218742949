import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";

type Args = {
  isCore: Item["isCore"];
  isMissingNeedsEstimate: Item["isMissingNeedsEstimate"];
  status: Item["status"];
  shouldUseSimplifiedTemplate: boolean;
};

export function isShowItemRequiredPhotosAndOptionalNotesAndHideComponents({
  isCore,
  isMissingNeedsEstimate,
  status,
  shouldUseSimplifiedTemplate,
}: Args): boolean {
  if (shouldUseSimplifiedTemplate) {
    return status === GoodMissingFixStatus.Fix && isMissingNeedsEstimate;
  }

  return status === GoodMissingFixStatus.Missing && isCore && isMissingNeedsEstimate;
}
