import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useRef } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function DeletePhotoDialog({ isOpen, onClose, onConfirm }: Props) {
  const cancelRef = useRef<any>();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete photos
          </AlertDialogHeader>
          <AlertDialogBody>Please confirm that you would like to delete the selected photos</AlertDialogBody>
          <AlertDialogFooter>
            <PrimaryButton
              isFullWidth
              onClick={(e) => {
                e.preventDefault();
                onConfirm();
              }}
            >
              Confirm
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
