import { Divider, VStack } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { usePageView } from "src/hooks/usePageView";
import { useNextStep } from "../../../../contexts/StepContext/useNextStep";
import { useAddress } from "../../../../hooks/useAddress";
import { useEndDate } from "../../../../hooks/useEndDate";
import { useSecurityAmountDue } from "../../../../hooks/useSecurityAmountDue";
import { useSecurityDepositDueDate } from "../../../../hooks/useSecurityDepositDueDate";
import { useStartDate } from "../../../../hooks/useStartDate";
import { useTenants } from "../../../../hooks/useTenants";
import { AddressAndTenantsBox } from "../WelcomeStep/AddressAndTenantsBox";
import { MoneyBox } from "../WelcomeStep/MoneyBox";
import { NoticeBox } from "../WelcomeStep/NoticeBox";
import { useIsShowSecurityDeposit } from "../WelcomeStep/useIsShowSecurityDeposit";

export function TPOWelcomeStep() {
  const address = useAddress();
  const startDate = useStartDate();
  const endDate = useEndDate();
  const tenants = useTenants();
  const { nextStep, isLoading } = useNextStep();
  const isMobile = useIsMobile();
  const securityAmountDue = useSecurityAmountDue();
  const securityDepositDueDate = useSecurityDepositDueDate();
  const analytics = usePageView("TP_TPO_WELCOME_VIEWED");
  const isShowSecurityDeposit = useIsShowSecurityDeposit();

  return (
    <VStack
      maxWidth="796px"
      spacing={5}
      bgColor="white"
      borderRadius={20}
      marginX="10px"
      marginY="30px"
      padding={isMobile ? "20px" : "40px"}
      display="flex"
      alignItems="start"
    >
      <NoticeBox
        title="Set up and secure your rental!"
        subTitle="In just a few steps, we’ll help you securely add a payment method and make your first payment."
      />
      <AddressAndTenantsBox address={address} fromDate={startDate} toDate={endDate} tenants={tenants} />
      <VStack w="100%" spacing={5} display="flex" alignItems="flex-start">
        {isShowSecurityDeposit && (
          <>
            <Divider />
            <MoneyBox title="Security deposit" money={securityAmountDue} dueDate={securityDepositDueDate} />
          </>
        )}
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading}
          isFullWidth={isMobile}
          onClick={async () => {
            analytics?.track("TP_TPO_WELCOME_CONTINUED");
            await nextStep();
          }}
        >
          Get Started
        </PrimaryButton>
      </VStack>
    </VStack>
  );
}
