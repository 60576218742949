import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useAddEmployment() {
  const applicantId = useApplicantId();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async () => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantEmployment: ApplicantEmploymentCreateData) {
            createApplicantEmployment(applicantEmployment: $applicantEmployment) {
              applicantEmploymentId
            }
          }
        `,
        variables: {
          applicantEmployment: {
            isCurrent: true,
            employer: "",
            jobTitle: "",
            streetAddress: "",
            unitNumber: "",
            state: "",
            country: "US",
            zipcode: "",
            annualIncomeInCents: 0,
            applicantId,
          },
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
