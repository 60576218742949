import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useAreaId } from "./useAreaId";

export type ItemOption = {
  optionId: string;
  isPresent: boolean | null;
};

type Args = {
  itemOptions: ItemOption[];
};

export function useAddOptions() {
  const { areaId: areaDataId } = useAreaId();
  const { gqlClient } = useAuth();

  return ({ itemOptions }: Args) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($areaDataId: UUID!, $itemOptions: [AddItemOption]) {
          addInspectionReportItems(areaDataId: $areaDataId, itemOptions: $itemOptions) {
            count
          }
        }
      `,
      variables: {
        areaDataId,
        itemOptions,
      },
    });
  };
}
