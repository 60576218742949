import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth";

const withAuth = (WrappedComponent: React.ComponentType) => {
  const ComponentWithAuth = (props: any) => {
    const { loading, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!loading && !user) {
        navigate("/login");
      }
    }, [loading, user]);

    if (loading || !user) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
