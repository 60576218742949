import { Box, BoxProps } from "@chakra-ui/react";
import { TickIcon as Icon } from "@doorstead/components/icons/TickIcon";

export function TickBox(boxProps: BoxProps) {
  return (
    <Box
      {...boxProps}
      w={25}
      h={25}
      borderRadius="full"
      bg="purple.300"
      border="1px solid white"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize={12}
      fontWeight="bold"
    >
      <Icon color="white" />
    </Box>
  );
}
