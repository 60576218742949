import { Box, Flex, VStack } from "@chakra-ui/react";
import { maxWidth, width } from "../config";

export function CongratsCard() {
  return (
    <Flex
      w={width}
      maxWidth={maxWidth}
      bgColor="#E2E3F8"
      gap={4}
      overflow="hidden"
      borderRadius={20}
      padding={4}
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        bottom: "-30px",
        left: "15px",
        width: 130,
        height: 63,
        bgColor: "rgb(171, 173, 215)",
        zIndex: 1,
        transform: "rotate(-45deg)",
        borderRadius: 10,
      }}
    >
      <Flex w={150} justifyContent="center" alignItems="center">
        <img
          style={{ zIndex: 2 }}
          alt="document"
          src="https://doorstead-components.s3.us-west-2.amazonaws.com/document.png"
        />
      </Flex>
      <VStack flex={1} alignItems="flex-start">
        <Box color="gray.700" fontSize="lg" fontWeight="bold">
          Congrats on signing your lease!
        </Box>
        <Box color="gray.500" fontWeight="normal" fontSize={15}>
          Your lease has been signed and next steps will be emailed to you.
        </Box>
      </VStack>
    </Flex>
  );
}
