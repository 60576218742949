import { Box } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";

type Props = {
  isSelecting: boolean;
  label: string;
  onSelect: (isSelecting: boolean) => void;
  variant: Variant;
  isReadOnly: boolean;
};

export function StatusButton({ isSelecting, label, onSelect, variant, isReadOnly }: Props) {
  if (isSelecting) {
    return (
      <Box
        onClick={() => {
          if (!isReadOnly) {
            onSelect(false);
          }
        }}
      >
        <Chip label={label} variant={variant} />
      </Box>
    );
  } else {
    return (
      <Box
        onClick={() => {
          if (!isReadOnly) {
            onSelect(true);
          }
        }}
      >
        <Chip label={label} variant={Variant.Gray} />
      </Box>
    );
  }
}
