import { Box, VStack, useMediaQuery } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Scale } from "../../../../@types/RentReady/Scale";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { RequiredLabel } from "../../RequiredLabel";
import { ScaleField } from "../../ScaleField";
import { PhotosField } from "../../fields/PhotosField";
import { TextAreaField } from "../../fields/TextAreaField";

type Props = {
  label: string;
  photos: Photo[];
  isWorking: boolean;
  number: Scale;
  note: string;
  photoPrefix: Prefix;
  onPhotosChange: (photos: Photo[]) => void;
  onIsWorking: (isWorking: boolean) => void;
  onNumberChange: (number: Scale) => void;
  onNoteChange: (note: string) => void;
};

function useIsSmall(): boolean {
  return useMediaQuery("(max-width: 450px)")[0];
}

function useGridTemplateAreas(): string {
  const isSmaller = useIsSmall();

  if (isSmaller) {
    return `
    "label        photosLabel"
    "labelField   photosField"
    "workingLabel photosField"
    "workingField photosField"
  `;
  }

  return `
    "label      workingLabel photosLabel"
    "labelField workingField photosField"
  `;
}

function useGridTemplateColumns(): string {
  const isSmaller = useIsSmall();
  return isSmaller ? "1fr auto" : "1fr 1fr auto";
}

function useGridTemplateRows(): string {
  const isSmaller = useIsSmall();
  return isSmaller ? "repeat(4, auto)" : "repeat(2, auto)";
}

export function Detail({
  label,
  photos,
  note,
  isWorking,
  photoPrefix,
  number,
  onIsWorking,
  onNoteChange,
  onNumberChange,
  onPhotosChange,
}: Props) {
  const isReadOnly = useIsReadOnly();
  const gridTemplateAreas = useGridTemplateAreas();
  const gridTemplateColumns = useGridTemplateColumns();
  const gridTemplateRows = useGridTemplateRows();

  return (
    <VStack alignItems="flex-start" w="100%">
      <Box
        w="100%"
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateAreas={gridTemplateAreas}
        gridTemplateRows={gridTemplateRows}
      >
        <RequiredLabel hasValue={number !== Scale.NotProvided} gridArea="label" w="100%" flexWrap="wrap">
          {label}
        </RequiredLabel>
        <Box gridArea="labelField" w="fit-content">
          <ScaleField value={number} onChange={onNumberChange} />
        </Box>
        {number !== Scale.NA && number !== Scale.NotProvided && (
          <>
            <Box gridArea="workingLabel" w="100%">
              Working?
            </Box>
            <RequiredLabel hasValue={photos.length > 0} gridArea="photosLabel" w="100%">
              Photos
            </RequiredLabel>
            <Box gridArea="workingField" w="100%">
              <Checkbox id="working" disabled={isReadOnly} value={isWorking} onChange={onIsWorking} />
            </Box>
            <Box gridArea="photosField" w="100%">
              <PhotosField label="" prefix={photoPrefix} values={photos} onChange={onPhotosChange} />
            </Box>
          </>
        )}
      </Box>
      <TextAreaField
        label=""
        placeholder="Notes on keys and access locations"
        value={note}
        onChange={onNoteChange}
        disabled={useIsReadOnly()}
      />
    </VStack>
  );
}
