import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { ActionNeededChip } from "./ActionNeededChip";

type Props = {
  title: string;
  children: ReactNode;
  isActionNeeded?: boolean;
  topRight?: ReactNode;
};

export function WhiteBlock({ title, isActionNeeded = false, topRight = null, children }: Props) {
  return (
    <VStack bgColor="white" alignItems="flex-start" borderRadius={20} p={5} w="100%">
      <Flex w="100%" justifyContent="space-between">
        <HStack>
          <Box fontWeight="bold" fontSize="xl">
            {title}
          </Box>
          {isActionNeeded && <ActionNeededChip />}
        </HStack>
        {topRight && <Box>{topRight}</Box>}
      </Flex>

      {children}
    </VStack>
  );
}
