import { Box, Flex, HStack, Link, VStack } from "@chakra-ui/react";
import { DoorsteadLogoWithoutText } from "@doorstead/components/DoorsteadLogoWithoutText";
import { DebugIcon } from "@doorstead/components/icons/DebugIcon";
import { OfflineIcon } from "@doorstead/components/icons/OfflineIcon";
import { RefreshIcon } from "@doorstead/components/icons/RefreshIcon";
import { ReactNode } from "react";
import { FlexWalkthroughView } from "src/components/flex-walkthrough/views/FlexWalkthroughView";
import { ConfirmSubmittedModalProvider } from "src/hooks/flexWalkthrough/useConfirmSubmittedModal";
import { useFetchFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport";
import { Error } from "../../components/Error";
import { FlexWalkthroughStartView } from "../../components/flex-walkthrough/views/FlexWalkthroughStartView";
import { bugReportLink } from "../../config";
import { Provider as DrawerProvider } from "../../hooks/flexWalkthrough/useDrawer";
import { useInitialView } from "../../hooks/flexWalkthrough/useInitialView";
import { View, Provider as ViewProvider, useView } from "../../hooks/flexWalkthrough/useView";
import { useIsDebugMode } from "../../hooks/rentReady/useIsDebugMode";
import { Provider as IsEditableProvider } from "../../hooks/rentReady/useIsEditable";
import { Provider as IsUploadingProvider } from "../../hooks/rentReady/useIsUploading";
import { Provider as UploadProgressProvider } from "../../hooks/rentReady/useUploadProgress";
import { useIsOffline } from "../../hooks/useIsOffline";
import { clearCache } from "./FlexWalkthroughDebugPage";

function FetchFlexWalkthroughReport({ children }: { children: ReactNode }) {
  const { isFetching, isFetched, data } = useFetchFlexWalkthroughReport();

  if (isFetching && !isFetched) {
    return null;
  } else if (!data || data.flexWalkthroughReport === null) {
    return <Error title="Can not find this property" />;
  }

  return <IsEditableProvider value={data.flexWalkthroughReport.submittedTime === null}>{children}</IsEditableProvider>;
}

function FurnishedTypeBox() {
  const { isLoading, data } = useFetchFlexWalkthroughReport();
  if (isLoading) return null;

  const propertyFurnishedType = data?.flexWalkthroughReport.propertyFurnishedType || null;
  switch (propertyFurnishedType) {
    case "Full": {
      return <Box fontSize="xl">Property fully furnished</Box>;
    }
    case "Partial": {
      return <Box fontSize="xl">Property partially furnished</Box>;
    }
    default:
      return null;
  }
}

function Main() {
  const { view } = useView();

  switch (view) {
    case View.StartView:
      return <FlexWalkthroughStartView />;
    case View.WalkthroughView:
      return <FlexWalkthroughView />;
    default:
      return null;
  }
}

function InitialView({ children }: { children: ReactNode }) {
  const view = useInitialView();
  return <ViewProvider view={view}>{children}</ViewProvider>;
}

function LoadingLayout({ children }: { children: ReactNode }) {
  const { data, isLoading } = useFetchFlexWalkthroughReport();
  const isOffline = useIsOffline();
  const isDebugMode = useIsDebugMode();
  const { view } = useView();

  return (
    <VStack minH="100vh" spacing={0} padding={0} alignItems="flex-start">
      <VStack as="header" w="100%" px={4} pt={4} alignItems="flex-start">
        {view === View.WalkthroughView && (
          <>
            <Flex alignItems="center" justifyContent="space-between" minH="24px" w="100%">
              <DoorsteadLogoWithoutText size={24} />
              <Flex
                h="24px"
                flex={1}
                fontSize="12px"
                color="gray.500"
                fontWeight="bold"
                px={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                {isLoading ? "" : data?.flexWalkthroughReport.propertyAddress || ""}
              </Flex>
              <Box onClick={clearCache}>
                <RefreshIcon size={24} color="black" />
              </Box>
            </Flex>
            <HStack fontSize="2xl" fontWeight="bold" spacing={4}>
              <Box>Property Walkthrough</Box>
              {isOffline && <OfflineIcon />}
              {isDebugMode && (
                <>
                  <Link target="_blank" href={bugReportLink}>
                    <DebugIcon size={32} color="black" />
                  </Link>
                </>
              )}
            </HStack>
            <FurnishedTypeBox />
          </>
        )}
      </VStack>
      <Flex as="main" flex={1} alignItems="stretch" w="100%" h="100%">
        {children}
      </Flex>
    </VStack>
  );
}

export function FlexWalkthroughLayout() {
  return (
    <UploadProgressProvider>
      <IsUploadingProvider>
        <DrawerProvider>
          <FetchFlexWalkthroughReport>
            <ConfirmSubmittedModalProvider>
              <InitialView>
                <LoadingLayout>
                  <Main />
                </LoadingLayout>
              </InitialView>
            </ConfirmSubmittedModalProvider>
          </FetchFlexWalkthroughReport>
        </DrawerProvider>
      </IsUploadingProvider>
    </UploadProgressProvider>
  );
}
