import produce from "immer";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { useAreaId } from "../../hooks/rentReady/useAreaId";
import { ItemState } from "../../hooks/rentReady/useUpdateItemCache";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";

export function useMapItemStateFromRRGraphQLResultCache() {
  const { areaId } = useAreaId();

  return (result: RRGraphQLResultCache, state: ItemState): RRGraphQLResultCache => {
    return produce(result, (draft) => {
      const areaCache = draft.inspectionReport.data?.find((e) => e.areaDataId === areaId);
      if (areaCache) {
        const item = areaCache.itemsData?.find((e) => e.itemDataId === state.id);
        if (item) {
          const selectedCosmeticOptions: string[] = state.cosmeticOptions.filter((e) => e.selected).map((e) => e.id);
          item.itemNotes = state.note;
          item.itemDataStatus = state.status;
          item.itemPhotoUrlsJson = mapPhotosToPhotoUrlsJson(state.photos);
          item.itemSelectedCosmeticOptions = selectedCosmeticOptions;
        }
      }
    });
  };
}
