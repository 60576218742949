import { Header } from "@doorstead/components/containers/Table";

export const headers: Header[] = [
  {
    label: "Transaction date",
    isNumeric: false,
  },
  {
    label: "Details",
    isNumeric: false,
  },
  {
    label: "Status",
    isNumeric: false,
  },
  {
    label: "Charge",
    isNumeric: true,
  },
  {
    label: "Payment",
    isNumeric: true,
  },
  {
    label: "Balance",
    isNumeric: true,
  },
];
