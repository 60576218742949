import { Photo } from "../../@types/RentReady/Photo";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { AreaNode } from "./useFetchInspectionReport/AreaNode";
import { useIsUploading } from "./useIsUploading";
import { useSyncSingleAreaFieldToCacheAndServer } from "./useSyncSingleAreaFieldToCacheAndServer";
import { ArgsArea } from "./useUpdatePartialAreaToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

export function useSyncAreaPhotosToCacheAndServer() {
  const upload = useUpload();
  const syncSingleFieldToCacheAndServer = useSyncSingleAreaFieldToCacheAndServer();
  const { setIsUploading } = useIsUploading();

  return async (cacheField: keyof AreaNode, serverField: keyof ArgsArea, prefix: Prefix, photos: Photo[]) => {
    setIsUploading(true);
    const newPhotos = photos.map((photo) => ({ ...photo, prefix } as PhotoWithPrefix));
    await Promise.all(await upload(newPhotos));
    setIsUploading(false);

    const photosToPhotoUrlsJson = mapPhotosToPhotoUrlsJson(newPhotos);
    await syncSingleFieldToCacheAndServer(cacheField, serverField, photosToPhotoUrlsJson, photosToPhotoUrlsJson);
  };
}
