import { DoorsteadProvider } from "@doorstead/components";
import { ScrollRestoration } from "react-router-dom";
import { MainLayout } from "src/components/MainLayout";
import { OneTimePayment } from "src/components/OneTimePayment";
import { useFooter } from "src/hooks/useFooter";
import { Provider as PaymentFailureModalProvider } from "src/hooks/useOpenPaymentFailureModal";
import { Provider as PaymentSuccessModalProvider } from "src/hooks/useOpenPaymentSuccessModal";

function OneTimePayPage() {
  const footer = useFooter();

  return (
    <DoorsteadProvider>
      <PaymentFailureModalProvider>
        <PaymentSuccessModalProvider>
          <MainLayout
            header={{
              logoLink: "",
              avatarName: "",
              links: [],
            }}
            footer={footer}
          >
            <OneTimePayment />
          </MainLayout>
          <ScrollRestoration />
        </PaymentSuccessModalProvider>
      </PaymentFailureModalProvider>
    </DoorsteadProvider>
  );
}

export default OneTimePayPage;
