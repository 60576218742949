import produce from "immer";
import { useQuery, useQueryClient } from "react-query";
import { db, readRRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { isOffline } from "../../../utils/isOffline";
import { useInspectionReportId } from "../useInspectionReportId";
import { InspectionReportNode } from "./InspectionReportNode";
import { fetchRRGraphQLResultServer } from "./fetchRRGraphQLResultServer";
import { useMergeCacheWithServer } from "./useMergeCacheWithServer";

export const queryKey = "useFetchInspectionReport";

export type RRGraphQLResult = {
  inspectionReport: InspectionReportNode;
  inspectionReportAreaOptions: {
    areaOptionId: string;
    areaOptionName: string;
  }[];
};

export function useFetchInspectionReport() {
  const inspectionReportId = useInspectionReportId();
  const mergeCacheWithServer = useMergeCacheWithServer();

  return useQuery<unknown, unknown, RRGraphQLResult>(queryKey, async () => {
    if (isOffline()) {
      return readRRGraphQLResultCache(inspectionReportId);
    } else {
      const rrGraphQLResultCache = await readRRGraphQLResultCache(inspectionReportId);
      const rrGraphQLResultServer = await fetchRRGraphQLResultServer(inspectionReportId);

      if (rrGraphQLResultCache === null) {
        //if the local db is not inserted, just insert the server data
        await db.rr_graphql_result.put({
          ...rrGraphQLResultServer,
          inspection_report_id: inspectionReportId,
        });
      } else {
        await mergeCacheWithServer(rrGraphQLResultCache, rrGraphQLResultServer);
      }
      return rrGraphQLResultServer;
    }
  });
}

export function useUpdateInspectionReportData() {
  const queryClient = useQueryClient();

  return (partialInspectionReport: Partial<InspectionReportNode>): RRGraphQLResult => {
    const newData = produce(queryClient.getQueryData(queryKey), (draft: RRGraphQLResult) => {
      draft.inspectionReport = Object.assign(draft.inspectionReport, partialInspectionReport);
    });
    queryClient.setQueryData(queryKey, newData);

    return newData as RRGraphQLResult;
  };
}
