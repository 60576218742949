import { ApplicantDoorsteadUser } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { getBirthdayToYear } from "./getBirthdayToYear";

export function isBirthdayValid(birthday: ApplicantDoorsteadUser["dayOfBirthTemp"]): boolean {
  if (birthday === null) return false;

  const birthdayArray = birthday.split("-");
  if (birthdayArray.length !== 3) return false;

  const year = parseInt(birthdayArray[0]);
  return year <= getBirthdayToYear();
}
