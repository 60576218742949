import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { LinkProvider } from "@doorstead/components/hooks/useReactRouterLink";
import { Link, LinkProps } from "react-router-dom";
import { ProjectLayout } from "../../components/rr-project/ProjectLayout";

export default function ProjectRoute() {
  return (
    <LinkProvider
      value={{
        Link(linkProps: any) {
          return <Link {...(linkProps as LinkProps)} />;
        },
      }}
    >
      <DoorsteadProvider>
        <ProjectLayout />
      </DoorsteadProvider>
    </LinkProvider>
  );
}
