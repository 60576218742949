import { RepairOrReplaceType } from "../../@types/RentReady/Komponent";
import { isPhotosDiff } from "./isPhotosDiff";
import { isTypesDiff, sortTypesById } from "./isTypesDiff";

export function isRepairOrReplaceTypeDiff(type1: RepairOrReplaceType, type2: RepairOrReplaceType): boolean {
  // Compare the photos in the repair or replace types
  if (isPhotosDiff(type1.photos, type2.photos)) {
    return true;
  }

  // Compare the selected property of the repair or replace types
  if (type1.selected !== type2.selected) {
    return true;
  }

  // Compare the note property of the repair or replace types
  if (type1.note !== type2.note) {
    return true;
  }

  // Compare the sorted types in the repair or replace types
  return isTypesDiff(sortTypesById(type1.types), sortTypesById(type2.types));
}
