import { Box } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import _ from "lodash";
import { useState } from "react";
import { useCreateStripeSetupIntent } from "src/hooks/useCreateStripeSetupIntent";
import { useFetchedMe } from "src/hooks/useFetchedMe";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";

type Props = {
  onDone: (setupIntentId: string) => Promise<void>;
};

export function SetupForm({ onDone }: Props) {
  const analytics = useGroupAnalytics();
  const isMobile = useIsMobile();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [result, setResult] = useState<any | null>(null);
  const me = useFetchedMe();
  const { createStripeSetupIntent } = useCreateStripeSetupIntent();

  const confirmSetup = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      // We defer getting the setup intent until the user clicks the button
      // so that every time the user clicks the button, we get a new setup intent.
      // We always want to get a new setup intent since in order to block non-US cards
      // from being added and we have to throw error even if the underlying
      // stripe.confirmSetup succeeds and succeeded setup intents can't be reused.
      // https://stripe.com/docs/payments/accept-a-payment-deferred?type=setup&client=react
      const { clientSecret } = await createStripeSetupIntent(me.doorsteadUserId);

      const { setupIntent, error } = await stripe.confirmSetup({
        clientSecret,
        elements,
        redirect: "if_required",
        confirmParams: {
          expand: ["payment_method"],
        },
      });
      console.log("confirmPayment", { setupIntent, error });
      if (error) {
        throw error;
      }
      setResult(setupIntent);
      analytics?.track("TP_ADD_PAYMENT_METHOD_STARTED");

      if (typeof setupIntent.payment_method !== "string" && setupIntent.payment_method?.card?.country !== "US") {
        throw { type: "invalid_request_error", message: "We only support US cards at this time." };
      }

      await onDone(setupIntent.id);
    } catch (error) {
      const stripeError = error as unknown as { type: string; message: string };
      setResult(stripeError);
      if (
        stripeError.type === "card_error" ||
        stripeError.type === "validation_error" ||
        stripeError.type === "invalid_request_error"
      ) {
        setErrorMessage(stripeError.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
      analytics?.track("TP_ADD_PAYMENT_METHOD_FAILED");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box width={"100%"}>
      <PaymentElement />
      <Box my={3}>
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading || !stripe || !elements}
          onClick={confirmSetup}
          isFullWidth={isMobile}
        >
          Continue
        </PrimaryButton>
      </Box>
      {!isLoading && !_.isEmpty(result) && !_.isEmpty(errorMessage) && (
        <div style={{ color: "red" }}>{errorMessage}</div>
      )}
    </Box>
  );
}
