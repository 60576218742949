import { moneyString } from "@doorstead/components";
import { Variant } from "@doorstead/components/Chip";
import { getNegotiatedDateFormattedDateString } from "src/utils/getNegotiatedDateFormattedDateString";
import { OptionType } from "./typings";

export function getOptionType(price: number, listingPrice: number) {
  if (price === listingPrice) {
    return OptionType.NORMAL;
  }
  return price < listingPrice ? OptionType.EARLY : OptionType.LATER;
}

export function getDatesBetween(startDate: string, endDate: string) {
  const dates = [];
  const currentDate = new Date(startDate);
  const currentEndDate = new Date(endDate);

  while (currentDate <= currentEndDate) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export function getOptionDateString(date: string) {
  // return convertDateBetweenTimeZones(date);
  return getNegotiatedDateFormattedDateString(date);
}

export function getOptionDescriptionString(
  price: number,
  listingPrice: number
): { highlightedText: string; description: string } {
  if (price === listingPrice) {
    return {
      highlightedText: "Listing price",
      description: "",
    };
  }

  const priceDiff = Math.abs(price - listingPrice);
  const monthlyPriceDiff = moneyString(priceDiff, false);
  const annualPriceDiff = moneyString(priceDiff * 12, false);
  if (price < listingPrice) {
    return {
      highlightedText: `${annualPriceDiff} discount`,
      description: `on your lease (${monthlyPriceDiff}/month)`,
    };
  } else {
    return {
      highlightedText: `${annualPriceDiff} more`,
      description: `for your lease (${monthlyPriceDiff}/month)`,
    };
  }
}

export function getOptionTagVariant(type: OptionType): Variant {
  switch (type) {
    case OptionType.EARLY:
      return Variant.Green;
    case OptionType.NORMAL:
      return Variant.Gray;
    case OptionType.LATER:
      return Variant.Red;
  }
}
