import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { RepairNode } from "./useFetchRRProject";

export type Repair = {
  repairId: string;
  ownerDecision: RepairNode["ownerDecision"];
  stage: RepairNode["stage"];
};

export function useUpdateRepairs() {
  const { gqlClient } = useAuth();

  return ({ repairs }: { repairs: Repair[] }) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation update($repairs: [UpdateRepairData]) {
          updateRepairs(repairs: $repairs) {
            count
          }
        }
      `,
      variables: {
        repairs,
      },
    });
  };
}
