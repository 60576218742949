import { SelectField } from "@doorstead/components";
import { AutoPayAmount } from "../../@types/AutoPay/typings";

export function AutopayAmountField() {
  return (
    <SelectField
      disabled
      id="payment_amount"
      label="Payment amount"
      value={AutoPayAmount.ACCOUNT_BALANCE}
      options={[{ name: "Pay account balance", value: AutoPayAmount.ACCOUNT_BALANCE }]}
    />
  );
}
