import produce from "immer";
import { useMutation } from "react-query";
import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { useInspectionReportId } from "./useInspectionReportId";
import { useUpdateThroughoutPropertyCleaningCache } from "./useUpdateThroughoutPropertyCleaningCache";
import {
  useMapToArgs,
  useUpdateThroughoutPropertyCleaningToServer,
} from "./useUpdateThroughoutPropertyCleaningToServer";

export function useSaveThroughoutPropertyCleaningSubPage() {
  const updateThroughoutPropertyCleaningCache = useUpdateThroughoutPropertyCleaningCache();
  const updateThroughoutPropertyCleaningToServer = useUpdateThroughoutPropertyCleaningToServer();
  const mapToArgs = useMapToArgs();
  const inspectionReportId = useInspectionReportId();

  return useMutation(async (throughoutPropertyCleaning: ThroughoutPropertyCleaning) => {
    await updateThroughoutPropertyCleaningCache(throughoutPropertyCleaning);

    if (isOnline()) {
      const {
        data: {
          updateInspectionReportThroughoutPropertyCleaning: {
            success,
            merged,
            throughoutPropertyCleaning: returnedThroughoutPropertyCleaning,
          },
        },
      } = await updateThroughoutPropertyCleaningToServer(mapToArgs(throughoutPropertyCleaning));
      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          //save back to cache
          const newCache = produce(cache, (draft) => {
            if (draft !== null) {
              draft.inspectionReport.cleanIsNeeded = returnedThroughoutPropertyCleaning.cleanIsNeeded;
              draft.inspectionReport.cleanSelectedOptions = returnedThroughoutPropertyCleaning.cleanSelectedOptions;
              draft.inspectionReport.cleanIsOdorRemoval = returnedThroughoutPropertyCleaning.cleanIsOdorRemoval;
              draft.inspectionReport.cleanNotes = returnedThroughoutPropertyCleaning.cleanNotes;
              draft.inspectionReport.updatedAtThroughoutPropertyCleaning =
                returnedThroughoutPropertyCleaning.updatedAtThroughoutPropertyCleaning;
            }
          });

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, newCache);
        }
      }
    }
  });
}
