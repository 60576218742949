import { Box, Flex, Img, VStack } from "@chakra-ui/react";

export function TPOPaidCard() {
  return (
    <Flex w="100%" justifyContent="center" alignItems="center" bgColor="white" borderRadius="20px" px={10} py={30}>
      <Flex w="100%" h="100%">
        <VStack flex={1} pr={10}>
          <Box fontSize="2xl" fontWeight="bold" color="gray.700">
            Thanks! We’ll reach out soon with next steps
          </Box>
          <Box color="gray.500">
            Once we confirm the security deposit funds have been received we will connect you with the rental property
            owner.
          </Box>
        </VStack>
        <Flex w="85px" justifyContent="center" alignItems="center">
          <Img w="85px" h="85px" src="https://doorstead-components.s3.us-west-2.amazonaws.com/ticked.svg" />
        </Flex>
      </Flex>
    </Flex>
  );
}
