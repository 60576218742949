import produce from "immer";
import { arrayToMap } from "../../../utils/arrayToMap";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { AreaNode } from "./AreaNode";
import { ItemNode } from "./ItemNode";
import { mergeComponentsCache } from "./mergeComponentsCache";

export function mergeItemsCache(cacheArea: AreaNode, serverArea: AreaNode): ItemNode[] {
  const cacheItems = cacheArea.itemsData || [];
  const serverItems = serverArea.itemsData || [];
  const cacheItemMap = arrayToMap(cacheItems, "itemDataId");
  const serverItemMap = arrayToMap(serverItems, "itemDataId");

  const toRemoveIds: Set<string> = new Set();
  let newCacheItems = produce(cacheItems, (draft) => {
    draft.forEach((cacheItem) => {
      if (serverItemMap.has(cacheItem.itemDataId)) {
        const serverItem = serverItemMap.get(cacheItem.itemDataId)!;

        if (
          shouldInvalidateCache({
            cacheTime: cacheItem.itemDataUpdatedAt,
            serverTime: serverItem.itemDataUpdatedAt,
          })
        ) {
          //merge the area
          cacheItem.itemSelectedCosmeticOptions = serverItem.itemSelectedCosmeticOptions;
          cacheItem.itemNotes = serverItem.itemNotes;
          cacheItem.itemPhotoUrlsJson = getMergedPhotos(cacheItem.itemPhotoUrlsJson, serverItem.itemPhotoUrlsJson);
          cacheItem.itemIsItemPresent = serverItem.itemIsItemPresent;
          cacheItem.itemDataStatus = serverItem.itemDataStatus;
          cacheItem.itemDataCreatedAt = serverItem.itemDataCreatedAt;
          cacheItem.itemDataUpdatedAt = serverItem.itemDataUpdatedAt;
        }

        //merge the structure
        if (
          shouldInvalidateCache({
            cacheTime: cacheItem.itemUpdatedAt,
            serverTime: serverItem.itemUpdatedAt,
          })
        ) {
          cacheItem.itemName = serverItem.itemName;
          cacheItem.itemUpdatedAt = serverItem.itemUpdatedAt;
          cacheItem.itemCosmeticDefect = serverItem.itemCosmeticDefect;
          cacheItem.itemCosmeticOptions = serverItem.itemCosmeticOptions;
          cacheItem.itemRequirements = serverItem.itemRequirements;
          cacheItem.itemShouldRender = serverItem.itemShouldRender;
          cacheItem.itemCosmeticOptionSelectionType = serverItem.itemCosmeticOptionSelectionType;
          cacheItem.itemCategory = serverItem.itemCategory;
        }

        cacheItem.componentsData = mergeComponentsCache(cacheItem, serverItem);
      } else {
        toRemoveIds.add(cacheItem.itemDataId);
      }
    });
  });

  if (
    shouldInvalidateCache({
      cacheTime: cacheArea.areaDataItemsUpdatedAt,
      serverTime: serverArea.areaDataItemsUpdatedAt,
    })
  ) {
    newCacheItems = newCacheItems.filter((e) => !toRemoveIds.has(e.itemDataId));

    for (const serverItem of Array.from(serverItemMap.values())) {
      if (!cacheItemMap.has(serverItem.itemDataId)) {
        newCacheItems.push(serverItem);
      }
    }
  }

  return newCacheItems;
}
