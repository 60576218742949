import { StackProps, VStack } from "@chakra-ui/react";
import { SelectField } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { Status, Type } from "@doorstead/components/fields/TextField";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import { isNullOrEmptyString } from "src/utils/isNullOrEmptyString";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { useUpdateDoorsteadUserField } from "../../../../../../hooks/applicants/useUpdateDoorsteadUserField";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";
import { ApplicantTextField } from "../../../../ApplicantTextField";
import { CardTitle } from "../../../../CardTitle";
import { Labels } from "../../../labels";
import { formatPhoneNumber, isValidPhoneNumber } from "./formatPhoneNumber";
import { replaceAllowedChars } from "./replaceAllowedChars";

export type Props = StackProps & {
  initialValue: {
    firstName: string;
    middleName: string;
    lastName: string;
    suffixName: string;
    phone: string;
    email: string;
  };
  padding: number;
  width: number | string;
  maxWidth: number | string;
  id: string;
  onContinue: () => void;
};

export const NameAndContactInformationCard = React.forwardRef(
  ({ initialValue, padding, width, maxWidth, id, onContinue }: Props, ref) => {
    const disabled = !useIsApplicantEditable();
    const isMobile = useIsMobile();
    const [{ email, phone, firstName, middleName, lastName, suffixName }, setValue] =
      useState<Props["initialValue"]>(initialValue);
    const { mutate: updateDoorsteadUserField } = useUpdateDoorsteadUserField();
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue.firstName, initialValue.lastName, initialValue.phone, initialValue.email]);

    return (
      <Card id={id} padding={padding} width={width} maxWidth={maxWidth} ref={ref}>
        <VStack width="100%" alignItems="flex-start">
          <CardTitle>Name + contact information</CardTitle>
          <GrayText>Please ensure full legal name and contact information are accurate.</GrayText>
          <ApplicantTextField
            id="first_name"
            label={Labels.FirstName}
            disabled={disabled}
            defaultValue={firstName}
            transform={replaceAllowedChars}
            getStatus={getTextRequiredStatus}
            validator={(firstName) => !isNullOrEmptyString(firstName)}
            onSyncToServer={(firstName) => {
              updateDoorsteadUserField({
                firstName,
              });
            }}
          />
          <ApplicantTextField
            id="middle_name"
            label={Labels.MiddleName}
            disabled={disabled}
            defaultValue={middleName}
            status={Status.Default}
            transform={replaceAllowedChars}
            onSyncToServer={async (middleName) => {
              setValue((value) => ({ ...value, middleName }));
              updateDoorsteadUserField({
                middleName,
              });
            }}
          />
          <ApplicantTextField
            id="last_name"
            label={Labels.LastName}
            defaultValue={lastName}
            disabled={disabled}
            getStatus={getTextRequiredStatus}
            validator={(lastName) => !isNullOrEmptyString(lastName)}
            transform={replaceAllowedChars}
            onSyncToServer={async (lastName) => {
              setValue((value) => ({ ...value, lastName }));
              updateDoorsteadUserField({
                lastName,
              });
            }}
          />
          <SelectField
            id="suffix_name"
            label={Labels.Suffix}
            value={suffixName}
            options={[
              {
                value: "Jr",
                name: "Jr",
              },
              {
                value: "Sr",
                name: "Sr",
              },
              {
                value: "II",
                name: "II",
              },
              {
                value: "III",
                name: "III",
              },
              {
                value: "IV",
                name: "IV",
              },
            ]}
            onChange={({ target: { value: suffixName } }) => {
              setValue((value) => ({ ...value, suffixName }));
              updateDoorsteadUserField({
                suffixName,
              });
            }}
          />
          <ApplicantTextField
            id="phone"
            label={Labels.Phone}
            defaultValue={phone}
            disabled={disabled}
            getStatus={(phone) => {
              return !isValidPhoneNumber(phone) ? Status.Error : getTextRequiredStatus(phone);
            }}
            validator={(phone) => !isNullOrEmptyString(phone) && isValidPhoneNumber(phone)}
            transform={formatPhoneNumber}
            onSyncToServer={async (phone) => {
              setValue((value) => ({ ...value, phone }));
              const sanitizedPhone = phone.replace(/\D/g, "");
              updateDoorsteadUserField({
                phone: sanitizedPhone,
              });
            }}
          />
          <ApplicantTextField
            id="email"
            type={Type.Email}
            label={Labels.Email}
            defaultValue={email}
            getStatus={getTextRequiredStatus}
            validator={(email) => !isNullOrEmptyString(email)}
            disabled
            onSyncToServer={(email) => setValue((value) => ({ ...value, email }))}
          />
          <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
            Continue
          </PrimaryButton>
        </VStack>
      </Card>
    );
  }
);
