export enum Labels {
  FirstName = "First name",
  MiddleName = "Middle name",
  LastName = "Last name",
  Suffix = "Suffix",
  Phone = "Phone",
  Email = "Email",
  CurrentResidenceAddressLine1 = "Current residence address",
  CurrentResidenceAddressLine1Format = `Please enter a valid address line with the following format:
  - Should contain alphanumeric characters (letters and numbers)
  - May include hyphens, periods, commas, and spaces
  - Special characters (#) and symbols are not allowed
`,
  CurrentResidenceState = "Current residence state",
  CurrentResidenceZipCode = "Current residence zip code",
  PreviousResidenceState = "Previous residence state",
  PreviousResidenceZipCode = "Previous residence zip code",
  EmploymentState = "Employment state",
  EmploymentZipCode = "Employment zip code",
  SSN = "Social security number",
  Birthday = "Date of Birth",
}
