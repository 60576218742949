import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeKeyDetailsCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    if (
      shouldInvalidateCache({
        cacheTime: cache.inspectionReport.updatedAtKeyDetail,
        serverTime: rrGraphQLResultServer.inspectionReport.updatedAtKeyDetail,
      })
    ) {
      cache.inspectionReport.frontDoorKeys = rrGraphQLResultServer.inspectionReport.frontDoorKeys;
      cache.inspectionReport.frontDoorKeysWorking = rrGraphQLResultServer.inspectionReport.frontDoorKeysWorking;
      cache.inspectionReport.frontDoorKeysNotes = rrGraphQLResultServer.inspectionReport.frontDoorKeysNotes;
      cache.inspectionReport.frontDoorKeysPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.frontDoorKeysPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.frontDoorKeysPhotoUrlsJson
      );
      cache.inspectionReport.mailboxKeys = rrGraphQLResultServer.inspectionReport.mailboxKeys;
      cache.inspectionReport.mailboxKeysWorking = rrGraphQLResultServer.inspectionReport.mailboxKeysWorking;
      cache.inspectionReport.mailboxKeysNotes = rrGraphQLResultServer.inspectionReport.mailboxKeysNotes;
      cache.inspectionReport.mailboxKeysPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.mailboxKeysPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.mailboxKeysPhotoUrlsJson
      );

      cache.inspectionReport.communityKeys = rrGraphQLResultServer.inspectionReport.communityKeys;
      cache.inspectionReport.communityKeysWorking = rrGraphQLResultServer.inspectionReport.communityKeysWorking;
      cache.inspectionReport.communityKeysNotes = rrGraphQLResultServer.inspectionReport.communityKeysNotes;
      cache.inspectionReport.communityKeysPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.communityKeysPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.communityKeysPhotoUrlsJson
      );

      cache.inspectionReport.garageRemotes = rrGraphQLResultServer.inspectionReport.garageRemotes;
      cache.inspectionReport.garageRemotesWorking = rrGraphQLResultServer.inspectionReport.garageRemotesWorking;
      cache.inspectionReport.garageRemotesNotes = rrGraphQLResultServer.inspectionReport.garageRemotesNotes;
      cache.inspectionReport.garageRemotesPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.garageRemotesPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.garageRemotesPhotoUrlsJson
      );

      cache.inspectionReport.keypads = rrGraphQLResultServer.inspectionReport.keypads;
      cache.inspectionReport.keypadsWorking = rrGraphQLResultServer.inspectionReport.keypadsWorking;
      cache.inspectionReport.keypadsNotes = rrGraphQLResultServer.inspectionReport.keypadsNotes;
      cache.inspectionReport.keypadsPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.keypadsPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.keypadsPhotoUrlsJson
      );

      cache.inspectionReport.additionalKeys = rrGraphQLResultServer.inspectionReport.additionalKeys;
      cache.inspectionReport.additionalKeysWorking = rrGraphQLResultServer.inspectionReport.additionalKeysWorking;
      cache.inspectionReport.additionalKeysNotes = rrGraphQLResultServer.inspectionReport.additionalKeysNotes;
      cache.inspectionReport.additionalKeysPhotoUrlsJson = getMergedPhotos(
        rrGraphQLResultCache.inspectionReport.additionalKeysPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.additionalKeysPhotoUrlsJson
      );

      cache.inspectionReport.updatedAtKeyDetail = rrGraphQLResultServer.inspectionReport.updatedAtKeyDetail;
    }
  });
}
