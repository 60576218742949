import { Box, Flex, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { Estimate } from "../../../../../@types/RentReady/Estimate";
import { useAppendPositionToHashTag } from "../../../../../hooks/rentReady/useAppendPositionToHashTag";
import { useEstimateId } from "../../../../../hooks/rentReady/useEstimateId";
import { View, useView } from "../../../../../hooks/rentReady/useView";
import { EstimatePhotosBox } from "../../../EstimatePhotosBox";
import { getSummary } from "./getSummary";

function useOnAddEstimatesClick() {
  const { setEstimateId } = useEstimateId();
  const { setView } = useView();
  const appendPositionToHashTag = useAppendPositionToHashTag("estimate_list");

  return (id: string) => {
    appendPositionToHashTag();
    setEstimateId(id);
    setView(View.EstimateEditView);
  };
}

export function EstimateCard(estimate: Estimate) {
  const onAddEstimatesClick = useOnAddEstimatesClick();
  const { uiName, description, photos: estimatePhotos } = estimate;

  return (
    <>
      <VStack w="100%" alignItems="flex-start">
        <Box color="gray.600" w="100%" fontWeight="bold">
          {uiName}
        </Box>
        {description.trim() !== "" && <Box fontWeight="normal">Notes: {description}</Box>}

        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <EstimatePhotosBox showZero={false} photos={estimatePhotos} prefix={null} />
          {estimate.decision === null ? (
            <PrimaryButton size="md" onClick={() => onAddEstimatesClick(estimate.id)}>
              Add Estimates
            </PrimaryButton>
          ) : (
            <VStack
              alignItems="flex-end"
              color="purple.500"
              fontWeight="bold"
              textDecoration="underline"
              onClick={() => onAddEstimatesClick(estimate.id)}
            >
              {getSummary(estimate).map((e, index) => (
                <Box key={index}>{e}</Box>
              ))}
            </VStack>
          )}
        </Flex>
      </VStack>
    </>
  );
}
