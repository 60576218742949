import { VStack } from "@chakra-ui/react";
import { useArea } from "../../../../../hooks/rentReady/useArea";
import { isAreaShowingHeatSource } from "../../../../../utils/rentReady/isAreaShowingHeatSource";
import { isAreaShowingSmokeDetector } from "../../../../../utils/rentReady/isAreaShowingSmokeDetector";
import { AreaHeatSourceBox } from "./AreaHeatSourceBox";
import { AreaSmokeDetectorFoundBox } from "./AreaSmokeDetectorFoundBox";

export function AreaOneBoxMoveIn() {
  const area = useArea()!;

  return (
    <VStack w="100%" alignItems="flex-start" p={4} spacing={5}>
      {isAreaShowingSmokeDetector(area) && <AreaSmokeDetectorFoundBox />}
      {isAreaShowingHeatSource(area) && <AreaHeatSourceBox />}
    </VStack>
  );
}
