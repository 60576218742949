import { Photo } from "../../@types/RentReady/Photo";

export function mapPhotosToPhotoUrlsJson(photos: Photo[]): string | null {
  return JSON.stringify(
    photos.map((photo) => ({
      id: photo.id,
      src: photo.src,
      thumbnail: photo.thumbnail,
      isUploaded: photo.isUploaded,
      toUploadFile: photo.toUploadFile,
    }))
  );
}
