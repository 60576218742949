import { db } from "../../databases/FlexWalkthroughDatabase";
import { FlexWalkthroughReportNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

type Args = {
  field: keyof FlexWalkthroughReportNode;
  value: any;
};

export function useUpdatePartialWalkthroughReportToCache() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();

  return async ({ field, value }: Args) => {
    return db.transaction("rw", db.flex_walkthrough_report_graphql_result, async () => {
      await db.flex_walkthrough_report_graphql_result
        .where("flex_walkthrough_report_id")
        .equals(flexWalkthroughReportId)
        .modify(function (ignored, ref) {
          const reportNode = ref.value.flexWalkthroughReport;
          if (reportNode) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            reportNode[field] = value;
          }
        });
    });
  };
}
