import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { useRefetchPaymentMethods } from "./useRefetchPaymentMethods";
import { useSuccess } from "./useSuccess";

const MUTATION = gql`
  mutation ($id: UUID!) {
    deletePaymentMethod(paymentMethodId: $id) {
      result
    }
  }
`;

export function useRemovePaymentMethod() {
  const { gqlClient } = useAuth();
  const success = useSuccess();

  const { refetchPaymentMethods, isLoading: isPaymentMethodsLoading } = useRefetchPaymentMethods();
  const mutation = useMutation(
    async (paymentMethodId: string) => {
      await gqlClient.mutate({
        mutation: MUTATION,
        variables: {
          id: paymentMethodId,
        },
      });
      await refetchPaymentMethods();
    },
    {
      onSuccess() {
        success({ title: "Payment method removed!" });
      },
    }
  );

  return {
    removePaymentMethod: mutation.mutate,
    loading: mutation.isLoading || isPaymentMethodsLoading,
  };
}
