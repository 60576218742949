import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TextAreaField } from "src/components/rent-ready/fields/TextAreaField";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useIsReadOnly } from "src/hooks/flexWalkthrough/useIsReadOnly";
import { useSyncWalkthroughItemTextToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncWalkthroughItemTextToCacheAndServer";
import { useUpdatePartialWalkthroughItemToCache } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughItemToCache";
import { ArgsItem } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughItemToServer";
import { useIsOnline } from "src/hooks/useIsOnline";

type Props = {
  item: FlexWalkthroughItemNode;
  defaultValue: string;
  cacheField: keyof FlexWalkthroughItemNode;
  serverField: keyof ArgsItem;
};

export function ItemAutoSyncTextField({ item, defaultValue, cacheField, serverField }: Props) {
  const [value, setValue] = useState(defaultValue);
  const syncWalkthroughItemTextToCacheAndServer = useSyncWalkthroughItemTextToCacheAndServer();
  const updatePartialWalkthroughItemToCache = useUpdatePartialWalkthroughItemToCache();
  const isOnline = useIsOnline();
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box
      onBlur={async () => {
        if (defaultValue !== value) {
          if (isOnline) {
            await syncWalkthroughItemTextToCacheAndServer(item, cacheField, serverField, value, value);
          } else {
            await updatePartialWalkthroughItemToCache({
              id: item.itemDataId,
              field: cacheField,
              value,
            });
          }
        }
      }}
    >
      <TextAreaField
        isValid={item.notesRequired ? value.trim() !== "" : true}
        label=""
        value={value}
        placeholder="Notes..."
        onChange={setValue}
        disabled={useIsReadOnly()}
      />
    </Box>
  );
}
