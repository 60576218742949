import { useIsKeyStorageAdditionalKeyStorageFeature } from "../useIsKeyStorageAdditionalKeyStorageFeature";
import { useKeyStorageCache } from "../useKeyStorageCache";

export function useIsAdditionalKeyStorageComplete(): boolean {
  const keyStorage = useKeyStorageCache();
  const isKeyStorageAdditionalKeyStorageFeature = useIsKeyStorageAdditionalKeyStorageFeature();

  if (keyStorage === null) {
    return false;
  }

  // if the feature is not enabled, then we can assume that the user has data
  if (!isKeyStorageAdditionalKeyStorageFeature) return true;

  const {
    additionalKeyStorage: { photos },
  } = keyStorage;

  return photos.length > 0;
}
