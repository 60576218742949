import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { mapInspectionReportNodeToKeyDetail } from "../../utils/rentReady/mapInspectionReportNodeToKeyDetail";
import { useInspectionReportCache } from "./useInspectionReportCache";

export function useKeyDetailCache(): KeyDetail | null {
  const inspectionReportCache = useInspectionReportCache();

  if (inspectionReportCache === null) return null;

  return mapInspectionReportNodeToKeyDetail(inspectionReportCache);
}
