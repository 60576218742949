import { Box, HStack } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";
import { useIsZtpo } from "../../../../hooks/applicants/useIsZtpo";

const originalPrice = 45;

export function ZTPoPriceBox() {
  return (
    <HStack fontWeight="bold">
      <Box textDecoration="line-through" color="gray.400">
        {moneyString(originalPrice, false)}
      </Box>
      <Box>$5</Box>
    </HStack>
  );
}

export function PriceBox() {
  const isZtpo = useIsZtpo();
  if (isZtpo) {
    return <ZTPoPriceBox />;
  }
  return <Box>{moneyString(originalPrice, false)}</Box>;
}
