import axios, { CancelTokenSource } from "axios";
import { useMutation } from "react-query";
import { useError } from "../useError";
import { Bucket, useGenerateS3UploadUrl } from "../useGenerateS3UploadUrl";
import { useSuccess } from "../useSuccess";
import { useUploadToS3 } from "../useUploadToS3";
import { ApplicantFileNodeType } from "./useFetchApplicationMe/ApplicantFileNode";

export function useUploadFile(
  onProgress: ({ progress, file }: { progress: number; file: File }) => void,
  onDone: ({ bucket, key, file }: { bucket: string; key: string; file: File }) => void
) {
  const success = useSuccess();
  const error = useError();
  const generateS3UploadUrl = useGenerateS3UploadUrl();
  const uploadToS3 = useUploadToS3();

  return useMutation(
    async ({
      file,
      fileType,
      setCancelSourceToken,
    }: {
      file: File;
      fileType: ApplicantFileNodeType;
      setCancelSourceToken: (cancelSourceToken: CancelTokenSource) => void;
    }) => {
      try {
        onProgress({ file, progress: 0 });
        const fileName = file.name;

        //1.get the signed Url
        const { bucket, key, url } = await generateS3UploadUrl({
          fileName,
          prefix: fileType.toLowerCase(),
          bucket: Bucket.Applicant,
        });

        //2. directly upload to S3
        const { cancelSource } = await uploadToS3({ url, file, onProgress });
        setCancelSourceToken(cancelSource);

        //3. DONE
        onProgress({ file, progress: 100 });
        success({ title: "Success", description: `File was uploaded` });
        onDone({ bucket, key, file });
      } catch (e) {
        if (axios.isCancel(e)) {
          success({
            title: "Upload cancelled",
            description: "",
          });
        } else {
          error({
            title: "Error",
            description: "Oops, something wrong happens. Please try another file.",
          });
        }
      }
    }
  );
}
