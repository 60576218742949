import { Flex } from "@chakra-ui/react";

type Props = {
  onClick?: () => void;
};

export function ZeroPhotoThumbnail({ onClick }: Props) {
  return (
    <Flex
      cursor="pointer"
      w="75px"
      h="75px"
      justifyContent="center"
      alignItems="center"
      fontWeight="bold"
      borderRadius="5px"
      fontSize="xl"
      boxShadow="inset 0 0 0 2px #CFD6E1"
      color="gray.500"
      onClick={onClick}
    >
      0
    </Flex>
  );
}
