import { Box } from "@chakra-ui/react";
import { Estimate } from "../../../../@types/RentReady/Estimate";
import { useEstimates } from "../../../../hooks/rentReady/useEstimates";
import { EstimatePhotosBox } from "../../EstimatePhotosBox";

function Content({ uiName, description, photos }: Estimate) {
  return (
    <Box
      w="100%"
      display="grid"
      minH="75px"
      gridTemplateAreas={`
        "name photo"
        "note photo"
    `}
      gridTemplateColumns="1fr auto"
      gridTemplateRows="auto auto"
    >
      <Box gridArea="name" fontWeight="semibold" wordBreak="break-word">
        {uiName}
      </Box>
      {description.trim() !== "" && (
        <Box gridArea="note" fontSize="sm" wordBreak="break-word">
          Notes: {description}
        </Box>
      )}
      <Box gridArea="photo">
        <EstimatePhotosBox prefix={null} photos={photos} />
      </Box>
    </Box>
  );
}
export function ChildEstimateBox(estimate: Estimate) {
  const estimates = useEstimates();
  if (!estimate.isGrouped) {
    return <Content {...estimate} />;
  }

  const elements = estimates.filter(({ throughoutPropertyType, dataType, isGrouped }) => {
    const bool = !isGrouped && throughoutPropertyType === estimate.throughoutPropertyType;
    if (bool) {
      switch (estimate.throughoutPropertyType) {
        case "Keys": {
          return dataType === "Keys";
        }
        default: {
          return dataType === "Area" || dataType === "Item" || dataType === "Throughout Property";
        }
      }
    }
    return false;
  });

  return (
    <>
      {elements.map((estimate) => (
        <Content key={estimate.id} {...estimate} />
      ))}
    </>
  );
}
