import withAuth from "src/utils/withAuth";
import { EverGreenMakePayment } from "../components/evergreens/EverGreenMakePayment";
import { TenantPortalProvider } from "../providers/TenantPortalProvider";

export default withAuth(() => {
  return (
    <TenantPortalProvider>
      <EverGreenMakePayment />
    </TenantPortalProvider>
  );
});
