import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { CrossIcon, GrayText, OutlineButton, PrimaryButton, useIsMobile } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { CardTitle } from "../../CardTitle";
import { Content } from "./Content";
import { Props } from "./typings";

export function NewApplicantsCard({
  values,
  title,
  subTitle,
  eventName,
  addLabel,
  numberLabel,
  onAdd,
  onChange,
  onRemove,
  onContinue,
  ...stackProps
}: Props) {
  const applicantAnalytics = useApplicantAnalytics();
  const isMobile = useIsMobile();

  return (
    <Card {...stackProps} padding={7} width="100%">
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>{title}</CardTitle>
        <GrayText width="100%">{subTitle}</GrayText>
        {values.map((value, index) => {
          return (
            <VStack width="100%" key={value.id} spacing={2} alignItems="flex-start">
              <Flex width="100%" alignItems="center" justifyContent="space-between">
                <Box color="gray.800" fontFamily="Inter" fontSize={20} fontWeight={600}>
                  {numberLabel} #{index + 1}
                </Box>
                <CrossIcon
                  size={15}
                  onClick={() => {
                    applicantAnalytics?.track(`TP_${eventName}_REMOVED`, { index });
                    onRemove(index);
                  }}
                />
              </Flex>
              <Content value={value} onChange={(applicant) => onChange(applicant, index)} />
            </VStack>
          );
        })}
        <Stack direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
          <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
            Continue
          </PrimaryButton>
          <OutlineButton
            isFullWidth={isMobile}
            onClick={() => {
              applicantAnalytics?.track(`TP_${eventName}_ADDED`);
              onAdd();
            }}
          >
            {addLabel}
          </OutlineButton>
        </Stack>
      </VStack>
    </Card>
  );
}
