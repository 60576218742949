import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

export type Props = BoxProps & {
  children: React.ReactNode;
};

export function Card({ children, ...boxProps }: Props) {
  return (
    <Box alignItems="left" w="100%" bgColor="white" borderRadius={20} padding={5} {...boxProps}>
      {children}
    </Box>
  );
}
