import { Item } from "../../@types/RentReady/Item";
import { useIsEditable } from "./useIsEditable";
import { useIsFullPaintAreaItem } from "./useIsFullPaintAreaItem";
import { useIsItemPurpleCircle } from "./useIsItemPurpleCircle";

export function useIsItemCrossIcon(item: Item): boolean {
  const isItemPurpleCircle = useIsItemPurpleCircle(item);
  const isFullPaintAreaItem = useIsFullPaintAreaItem(item);
  const isEditable = useIsEditable();

  if (!isEditable || isFullPaintAreaItem) {
    return false;
  }

  return isItemPurpleCircle;
}
