import { Option } from "../../../../@types/RentReady/Komponent";
import { isSelectionDisabled } from "../../../../utils/rentReady/isSelectionDisabled";

type Args = {
  isLoading: boolean;
  hasPhoto: boolean;
  hasNote: boolean;
  cosmeticOptions: Option[];
  hasDataUpdated: boolean;
};

export function isSaveDisabled({ isLoading, hasPhoto, cosmeticOptions, hasNote, hasDataUpdated }: Args): boolean {
  //if no data, loading, or no photo, disabled
  if (!hasDataUpdated || isLoading || !hasPhoto) {
    return true;
  }
  return isSelectionDisabled({ options: cosmeticOptions, hasNote });
}
