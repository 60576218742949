import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { BinIcon } from "@doorstead/components/icons/BinIcon";
import { Item } from "../../../../@types/RentReady/Item";
import { useArea } from "../../../../hooks/rentReady/useArea";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { Row } from "../../Row";
import { OptionPresentButtons } from "./OptionPresentButtons";

type Props = {
  name?: string;
  items: Item[];
  onChange: (uiId: string, isPresent: boolean | null) => void;
  onDelete: (uiId: string) => void;
  isShowDelete: (item: Item) => boolean;
};

export function ItemList({ name = "", items, isShowDelete, onDelete, onChange }: Props) {
  const { isThroughoutProperty } = useArea()!;
  const isV2 = useIsV2UiFeatures();
  return (
    <>
      {name.trim() !== "" && <Row color="gray.800" bgColor="gray.300" left={name} />}
      <VStack w="100%" px={5}>
        {items.length === 0 ? (
          <Flex justifyContent="flex-start" alignItems="center" w="100%" my={5}>
            No items
          </Flex>
        ) : (
          items.map((item, index) => {
            const { uiId, uiName: name } = item;
            return (
              <Flex
                key={`OPTIONAL_${uiId}_${index}`}
                px={0}
                py={2}
                w="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box color="gray.500">{name}</Box>
                {!isThroughoutProperty && (
                  <HStack>
                    {!isV2 && (
                      <OptionPresentButtons
                        isPresent={item.isPresent}
                        onChange={(isPresent) => onChange(uiId, isPresent)}
                      />
                    )}

                    {isShowDelete(item) && (
                      <Box onClick={() => onDelete(uiId)}>
                        <BinIcon size={24} color="gray.500" />
                      </Box>
                    )}
                  </HStack>
                )}
              </Flex>
            );
          })
        )}
      </VStack>
    </>
  );
}
