import { HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { Decision, Estimate } from "../../../../@types/RentReady/Estimate";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

type Props = {
  decision: Estimate["decision"];
  onChange: (decision: Estimate["decision"]) => void;
};

export function DecisionField({ decision, onChange }: Props) {
  const isReadOnly = useIsReadOnly();
  const isAddEstimates = decision === Decision.AddEstimates;
  const isCannotEstimates = decision === Decision.CanNotEstimate;
  const isError = decision === null;

  const AddEstimatesButton = isAddEstimates ? PrimaryButton : OutlineButton;
  const CannotEstimateButton = isCannotEstimates ? PrimaryButton : OutlineButton;

  return (
    <HStack w="100%" spacing={5} borderWidth={isError ? "3px" : ""} borderColor={isError ? "red.300" : ""}>
      <AddEstimatesButton
        isFullWidth
        disabled={isReadOnly}
        onClick={() => {
          onChange(decision === Decision.AddEstimates ? null : Decision.AddEstimates);
        }}
      >
        Add Estimate
      </AddEstimatesButton>
      <CannotEstimateButton
        isFullWidth
        disabled={isReadOnly}
        onClick={() => {
          onChange(decision === Decision.CanNotEstimate ? null : Decision.CanNotEstimate);
        }}
      >
        Cannot Estimate
      </CannotEstimateButton>
    </HStack>
  );
}
