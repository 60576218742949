import { useEffect, useRef } from "react";
import { GroupAnalytics, useGroupAnalytics } from "./useGroupAnalytics";

export function usePageView(eventName: string): GroupAnalytics {
  const analytics = useGroupAnalytics();
  const isPageViewed = useRef(false);

  useEffect(() => {
    if (analytics && !isPageViewed.current) {
      analytics.track(eventName);
      isPageViewed.current = true;
    }
  }, [analytics]);

  return analytics;
}
