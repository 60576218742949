import { Box } from "@chakra-ui/react";
import { HintBox } from "@doorstead/components/containers/HintBox";
import { moneyString } from "@doorstead/components/utils";
import { useHoldingDepositAmountDue } from "src/hooks/useHoldingDepositAmountDue";
import { useIsSkipWAHoldingDeposit } from "../../hooks/useIsSkipWAHoldingDeposit";

export function HoldingDepositHintBox() {
  const holdingDepositAmountDue = useHoldingDepositAmountDue();
  const skipWAHoldingDeposit = useIsSkipWAHoldingDeposit();
  if (skipWAHoldingDeposit || holdingDepositAmountDue === null || holdingDepositAmountDue === 0) {
    return null;
  }

  return (
    <HintBox>
      <Box fontSize="sm">
        {moneyString(holdingDepositAmountDue)}{" "}
        <Box as="span" color="gray.500" mr={1}>
          holding deposit required to secure lease. This amount will be applied toward your security deposit.
        </Box>
      </Box>
    </HintBox>
  );
}
