import { Flex } from "@chakra-ui/react";
import { ReactNode, useRef } from "react";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { useFileGroupProgress } from "../../../../../hooks/rentReady/useUploadProgress";
import { getFilesFromChangeEvent } from "../../../../../utils/getFilesFromChangeEvent";
import { ProgressBox } from "../../../ProgressBox";

type Props = {
  id: string;
  prefix: Prefix;
  onSelectFiles: (files: File[]) => void;
  isValid?: boolean;
  children: ReactNode;
};

export function Input({ prefix, id, onSelectFiles, isValid = true, children }: Props) {
  const { progress, isUploading } = useFileGroupProgress({ group: prefix });
  const ref = useRef<HTMLFormElement>(null);

  return (
    <form
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5,
        border: isValid ? "dotted 1px #B0B0B0" : "solid 1px red",
        width: 75,
        height: 75,
      }}
    >
      <Flex justifyContent="center" alignItems="center" as="label" htmlFor={id} cursor="pointer" w={75} h={75}>
        {isUploading ? <ProgressBox progress={progress} /> : children}
      </Flex>
      <input
        id={id}
        capture="environment"
        type="file"
        multiple
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e) => {
          const files: File[] = getFilesFromChangeEvent(e);
          if (files.length > 0) {
            onSelectFiles(files);
          }
          ref.current?.reset();
        }}
      />
    </form>
  );
}
