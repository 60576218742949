import { NumberField } from "@doorstead/components/fields/NumberField";
import { useIsReadOnly } from "../../../hooks/rentReady/useIsReadOnly";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export function RentReadyNumberField(props: Props) {
  const isReadOnly = useIsReadOnly();

  return (
    <NumberField
      disabled={isReadOnly}
      w="120px"
      min={0}
      max={100}
      step={1}
      value={props.value}
      onChange={props.onChange}
    />
  );
}
