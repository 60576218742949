import { Box, Flex } from "@chakra-ui/react";
import { CrossIcon } from "@doorstead/components/icons/CrossIcon";
import { useApplicantAnalytics } from "../../../../../hooks/applicants/useApplicantAnalytics";
import {
  ApplicantFileNode,
  ApplicantFileNodeType,
} from "../../../../../hooks/applicants/useFetchApplicationMe/ApplicantFileNode";
import { useIsApplicantEditable } from "../../../../../hooks/applicants/useIsApplicantEditable";
import { useOnFileClick } from "./useOnFileClick";
import { useRemoveFile } from "./useRemoveFile";

export function UploadedView({
  applicantFile,
  applicantFileType,
}: {
  applicantFile: ApplicantFileNode;
  applicantFileType: ApplicantFileNodeType;
}) {
  const { bucket, key, fileName } = applicantFile;
  const applicantAnalytics = useApplicantAnalytics();
  const { mutate } = useRemoveFile();
  const editable = useIsApplicantEditable();
  const fileClick = useOnFileClick();

  return (
    <Flex justifyContent="space-between" w="100%">
      <Box
        as="a"
        w="100%"
        alignItems="center"
        _hover={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={async () => {
          applicantAnalytics?.track(`TP_${applicantFileType}_FILE_CLICKED`);
          await fileClick({
            bucket,
            key,
          });
        }}
      >
        {fileName}
      </Box>
      {editable && (
        <CrossIcon
          onClick={() => {
            if (window.confirm("Are you sure to remove this file?")) {
              const applicantFileId = applicantFile.applicantFileId;
              applicantAnalytics?.track(`TP_${applicantFileType}_FILE_REMOVE_CLICKED`, { applicantFileId });
              mutate({ applicantFileId });
            }
          }}
        />
      )}
    </Flex>
  );
}
