import { HStack } from "@chakra-ui/react";
import { ScaleBox } from "./ScaleBox";

type Props = {
  value: boolean | null;
  onChange: (value: boolean) => void;
};

export function YesNoField({ value, onChange }: Props) {
  return (
    <HStack spacing={5} w="100%">
      <ScaleBox
        flex={1}
        label="Yes"
        value={value === true}
        onChange={(value) => {
          if (value) {
            onChange(true);
          }
        }}
      />
      <ScaleBox
        flex={1}
        label="No"
        value={value === false}
        onChange={(value) => {
          if (value) {
            onChange(false);
          }
        }}
      />
    </HStack>
  );
}
