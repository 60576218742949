import { Alert, AlertDescription, AlertIcon, Box, Heading, Link, VStack } from "@chakra-ui/react";
import produce from "immer";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { ApplicantType } from "src/hooks/applicants/useInviteCoTenantsAndGuarantors";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { getUuid } from "src/utils/getUuid";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { InviteCard } from "./InviteCard/InviteCard";
import { NewApplicantsCard } from "./NewApplicantsCard";
import { NewApplicantWithId } from "./NewApplicantsCard/typings";

export function AddCotenantsAndGuarantorsPage() {
  useApplicantPageView("TP_ADD_COTENANTS_AND_GUARANTORS_VIEWED");
  const applicantAnalytics = useApplicantAnalytics();
  const scrollIntoView = useScrollIntoView();
  const applicantId = useApplicantId();
  const [coTenants, setCoTenants] = useState<NewApplicantWithId[]>([]);
  const [guarantors, setGuarantors] = useState<NewApplicantWithId[]>([]);

  return (
    <>
      <Link as={RouterLink} to={`/application/${applicantId}`} color="purple.600" fontSize={16} fontWeight={500}>
        &lt; Back
      </Link>
      <Heading variant="h4" fontSize={36} fontWeight={600} mt={2}>
        Add co-tenants and guarantors
      </Heading>
      <NewApplicantsCard
        id="/0"
        mt={8}
        eventName="COTENANT"
        title="Co-tenants"
        addLabel="Add Co-tenant"
        numberLabel="Co-tenant"
        subTitle={
          <VStack spacing={4} width="100%" alignItems="flex-start">
            <Box>Will you have other tenants living with you?</Box>
            <Alert status="warning" backgroundColor="purple.100" borderRadius={6}>
              <AlertIcon color="purple.400" />
              <AlertDescription>
                <b>Important: </b>
                If applying with co-tenants, each person must submit a separate application.
                <br />
                Anyone age 18 or older who plans to live with you must be added here and submit their own application.
                <br />
                Each applicant must verify their identity through Persona by taking a selfie and providing a valid ID.
              </AlertDescription>
            </Alert>
          </VStack>
        }
        values={coTenants}
        onAdd={() => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantType.TENANT,
              });
            });
          });
        }}
        onChange={(applicant, index) => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(index) => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
        }}
        onContinue={() => {
          applicantAnalytics?.track("TP_ADD_COTENANTS_CARD_CONTINUED");
          scrollIntoView("/1");
        }}
      />
      <NewApplicantsCard
        id="/1"
        mt={8}
        eventName="GUARANTOR"
        title="Guarantors"
        addLabel="Add Guarantor"
        numberLabel="Guarantor"
        subTitle="Tenants need to demonstrate 3x monthly rent in income. Or they can add a Guarantor who can demonstrate 5x monthly rent in income."
        values={guarantors}
        onAdd={() => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantType.GUARANTOR,
              });
            });
          });
        }}
        onChange={(applicant, index) => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(index) => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
        }}
        onContinue={() => {
          applicantAnalytics?.track("TP_ADD_GUARANTORS_CARD_CONTINUED");
          scrollIntoView("/2");
        }}
      />
      <InviteCard id="/2" mt={8} coTenants={coTenants} guarantors={guarantors} />
    </>
  );
}
