import produce from "immer";
import { useMutation } from "react-query";
import { Area } from "../../@types/RentReady/Area";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { useInspectionReportId } from "./useInspectionReportId";
import { mapAreaCacheToServerArgs } from "./useMapAreaStateFromRRGraphQLResultCache";
import { useUpdateAreaCache } from "./useUpdateAreaCache";
import { useUpdateAreaToServer } from "./useUpdateAreaToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(area: Area): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  area.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.Areas;
    photos.push(photo as PhotoWithPrefix);
  });
  area.itemRemovalPhotos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.AreasPersonalItemRemovals;
    photos.push(photo as PhotoWithPrefix);
  });
  return photos;
}

function useGetUploadedArea() {
  const upload = useUpload();

  return async (area: Area): Promise<Area> => {
    return produce(area, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveAreaSubPage() {
  const updateAreaCache = useUpdateAreaCache();
  const updateAreaToServer = useUpdateAreaToServer();
  const inspectionReportId = useInspectionReportId();
  const getUploadedArea = useGetUploadedArea();

  return useMutation(async (area: Area) => {
    await updateAreaCache(area);
    if (isOnline()) {
      const newArea = await getUploadedArea(area);
      const {
        data: {
          updateInspectionReportArea: { success, merged, areaData: returnedAreaData },
        },
      } = await updateAreaToServer(mapAreaCacheToServerArgs(newArea));
      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          //save back to cache

          const newCache = produce(cache, (draft) => {
            draft.inspectionReport.data?.forEach((areaCache) => {
              if (areaCache.areaDataId === returnedAreaData.areaDataId) {
                areaCache.areaNotes = returnedAreaData.areaNotes;
                areaCache.areaSelectedCleaningOptions = returnedAreaData.areaSelectedCleaningOptions;
                areaCache.areaCleaningNotes = returnedAreaData.areaCleaningNotes;
                areaCache.areaSelectedPaintingOptions = returnedAreaData.areaSelectedPaintingOptions;
                areaCache.areaPaintingNotes = returnedAreaData.areaPaintingNotes;
                areaCache.areaSelectedItemRemoval = returnedAreaData.areaSelectedItemRemoval;
                areaCache.areaSelectedOdors = returnedAreaData.areaSelectedOdors;
                areaCache.areaPhotoUrlsJson = returnedAreaData.areaPhotoUrlsJson;
                areaCache.areaItemRemovalNotes = returnedAreaData.areaItemRemovalNotes;
                areaCache.areaItemRemovalPhotoUrlsJson = returnedAreaData.areaItemRemovalPhotoUrlsJson;
                areaCache.areaSmokeDetectorIsOneWorkingDetectorFound =
                  returnedAreaData.areaSmokeDetectorIsOneWorkingDetectorFound;
                areaCache.areaSmokeDetectorNotes = returnedAreaData.areaSmokeDetectorNotes;
                areaCache.areaSmokeDetectorStatus = returnedAreaData.areaSmokeDetectorStatus;
                areaCache.areaSmokeDetectorPhotoUrlsJson = returnedAreaData.areaSmokeDetectorPhotoUrlsJson;
                areaCache.areaDataUpdatedAt = returnedAreaData.areaDataUpdatedAt;
              }
            });
          });

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, newCache);
        }
      }
    }
  });
}
