import { Scale, getScaleString } from "../../@types/RentReady/Scale";

export function mapStringToScale(value: string | null): Scale {
  switch (value) {
    case getScaleString(Scale.Zero):
      return Scale.Zero;
    case getScaleString(Scale.One):
      return Scale.One;
    case getScaleString(Scale.Two):
      return Scale.Two;
    case getScaleString(Scale.Three):
      return Scale.Three;
    case getScaleString(Scale.Four):
      return Scale.Four;
    case getScaleString(Scale.NA):
      return Scale.NA;
    case null:
    default:
      return Scale.NotProvided;
  }
}
