import { Repair } from "../../@types/RepairList/Repair";
import { OwnerDecision } from "../../hooks/rr-project/useFetchRRProject";

export function getRepairStatusLabel(repair: Repair): string {
  const { ownerDecision, chosenVendorRepair } = repair;

  if (ownerDecision === OwnerDecision.No) {
    return "Rejected Repair";
  }

  if (ownerDecision === OwnerDecision.Yes) {
    if (chosenVendorRepair === null) {
      return "Requesting Estimates";
    } else {
      const { estimateApproved } = chosenVendorRepair;
      if (estimateApproved) {
        return "Approved Estimate";
      } else if (estimateApproved === false) {
        return "Rejected Estimate";
      }
    }
  }

  // Change the default value
  return "In Progress";
}
