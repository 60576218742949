import { gql } from "@apollo/client";
import { useAuth } from "../../../../../contexts/auth";

export type Value = {
  itemDataId: string;
  isItemPresent: boolean | null;
};

type Args = {
  values: Value[];
};

export function useUpdateOptions() {
  const { gqlClient } = useAuth();

  return async ({ values }: Args) => {
    //iterate the options and async update each option
    await gqlClient.mutate({
      mutation: gql`
        mutation ($items: [UpdateInspectionReportItemsDataInput]) {
          updateInspectionReportItemsData(items: $items) {
            count
          }
        }
      `,
      variables: {
        items: values,
      },
    });
  };
}
