import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { AutoPay } from "../@types/AutoPay/typings";
import { useAuth } from "../contexts/auth";
import { useFetchMe } from "./useFetchMe";

const mutation = gql`
  mutation ($autopays: [AutopayUpdateData]) {
    updateAutopays(autopays: $autopays) {
      count
    }
  }
`;

type Props = AutoPay & {
  autopayId: string;
};

export function useUpdateAutoPay() {
  const { refetch, isLoading: isMeLoading } = useFetchMe();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: updateAutoPay } = useMutation(
    async ({ autopayId, amount, date, paymentMethodId }: Props) => {
      await gqlClient.mutate({
        mutation,
        variables: {
          autopays: [
            {
              autopayId,
              amount,
              date,
              paymentMethodId,
            },
          ],
        },
      });
      await refetch();
    }
  );

  return {
    isLoading: isLoading || isMeLoading,
    updateAutoPay,
  };
}
