import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { Fragment, ReactElement, ReactNode } from "react";
import { Repair } from "src/@types/RepairList/Repair";
import { ActionNeededChip } from "../ActionNeededChip";

type Props = {
  tip: ReactNode;
  repairs: Repair[];
  title: string;
  isRequired?: boolean;
  RepairCard: (repair: Repair) => ReactElement;
};

export function MobileView({ repairs, title, isRequired = true, tip, RepairCard }: Props) {
  return (
    <Box w="100%">
      <VStack borderRadius={20} bgColor="white" p={5} alignItems="flex-start" w="100%">
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Box color="gray.700" fontWeight="semibold" fontSize="20px" maxW="150px">
            {title}
          </Box>
          <Box>{isRequired && <ActionNeededChip />}</Box>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          {tip}
        </Flex>
        <VStack w="100%" alignItems="flex-start">
          {repairs.length === 0 && <Box>No repairs yet</Box>}
          {repairs.map((repair) => (
            <Fragment key={repair.id}>
              <RepairCard {...repair} />
              {repair.id !== repairs[repairs.length - 1].id && <Divider />}
            </Fragment>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
}
