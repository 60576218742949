import { Item } from "../../@types/RentReady/Item";
import { useAppendPositionToHashTag } from "./useAppendPositionToHashTag";
import { useItemId } from "./useItemId";
import { View, useView } from "./useView";

export function useOnInCompleteItemClick(item: Item) {
  const appendPositionToHashTag = useAppendPositionToHashTag("area_one");
  const { setItemId } = useItemId();
  const { setView } = useView();

  return () => {
    appendPositionToHashTag();
    setItemId(item.id);
    setView(View.ItemEditView);
  };
}
