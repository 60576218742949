import { Flex } from "@chakra-ui/react";
import { Form as SignupForm } from "@doorstead/components/screens/SignupScreen";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { processAuthError, useAuth } from "src/contexts/auth";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";
import { useApplicantId } from "../../hooks/applicants/useApplicantId";
import { useApplicantLoginWithPassword } from "../../hooks/applicants/useApplicantLoginWithPassword";
import { useApplicantPageView } from "../../hooks/applicants/useApplicantPageView";
import { useRedirectIfAuthenticated } from "../../hooks/useRedirectIfAuthenticated";
import { ApplicantAuthWhiteBox } from "./ApplicantAuthWhiteBox";

function getApplicantLoginUrl(applicantId: string, email: string): string {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("email", email);
  urlSearchParams.append("applicantId", applicantId);
  return `/application/${applicantId}/login?${urlSearchParams.toString()}`;
}

function ApplicantSignupPage() {
  const applicantId = useApplicantId();
  const applicantAnalytics = useApplicantPageView("TP_APPLICANT_SIGN_UP_VIEWED");
  useRedirectIfAuthenticated({ to: `/application/${applicantId}` });
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { signup } = useAuth();
  const applicantLoginWithPassword = useApplicantLoginWithPassword(applicantId);

  const passwordSignup = async () => {
    try {
      setLoading(true);
      applicantAnalytics?.track("TP_APPLICANT_SIGN_UP_SUBMITTED", { email });
      await signup({ email, password });
      applicantAnalytics?.track("TP_APPLICANT_SIGN_UP_SUCCESS", { email });
      await applicantLoginWithPassword({ email, password });
      // Redirect to login/callback
    } catch (err: any) {
      const authError = processAuthError(err);
      setEmailError(authError.email || "");
      setPasswordError(authError.password || "");
      setErrorMessage(authError.general || "");
      applicantAnalytics?.track("TP_APPLICANT_SIGN_UP_FAILED", { email });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ApplicantPortalProvider>
      <ApplicantAuthWhiteBox>
        <SignupForm
          emailDisabled
          value={{ email, password }}
          onChange={({ email, password }) => {
            setEmail(email);
            setPassword(password);
          }}
          isSubmitting={loading}
          onLogin={passwordSignup}
          emailErrorMessage={emailError}
          passwordErrorMessage={passwordError}
          errorMessage={errorMessage}
          footer={
            <Flex
              as={Link}
              w="100%"
              justifyContent="center"
              color="purple.500"
              to={getApplicantLoginUrl(applicantId, email)}
            >
              Already a user? Log in
            </Flex>
          }
        />
      </ApplicantAuthWhiteBox>
    </ApplicantPortalProvider>
  );
}

export default ApplicantSignupPage;
