import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeThroughPropertyDetectorCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    if (
      shouldInvalidateCache({
        cacheTime: cache.inspectionReport.updatedAtThroughoutPropertyDetector,
        serverTime: rrGraphQLResultServer.inspectionReport.updatedAtThroughoutPropertyDetector,
      })
    ) {
      cache.inspectionReport.detectorSmokePhotoUrlsJson =
        rrGraphQLResultServer.inspectionReport.detectorSmokePhotoUrlsJson;
      cache.inspectionReport.detectorSmokeIsAtLeastOneOutsideBedrooms =
        rrGraphQLResultServer.inspectionReport.detectorSmokeIsAtLeastOneOutsideBedrooms;
      cache.inspectionReport.detectorSmokeIsAtLeastOneEachFloor =
        rrGraphQLResultServer.inspectionReport.detectorSmokeIsAtLeastOneEachFloor;
      cache.inspectionReport.detectorCmPhotoUrlsJson = rrGraphQLResultServer.inspectionReport.detectorCmPhotoUrlsJson;
      cache.inspectionReport.detectorCmIsAtLeastOneCoOutsideBedrooms =
        rrGraphQLResultServer.inspectionReport.detectorCmIsAtLeastOneCoOutsideBedrooms;
      cache.inspectionReport.detectorCmIsAtLeastOneCoEachFloor =
        rrGraphQLResultServer.inspectionReport.detectorCmIsAtLeastOneCoEachFloor;
      cache.inspectionReport.updatedAtThroughoutPropertyDetector =
        rrGraphQLResultServer.inspectionReport.updatedAtThroughoutPropertyDetector;
    }
  });
}
