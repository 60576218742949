import { TextAreaField } from "../../fields/TextAreaField";

type Props = {
  isValid?: boolean;
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
};
export function AreaNotesField({ isValid = true, value, onChange, disabled }: Props) {
  return (
    <TextAreaField
      isValid={isValid}
      label=""
      value={value}
      placeholder="Notes..."
      onChange={onChange}
      disabled={disabled}
    />
  );
}
