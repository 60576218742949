import { useDisclosure } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import React from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useAutopayMessage } from "../../../hooks/useAutopayMessage";
import { CardText } from "../../CardText";
import { EverGreenCard } from "../EverGreenCard";
import { EverGreenModal } from "../EverGreenModal/EverGreenModal";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { UpsertAutoPayCard } from "./UpsertAutoPayCard";
import { EditValue } from "./typings";

export function ManageAutoPay() {
  const analytics = useGroupAnalytics();
  const autopayMessage = useAutopayMessage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = React.useState<EditValue | null>(null);
  const isMobile = useIsMobile();

  return (
    <>
      <EverGreenModal
        isOpen={isOpen}
        onClose={() => {
          analytics?.track("TP_AUTOPAY_DISMISSED");
          onClose();
        }}
        title="Edit autopay"
      >
        {value !== null && (
          <UpsertAutoPayCard
            isEdit={true}
            initValue={value}
            onDone={(action) => {
              onClose();
              autopayMessage(action);
            }}
          />
        )}
      </EverGreenModal>
      <EverGreenCard>
        <CardText>Manage autopay</CardText>
        {isMobile ? (
          <MobileView mt={5} onOpen={onOpen} setValue={setValue} />
        ) : (
          <DesktopView onOpen={onOpen} setValue={setValue} />
        )}
      </EverGreenCard>
    </>
  );
}
