import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../contexts/auth";
import { AutoPayNode, MeResult, autopayFragment, queryKey as useFetchMeQueryKey } from "./useFetchMe";

type Result = {
  me: {
    tenant: {
      activeAutopay: AutoPayNode | null;
    };
  };
};

const queryKey = "useRefetchAutoPay";
const query = gql`
  query refetchAutoPay {
    me {
      tenant {
        ${autopayFragment}
      }
    }
  }
`;

export function useRefetchAutoPay() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: refetchAutoPay } = useMutation<Result>(
    queryKey,
    () => gqlClient.query({ query }).then((e) => e.data),
    {
      onSuccess(result) {
        queryClient.setQueryData(
          useFetchMeQueryKey,
          produce(queryClient.getQueryData(useFetchMeQueryKey), (draft: MeResult) => {
            draft.me.tenant.activeAutopay = result.me.tenant.activeAutopay;
          })
        );
      },
    }
  );

  return {
    refetchAutoPay,
    isLoading,
  };
}
