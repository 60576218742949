import { InspectionReportType } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReport } from "./useInspectionReport";

export function useShouldUseSimplifiedTemplate(): boolean {
  const { inspectionType } = useInspectionReport();
  return (
    [
      InspectionReportType.MOVE_IN_INSPECTION,
      InspectionReportType.TRANSFER_EVALUATION,
      InspectionReportType.ANNUAL_EVALUATION,
      InspectionReportType.PRE_MOVE_OUT_EVALUATION,
    ] as string[]
  ).includes(inspectionType);
}
