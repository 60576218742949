import { Box, Flex } from "@chakra-ui/react";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { ReactNode } from "react";
import { Row } from "./Row";

type Props = {
  hasData: boolean;
  isOpen: boolean;
  isExpandable: boolean;
  text: ReactNode;
  onClick: () => void;
};

function Arrow({ isOpen }: { isOpen: boolean }) {
  const config = {
    size: 2,
    color: "gray.600",
    direction: isOpen ? Direction.Top : Direction.Bottom,
  };
  return <ArrowIcon {...config} />;
}

function getColor({ isExpandable, hasData }: { isExpandable: boolean; hasData: boolean }): string {
  if (!isExpandable) {
    return "gray.400";
  } else if (hasData) {
    return "purple.500";
  } else {
    return "black.800";
  }
}

export function SubTitleRow({ isExpandable, isOpen, hasData, text, onClick }: Props) {
  const color = getColor({ hasData, isExpandable });
  return (
    <Row
      bgColor="gray.300"
      color={color}
      onClick={() => {
        if (isExpandable) {
          onClick();
        }
      }}
      left={
        hasData ? (
          <Flex alignItems="center">
            <Box borderRadius="50%" w={2} h={2} bgColor={color} mr={2} />
            {text}
          </Flex>
        ) : (
          text
        )
      }
      right={isExpandable && <Arrow isOpen={isOpen} />}
    />
  );
}
