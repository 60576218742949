import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { useIsEditable } from "../../../../../../hooks/rentReady/useIsEditable";
import { getPhotoSrc } from "../../../../../../utils/rentReady/getPhotoSrc";
import { PhotoThumbnail } from "../../../../PhotoThumbnail";
import { BackIcon } from "../../BackIcon";
import { Mode } from "../../Mode";
import { useGalleryIndex } from "../../useGalleryIndex";
import { useInternalState } from "../../useInternalState";
import { UploadNewButton } from "./UploadNewButton";

export function ThumbnailsMode() {
  const { onClose, photos, setMode, setPhotos } = useInternalState();
  const { setIndex } = useGalleryIndex();
  const editable = useIsEditable();
  return (
    <VStack p={5} alignItems="flex-start">
      <Flex w="100%" justifyContent="space-between">
        <BackIcon onClick={onClose} />
        {editable && setPhotos && (
          <HStack spacing={3}>
            <UploadNewButton />
            <OutlineButton
              size="sm"
              onClick={() => {
                setMode(Mode.SELECTING);
              }}
            >
              Select
            </OutlineButton>
          </HStack>
        )}
      </Flex>

      <Flex flex={1} flexWrap="wrap" gap={3}>
        {photos.length === 0 && <Box>No photos yet</Box>}
        {photos.map((photo, index) => (
          <Box key={photo.id} pos="relative">
            <PhotoThumbnail
              src={getPhotoSrc(photo, "thumbnail")}
              onClick={() => {
                setMode(Mode.GALLERY);
                setIndex(index);
              }}
            />
          </Box>
        ))}
      </Flex>
    </VStack>
  );
}
