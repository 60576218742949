import { ChangeEvent } from "react";

export function getFilesFromChangeEvent(e: ChangeEvent<HTMLInputElement>): File[] {
  const files: File[] = [];
  const targetFiles = e?.target?.files;
  if (targetFiles !== null) {
    for (let i = 0; i < targetFiles.length; i++) {
      const targetFile = targetFiles.item(i);
      if (targetFile !== null) {
        files.push(targetFile);
      }
    }
  }

  return files;
}
