import { useArea } from "./useArea";
import { useUpdateAreaCache } from "./useUpdateAreaCache";

export function useClearArea() {
  const area = useArea();
  const updateAreaCache = useUpdateAreaCache();

  return async () => {
    if (area === null) return;
    await updateAreaCache({
      ...area,
      cleanLight: false,
      cleanDeep: false,
      cleanOdors: false,
      cleanPersonalItemRemoval: false,
      cleaningNotes: "",
      itemRemovalNotes: "",
      painting: false,
      paintingNotes: "",
      photos: [],
      itemRemovalPhotos: [],
    });
  };
}
