import { Item } from "../../../../@types/RentReady/Item";
import { Komponent } from "../../../../@types/RentReady/Komponent";
import { sortByField } from "../../../../utils/rentReady/sortByField";
import { ComponentBar } from "./ComponentBar";

type Props = {
  item: Item;
  components: Komponent[];
};

export function ComponentRows({ item, components }: Props) {
  const sortedComponents = sortByField(components, "name");

  return (
    <>
      {sortedComponents.map((component) => (
        <ComponentBar key={component.id} item={item} component={component} />
      ))}
    </>
  );
}
