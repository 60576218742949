import { Item } from "../../../../../@types/RentReady/Item";
import { Komponent } from "../../../../../@types/RentReady/Komponent";
import { RepairOrReplace } from "../../../../../hooks/rentReady/useComponentId";
import { ComponentRepairReplaceCircle } from "./ComponentRepairReplaceCircle";

type Props = {
  item: Item;
  component: Komponent;
};

export function ComponentRepairCircle({ item, component }: Props) {
  const { replaceType, repairType } = component;

  return (
    <ComponentRepairReplaceCircle
      item={item}
      component={component}
      type={repairType}
      otherType={replaceType}
      repairOrReplace={RepairOrReplace.Repair}
    />
  );
}
