import { Box } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { Item } from "../../../../../@types/RentReady/Item";
import { Komponent, RepairOrReplaceType } from "../../../../../@types/RentReady/Komponent";
import { useAppendPositionToHashTag } from "../../../../../hooks/rentReady/useAppendPositionToHashTag";
import { RepairOrReplace } from "../../../../../hooks/rentReady/useComponentId";
import { useIsComponentPurpleCircle } from "../../../../../hooks/rentReady/useIsComponentPurpleCircle";
import { useOnCompleteComponentClick } from "../../../../../hooks/rentReady/useOnCompleteComponentClick";
import { useOnInCompleteComponentClick } from "../../../../../hooks/rentReady/useOnInCompleteComponentClick";

type Props = {
  item: Item;
  component: Komponent;
  type: RepairOrReplaceType;
  otherType: RepairOrReplaceType;
  repairOrReplace: RepairOrReplace;
};

export function ComponentRepairReplaceBox({ item, component, type, repairOrReplace, otherType }: Props) {
  const { id: itemId } = item;
  const { id: componentId } = component;
  const onCompleteComponentClick = useOnCompleteComponentClick({ itemId, componentId });
  const name = repairOrReplace === RepairOrReplace.Repair ? "Repair" : "Replace";
  const isComponentPurpleCircle = useIsComponentPurpleCircle(type);
  const onInCompleteComponentClick = useOnInCompleteComponentClick({ item, component });
  const appendPositionToHashTag = useAppendPositionToHashTag("area_one");

  if (isComponentPurpleCircle) {
    return (
      <Box
        onClick={() => {
          appendPositionToHashTag();
          onCompleteComponentClick(repairOrReplace);
        }}
      >
        <Chip label={name} variant={Variant.Red} />
      </Box>
    );
  } else {
    return (
      <Box
        onClick={() => {
          appendPositionToHashTag();
          onInCompleteComponentClick({ repairOrReplace, otherTypeSelected: otherType.selected });
        }}
      >
        <Chip label={name} variant={Variant.Gray} />
      </Box>
    );
  }
}
