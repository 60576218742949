import { Box, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  color: string;
  bgColor: string;
  left: ReactNode;
  right?: ReactNode;
  onClick?: () => void;
};

export function Row({ bgColor, color, left, right, onClick }: Props) {
  return (
    <Flex
      w="100%"
      pl={4}
      pr={4}
      h={8}
      fontWeight="bold"
      justifyContent="space-between"
      alignItems="center"
      bgColor={bgColor}
      color={color}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Box>{left}</Box>
      <Box>{right}</Box>
    </Flex>
  );
}
