import { Option } from "../../../../@types/RentReady/Komponent";
import { isSelectionDisabled } from "../../../../utils/rentReady/isSelectionDisabled";

type Args = {
  isLoading: boolean;
  hasPhoto: boolean;
  hasNote: boolean;
  options: Option[];
  types: Option[];
  hasDataUpdated: boolean;
};

export function isSaveDisabled({ isLoading, hasPhoto, options, types, hasNote, hasDataUpdated }: Args): boolean {
  //If data has updated and is loading, disable
  if (!hasDataUpdated || isLoading || !hasPhoto) {
    return true;
  }

  const isOptionsDisabled = isSelectionDisabled({ options, hasNote });
  const isTypesDisabled = isSelectionDisabled({ options: types, hasNote });
  return isOptionsDisabled || isTypesDisabled;
}
