import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchInspectionReport } from "./useFetchInspectionReport";
import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReportId } from "./useInspectionReportId";

export function useUpdateInspectionReport() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchInspectionReport();
  const inspectionReportId = useInspectionReportId();

  return useMutation(
    (inspectionReportNode: Partial<InspectionReportNode>) => {
      return gqlClient.mutate({
        mutation: gql`
          mutation updateInspectionReport($inspectionReport: [UpdateInspectionReportData]) {
            updateInspectionReport(inspectionReport: $inspectionReport) {
              count
            }
          }
        `,
        variables: {
          inspectionReport: [
            {
              ...inspectionReportNode,
              inspectionReportId,
            },
          ],
        },
      });
    },
    {
      async onSettled() {
        await refetch();
      },
    }
  );
}
