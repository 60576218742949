import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";
import { ApplicantDoorsteadUser } from "./useFetchApplicationMe/Applicant";
import { useFetchedApplicantUser } from "./useFetchedApplicantUser";

export function useUpdateDoorsteadUserField() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();
  const user = useFetchedApplicantUser();

  return useMutation(async (object: Partial<ApplicantDoorsteadUser>) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        draft.applicantById.doorsteadUser = {
          ...draft.applicantById.doorsteadUser,
          ...object,
        };
      })
    );

    return await gqlClient.mutate({
      mutation: gql`
        mutation ($doorsteadUser: DoorsteadUserUpdatePersonalData) {
          updateDoorsteadUser(doorsteadUser: $doorsteadUser) {
            count
          }
        }
      `,
      variables: {
        doorsteadUser: {
          ...object,
          id: user?.doorsteadUserId,
        },
      },
    });
  });
}
