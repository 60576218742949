import { HStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "./useDrawer";

export function useOpenItemWithPaintedAreaSelected() {
  const { onOpen, onClose } = useDrawer();

  return () => {
    onOpen({
      header: "Full area paint selected",
      body: "You have selected “full paint” in the Overview section for this area which is why this button is auto-selected and not clickable.",
      action: (
        <HStack w="100%" spacing={5}>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </HStack>
      ),
    });
  };
}
