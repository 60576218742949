import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { useNavigate } from "react-router-dom";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { Card } from "../../Card";

export function AutoPaySection() {
  const navigate = useNavigate();
  const analytics = useGroupAnalytics();

  return (
    <Card h="var(--section-height)" backgroundImage="/resources/bg.svg" pos="relative">
      <Image pos="absolute" right={5} bottom={5} src="/resources/autopay.svg" />
      <Flex h="100%" direction="column" justifyContent="space-between">
        <VStack w="65%" spacing={2} alignItems="flex-start">
          <Box color="gray.700" fontSize={18} fontWeight={700}>
            Save time with autopay
          </Box>
          <Box>Life can get busy. Save a little time by setting up automatic payments.</Box>
        </VStack>
        <Box>
          <OutlineButton
            size="md"
            onClick={() => {
              analytics?.track("TP_HP_SETUP_AUTOPAY_CLICKED");
              navigate("/autopay");
            }}
          >
            Set up autopay
          </OutlineButton>
        </Box>
      </Flex>
    </Card>
  );
}
