import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../../contexts/auth";
import { useFetchedApplicationRequest } from "../../../hooks/applicants/useFetchedApplicationRequest";

export type NewMemberInfo = {
  type: "TENANT" | "GUARANTOR";
  firstName: string;
  lastName: string;
  email: string;
};

export function useInviteCoTenantsAndGuarantorsV2() {
  const applicationRequest = useFetchedApplicationRequest();
  const { gqlClient } = useAuth();

  return useMutation(async (newMembersInfo: NewMemberInfo[]) => {
    return await gqlClient.mutate<{
      inviteCoTenantsAndGuarantors: {
        newApplicantIds: string[];
        failureEmails: string[];
      };
    }>({
      mutation: gql`
        mutation ($applicationRequestId: UUID!, $newMembersInfo: [NewMemberInviteInput]!) {
          inviteCoTenantsAndGuarantors(applicationRequestId: $applicationRequestId, newMembersInfo: $newMembersInfo) {
            newApplicantIds
            failureEmails
          }
        }
      `,
      variables: {
        applicationRequestId: applicationRequest.applicationRequestId,
        newMembersInfo,
      },
    });
  });
}
