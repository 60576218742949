type Props = {
  min: number;
  max: number;
  value: number;
};

export function getErrorMessage({ min, max, value }: Props): string {
  if (value < min) {
    return `The value must be at least ${min}`;
  } else if (value > max) {
    return `The value cannot exceed ${max}`;
  }
  return "";
}
