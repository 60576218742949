import { EmptyLayout } from "../components/EmptyLayout/EmptyLayout";
import { getDoorsteadEnv } from "../config";
import { usePageView } from "../hooks/usePageView";

const productionUrl = "https://retool.doorstead.com/embedded/public/3c0a776c-9ae0-4548-94f5-8ade135c85c5";
const intUrl = "https://int-retool.doorstead.com/embedded/public/98639aff-d074-4295-ba88-ee483a6a059b";

export function getSrc(): string {
  const url = getDoorsteadEnv() === "prod" ? productionUrl : intUrl;
  const urlHashParams = new URLSearchParams(window.location.hash.substring(1));
  const hashParams = new URLSearchParams();

  const names: string[] = ["firstName", "lastName", "email"];
  names.forEach((name) => {
    if (urlHashParams.has(name)) {
      hashParams.append(name, urlHashParams.get(name) || "");
    }
  });
  return url + (hashParams.toString() ? "#" + hashParams.toString() : "");
}

function HelpPage() {
  usePageView("TREE_IMPRESSION");

  return (
    <EmptyLayout logoLink="/help">
      <iframe style={{ width: "100%", maxWidth: "700px", height: "900px" }} src={getSrc()} />
    </EmptyLayout>
  );
}

export default HelpPage;
