import { Box } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";

export function PaidOffBox({ balanceDue }: { balanceDue: number }) {
  return (
    <Box>
      <b>No payment needed at this time.</b> Balance due is {moneyString(balanceDue)}
    </Box>
  );
}
