import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { mapPhotoUrlsJsonToPhotos } from "../../utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { mapStringToScale } from "../../utils/rentReady/mapStringToScale";
import { useInspectionReportCache } from "./useInspectionReportCache";

export function useKeyStorageCache(): KeyStorage | null {
  const inspectionReport = useInspectionReportCache();
  if (inspectionReport === null) return null;

  return {
    fullTenants: {
      number: mapStringToScale(inspectionReport.fullTenantSets),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReport.fullTenantSetsPhotoUrlsJson),
    },
    additionalKeyStorage: {
      photos: mapPhotoUrlsJsonToPhotos(inspectionReport.additionalKeyStoragePhotoUrlsJson),
      note: inspectionReport.additionalKeyStorageNotes || "",
    },
    lockbox: {
      code: inspectionReport.lockboxCode || "",
      photos: mapPhotoUrlsJsonToPhotos(inspectionReport.lockboxCodePhotoUrlsJson),
    },
    lockboxLocation: {
      location: inspectionReport.lockboxLocation || "",
      photos: mapPhotoUrlsJsonToPhotos(inspectionReport.lockboxLocationPhotoUrlsJson),
    },
  };
}
