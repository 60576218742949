import { Step } from "src/contexts/StepContext";

export function useTimelineStepMapper() {
  const steps = {
    [Step.WELCOME]: 0,
    [Step.PAYMENT_METHODS]: 1,
    [Step.PAY_HOLDING_DEPOSIT]: 2,
    [Step.PENDING_WALKTHROUGH]: 2,
    [Step.MAKE_A_PAYMENT]: 2,
    [Step.AUTO_PAY]: 3,
    [Step.DONE]: 3,
  };

  return {
    stepToTimelineIndex: (step: Step) => steps[step],
    timelineIndexToStep: (index: number) => {
      switch (index) {
        case 0:
          return Step.WELCOME;
        case 1:
          return Step.PAYMENT_METHODS;
        case 2:
          return Step.PAY_HOLDING_DEPOSIT;
        case 3:
        default:
          return Step.AUTO_PAY;
      }
    },
  };
}
