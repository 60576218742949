import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useDoorsteadManagedRepairs } from "../../../hooks/rr-project/useDoorsteadManagedRepairs";
import { DesktopView } from "../DesktopView";
import { MobileView } from "../MobileView";
import { DoorsteadManagedCard } from "./DoorsteadManagedCard";
import { DoorsteadManagedRepairsGuidelines } from "./DoorsteadManagedRepairsGuidelines";
import { DoorsteadManagedRow } from "./DoorsteadManagedRow";

export const templateColumns = "1fr 200px 120px 45px";
const title = "Doorstead managed repairs";

export function DoorsteadManagedRepairs() {
  const repairs = useDoorsteadManagedRepairs();
  const isMobile = useIsMobile();

  if (repairs.length === 0) return null;

  return isMobile ? (
    <MobileView
      tip={<DoorsteadManagedRepairsGuidelines />}
      repairs={repairs}
      isRequired={false}
      title={title}
      RepairCard={DoorsteadManagedCard}
    />
  ) : (
    <DesktopView
      isActionNeeded={false}
      tip={<DoorsteadManagedRepairsGuidelines />}
      repairs={repairs}
      title={title}
      columns={["Item / Location", "Photos", "Status", "Notes"]}
      templateColumns={templateColumns}
      TableRow={DoorsteadManagedRow}
    />
  );
}
