import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { FlexWalkthroughReportNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";
import { useUpdatePartialWalkthroughReportToCache } from "./useUpdatePartialWalkthroughReportToCache";
import { ArgsReport, useUpdatePartialWalkthroughReportToServer } from "./useUpdatePartialWalkthroughReportToServer";

export function useSyncSingleWalkthroughReportFieldToCacheAndServer() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();
  const updatePartialWalkthroughReportToServer = useUpdatePartialWalkthroughReportToServer();
  const updatePartialWalkthroughReportToCache = useUpdatePartialWalkthroughReportToCache();
  const { refetch } = useFetchFlexWalkthroughReport();

  return async (
    cacheField: keyof FlexWalkthroughReportNode,
    serverField: keyof ArgsReport,
    cacheValue: any,
    serverValue: any
  ) => {
    await updatePartialWalkthroughReportToCache({
      field: cacheField,
      value: cacheValue,
    });
    await updatePartialWalkthroughReportToServer({
      data: [
        {
          flexWalkthroughReportId: flexWalkthroughReportId,
          [serverField]: serverValue,
        },
      ],
    });
    await refetch();
  };
}
