import { Divider } from "@chakra-ui/react";
import { Fragment } from "react";
import { Item } from "../../../../@types/RentReady/Item";
import { Komponent } from "../../../../@types/RentReady/Komponent";
import { sortByField } from "../../../../utils/rentReady/sortByField";
import { ComponentBarV2 } from "./ComponentBarV2";

type Props = {
  item: Item;
  components: Komponent[];
};

export function ComponentRowsV2({ item, components }: Props) {
  const sortedComponents = sortByField(components, "name");

  return (
    <>
      {sortedComponents.map((component, index) => (
        <Fragment key={component.id}>
          <ComponentBarV2 item={item} component={component} />
          {index !== sortedComponents.length - 1 && <Divider />}
        </Fragment>
      ))}
    </>
  );
}
