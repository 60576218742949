import { Box, HStack } from "@chakra-ui/react";
import { SelectField } from "@doorstead/components";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { Operation } from "fast-json-patch";
import produce from "immer";
import { useEffect, useState } from "react";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { FullScreen } from "../../FullScreen";
import { Row } from "../../Row";
import { Record } from "./Record";
import { RecordList } from "./RecordList";
import { Option } from "./options";
import { useDiff } from "./useDiff";

type Props = {
  backView: View;
  name: string;
  options: Option[];
  initialRecords: Record[];
  onSaveRecord: ({ diff, records }: { diff: Operation[]; records: Record[] }) => void;
  isLoading: boolean;
  hasDeleted?: boolean;
};

export function RecordListEditView({
  backView,
  name,
  initialRecords,
  onSaveRecord,
  options,
  isLoading,
  hasDeleted = true,
}: Props) {
  const isOnline = useIsOnline();
  const { setView } = useView();
  const [records, setRecords] = useState<Record[]>(initialRecords);
  const [option, setOption] = useState<Option | null>(null);
  const diff = useDiff(initialRecords, records);
  useEffect(() => {
    setRecords(initialRecords);
  }, [JSON.stringify(initialRecords)]);

  return (
    <FullScreen
      headerName={`Edit ${name} List`}
      backView={backView}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(backView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isOnline && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isLoading || diff.length === 0}
            onClick={() => onSaveRecord({ diff, records })}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <Row color="white" bgColor="#515D6E" left={`Add ${name}`} />
      <HStack w="100%" alignItems="center" justifyContent="center" p={4} spacing={4}>
        <Box flex={1}>
          <SelectField
            id="area"
            placeholder="Select"
            label=""
            options={options}
            value={option === null ? "" : option.value}
            onChange={({ target: { value: id } }) => {
              if (id === "") {
                setOption(null);
              } else {
                setOption({
                  value: id,
                  name: options.find((e) => e.value === id)?.name || "",
                });
              }
            }}
          />
        </Box>
        <Box>
          <PrimaryButton
            disabled={option === null}
            onClick={() => {
              if (option === null) return;
              setRecords(
                produce((draft) => {
                  draft.push({
                    id: option.value,
                    name: option.name,
                  });
                })
              );
            }}
          >
            Add
          </PrimaryButton>
        </Box>
      </HStack>
      <Row color="white" bgColor="#515D6E" left={`Delete ${name}`} />
      <RecordList
        values={records}
        hasDeleted={hasDeleted}
        onDelete={(id) => {
          setRecords(
            produce((draft) => {
              const index = draft.findIndex((e) => e.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            })
          );
        }}
      />
    </FullScreen>
  );
}
