import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { useFetchedApplicationRequest } from "./useFetchedApplicationRequest";

export enum ApplicantType {
  TENANT = "TENANT",
  GUARANTOR = "GUARANTOR",
}

export type NewApplicant = {
  type: ApplicantType;
  email: string;
  firstName: string;
  lastName: string;
};

export function useInviteCoTenantsAndGuarantors() {
  const applicationRequest = useFetchedApplicationRequest();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async (newApplicants: NewApplicant[]) => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation ($applicationRequestId: UUID!, $newMembersInfo: [NewMemberInviteInput]!) {
            inviteCoTenantsAndGuarantors(applicationRequestId: $applicationRequestId, newMembersInfo: $newMembersInfo) {
              newApplicantIds
            }
          }
        `,
        variables: {
          applicationRequestId: applicationRequest.applicationRequestId,
          newMembersInfo: newApplicants,
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
