import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useSuccess } from "../useSuccess";
import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { useFetchedApplicant } from "./useFetchedApplicant";

export function useSignReleaseForm() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();
  const success = useSuccess();
  const { applicantId } = useFetchedApplicant();

  return useMutation(
    async (signature: string) => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantId: UUID, $signature: String) {
            signReleaseForm(applicantId: $applicantId, signature: $signature) {
              result
            }
          }
        `,
        variables: {
          applicantId,
          signature,
        },
      });
    },
    {
      async onSuccess() {
        success({ title: "Success!" });
        await refetch();
      },
    }
  );
}
