import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type GraphQLResult = {
  res: {
    url: string;
  }[];
};

export function useGenerateAllS3UrlForRentReady() {
  const { gqlClient } = useAuth();

  return async (keys: string[]): Promise<string[]> => {
    const res = await gqlClient.mutate({
      mutation: gql`
        mutation ($inputList: [GenerateAllS3UrlForRentReadyInput]) {
          generateAllS3UrlForRentReady(inputList: $inputList) {
            res {
              url
            }
          }
        }
      `,
      variables: {
        inputList: keys.map((key) => ({ key })),
      },
    });

    return (res.data.generateAllS3UrlForRentReady as GraphQLResult).res.map((e) => e.url);
  };
}
