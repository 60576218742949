import { VStack } from "@chakra-ui/react";
import { Status, Type } from "@doorstead/components/fields/TextField";
import { NewApplicant } from "src/hooks/applicants/useInviteCoTenantsAndGuarantors";
import { getTextRequiredStatus } from "src/utils/getTextRequiredStatus";
import { isValidEmail } from "src/utils/isValidEmail";
import { replaceAllowedChars } from "../../ApplicantOneSteps/ApplicantInCompleteCard/YourInformationSection/NameAndContactInformationCard/replaceAllowedChars";
import { ApplicantTextField } from "../../ApplicantTextField";

export type Props = {
  value: NewApplicant;
  onChange: (applicant: Partial<NewApplicant>) => void;
};

export function Content({ value: { firstName, lastName, email }, onChange }: Props) {
  return (
    <VStack width="100%">
      <ApplicantTextField
        id="first_name"
        label="First Name"
        defaultValue={firstName}
        transform={replaceAllowedChars}
        getStatus={getTextRequiredStatus}
        onSyncToServer={(firstName) =>
          onChange({
            firstName,
          })
        }
        debounce={0}
      />
      <ApplicantTextField
        id="last_name"
        label="Last Name"
        defaultValue={lastName}
        getStatus={getTextRequiredStatus}
        transform={replaceAllowedChars}
        onSyncToServer={(lastName) =>
          onChange({
            lastName,
          })
        }
        debounce={0}
      />
      <ApplicantTextField
        id="email"
        type={Type.Email}
        label="Email"
        defaultValue={email}
        getStatus={(email) => {
          return !isValidEmail(email) ? Status.Error : getTextRequiredStatus(email);
        }}
        onSyncToServer={(email) =>
          onChange({
            email,
          })
        }
        transform={(email) => email.toLowerCase()}
        debounce={0}
      />
    </VStack>
  );
}
