import { Item } from "../../@types/RentReady/Item";
import { ItemNode } from "./useFetchInspectionReport/ItemNode";
import { useUpdatePartialItemToCache } from "./useUpdatePartialItemToCache";
import { ArgsItem, useUpdatePartialItemToServer } from "./useUpdatePartialItemToServer";

export function useSyncItemTextToCacheAndServer() {
  const updatePartialItemToServer = useUpdatePartialItemToServer();
  const updatePartialItemToCache = useUpdatePartialItemToCache();

  return async (
    item: Item,
    cacheField: keyof ItemNode,
    serverField: keyof ArgsItem,
    cacheValue: any,
    serverValue: any
  ) => {
    await updatePartialItemToCache({
      id: item.id,
      field: cacheField,
      value: cacheValue,
    });
    await updatePartialItemToServer({
      items: [
        {
          itemDataId: item.id,
          [serverField]: serverValue,
        },
      ],
    });
  };
}
