import { Box, VStack } from "@chakra-ui/react";

type Props = {
  name: string;
  onClick: () => void;
};
export function GrayCircle({ name, onClick }: Props) {
  return (
    <VStack spacing={0.5} cursor="pointer">
      <Box borderRadius="50%" w="35px" h="35px" bgColor="gray.300" onClick={onClick} />
      <Box color="gray.500">{name}</Box>
    </VStack>
  );
}
