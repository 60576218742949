import { isDateStrEarlier } from "./isDateStrEarlier";

export function shouldInvalidateCache({
  cacheTime,
  serverTime,
}: {
  cacheTime: string | null;
  serverTime: string | null;
}): boolean {
  // local db is not empty, compare the server time and local time
  if (!cacheTime && serverTime !== null) {
    return true;
  } else if (cacheTime !== null && serverTime !== null && isDateStrEarlier(cacheTime, serverTime)) {
    return true;
  }
  return false;
}
