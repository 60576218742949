import { CameraIcon as Icon } from "@doorstead/components";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { Input } from "./Input";

export type Props = {
  id: string;
  prefix: Prefix;
  onSelectFiles: (files: File[]) => void;
  isValid?: boolean;
};

export function CameraIcon({ prefix, id, onSelectFiles, isValid = true }: Props) {
  return (
    <Input prefix={prefix} id={id} onSelectFiles={onSelectFiles} isValid={isValid}>
      <Icon />
    </Input>
  );
}
