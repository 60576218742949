import { useFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFlexWalkthroughReport";
import { useSubmitFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useSubmitFlexWalkthroughReport";
import { isWalkthroughItemComplete } from "src/utils/rentReady/isWalkthroughItemComplete";
import { mapPhotoUrlsJsonToPhotos } from "src/utils/rentReady/mapPhotoUrlsJsonToPhotos";

export function useIsFlexWalkthroughSubmitDisabled() {
  const { isLoading } = useSubmitFlexWalkthroughReport();
  const {
    items,
    propertyIsPhotoReady,
    propertyOverviewPhotoUrlsJson,
    propertyOverviewNotes,
    smokeDetectorPhotoUrlsJson,
    smokeDetectorIsAtLeastOneInsideEachBedroom,
    smokeDetectorIsAtLeastOneOutsideOfBedrooms,
    smokeDetectorIsAtLeastOneEachFloor,
    coDetectorPhotoUrlsJson,
    coDetectorIsAtLeastOneOutsideOfBedrooms,
    coDetectorIsAtLeastOneEachFloor,
    smokeDetectorIsAtLeastOneInsideEachBedroomNotes,
    smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes,
    smokeDetectorIsAtLeastOneEachFloorNotes,
    coDetectorIsAtLeastOneOutsideOfBedroomsNotes,
    coDetectorIsAtLeastOneEachFloorNotes,
  } = useFlexWalkthroughReport();

  const propertyOverviewPhotos = mapPhotoUrlsJsonToPhotos(propertyOverviewPhotoUrlsJson) || [];

  if (!propertyIsPhotoReady) {
    return (
      propertyOverviewPhotos.length == 0 ||
      propertyOverviewNotes == null ||
      propertyOverviewNotes.trim() === "" ||
      isLoading
    );
  }

  const smokeDetectorPhotos = mapPhotoUrlsJsonToPhotos(smokeDetectorPhotoUrlsJson) || [];
  const coPhotos = mapPhotoUrlsJsonToPhotos(coDetectorPhotoUrlsJson) || [];

  return (
    !items ||
    !items.every((item) => isWalkthroughItemComplete(item)) ||
    smokeDetectorPhotos.length == 0 ||
    coPhotos.length == 0 ||
    smokeDetectorIsAtLeastOneInsideEachBedroom == null ||
    smokeDetectorIsAtLeastOneOutsideOfBedrooms == null ||
    smokeDetectorIsAtLeastOneEachFloor == null ||
    coDetectorIsAtLeastOneOutsideOfBedrooms == null ||
    coDetectorIsAtLeastOneEachFloor == null ||
    (smokeDetectorIsAtLeastOneInsideEachBedroom == false &&
      (smokeDetectorIsAtLeastOneInsideEachBedroomNotes == null ||
        smokeDetectorIsAtLeastOneInsideEachBedroomNotes?.trimEnd() === "")) ||
    (smokeDetectorIsAtLeastOneOutsideOfBedrooms == false &&
      (smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes == null ||
        smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes?.trimEnd() === "")) ||
    (smokeDetectorIsAtLeastOneEachFloor == false &&
      (smokeDetectorIsAtLeastOneEachFloorNotes == null || smokeDetectorIsAtLeastOneEachFloorNotes?.trimEnd() === "")) ||
    (coDetectorIsAtLeastOneOutsideOfBedrooms == false &&
      (coDetectorIsAtLeastOneOutsideOfBedroomsNotes == null ||
        coDetectorIsAtLeastOneOutsideOfBedroomsNotes?.trimEnd() === "")) ||
    (coDetectorIsAtLeastOneEachFloor == false &&
      (coDetectorIsAtLeastOneEachFloorNotes == null || coDetectorIsAtLeastOneEachFloorNotes?.trimEnd() === "")) ||
    isLoading
  );
}
