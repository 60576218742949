import { Divider, Flex, VStack } from "@chakra-ui/react";
import { PrimaryButton, moneyString, useIsMobile } from "@doorstead/components";
import { useEffect, useState } from "react";
import { SubtotalSection } from "src/components/SubtotalSection";
import { TotalChargeSection } from "src/components/TotalChargeSection";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { usePageView } from "src/hooks/usePageView";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { useDefaultPaymentMethodId } from "../../../hooks/useDefaultPaymentMethodId";
import { useIsRentPaid } from "../../../hooks/useIsRentPaid";
import { useOpenPaymentFailureModal } from "../../../hooks/useOpenPaymentFailureModal";
import { useOpenPaymentSuccessModal } from "../../../hooks/useOpenPaymentSuccessModal";
import { usePayRent } from "../../../hooks/usePayRent";
import { useRentDue } from "../../../hooks/useRentDue";
import { getCents } from "../../../utils/getCents";
import { MonthlyChargesHintBox } from "../../MonthlyChargesHintBox";
import { BaseCard } from "../../Onboarding/steps/MakePaymentStep/BaseCard";
import { PaidOffBox } from "../../PaidOffBox";
import { PayNowDisclaimerText } from "../../PayNowDisclaimerText";
import { PaymentAmountField } from "../../PaymentAmountField";
import { PaymentMethodField } from "../../PaymentMethodField";

export function EverGreenMakePayment() {
  const isMobile = useIsMobile();
  const { payRent, loading } = usePayRent();
  const rentDue = useRentDue();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(defaultPaymentMethodId);
  const selectedPaymentMethod = useSelectedPaymentMethod(paymentMethodId);
  const isRentPaid = useIsRentPaid();
  const openPaymentSuccessModal = useOpenPaymentSuccessModal();
  const openPaymentFailureModal = useOpenPaymentFailureModal();
  const analytics = usePageView("TP_MANUAL_PAYMENT_VIEWED");
  const [amount, setAmount] = useState(rentDue);
  const isAmountInRange = 0.5 <= amount && amount <= rentDue;
  const { chargeAmount, cardTransactionFee, isFetching } = useChargeBreakdown(amount, selectedPaymentMethod?.type_);
  const balanceDue = useRentDue();

  useEffect(() => {
    setAmount(rentDue);
  }, [rentDue]);

  return (
    <BaseCard title="Make a payment">
      {isRentPaid ? (
        <PaidOffBox balanceDue={balanceDue} />
      ) : (
        <>
          <VStack w="100%" spacing={5}>
            <MonthlyChargesHintBox />
            <Divider />
            <SubtotalSection subTotalDue={rentDue} paid={0} />
            {!isRentPaid && (
              <>
                <PaymentMethodField
                  id="payment-method"
                  label="Payment method"
                  value={paymentMethodId || ""}
                  onChange={setPaymentMethodId}
                />
                <PaymentAmountField min={0.5} max={rentDue} value={amount} onChange={setAmount} />
              </>
            )}
            <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} />
            <Flex w="100%" justifyContent="space-between">
              <PrimaryButton
                disabled={
                  isRentPaid ||
                  paymentMethodId === null ||
                  paymentMethodId === "" ||
                  loading ||
                  !isAmountInRange ||
                  isFetching
                }
                isLoading={loading || isFetching}
                isFullWidth={isMobile}
                onClick={async () => {
                  if (
                    paymentMethodId !== null &&
                    window.confirm(`Are you sure to pay ${moneyString(chargeAmount)} for the rent?`)
                  ) {
                    analytics?.track("TP_MANUAL_PAY_CLICKED", {
                      paymentMethodId,
                    });
                    await payRent(
                      {
                        amountInCents: getCents(chargeAmount),
                        paymentMethodId,
                      },
                      {
                        onSuccess() {
                          analytics?.track("TP_MANUAL_PAY_SUCCESS", {
                            paymentMethodId,
                          });
                          openPaymentSuccessModal({
                            amount: chargeAmount,
                            paymentMethod: selectedPaymentMethod,
                            doneText: "Done",
                            suggestAutoPay: true,
                          });
                        },
                        onError(e: any) {
                          console.error(e);
                          analytics?.track("TP_MANUAL_PAY_FAILED", {
                            paymentMethodId,
                          });
                          const errorMessage =
                            e.graphQLErrors && e.graphQLErrors.length !== 0 ? e.graphQLErrors[0].message : null;
                          openPaymentFailureModal({
                            amount: chargeAmount,
                            paymentMethod: selectedPaymentMethod,
                            errorMessage,
                          });
                        },
                      }
                    );
                  }
                }}
              >
                Pay now
              </PrimaryButton>
            </Flex>
            <PayNowDisclaimerText />
          </VStack>
        </>
      )}
    </BaseCard>
  );
}
