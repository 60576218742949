export enum Status {
  Successful = "Successful",
  Pending = "Pending",
  Failed = "Failed",
}

export type Row = {
  id: string;
  transactionDate: string | null;
  details: string;
  chargeInCents: number | null;
  paymentInCents: number | null;
  status: Status | null;
  balanceInCents: number | null;
};

export type Props = {
  fontSize: "xl" | "4xl";
  rows: Row[];
  onViewClick?: () => void;
};
