import { HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "./useDrawer";

type Args = {
  onConfirm: () => void;
};

export function useOpenClearDefectDrawer() {
  const { onOpen: onDrawerOpen, onClose } = useDrawer();

  return ({ onConfirm }: Args) => {
    onDrawerOpen({
      header: "Clear defect?",
      body: "Please confirm you would like to clear this defect which will erase all of the defect data.",
      action: (
        <HStack w="100%" spacing={5}>
          <OutlineButton isFullWidth onClick={onClose}>
            Cancel
          </OutlineButton>
          <PrimaryButton
            isFullWidth
            onClick={async () => {
              await onConfirm();
              onClose();
            }}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      ),
    });
  };
}
