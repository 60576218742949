import { ThroughoutPropertyCleaning } from "../../../../@types/RentReady/ThroughoutPropertyCleaning";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { isComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyCleaningComplete";
import { useThroughoutPropertyCleaningCache } from "../../../../hooks/rentReady/useThroughoutPropertyCleaningCache";

type Args = {
  value: ThroughoutPropertyCleaning;
};

export function useIsThroughoutPropertyCleaningDisabled({ value }: Args): boolean {
  const throughoutPropertyCleaningCache = useThroughoutPropertyCleaningCache();
  const isReadOnly = useIsReadOnly();
  const isEqual = JSON.stringify(throughoutPropertyCleaningCache) === JSON.stringify(value);

  if (isEqual || isReadOnly) return true;

  return !isComplete(value);
}
