import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { isShowItemRequiredPhotosAndOptionalNotesAndHideComponents } from "./isShowItemRequiredPhotosAndOptionalNotesAndHideComponents";

type Args = {
  isCore: Item["isCore"];
  isMissingNeedsEstimate: Item["isMissingNeedsEstimate"];
  status: Item["status"];
  photos: Item["photos"];
  shouldUseSimplifiedTemplate: boolean;
};

export function isV2ItemDefaultOpen({
  isCore,
  isMissingNeedsEstimate,
  status,
  photos,
  shouldUseSimplifiedTemplate,
}: Args): boolean {
  switch (status) {
    case GoodMissingFixStatus.Fix:
      // We always expand the item if it is a fix
      return true;
    case GoodMissingFixStatus.Missing: {
      if (
        isShowItemRequiredPhotosAndOptionalNotesAndHideComponents({
          isCore,
          isMissingNeedsEstimate,
          status,
          shouldUseSimplifiedTemplate,
        })
      ) {
        return photos.length == 0;
      } else {
        return true;
      }
    }
    case null:
    default:
      return false;
  }
}
