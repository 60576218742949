import { Item } from "../../@types/RentReady/Item";
import { OtherOption } from "../../components/rent-ready/otherOption";

export function isItemCompleteCosmeticOptions(item: Item): boolean {
  if (item.cosmeticOptions.length === 0) return true;

  const selectedOption = item.cosmeticOptions.find((e) => e.selected);
  if (!selectedOption) return false;

  if (selectedOption.id === OtherOption.id) {
    return item.note.trim().length > 0;
  }

  return true;
}
