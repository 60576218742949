import { apiServerUrl } from "../../../config";
import { query } from "./query";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function fetchRRGraphQLResultServer(inspectionReportId: string): Promise<RRGraphQLResult> {
  return fetch(`${apiServerUrl()}/gql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { inspectionReportId },
    }),
  })
    .then((e) => e.json())
    .then((e) => e.data);
}
