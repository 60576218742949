import { Box, HStack } from "@chakra-ui/react";
import { SelectField } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useState } from "react";
import { Item } from "../../../../@types/RentReady/Item";
import { useItemOptions } from "../../../../hooks/rentReady/useItemOptions";
import { Option } from "../RecordListEditView/options";

type Props = {
  items: Item[];
  onAdd: (option: Option) => void;
};

function useDropdownOptions(items: Item[]): Option[] {
  const selectingIds: Set<string> = new Set(items.map((e) => e.id));
  return useItemOptions()
    .map(({ id: value, name, canHaveMultiple }) => ({
      value,
      name,
      canHaveMultiple,
    }))
    .filter(({ value, canHaveMultiple }) => {
      const isSelecting = selectingIds.has(value);
      return !(isSelecting && !canHaveMultiple);
    });
}

export function OptionDropdownList({ items, onAdd }: Props) {
  const [option, setOption] = useState<Option | null>(null);
  const options = useDropdownOptions(items);

  return (
    <HStack w="100%" alignItems="center" justifyContent="center" p={4} spacing={4}>
      <Box flex={1}>
        <SelectField
          id="item-options"
          placeholder="Select"
          label=""
          options={options}
          value={option === null ? "" : option.value}
          onChange={({ target: { value: id } }) => {
            if (id === "") {
              setOption(null);
            } else {
              setOption({
                value: id,
                name: options.find((e) => e.value === id)?.name || "",
              });
            }
          }}
        />
      </Box>
      <Box>
        <PrimaryButton disabled={option === null} onClick={() => onAdd(option!)}>
          Add
        </PrimaryButton>
      </Box>
    </HStack>
  );
}
