import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { isPhotosDiff } from "./isPhotosDiff";

export function isKeyStorageDiff(keyStorageCache: KeyStorage, keyStorageServer: KeyStorage): boolean {
  return (
    keyStorageCache.additionalKeyStorage.note !== keyStorageServer.additionalKeyStorage.note ||
    isPhotosDiff(keyStorageCache.additionalKeyStorage.photos, keyStorageServer.additionalKeyStorage.photos) ||
    keyStorageCache.lockbox.code !== keyStorageServer.lockbox.code ||
    isPhotosDiff(keyStorageCache.lockbox.photos, keyStorageServer.lockbox.photos) ||
    keyStorageCache.lockboxLocation.location !== keyStorageServer.lockboxLocation.location ||
    isPhotosDiff(keyStorageCache.lockboxLocation.photos, keyStorageServer.lockboxLocation.photos)
  );
}
