import { getPaymentMethodLabel } from "../utils/getPaymentMethodLabel";
import { PaymentMethodEdgeNode } from "./useFetchMe";
import { useFetchedMe } from "./useFetchedMe";

export function usePaymentMethods(): PaymentMethodEdgeNode[] {
  const me = useFetchedMe();

  if (me.paymentMethods === null) {
    return [];
  }
  return me.paymentMethods.edges.map((e) => e.node);
}

export function usePaymentOptions() {
  const values = usePaymentMethods();

  return {
    values: values
      // Filter out payment methods that are not yet verified
      .filter((pm) => pm.microDepositStatus === null)
      .map((e) => ({
        value: e.paymentMethodId,
        name: getPaymentMethodLabel({
          type: e.type_,
          bankAccountName: e.bankAccountName,
          bankAccountMask: e.bankAccountMask,
          cardBrand: e.cardBrand,
          cardLastFourDigits: e.cardLastFourDigits,
        }),
      })),
  };
}
