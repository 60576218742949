import { Repair } from "../../@types/RepairList/Repair";
import { OwnerDecision, RepairNode } from "../../hooks/rr-project/useFetchRRProject";
import { getRepairTitle } from "./getRepairTitle";
import { photoUrlsJsonToUrls } from "./photoUrlsJsonToUrls";

function compareRepairsOwnerDecision(repairA: RepairNode, repairB: RepairNode) {
  const orderList = [OwnerDecision.Yes, OwnerDecision.No, OwnerDecision.DIY];
  return (
    orderList.indexOf(repairA.ownerDecision || OwnerDecision.Yes) -
    orderList.indexOf(repairB.ownerDecision || OwnerDecision.Yes)
  );
}
function compareAcceptedRepairs(repairA: RepairNode, repairB: RepairNode) {
  const orderList = ["PendingEstimateApproval", "PendingEstimate", "ApprovedEstimate", "RejectedEstimate"];
  const acceptedRepairEstimateStateA = getAcceptedRepairEstimateState(repairA);
  const acceptedRepairEstimateStateB = getAcceptedRepairEstimateState(repairB);

  return orderList.indexOf(acceptedRepairEstimateStateA) - orderList.indexOf(acceptedRepairEstimateStateB);
}

function getAcceptedRepairEstimateState(repair: RepairNode) {
  if (repair.chosenVendorRepairId === null) {
    return "PendingEstimate";
  }
  if (repair?.chosenVendorRepair?.estimateApproved === null) {
    return "PendingEstimateApproval";
  }
  return repair?.chosenVendorRepair?.estimateApproved ? "ApprovedEstimate" : "RejectedEstimate";
}

function compareRepairs(repairA: RepairNode, repairB: RepairNode) {
  if (compareRepairsOwnerDecision(repairA, repairB)) {
    return compareRepairsOwnerDecision(repairA, repairB);
  }

  const isGroupedOrder = String(!!repairA.isGrouped).localeCompare(String(!!repairB.isGrouped));

  const sectionOrder = (repairA.section || "").localeCompare(repairB.section || "");

  const itemOrder = (repairA.item || "").localeCompare(repairB.item || "");

  const individualRepairOrder = isGroupedOrder ? isGroupedOrder : sectionOrder ? sectionOrder : itemOrder;

  if (repairA.ownerDecision === OwnerDecision.Yes) {
    if (compareAcceptedRepairs(repairA, repairB)) {
      return compareAcceptedRepairs(repairA, repairB);
    }
    return individualRepairOrder;
  }

  return individualRepairOrder;
}

export function mapRepairNodesToRepairs(repairNodes: RepairNode[]): Repair[] {
  return repairNodes
    .sort(compareRepairs)
    .map(
      ({
        repairId,
        repairRequirement,
        description,
        section,
        item,
        estimateNotes,
        ownerDecision,
        ownerNotes,
        photoUrlsJson,
        chosenVendorRepair,
        chosenVendorRepairId,
        stage,
      }) => {
        return {
          id: repairId,
          uiTitle: getRepairTitle({ section, item }),
          repairRequirement,
          description: description || "",
          estimateNotes: estimateNotes || "",
          ownerDecision: ownerDecision,
          ownerNotes: ownerNotes || "",
          chosenVendorRepair:
            chosenVendorRepair === null
              ? null
              : {
                  id: chosenVendorRepairId!,
                  estimateApproved: chosenVendorRepair.estimateApproved,
                  cannotEstimate: chosenVendorRepair.cannotEstimate,
                  lowEstimateCostCents: chosenVendorRepair.lowEstimateCostCents,
                  highEstimateCostCents: chosenVendorRepair.highEstimateCostCents,
                },
          photos: photoUrlsJsonToUrls(photoUrlsJson),
          stage,
        };
      }
    );
}
