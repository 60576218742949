import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = BoxProps & {
  children: ReactNode;
  isOpen: boolean;
};

export function DescriptionBox({ children, isOpen, ...boxProps }: Props) {
  let props: BoxProps = {
    ...boxProps,
    minW: "100%",
    pr: 5,
    fontSize: "14px",
    color: "gray.700",
    transition: "all 0.1s ease",
  };

  if (!isOpen) {
    props = {
      ...props,
      maxWidth: "300px",
      maxHeight: "80px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };
  }

  return <Box {...props}>{children}</Box>;
}
