import * as Sentry from "@sentry/react";
import React from "react";
import { useRouteError } from "react-router-dom";
import { EmptyLayout } from "../components/EmptyLayout/EmptyLayout";
import { Error } from "../components/Error";
import { unexpectedError } from "../components/Error/ErrorCode";

function ErrorPage() {
  const error: any = useRouteError();

  React.useEffect(() => {
    if (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }, [error.statusText]);

  return (
    <EmptyLayout logoLink="/">
      <Error {...unexpectedError} />
    </EmptyLayout>
  );
}

export default ErrorPage;
