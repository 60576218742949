import { Item } from "../../@types/RentReady/Item";
import { Komponent } from "../../@types/RentReady/Komponent";
import { useClearComponent } from "./useClearComponent";
import { RepairOrReplace, useComponentId } from "./useComponentId";
import { useItemId } from "./useItemId";
import { useOpenChangeDefectTypeDrawer } from "./useOpenChangeDefectTypeDrawer";
import { View, useView } from "./useView";

type Args = {
  item: Item;
  component: Komponent;
};

function useNavigateComponentDetail(item: Item, component: Komponent) {
  const { setItemId } = useItemId();
  const { setValue } = useComponentId();
  const { setView } = useView();

  return (repairOrReplace: RepairOrReplace) => {
    setItemId(item.id);
    setValue({
      componentId: component.id,
      repairOrReplace,
    });
    setView(View.ComponentEditView);
  };
}

export function useOnInCompleteComponentClick({ item, component }: Args) {
  const { mutate: clearComponent } = useClearComponent();
  const navigateComponentDetail = useNavigateComponentDetail(item, component);
  const openChangeDefectTypeDrawer = useOpenChangeDefectTypeDrawer();

  return ({ repairOrReplace, otherTypeSelected }: { repairOrReplace: RepairOrReplace; otherTypeSelected: boolean }) => {
    if (otherTypeSelected) {
      // If has data, ask to change
      // 1. clear component data
      // 2. jump to component detail
      //Otherwise, jump to component detail directly
      openChangeDefectTypeDrawer({
        async onConfirm() {
          await clearComponent({ componentId: component.id });
          navigateComponentDetail(repairOrReplace);
        },
      });
    } else {
      //This function is invoked for incomplete circle, we can directly navigate to the next page
      navigateComponentDetail(repairOrReplace);
    }
  };
}
