import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { useFetchMe } from "./useFetchMe";

const MUTATION = gql`
  mutation AgreeAutopayAchDisclaimer($achDisclaimerAt: String!) {
    agreeAutopayAchDisclaimer(achDisclaimerAt: $achDisclaimerAt) {
      achDisclaimerAt
    }
  }
`;

export function useAgreeAutopayDisclaimer() {
  const { refetch } = useFetchMe();
  const { gqlClient } = useAuth();

  return useMutation(
    ({ achDisclaimerAt }: { achDisclaimerAt: Date }) =>
      gqlClient.mutate({
        mutation: MUTATION,
        variables: { achDisclaimerAt: achDisclaimerAt.toISOString() },
      }),
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
