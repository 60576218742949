import { Flex, StackProps } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import React from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useCreateACHPaymentMethodFromPlaid } from "../../hooks/useCreateACHPaymentMethodFromPlaid";
import { useCreatePlaidLinkToken } from "../../hooks/useCreatePlaidLinkToken";
import { useFetchedMe } from "../../hooks/useFetchedMe";
import { useOpenPlaidTrustWindow } from "../../hooks/useOpenPlaidTrustWindow";
import { useRefetchPaymentMethods } from "../../hooks/useRefetchPaymentMethods";

export type Props = StackProps & {
  onDone: () => void;
};

export function SetupACHBox({ onDone, ...stackProps }: Props) {
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = React.useState(false);
  const { refetchPaymentMethods } = useRefetchPaymentMethods();
  const me = useFetchedMe();
  const { createPlaidToken } = useCreatePlaidLinkToken();
  const openPlaidTrustWindow = useOpenPlaidTrustWindow();
  const createACHPaymentMethodFromPlaid = useCreateACHPaymentMethodFromPlaid();
  const analytics = useGroupAnalytics();

  return (
    <Flex flexDirection="column" gap={6} alignItems="flex-start" {...stackProps}>
      <PrimaryButton
        disabled={isLoading}
        isLoading={isLoading}
        onClick={async () => {
          try {
            setIsLoading(true);
            analytics?.track("TP_ADD_PAYMENT_METHOD_STARTED");
            const plaidLinkToken = await createPlaidToken(me.doorsteadUserId);
            const { metadata, publicToken } = await openPlaidTrustWindow(plaidLinkToken.linkToken, () => {
              setIsLoading(false);
            });
            await createACHPaymentMethodFromPlaid({ publicToken, accountId: metadata.accounts[0].id });
            await refetchPaymentMethods();
            setIsLoading(false);
            onDone();
          } catch (e) {
            analytics?.track("TP_ADD_PAYMENT_METHOD_FAILED");
            console.error(e);
          } finally {
            setIsLoading(false);
          }
        }}
        isFullWidth={isMobile}
      >
        Continue
      </PrimaryButton>
    </Flex>
  );
}
