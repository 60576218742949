import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useError } from "../useError";
import { useSuccess } from "../useSuccess";
import { useApplicantAnalytics } from "./useApplicantAnalytics";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

const mutation = gql`
  mutation ($paymentMethodId: UUID!, $applicantId: UUID!, $amountInCents: Int!) {
    payApplicantFee(paymentMethodId: $paymentMethodId, applicantId: $applicantId, amountInCents: $amountInCents) {
      ledgerTransactionId
    }
  }
`;

export function usePayApplicantFee() {
  const applicantAnalytics = useApplicantAnalytics();
  const { refetch } = useFetchApplicationMe();
  const success = useSuccess();
  const error = useError();
  const { gqlClient } = useAuth();
  const applicantId = useApplicantId();

  return useMutation(
    ({ paymentMethodId, amountInCents }: { paymentMethodId: string; amountInCents: number }) =>
      gqlClient.mutate({
        mutation,
        variables: {
          paymentMethodId,
          applicantId,
          amountInCents,
        },
      }),
    {
      async onSuccess() {
        await refetch();
        success({ title: "Pay successful" });
        applicantAnalytics?.track("TP_PAY_APPLICANT_FEE_SUCCESS");
      },
      async onError(e) {
        error({ title: "Pay unsuccessful", description: "" });
        applicantAnalytics?.track("TP_PAY_APPLICANT_FEE_FAILED", { error: e });
      },
    }
  );
}
