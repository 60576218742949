import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Area } from "../../../../@types/RentReady/Area";
import { useAppendPositionToHashTag } from "../../../../hooks/rentReady/useAppendPositionToHashTag";
import { useAreaId } from "../../../../hooks/rentReady/useAreaId";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { isAreaComplete } from "../../../../utils/rentReady/isAreaComplete";
import { AreaRow } from "./AreaRow";

export function AreaRows({ areas }: { areas: Area[] }) {
  const { setAreaId } = useAreaId();
  const { setView } = useView();
  const isV2 = useIsV2UiFeatures();
  const appendPositionToHashTag = useAppendPositionToHashTag("area_list");
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  return (
    <>
      {areas.map((area) => {
        const { id, uiName, isThroughoutProperty } = area;
        const isComplete = isAreaComplete(area, isV2, shouldUseSimplifiedTemplate);

        return (
          <AreaRow
            id={id}
            key={id}
            text={uiName}
            isComplete={isComplete}
            onClick={() => {
              setAreaId(id);
              if (isV2 || isComplete || isThroughoutProperty) {
                appendPositionToHashTag();
                setView(View.AreaOneView);
              } else {
                setView(View.AreaOverviewView);
              }
            }}
          />
        );
      })}
    </>
  );
}
