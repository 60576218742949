import produce from "immer";
import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { getScaleString } from "../../@types/RentReady/Scale";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { mapPhotosToPhotoUrlsJson } from "./mapPhotosToPhotoUrlsJson";

export function mapKeyDetailFromRRGraphQLResultCache(
  result: RRGraphQLResultCache,
  keyDetail: KeyDetail
): RRGraphQLResultCache {
  return produce(result, (draft) => {
    draft.inspectionReport.frontDoorKeys = getScaleString(keyDetail.frontDoorKeys.number);
    draft.inspectionReport.frontDoorKeysWorking = keyDetail.frontDoorKeys.isWorking;
    draft.inspectionReport.frontDoorKeysPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyDetail.frontDoorKeys.photos);
    draft.inspectionReport.frontDoorKeysNotes = keyDetail.frontDoorKeys.note;

    draft.inspectionReport.mailboxKeys = getScaleString(keyDetail.mailboxKeys.number);
    draft.inspectionReport.mailboxKeysWorking = keyDetail.mailboxKeys.isWorking;
    draft.inspectionReport.mailboxKeysPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyDetail.mailboxKeys.photos);
    draft.inspectionReport.mailboxKeysNotes = keyDetail.mailboxKeys.note;

    draft.inspectionReport.communityKeys = getScaleString(keyDetail.communityKeys.number);
    draft.inspectionReport.communityKeysWorking = keyDetail.communityKeys.isWorking;
    draft.inspectionReport.communityKeysPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyDetail.communityKeys.photos);
    draft.inspectionReport.communityKeysNotes = keyDetail.communityKeys.note;

    draft.inspectionReport.garageRemotes = getScaleString(keyDetail.garageRemotes.number);
    draft.inspectionReport.garageRemotesWorking = keyDetail.garageRemotes.isWorking;
    draft.inspectionReport.garageRemotesPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyDetail.garageRemotes.photos);
    draft.inspectionReport.garageRemotesNotes = keyDetail.garageRemotes.note;

    draft.inspectionReport.keypads = getScaleString(keyDetail.keyPads.number);
    draft.inspectionReport.keypadsWorking = keyDetail.keyPads.isWorking;
    draft.inspectionReport.keypadsPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyDetail.keyPads.photos);
    draft.inspectionReport.keypadsNotes = keyDetail.keyPads.note;
  });
}
