import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { arrayToMap } from "../../../utils/arrayToMap";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { AreaNode } from "./AreaNode";
import { mergeItemsCache } from "./mergeItemsCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeAreasCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    const cacheAreas: AreaNode[] = cache.inspectionReport.data || [];
    const cacheAreaMap = arrayToMap(cacheAreas, "areaDataId");
    const serverAreaMap = arrayToMap(rrGraphQLResultServer.inspectionReport.data || [], "areaDataId");

    const toRemoveIds: Set<string> = new Set();
    cacheAreas.forEach((cacheArea) => {
      if (serverAreaMap.has(cacheArea.areaDataId)) {
        //if both cache and server area exists, we merge the new area if needed
        const serverArea = serverAreaMap.get(cacheArea.areaDataId)!;
        if (
          shouldInvalidateCache({
            cacheTime: cacheArea.areaDataUpdatedAt,
            serverTime: serverArea.areaDataUpdatedAt,
          })
        ) {
          //merge the area
          cacheArea.areaPhotoUrlsJson = getMergedPhotos(cacheArea.areaPhotoUrlsJson, serverArea.areaPhotoUrlsJson);
          cacheArea.areaNotes = serverArea.areaNotes;
          cacheArea.areaSelectedCleaningOptions = serverArea.areaSelectedCleaningOptions;
          cacheArea.areaSelectedItemRemoval = serverArea.areaSelectedItemRemoval;
          cacheArea.areaSelectedOdors = serverArea.areaSelectedOdors;
          cacheArea.areaCleaningNotes = serverArea.areaCleaningNotes;
          cacheArea.areaSelectedPaintingOptions = serverArea.areaSelectedPaintingOptions;
          cacheArea.areaPaintingNotes = serverArea.areaPaintingNotes;
          cacheArea.areaSmokeDetectorStatus = serverArea.areaSmokeDetectorStatus;
          cacheArea.areaSmokeDetectorPhotoUrlsJson = getMergedPhotos(
            cacheArea.areaSmokeDetectorPhotoUrlsJson,
            serverArea.areaSmokeDetectorPhotoUrlsJson
          );
          cacheArea.areaSmokeDetectorNotes = serverArea.areaSmokeDetectorNotes;
          cacheArea.areaItemRemovalNotes = serverArea.areaItemRemovalNotes;
          cacheArea.areaDataUpdatedAt = serverArea.areaDataUpdatedAt;
          cacheArea.areaItemRemovalPhotoUrlsJson = getMergedPhotos(
            cacheArea.areaItemRemovalPhotoUrlsJson,
            serverArea.areaItemRemovalPhotoUrlsJson
          );
        }

        //if the area config is changed, we update the config cache
        if (
          shouldInvalidateCache({
            cacheTime: cacheArea.areaUpdatedAt,
            serverTime: serverArea.areaUpdatedAt,
          })
        ) {
          cacheArea.areaShowInThroughoutProperty = serverArea.areaShowInThroughoutProperty;
          cacheArea.areaName = serverArea.areaName;
          cacheArea.areaUpdatedAt = serverArea.areaUpdatedAt;
        }
        //Both server and cache areas exist and server items are updated before, we merge the items from server

        cacheArea.itemsData = mergeItemsCache(cacheArea, serverArea);
      } else {
        //if cache area does not exist in server, we can remove it
        toRemoveIds.add(cacheArea.areaDataId);
      }
    });

    let newAreaNodes: AreaNode[] = cacheAreas;
    if (
      shouldInvalidateCache({
        cacheTime: rrGraphQLResultCache.inspectionReport.updatedAtAreas,
        serverTime: rrGraphQLResultServer.inspectionReport.updatedAtAreas,
      })
    ) {
      newAreaNodes = newAreaNodes.filter((area) => !toRemoveIds.has(area.areaDataId));

      for (const serverAreaNode of Array.from(serverAreaMap.values())) {
        if (!cacheAreaMap.has(serverAreaNode.areaDataId)) {
          newAreaNodes.push(serverAreaNode);
        }
      }
    }

    cache.inspectionReport.data = newAreaNodes;
  });
}
