import { useEffect } from "react";
import { useHashTagParam } from "../useHashTagParam";

export function useAutoFocusHashPosition(key: string) {
  const position = useHashTagParam(key);
  useEffect(() => {
    if (position !== null) {
      const top = Number(position);
      if (!isNaN(top)) {
        window.scrollTo({
          top,
          behavior: "auto",
        });
      }
    }
  }, [position]);
}
