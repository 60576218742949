import { Photo } from "./Photo";

export enum Decision {
  CanNotEstimate = "CanNotEstimate",
  AddEstimates = "AddEstimates",
}

export type Estimate = {
  id: string;
  uiName: string;
  description: string;
  photos: Photo[];
  createDate: Date;
  isGrouped: boolean;
  throughoutPropertyType: string;
  dataType: string;
  decision: Decision | null;
  isRange: boolean;
  fromPrice: number;
  toPrice: number;
  note: string;
  isOutOfScope: boolean;
  isToBeDetermined: boolean;
};
