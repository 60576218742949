import { Box } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";
import { Cell } from "@doorstead/components/containers/Table";
import { formatDate } from "../../utils/formatDate";
import { getDollarByCents } from "../../utils/getDollarByCents";
import { StatusChip } from "./StatusChip";
import { Row } from "./typing";

export const cells: Cell<Row>[] = [
  ({ transactionDate }: Row) => {
    if (transactionDate == null) return null;
    return (
      <Box color="gray.500" fontSize="14px" fontWeight={600}>
        {formatDate(transactionDate)}
      </Box>
    );
  },
  ({ details }: Row) => (
    <Box w={170} wordBreak="break-word" overflow="hidden" whiteSpace="normal">
      {details}
    </Box>
  ),
  ({ status }: Row) => <StatusChip size="sm" value={status} />,
  ({ chargeInCents }: Row) => (chargeInCents === null ? null : moneyString(getDollarByCents(chargeInCents))),
  ({ paymentInCents }: Row) => (paymentInCents === null ? null : moneyString(getDollarByCents(paymentInCents))),
  ({ balanceInCents }: Row) => (balanceInCents === null ? "TBD" : moneyString(getDollarByCents(balanceInCents))),
];
