import { apiServerUrl } from "../../../config";
import { query } from "./query";
import { FlexWalkthroughGraphQLResult } from "./useFetchFlexWalkthroughReport";

export function fetchFlexWalkthroughGraphQLResultServer(
  flexWalkthroughReportId: string
): Promise<FlexWalkthroughGraphQLResult> {
  return fetch(`${apiServerUrl()}/gql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { flexWalkthroughReportId },
    }),
  })
    .then((e) => e.json())
    .then((e) => e.data);
}
