import { Area } from "../../@types/RentReady/Area";
import { isHeatSourcePhotosValid } from "./isHeatSourcePhotosValid";
import { isNoteValid } from "./isNoteValid";

type Args = {
  heatSourceNotes: Area["heatSourceNotes"];
  heatSourceStatus: Area["heatSourceStatus"];
  heatSourcePhotos: Area["heatSourcePhotos"];
};

export function isHeatSourceComplete({ heatSourceNotes, heatSourceStatus, heatSourcePhotos }: Args) {
  return (
    heatSourceStatus !== null &&
    isHeatSourcePhotosValid(heatSourceStatus, heatSourcePhotos) &&
    isNoteValid(heatSourceStatus, heatSourceNotes)
  );
}
