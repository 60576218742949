import { Box } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "../../../../../hooks/rentReady/useDrawer";

export function useOnLockboxCodeClick() {
  const { onOpen, onClose } = useDrawer();

  return () => {
    onOpen({
      header: "Lockbox Code",
      body: (
        <Box maxW="250px">
          Only one set of access keys should remain in the lockbox. Photograph and note lockbox code and location on
          property.
        </Box>
      ),
      action: <PrimaryButton onClick={onClose}>Close</PrimaryButton>,
    });
  };
}
