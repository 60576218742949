import { Box, Grid, GridProps, ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../../../../hooks/applicants/useAddress";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationRequest } from "../../../../../hooks/applicants/useFetchedApplicationRequest";
import { useGoogleMapKey } from "../../../../../hooks/useGoogleMapKey";
import { useLogConversionEvent } from "../../../../../hooks/useLogConversionEvent";
import { getSegmentedAddress } from "../../../../../hooks/useSegmentedAddress";
import { useTrackImpressionEvent } from "../../../../../hooks/useTrackImpressionEvent";
import { useInviteCoTenantsAndGuarantorsV2 } from "../../../AddCotenantsAndGuarantorsPageV2/useInviteCoTenantsAndGuarantorsV2";
import { useInternalState } from "../useInternalState";
import { ScaleField } from "./ScaleField";
import { YesNoField } from "./YesNoField";

export function GetStartedView() {
  const isMobile = useIsMobile();
  const address = useAddress();
  const applicant = useFetchedApplicant();
  const applicationRequest = useFetchedApplicationRequest();
  const googleMapKey = useGoogleMapKey();
  const { mutate: inviteCoTenantsAndGuarantors, isLoading } = useInviteCoTenantsAndGuarantorsV2();
  const navigate = useNavigate();
  const { howManyAdults, setHowManyAdults, haveGuarantor, setHaveGuarantor } = useInternalState();
  const email = applicant.doorsteadUser.email || "";
  const propertyId = applicant.applicationRequest.property?.propertyId || "";
  const surface = "APPLICANT_PORTAL";
  useTrackImpressionEvent({
    data: {
      applicantId: applicant.applicantId,
      howManyAdults,
      haveGuarantor,
    },
    email,
    propertyId,
    action: "TP_APPLICANT_INVITE_COTENANT_GET_STARTED_VIEWED",
    surface,
  });
  const logConversionEvent = useLogConversionEvent();
  const segmentedAddress = getSegmentedAddress({
    addressLine1: applicationRequest.property.addressLine1,
    addressLine2: applicationRequest.property.addressLine2,
    zip: applicationRequest.property.zip,
    state: applicationRequest.property.state,
    city: applicationRequest.property.city,
  });
  const width = 240;

  const gridProps: GridProps = {
    gridGap: 10,
  };
  if (isMobile) {
    gridProps.templateColumns = "1fr";
  } else {
    gridProps.templateColumns = "280px 1fr";
  }

  return (
    <Grid {...gridProps}>
      {!isMobile && (
        <VStack alignItems="flex-start" bgColor="white" p={5} borderRadius={5}>
          <Box justifyContent="flex-start" w="100%" alignItems="center">
            <GoogleMapImage
              borderRadius={6}
              width={isMobile ? 300 : width}
              height={isMobile ? 220 : 220}
              address={address}
              googleKey={googleMapKey}
            />
          </Box>
          <VStack alignItems="flex-start" w={width}>
            <Box color="gray.500">Application for</Box>
            <VStack alignItems="flex-start" color="gray.700" fontWeight="bold" spacing={0}>
              <Box>{segmentedAddress[0]}</Box>
              <Box>{segmentedAddress[1]}</Box>
            </VStack>
          </VStack>
        </VStack>
      )}
      <VStack alignItems="flex-start" borderRadius={5} h="100%" w="100%" spacing={7} p={1} pb={5}>
        <VStack alignItems="flex-start" spacing={2}>
          <Box color="gray.700" fontSize="3xl">
            {"Let's get started"}
          </Box>
          <Box fontSize="md" color="gray.500">
            Help us understand who is applying with you.
          </Box>
        </VStack>
        <VStack alignItems="flex-start" spacing={3} w="100%">
          <Box color="black" fontWeight="bold">
            How many total adults (18 and older), including you, will be living in the property?
          </Box>
          <ScaleField value={howManyAdults} onChange={setHowManyAdults} />
        </VStack>
        <VStack alignItems="flex-start" spacing={3}>
          <Box display="inline-block" pos="relative">
            <Box color="black" fontWeight="bold">
              Will you have a guarantor applying to help meet income requirements?
            </Box>
            <UnorderedList spacing={2}>
              <ListItem>Combined income must be 3x the rent amount.</ListItem>
              <ListItem>
                A guarantor is a co-signer who agrees to take on financial responsibility of the lease if tenants are
                unable to pay rent.
              </ListItem>
            </UnorderedList>
          </Box>
          <YesNoField value={haveGuarantor} onChange={setHaveGuarantor} />
        </VStack>
        <PrimaryButton
          isFullWidth={isMobile}
          isLoading={isLoading}
          disabled={howManyAdults === null || haveGuarantor === null}
          onClick={() => {
            logConversionEvent({
              email,
              propertyId,
              action: "APPLICANT_PORTAL_ADD_CO_TENANT_GET_STARTED_CLICK",
              data: {
                applicantId: applicant.applicantId,
                howManyAdults,
                haveGuarantor,
              },
              surface,
            });
            if (howManyAdults === 1 && !haveGuarantor) {
              inviteCoTenantsAndGuarantors([], {
                onSuccess() {
                  logConversionEvent({
                    email,
                    propertyId,
                    action: "APPLICANT_PORTAL_ADD_CO_TENANT_GET_STARTED_CLICK_SUCCESS",
                    data: {
                      applicantId: applicant.applicantId,
                      howManyAdults,
                      haveGuarantor,
                    },
                    surface,
                  });
                  window.location.reload();
                },
                onError() {
                  logConversionEvent({
                    email,
                    propertyId,
                    action: "APPLICANT_PORTAL_ADD_CO_TENANT_GET_STARTED_CLICK_FAILED",
                    data: {
                      applicantId: applicant.applicantId,
                      howManyAdults,
                      haveGuarantor,
                    },
                    surface,
                  });
                },
              });
            } else {
              navigate(
                `/application/${applicant?.applicantId}/invite?total_cotenants=${howManyAdults}&have_guarantor=${haveGuarantor}&from=get_started`
              );
            }
          }}
        >
          Continue
        </PrimaryButton>
      </VStack>
    </Grid>
  );
}
