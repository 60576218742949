import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
};

const context = createContext<Props>({
  isDirty: false,
  setIsDirty: console.log,
});

export function DirtyProvider({ children }: { children: ReactNode }) {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <context.Provider
      value={{
        isDirty,
        setIsDirty,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useDirty() {
  return useContext(context);
}
