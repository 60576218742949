import { DollarField } from "@doorstead/components/fields/DollarField";
import { getErrorMessage } from "./getErrorMessage";

export type Props = {
  min?: number;
  max?: number;
  disabled?: boolean;
  value: number;
  onChange?: (value: number) => void;
};

export function PaymentAmountField({
  disabled = false,
  value,
  onChange,
  min = 5,
  max = Number.MAX_SAFE_INTEGER,
}: Props) {
  const isValid = value >= min && value <= max;
  const errorMessage = getErrorMessage({ value, min, max });

  return (
    <DollarField
      id="payment_amount"
      label="Payment amount"
      disabled={disabled}
      value={value}
      isValid={isValid}
      errorMessage={errorMessage}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
      }}
    />
  );
}
