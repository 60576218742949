import { useFetchedLease } from "./useFetchedLease";

export function getSegmentedAddress({
  addressLine1,
  addressLine2,
  zip,
  state,
  city,
}: {
  addressLine1: string;
  addressLine2: string | null;
  zip: string;
  state: string;
  city: string;
}): string[] {
  return [`${addressLine1}${addressLine2 === null ? "" : ` ${addressLine2}`}`, `${city}, ${state} ${zip}`];
}

export function useSegmentedAddress(): string[] {
  const { addressLine1, addressLine2, zip, state, city } = useFetchedLease().property;
  return getSegmentedAddress({ addressLine1, addressLine2, zip, state, city });
}
