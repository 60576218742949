import { Step } from "./typings";

export const waSteps: Step[] = [
  Step.WELCOME,
  Step.PAYMENT_METHODS,
  Step.PAY_HOLDING_DEPOSIT,
  Step.PENDING_WALKTHROUGH,
  Step.MAKE_A_PAYMENT,
  Step.AUTO_PAY,
  Step.DONE,
];
