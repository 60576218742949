import { FlexWalkthroughItemNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useSyncSingleWalkthroughItemFieldToCacheAndServer } from "./useSyncSingleWalkthroughItemFieldToCacheAndServer";
import { ArgsItem } from "./useUpdatePartialWalkthroughItemToServer";

export function useSyncWalkthroughItemTextToCacheAndServer() {
  const syncSingleWalkthroughItemFieldToCacheAndServer = useSyncSingleWalkthroughItemFieldToCacheAndServer();

  return async (
    item: FlexWalkthroughItemNode,
    cacheField: keyof FlexWalkthroughItemNode,
    serverField: keyof ArgsItem,
    cacheValue: any,
    serverValue: any
  ) => {
    await syncSingleWalkthroughItemFieldToCacheAndServer(item, cacheField, serverField, cacheValue, serverValue);
  };
}
