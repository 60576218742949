import { Box, GridItemProps } from "@chakra-ui/react";

type Props = GridItemProps & {
  text: React.ReactNode;
  fontSize?: string;
};

export function Title({ text, fontSize = "4xl", ...gridItemProps }: Props) {
  return (
    <Box {...gridItemProps} w="100%" color="gray.700" fontSize={fontSize} fontWeight="bold">
      {text}
    </Box>
  );
}
