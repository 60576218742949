import { RRProjectNodeStatus } from "../../../hooks/rr-project/useFetchRRProject";
import { View } from "../../../hooks/rr-project/useView";

export function getInitialView(status: RRProjectNodeStatus | null): View {
  if (status === null) return View.StartView;

  switch (status) {
    case RRProjectNodeStatus.Guideline:
      return View.GuidelineView;
    case RRProjectNodeStatus.RepairList:
      return View.RepairListView;
    case RRProjectNodeStatus.Start:
    default:
      return View.StartView;
  }
}
