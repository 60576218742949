import { useApplicantOnboardingStage } from "./useApplicantOnboardingStage";
import { ApplicantOnboardingStage } from "./useFetchApplicationMe/Applicant";

export function getProgress(applicantOnboardingStage: ApplicantOnboardingStage): number {
  if (applicantOnboardingStage === null) return 0;

  switch (applicantOnboardingStage) {
    case ApplicantOnboardingStage.YOUR_INFORMATION_SECTION:
      return 0;
    case ApplicantOnboardingStage.DOCUMENTS_SECTION:
      return 20;
    case ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION:
      return 40;
    case ApplicantOnboardingStage.SUBMIT_AND_PAY_SECTION:
      return 80;
    default:
      return 0;
  }
}

export function useProgress(): number {
  const applicantOnboardingStage = useApplicantOnboardingStage();
  return getProgress(applicantOnboardingStage);
}
