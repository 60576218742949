import { useAreaOptions } from "../../../../hooks/rentReady/useAreaOptions";
import { useAreasCache } from "../../../../hooks/rentReady/useAreasCache";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsDebugMode } from "../../../../hooks/rentReady/useIsDebugMode";
import { View } from "../../../../hooks/rentReady/useView";
import { RecordListEditView } from "../RecordListEditView";
import { Option } from "../RecordListEditView/options";
import { useOnSaveAreaList } from "./useOnSaveAreaList";

export function AreaListEditView() {
  const isDebugMode = useIsDebugMode();
  const records = useAreasCache()
    .filter((e) => isDebugMode || !e.isThroughoutProperty)
    .map(({ id, name }) => ({ id, name }));
  const { mutate: onSaveAreaList, isLoading } = useOnSaveAreaList();
  const options: Option[] = useAreaOptions().map(({ areaOptionId: value, areaOptionName: name }) => ({
    value,
    name,
  }));
  useDisplayInstruction(View.AreaListEditView);

  return (
    <RecordListEditView
      name="Area"
      backView={View.AreaListView}
      options={options}
      initialRecords={records}
      onSaveRecord={onSaveAreaList}
      isLoading={isLoading}
    />
  );
}
