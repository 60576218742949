import { Box, Flex, Image } from "@chakra-ui/react";
import { useIsDefaultAutopayFeature } from "../../../../hooks/useIsDefaultAutopayFeature";
import { CardText } from "../../../CardText";

export function Banner() {
  const isDefaultAutopayFeature = useIsDefaultAutopayFeature();

  return (
    <Flex
      borderTopRadius={20}
      w="100%"
      flexWrap={{
        sm: "wrap-reverse",
        md: "nowrap",
      }}
      justifyContent="space-between"
      alignItems="center"
      backgroundImage="/resources/bg.svg"
      padding={10}
    >
      <Flex h="100%" maxW="448px" justifyContent="flex-start" flexDirection="column">
        <Box color="gray.400" fontWeight="bold" fontSize="sm">
          {isDefaultAutopayFeature ? "Less work. More peace of mind." : "NEVER PAY A LATE FEE"}
        </Box>
        <CardText>{isDefaultAutopayFeature ? "Autopay" : "Set up autopay"}</CardText>
        <Box mt={5} color="gray.700" fontWeight="normal" fontSize="md">
          {isDefaultAutopayFeature ? (
            "No one likes late fees. With autopay you can rest assured that your monthly payments are on time every time. We do not charge for this service."
          ) : (
            <>
              If your rent payment is late, you’ll be charged a late fee every day until it’s paid. You will be charged{" "}
              <b>$50</b> when you miss your due date and <b>$15</b> for every additional day.
            </>
          )}
        </Box>
      </Flex>
      <Flex minWidth={140} justifyContent="center" alignItems="center">
        <Image boxSize={140} src="/resources/autopay.svg" />
      </Flex>
    </Flex>
  );
}
