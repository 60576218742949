export function isValidEIN(ein: string): boolean {
  if (ein.length !== 9) {
    return false;
  }

  if (!/^\d{9}$/.test(ein)) {
    return false;
  }

  const prefix = ein.substring(0, 2);

  if (prefix === "00") {
    return false;
  }

  return true;
}
