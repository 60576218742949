import { Flex, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton, Table, moneyString } from "@doorstead/components";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Status } from "../../components/TransactionHistoryTable";
import { StatusChip } from "../../components/TransactionHistoryTable/StatusChip";
import { EverGreenModal } from "../../components/evergreens/EverGreenModal/EverGreenModal";
import { getPaymentMethodLabel } from "../../utils/getPaymentMethodLabel";
import { PaymentMethodEdgeNode } from "../useFetchMe";
import { useGroupAnalytics } from "../useGroupAnalytics";
import { useIsAutoPaySetup } from "../useIsAutoPaySetup";
import { AutoPayBox } from "./AutoPayBox";

type Row = {
  id: string;
  amount: number;
  paymentMethod: PaymentMethodEdgeNode;
};

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  paymentMethod: PaymentMethodEdgeNode | null;
  setPaymentMethod: (paymentMethod: PaymentMethodEdgeNode | null) => void;
  amount: number;
  setAmount: (amount: number) => void;
  suggestAutoPay: boolean;
  setSuggestAutoPay: (suggestAutoPay: boolean) => void;
  doneText: string;
  setDoneText: (doneText: string) => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
  paymentMethod: null,
  setPaymentMethod() {
    console.log("setPaymentMethod");
  },
  amount: 0,
  setAmount() {
    console.log("setAmount");
  },
  suggestAutoPay: false,
  setSuggestAutoPay() {
    console.log("setSuggestAutoPay");
  },
  doneText: "Done",
  setDoneText() {
    console.log("setDoneText");
  },
});

export function Provider({ children }: { children: React.ReactNode }) {
  const analytics = useGroupAnalytics();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEdgeNode | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [suggestAutoPay, setSuggestAutoPay] = useState(false);
  const [doneText, setDoneText] = useState<string>("");

  return (
    <context.Provider
      value={{
        isOpen,
        onClose() {
          analytics?.track("TP_HP_MANUAL_PAID_DISMISSED");
          onClose();
        },
        onOpen,
        paymentMethod,
        setPaymentMethod,
        amount,
        setAmount,
        suggestAutoPay,
        setSuggestAutoPay,
        doneText,
        setDoneText,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

export function useOpenPaymentSuccessModal() {
  const { onOpen, setAmount, setPaymentMethod, setDoneText, setSuggestAutoPay } = useContext(context);

  return ({
    amount,
    paymentMethod,
    doneText,
    suggestAutoPay,
  }: {
    amount: ContextProps["amount"];
    paymentMethod: ContextProps["paymentMethod"];
    doneText: ContextProps["doneText"];
    suggestAutoPay: ContextProps["suggestAutoPay"];
  }) => {
    setAmount(amount);
    setPaymentMethod(paymentMethod);
    setDoneText(doneText);
    setSuggestAutoPay(suggestAutoPay);
    onOpen();
  };
}

function Component() {
  const { isOpen, onClose, suggestAutoPay, doneText, paymentMethod, amount } = useContext(context);
  const isAutoPaySetup = useIsAutoPaySetup();
  const navigate = useNavigate();
  const showAutoPay = suggestAutoPay && !isAutoPaySetup;
  const analytics = useGroupAnalytics();

  return (
    <EverGreenModal isOpen={isOpen} onClose={onClose} title="Thank you for your payment">
      <Flex direction="column" h={showAutoPay ? "400px" : "250px"}>
        <Flex flex={1} w="100%" h="100%" alignItems="center" justifyContent="center">
          <VStack w="100%" spacing={10}>
            <Flex w="100%" h={100} bgColor="gray.50" justifyContent="center" alignItems="center" borderRadius={6}>
              <Table<Row>
                rows={paymentMethod === null ? [] : [{ id: paymentMethod.paymentMethodId, amount, paymentMethod }]}
                headers={[
                  {
                    label: "Amount paid",
                    isNumeric: true,
                  },
                  {
                    label: "Payment method",
                    isNumeric: false,
                  },
                  {
                    label: "Status",
                    isNumeric: false,
                  },
                ]}
                cells={[
                  ({ amount }) => moneyString(amount),
                  ({ paymentMethod }) =>
                    getPaymentMethodLabel({
                      type: paymentMethod.type_,
                      bankAccountName: paymentMethod.bankAccountName,
                      bankAccountMask: paymentMethod.bankAccountMask,
                      cardBrand: paymentMethod.cardBrand,
                      cardLastFourDigits: paymentMethod.cardLastFourDigits,
                    }),
                  () => <StatusChip value={Status.Pending} />,
                ]}
              />
            </Flex>
            {showAutoPay && <AutoPayBox />}
          </VStack>
        </Flex>
        <HStack spacing={5} justifyContent="center" alignItems="center" w="100%">
          {showAutoPay && (
            <PrimaryButton
              size="lg"
              onClick={() => {
                analytics?.track("TP_HP_MANUAL_PAID_SETUP_AUTOPAY_CLICKED");
                navigate("/autopay");
              }}
            >
              Set up autopay
            </PrimaryButton>
          )}
          <OutlineButton size="lg" onClick={onClose}>
            {doneText}
          </OutlineButton>
        </HStack>
      </Flex>
    </EverGreenModal>
  );
}
