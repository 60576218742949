import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import React from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { usePageView } from "src/hooks/usePageView";
import { usePaymentMethods } from "../../../hooks/usePaymentMethods";
import { CardText } from "../../CardText";
import { PaymentMethod } from "../../PaymentMethod";
import { EverGreenCard } from "../EverGreenCard";
import { AddPaymentMethodModal } from "./AddPaymentMethodModal";
import { NoDataBox } from "./NoDataBox";
import { context, useInternalState } from "./useInternalState";

function Content() {
  const { onOpen } = useInternalState();
  const paymentMethods = usePaymentMethods();
  const analytics = useGroupAnalytics();
  usePageView("TP_PAYMENT_METHOD_VIEWED");

  return (
    <>
      <AddPaymentMethodModal />
      <EverGreenCard>
        <CardText pb={8}>Payment methods</CardText>
        {paymentMethods.length === 0 ? (
          <NoDataBox
            onAdd={() => {
              analytics?.track("TP_ADD_PAYMENT_METHOD_CLICKED");
              onOpen();
            }}
          />
        ) : (
          <>
            <Flex w="100%" justifyContent="space-between" flexWrap="wrap" gap={3}>
              <Box fontWeight="bold" color="gray.700" fontSize="xl">
                Choose your payment method
              </Box>
              <Box>
                <OutlineButton
                  size="md"
                  onClick={() => {
                    analytics?.track("TP_ADD_PAYMENT_METHOD_CLICKED");
                    onOpen();
                  }}
                >
                  Add payment method
                </OutlineButton>
              </Box>
            </Flex>
            <VStack gap={5} pt={5}>
              {paymentMethods.map((pm) => {
                return <PaymentMethod {...pm} key={pm.paymentMethodId} />;
              })}
            </VStack>
          </>
        )}
      </EverGreenCard>
    </>
  );
}

export function EverGreenPaymentMethods() {
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider value={{ loading, setLoading, isOpen, onOpen, onClose }}>
      <Content />
    </context.Provider>
  );
}
