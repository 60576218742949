import { Box } from "@chakra-ui/react";
import { useInstructionGuidelinesModal } from "../../../hooks/rr-project/useInstructionGuidelinesModal";

export function Instructions() {
  const { onOpen } = useInstructionGuidelinesModal();

  return (
    <Box color="gray.700" as="button" textDecoration="underline" onClick={() => onOpen()}>
      Instructions
    </Box>
  );
}
