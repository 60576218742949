import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export function MainBox({ children, ...flexProps }: FlexProps & { children: ReactNode }) {
  return (
    <Flex {...flexProps} justifyContent="center">
      <Flex justifyContent="flex-start" w="100%" maxW="75rem">
        {children}
      </Flex>
    </Flex>
  );
}
