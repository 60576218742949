import { useToUpdateRepairOwnerDecisionsStore } from "../../components/rr-project/stores/useToUpdateRepairOwnerDecisionsStore";
import { OwnerDecision } from "./useFetchRRProject";
import { useToApproveRepairs } from "./useToApproveRepairs";

export function useGetToApproveCount() {
  const toApproveRepairs = useToApproveRepairs();
  const { hasOwnerDecision, getOwnerDecision } = useToUpdateRepairOwnerDecisionsStore();

  return (ownerDecision: OwnerDecision): number => {
    let count = 0;
    toApproveRepairs.forEach((repair) => {
      if (hasOwnerDecision(repair.id)) {
        if (getOwnerDecision(repair.id) === ownerDecision) {
          count++;
        }
      } else if (repair.ownerDecision === ownerDecision) {
        count++;
      }
    });
    return count;
  };
}
