import { useEffect, useRef } from "react";

export function useRenderOnce(cb: () => void) {
  const renderedRef = useRef(false);

  useEffect(() => {
    if (!renderedRef.current) {
      cb();
    }
    renderedRef.current = true;
  }, [cb]);

  return renderedRef.current;
}
