import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useEstimateToApproveRepairs } from "../../../hooks/rr-project/useEstimateToApproveRepairs";
import { DesktopView } from "../DesktopView";
import { MobileView } from "../MobileView";
import { EstimatesToApproveCard } from "./EstimatesToApproveCard";
import { EstimatesToApproveRepairsGuidelines } from "./EstimatesToApproveRepairsGuidelines";
import { EstimatesToApproveRow } from "./EstimatesToApproveRow";

export const templateColumns = "1fr 150px 150px 120px 50px";
const title = "Estimates to approve";

export function EstimatesToApproveRepairs() {
  const repairs = useEstimateToApproveRepairs();
  const isMobile = useIsMobile();

  if (repairs.length === 0) return null;

  return isMobile ? (
    <MobileView
      repairs={repairs}
      isRequired
      title={title}
      RepairCard={EstimatesToApproveCard}
      tip={<EstimatesToApproveRepairsGuidelines />}
    />
  ) : (
    <DesktopView
      repairs={repairs}
      title={title}
      columns={["Item / Location", "Photos", "Estimates", "Approval", "Notes"]}
      templateColumns={templateColumns}
      TableRow={EstimatesToApproveRow}
      tip={<EstimatesToApproveRepairsGuidelines />}
    />
  );
}
