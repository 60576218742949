import { useIsFlexWalkthroughReportNew } from "./useIsFlexWalkthroughReportNew";
import { View } from "./useView";

export function useInitialView(): View {
  const isFlexWalkthroughReportNew = useIsFlexWalkthroughReportNew();

  //new view
  if (isFlexWalkthroughReportNew) {
    return View.StartView;
  }

  return View.WalkthroughView;
}
