import { Area } from "../../@types/RentReady/Area";
import { db, readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapAreaStateFromRRGraphQLResultCache } from "./useMapAreaStateFromRRGraphQLResultCache";

export function useUpdateAreaCache() {
  const inspectionReportId = useInspectionReportId();
  const mapAreaStateFromRRGraphQLResultCache = useMapAreaStateFromRRGraphQLResultCache();

  return async (area: Area) => {
    return db.transaction("rw", db.rr_graphql_result, async () => {
      const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
      if (graphQLCacheResult === null) return null;

      const result = mapAreaStateFromRRGraphQLResultCache(graphQLCacheResult, area);
      await writeRRGraphQLResult(inspectionReportId, result);
      return result;
    });
  };
}
