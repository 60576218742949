import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Mode } from "./Mode";
import { GalleryMode } from "./modes/GalleryMode";
import { SelectingMode } from "./modes/SelectingMode";
import { ThumbnailsMode } from "./modes/ThumbnailsMode";
import { Provider as GalleryIndexProvider } from "./useGalleryIndex";
import { Props as InternalStateProps, Provider } from "./useInternalState";

type Props = InternalStateProps & {
  size?: string;
  hasBackButton?: boolean;
  hasCloseButton?: boolean;
  index?: number;
};

export function PhotoEditorDialog(props: Props) {
  const [mode, setMode] = useState<Mode>(Mode.THUMBNAILS);
  const {
    onClose,
    isOpen,
    hasBackButton = true,
    hasCloseButton = false,
    index: defaultIndex = 0,
    size = "full",
  } = props;
  const [index, setIndex] = useState(defaultIndex);
  useEffect(() => {
    setMode(props.mode);
    setIndex(defaultIndex);
  }, [props.mode]);

  return (
    <Modal onClose={onClose} size={size} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        {hasCloseButton && <ModalCloseButton />}
        <ModalBody p={0}>
          <Provider value={{ ...props, isOpen, onClose, mode, setMode }}>
            <Box zIndex={1}>
              <GalleryIndexProvider value={{ index, setIndex }}>
                {mode === Mode.THUMBNAILS && <ThumbnailsMode />}
                {mode === Mode.SELECTING && <SelectingMode />}
                {mode === Mode.GALLERY && <GalleryMode hasBackButton={hasBackButton} />}
              </GalleryIndexProvider>
            </Box>
          </Provider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
