import { Box, Flex, HStack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { Modal, PrimaryButton, Table, moneyString } from "@doorstead/components";
import { ErrorBookIcon } from "@doorstead/components/icons/ErrorBookIcon";
import React, { useContext, useState } from "react";
import { Status } from "../../components/TransactionHistoryTable";
import { StatusChip } from "../../components/TransactionHistoryTable/StatusChip";
import { getPaymentMethodLabel } from "../../utils/getPaymentMethodLabel";
import { PaymentMethodEdgeNode } from "../useFetchMe";

type Row = {
  id: string;
  amount: number;
  paymentMethod: PaymentMethodEdgeNode;
};

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  paymentMethod: PaymentMethodEdgeNode | null;
  setPaymentMethod: (paymentMethod: PaymentMethodEdgeNode | null) => void;
  amount: number;
  setAmount: (amount: number) => void;
  errorMessage: string | null;
  setErrorMessage: (errorMessage: string | null) => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
  paymentMethod: null,
  setPaymentMethod() {
    console.log("setPaymentMethod");
  },
  amount: 0,
  setAmount() {
    console.log("setAmount");
  },
  errorMessage: null,
  setErrorMessage() {
    console.log("setErrorMessage");
  },
});

export function Provider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEdgeNode | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
        paymentMethod,
        setPaymentMethod,
        amount,
        setAmount,
        errorMessage,
        setErrorMessage,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

export function useOpenPaymentFailureModal() {
  const { onOpen, setAmount, setPaymentMethod, setErrorMessage } = useContext(context);

  return ({
    amount,
    paymentMethod,
    errorMessage,
  }: {
    amount: ContextProps["amount"];
    paymentMethod: ContextProps["paymentMethod"];
    errorMessage: string;
  }) => {
    setAmount(amount);
    setPaymentMethod(paymentMethod);
    setErrorMessage(errorMessage);
    onOpen();
  };
}

function Component() {
  const { isOpen, onClose, paymentMethod, amount, errorMessage } = useContext(context);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box paddingX={4} paddingY={8}>
        <VStack w="100%" justifyContent="center" spacing={5}>
          <ErrorBookIcon />
          <Text fontSize="3xl" color="gray.700" fontWeight="bold">
            {errorMessage || "Something went wrong"}
          </Text>
        </VStack>
        <Flex direction="column" h="250px">
          <Flex flex={1} w="100%" h="100%" alignItems="center" justifyContent="center">
            <VStack w="100%" spacing={10}>
              <Flex w="100%" h={100} bgColor="gray.50" justifyContent="center" alignItems="center" borderRadius={6}>
                <Table<Row>
                  rows={paymentMethod === null ? [] : [{ id: paymentMethod.paymentMethodId, amount, paymentMethod }]}
                  headers={[
                    {
                      label: "Amount paid",
                      isNumeric: true,
                    },
                    {
                      label: "Payment method",
                      isNumeric: false,
                    },
                    {
                      label: "Status",
                      isNumeric: false,
                    },
                  ]}
                  cells={[
                    ({ amount }) => moneyString(amount),
                    ({ paymentMethod }) =>
                      getPaymentMethodLabel({
                        type: paymentMethod.type_,
                        bankAccountName: paymentMethod.bankAccountName,
                        bankAccountMask: paymentMethod.bankAccountMask,
                        cardBrand: paymentMethod.cardBrand,
                        cardLastFourDigits: paymentMethod.cardLastFourDigits,
                      }),
                    () => <StatusChip value={Status.Failed} />,
                  ]}
                />
              </Flex>
            </VStack>
          </Flex>
          <HStack spacing={5} justifyContent="center" alignItems="center" w="100%">
            <PrimaryButton onClick={onClose}>Ok</PrimaryButton>
          </HStack>
        </Flex>
      </Box>
    </Modal>
  );
}
