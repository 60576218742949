import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { InspectionReportNode } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { mapPhotoUrlsJsonToPhotos } from "./mapPhotoUrlsJsonToPhotos";
import { mapStringToScale } from "./mapStringToScale";

export function mapInspectionReportNodeToKeyStorage(inspectionReportNode: InspectionReportNode): KeyStorage {
  return {
    fullTenants: {
      number: mapStringToScale(inspectionReportNode.fullTenantSets),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.fullTenantSetsPhotoUrlsJson),
    },
    additionalKeyStorage: {
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.additionalKeyStoragePhotoUrlsJson),
      note: inspectionReportNode.additionalKeyStorageNotes || "",
    },
    lockbox: {
      code: inspectionReportNode.lockboxCode || "",
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.lockboxCodePhotoUrlsJson),
    },
    lockboxLocation: {
      location: inspectionReportNode.lockboxLocation || "",
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.lockboxLocationPhotoUrlsJson),
    },
  };
}
