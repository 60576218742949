import { Item } from "../../../../../@types/RentReady/Item";
import { useIsItemPurpleCircle } from "../../../../../hooks/rentReady/useIsItemPurpleCircle";
import { ItemCompleteBoxV2 } from "./ItemCompleteBoxV2";
import { ItemInCompleteBoxV2 } from "./ItemInCompleteBoxV2";

export function ItemBoxV2(item: Item) {
  const isItemComplete = useIsItemPurpleCircle(item);

  return isItemComplete ? <ItemCompleteBoxV2 item={item} /> : <ItemInCompleteBoxV2 item={item} />;
}
