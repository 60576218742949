import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeKeyStorageCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    if (
      shouldInvalidateCache({
        cacheTime: cache.inspectionReport.updatedAtKeyStorage,
        serverTime: rrGraphQLResultServer.inspectionReport.updatedAtKeyStorage,
      })
    ) {
      //full tenant sets
      cache.inspectionReport.fullTenantSets = rrGraphQLResultServer.inspectionReport.fullTenantSets;
      cache.inspectionReport.fullTenantSetsPhotoUrlsJson = getMergedPhotos(
        cache.inspectionReport.fullTenantSetsPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.fullTenantSetsPhotoUrlsJson
      );

      //lockbox
      cache.inspectionReport.lockboxCode = rrGraphQLResultServer.inspectionReport.lockboxCode;
      cache.inspectionReport.lockboxCodePhotoUrlsJson = getMergedPhotos(
        cache.inspectionReport.lockboxCodePhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.lockboxCodePhotoUrlsJson
      );
      cache.inspectionReport.lockboxLocation = rrGraphQLResultServer.inspectionReport.lockboxLocation;
      cache.inspectionReport.lockboxLocationPhotoUrlsJson = getMergedPhotos(
        cache.inspectionReport.lockboxLocationPhotoUrlsJson,
        rrGraphQLResultServer.inspectionReport.lockboxLocationPhotoUrlsJson
      );

      //additional storage notes
      cache.inspectionReport.additionalKeyStorageNotes =
        rrGraphQLResultServer.inspectionReport.additionalKeyStorageNotes;
      cache.inspectionReport.additionalKeyStoragePhotoUrlsJson =
        rrGraphQLResultServer.inspectionReport.additionalKeyStoragePhotoUrlsJson;

      cache.inspectionReport.updatedAtKeyStorage = rrGraphQLResultServer.inspectionReport.updatedAtKeyStorage;
    }
  });
}
