import { Box } from "@chakra-ui/react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import _ from "lodash";
import { useState } from "react";

type Props = {
  onDone: (paymentMethod: PaymentMethod) => void;
};

export function PaymentForm({ onDone }: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [result, setResult] = useState<any | null>(null);

  const createPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({ elements });
      console.log("createPaymentMethod", { paymentMethod, error });
      if (error) {
        throw error;
      }
      setResult(paymentMethod);

      if (paymentMethod?.card?.country !== "US") {
        throw { type: "invalid_request_error", message: "We only support US cards at this time." };
      }

      onDone(paymentMethod);
    } catch (error) {
      const stripeError = error as unknown as { type: string; message: string };
      setResult(stripeError);
      if (
        stripeError.type === "card_error" ||
        stripeError.type === "validation_error" ||
        stripeError.type === "invalid_request_error"
      ) {
        setErrorMessage(stripeError.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box w="100%">
      <PaymentElement
        onChange={(e) => {
          if (e.complete) {
            createPaymentMethod();
          }
        }}
      />
      {!isLoading && !_.isEmpty(result) && !_.isEmpty(errorMessage) && (
        <div style={{ color: "red" }}>{errorMessage}</div>
      )}
    </Box>
  );
}
