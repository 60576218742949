import { Photo } from "src/@types/RentReady/Photo";
import { PhotosField } from "src/components/rent-ready/fields/PhotosField";
import { FlexWalkthroughReportNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useSyncWalkthroughReportPhotosToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncWalkthroughReportPhotosToCacheAndServer";
import { useUpdatePartialWalkthroughReportToCache } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughReportToCache";
import { ArgsReport } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughReportToServer";
import { Prefix } from "src/hooks/rentReady/useUpload";
import { useIsOnline } from "src/hooks/useIsOnline";
import { mapPhotosToPhotoUrlsJson } from "src/utils/rentReady/mapPhotosToPhotoUrlsJson";

type Props = {
  isValid?: boolean;
  prefix: Prefix;
  values: Photo[];
  cacheField: keyof FlexWalkthroughReportNode;
  serverField: keyof ArgsReport;
};
export function ReportAutoSyncPhotosField({ isValid = true, prefix, cacheField, serverField, values }: Props) {
  const syncWalkthroughReportPhotosToCacheAndServer = useSyncWalkthroughReportPhotosToCacheAndServer();
  const updatePartialWalkthroughReportToCache = useUpdatePartialWalkthroughReportToCache();
  const isOnline = useIsOnline();

  return (
    <PhotosField
      label=""
      prefix={prefix}
      values={values}
      isValid={isValid}
      onChange={async (photos) => {
        if (isOnline) {
          await syncWalkthroughReportPhotosToCacheAndServer(cacheField, serverField, prefix, photos);
        } else {
          await updatePartialWalkthroughReportToCache({
            field: cacheField,
            value: mapPhotosToPhotoUrlsJson(photos),
          });
        }
      }}
    />
  );
}
