import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { TextAreaField } from "src/components/rent-ready/fields/TextAreaField";
import { FlexWalkthroughReportNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useIsReadOnly } from "src/hooks/flexWalkthrough/useIsReadOnly";
import { useSyncSingleWalkthroughReportFieldToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncSingleWalkthroughReportFieldToCacheAndServer";
import { useUpdatePartialWalkthroughReportToCache } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughReportToCache";
import { ArgsReport } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughReportToServer";
import { useIsOnline } from "src/hooks/useIsOnline";

type Props = {
  isValid?: boolean;
  defaultValue: string;
  cacheField: keyof FlexWalkthroughReportNode;
  serverField: keyof ArgsReport;
};
export function ReportAutoSyncTextField({ isValid = true, cacheField, serverField, defaultValue }: Props) {
  const [value, setValue] = useState(defaultValue);
  const updatePartialWalkthroughReportToCache = useUpdatePartialWalkthroughReportToCache();
  const syncSingleWalkthroughReportFieldToCacheAndServer = useSyncSingleWalkthroughReportFieldToCacheAndServer();
  const isOnline = useIsOnline();

  return (
    <Box
      onBlur={async () => {
        if (defaultValue !== value) {
          if (isOnline) {
            await syncSingleWalkthroughReportFieldToCacheAndServer(cacheField, serverField, value, value);
          } else {
            await updatePartialWalkthroughReportToCache({
              field: cacheField,
              value,
            });
          }
        }
      }}
    >
      <TextAreaField
        isValid={isValid}
        label=""
        value={value}
        placeholder="Notes..."
        onChange={setValue}
        disabled={useIsReadOnly()}
      />
    </Box>
  );
}
