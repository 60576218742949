import { Box, BoxProps } from "@chakra-ui/react";

export type Props = BoxProps;

export function PayNowDisclaimerText({ ...boxProps }: Props) {
  return (
    <Box w="100%" fontSize="sm" color="gray.500" {...boxProps}>
      By clicking Pay now you hereby authorize that Doorstead may deduct the above amount from your credit card, debit
      card, or bank account.
    </Box>
  );
}
