import withAuth from "src/utils/withAuth";
import { EverGreenHome } from "../../components/evergreens/EverGreenHome";
import { TenantPortalProvider } from "../../providers/TenantPortalProvider";

export default withAuth(() => {
  return (
    <TenantPortalProvider>
      <EverGreenHome />
    </TenantPortalProvider>
  );
});
