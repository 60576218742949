import { Divider, VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useEffect, useState } from "react";
import { Key, KeyDetail } from "../../../../@types/RentReady/KeyDetail";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsKeyDetailsAdditionalKeysFeature } from "../../../../hooks/rentReady/useIsKeyDetailsAdditionalKeysFeature";
import { useKeyDetailCache } from "../../../../hooks/rentReady/useKeyDetailCache";
import { useSaveKeyDetailSubPage } from "../../../../hooks/rentReady/useSaveKeyDetailSubPage";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { Detail } from "./Detail";
import { useDiff } from "./useDiff";

function Content({ keyDetail }: { keyDetail: KeyDetail }) {
  const { setView } = useView();
  const error = useError();
  const [state, setState] = useState<KeyDetail>(keyDetail);
  const diff = useDiff(keyDetail, state);
  const success = useSuccess();
  const { mutate: updateKeyDetailMutation, isLoading } = useSaveKeyDetailSubPage();
  const isEditable = useIsEditable();
  const isKeyDetailsAdditionalKeysFeature = useIsKeyDetailsAdditionalKeysFeature();
  const disabled = isLoading || diff.length === 0;
  useDisplayInstruction(View.KeyDetailsView);
  useEffect(() => {
    setState(keyDetail);
  }, [JSON.stringify(keyDetail)]);

  const onChange = (key: keyof KeyDetail, field: keyof Key, value: any) => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft[key][field] = value;
      })
    );
  };

  return (
    <FullScreen
      headerName="Key Details"
      backView={View.AreaListView}
      contentProps={{ p: 5 }}
      backButton={
        <OutlineButton
          isFullWidth
          disabled={isLoading}
          onClick={() => {
            setView(View.AreaListView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={disabled}
            onClick={async () => {
              await updateKeyDetailMutation(state, {
                onSuccess() {
                  success({ title: "Key detail updated!" });
                  setView(View.AreaListView);
                },
                onError() {
                  error({
                    title: "Error updating key detail",
                    description: "There was an error updating the key detail. Please try again.",
                  });
                },
              });
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <VStack w="100%">
        <Detail
          label="Front Door Keys"
          photoPrefix={Prefix.FrontendDoorKeys}
          note={state.frontDoorKeys.note}
          isWorking={state.frontDoorKeys.isWorking}
          number={state.frontDoorKeys.number}
          photos={state.frontDoorKeys.photos}
          onIsWorking={(value) => onChange("frontDoorKeys", "isWorking", value)}
          onNoteChange={(value) => onChange("frontDoorKeys", "note", value)}
          onNumberChange={(value) => onChange("frontDoorKeys", "number", value)}
          onPhotosChange={(value) => onChange("frontDoorKeys", "photos", value)}
        />
        <Divider />
        <Detail
          label="Mailbox Keys"
          photoPrefix={Prefix.MailBoxKeys}
          note={state.mailboxKeys.note}
          isWorking={state.mailboxKeys.isWorking}
          number={state.mailboxKeys.number}
          photos={state.mailboxKeys.photos}
          onIsWorking={(value) => onChange("mailboxKeys", "isWorking", value)}
          onNoteChange={(value) => onChange("mailboxKeys", "note", value)}
          onNumberChange={(value) => onChange("mailboxKeys", "number", value)}
          onPhotosChange={(value) => onChange("mailboxKeys", "photos", value)}
        />
        <Divider />

        <Detail
          label="Community Keys"
          photoPrefix={Prefix.CommunityKeys}
          note={state.communityKeys.note}
          isWorking={state.communityKeys.isWorking}
          number={state.communityKeys.number}
          photos={state.communityKeys.photos}
          onIsWorking={(value) => onChange("communityKeys", "isWorking", value)}
          onNoteChange={(value) => onChange("communityKeys", "note", value)}
          onNumberChange={(value) => onChange("communityKeys", "number", value)}
          onPhotosChange={(value) => onChange("communityKeys", "photos", value)}
        />
        <Divider />
        <Detail
          label="Garage Remotes"
          photoPrefix={Prefix.GarageRemotes}
          note={state.garageRemotes.note}
          isWorking={state.garageRemotes.isWorking}
          number={state.garageRemotes.number}
          photos={state.garageRemotes.photos}
          onIsWorking={(value) => onChange("garageRemotes", "isWorking", value)}
          onNoteChange={(value) => onChange("garageRemotes", "note", value)}
          onNumberChange={(value) => onChange("garageRemotes", "number", value)}
          onPhotosChange={(value) => onChange("garageRemotes", "photos", value)}
        />
        <Divider />
        <Detail
          label="Keypads"
          photoPrefix={Prefix.KeyPads}
          note={state.keyPads.note}
          isWorking={state.keyPads.isWorking}
          number={state.keyPads.number}
          photos={state.keyPads.photos}
          onIsWorking={(value) => onChange("keyPads", "isWorking", value)}
          onNoteChange={(value) => onChange("keyPads", "note", value)}
          onNumberChange={(value) => onChange("keyPads", "number", value)}
          onPhotosChange={(value) => onChange("keyPads", "photos", value)}
        />
        {isKeyDetailsAdditionalKeysFeature && (
          <>
            <Divider />
            <Detail
              label="Additional Keys"
              photoPrefix={Prefix.AdditionalKeys}
              note={state.additionalKeys.note}
              isWorking={state.additionalKeys.isWorking}
              number={state.additionalKeys.number}
              photos={state.additionalKeys.photos}
              onIsWorking={(value) => onChange("additionalKeys", "isWorking", value)}
              onNoteChange={(value) => onChange("additionalKeys", "note", value)}
              onNumberChange={(value) => onChange("additionalKeys", "number", value)}
              onPhotosChange={(value) => onChange("additionalKeys", "photos", value)}
            />
          </>
        )}
      </VStack>
    </FullScreen>
  );
}

export function KeyDetailsView() {
  const keyDetail = useKeyDetailCache();
  if (keyDetail === null) {
    return null;
  }

  return <Content keyDetail={keyDetail} />;
}
