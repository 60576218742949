import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";
import { ApplicantOccupantNode } from "./useFetchApplicationMe/Applicant";

export function useUpdateOccupant() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    async (
      occupant: Partial<ApplicantOccupantNode> & { applicantOccupantId: ApplicantOccupantNode["applicantOccupantId"] }
    ) => {
      queryClient.setQueryData(
        queryKey,
        produce(queryClient.getQueryData(queryKey), (draft: Result) => {
          draft.applicantById.occupants.edges.forEach((edge) => {
            if (edge.node.applicantOccupantId === occupant.applicantOccupantId) {
              edge.node = Object.assign(edge.node, occupant);
            }
          });
        })
      );

      await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantOccupant: ApplicantOccupantUpdateData) {
            updateApplicantOccupant(applicantOccupant: $applicantOccupant) {
              count
            }
          }
        `,
        variables: {
          applicantOccupant: {
            ...occupant,
            applicantOccupantId: occupant.applicantOccupantId,
          },
        },
      });
    }
  );
}
