import { DoorsteadProvider, EmailSentScreen, ResetPasswordScreen, S3_BUCKET } from "@doorstead/components";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { processAuthError, useAuth } from "src/contexts/auth";
import { usePageView } from "src/hooks/usePageView";
import withoutAuth from "src/utils/withoutAuth";

function ForgotPasswordPage() {
  const [params] = useSearchParams();
  const initialEmail = params.get("email");
  const [email, setEmail] = useState(initialEmail || "");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { changePassword } = useAuth();
  usePageView("TP_FORGOT_PASSWORD_VIEWED");

  const resetPassword = async () => {
    try {
      setLoading(true);
      await changePassword({ email });
      setEmailSent(true);
    } catch (err: any) {
      const authError = processAuthError(err);
      setEmailError(authError.email || "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DoorsteadProvider>
      {emailSent ? (
        <EmailSentScreen
          title="Doorstead for Tenants"
          subTitle="Property management reimagined"
          titleColor="white"
          subTitleColor="white"
          backgroundImageUrl="https://doorstead-components.s3.us-west-2.amazonaws.com/tenant_portal_login.png"
          logoLink="/"
        />
      ) : (
        <ResetPasswordScreen
          title="Doorstead for Tenants"
          subTitle="Property management reimagined"
          titleColor="white"
          subTitleColor="white"
          backgroundImageUrl={`${S3_BUCKET}/tenant_portal_login.png`}
          isBackgroundGradient={false}
          email={email}
          onChange={setEmail}
          logoLink="/"
          isSubmitting={loading}
          onReset={resetPassword}
          emailErrorMessage={emailError}
        />
      )}
    </DoorsteadProvider>
  );
}

export default withoutAuth(ForgotPasswordPage);
