import { Flex } from "@chakra-ui/react";
import { Select } from "@doorstead/components";
import { Repair } from "../../@types/RepairList/Repair";
import { useRejectEstimateModal } from "../../hooks/rr-project/useRejectEstimateModal";
import { getRepairStatusLabel } from "../../utils/rr-project/getRepairStatusLabel";
import { isRepairPendingEstimateApproval } from "../../utils/rr-project/isRepairPendingEstimateApporval";
import { useToUpdateRepairEstimateApprovedStore } from "./stores/useToUpdateRepairEstimateApprovedStore";

type Props = {
  repair: Repair;
};

export function RepairStatusBox({ repair }: Props) {
  const { isOpenedBefore, onOpen } = useRejectEstimateModal();
  const { hasEstimateApprove, getEstimateApprove, onChangeEstimateApprove } = useToUpdateRepairEstimateApprovedStore();

  if (isRepairPendingEstimateApproval(repair)) {
    const serverValue = repair?.chosenVendorRepair?.estimateApproved ?? true;
    const value = hasEstimateApprove(repair.id) ? getEstimateApprove(repair.id) : serverValue;

    return (
      <Select
        id="repair-status"
        label=""
        value={value ? "approve" : "reject"}
        size="md"
        options={[
          {
            value: "approve",
            name: "Approve",
          },
          {
            value: "reject",
            name: "Reject",
          },
        ]}
        onChange={(event) => {
          const toValue = event.target.value;
          if (toValue === "reject" && !isOpenedBefore) {
            onOpen();
          }
          onChangeEstimateApprove(repair.id, toValue === "approve", serverValue);
        }}
      />
    );
  } else {
    return (
      <Flex justifyContent="flex-start" w="100%">
        {getRepairStatusLabel(repair)}
      </Flex>
    );
  }
}
