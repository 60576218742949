import produce from "immer";
import { Area } from "../../@types/RentReady/Area";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { CleaningOptions, PaintingOptions } from "./useAreasCache/mapAreaNodesToAreas";
import { Args } from "./useUpdateAreaToServer";

export function getAreaOptions(area: Area): { selectedCleaningOptions: string[]; selectedPaintingOptions: string[] } {
  const selectedCleaningOptions: string[] = [];
  const selectedPaintingOptions: string[] = [];

  if (area.cleanDeep) {
    selectedCleaningOptions.push(CleaningOptions.Deep);
  }
  if (area.cleanLight) {
    selectedCleaningOptions.push(CleaningOptions.Light);
  }
  if (area.painting) {
    selectedPaintingOptions.push(PaintingOptions.FullPainting);
  }

  return {
    selectedCleaningOptions,
    selectedPaintingOptions,
  };
}

export function mapAreaCacheToServerArgs(areaCache: Area): Args {
  const {
    id: areaDataId,
    itemRemovalPhotos,
    photos,
    itemRemovalNotes,
    cleaningNotes,
    paintingNotes,
    cleanOdors: selectedOdors,
    cleanPersonalItemRemoval: selectedItemRemoval,
    smokeDetectorNotes,
    smokeDetectorIsOneWorkingDetectorFound,
    smokeDetectorStatus,
    smokeDetectorPhotos,
    updateDate,
    notes,
  } = areaCache;
  const { selectedCleaningOptions, selectedPaintingOptions } = getAreaOptions(areaCache);
  const photoUrlsJson = mapPhotosToPhotoUrlsJson(photos);
  const itemRemovalPhotoUrlsJson = mapPhotosToPhotoUrlsJson(itemRemovalPhotos);

  return {
    areaDataId,
    photoUrlsJson,
    selectedCleaningOptions,
    cleaningNotes,
    selectedPaintingOptions,
    paintingNotes,
    selectedItemRemoval,
    selectedOdors,
    itemRemovalNotes,
    notes,
    itemRemovalPhotoUrlsJson,
    smokeDetectorNotes,
    smokeDetectorIsOneWorkingDetectorFound: smokeDetectorIsOneWorkingDetectorFound || false,
    smokeDetectorStatus,
    smokeDetectorPhotoUrlsJson: mapPhotosToPhotoUrlsJson(smokeDetectorPhotos),
    areaDataUpdatedAt: updateDate,
  };
}

export function useMapAreaStateFromRRGraphQLResultCache() {
  return (result: RRGraphQLResultCache, area: Area): RRGraphQLResultCache => {
    return produce(result, (draft) => {
      const { selectedCleaningOptions, selectedPaintingOptions } = getAreaOptions(area);
      const areaCache = draft.inspectionReport.data?.find((e) => e.areaDataId === area.id);
      if (areaCache) {
        areaCache.areaSelectedCleaningOptions = selectedCleaningOptions;
        areaCache.areaSelectedItemRemoval = area.cleanPersonalItemRemoval;
        areaCache.areaSelectedOdors = area.cleanOdors;
        areaCache.areaCleaningNotes = area.cleaningNotes;
        areaCache.areaSelectedPaintingOptions = selectedPaintingOptions;
        areaCache.areaPaintingNotes = area.paintingNotes;
        areaCache.areaItemRemovalNotes = area.itemRemovalNotes;

        areaCache.areaSmokeDetectorNotes = area.smokeDetectorNotes;
        areaCache.areaSmokeDetectorStatus = area.smokeDetectorStatus;
        areaCache.areaSmokeDetectorPhotoUrlsJson = mapPhotosToPhotoUrlsJson(area.smokeDetectorPhotos);

        areaCache.areaPhotoUrlsJson = mapPhotosToPhotoUrlsJson(area.photos);
        areaCache.areaNotes = area.notes;
        areaCache.areaItemRemovalPhotoUrlsJson = mapPhotosToPhotoUrlsJson(area.itemRemovalPhotos);
      }
    });
  };
}
