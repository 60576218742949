import { Box, Checkbox, Flex, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { CalendarIcon } from "@doorstead/components/icons";
import { useState } from "react";
import { getLabel } from "../../../../../@types/AutoPay/getLabel";
import { AutoPayDate } from "../../../../../@types/AutoPay/typings";
import { useNextStep } from "../../../../../contexts/StepContext/useNextStep";
import { useAgreeAutopayDisclaimer } from "../../../../../hooks/useAgreeAutopayDisclaimer";
import { useAutoPay } from "../../../../../hooks/useAutoPay";
import { useFetchedMe } from "../../../../../hooks/useFetchedMe";
import { usePageView } from "../../../../../hooks/usePageView";
import { AutopayDisclaimerModal } from "../../../../AutopayDisclaimerText/AutopayDisclaimerModal";
import { Banner } from "../Banner";
import { AddAutopayModal } from "./AddAutopayModal";
import { ConfirmDisableAutopayModal } from "./ConfirmDisableAutopayModal";
import { EditAutopayModal } from "./EditAutopayModal";
import { IconBox } from "./IconBox";
import { PaymentAccountIconBox } from "./PaymentAccountIconBox";

export function DefaultAutoPayStep() {
  const me = useFetchedMe();
  const autoPay = useAutoPay();
  const {
    isOpen: isOpenAddAutopayModal,
    onOpen: onOpenAddAutopayModal,
    onClose: onCloseAddAutopayModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditAutopayModal,
    onOpen: onOpenEditAutopayModal,
    onClose: onCloseEditAutopayModal,
  } = useDisclosure();
  const {
    isOpen: isOpenDisableConfirmAutopayModal,
    onOpen: onOpenDisableConfirmAutopayModal,
    onClose: onCloseDisableConfirmAutopayModal,
  } = useDisclosure();
  const {
    isOpen: isOpenDisclaimerModal,
    onOpen: onOpenDisclaimerModal,
    onClose: onCloseDisclaimerModal,
  } = useDisclosure();

  const [isChecked, setIsChecked] = useState(false);
  const { mutate: agreeAutopayDisclaimer, isLoading: isAgreeAutopayDisclaimerLoading } = useAgreeAutopayDisclaimer();
  const isMobile = useIsMobile();
  const analytics = usePageView("TP_OB_DEFAULT_AUTOPAY_VIEWED");
  const hasAutopay = !!autoPay;

  const { nextStep, isLoading: isNextStepLoading } = useNextStep();
  const isDisabled = isNextStepLoading || (hasAutopay && !isChecked);

  return (
    <>
      <AddAutopayModal
        isOpen={isOpenAddAutopayModal}
        onClose={() => {
          onCloseAddAutopayModal();
          onCloseEditAutopayModal();
          onCloseDisableConfirmAutopayModal();
        }}
      />
      {autoPay && (
        <EditAutopayModal
          autopay={autoPay}
          isOpen={isOpenEditAutopayModal}
          onClose={onCloseEditAutopayModal}
          onDisableClick={() => {
            onCloseEditAutopayModal();
            onOpenDisableConfirmAutopayModal();
          }}
        />
      )}

      {autoPay && (
        <ConfirmDisableAutopayModal
          autopay={autoPay}
          isOpen={isOpenDisableConfirmAutopayModal}
          onClose={onCloseDisableConfirmAutopayModal}
          onSuccess={() => {
            onCloseDisableConfirmAutopayModal();
          }}
          onBack={() => {
            onOpenEditAutopayModal();
            onCloseDisableConfirmAutopayModal();
          }}
        />
      )}
      <AutopayDisclaimerModal
        defaultShowIAgreeButton={false}
        isOpen={isOpenDisclaimerModal}
        onClose={onCloseDisclaimerModal}
        onOk={onCloseDisclaimerModal}
      />

      <Box w="100%" h="100%">
        <Banner />
        <VStack spacing={5} alignItems="flex-start" w="100%" bgColor="white" padding={8} borderBottomRadius={20}>
          <VStack spacing={10} w="100%" alignItems="flex-start" bgColor="gray.50" py={5} px={5} borderRadius={6}>
            <Flex alignItems="flex-start" justifyContent="space-between" w="100%">
              <Chip
                size="sm"
                label={hasAutopay ? "Autopay On" : "Autopay Off"}
                variant={hasAutopay ? Variant.Green : Variant.Red}
              />
              {hasAutopay ? (
                <OutlineButton size="sm" onClick={onOpenEditAutopayModal}>
                  Edit
                </OutlineButton>
              ) : (
                <PrimaryButton size="sm" onClick={onOpenAddAutopayModal}>
                  Enable Autopay
                </PrimaryButton>
              )}
            </Flex>
            <Flex w="100%" justifyContent="space-between" flexWrap="wrap">
              <IconBox title="Payment amount" subTitle="Account balance">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="100%"
                  width="100%"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"></path>
                </svg>
              </IconBox>
              <IconBox title="Payment date" subTitle={getLabel(autoPay?.date || AutoPayDate.TWENTY_EIGHTH)}>
                <CalendarIcon color="gray.900" />
              </IconBox>
              <PaymentAccountIconBox />
            </Flex>
          </VStack>

          {hasAutopay ? (
            <>
              <HStack
                role="button"
                borderRadius={6}
                spacing={5}
                bgColor="#EDF2F7"
                p={3}
                flexWrap="wrap"
                alignItems="flex-start"
                onClick={(e) => {
                  e.preventDefault();
                  setIsChecked((e) => !e);
                }}
              >
                <Checkbox isChecked={isChecked} />
                <Box flex={1} color="gray.500" fontSize="sm">
                  I authorize Doorstead to automatically make ACH payments to and debits from my bank account for
                  recurring payments.
                  <Box
                    as="span"
                    ml={1}
                    role="button"
                    color="purple.500"
                    _hover={{ textDecoration: "underline" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenDisclaimerModal();
                    }}
                  >
                    See details.
                  </Box>
                </Box>
              </HStack>
              <PrimaryButton
                isFullWidth={isMobile}
                isLoading={isNextStepLoading || isAgreeAutopayDisclaimerLoading}
                size="md"
                disabled={isDisabled}
                onClick={() => {
                  agreeAutopayDisclaimer(
                    {
                      achDisclaimerAt: new Date(),
                    },
                    {
                      onSuccess() {
                        analytics?.track("TP_OB_DEFAULT_AUTOPAY_AGREED", {
                          doorsteadUserId: me.doorsteadUserId,
                          tenantId: me.tenant.tenantId,
                        });
                        nextStep();
                      },
                    }
                  );
                }}
              >
                Continue
              </PrimaryButton>
            </>
          ) : (
            <>
              <OutlineButton
                isFullWidth={isMobile}
                isLoading={isNextStepLoading}
                disabled={isDisabled}
                size="md"
                onClick={() => {
                  analytics?.track("TP_OB_DEFAULT_AUTOPAY_CONTINUE_WITHOUT_AUTOPAY", {
                    doorsteadUserId: me.doorsteadUserId,
                    tenantId: me.tenant.tenantId,
                  });
                  nextStep();
                }}
              >
                Continue without autopay
              </OutlineButton>
            </>
          )}
        </VStack>
      </Box>
    </>
  );
}
