import { useHashTag } from "./useHashTag";

export function useHashTagParams(): Record<string, string> {
  const hashTag = useHashTag();
  const hashParams = hashTag.slice(1).split("&");
  const parsedParams: Record<string, string> = {};
  hashParams.forEach((param) => {
    const [key, value] = param.split("=");
    parsedParams[key] = value;
  });
  return parsedParams;
}
