import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { Item } from "../../../../@types/RentReady/Item";
import { ItemNode } from "../../../../hooks/rentReady/useFetchInspectionReport/ItemNode";
import { useSyncItemTextToCacheAndServer } from "../../../../hooks/rentReady/useSyncItemTextToCacheAndServer";
import { useUpdatePartialItemToCache } from "../../../../hooks/rentReady/useUpdatePartialItemToCache";
import { ArgsItem } from "../../../../hooks/rentReady/useUpdatePartialItemToServer";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { AreaNotesField } from "./AreaNotesField";

type Props = {
  isValid?: boolean;
  item: Item;
  defaultValue: string;
  cacheField: keyof ItemNode;
  serverField: keyof ArgsItem;
};

export function ItemAutoSyncTextField({ isValid = true, item, defaultValue, cacheField, serverField }: Props) {
  const [value, setValue] = useState(defaultValue);
  const syncItemTextToCacheAndServer = useSyncItemTextToCacheAndServer();
  const updatePartialItemToCache = useUpdatePartialItemToCache();
  const isOnline = useIsOnline();
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box
      onBlur={async () => {
        if (defaultValue !== value) {
          if (isOnline) {
            await syncItemTextToCacheAndServer(item, cacheField, serverField, value, value);
          } else {
            await updatePartialItemToCache({
              id: item.id,
              field: cacheField,
              value,
            });
          }
        }
      }}
    >
      <AreaNotesField value={value} isValid={isValid} onChange={setValue} disabled={useIsReadOnly()} />
    </Box>
  );
}
