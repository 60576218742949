import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

export function useCreateFlexWalkthroughReportData() {
  const { gqlClient } = useAuth();
  const flexWalkthroughReportId = useFlexWalkthroughReportId();
  const { refetch } = useFetchFlexWalkthroughReport();

  return useMutation(
    async () => {
      return gqlClient.mutate({
        mutation: gql`
          mutation ($flexWalkthroughReportId: UUID!) {
            createFlexWalkthroughReportData(flexWalkthroughReportId: $flexWalkthroughReportId) {
              success
            }
          }
        `,
        variables: {
          flexWalkthroughReportId,
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
