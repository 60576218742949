import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { getDollarByCents } from "../utils/getDollarByCents";
import { useFetchMe } from "./useFetchMe";
import { useGroupAnalytics } from "./useGroupAnalytics";
import { useOpenPaymentFailureModal } from "./useOpenPaymentFailureModal";
import { useOpenPaymentSuccessModal } from "./useOpenPaymentSuccessModal";
import { usePaymentMethods } from "./usePaymentMethods";
import { useRefetchReceivableLedgerEntryHistory } from "./useRefetchReceivableLedgerEntryHistory";
import { useTenantGroupId } from "./useTenantGroupId";

const MUTATION = gql`
  mutation payHoldingdep($amountInCents: Int!, $paymentMethodId: UUID!, $tenantGroupId: UUID!) {
    paySecdep(amountInCents: $amountInCents, paymentMethodId: $paymentMethodId, tenantGroupId: $tenantGroupId) {
      ledgerTransactionId
    }
  }
`;

export function usePayHoldingDeposit() {
  const { refetch } = useFetchMe();
  const paymentMethods = usePaymentMethods();
  const analytics = useGroupAnalytics();
  const openPaymentSuccessModal = useOpenPaymentSuccessModal();
  const openPaymentFailureModal = useOpenPaymentFailureModal();
  const { gqlClient } = useAuth();
  const tenantGroupId = useTenantGroupId();
  const { isLoading, refetchReceivableLedgerEntryHistory } = useRefetchReceivableLedgerEntryHistory();
  const mutation = useMutation(
    async ({ amountInCents, paymentMethodId }: { amountInCents: number; paymentMethodId: string }) => {
      await gqlClient.mutate({
        mutation: MUTATION,
        variables: {
          amountInCents,
          paymentMethodId,
          tenantGroupId,
        },
      });
      await refetchReceivableLedgerEntryHistory();
    },
    {
      async onSuccess(ignored, { amountInCents, paymentMethodId }) {
        const paymentMethod = paymentMethods.find((e) => e.paymentMethodId === paymentMethodId) || null;
        analytics?.track("TP_OB_HOLDING_DEP_PAID", {
          paymentMethodId,
        });
        await refetch();
        openPaymentSuccessModal({
          amount: getDollarByCents(amountInCents),
          paymentMethod,
          doneText: "Continue",
          suggestAutoPay: false,
        });
      },
      onError(e: any, { amountInCents, paymentMethodId }) {
        console.error(e);
        const paymentMethod = paymentMethods.find((e) => e.paymentMethodId === paymentMethodId) || null;
        analytics?.track("TP_OB_PAY_HOLDING_DEP_FAILED", {
          paymentMethodId,
        });
        const errorMessage = e.graphQLErrors && e.graphQLErrors.length !== 0 ? e.graphQLErrors[0].message : null;
        openPaymentFailureModal({ amount: getDollarByCents(amountInCents), paymentMethod, errorMessage });
      },
    }
  );

  return {
    payHoldingDeposit: mutation.mutate,
    loading: isLoading || mutation.isLoading,
  };
}
