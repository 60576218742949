import { Box, Divider, Flex, HStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useArea } from "../../../../../hooks/rentReady/useArea";
import { useSyncSingleAreaFieldToCacheAndServer } from "../../../../../hooks/rentReady/useSyncSingleAreaFieldToCacheAndServer";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { isNoteValid } from "../../../../../utils/rentReady/isNoteValid";
import { isSmokeDetectorComplete } from "../../../../../utils/rentReady/isSmokeDetectorComplete";
import { isSmokeDetectorsPhotoValid } from "../../../../../utils/rentReady/isSmokeDetectorsPhotoValid";
import { ArrowOpenIcon } from "../../../../ArrowOpenIcon";
import { RequiredLabel } from "../../../RequiredLabel";
import { GoodMissingFixButtons } from "../GoodMissingFixButtons";
import { AreaAutoSyncPhotosField } from "./AreaAutoSyncPhotosField";
import { AreaAutoSyncTextField } from "./AreaAutoSyncTextField";

export function AreaSmokeDetectorFoundBox() {
  const { smokeDetectorNotes, smokeDetectorStatus: defaultStatus, smokeDetectorPhotos } = useArea()!;
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    defaultIsOpen: !isSmokeDetectorComplete({
      smokeDetectorNotes,
      smokeDetectorStatus: defaultStatus,
      smokeDetectorPhotos,
    }),
  });
  const syncSingleAreaFieldToCacheAndServer = useSyncSingleAreaFieldToCacheAndServer();
  const [status, setStatus] = useState(defaultStatus);

  return (
    <>
      <Divider />
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <RequiredLabel
          hasValue={isSmokeDetectorComplete({
            smokeDetectorStatus: status,
            smokeDetectorNotes,
            smokeDetectorPhotos,
          })}
        >
          Smoke Detector
        </RequiredLabel>
        <HStack>
          <GoodMissingFixButtons
            value={status}
            onChange={async (toStatus) => {
              setStatus(toStatus);
              if (
                !isSmokeDetectorComplete({
                  smokeDetectorNotes,
                  smokeDetectorStatus: toStatus,
                  smokeDetectorPhotos,
                })
              ) {
                onOpen();
              } else {
                onClose();
              }
              await syncSingleAreaFieldToCacheAndServer(
                "areaSmokeDetectorStatus",
                "smokeDetectorStatus",
                toStatus,
                toStatus
              );
            }}
          />
          <ArrowOpenIcon isOpen={isOpen} onClick={() => onToggle()} />
        </HStack>
      </Flex>
      {isOpen && (
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <AreaAutoSyncPhotosField
            prefix={Prefix.AreasSmokeDetectors}
            values={smokeDetectorPhotos}
            isValid={isSmokeDetectorsPhotoValid(status, smokeDetectorPhotos)}
            cacheField="areaSmokeDetectorPhotoUrlsJson"
            serverField="smokeDetectorPhotoUrlsJson"
          />
          <Box w="1fr">
            <AreaAutoSyncTextField
              value={smokeDetectorNotes}
              isValid={isNoteValid(status, smokeDetectorNotes)}
              cacheField="areaSmokeDetectorNotes"
              serverField="smokeDetectorNotes"
            />
          </Box>
        </Flex>
      )}
    </>
  );
}
