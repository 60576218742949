import { Step } from "./typings";
import { useSteps } from "./useSteps";

export function useGetStep() {
  const steps = useSteps();

  return (index: number | null): Step => {
    if (index === null || index < 0 || index > steps.length - 1) {
      return steps[0];
    }
    return steps[index];
  };
}
