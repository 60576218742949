import { moneyString } from "@doorstead/components";
import { Decision, Estimate } from "../../../../../@types/RentReady/Estimate";

export function getSummary({
  decision,
  isOutOfScope,
  isToBeDetermined,
  note,
  isRange,
  fromPrice,
  toPrice,
}: Estimate): string[] {
  switch (decision) {
    case Decision.CanNotEstimate: {
      const arr: string[] = [];
      if (isToBeDetermined) {
        arr.push("To be determined");
      }
      if (isOutOfScope) {
        arr.push("Out of scope");
      }
      if (note.trim() !== "") {
        arr.push(note);
      }
      return arr;
    }
    case Decision.AddEstimates: {
      const arr: string[] = [];
      if (!isRange) {
        arr.push(moneyString(fromPrice, false));
      } else {
        arr.push(`${moneyString(fromPrice, false)} - ${moneyString(toPrice, false)}`);
      }
      return arr;
    }
    case null:
      return [];
  }
}
