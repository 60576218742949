import { ApolloProvider } from "@apollo/client";
import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { LinkProvider } from "@doorstead/components/hooks/useReactRouterLink";
import React from "react";
import { Link, LinkProps, ScrollRestoration, useNavigate } from "react-router-dom";
import { Provider as GoogleMapProvider } from "src/hooks/useGoogleMapKey";
import { EmptyScreen } from "../components/EmptyScreen";
import { Error } from "../components/Error";
import {
  noLease,
  noMeRecord,
  noRecord,
  noTenant,
  noTenantGroup,
  noTenantGroupProperty,
} from "../components/Error/ErrorCode";
import { MainLayout } from "../components/MainLayout";
import { Onboarding } from "../components/Onboarding";
import { GOOGLE_MAP_KEY } from "../config";
import { Step, useStep } from "../contexts/StepContext";
import { useAuth } from "../contexts/auth";
import { useFetchMe } from "../hooks/useFetchMe";
import { useFetchedMe } from "../hooks/useFetchedMe";
import { useFooter } from "../hooks/useFooter";
import { useHeader } from "../hooks/useHeader";
import { useIsTenantFlex } from "../hooks/useIsTenantFlex";
import { Provider as PaymentFailureModalProvider } from "../hooks/useOpenPaymentFailureModal";
import { Provider as PaymentSuccessModalProvider } from "../hooks/useOpenPaymentSuccessModal";
import { Provider as PropertyStateProvider } from "../hooks/usePropertyState";

function LayoutContent({ children }: { children: React.ReactNode }) {
  const header = useHeader();
  const footer = useFooter();
  const { step } = useStep();
  const tenantMe = useFetchedMe();
  const isFlex = useIsTenantFlex();

  return (
    <GoogleMapProvider value={GOOGLE_MAP_KEY}>
      <PaymentFailureModalProvider>
        <PaymentSuccessModalProvider>
          <PropertyStateProvider propertyState={tenantMe.tenant.activeLease.property.state}>
            <MainLayout header={header} footer={footer} isFlex={isFlex}>
              {step === Step.DONE ? children : <Onboarding />}
            </MainLayout>
          </PropertyStateProvider>
        </PaymentSuccessModalProvider>
      </PaymentFailureModalProvider>
    </GoogleMapProvider>
  );
}

function Body({ children }: { children: React.ReactNode }) {
  const { isFetching, isFetched, data } = useFetchMe();
  const navigate = useNavigate();

  if (isFetching && !isFetched) {
    return <EmptyScreen>Loading...</EmptyScreen>;
  } else if (!data) {
    return (
      <EmptyScreen>
        <Error {...noRecord} />
      </EmptyScreen>
    );
  } else if (data.me === null) {
    return (
      <EmptyScreen>
        <Error {...noMeRecord} />
      </EmptyScreen>
    );
  } else if (data.me.tenant === null) {
    if (data.me.applicants.edges.length === 1) {
      // Redirect to application page if tenant is not found and there is only one applicant
      const applicantId = data.me.applicants.edges[0].node.applicantId;
      if (applicantId) {
        navigate(`/application/${applicantId}`);
        return null;
      }
    }
    return (
      <EmptyScreen>
        <Error {...noTenant} />
      </EmptyScreen>
    );
  } else if (data.me.tenant.activeLease === null) {
    return (
      <EmptyScreen>
        <Error {...noLease} />
      </EmptyScreen>
    );
  } else if (data.me.tenant.activeLease.tenantGroup === null) {
    return (
      <EmptyScreen>
        <Error {...noTenantGroup} />
      </EmptyScreen>
    );
  } else if (data.me.tenant.activeLease.property === null) {
    return (
      <EmptyScreen>
        <Error {...noTenantGroupProperty} />
      </EmptyScreen>
    );
  } else {
    return <LayoutContent>{children} </LayoutContent>;
  }
}

export function TenantPortalProvider({ children }: { children: React.ReactNode }) {
  const { gqlClient } = useAuth();

  return (
    <ApolloProvider client={gqlClient}>
      <LinkProvider
        value={{
          Link(linkProps: any) {
            return <Link {...(linkProps as LinkProps)} />;
          },
        }}
      >
        <DoorsteadProvider>
          <Body>{children}</Body>
          <ScrollRestoration />
        </DoorsteadProvider>
      </LinkProvider>
    </ApolloProvider>
  );
}
