import { useMutation } from "react-query";
import { Area } from "../../@types/RentReady/Area";
import { Item } from "../../@types/RentReady/Item";
import { Komponent } from "../../@types/RentReady/Komponent";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { refreshPage } from "../../utils/refreshPage";
import { isKeyDetailDiff } from "../../utils/rentReady/isKeyDetailDiff";
import { isKeyStorageDiff } from "../../utils/rentReady/isKeyStorageDiff";
import { mapInspectionReportNodeToKeyDetail } from "../../utils/rentReady/mapInspectionReportNodeToKeyDetail";
import { mapInspectionReportNodeToKeyStorage } from "../../utils/rentReady/mapInspectionReportNodeToKeyStorage";
import { shouldInvalidateCache } from "../../utils/rentReady/shouldInvalidateCache";
import { useAreasCache } from "./useAreasCache";
import { mapAreaNodesToAreas } from "./useAreasCache/mapAreaNodesToAreas";
import { useCreateInspectionReportEstimatesData } from "./useCreateInspectionReportEstimatesData";
import { useCreateInspectionReportEstimatesDataV2 } from "./useCreateInspectionReportEstimatesDataV2";
import { useFetchInspectionReport } from "./useFetchInspectionReport";
import { fetchRRGraphQLResultServer } from "./useFetchInspectionReport/fetchRRGraphQLResultServer";
import { useMergeCacheWithServer } from "./useFetchInspectionReport/useMergeCacheWithServer";
import { useInspectionReportId } from "./useInspectionReportId";
import { useIsV2UiFeatures } from "./useIsV2UiFeatures";
import { useKeyDetailCache } from "./useKeyDetailCache";
import { useKeyStorageCache } from "./useKeyStorageCache";
import { useUpdateAreasToServer } from "./useUpdateAreasToServer";
import { useUpdateComponentsToServer } from "./useUpdateComponentsToServer";
import { useUpdateItemsToServer } from "./useUpdateItemsToServer";
import { useMapKeyDetailToArgs, useUpdateKeyDetailToServer } from "./useUpdateKeyDetailToServer";
import { useMapKeyStorageToArgs, useUpdateKeyStorageToServer } from "./useUpdateKeyStorageToServer";

function getItems(areas: Area[]): Item[] {
  const items: Item[] = [];
  for (const area of areas) {
    items.push(...area.items);
  }

  return items;
}

function getComponents(areas: Area[]): Komponent[] {
  const components: Komponent[] = [];
  for (const area of areas) {
    for (const item of area.items) {
      components.push(...item.components);
    }
  }

  return components;
}

export function useSubmitAreaList() {
  let areasCache = useAreasCache();
  const inspectionReportId = useInspectionReportId();
  const isV2 = useIsV2UiFeatures();
  const keyDetailCache = useKeyDetailCache();
  const keyStorageCache = useKeyStorageCache();
  const updateKeyDetailToServer = useUpdateKeyDetailToServer();
  const updateKeyStorageToServer = useUpdateKeyStorageToServer();
  const updateAreasToServer = useUpdateAreasToServer();
  const updateItemsToServer = useUpdateItemsToServer();
  const updateComponentsToServer = useUpdateComponentsToServer();
  const createInspectionReportEstimatesData = useCreateInspectionReportEstimatesData();
  const createInspectionReportEstimatesDataV2 = useCreateInspectionReportEstimatesDataV2();
  const mapKeyDetailToArgs = useMapKeyDetailToArgs();
  const mapKeyStorageToArgs = useMapKeyStorageToArgs();
  const { refetch } = useFetchInspectionReport();
  const mergeCacheWithServer = useMergeCacheWithServer();

  return useMutation(async () => {
    if (isOnline()) {
      //1. get the latest cache and server data to compare if there is any difference
      const rrGraphQLResultCache = await readRRGraphQLResultCache(inspectionReportId);
      const rrGraphQLResultServer = await fetchRRGraphQLResultServer(inspectionReportId);

      if (rrGraphQLResultCache === null) {
        refreshPage();
      } else if (
        shouldInvalidateCache({
          cacheTime: rrGraphQLResultCache.inspectionReport.completedEvalTime || null,
          serverTime: rrGraphQLResultServer.inspectionReport.completedEvalTime,
        })
      ) {
        console.log("Do nothing. The server is more recent than the local database.");
      } else {
        // 2. if the server is more recent, we invalidate the cache and sync the whole data
        await mergeCacheWithServer(rrGraphQLResultCache, rrGraphQLResultServer);

        //3. get the latest cache and server data to compare if there is any difference
        const keyDetailServer = mapInspectionReportNodeToKeyDetail(rrGraphQLResultServer.inspectionReport);
        const keyStorageServer = mapInspectionReportNodeToKeyStorage(rrGraphQLResultServer.inspectionReport);
        const areasServer: Area[] = mapAreaNodesToAreas(rrGraphQLResultServer.inspectionReport.data || []);
        const itemsServer: Item[] = getItems(areasServer);
        const componentsServer: Komponent[] = getComponents(areasServer);

        //4. if there is any difference, we sync the data to server
        if (keyDetailCache !== null && isKeyDetailDiff(keyDetailCache, keyDetailServer)) {
          await updateKeyDetailToServer(mapKeyDetailToArgs(keyDetailCache));
        }

        if (keyStorageCache !== null && isKeyStorageDiff(keyStorageCache, keyStorageServer)) {
          await updateKeyStorageToServer(mapKeyStorageToArgs(keyStorageCache));
        }

        areasCache = await updateAreasToServer(areasCache, areasServer);
        await updateItemsToServer(getItems(areasCache), itemsServer);
        await updateComponentsToServer(getComponents(areasCache), componentsServer);

        //5. create estimates data
        let completedEvalTime: string;
        if (isV2) {
          const {
            data: {
              createInspectionReportEstimatesDataV2: { completedEvalTime: time },
            },
          } = await createInspectionReportEstimatesDataV2();
          completedEvalTime = time;
        } else {
          const {
            data: {
              createInspectionReportEstimatesData: { completedEvalTime: time },
            },
          } = await createInspectionReportEstimatesData();
          completedEvalTime = time;
        }

        //update the latest timestamp
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          cache.inspectionReport.completedEvalTime = completedEvalTime;
          await writeRRGraphQLResult(inspectionReportId, cache);
        }
        await refetch();
      }
    }
  });
}
