import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { ApplicantNode, Result, queryKey } from "./useFetchApplicationMe";
import { useFetchedApplicant } from "./useFetchedApplicant";

export function useUpdateApplicantField() {
  const queryClient = useQueryClient();
  const applicant = useFetchedApplicant();
  const { gqlClient } = useAuth();

  return useMutation(async (object: Partial<ApplicantNode>) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        draft.applicantById = {
          ...draft.applicantById,
          ...object,
        };
      })
    );

    return await gqlClient.mutate({
      mutation: gql`
        mutation ($applicant: ApplicantUpdateData) {
          updateApplicant(applicant: $applicant) {
            count
          }
        }
      `,
      variables: {
        applicant: {
          ...object,
          applicantId: applicant.applicantId,
        },
      },
    });
  });
}
