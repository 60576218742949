import { Flex, HStack, VStack } from "@chakra-ui/react";
import { TextArea } from "@doorstead/components";
import { KeyStorage } from "../../../../../@types/RentReady/KeyStorage";
import { useIsReadOnly } from "../../../../../hooks/rentReady/useIsReadOnly";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { RequiredLabel } from "../../../RequiredLabel";
import { PhotosField } from "../../../fields/PhotosField";
import { QuestionIcon } from "../QuestionIcon";
import { useOnHintClick } from "./useOnHintClick";

type Props = {
  value: KeyStorage["additionalKeyStorage"];
  onChange: (field: keyof KeyStorage["additionalKeyStorage"], value: any) => void;
};

export function AdditionalKeyStorageBox({ value, onChange }: Props) {
  const isReadOnly = useIsReadOnly();
  const onHintClick = useOnHintClick();

  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack spacing={1}>
        <RequiredLabel hasValue={value.photos.length > 0}>Additional Key Storage</RequiredLabel>
        <QuestionIcon onClick={onHintClick} />
      </HStack>
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <PhotosField
          prefix={Prefix.AdditionalKeyStorage}
          values={value.photos}
          label=""
          onChange={(value) => onChange("photos", value)}
        />
      </Flex>
      <Flex w="100%">
        <TextArea value={value.note} disabled={isReadOnly} onChange={(value) => onChange("note", value)} />
      </Flex>
    </VStack>
  );
}
