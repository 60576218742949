import { Box, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { Card } from "@doorstead/components/Card";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useAddress } from "../../../hooks/applicants/useAddress";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useGoogleMapKey } from "../../../hooks/useGoogleMapKey";
import { padding, width } from "../../applicants/ApplicantOneSteps/config";
import { ApplicantPortalProvider } from "../../applicants/ApplicantPortalProvider";

function Content() {
  const isMobile = useIsMobile();
  const {
    applicationRequest: { property },
  } = useFetchedApplicant();
  const googleMapKey = useGoogleMapKey();
  const address = useAddress();
  const Layout = isMobile ? VStack : HStack;

  return (
    <Flex justifyContent="center" w="100%">
      <Card padding={padding} width={width} maxWidth="800px">
        <Layout w="100%" alignItems="flex-start" spacing={isMobile ? 10 : 5}>
          <Box w="fit-content" h="100%">
            <GoogleMapImage
              borderRadius={6}
              width={isMobile ? 322 : 240}
              height={isMobile ? 220 : 220}
              address={address}
              googleKey={googleMapKey}
            />
          </Box>
          <Flex padding={isMobile ? 0 : 5} w="100%" h="100%" alignItems="center">
            <VStack spacing={10} alignItems="flex-start" w="100%">
              <VStack alignItems="flex-start" w="100%">
                <Box fontSize="xl" fontWeight="bold" fontFamily="inter">
                  Lease start date link invalid for
                </Box>
                <Box fontSize="lg" fontWeight="bold" fontFamily="inter">
                  {property.fullAddress}
                </Box>
              </VStack>
              <Stack direction={isMobile ? "column" : "row"}>
                <Box fontSize="lg" fontWeight="normal" fontFamily="inter">
                  Contact
                </Box>
                <Text
                  as="a"
                  href="mailto:prospects@doorstead.com"
                  fontSize="lg"
                  fontWeight="bold"
                  textDecoration="underline"
                  color="purple.400"
                  fontFamily="inter"
                >
                  prospects@doorstead.com
                </Text>
                <Box fontSize="xl" fontWeight="normal" fontFamily="inter">
                  for help
                </Box>
              </Stack>
            </VStack>
          </Flex>
        </Layout>
      </Card>
    </Flex>
  );
}
export function LinkInvalidPage() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
