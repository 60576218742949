import { useArea } from "../../hooks/rentReady/useArea";
import { useIsThroughoutPropertyFeature } from "../../hooks/rentReady/useIsThroughoutPropertyFeature";

export function useIsAreaDetectorFeature() {
  const area = useArea();
  const isThroughoutPropertyFeature = useIsThroughoutPropertyFeature();
  if (area === null) return false;

  const isBedroomArea = area.name === "Bedroom";
  return isBedroomArea && isThroughoutPropertyFeature;
}
