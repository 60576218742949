import { useClearArea } from "../../../../hooks/rentReady/useClearArea";
import { useOpenClearDefectDrawer } from "../../../../hooks/rentReady/useOpenClearDefectDrawer";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { PurpleCircle } from "../../PurpleCircle";
import { Bar } from "./Bar";

export function AreaOneBox() {
  const openClearDefectDrawer = useOpenClearDefectDrawer();
  const { setView } = useView();
  const clearArea = useClearArea();

  return (
    <Bar
      minW=""
      title=""
      isShowAction
      onActionClick={() => {
        openClearDefectDrawer({
          async onConfirm() {
            await clearArea();
            setView(View.AreaListView);
          },
        });
      }}
    >
      <PurpleCircle name="Overview" onClick={() => setView(View.AreaOverviewView)} />
    </Bar>
  );
}
