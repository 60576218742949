import { Box } from "@chakra-ui/react";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";

type Props = {
  onClick: () => void;
};

export function BackIcon({ onClick }: Props) {
  return (
    <Box onClick={onClick}>
      <ArrowIcon color="black" size={2} direction={Direction.Left} />
    </Box>
  );
}
