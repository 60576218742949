import { OwnerDecision } from "./useFetchRRProject";
import { useGetToApproveCount } from "./useGetToApproveCount";
import { useGetToEstimatesCount } from "./useGetToEstimatesCount";

export function useNumberOfRejects(): number {
  const getToApproveCount = useGetToApproveCount();
  const getToEstimatesCount = useGetToEstimatesCount();

  return getToApproveCount(OwnerDecision.No) + getToEstimatesCount(false);
}
