import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { InspectionReportNode } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { mapPhotoUrlsJsonToPhotos } from "./mapPhotoUrlsJsonToPhotos";

export function mapInspectionReportNodeToThroughoutPropertyDetector(
  inspectionReport: InspectionReportNode
): ThroughoutPropertyDetector {
  return {
    detectorSmokePhotos: mapPhotoUrlsJsonToPhotos(inspectionReport.detectorSmokePhotoUrlsJson),
    detectorSmokeIsAtLeastOneOutsideBedrooms: inspectionReport.detectorSmokeIsAtLeastOneOutsideBedrooms,
    detectorSmokeIsAtLeastOneEachFloor: inspectionReport.detectorSmokeIsAtLeastOneEachFloor,
    detectorCmPhotos: mapPhotoUrlsJsonToPhotos(inspectionReport.detectorCmPhotoUrlsJson),
    detectorCmIsAtLeastOneCoOutsideBedrooms: inspectionReport.detectorCmIsAtLeastOneCoOutsideBedrooms,
    detectorCmIsAtLeastOneCoEachFloor: inspectionReport.detectorCmIsAtLeastOneCoEachFloor,
  };
}
