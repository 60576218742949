import { db } from "../../databases/FlexWalkthroughDatabase";
import { FlexWalkthroughItemNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useFlexWalkthroughReportId } from "./useFlexWalkthroughReportId";

type Args = {
  id: string;
  field: keyof FlexWalkthroughItemNode;
  value: any;
};

export function useUpdatePartialWalkthroughItemToCache() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();

  return async ({ id, field, value }: Args) => {
    return db.transaction("rw", db.flex_walkthrough_report_graphql_result, async () => {
      db.flex_walkthrough_report_graphql_result
        .where("flex_walkthrough_report_id")
        .equals(flexWalkthroughReportId)
        .modify((ignored, ref) => {
          const itemNode = ref.value.flexWalkthroughReport.items?.find((e) => e.itemDataId === id);
          if (itemNode) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            itemNode[field] = value;
          }
        });
    });
  };
}
