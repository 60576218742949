import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchInspectionReport } from "./useFetchInspectionReport";

type Args = {
  cannotEstimate: boolean;
  cannotEstimateReason: string;
  estimateNotes: string;
  estimatesDataId: string;
  highEstimateCostCents: number;
  lowEstimateCostCents: number;
};

export function useUpdateInspectionReportEstimatesData() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchInspectionReport();

  return useMutation(
    (args: Args) => {
      return gqlClient.mutate({
        mutation: gql`
          mutation ($estimates: [UpdateInspectionReportEstimatesDataInput]) {
            updateInspectionReportEstimatesData(estimates: $estimates) {
              count
            }
          }
        `,
        variables: {
          estimates: [args],
        },
      });
    },
    {
      async onSettled() {
        await refetch();
      },
    }
  );
}
