import { Box, Flex, Img, Text, useDisclosure } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { Photo as PhotoType } from "../../@types/RentReady/Photo";
import { Prefix } from "../../hooks/rentReady/useUpload";
import { PhotoEditorDialog } from "../rent-ready/views/PhotoEditorDialog";
import { Mode } from "../rent-ready/views/PhotoEditorDialog/Mode";

type Props = {
  index: number;
  url: string;
  isLast: boolean;
  remainingNumber: number;
  photos: PhotoType[];
};

function Image({ url, onClick }: { url: string; onClick?: () => void }) {
  return (
    <Img
      cursor="pointer"
      alt=""
      w="60px"
      h="60px"
      src={url}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    />
  );
}

function PhotoDialog({
  photos,
  isOpen,
  onClose,
  index,
}: {
  photos: PhotoType[];
  isOpen: boolean;
  onClose: () => void;
  index: number;
}) {
  const isMobile = useIsMobile();
  return (
    <PhotoEditorDialog
      index={index}
      size={isMobile ? "full" : "4xl"}
      hasBackButton={false}
      hasCloseButton
      mode={Mode.GALLERY}
      setMode={() => {
        console.log("setMode");
      }}
      prefix={Prefix.Items}
      photos={photos}
      setPhotos={() => {
        console.log("setPhotos");
      }}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

export function Photo({ url, isLast, remainingNumber, photos, index }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isLast && remainingNumber > 0) {
    return (
      <>
        <PhotoDialog index={index} photos={photos} isOpen={isOpen} onClose={onClose} />
        <Box cursor="pointer" position="relative" onClick={onOpen}>
          <Image url={url} />
          <Flex
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.6)"
            justifyContent="center"
            alignItems="center"
          >
            <Text color="white" fontSize="24px">
              +{remainingNumber}
            </Text>
          </Flex>
        </Box>
      </>
    );
  }

  return (
    <>
      <PhotoDialog index={index} photos={photos} isOpen={isOpen} onClose={onClose} />
      <Image url={url} onClick={onOpen} />
    </>
  );
}
