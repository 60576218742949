import { Estimate } from "../../@types/RentReady/Estimate";
import { sortByField } from "../../utils/rentReady/sortByField";
import { mapEstimateNodesToEstimates } from "./useAreasCache/mapEstimateNodesToEstimates";
import { useInspectionReport } from "./useInspectionReport";

export function useEstimates(): Estimate[] {
  const { estimatesData } = useInspectionReport();
  if (estimatesData === null) {
    return [];
  }

  return sortByField(mapEstimateNodesToEstimates(estimatesData), "uiName");
}
