import { Flex } from "@chakra-ui/react";

export function ApplicantAuthWhiteBox({ children }: { children: React.ReactNode }) {
  return (
    <Flex justifyContent="center" alignItems="center" w="100%">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        w={430}
        h={450}
        borderRadius={20}
        padding={10}
      >
        {children}
      </Flex>
    </Flex>
  );
}
