import { Box, VStack } from "@chakra-ui/react";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { DateField } from "@doorstead/components/fields";
import { Status } from "@doorstead/components/fields/DateField";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { addBusinessDays, addDays } from "date-fns/esm";
import React, { useState } from "react";
import { toPrefixZero } from "src/components/applicants/ApplicantDateField/toPrefixZero";
import { useAddMoveInDateApplicationRequest } from "src/hooks/applicants/useAddMoveInDateApplicationRequest";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useIsTPO } from "src/hooks/applicants/useIsTPO";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { getYearMonthAndDateFromDateString } from "src/utils/getYearMonthAndDateFromDateString";
import { useApplicantAnalytics } from "../../../../../../hooks/applicants/useApplicantAnalytics";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
};

export const LeaseStartDateCard = React.forwardRef(({ padding, width, ...props }: Props, ref) => {
  const isMobile = useIsMobile();
  const applicantAnalytics = useApplicantAnalytics();
  const scrollIntoView = useScrollIntoView();
  const { mutate: addMoveInDate, isLoading: isAddingMoveInDate } = useAddMoveInDateApplicationRequest();
  const {
    applicationRequest: {
      negotiatedMoveInDate,
      property: { timezone },
    },
  } = useFetchedApplicant();
  const isTPO = useIsTPO();
  const today = new Date();
  const enabledFromDate = addBusinessDays(today, 5);
  const enabledToDate = isTPO ? addDays(enabledFromDate, 60) : addDays(enabledFromDate, 10);
  const negotiatedMoveInDateInTargetTimezone = negotiatedMoveInDate
    ? new Date(`${negotiatedMoveInDate}T00:00:00.000`).toLocaleString("en-US", {
        timeZone: timezone,
      })
    : null;
  const dateToShow = negotiatedMoveInDateInTargetTimezone ? new Date(negotiatedMoveInDateInTargetTimezone) : null;
  const formattedDate = dateToShow
    ? `${dateToShow.getFullYear()}-${String(dateToShow.getMonth() + 1).padStart(2, "0")}-${String(
        dateToShow.getDate()
      ).padStart(2, "0")}`
    : null;

  const [selectedDate, setSelectedDate] = useState<string | null>(formattedDate);

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <Box width="100%" fontSize="2xl" fontWeight={600} textAlign="left">
          Select lease start date
        </Box>
        <GrayText fontSize="medium">Applications with earlier lease start date receive priority</GrayText>
        <Box w="100%">
          <DateField
            id="lease-start-date"
            label="Lease start date"
            status={!selectedDate ? Status.Error : (getTextRequiredStatus(selectedDate) as Status)}
            enabledFromDate={{
              year: enabledFromDate.getFullYear(),
              month: enabledFromDate.getMonth() + 1,
              date: enabledFromDate.getDate(),
            }}
            enabledToDate={{
              year: enabledToDate.getFullYear(),
              month: enabledToDate.getMonth() + 1,
              date: enabledToDate.getDate(),
            }}
            value={selectedDate ? getYearMonthAndDateFromDateString(selectedDate) : null}
            onChange={async (value) => {
              if (value !== null) {
                const selectedDate = `${value.year}-${toPrefixZero(value.month)}-${toPrefixZero(value.date)}`;
                setSelectedDate(selectedDate);
                addMoveInDate({
                  negotiatedMoveInDate: selectedDate,
                });
              }
            }}
          />
        </Box>
        <PrimaryButton
          isLoading={isAddingMoveInDate}
          isFullWidth={isMobile}
          onClick={async () => {
            if (selectedDate) {
              applicantAnalytics?.track("TP_LEASE_START_DATE_CONTINUED");
              addMoveInDate(
                {
                  negotiatedMoveInDate: selectedDate,
                },
                {
                  onSuccess: () => {
                    scrollIntoView("/3/children/2");
                  },
                }
              );
            }
          }}
        >
          Continue
        </PrimaryButton>
      </VStack>
    </Card>
  );
});
