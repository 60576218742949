import { usePageView } from "src/hooks/usePageView";
import { usePaymentMethods } from "src/hooks/usePaymentMethods";
import { StepCard } from "../../StepCard";
import { AddFirstPaymentMethodCard } from "./AddFirstPaymentMethodCard";
import { AddMorePaymentMethodsCard } from "./AddMorePaymentMethodsCard";

function Content() {
  const paymentMethods = usePaymentMethods();
  usePageView("TP_OB_PAYMENT_METHOD_VIEWED");

  if (paymentMethods.length === 0) {
    return <AddFirstPaymentMethodCard />;
  } else {
    return <AddMorePaymentMethodsCard />;
  }
}

export function PaymentMethodStep() {
  return (
    <StepCard title="Add Payment method">
      <Content />
    </StepCard>
  );
}
