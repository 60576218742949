import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";

export enum AccountType {
  CHECKING = "Checking",
  SAVINGS = "Savings",
}

export enum TaxpayerType {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
}

const MUTATION = gql`
  mutation createVendorCounterparty(
    $vendorId: UUID!
    $w9Entity: String!
    $taxpayerType: String!
    $taxpayerIdentifier: String!
    $bankAccountNumber: String!
    $bankRoutingNumber: String!
    $bankAccountType: String!
  ) {
    createVendorCounterparty(
      vendorId: $vendorId
      w9Entity: $w9Entity
      taxpayerType: $taxpayerType
      taxpayerIdentifier: $taxpayerIdentifier
      bankAccountNumber: $bankAccountNumber
      bankRoutingNumber: $bankRoutingNumber
      bankAccountType: $bankAccountType
    ) {
      counterpartyId
      mtCounterpartyId
      mtExternalAccountId
    }
  }
`;

export function useCreateVendorCounterparty() {
  const { gqlClient } = useAuth();
  const mutation = useMutation(
    ({
      vendorId,
      w9Entity,
      taxpayerType,
      taxpayerIdentifier,
      bankAccountNumber,
      bankRoutingNumber,
      bankAccountType,
    }: {
      vendorId: string;
      w9Entity: string;
      taxpayerType: TaxpayerType;
      taxpayerIdentifier: string;
      bankAccountNumber: string;
      bankRoutingNumber: string;
      bankAccountType: AccountType;
    }) =>
      gqlClient.mutate({
        mutation: MUTATION,
        variables: {
          vendorId,
          w9Entity,
          taxpayerType,
          taxpayerIdentifier,
          bankAccountNumber,
          bankRoutingNumber,
          bankAccountType,
        },
      })
  );

  return {
    createVendorCounterparty: mutation.mutate,
    loading: mutation.isLoading,
  };
}
