import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { AreaNode } from "./useFetchInspectionReport/AreaNode";

export type Args = {
  areaDataId: string;
  photoUrlsJson: string | null;
  selectedCleaningOptions: string[];
  cleaningNotes: string;
  selectedPaintingOptions: string[];
  paintingNotes: string;
  selectedItemRemoval: boolean;
  selectedOdors: boolean;
  itemRemovalNotes: string;
  itemRemovalPhotoUrlsJson: string | null;
  notes: string | null;
  smokeDetectorIsOneWorkingDetectorFound: boolean;
  smokeDetectorNotes: AreaNode["areaSmokeDetectorNotes"];
  smokeDetectorStatus: AreaNode["areaSmokeDetectorStatus"];
  smokeDetectorPhotoUrlsJson: AreaNode["areaSmokeDetectorPhotoUrlsJson"];
  areaDataUpdatedAt: string | null;
};

type Result = {
  data: {
    updateInspectionReportArea: {
      success: boolean;
      merged: boolean;
      areaData: {
        areaDataId: AreaNode["areaDataId"];
        areaNotes: AreaNode["areaNotes"];
        areaSelectedCleaningOptions: AreaNode["areaSelectedCleaningOptions"];
        areaCleaningNotes: AreaNode["areaCleaningNotes"];
        areaSelectedPaintingOptions: AreaNode["areaSelectedPaintingOptions"];
        areaPaintingNotes: AreaNode["areaPaintingNotes"];
        areaSelectedItemRemoval: AreaNode["areaSelectedItemRemoval"];
        areaSelectedOdors: AreaNode["areaSelectedOdors"];
        areaPhotoUrlsJson: AreaNode["areaPhotoUrlsJson"];
        areaItemRemovalNotes: AreaNode["areaItemRemovalNotes"];
        areaItemRemovalPhotoUrlsJson: AreaNode["areaItemRemovalPhotoUrlsJson"];
        areaSmokeDetectorIsOneWorkingDetectorFound: AreaNode["areaSmokeDetectorIsOneWorkingDetectorFound"];
        areaSmokeDetectorNotes: AreaNode["areaSmokeDetectorNotes"];
        areaSmokeDetectorStatus: AreaNode["areaSmokeDetectorStatus"];
        areaSmokeDetectorPhotoUrlsJson: AreaNode["areaSmokeDetectorPhotoUrlsJson"];
        areaDataUpdatedAt: AreaNode["areaDataUpdatedAt"];
      };
    };
  };
};

export function useUpdateAreaToServer() {
  const { gqlClient } = useAuth();

  return async ({
    areaDataId,
    photoUrlsJson,
    notes,
    selectedCleaningOptions,
    cleaningNotes,
    selectedPaintingOptions,
    paintingNotes,
    selectedItemRemoval,
    selectedOdors,
    itemRemovalNotes,
    itemRemovalPhotoUrlsJson,
    smokeDetectorIsOneWorkingDetectorFound,
    smokeDetectorNotes,
    smokeDetectorStatus,
    smokeDetectorPhotoUrlsJson,
    areaDataUpdatedAt,
  }: Args) => {
    const variables: Record<string, any> = {
      areaDataId,
      photoUrlsJson,
      notes,
      selectedCleaningOptions,
      cleaningNotes,
      selectedPaintingOptions,
      paintingNotes,
      selectedItemRemoval,
      selectedOdors,
      itemRemovalNotes,
      itemRemovalPhotoUrlsJson,
      smokeDetectorNotes,
      smokeDetectorIsOneWorkingDetectorFound,
      smokeDetectorStatus,
      smokeDetectorPhotoUrlsJson,
      areaDataUpdatedAt,
    };

    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $areaDataId: UUID!
          $cleaningNotes: String
          $paintingNotes: String
          $photoUrlsJson: String
          $selectedCleaningOptions: [String]
          $selectedPaintingOptions: [String]
          $selectedItemRemoval: Boolean
          $selectedOdors: Boolean
          $itemRemovalNotes: String
          $itemRemovalPhotoUrlsJson: String
          $areaDataUpdatedAt: DateTime
          $smokeDetectorNotes: String
          $notes: String
          $smokeDetectorIsOneWorkingDetectorFound: Boolean
          $smokeDetectorStatus: String
          $smokeDetectorPhotoUrlsJson: String
        ) {
          updateInspectionReportArea(
            areaDataId: $areaDataId
            notes: $notes
            cleaningNotes: $cleaningNotes
            paintingNotes: $paintingNotes
            photoUrlsJson: $photoUrlsJson
            selectedCleaningOptions: $selectedCleaningOptions
            selectedPaintingOptions: $selectedPaintingOptions
            selectedItemRemoval: $selectedItemRemoval
            selectedOdors: $selectedOdors
            itemRemovalNotes: $itemRemovalNotes
            itemRemovalPhotoUrlsJson: $itemRemovalPhotoUrlsJson
            areaDataUpdatedAt: $areaDataUpdatedAt
            smokeDetectorNotes: $smokeDetectorNotes
            smokeDetectorIsOneWorkingDetectorFound: $smokeDetectorIsOneWorkingDetectorFound
            smokeDetectorStatus: $smokeDetectorStatus
            smokeDetectorPhotoUrlsJson: $smokeDetectorPhotoUrlsJson
          ) {
            success
            merged
            areaData {
              areaDataId
              areaNotes
              areaSelectedCleaningOptions
              areaCleaningNotes
              areaSelectedPaintingOptions
              areaPaintingNotes
              areaSelectedItemRemoval
              areaSelectedOdors
              areaPhotoUrlsJson
              areaItemRemovalNotes
              areaItemRemovalPhotoUrlsJson
              areaSmokeDetectorIsOneWorkingDetectorFound
              areaSmokeDetectorNotes
              areaSmokeDetectorStatus
              areaSmokeDetectorPhotoUrlsJson
              areaDataUpdatedAt
            }
          }
        }
      `,
      variables,
    })) as Result;
  };
}
