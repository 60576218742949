import { Box, Flex, StackProps, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { getLabel } from "../../../@types/AutoPay/getLabel";
import { useAutoPay } from "../../../hooks/useAutoPay";
import { getAutopayNextDateLabel } from "../../../utils/getAutopayNextDateLabel";
import { getPaymentMethodLabel } from "../../../utils/getPaymentMethodLabel";
import { MonthlyChargesHintBox } from "../../MonthlyChargesHintBox";
import { EditValue } from "./typings";
import { useTrackAutopayEditClicked } from "./useTrackAutopayEditClicked";

type Props = StackProps & {
  onOpen: () => void;
  setValue: (value: EditValue | null) => void;
};

function LeftRightBox({ left, right }: { left: string; right: string }) {
  return (
    <Flex w="100%" justifyContent="space-between">
      <Box color="gray.500" fontWeight="bold">
        {left}
      </Box>
      <Box textAlign="right">{right}</Box>
    </Flex>
  );
}

export function MobileView({ onOpen, setValue, ...stackProps }: Props) {
  const trackAutopayEditClicked = useTrackAutopayEditClicked();
  const autoPay = useAutoPay();

  if (autoPay === null) {
    return null;
  }
  const paymentMethodId = autoPay.paymentMethod.paymentMethodId;
  const paymentMethod = getPaymentMethodLabel({
    type: autoPay.paymentMethod.type_,
    bankAccountName: autoPay.paymentMethod.bankAccountName,
    bankAccountMask: autoPay.paymentMethod.bankAccountMask,
    cardBrand: autoPay.paymentMethod.cardBrand,
    cardLastFourDigits: autoPay.paymentMethod.cardLastFourDigits,
  });

  return (
    <VStack {...stackProps} spacing={5}>
      <MonthlyChargesHintBox />
      <LeftRightBox left="Autopay account" right={paymentMethod} />
      <LeftRightBox left="Recurring date" right={getLabel(autoPay.date)} />
      <LeftRightBox left="Next pay date" right={getAutopayNextDateLabel(autoPay.nextPaymentDate)} />
      <OutlineButton
        isFullWidth
        onClick={() => {
          trackAutopayEditClicked({
            autopayId: autoPay.autopayId,
            paymentMethodId,
          });
          setValue({
            id: autoPay.autopayId,
            paymentMethodId,
            payOn: autoPay.date,
            nextScheduleDate: autoPay.nextPaymentDate,
          });
          onOpen();
        }}
      >
        Edit
      </OutlineButton>
    </VStack>
  );
}
