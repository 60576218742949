import { Box, Divider, Flex, HStack, VStack } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { TitleRow } from "src/components/rent-ready/TitleRow";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFlexWalkthroughReport";
import { useSyncSingleWalkthroughItemFieldToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncSingleWalkthroughItemFieldToCacheAndServer";
import { mapPhotoUrlsJsonToPhotos } from "src/utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { InstructionIcon } from "./InstructionIcon";
import { ReportAutoSyncPhotosField } from "./ReportAutoSyncPhotosField";
import { SafetyRow } from "./SafetyRow";

export function useUpdateItemStatus() {
  const syncSingleItemFieldToCacheAndServer = useSyncSingleWalkthroughItemFieldToCacheAndServer();

  return useMutation(({ item, response }: { item: FlexWalkthroughItemNode; response: string }) => {
    return syncSingleItemFieldToCacheAndServer(item, "response", "response", response, response);
  });
}

export function FlexSafetySection() {
  const { smokeDetectorPhotoUrlsJson, coDetectorPhotoUrlsJson } = useFlexWalkthroughReport();
  let smokeDetectorPhotos: Photo[] = [];
  let coPhotos: Photo[] = [];

  if (smokeDetectorPhotoUrlsJson) {
    smokeDetectorPhotos = mapPhotoUrlsJsonToPhotos(smokeDetectorPhotoUrlsJson);
  }

  if (coDetectorPhotoUrlsJson) {
    coPhotos = mapPhotoUrlsJsonToPhotos(coDetectorPhotoUrlsJson);
  }

  return (
    <Box flex={1}>
      <TitleRow text="Safety" />
      <VStack w="100%" spacing={5}>
        <Flex flexDirection="column" w="100%">
          <VStack spacing={1}>
            <VStack w="100%" p={4}>
              <Flex w="100%" justifyContent="space-between" flex={1} fontSize="md">
                <HStack>
                  <Box>Smoke detectors</Box>
                  <InstructionIcon text={"Please take photos of all smoke detectors found in the house."} />
                </HStack>
              </Flex>
              <Flex display={"flex"} justifyContent="space-between" alignItems="center" w="100%" p={2}>
                <Box>
                  <ReportAutoSyncPhotosField
                    prefix={`${Prefix.FlexWalkthroughReport}_smokeDetectorPhotoUrlsJson` as Prefix}
                    values={smokeDetectorPhotos}
                    isValid={smokeDetectorPhotos.length > 0}
                    cacheField="smokeDetectorPhotoUrlsJson"
                    serverField="smokeDetectorPhotoUrlsJson"
                  />
                </Box>
              </Flex>
              <SafetyRow
                text="1 inside each bedroom"
                tooltip="There is at least one functioning smoke detector inside each bedroom. Note any missing or not functional ones."
                field="smokeDetectorIsAtLeastOneInsideEachBedroom"
                notesField="smokeDetectorIsAtLeastOneInsideEachBedroomNotes"
              />
              <SafetyRow
                text="1 outside of bedrooms"
                tooltip="There is at least one functioning smoke detector outside of the bedrooms. It should be located within 6ft of the bedrooms, usually in the hallway."
                field="smokeDetectorIsAtLeastOneOutsideOfBedrooms"
                notesField="smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes"
              />
              <SafetyRow
                text="1 on each floor"
                tooltip="There is at least one functioning smoke detector on each floor.  This can include ones inside or outside the bedrooms."
                field="smokeDetectorIsAtLeastOneEachFloor"
                notesField="smokeDetectorIsAtLeastOneEachFloorNotes"
              />
              <Divider />
              <Flex w="100%" justifyContent="space-between">
                <HStack>
                  <Box>Carbon monoxide monitor</Box>
                  <InstructionIcon text={"Please take photos of all carbon monoxide monitors found in the house."} />
                </HStack>
              </Flex>
              <Flex display={"flex"} justifyContent="space-between" alignItems="center" w="100%" p={2}>
                <Box>
                  <ReportAutoSyncPhotosField
                    prefix={`${Prefix.FlexWalkthroughReport}coDetectorPhotoUrlsJson` as Prefix}
                    values={coPhotos}
                    isValid={coPhotos.length > 0}
                    cacheField="coDetectorPhotoUrlsJson"
                    serverField="coDetectorPhotoUrlsJson"
                  />
                </Box>
              </Flex>
              <SafetyRow
                text="1 outside of bedrooms"
                tooltip="There is one functioning carbon monoxide outside of the bedrooms. It should be located within 6ft of the bedrooms, usually in the hallway."
                field="coDetectorIsAtLeastOneOutsideOfBedrooms"
                notesField="coDetectorIsAtLeastOneOutsideOfBedroomsNotes"
              />
              <SafetyRow
                text="1 on each floor"
                tooltip="There is at least one functioning carbon monoxide monitor on each floor."
                field="coDetectorIsAtLeastOneEachFloor"
                notesField="coDetectorIsAtLeastOneEachFloorNotes"
              />
            </VStack>
          </VStack>
        </Flex>
      </VStack>
    </Box>
  );
}
