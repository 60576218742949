import { gql } from "@apollo/client";
import { useAuth } from "../contexts/auth";

export enum Bucket {
  Applicant = "Applicant",
  RentReadyApp = "RentReadyApp",
}

type Args = {
  bucket: Bucket;
  fileName: string;
  prefix: string;
};

type Return = {
  bucket: Bucket;
  key: string;
  url: string;
};

export function useGenerateS3UploadUrl() {
  const { gqlClient } = useAuth();
  return async ({ bucket, fileName, prefix }: Args): Promise<Return> => {
    const res = await gqlClient.mutate({
      mutation: gql`
        mutation ($bucket: Bucket, $fileName: String, $prefix: String) {
          generateS3UploadUrl(bucket: $bucket, fileName: $fileName, prefix: $prefix) {
            bucket
            key
            url
          }
        }
      `,
      variables: {
        fileName,
        prefix,
        bucket,
      },
    });

    return res.data.generateS3UploadUrl as Return;
  };
}
