import { ApplicantLeaseNegotiationStartDatePage } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage";
import { ApplicantLeaseStartDatePage } from "src/components/applicants/ApplicantLeaseStartDatePage";
import { useIsNegotiated } from "src/hooks/applicants/useIsNegotiated";
import { useLeaseNegotiationEnabled } from "src/hooks/applicants/useLeaseNegotiationEnabled";
import { useRequireNegotiation } from "src/hooks/applicants/useRequireNegotiation";
import { useUseApplicationFlowV2 } from "src/hooks/applicants/useUseApplicationFlowV2";
import { Error } from "../../components/Error";
import { ApplicantLandingCard } from "../../components/applicants/ApplicantLandingCard";
import { ApplicantLandingCardV2 } from "../../components/applicants/ApplicantLandingCardV2";
import { ApplicantOneSteps } from "../../components/applicants/ApplicantOneSteps";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";
import { useAuth } from "../../contexts/auth";
import { useApplicantAnalytics } from "../../hooks/applicants/useApplicantAnalytics";
import { useApplicantId } from "../../hooks/applicants/useApplicantId";
import { useFetchedApplicant } from "../../hooks/applicants/useFetchedApplicant";
import { useIsPrimaryApplicant } from "../../hooks/applicants/useIsPrimaryApplicant";

function Content() {
  const applicantAnalytics = useApplicantAnalytics();
  const { user, loading } = useAuth();
  const applicantNode = useFetchedApplicant();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const requireNegotiation = useRequireNegotiation();
  const isNegotiated = useIsNegotiated();
  const leaseNegotiationEnabled = useLeaseNegotiationEnabled();
  const isLogin = !!user;
  const applicantId = useApplicantId();
  const useApplicationFlowV2 = useUseApplicationFlowV2();

  if (loading) {
    return null;
  } else if (applicantNode.coverPageGetStartedAt === null) {
    return <ApplicantLandingCardV2 />;
  }

  if (useApplicationFlowV2) {
    if (!isLogin) {
      const applicantUserEmail = applicantNode.doorsteadUser.email;

      if (applicantNode.doorsteadUser.status === "active") {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("email", applicantUserEmail);
        urlSearchParams.append("applicantId", applicantId);
        window.location.href = `/application/${applicantId}/login?${urlSearchParams.toString()}`;
      } else {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("email", applicantUserEmail);
        window.location.href = `/application/${applicantId}/signup?${urlSearchParams.toString()}`;
      }

      return null;
    } else if (
      user &&
      applicantNode.doorsteadUser.doorsteadUserId !== user.doorsteadUserId &&
      !user.email.endsWith("@doorstead.com")
    ) {
      applicantAnalytics?.track("TP_APPLICANT_UNMATCHED");
      // If not login, show the onboarding card
      return <Error title="Sorry, this application doesn't belong to you." errorType="incorrectUser" />;
    } else {
      return <ApplicantOneSteps />;
    }
  } else {
    if (isPrimaryApplicant && !isNegotiated) {
      // Lease Negotiation if enabled, else show Add move-in date page
      if (leaseNegotiationEnabled && requireNegotiation) {
        return <ApplicantLeaseNegotiationStartDatePage />;
      }
      return <ApplicantLeaseStartDatePage />;
    } else if (!isLogin) {
      return <ApplicantLandingCard />;
    } else if (
      user &&
      applicantNode.doorsteadUser.doorsteadUserId !== user.doorsteadUserId &&
      !user.email.endsWith("@doorstead.com")
    ) {
      applicantAnalytics?.track("TP_APPLICANT_UNMATCHED");
      // If not login, show the onboarding card
      return <Error title="Sorry, this application doesn't belong to you." errorType="incorrectUser" />;
    } else {
      return <ApplicantOneSteps />;
    }
  }
}

export default function ApplicationOneRoute() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
