import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../contexts/auth";
import { MeResult, ReceivableLedgerEntryHistory, queryKey as useFetchMeQueryKey } from "./useFetchMe";

type Result = {
  me: {
    tenant: {
      activeLease: {
        tenantGroup: {
          receivableLedgerEntryHistory: ReceivableLedgerEntryHistory[];
        };
      };
    };
  };
};

const queryKey = "useRefetchReceivableLedgerEntryHistory";
const query = gql`
  query refetchReceivableLedgerEntryHistory {
    me {
      tenant {
        activeLease {
          tenantGroup {
            receivableLedgerEntryHistory {
              ledgerTransactionId
              resultingAvailableAccountBalanceInCents
              amountInCents
              direction
              ledgerTransaction {
                effectiveDate
                status
              }
            }
          }
        }
      }
    }
  }
`;

export function useRefetchReceivableLedgerEntryHistory() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: refetchReceivableLedgerEntryHistory } = useMutation<Result>(
    queryKey,
    () => gqlClient.query({ query }).then((e) => e.data),
    {
      onSuccess(result) {
        queryClient.setQueryData(
          useFetchMeQueryKey,
          produce(queryClient.getQueryData(useFetchMeQueryKey), (draft: MeResult) => {
            draft.me.tenant.activeLease.tenantGroup.receivableLedgerEntryHistory =
              result.me.tenant.activeLease.tenantGroup.receivableLedgerEntryHistory;
          })
        );
      },
    }
  );

  return {
    refetchReceivableLedgerEntryHistory,
    isLoading,
  };
}
