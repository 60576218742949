type Args = {
  dataUrl: string;
  fileName: string;
};

export async function dataUrlToThumbnailFile({ dataUrl, fileName }: Args): Promise<File> {
  const canvas = document.createElement("canvas");
  canvas.width = 75;
  canvas.height = 75;

  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Canvas context could not be created.");
  }

  const image = new Image();
  image.src = dataUrl;

  return new Promise((resolve) => {
    image.onload = () => {
      context.drawImage(image, 0, 0, 75, 75);
      canvas.toBlob((blob) => {
        const file = new File([blob!], fileName, { type: "image/png" });
        resolve(file);
      }, "image/png");
    };
  });
}
