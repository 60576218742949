import { Box, Flex } from "@chakra-ui/react";
import { GrayText } from "@doorstead/components/GrayText";
import { Progress } from "@doorstead/components/Progress";
import { CrossIcon } from "@doorstead/components/icons/CrossIcon";
import { CancelTokenSource } from "axios";
import produce from "immer";
import { useInternalState } from "./useInternalState";

type Props = {
  id: string;
  fileName: string;
  progress: number;
  cancelTokenSource: CancelTokenSource | null;
};

export function UploadingView({ id, fileName, progress, cancelTokenSource }: Props) {
  const { setUploadingFilesMap } = useInternalState();

  return (
    <>
      <Flex justifyContent="space-between" w="100%">
        <GrayText w="100%">{fileName}</GrayText>
        {cancelTokenSource && (
          <CrossIcon
            onClick={() => {
              cancelTokenSource.cancel("cancel!");
              setUploadingFilesMap((prev) => {
                return produce(prev, (draft) => {
                  delete draft[id];
                });
              });
            }}
          />
        )}
      </Flex>
      <Box w="100%">
        <Progress value={progress} />
      </Box>
    </>
  );
}
