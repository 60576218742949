import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Args = {
  areaIds: string[];
};

export function useRemoveAreas() {
  const { gqlClient } = useAuth();

  return ({ areaIds }: Args) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($areaDataIds: [UUID]!) {
          deleteInspectionReportAreas(areaDataIds: $areaDataIds) {
            count
          }
        }
      `,
      variables: {
        areaDataIds: areaIds,
      },
    });
  };
}
