import { Item } from "../../@types/RentReady/Item";
import { isThroughoutPropertyItemRequirePhotos } from "./isThroughoutPropertyItemRequirePhotos";

export function isThroughoutPropertyItemPhotosValid(item: Item, shouldUseSimplifiedTemplate: boolean) {
  const isPhotoRequired = isThroughoutPropertyItemRequirePhotos({
    name: item.name,
    status: item.status,
    shouldUseSimplifiedTemplate,
  });

  if (!isPhotoRequired) {
    return true;
  }

  return item.photos.length > 0;
}
