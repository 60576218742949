import { ReactNode } from "react";
import { Row } from "./Row";

type Props = {
  text: string;
  right?: ReactNode;
};
export function TitleRow({ text, right }: Props) {
  return <Row bgColor="#515D6E" left={text} color="white" right={right} />;
}
