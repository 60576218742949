import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import React, { FC, PropsWithChildren, createContext, useEffect, useState } from "react";

const AnalyticsContext = createContext<Analytics | null>({} as any);

interface Props extends PropsWithChildren {
  segmentWriteKey: string;
}

const AnalyticsProvider: FC<Props> = ({ segmentWriteKey, children }) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: segmentWriteKey });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [segmentWriteKey]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

const useAnalytics = () => React.useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalytics };
