import produce from "immer";
import { Dispatch, SetStateAction } from "react";
import { Image, Photo } from "../../@types/RentReady/Photo";
import { fileToDataUrl } from "../../utils/fileToDataUrl";
import { getUuid } from "../../utils/getUuid";
import { compressImageFile } from "../../utils/rentReady/compressImageFile";

type Args = {
  files: File[];
  setValues: Dispatch<SetStateAction<Photo[]>>;
};

export function useOnSelectFiles() {
  return async ({ files, setValues }: Args) => {
    const newPhotos: Photo[] = [];
    for (const file of files) {
      const compressedFile = await compressImageFile(file);
      const dataUrl = await fileToDataUrl(compressedFile);
      if (dataUrl !== null) {
        const image: Image = {
          bucket: "",
          key: "",
          fileName: "",
          url: "",
        };

        newPhotos.push({
          id: getUuid(),
          src: image,
          thumbnail: image,
          isUploaded: false,
          toUploadFile: {
            dataUrl,
          },
        });
      }
    }

    setValues(
      produce((draft) => {
        return draft.concat(newPhotos);
      })
    );
  };
}
