import { usePageView } from "src/hooks/usePageView";
import { useIsAutoPaySetup } from "../../../hooks/useIsAutoPaySetup";
import { ManageAutoPay } from "./ManageAutoPay";
import { NoData } from "./NoDataBox";

export function EverGreenAutoPay() {
  const isAutoPaySetup = useIsAutoPaySetup();
  usePageView("TP_AUTOPAY_VIEWED");

  if (isAutoPaySetup) {
    return <ManageAutoPay />;
  } else {
    return <NoData />;
  }
}
