import produce from "immer";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { useAreaId } from "./useAreaId";
import { useItemId } from "./useItemId";
import { ComponentUpdateState } from "./useUpdateComponentCache";

export function useMapComponentStateFromRRGraphQLResultCache() {
  const { areaId } = useAreaId();
  const { itemId } = useItemId();

  return (result: RRGraphQLResultCache, state: ComponentUpdateState): RRGraphQLResultCache => {
    return produce(result, (draft) => {
      const areaCache = draft.inspectionReport.data?.find((e) => e.areaDataId === areaId);
      if (areaCache) {
        const item = areaCache.itemsData?.find((e) => e.itemDataId === itemId);
        if (item) {
          const component = item.componentsData?.find((e) => e.componentDataId === state.id);
          if (component) {
            const types = state.types.filter((e) => e.selected).map((e) => e.id);
            const selectedReplace = state.isRepair === false;
            const selectedComponentOptions = state.options.filter((e) => e.selected).map((e) => e.id);
            const selectedRepair = state.isRepair === true;
            const selectedRepairOptions = selectedRepair ? types : [];
            const selectedReplaceOptions = selectedReplace ? types : [];

            component.componentNotes = state.note;
            component.componentSelectedComponentOptions = selectedComponentOptions;
            component.componentSelectedRepair = selectedRepair;
            component.componentSelectedRepairOptions = selectedRepairOptions;
            component.componentSelectedReplace = selectedReplace;
            component.componentSelectedReplaceOptions = selectedReplaceOptions;
            component.componentPhotoUrlsJson = mapPhotosToPhotoUrlsJson(state.photos);
          }
        }
      }
    });
  };
}
