import produce from "immer";
import { Item } from "../../@types/RentReady/Item";
import { arrayToRecord } from "../../utils/arrayToRecord";
import { isOffline } from "../../utils/isOffline";
import { isItemDiff } from "../../utils/rentReady/isItemDiff";
import { mapItemToArgs, useUpdateItemToServer } from "./useUpdateItemToServer";

export function useUpdateItemsToServer() {
  const updateItemToServer = useUpdateItemToServer();

  return async (itemsCache: Item[], itemsSever: Item[]): Promise<Item[]> => {
    if (isOffline() || itemsCache.length === 0) return itemsCache;
    else {
      itemsCache = produce(itemsCache, (draft) => {
        return draft;
      });
      //make map[id: Item]
      const itemsServerMap = arrayToRecord(itemsSever, "id");

      for (const itemCache of itemsCache) {
        //1. update to server if updated
        if (isItemDiff(itemCache, itemsServerMap[itemCache.id])) {
          //update area
          await updateItemToServer(mapItemToArgs(itemCache));
        }
      }

      return itemsCache;
    }
  };
}
