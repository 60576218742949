import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
  areaId: string;
  setAreaId: (areaId: string) => void;
};

const context = createContext<Props>({
  areaId: "",
  setAreaId(areaId) {
    console.log(areaId);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [areaId, setAreaId] = useState("");

  return (
    <context.Provider
      value={{
        areaId,
        setAreaId,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useAreaId() {
  return useContext(context);
}
