import { auth0Config } from "../../config";
import { useApplicantWebAuth } from "./useApplicantWebAuth";

export function useApplicantLoginWithPassword(applicantId: string) {
  const { dbConnection } = auth0Config();
  const webAuth = useApplicantWebAuth(applicantId);

  return ({ email, password }: { email: string; password: string }) => {
    return new Promise<void>((resolve, reject) => {
      webAuth.login(
        {
          email,
          password,
          realm: dbConnection,
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  };
}
