import { Photo } from "src/@types/RentReady/Photo";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { mapPhotoUrlsJsonToPhotos } from "./mapPhotoUrlsJsonToPhotos";

export function isWalkthroughItemComplete(item: FlexWalkthroughItemNode): boolean {
  if (item.response === item.positiveActionText) return true;

  let photos: Photo[] = [];

  if (item.photoUrlsJson) {
    photos = mapPhotoUrlsJsonToPhotos(item.photoUrlsJson);
  }

  if (
    item.response === item.negativeActionText &&
    (!item.photosRequired || (item.photosRequired && photos.length > 0)) &&
    (!item.notesRequired || (item.notesRequired && item.notes && item.notes?.trim() !== ""))
  )
    return true;

  return false;
}
