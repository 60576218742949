import { useParams, useSearchParams } from "react-router-dom";

export function useApplicantId(): string {
  const [searchParams] = useSearchParams();
  const { id: applicant_id } = useParams();
  if (searchParams.has("applicantId")) {
    return searchParams.get("applicantId") || "";
  }

  return applicant_id || "";
}
