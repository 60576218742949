import { Box, Divider, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { useArea } from "../../../../../hooks/rentReady/useArea";
import { useIsAreaKitchen } from "../../../../../hooks/rentReady/useIsAreaKitchen";
import { useIsAreaLaundry } from "../../../../../hooks/rentReady/useIsAreaLaundry";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { isAreaShowingHeatSource } from "../../../../../utils/rentReady/isAreaShowingHeatSource";
import { isAreaShowingSmokeDetector } from "../../../../../utils/rentReady/isAreaShowingSmokeDetector";
import { ArrowOpenIcon } from "../../../../ArrowOpenIcon";
import { RequiredLabel } from "../../../RequiredLabel";
import { AreaAutoSyncPhotosField } from "./AreaAutoSyncPhotosField";
import { AreaAutoSyncTextField } from "./AreaAutoSyncTextField";
import { AreaFullAreaPaintingBox } from "./AreaFullAreaPaintingBox";
import { AreaHeatSourceBox } from "./AreaHeatSourceBox";
import { AreaPersonalItemRemovalBox } from "./AreaPersonalItemRemovalBox";
import { AreaSmokeDetectorFoundBox } from "./AreaSmokeDetectorFoundBox";

export function AreaOneBoxV2() {
  const area = useArea()!;
  const { photos, notes } = area;
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const isAreaKitchen = useIsAreaKitchen();
  const isAreaLaundry = useIsAreaLaundry();

  return (
    <VStack w="100%" alignItems="flex-start" p={4} spacing={5}>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <RequiredLabel hasValue={photos.length >= 2}>
          {isAreaKitchen || isAreaLaundry ? "Area and Appliances Photos" : "Area Photos"}
        </RequiredLabel>
        <ArrowOpenIcon isOpen={isOpen} onClick={onToggle} />
      </Flex>

      <Flex display={isOpen ? "flex" : "none"} justifyContent="space-between" alignItems="center" w="100%">
        <Box>
          <AreaAutoSyncPhotosField
            cacheField="areaPhotoUrlsJson"
            serverField="photoUrlsJson"
            prefix={Prefix.Areas}
            values={photos}
          />
        </Box>
        <Box>
          <AreaAutoSyncTextField value={notes} cacheField="areaNotes" serverField="notes" />
        </Box>
      </Flex>
      {isAreaShowingSmokeDetector(area) && <AreaSmokeDetectorFoundBox />}
      {isAreaShowingHeatSource(area) && <AreaHeatSourceBox />}
      <Divider />
      <AreaPersonalItemRemovalBox />
      <Divider />
      <AreaFullAreaPaintingBox />
    </VStack>
  );
}
