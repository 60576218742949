import { Option } from "../../../../@types/RentReady/Komponent";
import { OtherOption } from "../../otherOption";

type Args = {
  hasNotes: boolean;
  options: Option[];
  types: Option[];
};

function optionCheck(options: Option[]): boolean {
  //if there are no options, it is valid
  if (options.length === 0) return true;

  const otherOption = options.find((option) => option.id === OtherOption.id);

  //if options do not have any other option, it is valid
  if (!otherOption) return true;

  //if there is an other option selected, and the notes are not filled, it is invalid
  return !otherOption.selected;
}

export function isNotesValid({ hasNotes, options, types }: Args): boolean {
  //if there are notes filled, it is always valid
  if (hasNotes) {
    return true;
  }

  return optionCheck(options) && optionCheck(types);
}
