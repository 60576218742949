import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { DoorsteadLogo } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useCreateFlexWalkthroughReportData } from "src/hooks/flexWalkthrough/useCreateFlexWalkthroughReportData";
import { useFlexWalkthroughReport } from "../../../../hooks/flexWalkthrough/useFlexWalkthroughReport";
import { useSyncServerToLocalDb } from "../../../../hooks/flexWalkthrough/useSyncServerToLocalDb";
import { useIsOnline } from "../../../../hooks/useIsOnline";

export function FlexWalkthroughStartView() {
  const isOnline = useIsOnline();
  const { propertyAddress } = useFlexWalkthroughReport();
  const { mutate: createFlexWalkthroughReportData, isLoading } = useCreateFlexWalkthroughReportData();
  const serverToLocalDb = useSyncServerToLocalDb();

  return (
    <Flex flexDirection="column" w="100%" pos="relative">
      <VStack flex={1} w="100%" p={5}>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <DoorsteadLogo width={200} href="" />
        </Flex>
        <VStack pt={10}>
          <Box fontWeight="bold" fontSize="xl">
            Property Walkthrough
          </Box>
          <Box>for</Box>
          <Box fontWeight="semibold">{propertyAddress}</Box>
          <Box>
            <Divider />
          </Box>
        </VStack>
      </VStack>
      <VStack as="footer" pos="sticky" bottom={0} spacing={0}>
        <Box color="purple.400" fontWeight="bold" p={5}>
          Once you arrive at the property, please hit Start
        </Box>
        <Divider />
        {isOnline && (
          <Box w="100%" p={5}>
            <PrimaryButton
              isFullWidth
              disabled={isLoading}
              isLoading={isLoading}
              onClick={async () => {
                await createFlexWalkthroughReportData(undefined, {
                  onSuccess: serverToLocalDb,
                });
              }}
            >
              Start
            </PrimaryButton>
          </Box>
        )}
      </VStack>
    </Flex>
  );
}
