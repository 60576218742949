import { Box } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useDrawer } from "../../../../../hooks/rentReady/useDrawer";

export function useOnHintClick() {
  const { onOpen, onClose } = useDrawer();

  return () => {
    onOpen({
      header: "Additional Key Storage",
      body: (
        <Box maxW="250px">
          Only one set of access keys should remain in the lockbox. Photograph all other keys left in a drawer in the
          kitchen
        </Box>
      ),
      action: <PrimaryButton onClick={onClose}>Close</PrimaryButton>,
    });
  };
}
