import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { CompleteIcon } from "./CompleteIcon";
import { InCompleteIcon } from "./InCompleteIcon";

type Props = FlexProps & {
  isComplete: boolean;
  text: string;
  onClick: () => void;
};

export function AreaRow({ isComplete, text, onClick, ...flexProps }: Props) {
  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between" onClick={onClick} {...flexProps}>
      <Flex>
        {isComplete ? <CompleteIcon /> : <InCompleteIcon />}
        <Box ml={3}>{text}</Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <ArrowIcon size={2} color="black" direction={Direction.Right} />
      </Flex>
    </Flex>
  );
}
