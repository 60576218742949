import { Divider, VStack } from "@chakra-ui/react";
import { useAddEmployment } from "../../../../../../hooks/applicants/useAddEmployment";
import { useClearEmployments } from "../../../../../../hooks/applicants/useClearEmployments";
import { ApplicantEmploymentNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useRemoveEmployment } from "../../../../../../hooks/applicants/useRemoveEmployment";
import { useUpdateEmployment } from "../../../../../../hooks/applicants/useUpdateEmployment";
import { ValuesCard } from "../../ValuesCard";
import { Content } from "./Content";
import { Props } from "./typings";

export function EmploymentsCard({ id, values, ...valuesProps }: Props) {
  const { mutate: onAdd } = useAddEmployment();
  const { mutate: onClear } = useClearEmployments();
  const { mutate: onRemove } = useRemoveEmployment();
  const { mutate: onUpdateEmployment } = useUpdateEmployment();

  return (
    <ValuesCard
      {...valuesProps}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      idField="applicantEmploymentId"
      id={id}
      title="Current employment"
      subTitle="Please tell us about your current occupation."
      noLabel="Not currently employed"
      yesLabel="Currently employed"
      numberLabel="Place of Employment"
      addLabel="Add another"
      values={values}
      content={(index, value) => (
        <VStack w="100%" spacing={10}>
          <Content value={value as ApplicantEmploymentNode} onChange={onUpdateEmployment} />
          <Divider />
        </VStack>
      )}
      onRemove={(ignored, value) => {
        onRemove((value as ApplicantEmploymentNode).applicantEmploymentId);
      }}
      onAdd={onAdd}
      onReset={() => onClear()}
    />
  );
}
