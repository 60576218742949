import { Box, Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { useNumberOfApprovals } from "../../../hooks/rr-project/useNumberOfApprovals";
import { useNumberOfDIYs } from "../../../hooks/rr-project/useNumberOfDIYs";
import { useNumberOfNewIssues } from "../../../hooks/rr-project/useNumberOfNewIssues";
import { useNumberOfRejects } from "../../../hooks/rr-project/useNumberOfRejects";
import { View, useView } from "../../../hooks/rr-project/useView";
import { MainBox } from "../MainBox";
import { Submit } from "./Submit";

export function Footer() {
  const { view, setView } = useView();
  const isMobile = useIsMobile();
  const numberOfNewIssues = useNumberOfNewIssues();
  const numberOfApprovals = useNumberOfApprovals();
  const numberOfDIYs = useNumberOfDIYs();
  const numberOfRejects = useNumberOfRejects();
  const totalCount = numberOfApprovals + numberOfDIYs + numberOfRejects;

  return (
    <MainBox as="footer" pos="sticky" bottom={0} bgColor="white">
      <Flex justifyContent="space-between" alignItems="center" w="100%" m={5}>
        {view === View.RepairListView && (
          <Stack
            w="100%"
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "space-between"}
          >
            <VStack alignItems="flex-start" justifyContent="center">
              {!isMobile && <Box>{numberOfNewIssues} new issues need your attention</Box>}
              {totalCount > 0 && (
                <HStack>
                  {numberOfApprovals > 0 && <Chip label={`${numberOfApprovals} Approvals`} variant={Variant.Green} />}
                  {numberOfDIYs > 0 && <Chip label={`${numberOfDIYs} selected as DIY`} variant={Variant.Purple} />}
                  {numberOfRejects > 0 && <Chip label={`${numberOfRejects} Rejects`} variant={Variant.Red} />}
                </HStack>
              )}
            </VStack>
            <Submit />
          </Stack>
        )}
        {view === View.HowItWorksView && (
          <>
            <Flex alignItems="flex-start">
              <Box>
                <PrimaryButton size="md" onClick={() => setView(View.RepairListView)}>
                  <HStack>
                    <ArrowIcon color="white" direction={Direction.Left} />
                    <Box>Back to Repairs</Box>
                  </HStack>
                </PrimaryButton>
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </MainBox>
  );
}
