import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { InspectionReportNode } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { getIsWorkingValue } from "./getIsWorkingValue";
import { mapPhotoUrlsJsonToPhotos } from "./mapPhotoUrlsJsonToPhotos";
import { mapStringToScale } from "./mapStringToScale";

export function mapInspectionReportNodeToKeyDetail(inspectionReportNode: InspectionReportNode): KeyDetail {
  return {
    frontDoorKeys: {
      number: mapStringToScale(inspectionReportNode.frontDoorKeys),
      isWorking: getIsWorkingValue(inspectionReportNode.frontDoorKeysWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.frontDoorKeysPhotoUrlsJson),
      note: inspectionReportNode.frontDoorKeysNotes || "",
    },
    mailboxKeys: {
      number: mapStringToScale(inspectionReportNode.mailboxKeys),
      isWorking: getIsWorkingValue(inspectionReportNode.mailboxKeysWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.mailboxKeysPhotoUrlsJson),
      note: inspectionReportNode.mailboxKeysNotes || "",
    },
    communityKeys: {
      number: mapStringToScale(inspectionReportNode.communityKeys),
      isWorking: getIsWorkingValue(inspectionReportNode.communityKeysWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.communityKeysPhotoUrlsJson),
      note: inspectionReportNode.communityKeysNotes || "",
    },
    garageRemotes: {
      number: mapStringToScale(inspectionReportNode.garageRemotes),
      isWorking: getIsWorkingValue(inspectionReportNode.garageRemotesWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.garageRemotesPhotoUrlsJson),
      note: inspectionReportNode.garageRemotesNotes || "",
    },
    keyPads: {
      number: mapStringToScale(inspectionReportNode.keypads),
      isWorking: getIsWorkingValue(inspectionReportNode.keypadsWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.keypadsPhotoUrlsJson),
      note: inspectionReportNode.keypadsNotes || "",
    },
    additionalKeys: {
      number: mapStringToScale(inspectionReportNode.additionalKeys),
      isWorking: getIsWorkingValue(inspectionReportNode.additionalKeysWorking),
      photos: mapPhotoUrlsJsonToPhotos(inspectionReportNode.additionalKeysPhotoUrlsJson),
      note: inspectionReportNode.additionalKeysNotes || "",
    },
  };
}
