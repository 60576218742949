import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Args = {
  itemId: string;
  components: { componentDataId: string }[];
};

export function useClearItemAndComponentsPhotosAndNotes() {
  const { gqlClient } = useAuth();

  return async ({ itemId, components }: Args) => {
    await gqlClient.mutate({
      mutation: gql`
        mutation (
          $items: [UpdateInspectionReportItemsDataInput]
          $components: [UpdateInspectionReportComponentsOption]
          $isClearPhotosAndNotes: Boolean
        ) {
          updateInspectionReportItemsData(items: $items) {
            count
          }
          clearInspectionReportComponents(components: $components, isClearPhotosAndNotes: $isClearPhotosAndNotes) {
            count
            updatedAtList
          }
        }
      `,
      variables: {
        items: [
          {
            itemDataId: itemId,
            notes: "",
            photoUrlsJson: "[]",
          },
        ],
        components,
        isClearPhotosAndNotes: true,
      },
    });
  };
}
