import { Box, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Photo } from "../../@types/RentReady/Photo";
import { Prefix } from "../../hooks/rentReady/useUpload";
import { PhotoThumbnailWithNumber } from "./PhotoThumbnailWithNumber";
import { ZeroPhotoThumbnail } from "./ZeroPhotoThumbnail";
import { PhotoEditorDialog } from "./views/PhotoEditorDialog";
import { Mode } from "./views/PhotoEditorDialog/Mode";

type Props = {
  prefix: Prefix | null;
  photos: Photo[];
  showZero?: boolean;
};

export function EstimatePhotosBox({ showZero = true, prefix, photos }: Props) {
  const [mode, setMode] = useState(Mode.THUMBNAILS);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <PhotoEditorDialog
        prefix={prefix}
        mode={mode}
        setMode={setMode}
        photos={photos}
        onClose={onClose}
        isOpen={isOpen}
      />
      {photos.length === 0 && <>{showZero ? <ZeroPhotoThumbnail /> : <Box w={75} h={75} />}</>}
      {photos.length > 0 && (
        <PhotoThumbnailWithNumber
          photo={photos[0]}
          onClick={() => {
            setMode(Mode.THUMBNAILS);
            onOpen();
          }}
          number={photos.length}
        />
      )}
    </>
  );
}
