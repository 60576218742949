import { Box, Flex, ListItem, Modal, ModalContent, ModalFooter, UnorderedList, useDisclosure } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useContext } from "react";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function DoorsteadManagedRepairsInstructionModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useContext(context);

  return (
    <Modal size={isMobile ? "full" : "2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Box paddingX={4} paddingY={8}>
          <Flex fontWeight="bold" fontSize="3xl" color="gray.700" justifyContent="flex-start" alignItems="center">
            Doorstead Managed Repairs
          </Flex>
          <Box color="gray.500" mt={3}>
            <Box>
              Thanks for approving these repairs and estimates. Our experienced team will be taking if from here. What
              to expect:
            </Box>
            <UnorderedList mt={3}>
              <ListItem>
                Your dedicated property specialist will be working with our trusted vendor partners to ensure your
                property is made rent ready as quickly and smoothly as possible
              </ListItem>
              <ListItem>
                Our team will schedule the vendors and keep you informed of the expected completion dates
              </ListItem>
              <ListItem>
                Once work is complete we will complete a quality assurance check to make sure the repairs are done up to
                our high standards
              </ListItem>
              <ListItem>
                Once QA is complete, we perform a deep clean of the house, and schedule professional photos for listing
              </ListItem>
              <ListItem>
                We’ll then inform you that your property is rent ready and get the property listed as soon as possible!
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Box color="gray.500" p={5}>
          While the Initial Evaluation captures most key repairs, we&lsquo;ll consult you if vendors identify extra
          issues that may change the scope of work. Doorstead’s protocol requires a move-in cleaning (ranging $200-$450)
          to sanitize the house before tenant move-in which will be deducted from your first month’s rent.
        </Box>
        <ModalFooter>
          <Flex mt={3} justifyContent="center" alignItems="center" w="100%">
            <PrimaryButton size="md" onClick={onClose}>
              Back to the list
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function useDoorsteadManagedRepairsInstructionModal() {
  return useContext(context);
}
