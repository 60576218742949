import { Photo } from "../../@types/RentReady/Photo";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";

export function isSmokeDetectorsPhotoValid(status: GoodMissingFixStatus | null, photos: Photo[]): boolean {
  switch (status) {
    case GoodMissingFixStatus.Missing:
      return true;
    case GoodMissingFixStatus.Good:
    case GoodMissingFixStatus.Fix:
      return photos.length > 0;
    case null:
      return true;
  }
}
