import { ReactNode, createContext, useContext, useState } from "react";

export enum RepairOrReplace {
  Repair = "Repair",
  Replace = "Replace",
}

type Value = {
  componentId: string;
  repairOrReplace: RepairOrReplace;
};

type Props = {
  value: Value;
  setValue: (value: Value) => void;
};

const context = createContext<Props>({
  value: {
    componentId: "",
    repairOrReplace: RepairOrReplace.Repair,
  },
  setValue(value) {
    console.log(value);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [value, setValue] = useState<{
    componentId: string;
    repairOrReplace: RepairOrReplace;
  }>({
    componentId: "",
    repairOrReplace: RepairOrReplace.Repair,
  });
  return <context.Provider value={{ value, setValue }}>{children}</context.Provider>;
}

export function useComponentId() {
  return useContext(context);
}
