import { Text, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components";
import { useNavigate } from "react-router-dom";
import { useApplicantId } from "../../hooks/applicants/useApplicantId";

type Props = {
  title: string;
  subTitle?: string;
  errorType?: string;
};

export function Error({ title, subTitle = "", errorType }: Props) {
  const applicantId = useApplicantId();
  const navigate = useNavigate();

  return (
    <VStack spacing={3}>
      <img
        style={{ border: 0, maxWidth: "100%" }}
        alt="doorstead"
        loading="lazy"
        src="https://doorstead-components.s3.us-west-2.amazonaws.com/houses.svg"
      />
      <Text textAlign="center" fontSize="3rem" lineHeight="1.1" fontWeight="bold">
        {title}
      </Text>
      <Text textAlign="center" w="370px" color="gray.500" fontSize="20px" lineHeight="152%" fontWeight="medium">
        {subTitle}
      </Text>
      {errorType === "incorrectUser" ? (
        <PrimaryButton
          onClick={() => {
            navigate(`/application/logout?applicantId=${applicantId}`);
          }}
        >
          Log Out
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={() => {
            navigate("/");
          }}
        >
          Back to Home
        </PrimaryButton>
      )}
    </VStack>
  );
}
