import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { DateField } from "@doorstead/components/fields/DateField";
import { Fragment } from "react";
import { useAutoFocusHashPosition } from "../../../../hooks/rentReady/useAutoFocusHashPosition";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useEstimateList } from "../../../../hooks/rentReady/useEstimateList";
import { useUpdateInspectionReportData } from "../../../../hooks/rentReady/useFetchInspectionReport/useFetchInspectionReport";
import { useInspectionReport } from "../../../../hooks/rentReady/useInspectionReport";
import { useIsInspectionReportSubmitted } from "../../../../hooks/rentReady/useIsInspectionReportSubmitted";
import { useIsNoEstimateData } from "../../../../hooks/rentReady/useIsNoEstimateData";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { useRemovePositionToHashTag } from "../../../../hooks/rentReady/useRemovePositionToHashTag";
import { useUpdateInspectionReport } from "../../../../hooks/rentReady/useUpdateInspectionReport";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { getYearMonthAndDateFromDateString } from "../../../../utils/getYearMonthAndDateFromDateString";
import { WholeNumberField } from "../../../WholeNumberField";
import { RequiredLabel } from "../../RequiredLabel";
import { SubmitButton } from "../../SubmitButton";
import { EstimateCard } from "./EstimateCard";

const backView = View.AreaListView;

export function EstimateListView() {
  const { setView } = useView();
  const isOnline = useIsOnline();
  const estimates = useEstimateList();
  const isInspectionReportSubmitted = useIsInspectionReportSubmitted();
  const { estimateStartDate, estimateJobTimeInDays } = useInspectionReport();
  const updateInspectionReportData = useUpdateInspectionReportData();
  const { mutate: updateInspectionReport } = useUpdateInspectionReport();
  const isReadOnly = useIsReadOnly();
  const isNoEstimateData = useIsNoEstimateData();
  const removePositionToHashTag = useRemovePositionToHashTag("estimate_list");
  useAutoFocusHashPosition("estimate_list");
  useDisplayInstruction(View.EstimateListView);

  return (
    <>
      <VStack w="100%" px={5} spacing={5} alignItems="flex-start">
        {isNoEstimateData && <Box>No estimates yet</Box>}
        {!isNoEstimateData && (
          <>
            <VStack alignItems="flex-start">
              <RequiredLabel hasValue={estimateStartDate !== null}>Earliest Job Start Date</RequiredLabel>
              <DateField
                id="start_date"
                label={null}
                disabled={isReadOnly}
                value={getYearMonthAndDateFromDateString(estimateStartDate)}
                onChange={(value) => {
                  const estimateStartDate =
                    value === null
                      ? null
                      : `${value.year}-${value.month.toString().padStart(2, "0")}-${value.date
                          .toString()
                          .padStart(2, "0")}`;
                  updateInspectionReportData({
                    estimateStartDate,
                  });
                  updateInspectionReport({
                    estimateStartDate,
                  });
                }}
              />
            </VStack>
            <VStack alignItems="flex-start">
              <RequiredLabel hasValue={estimateJobTimeInDays !== null}>Job Time in Days</RequiredLabel>
              <WholeNumberField
                onBlur={(estimateJobTimeInDays) => {
                  updateInspectionReport({
                    estimateJobTimeInDays,
                  });
                }}
                disabled={isReadOnly}
                value={estimateJobTimeInDays}
                isInvalid={estimateJobTimeInDays === null}
                onChange={(estimateJobTimeInDays) => {
                  updateInspectionReportData({
                    estimateJobTimeInDays,
                  });
                }}
              />
            </VStack>
            <Divider />
          </>
        )}
        {estimates.map((estimate) => (
          <Fragment key={estimate.id}>
            <EstimateCard {...estimate} />
            <Divider />
          </Fragment>
        ))}
        {isOnline && (
          <HStack
            pos="sticky"
            bottom={0}
            bgColor="white"
            as="footer"
            w="100%"
            justifyContent="space-between"
            mt={2}
            spacing={5}
            pt={5}
            pb={5}
            zIndex={1}
            borderTopRadius="6px"
          >
            <OutlineButton
              isFullWidth
              onClick={() => {
                removePositionToHashTag();
                setView(backView);
              }}
            >
              Back
            </OutlineButton>
            {!isInspectionReportSubmitted && <SubmitButton estimates={estimates} />}
          </HStack>
        )}
      </VStack>
    </>
  );
}
