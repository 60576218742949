import { useGenerateS3PresignedUrl } from "../../../../../hooks/applicants/useGenerateS3PresignedUrl";

export function useOnFileClick() {
  const { mutate: generateS3PresignedUrl } = useGenerateS3PresignedUrl();

  return async ({ bucket, key }: { bucket: string; key: string }) => {
    try {
      generateS3PresignedUrl(
        { bucket, key },
        {
          onSuccess(res) {
            const url = res?.data?.generateS3PresignedUrl?.url || null;
            if (url !== null) {
              window.open(url, "_blank");
            }
          },
        }
      );
    } catch (e) {
      return;
    }
  };
}
