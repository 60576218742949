import { gql } from "@apollo/client";
import { DoorsteadProvider, S3_BUCKET, SignupScreen } from "@doorstead/components";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { processAuthError, useAuth } from "src/contexts/auth";
import { usePageView } from "src/hooks/usePageView";
import withoutAuth from "src/utils/withoutAuth";

function SignupPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const userId = params.get("user_id");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { signup, loginWithPassword, gqlClient } = useAuth();
  const analytics = usePageView("TP_SIGN_UP_VIEWED");

  const passwordSignup = async () => {
    try {
      setLoading(true);
      analytics?.track("TP_SIGN_UP_SUBMITTED", { email });
      await signup({ email, password });
      analytics?.track("TP_SIGN_UP_SUCCESS", { email });
      await loginWithPassword({ email, password });
      // Redirect to login/callback
    } catch (err: any) {
      const authError = processAuthError(err);
      setEmailError(authError.email || "");
      setPasswordError(authError.password || "");
      setErrorMessage(authError.general || "");
      analytics?.track("TP_SIGN_UP_FAILED", { email });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userId) {
      return;
    }

    (async () => {
      const { data } = await gqlClient.query({
        query: gql`
          query getDoorsteadUserById($userId: UUID) {
            doorsteadUserById(doorsteadUserId: $userId) {
              email
              status
            }
          }
        `,
        variables: { userId },
      });
      console.log("getDoorsteadUserById", { data });

      if (!data?.doorsteadUserById) {
        setEmailError(`User ${userId} not found`);
      } else {
        const userEmail = data.doorsteadUserById.email;
        if (data.doorsteadUserById.status === "active") {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append("email", userEmail);
          navigate(`/login?${urlSearchParams.toString()}`);
        } else {
          setEmail(userEmail);
        }
      }
    })();
  }, [userId]);

  return (
    <DoorsteadProvider>
      <SignupScreen
        title="Doorstead for Tenants"
        subTitle="Property management reimagined"
        emailDisabled
        titleColor="white"
        subTitleColor="white"
        backgroundImageUrl={`${S3_BUCKET}/tenant_portal_login.png`}
        isBackgroundGradient={false}
        value={{ email, password }}
        onChange={({ email, password }) => {
          setEmail(email);
          setPassword(password);
        }}
        logoLink="/"
        loginLink={`/login?email=${encodeURIComponent(email)}`}
        isSubmitting={loading}
        onLogin={passwordSignup}
        emailErrorMessage={emailError}
        passwordErrorMessage={passwordError}
        errorMessage={errorMessage}
      />
    </DoorsteadProvider>
  );
}

export default withoutAuth(SignupPage);
