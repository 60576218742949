import { useQuery } from "react-query";
import { useAreaId } from "./useAreaId";
import {
  Result,
  fetchInspectionReportItemOptionsByAreaId,
} from "./useFetchInspectionReport/fetchInspectionReportItemOptionsByAreaId";

export const queryKey = "useFetchAreaItemOptions";

export function useFetchAreaItemOptions() {
  const { areaId } = useAreaId();
  return useQuery<unknown, unknown, Result>(queryKey, () => fetchInspectionReportItemOptionsByAreaId(areaId), {
    refetchOnMount: true,
  });
}
