import { OwnerDecision } from "./useFetchRRProject";
import { useGetToApproveCount } from "./useGetToApproveCount";
import { useGetToEstimatesCount } from "./useGetToEstimatesCount";

export function useNumberOfApprovals(): number {
  const getToApproveCount = useGetToApproveCount();
  const getToEstimatesCount = useGetToEstimatesCount();

  return getToApproveCount(OwnerDecision.Yes) + getToEstimatesCount(true) + getToEstimatesCount(null);
}
