import { useAnalytics } from "../../../../../contexts/analytics";
import { AutoPayNode } from "../../../../../hooks/useFetchMe";
import { UpsertAutoPayCard } from "../../../../evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { EverGreenModal } from "../../../../evergreens/EverGreenModal/EverGreenModal";

type Props = {
  autopay: AutoPayNode;
  isOpen: boolean;
  onClose: () => void;
  onDisableClick: () => void;
};

export function EditAutopayModal({ autopay, isOpen, onClose, onDisableClick }: Props) {
  const analytics = useAnalytics();

  return (
    <>
      <EverGreenModal
        title="Edit autopay"
        isOpen={isOpen}
        onClose={() => {
          analytics?.track("TP_AUTOPAY_DISMISSED");
          onClose();
        }}
      >
        <UpsertAutoPayCard
          isEdit={true}
          initValue={{
            id: autopay.autopayId,
            paymentMethodId: autopay.paymentMethod.paymentMethodId,
            payOn: autopay.date,
            nextScheduleDate: autopay.nextPaymentDate,
          }}
          onDone={onClose}
          onCancel={onClose}
          hasRemoveButton={false}
          onDisableClick={onDisableClick}
        />
      </EverGreenModal>
    </>
  );
}
