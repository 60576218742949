import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { useArea } from "../../../../hooks/rentReady/useArea";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { isAreaShowingHeatSource } from "../../../../utils/rentReady/isAreaShowingHeatSource";
import { isAreaShowingSmokeDetector } from "../../../../utils/rentReady/isAreaShowingSmokeDetector";
import { TitleRow } from "../../TitleRow";
import { AreaOneBox } from "./AreaOneBox";
import { AreaOneBoxV2 } from "./AreaOneBoxV2";
import { AreaOneBoxMoveIn } from "./AreaOneBoxV2/AreaOneBoxMoveIn";

export function AreaRow() {
  const area = useArea();
  const isV2 = useIsV2UiFeatures();
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  if (area === null || area.isThroughoutProperty) return null;

  if (shouldUseSimplifiedTemplate && !isAreaShowingSmokeDetector(area) && !isAreaShowingHeatSource(area)) return null;

  return (
    <>
      <TitleRow text="Area" />
      {shouldUseSimplifiedTemplate ? <AreaOneBoxMoveIn /> : isV2 ? <AreaOneBoxV2 /> : <AreaOneBox />}
    </>
  );
}
