import { gql } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import { useAnalytics } from "src/contexts/analytics";
import { useAuth } from "../../../contexts/auth";
import { useApplicantId } from "../useApplicantId";
import { ApplicantNode } from "./Applicant";

export const queryKey = "useFetchApplicationMe";

export type Result = {
  applicantById: ApplicantNode;
};

const query = gql`
  query ($applicantId: UUID) {
    applicantById(applicantId: $applicantId) {
      doorsteadUser {
        doorsteadUserId
        status
        firstName
        middleName
        lastName
        suffixName
        email
        phone
        passportNumber
        driverLicenseNumber
        ssnTemp
        dayOfBirthTemp
        hasBankruptcy
        isEvicted
        hasGuilty
      }
      moveInDate
      moveOutDate
      landlordName
      landlordPhone
      landlordEmail
      onboardingStage
      desiredLeaseStartAt
      createdAt
      coverPageGetStartedAt
      status
      applicantId
      hasAgreed
      proofOfIncomeIsNewJob
      applicantId
      addressLine1
      addressLine2
      state
      country
      city
      zipcode
      releaseFormSignature
      personaInquiryId
      isSalaryIncomeChecked
      isSelfEmployedIncomeChecked
      isOtherIncomeChecked
      occupants {
        edges {
          node {
            applicantOccupantId
            fullName
            createdAt
          }
        }
      }
      files {
        edges {
          node {
            applicantFileId
            type
            bucket
            key
            fileName
            objectSizeInBytes
            lastModifiedTimestamp
            contentType
          }
        }
      }
      animals {
        edges {
          node {
            applicantAnimalId
            name
            type
            breed
            color
            weightLb
            isServiceAnimal
            hasVerification
          }
        }
      }
      employments {
        edges {
          node {
            applicantEmploymentId
            isCurrent
            employer
            jobTitle
            streetAddress
            unitNumber
            state
            country
            city
            zipcode
            annualIncomeInCents
            currentJobStartDate
            createdAt
          }
        }
      }
      previousResidences {
        edges {
          node {
            applicantResidenceId
            moveInDate
            moveOutDate
            landlordName
            landlordPhone
            landlordEmail
            addressLine1
            addressLine2
            city
            state
            country
            zipcode
          }
        }
      }
      applicationRequest {
        applicationRequestId
        status
        inviteCoTenantAndGuarantorAt
        isTpoHideApplicationFeeFeature
        negotiatedRent
        negotiatedMoveInDate
        negotiationLinkStartAt
        negotiationLinkEndAt
        appStartedAt
        appSubmittedAt
        useApplicationFlowV2
        areAllApplicantsSubmitted
        currentSignatureRequest {
          signatureRequestId
          isLeaseSignedByOwner
        }
        primaryApplicant {
          applicantId
        }
        property {
          propertyId
          isCoTenantsInviteV2Feature
          isEmbeddedLeaseSigningEnabled
          propertyType
          bedrooms
          bathrooms
          fullAddress
          sqft
          addressLine1
          addressLine2
          zip
          state
          city
          timezone
        }
        listing {
          listingId
          leaseNegotiationEnabled
          price
          moveInOptions {
            startDate
            endDate
            price
          }
          renewal {
            contract {
              type
              contractRequest {
                multistepFlowType
              }
            }
          }
        }
      }
    }
  }
`;

export function useFetchApplicationMe() {
  const { gqlClient } = useAuth();
  const analytics = useAnalytics();
  const applicantId = useApplicantId();

  return useQuery<unknown, unknown, Result>(queryKey, async () => {
    Sentry.addBreadcrumb({
      category: "#[Tenant-Portal]useFetchApplicationMe",
      message: "Request",
      data: { query, variables: { applicantId } },
      level: "info",
    });
    const result: Result = await gqlClient
      .query({ query, variables: { applicantId } })
      .then((e) => e.data)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        throw error;
      });

    const applicant = result?.applicantById;
    const user = applicant?.doorsteadUser;

    analytics?.identify(user.email, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      doorsteadUserId: user?.doorsteadUserId,
      applicantId: applicant?.applicantId,
      applicationRequestId: applicant?.applicationRequest?.applicationRequestId,
    });

    return result;
  });
}
