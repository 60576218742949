import { Divider } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useEffect, useState } from "react";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { Item } from "../../../../@types/RentReady/Item";
import { Photo } from "../../../../@types/RentReady/Photo";
import { GoodMissingFixStatus } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useItem } from "../../../../hooks/rentReady/useItem";
import { useSaveItemSubPage } from "../../../../hooks/rentReady/useSaveItemSubPage";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { RequiredLabel } from "../../RequiredLabel";
import { PhotosField } from "../../fields/PhotosField";
import { TextAreaField } from "../../fields/TextAreaField";
import { isNotesValid } from "../ComponentEditView/isNotesValid";
import { ItemTypeSelect } from "./ItemTypeSelect";
import { isItemEditStateDiff } from "./isItemEditStateDiff";
import { isSaveDisabled } from "./isSaveDisabled";

export type ItemEditState = {
  photos: Photo[];
  note: string;
  cosmeticOptions: Item["cosmeticOptions"];
  status: Item["status"];
  updatedDate: Item["updatedDate"];
};

function Content({ item: initialItem }: { item: Item }) {
  const initialState = {
    photos: initialItem?.photos || [],
    note: initialItem?.note || "",
    cosmeticOptions: initialItem?.cosmeticOptions || [],
    status: initialItem?.status || null,
    updatedDate: initialItem?.updatedDate || null,
  };
  const [state, setState] = useState<ItemEditState>(initialState);
  const { setView } = useView();
  const { mutate: updateItem, isLoading } = useSaveItemSubPage();
  const success = useSuccess();
  const error = useError();
  const isEditable = useIsEditable();
  useEffect(() => {
    setState(initialState);
  }, [JSON.stringify(initialItem), JSON.stringify(initialState)]);

  const disabled = isSaveDisabled({
    isLoading,
    hasNote: state.note.trim().length !== 0,
    hasPhoto: state.photos.length > 0,
    hasDataUpdated: isItemEditStateDiff(state, initialState),
    cosmeticOptions: state.cosmeticOptions,
  });
  const hasValue = state.cosmeticOptions.some((e) => e.selected);

  if (initialItem === null) return null;

  return (
    <FullScreen
      headerName={`${initialItem.uiName}: Overview`}
      backView={View.AreaOneView}
      contentProps={{ p: 5 }}
      backButton={
        <OutlineButton
          isFullWidth
          disabled={isLoading}
          onClick={() => {
            setView(View.AreaOneView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            disabled={disabled}
            isLoading={isLoading}
            onClick={() => {
              //if status is null, set to Fix
              updateItem(
                { ...state, id: initialItem.id, status: GoodMissingFixStatus.Fix },
                {
                  onSuccess() {
                    success({ title: "Updated!" });
                    setView(View.AreaOneView);
                  },
                  onError() {
                    error({
                      title: "Failed to update",
                      description: "There was an error updating. Please try again.",
                    });
                  },
                }
              );
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <PhotosField
        prefix={Prefix.Items}
        values={state.photos}
        onChange={(photos) => {
          setState(
            produce((draft) => {
              draft.photos = photos;
            })
          );
        }}
      />
      <Divider />
      {state.cosmeticOptions.length > 0 && (
        <>
          <RequiredLabel w="100%" hasValue={hasValue}>
            {initialItem.cosmeticDefect} Type
          </RequiredLabel>
          <Divider />
          <ItemTypeSelect
            values={state.cosmeticOptions}
            onChange={(cosmeticOptions) => {
              setState(
                produce((draft) => {
                  draft.cosmeticOptions = cosmeticOptions;
                })
              );
            }}
          />
          <Divider />
        </>
      )}
      <TextAreaField
        isValid={isNotesValid({ hasNotes: state.note.trim().length !== 0, options: state.cosmeticOptions, types: [] })}
        label="Notes"
        value={state.note}
        onChange={(note) => {
          setState(
            produce((draft) => {
              draft.note = note;
            })
          );
        }}
        disabled={useIsReadOnly()}
      />
    </FullScreen>
  );
}

export function ItemEditView() {
  const item = useItem();
  if (item === null) return null;

  return <Content item={item} />;
}
