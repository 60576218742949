import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { Repair } from "src/@types/RepairList/Repair";
import { ExpandButton } from "../ExpandButton";
import { NoteField } from "../NoteField";
import { Photos } from "../Photos";
import { RepairChip } from "../RepairChip";
import { RepairOwnerDecisionField } from "../RepairOwnerDecisionField";
import { RepairOwnerNoteIcon } from "../RepairOwnerNoteIcon";

export function ToApproveRepairCard({
  id,
  ownerDecision,
  ownerNotes,
  uiTitle,
  repairRequirement,
  description,
  photos,
}: Repair) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <VStack w="100%" color="gray.700">
      <Flex justifyContent="space-between" w="100%">
        <Box fontWeight="bold">{uiTitle}</Box>
        <RepairChip repairRequirement={repairRequirement} />
      </Flex>
      <Flex justifyContent="flex-start" w="100%">
        {description}
      </Flex>
      <Flex w="100%" justifyContent="flex-start" gap={3} overflowX="scroll">
        <Photos urls={photos} mostShown={4} />
      </Flex>
      <ExpandButton isOpen={isOpen} onClick={onToggle} />
      {isOpen && (
        <>
          <Flex w="100%" justifyContent="space-between">
            <RepairOwnerDecisionField repairId={id} ownerDecision={ownerDecision} />
            <Flex justifyContent="center" alignItems="center" px={5}>
              <RepairOwnerNoteIcon value={ownerNotes} />
            </Flex>
          </Flex>
          <Box display={isOpen ? "block" : "none"} w="100%">
            <NoteField id={id} defaultValue={ownerNotes} />
          </Box>
        </>
      )}
    </VStack>
  );
}
