import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapThroughoutPropertyDetectorFromRRGraphQLResultCache } from "./useMapThroughoutPropertyDetectorFromRRGraphQLResultCache";

export function useUpdateThroughoutPropertyDetectorCache() {
  const inspectionReportId = useInspectionReportId();
  const mapThroughoutPropertyDetectorFromRRGraphQLResultCache =
    useMapThroughoutPropertyDetectorFromRRGraphQLResultCache();

  return async (throughoutPropertyDetector: ThroughoutPropertyDetector) => {
    const graphQLCacheResultCache = await readRRGraphQLResultCache(inspectionReportId);

    if (graphQLCacheResultCache === null) return null;

    const cacheResult = mapThroughoutPropertyDetectorFromRRGraphQLResultCache(
      graphQLCacheResultCache,
      throughoutPropertyDetector
    );
    await writeRRGraphQLResult(inspectionReportId, cacheResult);

    return cacheResult;
  };
}
