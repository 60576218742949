import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useToApproveRepairs } from "../../../hooks/rr-project/useToApproveRepairs";
import { DesktopView } from "../DesktopView";
import { MobileView } from "../MobileView";
import { Instructions } from "./Instructions";
import { ToApproveRepairCard } from "./ToApproveRepairCard";
import { ToApproveTableRow } from "./ToApproveTableRow";

export const templateColumns = "1fr 190px 150px 45px";
const title = "Repairs to approve";

export function ToApproveRepairs() {
  const repairs = useToApproveRepairs();
  const isMobile = useIsMobile();

  if (repairs.length === 0) return null;

  return isMobile ? (
    <MobileView tip={<Instructions />} repairs={repairs} title={title} RepairCard={ToApproveRepairCard} />
  ) : (
    <DesktopView
      tip={<Instructions />}
      repairs={repairs}
      columns={["Item / Location", "Photos", "Approval", "Notes"]}
      templateColumns="1fr 200px 120px 45px"
      TableRow={ToApproveTableRow}
      title={title}
    />
  );
}
