import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";

type Args = {
  name: Item["name"];
  status: Item["status"];
  shouldUseSimplifiedTemplate: boolean;
};

export function isThroughoutPropertyItemRequirePhotos({ name, status, shouldUseSimplifiedTemplate }: Args): boolean {
  switch (status) {
    case GoodMissingFixStatus.Good:
      if (shouldUseSimplifiedTemplate) {
        return false;
      }

      return ["AC", "Fuse Box", "Heating", "Thermostats", "Water Heater"].includes(name);
    case GoodMissingFixStatus.Missing:
      return false;
    case GoodMissingFixStatus.Fix:
      return true;
    default:
      return false;
  }
}
