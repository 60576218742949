import { writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { fetchRRGraphQLResultServer } from "./useFetchInspectionReport/fetchRRGraphQLResultServer";
import { RRGraphQLResult } from "./useFetchInspectionReport/useFetchInspectionReport";
import { useInspectionReportId } from "./useInspectionReportId";

export function useSyncServerToLocalDb() {
  const inspectionReportId = useInspectionReportId();
  return async () => {
    const res: RRGraphQLResult = await fetchRRGraphQLResultServer(inspectionReportId);
    await writeRRGraphQLResult(inspectionReportId, {
      ...res,
      inspection_report_id: inspectionReportId,
    });
  };
}
