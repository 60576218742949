import { Box, Flex, FlexProps, HStack } from "@chakra-ui/react";
import { CrossIcon } from "@doorstead/components/icons/CrossIcon";
import { ReactNode } from "react";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

type Props = FlexProps & {
  minW?: string;
  title: string;
  isShowAction: boolean;
  onActionClick: () => void;
  children: ReactNode;
};

export function Bar({ minW = "140px", title, isShowAction, onActionClick, children, ...flexProps }: Props) {
  const isReadOnly = useIsReadOnly();
  return (
    <Flex justifyContent="space-between" w="100%" p={4} alignItems="center" {...flexProps}>
      <HStack spacing={2}>
        <Box minW={minW}>{title}</Box>
        {children}
      </HStack>

      {!isReadOnly && isShowAction && (
        <Flex justifyContent="center" alignItems="center" w={8} h={8}>
          <CrossIcon onClick={onActionClick} />
        </Flex>
      )}
    </Flex>
  );
}
