import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { ScrollRestoration } from "react-router-dom";
import { MainLayout } from "src/components/MainLayout";
import { VendorOnboarding } from "src/components/VendorOnboarding";
import { useFooter } from "src/hooks/useFooter";

function VendorOnboardingPage() {
  const footer = useFooter();

  return (
    <DoorsteadProvider>
      <MainLayout
        header={{
          logoLink: "",
          avatarName: "",
          links: [],
        }}
        footer={footer}
      >
        <VendorOnboarding />
      </MainLayout>
      <ScrollRestoration />
    </DoorsteadProvider>
  );
}

export default VendorOnboardingPage;
