import { Decision, Estimate } from "../../../../@types/RentReady/Estimate";
import { AddEstimatesBox } from "./AddEstimatesBox";
import { CannotEstimateBox } from "./CannotEstimateBox";

type Props = {
  estimate: Estimate;
  onChange: (estimate: Estimate) => void;
};

export function DecisionBox({ estimate, onChange }: Props) {
  switch (estimate.decision) {
    case Decision.CanNotEstimate:
      return <CannotEstimateBox estimate={estimate} onChange={onChange} />;
    case Decision.AddEstimates:
      return <AddEstimatesBox estimate={estimate} onChange={onChange} />;
    default:
      return null;
  }
}
