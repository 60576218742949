import { SelectField } from "@doorstead/components";
import { PropertyType } from "../../../../hooks/rentReady/useFetchInspectionReport/PropertyType";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

const options = [
  {
    name: "Single Family",
    value: PropertyType.SingleFamily,
  },
  {
    name: "Condo",
    value: PropertyType.Condo,
  },
  {
    name: "Townhouse",
    value: PropertyType.Townhouse,
  },
  {
    name: "Multi-Family",
    value: PropertyType.MultiFamily,
  },
  {
    name: "ADU",
    value: PropertyType.ADU,
  },
];

type Props = {
  value: PropertyType;
  onChange: (value: PropertyType) => void;
};

export function PropertyTypeSelect({ value, onChange }: Props) {
  const isReadOnly = useIsReadOnly();
  return (
    <SelectField
      value={value}
      placeholder="Select"
      disabled={isReadOnly}
      id="property-type-select"
      options={options}
      label=""
      onChange={(e) => {
        onChange(e.target.value as PropertyType);
      }}
    />
  );
}
