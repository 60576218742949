import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { LinkProvider } from "@doorstead/components/hooks/useReactRouterLink";
import { Link, LinkProps } from "react-router-dom";
import { RentReadyLayout } from "./RentReadyLayout";

export default function RentReadyOnePage() {
  return (
    <LinkProvider
      value={{
        Link(linkProps: any) {
          return <Link {...(linkProps as LinkProps)} />;
        },
      }}
    >
      <DoorsteadProvider>
        <RentReadyLayout />
      </DoorsteadProvider>
    </LinkProvider>
  );
}
