import { Box, Flex, Grid, GridItem, Img, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { PeriodRoundedIcon } from "@doorstead/components/icons/PeriodRoundedIcon";
import { PersonRoundedIcon } from "@doorstead/components/icons/PersonRoundedIcon";
import { ShieldRoundedIcon } from "@doorstead/components/icons/ShieldRoundedIcon";
import { WalletRoundedIcon } from "@doorstead/components/icons/WalletRoundedIcon";
import { ReactNode } from "react";
import { RRProjectNodeStatus } from "../../../hooks/rr-project/useFetchRRProject";
import { useUpdateRRProjectStatus } from "../../../hooks/rr-project/useUpdateRRProjectStatus";
import { View, useView } from "../../../hooks/rr-project/useView";

function IconLabel({ icon, label }: { icon: ReactNode; label: string }) {
  return (
    <GridItem display="flex" alignItems="center" justifyContent="flex-start">
      <Box>{icon}</Box>
      <Box ml={5} color="black" fontWeight="semibold">
        {label}
      </Box>
    </GridItem>
  );
}

export function StartView() {
  const { setView } = useView();
  const { mutate, isLoading } = useUpdateRRProjectStatus();
  const isMobile = useIsMobile();

  return (
    <VStack
      spacing={10}
      w="100%"
      maxW="800px"
      p={10}
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      borderRadius={25}
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Box fontWeight="bold" fontSize={isMobile ? "24px" : "4xl"}>
          Let&apos;s get your property rent ready
        </Box>
        <Img w={isMobile ? "48px" : "80px"} h={isMobile ? "48px" : "80px"} src="/resources/rr-project/tools.png" />
      </Flex>
      <VStack color="gray.500" spacing={10}>
        <Box>
          We’re here to help you get your property in great shape, so we can rent it out at the best price and as
          quickly as possible.
        </Box>
        <Box>
          A dedicated property specialist has reviewed your home and identified a list of repairs which will help you:
        </Box>
        <Grid
          templateRows={{
            sm: "repeat(1fr, 4)",
            md: "1fr 1fr",
          }}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
          }}
          w="100%"
          gap={8}
        >
          <IconLabel icon={<PersonRoundedIcon color="purple.200" />} label="Attract quality tenants" />
          <IconLabel icon={<PeriodRoundedIcon color="orange.200" />} label="Reduce vacancy periods" />
          <IconLabel icon={<WalletRoundedIcon color="#FEB2B2" />} label="Maximize rental income" />
          <IconLabel
            icon={<ShieldRoundedIcon color="green.200" />}
            label="Comply with safety standards and local regulations"
          />
        </Grid>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <PrimaryButton
            isFullWidth={isMobile}
            size="lg"
            isLoading={isLoading}
            onClick={() => {
              mutate(RRProjectNodeStatus.Guideline, {
                onSuccess() {
                  setView(View.GuidelineView);
                },
              });
            }}
          >
            Next
          </PrimaryButton>
        </Flex>
      </VStack>
    </VStack>
  );
}
