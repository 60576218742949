import { Box, HStack, Text, useToken } from "@chakra-ui/react";
import { getOptionDateString } from "../LeaseStartDateOption/utils";
import { Props } from "./typings";

export const LeaseStartDateSuboption = ({ date, selected, onClick }: Props) => {
  const color = useToken("colors", [selected ? "purple.400" : "gray.50"])[0];

  return (
    <HStack
      px={5}
      py={5}
      gap={1}
      wordBreak="normal"
      backgroundColor="gray.100"
      borderRadius={11}
      boxShadow={`inset 0px 0px 0px ${selected ? 3 : 0}px ${color}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      _hover={{ cursor: "pointer" }}
    >
      <Box
        flex="0 0 auto"
        width="20px"
        height="20px"
        backgroundColor="white"
        borderRadius="50%"
        borderWidth={selected ? 6 : 2}
        borderColor={selected ? "purple.500" : "gray.400"}
      />
      <Text>{getOptionDateString(date)}</Text>
    </HStack>
  );
};
