import { Repair } from "../../@types/RepairList/Repair";
import { useDIYRepairs } from "./useDIYRepairs";
import { useEstimateToApproveRepairs } from "./useEstimateToApproveRepairs";
import { OwnerDecision } from "./useFetchRRProject";
import { useRepairs } from "./useRepairs";
import { useToApproveRepairs } from "./useToApproveRepairs";

export function useDoorsteadManagedRepairs(): Repair[] {
  const yesRepairs = useRepairs().filter((e) => e.ownerDecision === OwnerDecision.Yes);
  const totalSet = new Set([
    ...useToApproveRepairs().map((e) => e.id),
    ...useEstimateToApproveRepairs().map((e) => e.id),
    ...useDIYRepairs().map((e) => e.id),
  ]);

  return yesRepairs.filter(({ id }) => !totalSet.has(id));
}
