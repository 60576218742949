import create from "zustand";
import { RepairNode } from "../../../hooks/rr-project/useFetchRRProject";

interface State {
  repairOwnerDecisions: Record<string, RepairNode["ownerDecision"]>;
  getOwnerDecision: (repairId: RepairNode["repairId"]) => RepairNode["ownerDecision"];
  hasOwnerDecision: (repairId: RepairNode["repairId"]) => boolean;
  onChangeOwnerDecision: (
    repairId: RepairNode["repairId"],
    toUpdateOwnerDecision: RepairNode["ownerDecision"],
    serverOwnerDecisionValue: RepairNode["ownerDecision"]
  ) => void;
  clear: () => void;
  isEmpty: () => boolean;
}

export const useToUpdateRepairOwnerDecisionsStore = create<State>((set, getState) => ({
  repairOwnerDecisions: {},
  getOwnerDecision(repairId) {
    return getState().repairOwnerDecisions[repairId] || null;
  },
  hasOwnerDecision(repairId) {
    return repairId in getState().repairOwnerDecisions;
  },
  onChangeOwnerDecision(repairId, toUpdateOwnerDecision, serverOwnerDecision) {
    //if the current owner decision in the store is the same with the server owner decision
    if (toUpdateOwnerDecision === serverOwnerDecision) {
      set((state) => {
        const updatedDecisions = { ...state.repairOwnerDecisions };
        delete updatedDecisions[repairId];
        return { repairOwnerDecisions: updatedDecisions };
      });
    } else {
      set((state) => ({
        repairOwnerDecisions: {
          ...state.repairOwnerDecisions,
          [repairId]: toUpdateOwnerDecision,
        },
      }));
    }
  },
  clear: () => set(() => ({ repairOwnerDecisions: {} })),
  isEmpty() {
    return Object.keys(getState().repairOwnerDecisions).length === 0;
  },
}));
