import { Item } from "../../../../@types/RentReady/Item";
import { CheckboxesField } from "../../fields/CheckboxesField";

type Props = {
  values: Item["cosmeticOptions"];
  onChange: (values: Item["cosmeticOptions"]) => void;
};

export function ItemMultipleTypeSelect({ values, onChange }: Props) {
  return (
    <CheckboxesField
      values={values}
      onChange={(cosmeticOptions) => {
        onChange(cosmeticOptions);
      }}
    />
  );
}
