import { SelectField } from "@doorstead/components/fields/SelectField";
import { Scale, getScaleString } from "../../../@types/RentReady/Scale";
import { useIsReadOnly } from "../../../hooks/rentReady/useIsReadOnly";
import { mapStringToScale } from "../../../utils/rentReady/mapStringToScale";

type Props = {
  value: Scale;
  onChange: (value: Scale) => void;
  options?: { name: string; value: Scale }[];
};

const defaultOptions = [
  {
    name: getScaleString(Scale.NA),
    value: Scale.NA,
  },
  {
    name: getScaleString(Scale.Zero),
    value: Scale.Zero,
  },
  {
    name: getScaleString(Scale.One),
    value: Scale.One,
  },
  {
    name: getScaleString(Scale.Two),
    value: Scale.Two,
  },
  {
    name: getScaleString(Scale.Three),
    value: Scale.Three,
  },
  {
    name: getScaleString(Scale.Four),
    value: Scale.Four,
  },
];

export function ScaleField({ value, onChange, options = defaultOptions }: Props) {
  const isReadOnly = useIsReadOnly();

  return (
    <SelectField
      label=""
      id="SelectField"
      placeholder="Select"
      disabled={isReadOnly}
      options={options}
      value={value}
      onChange={(e) => {
        onChange(mapStringToScale(e.target.value));
      }}
    />
  );
}
