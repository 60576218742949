import { CalendarIcon, HelloIcon, MoneyHouseIcon, TimelineProps, WalletIcon } from "@doorstead/components";
import { Timeline as DoorsteadTimeline } from "@doorstead/components/containers/Timeline";
import { useStep } from "../../../contexts/StepContext";
import { useSetStep } from "../../../contexts/StepContext/useSetStep";
import { useIsDefaultAutopayFeature } from "../../../hooks/useIsDefaultAutopayFeature";
import { useIsTPO } from "../../../hooks/useIsTPO";
import { useTimelineStepMapper } from "./useTimelineStepMapper";

export type Props = Omit<TimelineProps, "steps" | "currentStep">;

const autopayStepId = "set-up-autopay";

export function Timeline({ ...props }: Props) {
  const { step } = useStep();
  const { setStep } = useSetStep();
  const { stepToTimelineIndex, timelineIndexToStep } = useTimelineStepMapper();
  const isTPO = useIsTPO();
  const isDefaultAutopayFeature = useIsDefaultAutopayFeature();

  let steps = [
    {
      id: "view-lease-details",
      name: "View lease details",
      icon: <HelloIcon size={20} color="white" />,
    },
    {
      id: "add-payment-method",
      name: "Add payment method",
      icon: <WalletIcon size={20} color="white" />,
    },
    {
      id: "make-payments",
      name: "Make payments",
      icon: <MoneyHouseIcon size={20} color="white" />,
    },
    {
      id: autopayStepId,
      name: isDefaultAutopayFeature ? "Confirm autopay" : "Set up autopay",
      icon: <CalendarIcon size={20} color="white" />,
    },
  ];

  if (isTPO) {
    //remove set-up-autopay
    steps = steps.filter((step) => step.id !== autopayStepId);
  }

  return (
    <DoorsteadTimeline
      {...props}
      currentStep={stepToTimelineIndex(step)}
      onStepClick={(_, index) => setStep(timelineIndexToStep(index))}
      steps={steps}
    />
  );
}
