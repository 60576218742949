export enum PropertyType {
  SingleFamily = "single-family property",
  Condo = "condominium",
  Townhouse = "townhouse",
  MultiFamily = "multi-family property",
  ADU = "ADU",
}

export function getLabel(propertyType: PropertyType): string {
  switch (propertyType) {
    case PropertyType.SingleFamily:
      return "Single Family";
    case PropertyType.Condo:
      return "Condo";
    case PropertyType.Townhouse:
      return "Townhouse";
    case PropertyType.MultiFamily:
      return "Multi Family";
    case PropertyType.ADU:
      return "ADU";
    default:
      return "";
  }
}
