import { Flex } from "@chakra-ui/react";
import { DoorsteadProvider, MainLayout } from "@doorstead/components";
import React from "react";
import { useFooter } from "../../hooks/useFooter";

type Props = {
  logoLink: string;
  children: React.ReactNode;
};

export function EmptyLayout({ children, logoLink }: Props) {
  const header = {
    logoLink,
    links: [],
    avatarName: "",
  };
  const footer = useFooter();

  return (
    <DoorsteadProvider>
      <MainLayout header={header} footer={footer}>
        <Flex justifyContent="center" alignItems="center" w="100%" minH="calc(100vh - 118px - 110px)">
          {children}
        </Flex>
      </MainLayout>
    </DoorsteadProvider>
  );
}
