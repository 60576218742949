import { Box, VStack } from "@chakra-ui/react";
import { useIsTenantFlex } from "../../../../../hooks/useIsTenantFlex";
import { useOwnerInfo } from "../../../../../hooks/useOwnerInfo";

function EmailLink({ email }: { email: string }) {
  return (
    <Box as="a" href={`mailto:${email}`}>
      {email}
    </Box>
  );
}

function PhoneLink({ phone }: { phone: string }) {
  return (
    <Box as="a" href={`tel:${phone}`}>
      {phone}
    </Box>
  );
}

export function OwnerBox() {
  const isFlex = useIsTenantFlex();
  const owner = useOwnerInfo();
  if (!isFlex) return null;

  if (!owner) return <Box>No landlord information</Box>;

  return (
    <VStack w="100%" p={4} bgColor="gray.100" borderRadius={12} alignItems="flex-start">
      <Box fontWeight="bold">{owner.fullName}</Box>
      <Box>
        {owner.email && <EmailLink email={owner.email} />} {owner.email && owner.phone && "•"}{" "}
        {owner.phone && <PhoneLink phone={owner.phone} />}
      </Box>
    </VStack>
  );
}
