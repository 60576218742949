import { Divider, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useState } from "react";
import { Estimate } from "../../../../@types/RentReady/Estimate";
import { useEstimate } from "../../../../hooks/rentReady/useEstimate";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { FullScreen } from "../../FullScreen";
import { ChildEstimateBox } from "./ChildEstimateBox";
import { DecisionBox } from "./DecisionBox";
import { DecisionField } from "./DecisionField";
import { SaveButton } from "./SaveButton";

export function EstimateEditView() {
  const { setView } = useView();
  const initialEstimate = useEstimate();
  const [estimate, setEstimate] = useState<Estimate>(initialEstimate);
  const { uiName, decision } = estimate;
  const isOnline = useIsOnline();

  return (
    <FullScreen
      headerName={uiName}
      backView={View.EstimateListView}
      contentProps={{ p: 5 }}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(View.EstimateListView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={isOnline && <SaveButton initialEstimate={initialEstimate} estimate={estimate} />}
    >
      <Divider />
      <VStack w="100%" alignItems="flex-start">
        <ChildEstimateBox {...estimate} />
      </VStack>
      <Divider />
      <DecisionField
        decision={decision}
        onChange={(decision) =>
          setEstimate(
            produce((draft) => {
              draft.decision = decision;
            })
          )
        }
      />
      <DecisionBox estimate={estimate} onChange={setEstimate} />
    </FullScreen>
  );
}
