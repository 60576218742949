import { Box, Divider, HStack } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import { ThroughoutPropertyCleaning } from "../../../../@types/RentReady/ThroughoutPropertyCleaning";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

type Props = {
  value: ThroughoutPropertyCleaning["cleanIsOdorRemoval"];
  onChange: (value: ThroughoutPropertyCleaning["cleanIsOdorRemoval"]) => void;
};

export function CleaningIsOrderRemoval({ value, onChange }: Props) {
  const isReadOnly = useIsReadOnly();

  return (
    <>
      <Box mb={2} fontWeight="bold">
        Select, only if needed
      </Box>
      <Divider />
      <HStack h={10}>
        <Checkbox id="cleanIsOdorRemoval" value={value} disabled={isReadOnly} onChange={onChange} />
        <Box as="label" htmlFor="cleanIsOdorRemoval">
          Odor Removal
        </Box>
      </HStack>
    </>
  );
}
