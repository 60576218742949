import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useRRProjectId } from "./useRRProjectId";

export function useCreateRrProjectEvent() {
  const { gqlClient } = useAuth();
  const rrProjectId = useRRProjectId();

  return ({ event }: { event: string }) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($rrProjectId: UUID!, $event: String!) {
          createRrProjectEvent(rrProjectId: $rrProjectId, event: $event) {
            updateSuccess
          }
        }
      `,
      variables: {
        rrProjectId,
        event,
      },
    });
  };
}
