import { Flex, useMediaQuery } from "@chakra-ui/react";
import { Photo } from "../../../../../@types/RentReady/Photo";
import { useIsEditable } from "../../../../../hooks/rentReady/useIsEditable";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { PhotoThumbnailWithNumber } from "../../../PhotoThumbnailWithNumber";
import { ZeroPhotoThumbnail } from "../../../ZeroPhotoThumbnail";
import { CameraIcon } from "./CameraIcon";

type Props = {
  prefix: Prefix;
  values: Photo[];
  onThumbnailClick: () => void;
  onSelectFiles: (files: File[]) => void;
  isValid?: boolean;
};

export function Photos({ prefix, values, onSelectFiles, onThumbnailClick, isValid = true }: Props) {
  const isEditable = useIsEditable();
  const isMobile = useMediaQuery("(max-width: 355px)")[0];

  return (
    <Flex
      gap={2}
      alignItems="center"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      {isEditable && (
        <CameraIcon id={`${prefix}_camera_upload`} isValid={isValid} prefix={prefix} onSelectFiles={onSelectFiles} />
      )}
      {values.length === 0 && <ZeroPhotoThumbnail onClick={onThumbnailClick} />}
      {values.length >= 1 && (
        <PhotoThumbnailWithNumber photo={values[0]} onClick={onThumbnailClick} number={values.length} />
      )}
    </Flex>
  );
}
