import { ApolloClient, gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";

export async function removeEmployment(gqlClient: ApolloClient<any>, applicantEmploymentId: string) {
  await gqlClient.mutate({
    mutation: gql`
      mutation ($applicantEmploymentId: UUID) {
        deleteApplicantEmployment(applicantEmploymentId: $applicantEmploymentId) {
          result
        }
      }
    `,
    variables: {
      applicantEmploymentId,
    },
  });
}

export function useRemoveEmployment() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (applicantEmploymentId: string) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        const animalIndex = draft.applicantById.employments.edges.findIndex(
          (e) => e.node.applicantEmploymentId === applicantEmploymentId
        );
        draft.applicantById.employments.edges.splice(animalIndex, 1);
      })
    );

    return await removeEmployment(gqlClient, applicantEmploymentId);
  });
}
