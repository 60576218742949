import { gql } from "@apollo/client";
import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { useAuth } from "../../contexts/auth";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { mapStringToScale } from "../../utils/rentReady/mapStringToScale";
import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReportCache } from "./useInspectionReportCache";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  additionalKeyStorageNotes: InspectionReportNode["additionalKeyStorageNotes"];
  additionalKeyStoragePhotoUrlsJson: InspectionReportNode["additionalKeyStoragePhotoUrlsJson"];
  fullTenantSets: InspectionReportNode["fullTenantSets"];
  fullTenantSetsPhotoUrlsJson: InspectionReportNode["fullTenantSetsPhotoUrlsJson"];
  lockboxCode: InspectionReportNode["lockboxCode"];
  lockboxCodePhotoUrlsJson: InspectionReportNode["lockboxCodePhotoUrlsJson"];
  lockboxLocation: InspectionReportNode["lockboxLocation"];
  lockboxLocationPhotoUrlsJson: InspectionReportNode["lockboxLocationPhotoUrlsJson"];
  updatedAtKeyStorage: InspectionReportNode["updatedAtKeyStorage"];
};

type Result = {
  data: {
    updateInspectionReportKeyStorage: {
      success: boolean;
      merged: boolean;
      keyStorage: Args;
    };
  };
};

const mutation = gql`
  mutation (
    $inspectionReportId: UUID!
    $additionalKeyStorageNotes: String
    $additionalKeyStoragePhotoUrlsJson: String
    $fullTenantSets: String
    $fullTenantSetsPhotoUrlsJson: String
    $lockboxCode: String
    $lockboxCodePhotoUrlsJson: String
    $lockboxLocation: String
    $lockboxLocationPhotoUrlsJson: String
    $updatedAtKeyStorage: DateTime
  ) {
    updateInspectionReportKeyStorage(
      additionalKeyStorageNotes: $additionalKeyStorageNotes
      additionalKeyStoragePhotoUrlsJson: $additionalKeyStoragePhotoUrlsJson
      fullTenantSets: $fullTenantSets
      fullTenantSetsPhotoUrlsJson: $fullTenantSetsPhotoUrlsJson
      inspectionReportId: $inspectionReportId
      lockboxCode: $lockboxCode
      lockboxCodePhotoUrlsJson: $lockboxCodePhotoUrlsJson
      lockboxLocation: $lockboxLocation
      lockboxLocationPhotoUrlsJson: $lockboxLocationPhotoUrlsJson
      updatedAtKeyStorage: $updatedAtKeyStorage
    ) {
      success
      merged
      keyStorage {
        fullTenantSets
        fullTenantSetsPhotoUrlsJson
        lockboxCode
        lockboxCodePhotoUrlsJson
        lockboxLocation
        lockboxLocationPhotoUrlsJson
        additionalKeyStorageNotes
        additionalKeyStoragePhotoUrlsJson
        updatedAtKeyStorage
        __typename
      }
      __typename
    }
  }
`;

export function useMapKeyStorageToArgs() {
  const inspectionReportCache = useInspectionReportCache()!;

  return (keyStorage: KeyStorage): Args => {
    const fullTenantSetsPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyStorage.fullTenants.photos);
    const lockboxCodePhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyStorage.lockbox.photos);
    return {
      additionalKeyStorageNotes: keyStorage.additionalKeyStorage.note,
      additionalKeyStoragePhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyStorage.additionalKeyStorage.photos),
      fullTenantSets: mapStringToScale(keyStorage.fullTenants.number),
      fullTenantSetsPhotoUrlsJson,
      lockboxCode: keyStorage.lockbox.code,
      lockboxCodePhotoUrlsJson,
      lockboxLocation: keyStorage.lockboxLocation.location,
      lockboxLocationPhotoUrlsJson: mapPhotosToPhotoUrlsJson(keyStorage.lockboxLocation.photos),
      updatedAtKeyStorage: inspectionReportCache.updatedAtKeyStorage,
    };
  };
}

export function useUpdateKeyStorageToServer() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return async (args: Args) => {
    return (await gqlClient.mutate({
      mutation,
      variables: {
        ...args,
        inspectionReportId,
      },
    })) as Result;
  };
}
