import { Item } from "../../../../@types/RentReady/Item";
import { useItem } from "../../../../hooks/rentReady/useItem";
import { ItemMultipleTypeSelect } from "./ItemMultipleTypeSelect";
import { ItemSingleTypeSelect } from "./ItemSingleTypeSelect";

type Props = {
  values: Item["cosmeticOptions"];
  onChange: (values: Item["cosmeticOptions"]) => void;
};

export function ItemTypeSelect({ values, onChange }: Props) {
  const item = useItem();

  if (item === null) return null;
  else if (item.selectionType === "multiple") {
    return <ItemMultipleTypeSelect values={values} onChange={onChange} />;
  } else if (item.selectionType === "single") {
    return <ItemSingleTypeSelect values={values} onChange={onChange} />;
  } else {
    //this should never happen
    return null;
  }
}
