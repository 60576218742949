import { useState } from "react";
import { GOOGLE_MAP_KEY } from "../../../../config";
import { Provider as GoogleMapProvider } from "../../../../hooks/useGoogleMapKey";
import { GetStartedView } from "./GetStartedView/GetStartedView";
import { InternalStateProvider } from "./useInternalState";
import { View, ViewProvider } from "./useView";

// idea: to move out from setView, this is confusing when we are using react router already
export function PrimaryApplicantPreInCompleteCard() {
  const [howManyAdults, setHowManyAdults] = useState<number | null>(null);
  const [haveGuarantor, setHaveGuarantor] = useState<boolean | null>(null);

  return (
    <ViewProvider view={View.GetStartedView}>
      <GoogleMapProvider value={GOOGLE_MAP_KEY}>
        <InternalStateProvider
          value={{
            howManyAdults,
            setHowManyAdults,
            haveGuarantor,
            setHaveGuarantor,
          }}
        >
          <GetStartedView />
        </InternalStateProvider>
      </GoogleMapProvider>
    </ViewProvider>
  );
}
