import { Box, Flex, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { Repair } from "../../../@types/RepairList/Repair";
import { getEstimateDollarStr } from "../../../utils/rr-project/getEstimateDollarStr";
import { ExpandButton } from "../ExpandButton";
import { NoteField } from "../NoteField";
import { Photos } from "../Photos";
import { RepairChip } from "../RepairChip";
import { RepairOwnerNoteIcon } from "../RepairOwnerNoteIcon";
import { RepairStatusBox } from "../RepairStatusBox";

export function EstimatesToApproveCard(repair: Repair) {
  const { id, uiTitle, estimateNotes, ownerNotes, repairRequirement, description, photos } = repair;
  const { isOpen, onToggle } = useDisclosure();
  const estimateDollar = getEstimateDollarStr(repair);

  return (
    <VStack w="100%" color="gray.700">
      <Flex justifyContent="space-between" w="100%">
        <Box fontWeight="bold">{uiTitle}</Box>
        <RepairChip repairRequirement={repairRequirement} />
      </Flex>
      <HStack alignItems="center" justifyContent="space-between" w="100%">
        <RepairStatusBox repair={repair} />
        <Flex justifyContent="center" alignItems="center" h="40px" w="40px">
          <RepairOwnerNoteIcon value={ownerNotes} />
        </Flex>
      </HStack>
      <Flex flexDirection="column" justifyContent="flex-start" w="100%">
        <Box>{description}</Box>
        <Box>{estimateNotes}</Box>
      </Flex>
      <Flex w="100%" justifyContent="flex-start" gap={3} overflowX="scroll">
        <Photos urls={photos} mostShown={4} />
      </Flex>
      <ExpandButton isOpen={isOpen} onClick={onToggle} />
      {isOpen && (
        <Flex display={isOpen ? "block" : "none"} w="100%">
          <NoteField id={id} defaultValue={ownerNotes} />
        </Flex>
      )}
      {estimateDollar !== "" && (
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Box>Estimate:</Box>
          <Box>{estimateDollar}</Box>
        </Flex>
      )}
    </VStack>
  );
}
