import produce from "immer";
import { mapPhotoUrlsJsonToPhotos } from "./mapPhotoUrlsJsonToPhotos";
import { mapPhotosToPhotoUrlsJson } from "./mapPhotosToPhotoUrlsJson";

export function getMergedPhotos(clientPhotosStr: string | null, serverPhotosStr: string | null): string | null {
  const clientPhotos = mapPhotoUrlsJsonToPhotos(clientPhotosStr);
  const serverPhotos = mapPhotoUrlsJsonToPhotos(serverPhotosStr);

  return mapPhotosToPhotoUrlsJson(
    produce(serverPhotos, (draft) => {
      clientPhotos.forEach((photo) => {
        // If client photos are not uploaded, we save it
        if (!photo.isUploaded) {
          draft.push(photo);
        }
      });
    })
  );
}
