import { View } from "./useView";

export function getHasHeader(view: View): boolean {
  switch (view) {
    case View.PropertyDetailView:
    case View.AreaListView:
    case View.EstimateListView:
      return true;
    case View.AreaOneView:
    case View.ThroughoutPropertyCleaningEditView:
    case View.ThroughoutPropertyDetectorEditView:
    case View.StartView:
    case View.ExpiredView:
    case View.PropertyDetailEditView:
    case View.ItemListEditView:
    case View.EstimateEditView:
    case View.ComponentEditView:
    case View.AreaOverviewView:
    case View.ItemEditView:
    case View.AreaListEditView:
    case View.KeyDetailsView:
    case View.KeyStorageView:
      return false;
  }
}
