import produce from "immer";
import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";

export function useMapThroughoutPropertyDetectorFromRRGraphQLResultCache() {
  return (cache: RRGraphQLResultCache, state: ThroughoutPropertyDetector): RRGraphQLResultCache => {
    return produce(cache, (draft) => {
      if (draft !== null) {
        draft.inspectionReport.detectorCmIsAtLeastOneCoEachFloor = state.detectorCmIsAtLeastOneCoEachFloor;
        draft.inspectionReport.detectorCmIsAtLeastOneCoOutsideBedrooms = state.detectorCmIsAtLeastOneCoOutsideBedrooms;
        draft.inspectionReport.detectorCmPhotoUrlsJson = mapPhotosToPhotoUrlsJson(state.detectorCmPhotos);
        draft.inspectionReport.detectorSmokeIsAtLeastOneEachFloor = state.detectorSmokeIsAtLeastOneEachFloor;
        draft.inspectionReport.detectorSmokeIsAtLeastOneOutsideBedrooms =
          state.detectorSmokeIsAtLeastOneOutsideBedrooms;
        draft.inspectionReport.detectorSmokePhotoUrlsJson = mapPhotosToPhotoUrlsJson(state.detectorSmokePhotos);
      }
    });
  };
}
