import { Item } from "../../@types/RentReady/Item";
import { GoodMissingFixStatus } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { isThroughoutPropertyItemPhotosValid } from "./isThroughoutPropertyItemPhotosValid";

export function isThroughoutPropertyItemComplete(item: Item, shouldUseSimplifiedTemplate: boolean): boolean {
  switch (item.status) {
    case GoodMissingFixStatus.Good:
      return isThroughoutPropertyItemPhotosValid(item, shouldUseSimplifiedTemplate);
    case GoodMissingFixStatus.Missing:
      if (shouldUseSimplifiedTemplate) {
        return isThroughoutPropertyItemPhotosValid(item, shouldUseSimplifiedTemplate);
      }
      return isThroughoutPropertyItemPhotosValid(item, shouldUseSimplifiedTemplate) && item.note.trim() !== "";
    case GoodMissingFixStatus.Fix: {
      return isThroughoutPropertyItemPhotosValid(item, shouldUseSimplifiedTemplate) && item.note.trim() !== "";
    }
    case null:
      return false;
  }
}
