import { useIsProratedRentPaid } from "../../../../hooks/useIsProratedRentPaid";
import { useIsTPO } from "../../../../hooks/useIsTPO";
import { useIsShowSecurityDeposit } from "../WelcomeStep/useIsShowSecurityDeposit";
import { TPOPaidCard } from "./FlexPaidCard";
import { PaidCard } from "./PaidCard";
import { PayProratedRentCard } from "./PayProratedRentCard";
import { PaySecurityDepositCard } from "./PaySecurityDepositCard";

export function MakePaymentStep() {
  const isProratedRentPaid = useIsProratedRentPaid();
  const isShowSecurityDeposit = useIsShowSecurityDeposit();
  const isTPO = useIsTPO();

  if (isShowSecurityDeposit) {
    return <PaySecurityDepositCard />;
  }
  if (isTPO) {
    return <TPOPaidCard />;
  }
  if (!isProratedRentPaid) {
    return <PayProratedRentCard />;
  }
  return <PaidCard />;
}
