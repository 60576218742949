import produce from "immer";
import { useMutation } from "react-query";
import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { useInspectionReportId } from "./useInspectionReportId";
import { updateKeyStorageCache } from "./useUpdateKeyStorageCache";
import { useMapKeyStorageToArgs, useUpdateKeyStorageToServer } from "./useUpdateKeyStorageToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(keyStorage: KeyStorage): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  keyStorage.fullTenants.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.StorageFullTenant;
    photos.push(photo as PhotoWithPrefix);
  });

  keyStorage.additionalKeyStorage.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.AdditionalKeyStorage;
    photos.push(photo as PhotoWithPrefix);
  });

  keyStorage.lockbox.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.LockBox;
    photos.push(photo as PhotoWithPrefix);
  });

  return photos;
}

function useGetUploadedKeyStorage() {
  const upload = useUpload();

  return async (keyStorage: KeyStorage): Promise<KeyStorage> => {
    return produce(keyStorage, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveKeyStorageSubPage() {
  const inspectionReportId = useInspectionReportId();
  const updateKeyStorageToServer = useUpdateKeyStorageToServer();
  const mapKeyStorageToArgs = useMapKeyStorageToArgs();
  const getUploadedKeyStorage = useGetUploadedKeyStorage();

  return useMutation(async (keyStorage: KeyStorage) => {
    await updateKeyStorageCache(inspectionReportId, keyStorage);

    if (isOnline()) {
      const newKeyStorage = await getUploadedKeyStorage(keyStorage);
      const {
        data: {
          updateInspectionReportKeyStorage: { success, merged, keyStorage: returnedKeyStorage },
        },
      } = await updateKeyStorageToServer(mapKeyStorageToArgs(newKeyStorage));

      if (success || merged) {
        const cache = await readRRGraphQLResultCache(inspectionReportId);

        if (cache !== null) {
          cache.inspectionReport.fullTenantSets = returnedKeyStorage.fullTenantSets;
          cache.inspectionReport.fullTenantSetsPhotoUrlsJson = returnedKeyStorage.fullTenantSetsPhotoUrlsJson;

          cache.inspectionReport.lockboxCode = returnedKeyStorage.lockboxCode;
          cache.inspectionReport.lockboxCodePhotoUrlsJson = returnedKeyStorage.lockboxCodePhotoUrlsJson;
          cache.inspectionReport.lockboxLocation = returnedKeyStorage.lockboxLocation;

          //additionalKeyStorage
          cache.inspectionReport.additionalKeyStorageNotes = returnedKeyStorage.additionalKeyStorageNotes;
          cache.inspectionReport.additionalKeyStoragePhotoUrlsJson =
            returnedKeyStorage.additionalKeyStoragePhotoUrlsJson;

          cache.inspectionReport.updatedAtKeyStorage = returnedKeyStorage.updatedAtKeyStorage;

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, cache);
        }
      }
    }
  });
}
