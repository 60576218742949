import { StackProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export enum ApplicantTypeV2 {
  TENANT = "tenant",
  GUARANTOR = "guarantor",
}

export type NewApplicantV2 = {
  type: ApplicantTypeV2;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  disabled: boolean;
};

export type NewApplicantWithIdV2 = NewApplicantV2 & { id: string; disabled: boolean; removable: boolean };

export type Props = Omit<StackProps, "onChange"> & {
  values: NewApplicantWithIdV2[];
  duplicatedEmails: Set<string>;
  title: string;
  subTitle: ReactNode;
  eventName: string;
  addLabel: string;
  numberLabel: string;
  borderRadius?: number;
  onAdd: () => void;
  onChange: (applicant: Partial<NewApplicantV2>, id: string) => void;
  onRemove: (id: string) => void;
};
