import { Photo } from "../../../../../@types/RentReady/Photo";
import { AreaNode } from "../../../../../hooks/rentReady/useFetchInspectionReport/AreaNode";
import { useSyncAreaPhotosToCacheAndServer } from "../../../../../hooks/rentReady/useSyncAreaPhotosToCacheAndServer";
import { useUpdatePartialAreaToCache } from "../../../../../hooks/rentReady/useUpdatePartialAreaToCache";
import { ArgsArea } from "../../../../../hooks/rentReady/useUpdatePartialAreaToServer";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { useIsOnline } from "../../../../../hooks/useIsOnline";
import { mapPhotosToPhotoUrlsJson } from "../../../../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { PhotosField } from "../../../fields/PhotosField";

type Props = {
  isValid?: boolean;
  prefix: Prefix;
  values: Photo[];
  cacheField: keyof AreaNode;
  serverField: keyof ArgsArea;
};
export function AreaAutoSyncPhotosField({ isValid = true, prefix, cacheField, serverField, values }: Props) {
  const syncPhotosToCacheAndServer = useSyncAreaPhotosToCacheAndServer();
  const updatePartialAreaToCache = useUpdatePartialAreaToCache();
  const isOnline = useIsOnline();

  return (
    <PhotosField
      label=""
      prefix={prefix}
      values={values}
      isValid={isValid}
      onChange={async (photos) => {
        if (isOnline) {
          await syncPhotosToCacheAndServer(cacheField, serverField, prefix, photos);
        } else {
          await updatePartialAreaToCache({
            field: cacheField,
            value: mapPhotosToPhotoUrlsJson(photos),
          });
        }
      }}
    />
  );
}
