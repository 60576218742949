import { Box, Flex, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { ExclamationFilledIcon } from "@doorstead/components/icons/ExclamationFilledIcon";

type Props = {
  onAdd: () => void;
};

export function NoDataBox({ onAdd }: Props) {
  return (
    <Flex w="100%" justifyContent="center" alignItems="center">
      <VStack gap={5}>
        <ExclamationFilledIcon size={39} />
        <Box fontWeight="bold" color="gray.700" fontSize="xl">
          Add your first payment method
        </Box>
        <OutlineButton onClick={onAdd}>Add payment method</OutlineButton>
      </VStack>
    </Flex>
  );
}
