import { Box, Flex, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { Checkbox, Modal, TextArea } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import React, { useContext, useState } from "react";
import { useCreateSurvey } from "./useCreateSurvey";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function ShareDIYOrRejectThoughtModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

type Question = {
  label: string;
  value: string;
};

const otherValue = "Other";
const questions: Question[] = [
  { label: "I have my own handyman to complete repairs", value: "I have my own handyman to complete repairs" },
  { label: "I can complete repairs myself", value: "I can complete repairs myself" },
  { label: "I don’t agree with some of the repairs", value: "I don’t agree with some of the repairs" },
  { label: "I don’t agree with some of the estimates", value: "I don’t agree with some of the estimates" },
  { label: "Other", value: otherValue },
];

function Component() {
  const { isOpen, onClose } = useContext(context);
  const [surveyOptions, setSurveyOptions] = useState<string[]>([]);
  const [surveyOtherNotes, setSurveyOtherNotes] = useState<string>("");
  const [surveyNotes, setSurveyNotes] = useState<string>("");
  const { mutate, isLoading } = useCreateSurvey();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box paddingX={4} paddingY={8}>
        <Flex fontSize="xl" justifyContent="center" alignItems="center" color="gray.700" fontWeight="bold">
          Share your thoughts
        </Flex>
        <VStack alignItems="flex-start" spacing={3}>
          <Box color="gray.700" mt={3} fontWeight="semibold">
            Please let us know why you decided to reject or DIY one or more repairs:
          </Box>
          <VStack spacing={3}>
            {questions.map(({ value, label }) => (
              <HStack key={value} w="100%">
                <Checkbox
                  id={value}
                  value={surveyOptions.includes(value)}
                  onChange={(checked) => {
                    //toggle the values, if already in the array, remove it, otherwise add it
                    if (checked) {
                      //if value already in the values, we have to splice it otherwise, we append it
                      setSurveyOptions(
                        produce((draft) => {
                          const index = draft.indexOf(value);
                          if (index > -1) {
                            draft.splice(index, 1);
                          } else {
                            draft.push(value);
                          }
                        })
                      );
                    } else {
                      setSurveyOptions(surveyOptions.filter((v) => v !== value));
                    }
                  }}
                />
                <Box color="gray.500">{label}</Box>
              </HStack>
            ))}
          </VStack>
          {surveyOptions.includes(otherValue) && (
            <TextArea placeholder="Please describe here" value={surveyOtherNotes} onChange={setSurveyOtherNotes} />
          )}
          <VStack w="100%">
            <HStack w="100%">
              <Box color="gray.700" fontWeight="semibold">
                Additional Notes
              </Box>
              <Box color="gray.400">(Optional)</Box>
            </HStack>
            <TextArea placeholder="Your notes here" value={surveyNotes} onChange={setSurveyNotes} />
          </VStack>
        </VStack>
      </Box>
      <Flex mt={3} justifyContent="center" alignItems="center" w="100%">
        <PrimaryButton
          size="md"
          onClick={() => {
            mutate(
              {
                surveyOptions,
                surveyOtherNotes,
                surveyNotes,
              },
              {
                onSettled: onClose,
              }
            );
          }}
          isLoading={isLoading}
        >
          Submit
        </PrimaryButton>
      </Flex>
    </Modal>
  );
}

export function useShareSurveyModal() {
  return useContext(context);
}
