import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Args = {
  itemId: string;
};

export function useClearItemPhotosAndNotes() {
  const { gqlClient } = useAuth();

  return async ({ itemId }: Args) => {
    await gqlClient.mutate({
      mutation: gql`
        mutation ($items: [UpdateInspectionReportItemsDataInput]) {
          updateInspectionReportItemsData(items: $items) {
            count
          }
        }
      `,
      variables: {
        items: [
          {
            itemDataId: itemId,
            notes: "",
            photoUrlsJson: "[]",
          },
        ],
      },
    });
  };
}
