import React from "react";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const context = React.createContext<Props>({
  loading: false,
  setLoading() {
    console.log("setLoading");
  },
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function useInternalState() {
  return React.useContext(context);
}
