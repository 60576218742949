import { useDisclosure } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { Estimate } from "../../../@types/RentReady/Estimate";
import { useInspectionReport } from "../../../hooks/rentReady/useInspectionReport";
import { useIsNoEstimateData } from "../../../hooks/rentReady/useIsNoEstimateData";
import { useUpdateInspectionReportSubmittedTime } from "../../../hooks/rentReady/useUpdateInspectionReportSubmittedTime";
import { View, useView } from "../../../hooks/rentReady/useView";
import { useSuccess } from "../../../hooks/useSuccess";
import { AlertDialog } from "../AlertDialog";

type Props = {
  estimates: Estimate[];
};

function useIsValueValid(): boolean {
  const noEstimateData = useIsNoEstimateData();
  const { estimateStartDate, estimateJobTimeInDays } = useInspectionReport();
  if (noEstimateData) {
    return true;
  }
  return estimateStartDate !== null && estimateJobTimeInDays !== null;
}

export function SubmitButton({ estimates }: Props) {
  const { mutate: updateInspectionReportSubmittedTime, isLoading } = useUpdateInspectionReportSubmittedTime();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setView } = useView();
  const success = useSuccess();
  const isValueValid = useIsValueValid();
  const disabled = !isValueValid || isLoading || estimates.some((e) => e.decision === null);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        title="Confirm Submission"
        description="Please confirm that you would like to submit the property evaluation and repair estimates"
        submitButton={
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => {
              updateInspectionReportSubmittedTime({} as any, {
                onSuccess() {
                  setView(View.PropertyDetailView);
                  success({
                    title: "Submitted Successfully",
                  });
                },
                onSettled() {
                  onClose();
                },
              });
            }}
          >
            Confirm
          </PrimaryButton>
        }
      />
      <PrimaryButton
        isFullWidth
        isLoading={isLoading}
        disabled={disabled}
        onClick={() => {
          onOpen();
        }}
      >
        Submit Estimates
      </PrimaryButton>
    </>
  );
}
