import { Option } from "../../@types/RentReady/Komponent";

export function sortOptionsById(options: Option[]): Option[] {
  return [...options].sort((a, b) => a.id.localeCompare(b.id));
}

export function isOptionsDiff(options1: Option[], options2: Option[]): boolean {
  // Check if the lengths of the arrays are different
  if (options1.length !== options2.length) {
    return true;
  }

  // Iterate over each option in the sorted arrays
  for (let i = 0; i < options1.length; i++) {
    const option1 = options1[i];
    const option2 = options2[i];

    // Compare the properties of the options
    if (!areOptionsEqual(option1, option2)) {
      return true;
    }
  }

  return false;
}

export function areOptionsEqual(option1: Option, option2: Option): boolean {
  // Compare the name and selected properties of the options
  return option1.id === option2.id && option1.name === option2.name && option1.selected === option2.selected;
}
