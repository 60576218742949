import { useLiveQuery } from "dexie-react-hooks";
import { RRGraphQLResultCache, db } from "../../databases/RentReadyDatabase";
import { useInspectionReportId } from "./useInspectionReportId";

export const queryKey = "useRRGraphQLResultCache";

export function useRRGraphQLResultCache() {
  const inspectionReportId = useInspectionReportId();

  return useLiveQuery(async (): Promise<RRGraphQLResultCache | null | undefined> => {
    return db.rr_graphql_result.where("inspection_report_id").equals(inspectionReportId).first();
  });
}
