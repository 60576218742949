import { Box, Flex, HStack } from "@chakra-ui/react";
import { useFeeName } from "src/hooks/useFeeName";
import { useIsZtpo } from "../../../../hooks/applicants/useIsZtpo";
import { LimitedTimeDiscountBox } from "./LimitedTimeDiscountBox";
import { PriceBox } from "./PriceBox";

export function ApplicantFeeDesktopBox() {
  const isZtpo = useIsZtpo();
  const feeName = useFeeName();
  return (
    <Flex w="100%" justifyContent="space-between" bgColor="#F7FAFC" borderRadius={6} p={4} fontWeight="bold">
      <HStack>
        <Box color="#1A202C">{feeName}</Box>
        {isZtpo && <LimitedTimeDiscountBox />}
      </HStack>
      <HStack color="#1A202C">
        <PriceBox /> <Box>per applicant</Box>
      </HStack>
    </Flex>
  );
}
