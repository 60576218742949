import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Item } from "../../../../@types/RentReady/Item";
import { writeRRItemOpen } from "../../../../databases/RentReadyDatabase";
import { useClearItemPhotosAndNotes } from "../../../../hooks/rentReady/useClearItemPhotosAndNotes";
import { useFetchInspectionReport } from "../../../../hooks/rentReady/useFetchInspectionReport";
import { GoodMissingFixStatus } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useSyncSingleItemFieldToCacheAndServer } from "../../../../hooks/rentReady/useSyncSingleItemFieldToCacheAndServer";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { useStatusFixConfirmationDrawer } from "../../../../hooks/rr-project/useStatusFixConfirmationDrawer";
import { isNoteValid } from "../../../../utils/rentReady/isNoteValid";
import { isThroughoutPropertyItemComplete } from "../../../../utils/rentReady/isThroughoutPropertyItemComplete";
import { isThroughoutPropertyItemPhotosValid } from "../../../../utils/rentReady/isThroughoutPropertyItemPhotosValid";
import { ArrowOpenIcon } from "../../../ArrowOpenIcon";
import { RequiredLabel } from "../../RequiredLabel";
import { GoodMissingFixButtons } from "./GoodMissingFixButtons";
import { ItemAutoSyncPhotosField } from "./ItemAutoSyncPhotosField";
import { ItemAutoSyncTextField } from "./ItemAutoSyncTextField";

type Props = {
  item: Item;
  defaultOpen: boolean;
};

export function useUpdateItemStatus() {
  const syncSingleItemFieldToCacheAndServer = useSyncSingleItemFieldToCacheAndServer();

  return useMutation(({ item, status }: { item: Item; status: GoodMissingFixStatus }) => {
    return syncSingleItemFieldToCacheAndServer(item, "itemDataStatus", "status", status, status);
  });
}

export function ItemRowThroughoutProperty({ item, defaultOpen }: Props) {
  const { refetch } = useFetchInspectionReport();
  const { onOpen: openStatusConfirmation } = useStatusFixConfirmationDrawer();
  const { id, uiName, photos, note, status: defaultStatus } = item;
  const { mutate: updateItemStatus } = useUpdateItemStatus();
  const [status, setStatus] = useState<GoodMissingFixStatus | null>(defaultStatus);
  const [isOpen, setOpen] = useState(defaultOpen);
  const clearItemPhotosAndNotes = useClearItemPhotosAndNotes();
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const onStatusChanged = async (status: GoodMissingFixStatus) => {
    await updateItemStatus({ item, status });
    setOpen(status === GoodMissingFixStatus.Missing || status === GoodMissingFixStatus.Fix);
    setStatus(status);

    if (defaultStatus !== null) {
      await clearItemPhotosAndNotes({ itemId: item.id });
    }
    await refetch();
  };

  return (
    <>
      <VStack w="100%" p={4} id={item.id}>
        <Flex w="100%" justifyContent="space-between">
          <Flex justifyContent="center" alignItems="center" fontSize="sm" fontWeight="bold">
            <RequiredLabel
              hasValue={isThroughoutPropertyItemComplete({ ...item, status }, shouldUseSimplifiedTemplate)}
            >
              {uiName}
            </RequiredLabel>
          </Flex>
          <HStack>
            <GoodMissingFixButtons
              value={status}
              onChange={async (toStatus) => {
                if (status === GoodMissingFixStatus.Missing && toStatus !== GoodMissingFixStatus.Missing) {
                  openStatusConfirmation(item, status, toStatus, () => onStatusChanged(toStatus));
                } else if (status === GoodMissingFixStatus.Fix && toStatus !== GoodMissingFixStatus.Fix) {
                  openStatusConfirmation(item, status, toStatus, () => onStatusChanged(toStatus));
                } else {
                  await onStatusChanged(toStatus);
                }
              }}
            />
            <ArrowOpenIcon
              isOpen={isOpen}
              onClick={() => {
                setOpen((isOpen) => {
                  const toIsOpen = !isOpen;
                  writeRRItemOpen(item.id, toIsOpen);
                  return toIsOpen;
                });
              }}
            />
          </HStack>
        </Flex>
      </VStack>
      <Box px={5} display={isOpen ? "flex" : "none"} w="100%" justifyContent="space-between" alignItems="flex-start">
        <Box>
          {status !== GoodMissingFixStatus.Missing && (
            <ItemAutoSyncPhotosField
              prefix={`${Prefix.Items}_${id}` as Prefix}
              item={item}
              isValid={isThroughoutPropertyItemPhotosValid(
                {
                  ...item,
                  status: status,
                },
                shouldUseSimplifiedTemplate
              )}
              values={photos}
              cacheField="itemPhotoUrlsJson"
              serverField="photoUrlsJson"
            />
          )}
        </Box>
        <Box>
          <ItemAutoSyncTextField
            isValid={shouldUseSimplifiedTemplate ? true : isNoteValid(status, note)}
            item={item}
            defaultValue={note}
            cacheField="itemNotes"
            serverField="notes"
          />
        </Box>
      </Box>
    </>
  );
}
