import { Repair } from "../../@types/RepairList/Repair";
import { OwnerDecision, RepairStage } from "./useFetchRRProject";
import { useRepairs } from "./useRepairs";

export function useDIYRepairs(): Repair[] {
  const repairs = useRepairs();

  return repairs.filter(
    ({ ownerDecision, stage }) => ownerDecision === OwnerDecision.DIY && stage === RepairStage.WorkInProgress
  );
}
