import { Box, Divider, Flex, HStack } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useEffect, useState } from "react";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Area } from "../../../../@types/RentReady/Area";
import { useArea } from "../../../../hooks/rentReady/useArea";
import { useAreaId } from "../../../../hooks/rentReady/useAreaId";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { useIsThroughoutPropertyFeature } from "../../../../hooks/rentReady/useIsThroughoutPropertyFeature";
import { useSaveAreaSubPage } from "../../../../hooks/rentReady/useSaveAreaSubPage";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { isAreaDiff } from "../../../../utils/rentReady/isAreaDiff";
import { useIsAreaDetectorFeature } from "../../../../utils/rentReady/useIsAreaDetectorFeature";
import { FullScreen } from "../../FullScreen";
import { YesNoButtons } from "../../YesNoButtons";
import { PhotosField } from "../../fields/PhotosField";
import { TextAreaField } from "../../fields/TextAreaField";
import { AreaNotesField } from "../AreaOneView/AreaNotesField";

function Content({ area: initArea }: { area: Area }) {
  const { id, uiName } = initArea;
  const { setView } = useView();
  const { setAreaId } = useAreaId();
  const success = useSuccess();
  const error = useError();
  const isEditable = useIsEditable();
  const isReadOnly = useIsReadOnly();
  const [area, setArea] = useState<Area>(initArea);
  const { isLoading, mutate } = useSaveAreaSubPage();
  const isThroughoutPropertyFeature = useIsThroughoutPropertyFeature();
  const isAreaDetectorFeature = useIsAreaDetectorFeature();
  useDisplayInstruction(View.AreaOverviewView);
  useEffect(() => {
    setArea(initArea);
  }, [JSON.stringify(initArea)]);
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  const {
    itemRemovalNotes,
    cleaningNotes,
    paintingNotes,
    cleanLight,
    cleanDeep,
    cleanOdors,
    cleanPersonalItemRemoval,
    painting,
    notes,
    smokeDetectorIsOneWorkingDetectorFound,
    smokeDetectorNotes,
    itemRemovalPhotos,
  } = area;

  const isSaveDisabled =
    area.photos.length < 2 ||
    isLoading ||
    (isAreaDetectorFeature && smokeDetectorIsOneWorkingDetectorFound === null) ||
    (cleanPersonalItemRemoval && itemRemovalPhotos.length === 0) ||
    !isAreaDiff(area, initArea);
  const backView = area.photos.length > 0 ? View.AreaOneView : View.AreaListView;

  return (
    <FullScreen
      contentProps={{ p: 5 }}
      headerName={`${uiName}: Overview`}
      backView={backView}
      backButton={
        <OutlineButton
          isFullWidth
          disabled={isLoading}
          onClick={() => {
            if (area.photos.length > 0) {
              setView(View.AreaOneView);
            } else {
              setView(View.AreaListView);
            }
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            disabled={isSaveDisabled}
            isLoading={isLoading}
            onClick={() => {
              mutate(
                { ...area },
                {
                  onSuccess() {
                    success({ title: "Area updated!" });
                    setAreaId(id);
                    setView(View.AreaOneView);
                  },
                  onError() {
                    error({
                      title: "Error updating area",
                      description: "There was an error updating the area. Please try again.",
                    });
                  },
                }
              );
            }}
          >
            Next
          </PrimaryButton>
        )
      }
    >
      {!shouldUseSimplifiedTemplate && (
        <Flex w="100%" justifyContent="space-between" alignItems="flex-end">
          <Box>
            <PhotosField
              label="Area Photos"
              prefix={Prefix.Areas}
              values={area.photos}
              onChange={(photos) => {
                setArea({ ...area, photos });
              }}
            />
          </Box>
          <Box>
            <AreaNotesField
              value={notes}
              onChange={(notes) => {
                setArea({ ...area, notes });
              }}
              disabled={isReadOnly}
            />
          </Box>
        </Flex>
      )}
      {!isThroughoutPropertyFeature && (
        <>
          <Box fontWeight="bold">Cleaning</Box>
          <HStack w="100%">
            <Checkbox
              id="cleanLight"
              value={cleanLight}
              disabled={isReadOnly}
              onChange={(cleanLight) => {
                setArea({ ...area, cleanLight });
              }}
            />
            <Box as="label" htmlFor="cleanLight">
              Light Clean
            </Box>
          </HStack>
          <HStack w="100%">
            <Checkbox
              id="cleanDeep"
              value={cleanDeep}
              disabled={isReadOnly}
              onChange={(cleanDeep) => {
                setArea({ ...area, cleanDeep });
              }}
            />
            <Box as="label" htmlFor="cleanDeep">
              Deep Clean
            </Box>
          </HStack>

          <HStack w="100%">
            <Checkbox
              id="Odors"
              value={cleanOdors}
              disabled={isReadOnly}
              onChange={(cleanOdors) => {
                setArea({ ...area, cleanOdors });
              }}
            />
            <Box as="label" htmlFor="Odors">
              Odors
            </Box>
          </HStack>
          <TextAreaField
            label=""
            value={cleaningNotes}
            placeholder="Notes on cleaning details"
            onChange={(cleaningNotes) => {
              setArea({ ...area, cleaningNotes });
            }}
            disabled={isReadOnly}
          />
        </>
      )}
      {isAreaDetectorFeature && (
        <>
          <Box fontWeight="bold">Smoke Detector Inside Bedroom (required)</Box>
          <Divider />
          <Flex w="100%" justifyContent="space-between">
            <Box>1 working detector found?*</Box>
            <Box>
              <YesNoButtons
                value={smokeDetectorIsOneWorkingDetectorFound}
                onChange={(smokeDetectorIsOneWorkingDetectorFound) => {
                  setArea({ ...area, smokeDetectorIsOneWorkingDetectorFound });
                }}
              />
            </Box>
          </Flex>
          <TextAreaField
            label=""
            value={smokeDetectorNotes}
            placeholder="Notes on smoke detectors"
            onChange={(smokeDetectorNotes) => {
              setArea({ ...area, smokeDetectorNotes });
            }}
            disabled={isReadOnly}
          />
        </>
      )}
      <Box fontWeight="bold">Personal Item Removal</Box>
      <Flex w="100%" justifyContent="space-between">
        <HStack w="100%">
          <Checkbox
            id="cleanPersonalItemRemoval"
            value={cleanPersonalItemRemoval}
            disabled={isReadOnly}
            onChange={(cleanPersonalItemRemoval) => {
              setArea({ ...area, cleanPersonalItemRemoval });
            }}
          />
          <Box as="label" htmlFor="cleanPersonalItemRemoval">
            Personal item removal
          </Box>
        </HStack>
        {cleanPersonalItemRemoval && (
          <Box w="100%">
            <PhotosField
              label=""
              prefix={Prefix.AreasPersonalItemRemovals}
              values={area.itemRemovalPhotos}
              onChange={(itemRemovalPhotos) => {
                setArea({ ...area, itemRemovalPhotos });
              }}
            />
          </Box>
        )}
      </Flex>
      <TextAreaField
        label=""
        value={itemRemovalNotes}
        placeholder="Notes on personal item removal"
        onChange={(itemRemovalNotes) => {
          setArea({ ...area, itemRemovalNotes });
        }}
        disabled={isReadOnly}
      />
      <Box fontWeight="bold">Painting</Box>
      <HStack w="100%">
        <Checkbox
          id="fullPainting"
          value={painting}
          disabled={isReadOnly}
          onChange={(painting) => {
            setArea({ ...area, painting });
          }}
        />
        <Box as="label" htmlFor="fullPainting">
          Full area painting
        </Box>
      </HStack>
      <AreaNotesField
        value={paintingNotes}
        onChange={(paintingNotes) => {
          setArea({ ...area, paintingNotes });
        }}
        disabled={isReadOnly}
      />
    </FullScreen>
  );
}

export function AreaOverviewView() {
  const area = useArea();
  if (area === null) return null;

  return <Content area={area} />;
}
