import { SelectField, Props as SelectFieldProps } from "@doorstead/components/fields/SelectField";
import { useState } from "react";

export type Props = SelectFieldProps & {
  value: string;
  validateOnBlur?: boolean;
  validator?: (value: string) => boolean;
};

export function ApplicantSelectField({ options = [], value = "", validator, validateOnBlur = true, onChange }: Props) {
  const [isValid, setIsValid] = useState<boolean>(true);

  return (
    <SelectField
      id="type"
      label="Type"
      value={value}
      isError={!isValid}
      options={options}
      onChange={onChange}
      onBlur={() => {
        // run validation on blur if set
        if (validateOnBlur && validator) {
          const result = validator(value);
          setIsValid(!!result);
        }
      }}
    />
  );
}
