import { Box, Center, HStack, Stack, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useSearchParams } from "react-router-dom";
import { useLogClickEvent } from "src/hooks/useLogClickEvent";
import { useAddress } from "../../../hooks/applicants/useAddress";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { useUpdateApplicantField } from "../../../hooks/applicants/useUpdateApplicantField";
import { useGoogleMapKey } from "../../../hooks/useGoogleMapKey";
import { getSegmentedAddress } from "../../../hooks/useSegmentedAddress";
import { useTrackImpressionEvent } from "../../../hooks/useTrackImpressionEvent";
import { ApplicantFeeBox } from "./ApplicantFeeBox/ApplicantFeeBox";
import { ThankYouBox } from "./ApplicantFeeBox/ThankYouBox";
import { InfoGrid } from "./InfoGrid";

export function ApplicantLandingCardV2() {
  const isMobile = useIsMobile();
  const address = useAddress();
  const googleMapKey = useGoogleMapKey();
  const {
    doorsteadUser,
    applicantId,
    applicationRequest: { property, isTpoHideApplicationFeeFeature },
  } = useFetchedApplicant();
  const [seg1, seg2] = getSegmentedAddress(property);
  const me = useFetchedApplicationMe();
  const { mutate: updateApplicantField, isLoading } = useUpdateApplicantField();
  const logClickEvent = useLogClickEvent();
  const hideApplicationFees = isTpoHideApplicationFeeFeature || false;
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source") || "";

  useApplicantPageView("TP_APPLICATION_COVERPAGE_V2_VIEWED");

  useTrackImpressionEvent({
    data: {
      ownerId: doorsteadUser.doorsteadUserId,
      applicantId: applicantId,
      applicationRequestId: me.applicantById.applicationRequest.applicationRequestId,
      applicationRequest: me.applicantById.applicationRequest,
      source: source,
      hideApplicationFees,
    },
    propertyId: property.propertyId,
    email: doorsteadUser.email,
    action: "TP_APPLICATION_COVERPAGE_V2_VIEWED_RDS",
    surface: "APPLICATION_PORTAL",
  });

  return (
    <VStack w="100%" alignItems="center" spacing={5}>
      {me.applicantById.doorsteadUser.firstName && (
        <Center fontWeight="bold" color="#1A202C" fontSize="3xl">
          Hello, {me.applicantById.doorsteadUser.firstName}
        </Center>
      )}
      {isMobile && (
        <PrimaryButton
          isLoading={isLoading}
          onClick={async () => {
            await updateApplicantField({
              coverPageGetStartedAt: new Date().toISOString(),
            });
            await logClickEvent({
              email: doorsteadUser.email || "",
              propertyId: property.propertyId || "",
              data: {
                property,
                first_name: doorsteadUser.firstName,
                address: property.fullAddress,
                doorsteadUserId: doorsteadUser.doorsteadUserId,
                applicantId: me.applicantById.applicantId,
                hideApplicationFees,
                source: source,
              },
              action: "TP_APPLICATION_COVERPAGE_V2_GET_STARTED_CLICKED",
              surface: "APPLICANT_PORTAL",
            });
          }}
        >
          Get started
        </PrimaryButton>
      )}

      <VStack w="100%" alignItems="flex-start" bgColor="white" borderRadius={20} p={isMobile ? 4 : 10} spacing={5}>
        <HStack w="100%" alignItems="center" spacing={5}>
          {!isMobile && (
            <Box w="fit-content" h="100%">
              <GoogleMapImage
                borderRadius={6}
                width={isMobile ? 322 : 240}
                height={isMobile ? 220 : 220}
                address={address}
                googleKey={googleMapKey}
              />
            </Box>
          )}
          <VStack alignItems="flex-start" w="100%" spacing={3}>
            <Box color="gray.500" fontSize="md" lineHeight="24px">
              Rental property address
            </Box>
            <VStack spacing={0} alignItems="flex-start" fontWeight="bold" fontSize="xl" color="gray.700">
              <Box>{seg1}</Box>
              <Box>{seg2}</Box>
            </VStack>
            <InfoGrid />
          </VStack>
        </HStack>
        {!isMobile && <ThankYouBox />}
        <Stack
          direction={isMobile || hideApplicationFees ? "column" : "row"}
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          {!hideApplicationFees && <ApplicantFeeBox />}
          {isMobile && <ThankYouBox />}
          {!isMobile && (
            <PrimaryButton
              isLoading={isLoading}
              size="lg"
              onClick={async () => {
                await updateApplicantField({
                  coverPageGetStartedAt: new Date().toISOString(),
                });
                await logClickEvent({
                  email: doorsteadUser.email || "",
                  propertyId: property.propertyId || "",
                  data: {
                    property,
                    first_name: doorsteadUser.firstName,
                    address: property.fullAddress,
                    doorsteadUserId: doorsteadUser.doorsteadUserId,
                    applicantId: me.applicantById.applicantId,
                    source: source,
                    hideApplicationFees,
                  },
                  action: "TP_APPLICATION_COVERPAGE_V2_GET_STARTED_CLICKED",
                  surface: "APPLICANT_PORTAL",
                });
              }}
            >
              Get started
            </PrimaryButton>
          )}
        </Stack>
      </VStack>
    </VStack>
  );
}
