import { Box, Flex, Grid, GridItem, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { Repair } from "../../../@types/RepairList/Repair";
import { ArrowOpenIcon } from "../../ArrowOpenIcon";
import { DescriptionBox } from "../DescriptionBox";
import { DescriptionText } from "../DescriptionText";
import { NoteField } from "../NoteField";
import { Photos } from "../Photos";
import { RepairChip } from "../RepairChip";
import { RepairOwnerNoteIcon } from "../RepairOwnerNoteIcon";
import { RepairStatusBox } from "../RepairStatusBox";
import { templateColumns } from "./DoorsteadManagedRepairs";

export function DoorsteadManagedRow(repair: Repair) {
  const { id, uiTitle, repairRequirement, description, photos, ownerNotes } = repair;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Grid templateColumns={templateColumns} w="100%" gap={4}>
      <GridItem color="black">
        <HStack w="100%">
          <VStack alignItems="flex-start">
            <HStack>
              <HStack color="gray.800" justifyContent="center" alignItems="center" spacing={2}>
                <ArrowOpenIcon isOpen={isOpen} onClick={onToggle} />
                <Box>{uiTitle}</Box>
              </HStack>
              <RepairChip repairRequirement={repairRequirement} />
            </HStack>
            <DescriptionBox isOpen={isOpen} pl={10}>
              <VStack alignItems="flex-start">
                <DescriptionText isOpen={isOpen}>{description}</DescriptionText>
                <Box display={isOpen ? "block" : "none"} w="100%">
                  <NoteField id={id} defaultValue={ownerNotes} />
                </Box>
              </VStack>
            </DescriptionBox>
          </VStack>
        </HStack>
      </GridItem>
      <GridItem color="gray.500">
        <Photos urls={photos} mostShown={3} />
      </GridItem>
      <GridItem color="gray.600">
        <RepairStatusBox repair={repair} />
      </GridItem>
      <GridItem as="button" display="flex" alignItems="flex-start" justifyContent="center">
        <Flex justifyContent="center" alignItems="center" h="42px" w="100%" onClick={onToggle}>
          <RepairOwnerNoteIcon value={ownerNotes} />
        </Flex>
      </GridItem>
    </Grid>
  );
}
