import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth";

const withoutAuth = (WrappedComponent: React.ComponentType) => {
  return (props: any) => {
    const { loading, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!loading && user) {
        navigate("/");
      }
    }, [loading, user]);

    if (loading || user) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withoutAuth;
