import { Komponent } from "../../@types/RentReady/Komponent";
import { useClearComponent } from "./useClearComponent";
import { useOpenClearDefectDrawer } from "./useOpenClearDefectDrawer";

type Args = {
  component: Komponent;
};

export function useComponentActionClick({ component }: Args) {
  const { mutate: clearComponent } = useClearComponent();
  const openClearDefectDrawer = useOpenClearDefectDrawer();

  return () => {
    openClearDefectDrawer({
      onConfirm() {
        clearComponent({
          componentId: component.id,
        });
      },
    });
  };
}
