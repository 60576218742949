import { LoginScreen as DoorsteadLoginScreen, DoorsteadProvider, S3_BUCKET } from "@doorstead/components";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { processAuthError, useAuth } from "src/contexts/auth";
import { usePageView } from "src/hooks/usePageView";
import withoutAuth from "src/utils/withoutAuth";

function LoginPage() {
  // https://auth0.com/docs/customize/email/email-templates/email-template-descriptions
  const [params] = useSearchParams();
  const initialEmail = params.get("email");
  const success = params.get("success");
  const message = params.get("message") || "";
  const [email, setEmail] = useState(initialEmail || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { loginWithPassword } = useAuth();
  const analytics = usePageView("TP_LOGIN_VIEWED");
  const successMessage = success ? message : "";

  const passwordLogin = async () => {
    try {
      setLoading(true);
      analytics?.track("TP_LOGIN_SUBMITTED", { email });
      await loginWithPassword({ email, password });
      // Redirect to login/callback
    } catch (err: any) {
      const authError = processAuthError(err);
      console.log(authError);
      setEmailError(authError.email || "");
      setPasswordError(authError.password || "");
      setErrorMessage(authError.general || "");
      analytics?.track("TP_LOGIN_FAILED", { email });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DoorsteadProvider>
      <DoorsteadLoginScreen
        title="Doorstead for Tenants"
        subTitle="Property management reimagined"
        titleColor="white"
        subTitleColor="white"
        backgroundImageUrl={`${S3_BUCKET}/tenant_portal_login.png`}
        isBackgroundGradient={false}
        hasGoogleLogin={false}
        hasFacebookLogin={false}
        value={{ email, password }}
        onChange={({ email, password }) => {
          setEmail(email);
          setPassword(password);
        }}
        onLogin={passwordLogin}
        forgetPasswordLink={`/forgot-password?email=${encodeURIComponent(email)}`}
        logoLink="/"
        isSubmitting={loading}
        emailErrorMessage={emailError}
        passwordErrorMessage={passwordError}
        emailDisabled={false}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </DoorsteadProvider>
  );
}

export default withoutAuth(LoginPage);
