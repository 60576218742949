import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { GrayText } from "@doorstead/components";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useGoogleMapKey } from "src/hooks/useGoogleMapKey";
import { useSegmentedAddress } from "src/hooks/useSegmentedAddress";
import { useAddress } from "../../../../../hooks/useAddress";
import { MoneyRentIconBox } from "../../MoneyRentIconBox";
import { PaymentDueDateIconBox } from "../../PaymentDueDateIconBox";
import { Title } from "../../Title";
import { OwnerBox } from "./OwnerBox";

export function Desktop() {
  const address = useAddress();
  const googleMapKey = useGoogleMapKey();
  const segmentedAddress = useSegmentedAddress();

  return (
    <HStack gap={7} w="100%" bgColor="white" borderRadius="20px" padding={5} h="fit-content">
      <Flex w="240px" h="234px" justifyContent="center" alignItems="center" borderRadius="6px">
        <GoogleMapImage borderRadius={6} width={322} height={220} address={address} googleKey={googleMapKey} />
      </Flex>
      <VStack
        flexGrow={1}
        spacing={3}
        paddingY={5}
        alignItems="flex-start"
        justifyContent="space-between"
        flex={1}
        minH="234px"
      >
        <Box>
          <GrayText>Lease address</GrayText>
          <Box paddingTop={3}>
            <Title
              fontSize="3xl"
              text={
                <>
                  <Box>{segmentedAddress[0]}</Box>
                  <Box>{segmentedAddress[1]}</Box>
                </>
              }
            />
          </Box>
        </Box>
        <Flex w="100%">
          <MoneyRentIconBox flex={1} />
          <PaymentDueDateIconBox flex={1} />
        </Flex>
        <OwnerBox />
      </VStack>
    </HStack>
  );
}
