export enum ApplicantFileNodeType {
  ID_CARD = "ID_CARD",
  PREV_TWO_PAY_STUBS = "PREV_TWO_PAY_STUBS",
  PREV_THREE_MONTHS_BANK_STATEMENT = "PREV_THREE_MONTHS_BANK_STATEMENT",
  TEN_NINE_NINE_TAX_DOCUMENT = "TEN_NINE_NINE_TAX_DOCUMENT",
  PREV_X_MONTHS_BANK_STATEMENT = "PREV_X_MONTHS_BANK_STATEMENT",
}

export type ApplicantFileNode = {
  applicantFileId: string;
  type: ApplicantFileNodeType;
  bucket: string;
  key: string;
  fileName: string;
  objectSizeInBytes: number;
  lastModifiedTimestamp: string;
  contentType: string;
};
