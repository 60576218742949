import auth0 from "auth0-js";
import { appServerUrl, auth0Config } from "../../config";

export function useApplicantWebAuth(applicantId: string) {
  const { domain, clientID, audience } = auth0Config();

  return new auth0.WebAuth({
    domain,
    clientID,
    audience,
    responseType: "token",
    redirectUri: `${appServerUrl()}/application/login/callback?applicantId=${applicantId}`,
    scope: "openid profile email",
  });
}
