import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useDIYRepairs } from "../../../hooks/rr-project/useDIYRepairs";
import { DesktopView } from "../DesktopView";
import { MobileView } from "../MobileView";
import { DIYGuidelines } from "./DIYGuidelines";
import { DIYRepairCard } from "./DIYRepairCard";
import { DIYTableRow } from "./DIYTableRow";

export const templateColumns = "1fr 200px";
const title = "Owner managed(DIY)";

export function DIYRepairs() {
  const repairs = useDIYRepairs();
  const isMobile = useIsMobile();

  if (repairs.length === 0) return null;

  return isMobile ? (
    <MobileView repairs={repairs} isRequired={false} title={title} RepairCard={DIYRepairCard} tip={<DIYGuidelines />} />
  ) : (
    <DesktopView
      isActionNeeded={false}
      tip={<DIYGuidelines />}
      repairs={repairs}
      title={title}
      columns={["Item / Location", "Photos"]}
      templateColumns={templateColumns}
      TableRow={DIYTableRow}
    />
  );
}
