import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

export type ArgsItem = {
  id: string;
  response: string;
  notes: string;
  photoUrlsJson: string;
};

export type Args = {
  items: (Partial<ArgsItem> & {
    id: string;
  })[];
};

type Result = {
  data: {
    updateFlexWalkthroughItemsData: {
      count: number;
    };
  };
};

export function useUpdatePartialWalkthroughItemToServer() {
  const { gqlClient } = useAuth();

  return async ({ items }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation ($items: [UpdateFlexWalkthroughItemsDataInput]) {
          updateFlexWalkthroughItemsData(items: $items) {
            count
          }
        }
      `,
      variables: {
        items,
      },
    })) as Result;
  };
}
