import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { GrayText, LeftRightBox, PrimaryButton, moneyString } from "@doorstead/components";
import { useNavigate } from "react-router-dom";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useIsRentPaid } from "src/hooks/useIsRentPaid";
import { useRentDue } from "src/hooks/useRentDue";
import { useThisHumanMonthEnglish } from "src/hooks/useThisHumanMonthEnglish";
import { Card } from "../../Card";
import { Title } from "../../Title";

export function MonthlySection() {
  const navigate = useNavigate();
  const rentDue = useRentDue();
  const isRentPaid = useIsRentPaid();
  const thisHumanMonthEnglish = useThisHumanMonthEnglish();
  const analytics = useGroupAnalytics();

  return (
    <>
      <Card h="var(--section-height)" pos="relative">
        <Flex direction="column" h="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Title text={thisHumanMonthEnglish} fontSize="xl" />
            <Box>
              <PrimaryButton
                isFullWidth
                disabled={isRentPaid}
                size="lg"
                onClick={async () => {
                  analytics?.track("TP_HP_MANUAL_PAY_CLICKED");
                  navigate("/payment");
                }}
              >
                Pay now
              </PrimaryButton>
            </Box>
          </Flex>
          <Divider mt={3} />
          <Flex h="100%" alignItems="center">
            <VStack spacing={3} w="100%">
              <LeftRightBox
                left={<GrayText>Amount due</GrayText>}
                right={
                  <Box color="gray.700" fontWeight="bold">
                    {moneyString(rentDue)}
                  </Box>
                }
              />
              <LeftRightBox
                left={<GrayText>Rent due</GrayText>}
                right={
                  <Box color="gray.700" fontWeight="bold">
                    {moneyString(rentDue)}
                  </Box>
                }
              />
            </VStack>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
