import { Area } from "../../@types/RentReady/Area";
import { isAreaPainting } from "./isAreaPainting";
import { isAreaShowingHeatSource } from "./isAreaShowingHeatSource";
import { isAreaShowingSmokeDetector } from "./isAreaShowingSmokeDetector";
import { isHeatSourceComplete } from "./isHeatSourceComplete";
import { isSmokeDetectorComplete } from "./isSmokeDetectorComplete";
import { isThroughoutPropertyItemComplete } from "./isThroughoutPropertyItemComplete";
import { isV2ItemComplete } from "./isV2ItemComplete";

function isAreaSmokeDetectorComplete(area: Area): boolean {
  if (!isAreaShowingSmokeDetector(area)) return true;

  return isSmokeDetectorComplete(area);
}

function isAreaHeatSourceComplete(area: Area): boolean {
  if (!isAreaShowingHeatSource(area)) return true;

  return isHeatSourceComplete(area);
}

export function isAreaComplete(area: Area, isV2: boolean, shouldUseSimplifiedTemplate: boolean): boolean {
  const isAreaPhotos = area.photos.length >= 2;

  const isAreaFullPaint = isAreaPainting(area);
  if (area.isThroughoutProperty) {
    return area.items.every((item) => isThroughoutPropertyItemComplete(item, shouldUseSimplifiedTemplate));
  } else if (isV2) {
    if (shouldUseSimplifiedTemplate) {
      const isAreaAndItemsComplete = area.items.every((item) =>
        isV2ItemComplete({
          item,
          isAreaFullPaint,
          shouldUseSimplifiedTemplate,
        })
      );

      return isAreaAndItemsComplete && isAreaSmokeDetectorComplete(area) && isAreaHeatSourceComplete(area);
    }

    const isAreaAndItemsComplete =
      isAreaPhotos &&
      area.items.every((item) =>
        isV2ItemComplete({
          item,
          isAreaFullPaint,
          shouldUseSimplifiedTemplate,
        })
      );

    return isAreaAndItemsComplete && isAreaSmokeDetectorComplete(area) && isAreaHeatSourceComplete(area);
  } else {
    return isAreaPhotos;
  }
}
