import { OutlineButton } from "@doorstead/components/buttons";
import { useRef } from "react";
import { useOnSelectFiles } from "../../../../../../hooks/rentReady/useOnSelectFiles";
import { getFilesFromChangeEvent } from "../../../../../../utils/getFilesFromChangeEvent";
import { useInternalState } from "../../useInternalState";

const id = "UploadNewButton";

export function UploadNewButton() {
  const onSelectFiles = useOnSelectFiles();
  const { setPhotos } = useInternalState();
  const ref = useRef<HTMLInputElement>(null);
  if (!setPhotos) return null;

  return (
    <>
      <input
        id={id}
        ref={ref}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        multiple
        onChange={async (e) => {
          const files = getFilesFromChangeEvent(e);
          await onSelectFiles({
            files,
            setValues: setPhotos,
          });
        }}
      />
      <OutlineButton
        size="sm"
        onClick={() => {
          if (ref.current) {
            ref.current.click();
          }
        }}
      >
        Upload new
      </OutlineButton>
    </>
  );
}
