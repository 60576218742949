import { Box, Flex } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { View, useView } from "../../../hooks/rr-project/useView";
import { NoticeCard } from "../NoticeCard";
import { GuidelineView } from "../views/GuidelineView";
import { HowItWorksView } from "../views/HowItWorksView";
import { RepairListView } from "../views/RepairListView";
import { StartView } from "../views/StartView";

export function Main() {
  const { view } = useView();
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="flex-start" w="100%" mx={2}>
      {view === View.StartView && <StartView />}
      {view === View.GuidelineView && <GuidelineView />}
      {(view === View.RepairListView || view === View.HowItWorksView) && (
        <>
          <Box as="header" fontWeight="bold" fontSize={isMobile ? "28px" : "4xl"} color="gray.800">
            Your rent ready plan
          </Box>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <NoticeCard my={5} />
            {view === View.RepairListView && <RepairListView />}
            {view === View.HowItWorksView && <HowItWorksView />}
          </Flex>
        </>
      )}
    </Flex>
  );
}
