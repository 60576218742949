import { useIsPropertyWA } from "../../hooks/useIsPropertyWA";
import { useIsSkipWAHoldingDeposit } from "../../hooks/useIsSkipWAHoldingDeposit";
import { caSteps } from "./caSteps";
import { Step } from "./typings";
import { waSkippingHoldingDepositSteps } from "./waSkippingHoldingDepositSteps";
import { waSteps } from "./waSteps";

export function useSteps(): Step[] {
  const isPropertyWA = useIsPropertyWA();
  const skipWAHoldingDeposit = useIsSkipWAHoldingDeposit();
  if (isPropertyWA) {
    return skipWAHoldingDeposit ? waSkippingHoldingDepositSteps : waSteps;
  } else {
    return caSteps;
  }
}
