import { Divider, VStack } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { HoldingDepositHintBox } from "src/components/HoldingDepositHintBox";
import { useIsLeaseStartDateFirstDayOfMonth } from "src/hooks/useIsLeaseStartDateFirstDayOfMonth";
import { usePageView } from "src/hooks/usePageView";
import { useNextStep } from "../../../../contexts/StepContext/useNextStep";
import { useAddress } from "../../../../hooks/useAddress";
import { useEndDate } from "../../../../hooks/useEndDate";
import { useIsProratedRentPaid } from "../../../../hooks/useIsProratedRentPaid";
import { useMonthlyRent } from "../../../../hooks/useMonthlyRent";
import { useMonthlyRentDueDate } from "../../../../hooks/useMonthlyRentDueDate";
import { useProratedRentDue } from "../../../../hooks/useProratedRentDue";
import { useProratedRentDueDate } from "../../../../hooks/useProratedRentDueDate";
import { useSecurityAmountDue } from "../../../../hooks/useSecurityAmountDue";
import { useSecurityDepositDueDate } from "../../../../hooks/useSecurityDepositDueDate";
import { useStartDate } from "../../../../hooks/useStartDate";
import { useTenants } from "../../../../hooks/useTenants";
import { AddressAndTenantsBox } from "./AddressAndTenantsBox";
import { MoneyBox } from "./MoneyBox";
import { NoticeBox } from "./NoticeBox";
import { useIsShowSecurityDeposit } from "./useIsShowSecurityDeposit";
import { useTitle } from "./useTitle";

export function WelcomeStep() {
  const address = useAddress();
  const startDate = useStartDate();
  const endDate = useEndDate();
  const tenants = useTenants();
  const { nextStep, isLoading } = useNextStep();
  const isMobile = useIsMobile();
  const securityAmountDue = useSecurityAmountDue();
  const securityDepositDueDate = useSecurityDepositDueDate();
  const monthlyRent = useMonthlyRent();
  const monthlyRentDueDate = useMonthlyRentDueDate();
  const proratedRentDue = useProratedRentDue();
  const isProratedRentPaid = useIsProratedRentPaid();
  const proratedRentDueDate = useProratedRentDueDate();
  const analytics = usePageView("TP_OB_WELCOME_VIEWED");
  const isLeaseStartDateFirstDayOfMonth = useIsLeaseStartDateFirstDayOfMonth();
  const isShowSecurityDeposit = useIsShowSecurityDeposit();
  const title = useTitle();

  return (
    <VStack
      maxWidth="796px"
      spacing={5}
      bgColor="white"
      borderRadius={20}
      marginX="10px"
      marginY="30px"
      padding={isMobile ? "20px" : "40px"}
      display="flex"
      alignItems="start"
    >
      <NoticeBox
        title={title}
        subTitle="In just a few steps, we’ll help you securely add a payment method and make your first payment."
      />
      <AddressAndTenantsBox address={address} fromDate={startDate} toDate={endDate} tenants={tenants} />
      <VStack w="100%" spacing={5} display="flex" alignItems="flex-start">
        {isShowSecurityDeposit && (
          <>
            <Divider />
            <MoneyBox title="Security deposit" money={securityAmountDue} dueDate={securityDepositDueDate} />
          </>
        )}
        {!isProratedRentPaid && (
          <>
            <Divider />
            <MoneyBox
              title={isLeaseStartDateFirstDayOfMonth ? "First Month's Rent" : "Prorated Rent"}
              money={proratedRentDue}
              dueDate={proratedRentDueDate}
            />
          </>
        )}
        <Divider />
        <MoneyBox title="Monthly Rent" money={monthlyRent} dueDate={`on the ${monthlyRentDueDate}`} />
        <HoldingDepositHintBox />
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading}
          isFullWidth={isMobile}
          onClick={async () => {
            analytics?.track("TP_OB_WELCOME_CONTINUED");
            await nextStep();
          }}
        >
          Get Started
        </PrimaryButton>
      </VStack>
    </VStack>
  );
}
