import { Box, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Item } from "../../../../@types/RentReady/Item";
import { writeRRItemOpen } from "../../../../databases/RentReadyDatabase";
import { useIsAreaPainting } from "../../../../hooks/rentReady/useIsAreaPainting";
import { SubTitleRow } from "../../SubTitleRow";
import { ComponentRows } from "./ComponentRows";
import { NonInlineItemRow } from "./ItemBar";
import { hasItemGroupData } from "./hasItemGroupData";

type Props = {
  item: Item;
  defaultOpen: boolean;
};

function getTitle({ isPresent, uiName }: Item): string {
  if (isPresent === false) {
    return `${uiName} - missing`;
  }
  return uiName;
}

export function ItemRow({ item, defaultOpen }: Props) {
  const { id, components } = item;
  const isAreaFullPaint = useIsAreaPainting();
  const [isOpen, setOpen] = useState(defaultOpen);
  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <Box w="100%" id={id}>
      <SubTitleRow
        isOpen={isOpen}
        hasData={hasItemGroupData(item, isAreaFullPaint)}
        text={getTitle(item)}
        onClick={async () => {
          setOpen((isOpen) => {
            const toIsOpen = !isOpen;
            writeRRItemOpen(item.id, toIsOpen);
            return toIsOpen;
          });
        }}
        isExpandable={!!item.isPresent}
      />
      {isOpen && (
        <>
          <NonInlineItemRow {...item} />
          <Divider />
          <ComponentRows item={item} components={components} />
        </>
      )}
    </Box>
  );
}
