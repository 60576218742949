import { Box, Flex, Link } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { Header, Props as MainLayoutProps } from "@doorstead/components/layouts/MainLayout";

export type Props = Omit<MainLayoutProps, "background">;
export const linkUrl = "https://www.doorstead.com/statement-of-rental-policy";
export const linkText = "Doorstead Statement of Rental Policy";

export function MainLayout({ header, children }: Props) {
  const isMobile = useIsMobile();
  return (
    <>
      <Header {...header} px={5} />
      <Flex
        alignItems="start"
        justifyContent="center"
        w="100%"
        minH="inherit"
        background="linear-gradient(360deg, #F9F8FF 96.87%, #FFFFFF 100.47%)"
        pb={5}
        px={4}
      >
        <Box
          as="main"
          minH="calc(100vh - 118px - 100px)"
          w={{
            sm: "100%",
            md: "800px",
            "2xl": "1050px",
          }}
        >
          {children}
        </Box>
      </Flex>
      <Flex
        as="footer"
        backgroundColor="white"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "center" : "space-between"}
        alignItems={isMobile ? "flex-start" : "center"}
        padding="20px 60px"
      >
        <Box fontFamily="inter" fontWeight="600" fontSize="13px" mb={isMobile ? "10px" : "0"}>
          <Box lineHeight="8">© Doorstead Inc. All rights reserved.</Box>
          <Link
            href="https://www.doorstead.com/privacy-policy"
            target="_blank"
            color="gray.800"
            fontFamily="inter"
            fontWeight="600"
            fontSize="13px"
            lineHeight="8"
          >
            Doorstead Statement of Rental Policy
          </Link>
        </Box>
        <Box fontFamily="inter" fontSize="13px">
          <Box lineHeight={isMobile ? "8" : "6"}>CA: DRE# 02089344</Box>
          <Box lineHeight={isMobile ? "8" : "6"}>WA: License# 21034868</Box>
          <Box lineHeight={isMobile ? "8" : "6"}>FL: License# CQ1070582</Box>
        </Box>
      </Flex>
    </>
  );
}
