import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { useThroughoutPropertyCleaningCache } from "./useThroughoutPropertyCleaningCache";

export function isComplete(value: ThroughoutPropertyCleaning): boolean {
  if (value.cleanIsNeeded === null) {
    return false;
  }
  return !(value.cleanIsNeeded && value.cleanSelectedOptions.length === 0);
}

export function useIsThroughoutPropertyCleaningComplete(): boolean {
  const value = useThroughoutPropertyCleaningCache();
  if (value === null) return false;

  return isComplete(value);
}
