import { Area } from "../../@types/RentReady/Area";
import { attachCounting } from "./attachCounting";
import { moveBuildingAndYardToTheFirst } from "./moveBuildingAndYardToTheFirst";
import { sortByCreateDate } from "./sortByCreateDate";
import { sortByField } from "./sortByField";

export function sortAreas(areas: Area[]): Area[] {
  areas = areas.sort(sortByCreateDate);
  areas = attachCounting(areas, "name", "uiName");
  areas = sortByField(areas, "uiName");
  return moveBuildingAndYardToTheFirst<Area>(areas);
}
