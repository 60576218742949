import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { mapInspectionReportNodeToThroughoutPropertyCleaning } from "../../utils/rentReady/mapInspectionReportNodeToThroughoutPropertyCleaning";
import { useInspectionReportCache } from "./useInspectionReportCache";

export function useThroughoutPropertyCleaningCache(): ThroughoutPropertyCleaning | null {
  const inspectionReportCache = useInspectionReportCache();

  if (inspectionReportCache === null) return null;

  return mapInspectionReportNodeToThroughoutPropertyCleaning(inspectionReportCache);
}
