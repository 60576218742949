import { RRGraphQLResultCache, writeRRGraphQLResult } from "../../../databases/RentReadyDatabase";
import { useInspectionReportId } from "../useInspectionReportId";
import { mergeAreasCache } from "./mergeAreasCache";
import { mergeInspectionReportCache } from "./mergeInspectionReportCache";
import { mergeKeyDetailsCache } from "./mergeKeyDetailsCache";
import { mergeKeyStorageCache } from "./mergeKeyStorageCache";
import { mergeThroughPropertyCleaningCache } from "./mergeThroughPropertyCleaningCache";
import { mergeThroughPropertyDetectorCache } from "./mergeThroughPropertyDetectorCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function useMergeCacheWithServer() {
  const inspectionReportId = useInspectionReportId();

  return async (rrGraphQLResultCache: RRGraphQLResultCache | null, rrGraphQLResultServer: RRGraphQLResult) => {
    if (rrGraphQLResultCache && rrGraphQLResultCache.inspectionReport) {
      let newRRGraphQLResultCache = mergeInspectionReportCache(rrGraphQLResultCache, rrGraphQLResultServer);
      newRRGraphQLResultCache = mergeThroughPropertyCleaningCache(newRRGraphQLResultCache, rrGraphQLResultServer);
      newRRGraphQLResultCache = mergeThroughPropertyDetectorCache(newRRGraphQLResultCache, rrGraphQLResultServer);
      newRRGraphQLResultCache = mergeKeyDetailsCache(newRRGraphQLResultCache, rrGraphQLResultServer);
      newRRGraphQLResultCache = mergeKeyStorageCache(newRRGraphQLResultCache, rrGraphQLResultServer);
      newRRGraphQLResultCache = mergeAreasCache(newRRGraphQLResultCache, rrGraphQLResultServer);
      await writeRRGraphQLResult(inspectionReportId, newRRGraphQLResultCache);
    }
  };
}
