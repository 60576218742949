import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import produce from "immer";
import ImageGallery from "react-image-gallery";
import { useIsEditable } from "../../../../../../hooks/rentReady/useIsEditable";
import { getPhotoSrc } from "../../../../../../utils/rentReady/getPhotoSrc";
import { BackIcon } from "../../BackIcon";
import { DeleteIcon } from "../../DeleteIcon";
import { Mode } from "../../Mode";
import { useGalleryIndex } from "../../useGalleryIndex";
import { useInternalState } from "../../useInternalState";
import { DeletePhotoDialog } from "../SelectingMode/DeletePhotoDialog";
import "./image-gallery.css";

const backMode = Mode.THUMBNAILS;

type Props = {
  hasBackButton?: boolean;
};

export function GalleryMode({ hasBackButton = true }: Props) {
  const isEditable = useIsEditable();
  const { photos, setMode, setPhotos } = useInternalState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { index, setIndex } = useGalleryIndex();

  return (
    <>
      {setPhotos && (
        <DeletePhotoDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => {
            //if index in the range of photos
            if (photos.length > index) {
              const newPhotos = produce(photos, (draft) => {
                draft.splice(index, 1);
              });
              setPhotos(newPhotos);

              //move to the previous index
              setIndex(Math.min(index, newPhotos.length - 1));
              if (newPhotos.length === 0) {
                setMode(backMode);
              }
              onClose();
            }
          }}
        />
      )}
      <Flex flexDirection="column" p={5}>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          {hasBackButton && <BackIcon onClick={() => setMode(backMode)} />}
          {isEditable && <Box>{setPhotos && <DeleteIcon onClick={onOpen} />}</Box>}
        </Flex>
        <Box p={5}>
          <ImageGallery
            startIndex={index}
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen={false}
            items={photos.map((photo) => ({
              original: getPhotoSrc(photo, "src"),
              thumbnail: getPhotoSrc(photo, "thumbnail"),
            }))}
            onSlide={setIndex}
          />
        </Box>
      </Flex>
    </>
  );
}
