import { EmptyLayout } from "../components/EmptyLayout/EmptyLayout";
import { Error } from "../components/Error";
import { noPage } from "../components/Error/ErrorCode";

function NotFoundPage() {
  return (
    <EmptyLayout logoLink="/">
      <Error {...noPage} />
    </EmptyLayout>
  );
}

export default NotFoundPage;
