import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useAddAnimal() {
  const applicantId = useApplicantId();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async () => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantAnimal: ApplicantAnimalCreateData) {
            createApplicantAnimal(applicantAnimal: $applicantAnimal) {
              applicantAnimalId
            }
          }
        `,
        variables: {
          applicantAnimal: {
            name: "",
            type: "",
            breed: "",
            color: "",
            weight: "",
            isServiceAnimal: false,
            hasVerification: false,
            applicantId,
          },
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
