import { Box, Flex, Img, VStack } from "@chakra-ui/react";

export function HowItWorksView() {
  return (
    <VStack spacing={5} w="100%" p={5} bgColor="white" borderRadius={20} mt={5}>
      <Flex w="100%" justifyContent="space-between" px={5}>
        <Box fontSize="4xl" fontWeight="bold">
          Why repairs needed?
        </Box>
        <Box>
          <Img src="/resources/rr-project/tools.png" />
        </Box>
      </Flex>
      <VStack alignItems="flex-start" w="100%" p={5} spacing={5}>
        <Box color="gray.500">
          As a property owner, you know that one of the keys to success is having high-quality tenants who respect your
          property and pay on time.
        </Box>
        <Box color="gray.500">
          However, attracting those tenants can be a challenge. That&apos;s why we recommend that you take advantage of
          our required and recommended approval process. This process ensures that your property is in top shape and
          ready to attract the best tenants possible.
        </Box>
        <Box fontWeight="bold">Here are just a few reasons why you should consider approving our recommendations:</Box>
        <Box color="gray.500">
          To take advantage of our recommended approvals, simply click the &quot;Go to repair list&quot; button below.
          We&apos;ll provide you with a detailed list of recommended repairs and improvements, along with an estimate of
          the cost and timeline. Thank you for choosing Doorstead. We look forward to helping you maximize your rental
          income and minimize your headaches.
        </Box>
      </VStack>
    </VStack>
  );
}
