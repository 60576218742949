import { Box } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import React from "react";
import { CardText } from "../../CardText";

export type Props = {
  title: string;
  children: React.ReactNode;
};

export function StepCard({ title, children }: Props) {
  const isMobile = useIsMobile();

  return (
    <Box w="100%" bgColor="white" p={isMobile ? 4 : 8} borderRadius="20px">
      <CardText>{title}</CardText>
      {children}
    </Box>
  );
}
