import { Item } from "../../../../@types/RentReady/Item";
import { Photo } from "../../../../@types/RentReady/Photo";
import { ItemNode } from "../../../../hooks/rentReady/useFetchInspectionReport/ItemNode";
import { useSyncItemPhotosToCacheAndServer } from "../../../../hooks/rentReady/useSyncItemPhotosToCacheAndServer";
import { useUpdatePartialItemToCache } from "../../../../hooks/rentReady/useUpdatePartialItemToCache";
import { ArgsItem } from "../../../../hooks/rentReady/useUpdatePartialItemToServer";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { mapPhotosToPhotoUrlsJson } from "../../../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { PhotosField } from "../../fields/PhotosField";

type Props = {
  item: Item;
  isValid?: boolean;
  prefix: Prefix;
  values: Photo[];
  cacheField: keyof ItemNode;
  serverField: keyof ArgsItem;
};
export function ItemAutoSyncPhotosField({ item, isValid = true, prefix, cacheField, serverField, values }: Props) {
  const isOnline = useIsOnline();
  const syncPhotosToCacheAndServer = useSyncItemPhotosToCacheAndServer();
  const updatePartialItemToCache = useUpdatePartialItemToCache();

  return (
    <PhotosField
      label=""
      prefix={prefix}
      values={values}
      isValid={isValid}
      onChange={async (values) => {
        if (isOnline) {
          await syncPhotosToCacheAndServer(item, cacheField, serverField, prefix, values);
        } else {
          await updatePartialItemToCache({
            id: item.id,
            field: cacheField,
            value: mapPhotosToPhotoUrlsJson(values),
          });
        }
      }}
    />
  );
}
