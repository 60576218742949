import { Divider, Flex, VStack } from "@chakra-ui/react";
import { LeftRightBox, OutlineButton, PrimaryButton, moneyString, useIsMobile } from "@doorstead/components";
import { useState } from "react";
import { PaymentMethodField } from "src/components/PaymentMethodField";
import { SubtotalSection } from "src/components/SubtotalSection";
import { TotalChargeSection } from "src/components/TotalChargeSection";
import { useNextStep } from "src/contexts/StepContext/useNextStep";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { useIsLeaseStartDateFirstDayOfMonth } from "src/hooks/useIsLeaseStartDateFirstDayOfMonth";
import { usePageView } from "src/hooks/usePageView";
import { useProratedRentDue } from "src/hooks/useProratedRentDue";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { useCanSkipProratedRentStep } from "../../../../../hooks/useCanSkipProratedRentStep";
import { useDefaultPaymentMethodId } from "../../../../../hooks/useDefaultPaymentMethodId";
import { useFetchMe } from "../../../../../hooks/useFetchMe";
import { useOpenPaymentFailureModal } from "../../../../../hooks/useOpenPaymentFailureModal";
import { useOpenPaymentSuccessModal } from "../../../../../hooks/useOpenPaymentSuccessModal";
import { usePayRent } from "../../../../../hooks/usePayRent";
import { useProratedRentDueDate } from "../../../../../hooks/useProratedRentDueDate";
import { getCents } from "../../../../../utils/getCents";
import { MoneyText } from "../../../../MoneyText";
import { PayNowDisclaimerText } from "../../../../PayNowDisclaimerText";
import { PaymentAmountField } from "../../../../PaymentAmountField";
import { BaseCard } from "../BaseCard";
import { DueDateBox } from "../DueDateBox";

export function PayProratedRentCard() {
  const { refetch, isRefetching } = useFetchMe();
  const { loading: isPayingRent, payRent } = usePayRent();
  const isMobile = useIsMobile();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(defaultPaymentMethodId);
  const selectedPaymentMethod = useSelectedPaymentMethod(paymentMethodId);
  const proratedRentDue = useProratedRentDue();
  const proratedRentDueDate = useProratedRentDueDate();
  const openPaymentSuccessModal = useOpenPaymentSuccessModal();
  const openPaymentFailureModal = useOpenPaymentFailureModal();
  const isLeaseStartDateFirstDayOfMonth = useIsLeaseStartDateFirstDayOfMonth();
  const analytics = usePageView("TP_OB_PAY_RENT_VIEWED");
  const { chargeAmount, cardTransactionFee } = useChargeBreakdown(proratedRentDue, selectedPaymentMethod?.type_);
  const { nextStep, isLoading: isStepLoading } = useNextStep();
  const canSkipProratedRentStep = useCanSkipProratedRentStep();

  return (
    <BaseCard title={isLeaseStartDateFirstDayOfMonth ? "Pay First Month's Rent" : "Pay Prorated Rent"}>
      <LeftRightBox left="Prorated rent" right={<MoneyText>{moneyString(proratedRentDue)}</MoneyText>} />
      <DueDateBox>{proratedRentDueDate}</DueDateBox>
      <Divider />
      <SubtotalSection subTotalDue={proratedRentDue} paid={0} />
      <VStack w="100%" spacing={5} alignItems="flex-start">
        <PaymentMethodField
          id="payment_method"
          label="Payment method"
          value={paymentMethodId || ""}
          onChange={setPaymentMethodId}
        />
        <PaymentAmountField disabled value={proratedRentDue} />
      </VStack>
      <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} />
      <Flex w="100%" justifyContent="space-between" gap={3} flexWrap="wrap">
        <PrimaryButton
          isFullWidth={isMobile}
          disabled={isPayingRent || !paymentMethodId}
          isLoading={isRefetching || isPayingRent}
          onClick={() => {
            if (paymentMethodId !== null) {
              analytics?.track("TP_OB_PAY_RENT_CLICKED", {
                paymentMethodId,
              });
              payRent(
                {
                  amountInCents: getCents(chargeAmount),
                  paymentMethodId,
                },
                {
                  async onSuccess() {
                    analytics?.track("TP_OB_RENT_PAID", {
                      paymentMethodId,
                    });
                    await refetch();
                    openPaymentSuccessModal({
                      amount: chargeAmount,
                      paymentMethod: selectedPaymentMethod,
                      doneText: "Continue",
                      suggestAutoPay: false,
                    });
                  },
                  onError(e: any) {
                    console.error(e);
                    analytics?.track("TP_OB_PAY_RENT_FAILED", {
                      paymentMethodId,
                    });
                    const errorMessage =
                      e.graphQLErrors && e.graphQLErrors.length !== 0 ? e.graphQLErrors[0].message : null;
                    openPaymentFailureModal({
                      amount: chargeAmount,
                      paymentMethod: selectedPaymentMethod,
                      errorMessage,
                    });
                  },
                }
              );
            }
          }}
        >
          Pay now
        </PrimaryButton>
        {canSkipProratedRentStep && (
          <OutlineButton
            disabled={isStepLoading}
            isLoading={isStepLoading}
            isFullWidth={isMobile}
            onClick={() => {
              analytics?.track("TP_OB_PAY_RENT_SKIPPED");
              nextStep();
            }}
          >
            Skip for now
          </OutlineButton>
        )}
      </Flex>
      <Flex>
        <PayNowDisclaimerText />
      </Flex>
    </BaseCard>
  );
}
