import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useFetchInspectionReport } from "./useFetchInspectionReport";

type Args = {
  componentId: string;
};

export function useClearComponent() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchInspectionReport();

  return useMutation(async ({ componentId }: Args) => {
    await gqlClient.mutate({
      mutation: gql`
        mutation ($components: [UpdateInspectionReportComponentsOption]) {
          clearInspectionReportComponents(components: $components) {
            count
            updatedAtList
          }
        }
      `,
      variables: {
        components: [
          {
            componentDataId: componentId,
          },
        ],
      },
    });
    return await refetch();
  });
}
