import { ApplicantNodeStatus } from "./useFetchApplicationMe/Applicant";
import { useFetchedApplicantStatus } from "./useFetchedApplicantStatus";

export function useIsApplicantEditable() {
  const status = useFetchedApplicantStatus();
  return (
    !status ||
    ![
      ApplicantNodeStatus.SUBMITTED,
      ApplicantNodeStatus.LEASE_SIGNATURE_PENDING,
      ApplicantNodeStatus.LEASE_SIGNED,
    ].includes(status)
  );
}
