import produce from "immer";
import { Area } from "../../@types/RentReady/Area";
import { arrayToRecord } from "../../utils/arrayToRecord";
import { isOffline } from "../../utils/isOffline";
import { isAreaDiff } from "../../utils/rentReady/isAreaDiff";
import { mapAreaCacheToServerArgs } from "./useMapAreaStateFromRRGraphQLResultCache";
import { useUpdateAreaToServer } from "./useUpdateAreaToServer";

export function useUpdateAreasToServer() {
  const updateAreaToServer = useUpdateAreaToServer();

  return async (areasCache: Area[], areasServer: Area[]): Promise<Area[]> => {
    if (isOffline() || areasCache.length === 0) return areasCache;
    else {
      areasCache = produce(areasCache, (draft) => {
        return draft;
      });
      //make map[id: Area]
      const areasServerMap = arrayToRecord(areasServer, "id");

      for (const areaCache of areasCache) {
        //1. compare with server, update to server if updated
        if (isAreaDiff(areaCache, areasServerMap[areaCache.id])) {
          //update area
          await updateAreaToServer(mapAreaCacheToServerArgs(areaCache));
        }
      }

      return areasCache;
    }
  };
}
