import { SelectField } from "@doorstead/components";
import { AutoPayDate } from "../../@types/AutoPay/typings";

type Props = {
  id: string;
  label: string;
  value: AutoPayDate | "";
  onChange: (value: AutoPayDate | "") => void;
};

export function PayOnField({ id, label, value, onChange }: Props) {
  return (
    <SelectField
      id={id}
      label={label}
      value={value}
      options={[
        { name: "28th", value: AutoPayDate.TWENTY_EIGHTH },
        { name: "Month-end", value: AutoPayDate.LAST },
        { name: "1st", value: AutoPayDate.FIRST },
        { name: "2nd", value: AutoPayDate.SECOND },
        { name: "3rd", value: AutoPayDate.THIRD },
      ]}
      onChange={(e) => onChange(e.target.value as AutoPayDate)}
    />
  );
}
