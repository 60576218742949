import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { PaymentMethodType } from "./useFetchMe";

const MUTATION = gql`
  mutation (
    $name: String!
    $email: String!
    $address: String!
    $comments: String
    $amountInCents: Int!
    $paymentMethodType: PaymentMethodType!
    $accountNumber: String
    $routingNumber: String
    $stripePaymentMethodId: String
  ) {
    payOneTime(
      name: $name
      email: $email
      address: $address
      comments: $comments
      amountInCents: $amountInCents
      paymentMethodType: $paymentMethodType
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      oneTimePaymentId
    }
  }
`;

export function usePayOneTime() {
  const { gqlClient } = useAuth();
  const mutation = useMutation(
    ({
      name,
      email,
      address,
      comments,
      amountInCents,
      paymentMethodType,
      accountNumber,
      routingNumber,
      stripePaymentMethodId,
    }: {
      name: string;
      email: string;
      address: string;
      comments: string;
      amountInCents: number;
      paymentMethodType: PaymentMethodType;
      accountNumber: string | null;
      routingNumber: string | null;
      stripePaymentMethodId: string | null;
    }) =>
      gqlClient.mutate({
        mutation: MUTATION,
        variables: {
          name,
          email,
          address,
          comments,
          amountInCents,
          paymentMethodType: paymentMethodType.toUpperCase(),
          accountNumber,
          routingNumber,
          stripePaymentMethodId,
        },
      })
  );

  return {
    payOneTime: mutation.mutate,
    loading: mutation.isLoading,
  };
}
