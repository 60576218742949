import { Area } from "../../../@types/RentReady/Area";
import { sortAreas } from "../../../utils/rentReady/sortAreas";
import { useInspectionReportCache } from "../useInspectionReportCache";
import { mapAreaNodesToAreas } from "./mapAreaNodesToAreas";

export function useAreasCache(): Area[] {
  const inspectionReportCache = useInspectionReportCache();
  // mapping
  return sortAreas(mapAreaNodesToAreas(inspectionReportCache?.data || []));
}
