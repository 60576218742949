import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  isOpen: boolean;
  children: ReactNode;
};

export function DescriptionText({ isOpen, children }: Props) {
  let props: BoxProps = {};

  if (!isOpen) {
    props = {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    };
  }
  return <Box {...props}>{children}</Box>;
}
