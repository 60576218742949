import { PrimaryButton } from "@doorstead/components";
import { useConfirmSubmittedModal } from "src/hooks/flexWalkthrough/useConfirmSubmittedModal";
import { useUpdateFlexWalkthroughReportSubmittedTime } from "src/hooks/flexWalkthrough/useUpdateFlexWalkthroughReportSubmittedTime";
import { useIsOffline } from "src/hooks/useIsOffline";
import { useIsFlexWalkthroughSubmitDisabled } from "./useIsFlexWalkthroughSubmitDisabled";

export function FlexWalkthroughSubmitButton() {
  const disabled = useIsFlexWalkthroughSubmitDisabled();
  const isOffline = useIsOffline();
  const { isLoading } = useUpdateFlexWalkthroughReportSubmittedTime();

  const { onOpen } = useConfirmSubmittedModal();

  if (isOffline) return null;

  return (
    <>
      <PrimaryButton isFullWidth isLoading={isLoading} disabled={disabled} onClick={onOpen}>
        Submit
      </PrimaryButton>
    </>
  );
}
