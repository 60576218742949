import { Photo } from "../../@types/RentReady/Photo";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { useIsUploading } from "../rentReady/useIsUploading";
import { PhotoWithPrefix, Prefix, useUpload } from "../rentReady/useUpload";
import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { FlexWalkthroughItemNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useSyncSingleWalkthroughItemFieldToCacheAndServer } from "./useSyncSingleWalkthroughItemFieldToCacheAndServer";
import { ArgsItem } from "./useUpdatePartialWalkthroughItemToServer";

export function useSyncWalkthroughItemPhotosToCacheAndServer() {
  const upload = useUpload();
  const { setIsUploading } = useIsUploading();
  const syncSingleWalkthroughItemFieldToCacheAndServer = useSyncSingleWalkthroughItemFieldToCacheAndServer();
  const { refetch } = useFetchFlexWalkthroughReport();

  return async (
    item: FlexWalkthroughItemNode,
    cacheField: keyof FlexWalkthroughItemNode,
    serverField: keyof ArgsItem,
    prefix: Prefix,
    photos: Photo[]
  ) => {
    setIsUploading(true);
    const newPhotos = photos.map((photo) => ({ ...photo, prefix } as PhotoWithPrefix));
    await Promise.all(await upload(newPhotos));
    setIsUploading(false);

    const photosToPhotoUrlsJson = mapPhotosToPhotoUrlsJson(newPhotos);
    await syncSingleWalkthroughItemFieldToCacheAndServer(
      item,
      cacheField,
      serverField,
      photosToPhotoUrlsJson,
      photosToPhotoUrlsJson
    );
    await refetch();
  };
}
