import { NewApplicantWithIdV2 } from "./NewApplicantsCardV2/typings";

export function getDuplicatedEmails({
  coTenants,
  guarantors,
}: {
  coTenants: NewApplicantWithIdV2[];
  guarantors: NewApplicantWithIdV2[];
}): Set<string> {
  const map = new Map<string, number>();
  coTenants.forEach((e) => {
    map.set(e.email, (map.get(e.email) || 0) + 1);
  });

  guarantors.forEach((e) => {
    map.set(e.email, (map.get(e.email) || 0) + 1);
  });

  const duplicatedEmails = new Set<string>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  for (const [email, count] of map) {
    if (count > 1) {
      duplicatedEmails.add(email);
    }
  }

  return duplicatedEmails;
}
