import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { useState } from "react";
import { getPhotoSrc } from "../../../../../../utils/rentReady/getPhotoSrc";
import { PhotoThumbnail } from "../../../../PhotoThumbnail";
import { DeleteIcon } from "../../DeleteIcon";
import { Mode } from "../../Mode";
import { useInternalState } from "../../useInternalState";
import { DeletePhotoDialog } from "./DeletePhotoDialog";
import { TickBox } from "./TickBox";

export function SelectingMode() {
  const { photos, setPhotos, setMode } = useInternalState();
  const [ids, setIds] = useState<string[]>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      {setPhotos && (
        <DeletePhotoDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => {
            if (ids.length > 0) {
              //remove the photos
              setPhotos((photos) => photos.filter((photo) => !ids.includes(photo.id)));
              setIds([]);
              onClose();
              setMode(Mode.THUMBNAILS);
            }
          }}
        />
      )}

      <VStack p={5} alignItems="flex-start">
        <Flex w="100%" justifyContent="space-between">
          <Flex justifyContent="center" alignItems="center">
            <OutlineButton size="sm" onClick={() => setMode(Mode.THUMBNAILS)}>
              Cancel
            </OutlineButton>
          </Flex>
          <Flex flex={1} justifyContent="center" alignItems="center">
            {ids.length} Photo{ids.length >= 2 ? "s" : ""} Selected
          </Flex>
          <DeleteIcon isShow={setPhotos && ids.length > 0} onClick={onOpen} />
        </Flex>
        <Flex flex={1} flexWrap="wrap" gap={3}>
          {photos.map((photo) => (
            <Box key={photo.id} pos="relative">
              <PhotoThumbnail
                src={getPhotoSrc(photo, "thumbnail")}
                onClick={() => {
                  //toggle the id
                  if (ids.includes(photo.id)) {
                    setIds(ids.filter((id) => id !== photo.id));
                  } else {
                    setIds([...ids, photo.id]);
                  }
                }}
              />
              {ids.includes(photo.id) && <TickBox position="absolute" bottom={1} right={1} />}
            </Box>
          ))}
        </Flex>
      </VStack>
    </>
  );
}
