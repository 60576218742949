import produce from "immer";
import { Item } from "../../@types/RentReady/Item";
import { attachCounting } from "../../utils/rentReady/attachCounting";
import { sortByCreateDate } from "../../utils/rentReady/sortByCreateDate";
import { sortByField } from "../../utils/rentReady/sortByField";
import { useArea } from "./useArea";
import { attachIsCore } from "./useAreasCache/attachIsCore";

export function normalizeItems(items: Item[]): Item[] {
  let newItems = produce(items, (draft) => {
    return draft.sort(sortByCreateDate);
  });
  newItems = attachIsCore(newItems);
  newItems = attachCounting(newItems, "name", "uiName");
  newItems = sortByField(newItems, "uiName");
  newItems = newItems.map((e, index) => ({ ...e, index }));
  return newItems;
}

export function useItems(): Item[] {
  const area = useArea();
  if (area === null) {
    return [];
  }
  return normalizeItems(area.items);
}
