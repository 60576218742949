import { NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
  disabled?: boolean;
  value: number | null;
  onChange: (value: number | null) => void;
  onBlur: (value: number | null) => void;
  isInvalid: boolean;
};

function stringToNumber(value: string): number | null {
  const num = Number.parseInt(value);
  return Number.isNaN(num) ? null : num;
}

export function WholeNumberField({ disabled = false, value: initialValue, onChange, isInvalid, onBlur }: Props) {
  const format = (val: string) => {
    const num = Number.parseInt(val, 10);
    if (Number.isNaN(num)) return "";

    return num.toLocaleString();
  };
  const [value, setValue] = useState<string>((initialValue || "").toString());

  useEffect(() => {
    onChange(stringToNumber(value));
  }, [value]);

  return (
    <NumberInput
      precision={0}
      onChange={(valueString) => setValue(valueString)}
      value={format(value)}
      keepWithinRange={false}
      isInvalid={isInvalid}
      onBlur={() => onBlur(stringToNumber(value))}
    >
      <NumberInputField disabled={disabled} />
      <NumberInputStepper />
    </NumberInput>
  );
}
