import { Chip, Variant } from "@doorstead/components/Chip";
import { RepairNode } from "../../hooks/rr-project/useFetchRRProject";

type Props = {
  repairRequirement: RepairNode["repairRequirement"];
};

export function RepairChip({ repairRequirement }: Props) {
  switch (repairRequirement) {
    case "Owner-Recommended":
      return <Chip label="Recommended" size="sm" variant={Variant.Purple} />;
    case "Owner-Required":
      return <Chip label="Required" size="sm" variant={Variant.Red} />;
    case "Owner-Optional":
      return <Chip label="Optional" size="sm" variant={Variant.Gray} />;
    default:
      return null;
  }
}
