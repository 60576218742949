import { Box, Flex } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import { MainLayout as DoorsteadMainLayout, Props as MainLayoutProps } from "@doorstead/components/layouts/MainLayout";

export type Props = Omit<MainLayoutProps, "background">;

export function MainLayout({ header, footer, children, isFlex, isTPO }: Props) {
  const isMobile = useIsMobile();

  return (
    <DoorsteadMainLayout
      header={header}
      footer={footer}
      isFlex={isFlex}
      isTPO={isTPO}
      background="linear-gradient(0deg, rgb(249, 248, 255) 80%, rgb(255, 255, 255) 100%)"
    >
      <Flex alignItems="start" justifyContent="center" w="100%" minH="inherit" marginY={isMobile ? 0 : 12}>
        <Box
          w={{
            sm: "100%",
            md: "800px",
            "2xl": "1050px",
          }}
        >
          {children}
        </Box>
      </Flex>
    </DoorsteadMainLayout>
  );
}
