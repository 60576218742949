import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";

function LoginCallbackPage() {
  const { loading, user, webAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useGroupAnalytics();

  useEffect(() => {
    webAuth.popup.callback({ hash: location.hash });
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (user) {
      analytics?.track("TP_LOGIN_SUCCESS");
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [loading, user]);

  return null;
}

export default LoginCallbackPage;
