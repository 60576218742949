import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, createContext, useContext, useState } from "react";

type Config = {
  body: ReactNode;
  action: ReactNode;
};

type Context = {
  config: Config;
  setConfig: (content: Config) => void;
  isOpen: boolean;
  onOpen: (content: Config) => void;
  onClose: () => void;
};

const context = createContext<Context>({
  config: { body: "", action: null },
  setConfig: console.log,
  isOpen: false,
  onOpen: console.log,
  onClose: console.log,
});

export function Provider({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [config, setConfig] = useState<Config>({ body: "", action: null });

  return (
    <>
      <context.Provider
        value={{
          config,
          setConfig,
          isOpen,
          onOpen(content) {
            setConfig(content);
            onOpen();
          },
          onClose,
        }}
      >
        {children}
      </context.Provider>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Flex justifyContent="center" alignItems="center">
              <VStack minW={60} justifyContent="center">
                <Box>{config.body}</Box>
                <HStack>{config.action}</HStack>
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export function useDrawer() {
  return useContext(context);
}
