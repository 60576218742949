import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../contexts/auth";
import { useFetchMe } from "./useFetchMe";
import { useTenantGroupId } from "./useTenantGroupId";

const MUTATION = gql`
  mutation ($amountInCents: Int!, $paymentMethodId: UUID!, $tenantGroupId: UUID!) {
    payRent(amountInCents: $amountInCents, paymentMethodId: $paymentMethodId, tenantGroupId: $tenantGroupId) {
      ledgerTransactionId
    }
  }
`;

export function usePayRent() {
  const { refetch } = useFetchMe();
  const { gqlClient } = useAuth();
  const tenantGroupId = useTenantGroupId();
  const mutation = useMutation(
    ({ amountInCents, paymentMethodId }: { amountInCents: number; paymentMethodId: string }) =>
      gqlClient.mutate({
        mutation: MUTATION,
        variables: {
          amountInCents,
          paymentMethodId,
          tenantGroupId,
        },
      }),
    {
      async onSuccess() {
        await refetch();
      },
    }
  );

  return {
    payRent: mutation.mutate,
    loading: mutation.isLoading,
  };
}
