import { Box, VStack } from "@chakra-ui/react";
import produce from "immer";
import { useAddAnimal } from "../../../../../hooks/applicants/useAddAnimal";
import { useAddOccupant } from "../../../../../hooks/applicants/useAddOccupant";
import { useApplicantPageView } from "../../../../../hooks/applicants/useApplicantPageView";
import { useClearAnimals } from "../../../../../hooks/applicants/useClearAnimals";
import { useClearOccupants } from "../../../../../hooks/applicants/useClearOccupants";
import { ApplicantOnboardingStage } from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../../../hooks/applicants/useFetchedApplicationMe";
import { useIsPrimaryApplicant } from "../../../../../hooks/applicants/useIsPrimaryApplicant";
import { useRemoveAnimal } from "../../../../../hooks/applicants/useRemoveAnimal";
import { useRemoveOccupant } from "../../../../../hooks/applicants/useRemoveOccupant";
import { useUpdateAnimal } from "../../../../../hooks/applicants/useUpdateAnimal";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useUpdateOccupant } from "../../../../../hooks/applicants/useUpdateOccupant";
import { useScrollIntoView } from "../../../../../hooks/useScrollIntoView";
import { isNullOrEmptyString } from "../../../../../utils/isNullOrEmptyString";
import { maxWidth, padding, width } from "../../config";
import { CurrentAddressCard } from "./CurrentResidenceCard";
import { NameAndContactInformationCard } from "./NameAndContactInformationCard";
import { OccupantsCard } from "./OccupantsCard";
import { PetsCard } from "./PetsCard";
import { getPetsError } from "./getPetsError";

const sectionIds = {
  root: "/0",
  nameAndContact: "/0/children/0",
  currentAddress: "/0/children/1",
  occupants: "/0/children/2",
  pets: "/0/children/3",
};

export function YourInformationSection() {
  const applicantAnalytics = useApplicantPageView("TP_YOUR_INFO_SECTION_VIEWED");
  const {
    applicantById: { doorsteadUser, animals, occupants },
  } = useFetchedApplicationMe();
  const applicant = useFetchedApplicant();
  const scrollIntoView = useScrollIntoView();
  const { mutate: updateAnimal } = useUpdateAnimal();
  const { mutate: addAnimal } = useAddAnimal();
  const { mutate: addOccupant } = useAddOccupant();
  const { mutate: updateOccupant } = useUpdateOccupant();
  const { mutate: removeAnimal } = useRemoveAnimal();
  const { mutate: removeOccupant } = useRemoveOccupant();
  const { mutate: clearAnimals } = useClearAnimals();
  const { mutate: clearOccupants } = useClearOccupants();
  const { mutate: updateOnboardingStage } = useUpdateApplicantOnboardingStage();
  const isPrimaryApplicant = useIsPrimaryApplicant();

  if (applicant === null) {
    return <Box>Sorry, we cannot find this applicant</Box>;
  }

  const PetBox = ({ id }: { id: string }) => {
    return (
      <PetsCard
        idField="applicantAnimalId"
        id={id}
        width={width}
        maxWidth={maxWidth}
        padding={padding}
        subTitle={
          <Box>
            {`Please ensure pet information is complete and accurate. Owners and HOA's can have strict pet rules. Inaccurate information could result in disqualification of your application at a later stage.`}
            <b>Please answer on behalf of all tenants.</b>
          </Box>
        }
        values={animals.edges.map(({ node }) => node)}
        onAdd={addAnimal}
        onReset={() => clearAnimals()}
        onRemove={removeAnimal}
        onChange={(animal) => {
          updateAnimal({
            ...animal,
          });
        }}
        onContinue={async () => {
          applicantAnalytics?.track("TP_PETS_CARD_CONTINUED");

          // occupants validation
          const hasEmptyOccupantFullName = occupants.edges
            .map(({ node }) => node)
            .some(({ fullName }) => isNullOrEmptyString(fullName));

          if (hasEmptyOccupantFullName) {
            scrollIntoView(sectionIds.occupants);
          } else {
            if (getPetsError(animals).hasError) {
              scrollIntoView(sectionIds.pets);
            } else {
              updateOnboardingStage(ApplicantOnboardingStage.DOCUMENTS_SECTION);
            }
          }
        }}
      />
    );
  };

  return (
    <Box id={sectionIds.root}>
      <VStack spacing={5}>
        <NameAndContactInformationCard
          id={sectionIds.nameAndContact}
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          initialValue={{
            firstName: doorsteadUser.firstName || "",
            middleName: doorsteadUser.middleName || "",
            lastName: doorsteadUser.lastName || "",
            suffixName: doorsteadUser.suffixName || "",
            phone: doorsteadUser.phone || "",
            email: doorsteadUser.email || "",
          }}
          onContinue={() => {
            applicantAnalytics?.track("TP_NAME_CONTACT_CARD_CONTINUED");
            scrollIntoView(sectionIds.currentAddress);
          }}
        />
        <CurrentAddressCard
          id={sectionIds.currentAddress}
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          onContinue={() => {
            applicantAnalytics?.track("TP_CURRENT_ADDRESS_CARD_CONTINUED");
            if (isPrimaryApplicant) {
              scrollIntoView(sectionIds.occupants);
            } else {
              // If the user is not the primary applicant, we skip the occupants and Pet section
              updateOnboardingStage(ApplicantOnboardingStage.DOCUMENTS_SECTION);
            }
          }}
        />
        {isPrimaryApplicant && (
          <>
            <OccupantsCard
              idField="applicantOccupantId"
              id={sectionIds.occupants}
              width={width}
              maxWidth={maxWidth}
              padding={padding}
              values={produce(
                occupants.edges.map(({ node }) => node),
                (draft) => {
                  return draft.sort((a, b) => {
                    if (a.createdAt && b.createdAt) {
                      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                    }
                    return 0;
                  });
                }
              )}
              hasAddMoreButton={occupants.edges.length < 4}
              onAdd={addOccupant}
              onReset={() => clearOccupants()}
              onRemove={removeOccupant}
              onChange={(occupant) => {
                updateOccupant({
                  applicantOccupantId: occupant.applicantOccupantId,
                  fullName: occupant.fullName,
                });
              }}
              onContinue={async () => {
                applicantAnalytics?.track("TP_OCCUPANTS_CARD_CONTINUED");
                scrollIntoView(sectionIds.pets);
              }}
            />
            <PetBox id={sectionIds.pets} />
          </>
        )}
      </VStack>
    </Box>
  );
}
