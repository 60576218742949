import { useMutation } from "react-query";
import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { mapKeyStorageFromRRGraphQLResultCache } from "../../utils/rentReady/mapKeyStorageFromRRGraphQLResultCache";
import { useInspectionReportId } from "./useInspectionReportId";

export async function updateKeyStorageCache(inspectionReportId: string, keyStorage: KeyStorage) {
  const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
  if (graphQLCacheResult !== null) {
    const result = mapKeyStorageFromRRGraphQLResultCache(graphQLCacheResult, keyStorage);
    await writeRRGraphQLResult(inspectionReportId, result);
  }
}

export function useUpdateKeyStorageCache() {
  const inspectionReportId = useInspectionReportId();

  return useMutation(async (keyStorage: KeyStorage) => {
    await updateKeyStorageCache(inspectionReportId, keyStorage);
  });
}
