import { Box, Center, HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export function IconBox({ children, title, subTitle }: { children: ReactNode; title: string; subTitle: string }) {
  return (
    <HStack alignItems="center" w="fit-content" spacing={2}>
      <Center w={10} h={10} bgColor="gray.200" p={2} borderRadius={6}>
        {children}
      </Center>
      <VStack w="100%" alignItems="flex-start" fontSize="xs">
        <Box color="gray.500">{title}</Box>
        <Box color="gray.900">{subTitle}</Box>
      </VStack>
    </HStack>
  );
}
