export enum CleanSelectedOption {
  Light = "Light",
  Deep = "Deep",
}

export type ThroughoutPropertyCleaning = {
  cleanIsNeeded: boolean | null;
  cleanSelectedOptions: CleanSelectedOption[];
  cleanIsOdorRemoval: boolean;
  cleanNotes: string;
};
