import { Divider, VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useEffect, useState } from "react";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { Item } from "../../../../@types/RentReady/Item";
import { Komponent, Option, Type } from "../../../../@types/RentReady/Komponent";
import { Photo } from "../../../../@types/RentReady/Photo";
import { useComponent } from "../../../../hooks/rentReady/useComponent";
import { RepairOrReplace, useComponentId } from "../../../../hooks/rentReady/useComponentId";
import { GoodMissingFixStatus } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { useItem } from "../../../../hooks/rentReady/useItem";
import { useSaveComponentSubPage } from "../../../../hooks/rentReady/useSaveComponentSubPage";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { RequiredLabel } from "../../RequiredLabel";
import { CheckboxesField } from "../../fields/CheckboxesField";
import { PhotosField } from "../../fields/PhotosField";
import { TextAreaField } from "../../fields/TextAreaField";
import { useUpdateItemStatus } from "../AreaOneView/ItemRowV2";
import { ComponentMultipleTypeSelect } from "./ComponentMultipleTypeSelect";
import { ComponentSingleTypeSelect } from "./ComponentSingleTypeSelect";
import { isNotesValid } from "./isNotesValid";
import { isSaveDisabled } from "./isSaveDisabled";
import { useDiff } from "./useDiff";

const backView = View.AreaOneView;

export type State = {
  photos: Photo[];
  types: Type[];
  options: Option[];
  note: string;
  updatedDate: Komponent["updatedDate"];
};

function Content({ component: initComponent, item }: { component: Komponent; item: Item }) {
  const { setView } = useView();
  const { value } = useComponentId();
  const success = useSuccess();
  const error = useError();
  const isEditable = useIsEditable();
  const isV2 = useIsV2UiFeatures();
  const componentRepairOrReplace =
    value.repairOrReplace === RepairOrReplace.Repair
      ? initComponent?.repairType || null
      : initComponent?.replaceType || null;

  const initialState = {
    photos: componentRepairOrReplace === null ? [] : componentRepairOrReplace.photos,
    note: componentRepairOrReplace === null ? "" : componentRepairOrReplace.note,
    types: componentRepairOrReplace === null ? [] : componentRepairOrReplace.types,
    options: initComponent === null ? [] : initComponent.options,
    updatedDate: initComponent?.updatedDate || null,
  };
  const [state, setState] = useState<State>(initialState);
  const { mutate: updateItemStatus } = useUpdateItemStatus();
  const { mutate, isLoading } = useSaveComponentSubPage();
  const hasTypeValue = state.types.some((e) => e.selected);
  const hasOptionValue = state.options.some((e) => e.selected);
  const diff = useDiff(initialState, state);
  useEffect(() => {
    setState(initialState);
  }, [JSON.stringify(initialState)]);

  if (initComponent === null) return null;

  const isMultiple = initComponent.optionSelectionType === "multiple";
  const isRepair = value.repairOrReplace === RepairOrReplace.Repair;
  const repairOrReplaceLabel = isRepair ? "Repair" : "Replace";
  const saveDisabled = isSaveDisabled({
    isLoading,
    hasDataUpdated: diff.length > 0,
    hasPhoto: state.photos.length > 0,
    options: state.options,
    types: state.types,
    hasNote: state.note.trim().length > 0,
  });

  return (
    <FullScreen
      headerName={`${initComponent.name} ${repairOrReplaceLabel} Details`}
      backView={backView}
      backButton={
        <OutlineButton isFullWidth disabled={isLoading} onClick={() => setView(View.AreaOneView)}>
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={saveDisabled}
            onClick={() => {
              //if component's item status is not Fix, but component has been updated, update the status to Fix
              mutate(
                {
                  componentState: {
                    ...state,
                    id: initComponent.id,
                    isRepair,
                  },
                },
                {
                  onSuccess() {
                    success({ title: "Component updated!" });
                    if (isV2) {
                      updateItemStatus(
                        {
                          item,
                          status: GoodMissingFixStatus.Fix,
                        },
                        {
                          onSuccess() {
                            setView(View.AreaOneView);
                          },
                        }
                      );
                    }
                  },
                  onError() {
                    error({ title: "Failed to update component", description: "" });
                  },
                }
              );
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <VStack w="100%" alignItems="flex-start" padding={5}>
        <PhotosField
          prefix={Prefix.Components}
          values={state.photos}
          onChange={(photos) => {
            setState(
              produce((draft) => {
                draft.photos = photos;
              })
            );
          }}
        />
        <Divider />
        {state.options.length > 0 && (
          <>
            <RequiredLabel hasValue={hasOptionValue}>{initComponent.name} Type</RequiredLabel>
            <Divider />
            <CheckboxesField<Type>
              id="options"
              values={state.options}
              onChange={(options) => {
                setState(
                  produce((draft) => {
                    draft.options = options;
                  })
                );
              }}
            />
          </>
        )}

        {state.types.length > 0 && (
          <>
            <RequiredLabel hasValue={hasTypeValue}>{repairOrReplaceLabel} Type</RequiredLabel>
            <Divider />
            {isMultiple ? (
              <ComponentMultipleTypeSelect
                values={state.types}
                onChange={(types) => {
                  setState(
                    produce((draft) => {
                      draft.types = types;
                    })
                  );
                }}
              />
            ) : (
              <ComponentSingleTypeSelect
                values={state.types}
                onChange={(types) => {
                  setState(
                    produce((draft) => {
                      draft.types = types;
                    })
                  );
                }}
              />
            )}

            <Divider />
          </>
        )}

        <TextAreaField
          isValid={isNotesValid({
            hasNotes: state.note.trim() !== "",
            options: state.options,
            types: state.types,
          })}
          label="Notes"
          value={state.note}
          onChange={(note) =>
            setState(
              produce((draft) => {
                draft.note = note;
              })
            )
          }
          disabled={useIsReadOnly()}
        />
      </VStack>
    </FullScreen>
  );
}

export function ComponentEditView() {
  const item = useItem();
  const component = useComponent();
  if (component === null || item === null) return null;

  return <Content item={item} component={component} />;
}
