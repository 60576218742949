import { Box, VStack } from "@chakra-ui/react";
import produce from "immer";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { Decision, Estimate } from "../../../../@types/RentReady/Estimate";
import { CheckboxesField } from "../../fields/CheckboxesField";
import { TextAreaField } from "../../fields/TextAreaField";

type Props = {
  estimate: Estimate;
  onChange: (estimate: Estimate) => void;
};

enum CheckboxId {
  OutOfScope = "out of scope",
  ToBeDetermined = "to be determined",
}

export function CannotEstimateBox({ estimate, onChange }: Props) {
  return (
    <VStack alignItems="flex-start" w="100%">
      <CheckboxesField
        isMultiple={false}
        values={[
          {
            id: CheckboxId.OutOfScope,
            name: "Out of scope",
            selected: estimate.isOutOfScope,
          },
          {
            id: CheckboxId.ToBeDetermined,
            name: "To be determined",
            selected: estimate.isToBeDetermined,
          },
        ]}
        onChange={(values) => {
          onChange(
            produce(estimate, (draft) => {
              if (draft.decision === Decision.CanNotEstimate) {
                draft.isOutOfScope = values.find((v) => v.id === CheckboxId.OutOfScope)?.selected ?? false;
                draft.isToBeDetermined = values.find((v) => v.id === CheckboxId.ToBeDetermined)?.selected ?? false;
              }
            })
          );
        }}
      />
      <Box fontWeight="bold">Reason notes</Box>
      <TextAreaField
        label=""
        value={estimate.note}
        onChange={(value) =>
          onChange(
            produce(estimate, (draft) => {
              if (draft.decision === Decision.CanNotEstimate) {
                draft.note = value;
              }
            })
          )
        }
        disabled={useIsReadOnly()}
      />
    </VStack>
  );
}
