import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useRRProjectId } from "./useRRProjectId";

export function useSubmitOwnerEstimateApproval() {
  const { gqlClient } = useAuth();
  const rrProjectId = useRRProjectId();

  return () => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($data: SubmitOwnerEstimateApprovalData) {
          submitOwnerEstimateApproval(data: $data) {
            rrProjectId
          }
        }
      `,
      variables: {
        data: {
          rrProjectId,
        },
      },
    });
  };
}
