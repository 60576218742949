import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { ApplicantFileNode } from "./useFetchApplicationMe/ApplicantFileNode";

export function useAddApplicantFile() {
  const applicantId = useApplicantId();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async ({
      fileName,
      key,
      bucket,
      contentType,
      type: fileType,
      objectSizeInBytes,
      lastModifiedTimestamp,
    }: Omit<ApplicantFileNode, "applicantFileId">) => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation (
            $applicantId: UUID!
            $bucket: String!
            $contentType: String!
            $fileName: String!
            $fileType: String!
            $key: String!
            $lastModifiedTimestamp: DateTime!
            $objectSizeInBytes: Int!
          ) {
            createApplicantFile(
              applicantId: $applicantId
              bucket: $bucket
              contentType: $contentType
              fileName: $fileName
              fileType: $fileType
              key: $key
              lastModifiedTimestamp: $lastModifiedTimestamp
              objectSizeInBytes: $objectSizeInBytes
            ) {
              result
            }
          }
        `,
        variables: {
          applicantId,
          bucket,
          contentType,
          fileName,
          fileType,
          key,
          lastModifiedTimestamp,
          objectSizeInBytes,
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
