import { useEffect, useRef } from "react";
import { ApplicantAnalytics, useApplicantAnalytics } from "./useApplicantAnalytics";
import { useApplicantId } from "./useApplicantId";

export function useApplicantPageView(eventName: string): ApplicantAnalytics {
  const applicantId = useApplicantId();
  const analytics = useApplicantAnalytics();
  const isPageViewed = useRef(false);

  useEffect(() => {
    if (analytics && !isPageViewed.current) {
      analytics.track(eventName, { applicantId });
      isPageViewed.current = true;
    }
  }, [analytics]);

  return analytics;
}
