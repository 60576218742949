import { CancelTokenSource } from "axios";
import React from "react";
import { ApplicantFileNodeType } from "../../../../../hooks/applicants/useFetchApplicationMe/ApplicantFileNode";

type UploadingFile = {
  id: string;
  progress: number;
  file: File;
  cancelTokenSource: CancelTokenSource | null;
};

type ContextProps = {
  applicantFileType: ApplicantFileNodeType;
  uploadingFilesMap: Record<string, UploadingFile>;
  setUploadingFilesMap: React.Dispatch<React.SetStateAction<Record<string, UploadingFile>>>;
};

const context = React.createContext<ContextProps>({
  applicantFileType: null as any,
  uploadingFilesMap: {},
  setUploadingFilesMap() {
    console.log("setUploadingFilesMap");
  },
});

export function Provider({
  applicantFileType,
  children,
}: {
  applicantFileType: ApplicantFileNodeType;
  children: React.ReactNode;
}) {
  const [uploadingFilesMap, setUploadingFilesMap] = React.useState<Record<string, UploadingFile>>({});
  return (
    <context.Provider
      value={{
        applicantFileType,
        uploadingFilesMap,
        setUploadingFilesMap,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useInternalState() {
  return React.useContext(context);
}
