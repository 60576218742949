import { Box } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useState } from "react";
import { InspectionReportNode } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useInspectionReport } from "../../../../hooks/rentReady/useInspectionReport";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useUpdateInspectionReport } from "../../../../hooks/rentReady/useUpdateInspectionReport";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { numberToCommaString } from "../../../../utils/numberToCommaString";
import { FullScreen } from "../../FullScreen";
import { RentReadyNumberField } from "../../RentReadyNumberField";
import { ControlBox } from "./ControlBox";
import { PropertyTypeSelect } from "./PropertyTypeSelect";
import { useDiff } from "./useDiff";

export function PropertyDetailEditView() {
  const { setView } = useView();
  const remoteInspectionReport = useInspectionReport();
  const { mutate: updateInspectionReport, isLoading } = useUpdateInspectionReport();
  const [state, setState] = useState<InspectionReportNode>(remoteInspectionReport);
  const diff = useDiff(remoteInspectionReport, state);
  const isEditable = useIsEditable();
  const success = useSuccess();
  const error = useError();

  const onChange = (field: keyof InspectionReportNode, value: any) => {
    setState((prevState) =>
      produce(prevState, (draft) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-prototype-builtins
        if (draft.hasOwnProperty(field)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          draft[field] = value;
        }
      })
    );
  };

  return (
    <FullScreen
      contentProps={{ p: 5 }}
      headerName="Editing property details"
      backView={View.PropertyDetailView}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(View.PropertyDetailView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isLoading || diff.length === 0}
            onClick={async () => {
              await updateInspectionReport(
                {
                  propertyType: state.propertyType,
                  propertyTypeNotes: state.propertyTypeNotes,
                  bedrooms: state.bedrooms,
                  bedroomNotes: state.bedroomNotes,
                  fullBathrooms: state.fullBathrooms,
                  fullBathroomNotes: state.fullBathroomNotes,
                  halfBathrooms: state.halfBathrooms,
                  halfBathroomNotes: state.halfBathroomNotes,
                  sqFtNotes: state.sqFtNotes,
                  parking: state.parking,
                  parkingNotes: state.parkingNotes,
                },
                {
                  onSuccess() {
                    success({
                      title: "Property updated",
                    });
                    setView(View.PropertyDetailView);
                  },
                  onError() {
                    error({
                      title: "Error updating property",
                      description: "There was an error updating the property. Please try again.",
                    });
                  },
                }
              );
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <ControlBox
        label="Type"
        control={
          <PropertyTypeSelect value={state.propertyType} onChange={(value) => onChange("propertyType", value)} />
        }
        note={state.propertyTypeNotes}
        onNoteChange={(value) => onChange("propertyTypeNotes", value)}
      />
      <ControlBox
        label="Bedrooms"
        control={<RentReadyNumberField value={state.bedrooms || 0} onChange={(value) => onChange("bedrooms", value)} />}
        note={state.bedroomNotes}
        onNoteChange={(value) => onChange("bedroomNotes", value)}
      />
      <ControlBox
        label="Full Bathrooms"
        control={
          <RentReadyNumberField
            value={state.fullBathrooms || 0}
            onChange={(value) => onChange("fullBathrooms", value)}
          />
        }
        note={state.fullBathroomNotes}
        onNoteChange={(value) => onChange("fullBathroomNotes", value)}
      />
      <ControlBox
        label="Half Bathrooms"
        control={
          <RentReadyNumberField
            value={state.halfBathrooms || 0}
            onChange={(value) => onChange("halfBathrooms", value)}
          />
        }
        note={state.halfBathroomNotes}
        onNoteChange={(value) => onChange("halfBathroomNotes", value)}
      />
      <ControlBox
        label="Property sqft"
        control={<Box>{numberToCommaString(state.sqFt || 0)}</Box>}
        note={state.sqFtNotes}
        onNoteChange={(value) => onChange("sqFtNotes", value)}
      />
      <ControlBox
        label="Parking Spots"
        control={<RentReadyNumberField value={state.parking || 0} onChange={(value) => onChange("parking", value)} />}
        note={state.parkingNotes}
        onNoteChange={(value) => onChange("parkingNotes", value)}
      />
    </FullScreen>
  );
}
