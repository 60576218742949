import { Komponent } from "../../@types/RentReady/Komponent";
import { isOptionsDiff, sortOptionsById } from "./isOptionsDiff";
import { isRepairOrReplaceTypeDiff } from "./isRepairOrReplaceTypeDiff";
import { isTypesDiff, sortTypesById } from "./isTypesDiff";

export function isKomponentDiff(data1: Komponent, data2: Komponent): boolean {
  // Sort the options in both komponents by ID
  const sortedOptions1 = sortOptionsById(data1.options);
  const sortedOptions2 = sortOptionsById(data2.options);

  // Sort the types in both komponents by ID
  const sortedTypes1 = sortTypesById(data1.repairType.types);
  const sortedTypes2 = sortTypesById(data2.repairType.types);

  // Compare the properties of the komponents
  if (
    data1.id !== data2.id ||
    data1.name !== data2.name ||
    data1.optionSelectionType !== data2.optionSelectionType ||
    data1.isShowReplaceOptions !== data2.isShowReplaceOptions ||
    data1.isShowRepairOptions !== data2.isShowRepairOptions
  ) {
    return true;
  }

  // Compare the sorted options in the komponents
  if (isOptionsDiff(sortedOptions1, sortedOptions2)) {
    return true;
  }

  if (isTypesDiff(sortedTypes1, sortedTypes2)) {
    return true;
  }

  // Compare the repair types in the komponents
  if (isRepairOrReplaceTypeDiff(data1.repairType, data2.repairType)) {
    return true;
  }

  // Compare the replace types in the komponents
  return isRepairOrReplaceTypeDiff(data1.replaceType, data2.replaceType);
}
