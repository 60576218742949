import { Box, Stack, Text, VStack } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { moneyString } from "@doorstead/components";
import { differenceInDays, differenceInHours, format } from "date-fns";
import { useMemo, useState } from "react";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useApplicantPageView } from "src/hooks/applicants/useApplicantPageView";
import { useFetchedApplicationRequest } from "src/hooks/applicants/useFetchedApplicationRequest";
import { useLeaseNegotiateApplicationRequest } from "src/hooks/applicants/useLeaseNegotiateApplicationRequest";
import { useListing } from "src/hooks/applicants/useListing";
import { getNegotiatedDateFormattedDateString } from "src/utils/getNegotiatedDateFormattedDateString";
import { getUuid } from "src/utils/getUuid";
import { useFetchApplicationMe } from "../../../hooks/applicants/useFetchApplicationMe";
import { useIsNegotiated } from "../../../hooks/applicants/useIsNegotiated";
import { useTrackImpressionEvent } from "../../../hooks/useTrackImpressionEvent";
import { LeaseStartDateOption, Option } from "./LeaseStartDateOption";

type Props = {
  pageViewEventNamePrefix?: string;
  submitButtonText?: string;
  onSuccess?: () => void;
};

function getLocaleDate(utcDateString: string): Date {
  const utcDate = new Date(utcDateString);
  const localeDate = new Date(utcDate);
  localeDate.setMinutes(localeDate.getMinutes() - new Date().getTimezoneOffset());

  return localeDate;
}

function formatNegotiationDeadline(expirationDateString: string | null): string {
  if (!expirationDateString) {
    return "";
  }

  const expirationLocaleDate = getLocaleDate(expirationDateString); // 2024-06-18T20:00:00 UTC+8
  // console.log("expiration locale Date: ", expirationLocaleDate);
  const formattedDate = format(expirationLocaleDate, "ccc LLLL d, yyyy h:mma");
  const now = new Date();
  // console.log("now locale Date: ", now);
  const hoursLeft = differenceInHours(expirationLocaleDate, now);
  return `${formattedDate} (${hoursLeft} hours left)`;
}

export function ApplicantLeaseNegotiationStartDatePage({
  pageViewEventNamePrefix = "TP_APPLICATION_LEASE_",
  submitButtonText = "Continue",
  onSuccess = () => {
    console.log("on submit Success");
  },
}: Props) {
  useApplicantPageView(`${pageViewEventNamePrefix}START_DATE_VIEWED`);
  const { data: meData } = useFetchApplicationMe();
  const applicantId = meData?.applicantById?.applicantId || "";
  const applicationRequestId = meData?.applicantById?.applicationRequest.applicationRequestId || "";
  const analytics = useApplicantAnalytics();
  const { price: listingPrice, moveInOptions } = useListing();
  const { negotiatedMoveInDate, negotiatedRent, negotiationLinkEndAt } = useFetchedApplicationRequest();
  const { mutate: leaseNegotiate, isLoading } = useLeaseNegotiateApplicationRequest();
  const isMobile = useIsMobile();
  const options: Option[] = useMemo(
    () =>
      moveInOptions.map(({ startDate, endDate, price }) => ({
        id: getUuid(),
        startDate: startDate,
        endDate: endDate,
        price,
        listingPrice,
      })),
    []
  );
  const [option, setOption] = useState<Option | null>(null);
  const [leaseStartDate, setLeaseStartDate] = useState<string | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const isNegotiated = useIsNegotiated();

  if (!isNegotiated) {
    useTrackImpressionEvent({
      data: {
        applicantId,
        applicationRequestId,
        negotiateOptions: options,
      },
      email: meData?.applicantById.doorsteadUser.email || "",
      propertyId: meData?.applicantById?.applicationRequest?.property?.propertyId || "",
      action: "LEASE_NEGOTIATION_INITIAL_IMPRESSION",
      surface: "APPLICANT_PORTAL",
    });
  }

  return (
    <VStack gap={6} mb={8}>
      <Stack width="100%" direction={isMobile ? "column" : "row"} spacing={isMobile ? 0 : 5} px={7}>
        <Text color="gray.800" fontSize={isMobile ? "4xl" : "5xl"} fontWeight="extrabold">
          Select
        </Text>
        <Text color="gray.800" fontSize={isMobile ? "4xl" : "5xl"} fontWeight="extrabold">
          lease start date
        </Text>
      </Stack>

      <Text color="gray.600" fontSize="md" fontWeight="medium" px={7}>
        <b>Applications with earlier lease start dates receive priority.</b> Additionally, selecting an earlier start
        date may allow you to lock in a lower rent.
      </Text>
      {isNegotiated && (
        <VStack w="100%">
          <VStack w="100%" borderRadius={6} color="gray.600" bgColor="purple.100" py={5} px={7} alignItems="flex-start">
            <Box>
              <b>Important:</b> You are renegotiating your lease start date. Any date changes may require repricing your
              monthly rent accordingly.
            </Box>
            <Box fontWeight="bold">
              Current lease start date: {getNegotiatedDateFormattedDateString(negotiatedMoveInDate)}
            </Box>
            <Box fontWeight="bold">Current monthly rent: {moneyString(negotiatedRent, false)}</Box>
          </VStack>
          <VStack w="100%" borderRadius={6} color="gray.600" bgColor="red.200" py={5} px={7} alignItems="flex-start">
            <Box fontWeight="bold">
              Lease start date selection deadline: {formatNegotiationDeadline(negotiationLinkEndAt)}
            </Box>
          </VStack>
        </VStack>
      )}
      <Stack width="100%" flexWrap={isMobile ? "wrap" : "nowrap"} spacing={0} flexDirection="column" gap={8}>
        {options.map((opt: Option, index: number) => (
          <LeaseStartDateOption
            key={opt.id}
            {...opt}
            selected={option?.id === opt.id}
            onClick={() => {
              setOption(opt);
              setLeaseStartDate(null);
              setPrice(null);
              analytics.track(`${pageViewEventNamePrefix}START_DATE_OPTION_CLICKED`, {
                selectedOption: opt,
                selectedOptionIndex: index,
                options,
                listingPrice,
              });
            }}
            onSelect={(date, price) => {
              // console.log("Select date: ", date, "price: ", price);
              setLeaseStartDate(date);
              setPrice(price);
              analytics.track(`${pageViewEventNamePrefix}START_DATE_SELECTED`, {
                selectedDate: date,
                selectedDateIndex: differenceInDays(new Date(date), new Date(options[0].startDate)),
                selectedPrice: price,
                selectedOption: opt,
                selectedOptionIndex: index,
                options,
                listingPrice,
              });
            }}
          />
        ))}
      </Stack>
      <Box borderRadius={6} color="gray.600" bgColor="orange.100" py={5} px={8}>
        <b>Important:</b> Once selected, your lease start date and monthly rental rate will be locked in. Any date
        changes may require repricing your monthly rent accordingly.
      </Box>
      <PrimaryButton
        disabled={!leaseStartDate}
        isLoading={isLoading}
        onClick={() => {
          if (leaseStartDate && price) {
            analytics.track(`${pageViewEventNamePrefix}START_DATE_SUBMITTED`, {
              leaseStartDate: leaseStartDate,
              price,
              listingPrice,
            });
            // console.log("Submit Lease Start Date ", leaseStartDate);

            leaseNegotiate(
              {
                negotiatedRent: price,
                negotiatedMoveInDate: leaseStartDate,
              },
              { onSuccess }
            );
          }
        }}
      >
        {submitButtonText}
      </PrimaryButton>
      <Box>
        <Text mt={8} fontSize="sm" color="gray.500">
          Offer valid if application completed in 48 hours. Subject to final review by Doorstead.
        </Text>
      </Box>
    </VStack>
  );
}
