import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

export type VendorRepair = {
  vendorRepairId: string;
  estimateApproved: boolean | null;
};

export function useUpdateVendorRepairs() {
  const { gqlClient } = useAuth();

  return ({ vendorRepairs }: { vendorRepairs: VendorRepair[] }) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation update($vendorRepairs: [VendorRepairUpdateData]) {
          updateVendorRepairs(vendorRepairs: $vendorRepairs) {
            count
          }
        }
      `,
      variables: {
        vendorRepairs,
      },
    });
  };
}
