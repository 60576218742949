import { Scale } from "../../../@types/RentReady/Scale";
import { useIsKeyStorageFullTenantSetsFeature } from "../useIsKeyStorageFullTenantSetsFeature";
import { useKeyStorageCache } from "../useKeyStorageCache";

export function useIsFullTenantSetsComplete(): boolean {
  const keyStorage = useKeyStorageCache();
  const isKeyStorageFullTenantSetsFeature = useIsKeyStorageFullTenantSetsFeature();

  if (keyStorage === null) {
    return false;
  }

  // if the feature is not enabled, then we can assume that the user has data
  if (!isKeyStorageFullTenantSetsFeature) return true;

  const {
    fullTenants: { photos, number },
  } = keyStorage;

  switch (number) {
    case Scale.NotProvided: {
      return false;
    }
    case Scale.Zero:
    case Scale.One:
    case Scale.Two:
    case Scale.Three:
    case Scale.Four:
    default: {
      return photos.length > 0;
    }
  }
}
