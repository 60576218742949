import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { useAppendPositionToHashTag } from "../../../../hooks/rentReady/useAppendPositionToHashTag";
import { useAreasCache } from "../../../../hooks/rentReady/useAreasCache";
import { useAutoFocusHashPosition } from "../../../../hooks/rentReady/useAutoFocusHashPosition";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsDebugMode } from "../../../../hooks/rentReady/useIsDebugMode";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsKeyDetailComplete } from "../../../../hooks/rentReady/useIsKeyDetailComplete";
import { useIsKeyStorageComplete } from "../../../../hooks/rentReady/useIsKeyStorageComplete";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { useIsThroughoutPropertyCleaningComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyCleaningComplete";
import { useIsThroughoutPropertyDetectorComplete } from "../../../../hooks/rentReady/useIsThroughoutPropertyDetectorComplete";
import { useIsThroughoutPropertyFeature } from "../../../../hooks/rentReady/useIsThroughoutPropertyFeature";
import { useRemovePositionToHashTag } from "../../../../hooks/rentReady/useRemovePositionToHashTag";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { TitleRow } from "../../TitleRow";
import { AreaListSubmitButton } from "./AreaListSubmitButton";
import { AreaRow } from "./AreaRow";
import { AreaRows } from "./AreaRows";

export function AreaListView() {
  const { setView } = useView();
  const areas = useAreasCache();
  const throughoutPropertyAreas = areas.filter(({ isThroughoutProperty }) => isThroughoutProperty);
  const nonThroughoutPropertyAreas = areas.filter(({ isThroughoutProperty }) => !isThroughoutProperty);
  const isKeyDetailComplete = useIsKeyDetailComplete();
  const isKeyStorageComplete = useIsKeyStorageComplete();
  const isEditable = useIsEditable();
  const isReadOnly = useIsReadOnly();
  const isThroughoutPropertyCleaningComplete = useIsThroughoutPropertyCleaningComplete();
  const isThroughoutPropertyDetectorComplete = useIsThroughoutPropertyDetectorComplete();
  const isThroughoutPropertyFeature = useIsThroughoutPropertyFeature();
  const isOnline = useIsOnline();
  const isDebugMode = useIsDebugMode();
  const appendPositionToHashTag = useAppendPositionToHashTag("area_list");
  const removePositionToHashTag = useRemovePositionToHashTag("area_list");
  useAutoFocusHashPosition("area_list");
  useDisplayInstruction(View.AreaListView);
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  return (
    <Flex flexDirection="column" w="100%">
      <Box flex={1}>
        <TitleRow text="Access" />
        <VStack w="100%" spacing={5} p={5}>
          <AreaRow
            text="Key Details"
            isComplete={isKeyDetailComplete}
            onClick={() => {
              appendPositionToHashTag();
              setView(View.KeyDetailsView);
            }}
          />
          <AreaRow
            text="Key Storage"
            isComplete={isKeyStorageComplete}
            onClick={() => {
              appendPositionToHashTag();
              setView(View.KeyStorageView);
            }}
          />
        </VStack>
        <TitleRow
          text="Areas"
          right={
            ((isEditable && isOnline) || isDebugMode) && (
              <Box
                onClick={() => {
                  appendPositionToHashTag();
                  setView(View.AreaListEditView);
                }}
              >
                Edit Area List
              </Box>
            )
          }
        />
        <VStack w="100%" spacing={5} p={5}>
          {nonThroughoutPropertyAreas.length === 0 ? (
            <Box>Can not find any areas</Box>
          ) : (
            <AreaRows areas={nonThroughoutPropertyAreas} />
          )}
        </VStack>

        {isThroughoutPropertyFeature && (
          <>
            <TitleRow text="Throughout Property" />
            <VStack w="100%" spacing={5} p={5}>
              {!shouldUseSimplifiedTemplate ? (
                <AreaRow
                  text="Cleaning"
                  isComplete={isThroughoutPropertyCleaningComplete}
                  onClick={() => {
                    appendPositionToHashTag();
                    setView(View.ThroughoutPropertyCleaningEditView);
                  }}
                />
              ) : null}
              <AreaRow
                text="Smoke & CO Detectors"
                isComplete={isThroughoutPropertyDetectorComplete}
                onClick={() => {
                  appendPositionToHashTag();
                  setView(View.ThroughoutPropertyDetectorEditView);
                }}
              />
              <AreaRows areas={throughoutPropertyAreas} />
            </VStack>
          </>
        )}
      </Box>
      <HStack p={5} spacing={4} as="footer" pos="sticky" bottom={0} bgColor="white">
        {isReadOnly && (
          <OutlineButton
            isFullWidth
            onClick={() => {
              removePositionToHashTag();
              setView(View.PropertyDetailView);
            }}
          >
            Back
          </OutlineButton>
        )}
        <AreaListSubmitButton />
      </HStack>
    </Flex>
  );
}
