import { isAfter } from "date-fns";
import type { Row } from "../components/TransactionHistoryTable";
import { Status } from "../components/TransactionHistoryTable";
import { LedgerTransaction, ReceivableLedgerEntryHistory } from "./useFetchMe";
import { useFetchedTenant } from "./useFetchedTenant";

export function getStatus(legerTransactionStatus: LedgerTransaction["status"]): Status {
  switch (legerTransactionStatus) {
    case "pending":
      return Status.Pending;
    case "posted":
      return Status.Successful;
    case "archived":
    default:
      return Status.Failed;
  }
}

function sortByDateDesc(a: ReceivableLedgerEntryHistory, b: ReceivableLedgerEntryHistory): number {
  const aStr = a.ledgerTransaction.effectiveDate;
  const bStr = b.ledgerTransaction.effectiveDate;
  if (aStr === bStr) {
    return 0;
  } else {
    const aDate = new Date(aStr);
    const bDate = new Date(bStr);
    return isAfter(aDate, bDate) ? -1 : 1;
  }
}

export function useTransactionHistories(): Row[] {
  const tenant = useFetchedTenant();
  const receivableLedgerEntryHistory = tenant.activeLease.tenantGroup.receivableLedgerEntryHistory;

  return [...receivableLedgerEntryHistory]
    .sort(sortByDateDesc)
    .map(
      ({
        ledgerTransactionId: id,
        resultingAvailableAccountBalanceInCents: balanceInCents,
        direction,
        ledgerTransaction: { effectiveDate, status, category, description },
        amountInCents,
      }) => ({
        id,
        transactionDate: effectiveDate,
        details: description || category,
        chargeInCents: direction === "debit" ? amountInCents : null,
        paymentInCents: direction === "credit" ? amountInCents : null,
        balanceInCents,
        status: direction === "debit" ? null : getStatus(status),
      })
    );
}
