import produce from "immer";

export function attachCounting<T>(items: T[], fromName: keyof T, toName: keyof T): T[] {
  return produce(items, (draft) => {
    const map: Record<string, number> = {};
    draft.forEach((i) => {
      const item: T = i as T;
      const key = item[fromName] as string;

      if (!map[key]) {
        map[key] = 1;
        item[toName] = `${item[fromName]}` as T[keyof T];
      } else {
        map[key] += 1;
        item[toName] = `${item[fromName]} ${map[key]}` as T[keyof T];
      }
    });
  });
}
