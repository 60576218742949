import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { useFetchFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport";
import { FlexWalkthroughItemNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { FlexWalkthroughReportNode } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/FlexWalkthroughReportNode";
import { useSyncServerToLocalDb } from "src/hooks/flexWalkthrough/useSyncServerToLocalDb";
import { useSyncSingleWalkthroughItemFieldToCacheAndServer } from "src/hooks/flexWalkthrough/useSyncSingleWalkthroughItemFieldToCacheAndServer";
import { useUpdateFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useUpdateFlexWalkthroughReport";
import { ArgsReport } from "src/hooks/flexWalkthrough/useUpdatePartialWalkthroughReportToServer";
import { ActionButtons } from "./ActionButtons";
import { InstructionIcon } from "./InstructionIcon";
import { ReportAutoSyncTextField } from "./ReportAutoSyncTextField";

type Props = {
  text: string;
  tooltip: string;
  field: keyof FlexWalkthroughReportNode;
  notesField: keyof ArgsReport;
};

export function useUpdateItemStatus() {
  const syncSingleWalkthroughItemFieldToCacheAndServer = useSyncSingleWalkthroughItemFieldToCacheAndServer();

  return useMutation(({ item, response }: { item: FlexWalkthroughItemNode; response: string }) => {
    return syncSingleWalkthroughItemFieldToCacheAndServer(item, "response", "response", response, response);
  });
}

export function SafetyRow({ text, tooltip, field, notesField }: Props) {
  const { data } = useFetchFlexWalkthroughReport();
  const { mutate: updateFlexWalkthroughReport } = useUpdateFlexWalkthroughReport();
  const serverToLocalDb = useSyncServerToLocalDb();

  return (
    <>
      <VStack w="100%" p={2}>
        <Flex w="100%" justifyContent="space-between">
          <Flex justifyContent="center" alignItems="center" fontSize="sm" fontWeight="bold">
            <HStack>
              <Box>{text}</Box>
              <InstructionIcon text={tooltip} />
            </HStack>
          </Flex>
          <HStack>
            <ActionButtons
              response={
                data?.flexWalkthroughReport[field] ? "Yes" : data?.flexWalkthroughReport[field] === false ? "No" : ""
              }
              positiveActionText={"Yes"}
              negativeActionText={"No"}
              onChange={async (toResponse) => {
                await updateFlexWalkthroughReport(
                  {
                    [field]: toResponse === "Yes" ? true : false,
                  },
                  {
                    onSuccess: serverToLocalDb,
                  }
                );
              }}
            />
          </HStack>
        </Flex>
        {data?.flexWalkthroughReport[field] === false && (
          <Flex display={"flex"} justifyContent="space-between" alignItems="center" w="100%" p={2}>
            <Box>
              <ReportAutoSyncTextField
                isValid={
                  data?.flexWalkthroughReport[notesField] !== null &&
                  data?.flexWalkthroughReport[notesField]?.toString().trim() !== ""
                }
                defaultValue={data?.flexWalkthroughReport[notesField]?.toString() || ""}
                cacheField={notesField}
                serverField={notesField}
              />
            </Box>
          </Flex>
        )}
      </VStack>
    </>
  );
}
