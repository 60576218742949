import { ApplicantNode } from "../../../../../hooks/applicants/useFetchApplicationMe";
import { isNullOrEmptyString } from "../../../../../utils/isNullOrEmptyString";

export function getPetsError(animals: ApplicantNode["animals"]): { hasError: boolean; error: string } {
  const pets = animals.edges.map((e) => e.node);
  for (const pet of pets) {
    if (pet.type === "") {
      return { hasError: true, error: "Please select a pet type" };
    }
    if (pet.type === "Dog") {
      if (isNullOrEmptyString(pet.breed)) {
        return { hasError: true, error: "Please enter a dog breed" };
      }
      if (pet.weightLb === 0) {
        return { hasError: true, error: "Please enter a dog weight" };
      }
    }
  }

  return { hasError: false, error: "" };
}
