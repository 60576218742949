import { Area } from "../../@types/RentReady/Area";
import { isDateDiff } from "../isDateDiff";
import { isPhotosDiff, sortPhotosById } from "./isPhotosDiff";

export type ComparedArea = Omit<Area, "items">;

export function isAreaDiff(area1: ComparedArea, area2: ComparedArea): boolean {
  // Sort the photos in both areas by ID
  const sortedPhotos1 = sortPhotosById(area1.photos);
  const sortedPhotos2 = sortPhotosById(area2.photos);

  // Compare the properties of the areas
  if (
    area1.id !== area2.id ||
    area1.name !== area2.name ||
    area1.uiName !== area2.uiName ||
    area1.cleanLight !== area2.cleanLight ||
    area1.notes !== area2.notes ||
    area1.cleanDeep !== area2.cleanDeep ||
    area1.cleanOdors !== area2.cleanOdors ||
    area1.cleanPersonalItemRemoval !== area2.cleanPersonalItemRemoval ||
    area1.cleaningNotes !== area2.cleaningNotes ||
    area1.itemRemovalNotes !== area2.itemRemovalNotes ||
    area1.painting !== area2.painting ||
    area1.isThroughoutProperty !== area2.isThroughoutProperty ||
    area1.paintingNotes !== area2.paintingNotes ||
    area1.smokeDetectorIsOneWorkingDetectorFound !== area2.smokeDetectorIsOneWorkingDetectorFound ||
    area1.smokeDetectorNotes !== area2.smokeDetectorNotes ||
    isDateDiff(area1.createDate, area2.createDate)
  ) {
    return true;
  }

  // Compare the sorted photos in the areas
  if (isPhotosDiff(sortedPhotos1, sortedPhotos2)) {
    return true;
  }

  // Sort the item removal photos in both areas by ID
  const sortedItemRemovalPhotos1 = sortPhotosById(area1.itemRemovalPhotos);
  const sortedItemRemovalPhotos2 = sortPhotosById(area2.itemRemovalPhotos);

  // Compare the sorted item removal photos in the areas
  return isPhotosDiff(sortedItemRemovalPhotos1, sortedItemRemovalPhotos2);
}
