import { AutoPayDate } from "./typings";

export function getValue(date: AutoPayDate): string {
  switch (date) {
    case AutoPayDate.FIRST:
      return "FIRST";
    case AutoPayDate.SECOND:
      return "SECOND";
    case AutoPayDate.THIRD:
      return "THIRD";
    case AutoPayDate.TWENTY_EIGHTH:
      return "TWENTY_EIGHTH";
    case AutoPayDate.LAST:
      return "LAST";
    default:
      throw new Error("Not implemented yet");
  }
}
