import { gql } from "@apollo/client";
import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { useAuth } from "../../contexts/auth";
import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useInspectionReportCache } from "./useInspectionReportCache";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  cleanIsNeeded: InspectionReportNode["cleanIsNeeded"];
  cleanSelectedOptions: InspectionReportNode["cleanSelectedOptions"];
  cleanIsOdorRemoval: InspectionReportNode["cleanIsOdorRemoval"];
  cleanNotes: InspectionReportNode["cleanNotes"];
  updatedAtThroughoutPropertyCleaning: InspectionReportNode["updatedAtThroughoutPropertyCleaning"];
};

type Result = {
  data: {
    updateInspectionReportThroughoutPropertyCleaning: {
      success: boolean;
      merged: boolean;
      throughoutPropertyCleaning: Args;
    };
  };
};

export function useMapToArgs() {
  const inspectionReportCache = useInspectionReportCache();

  return (throughoutPropertyCleaning: ThroughoutPropertyCleaning): Args => {
    return {
      cleanIsNeeded: throughoutPropertyCleaning.cleanIsNeeded,
      cleanSelectedOptions: throughoutPropertyCleaning.cleanSelectedOptions,
      cleanIsOdorRemoval: throughoutPropertyCleaning.cleanIsOdorRemoval,
      cleanNotes: throughoutPropertyCleaning.cleanNotes,
      updatedAtThroughoutPropertyCleaning: inspectionReportCache!.updatedAtThroughoutPropertyCleaning,
    };
  };
}

export function useUpdateThroughoutPropertyCleaningToServer() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return async (args: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $cleanIsNeeded: Boolean
          $cleanIsOdorRemoval: Boolean
          $cleanNotes: String
          $cleanSelectedOptions: [String]
          $inspectionReportId: UUID!
          $updatedAtThroughoutPropertyCleaning: DateTime
        ) {
          updateInspectionReportThroughoutPropertyCleaning(
            cleanIsNeeded: $cleanIsNeeded
            cleanIsOdorRemoval: $cleanIsOdorRemoval
            cleanNotes: $cleanNotes
            cleanSelectedOptions: $cleanSelectedOptions
            inspectionReportId: $inspectionReportId
            updatedAtThroughoutPropertyCleaning: $updatedAtThroughoutPropertyCleaning
          ) {
            success
            merged
            throughoutPropertyCleaning {
              cleanIsNeeded
              cleanSelectedOptions
              cleanIsOdorRemoval
              cleanNotes
              updatedAtThroughoutPropertyCleaning
            }
          }
        }
      `,
      variables: {
        ...args,
        inspectionReportId,
      },
    })) as Result;
  };
}
