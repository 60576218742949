import { useIsCoTenantsInviteV2Feature } from "./useIsCoTenantsInviteV2Feature";
import { useIsInvitedCoTenantsAndGuarantors } from "./useIsInvitedCoTenantsAndGuarantors";
import { useIsPrimaryApplicant } from "./useIsPrimaryApplicant";

export function useIsShowInvitedCoTenantLink() {
  const isCoTenantsInviteV2Feature = useIsCoTenantsInviteV2Feature();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const isInvitedCoTenantsAndGuarantors = useIsInvitedCoTenantsAndGuarantors();

  if (isCoTenantsInviteV2Feature) {
    return isPrimaryApplicant && isInvitedCoTenantsAndGuarantors;
  } else {
    return isPrimaryApplicant;
  }
}
