import { ApolloClient, gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";

export async function removeAnimal(gqlClient: ApolloClient<any>, applicantAnimalId: string) {
  await gqlClient.mutate({
    mutation: gql`
      mutation ($applicantAnimalId: UUID) {
        deleteApplicantAnimal(applicantAnimalId: $applicantAnimalId) {
          result
        }
      }
    `,
    variables: {
      applicantAnimalId,
    },
  });
}

export function useRemoveAnimal() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (applicationAnimalId: string) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        const animalIndex = draft.applicantById.animals.edges.findIndex(
          (e) => e.node.applicantAnimalId === applicationAnimalId
        );
        draft.applicantById.animals.edges.splice(animalIndex, 1);
      })
    );

    return await removeAnimal(gqlClient, applicationAnimalId);
  });
}
