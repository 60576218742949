import { Box } from "@chakra-ui/react";
import { useEstimatesGuidelinesModal } from "../../../hooks/rr-project/useEstimatesGuidelinesModal";

export function EstimatesToApproveRepairsGuidelines() {
  const { onOpen } = useEstimatesGuidelinesModal();

  return (
    <Box color="gray.700" as="button" textDecoration="underline" onClick={onOpen}>
      Instructions
    </Box>
  );
}
