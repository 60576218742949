import produce from "immer";
import { Komponent } from "../../@types/RentReady/Komponent";
import { arrayToRecord } from "../../utils/arrayToRecord";
import { isOffline } from "../../utils/isOffline";
import { isKomponentDiff } from "../../utils/rentReady/isKomponentDiff";
import { mapComponentToArgs, useUpdateComponentToServer } from "./useUpdateComponentToServer";

export function useUpdateComponentsToServer() {
  const updateComponentToServer = useUpdateComponentToServer();

  return async (componentsCache: Komponent[], componentsSever: Komponent[]): Promise<Komponent[]> => {
    if (isOffline() || componentsCache.length === 0) return componentsCache;
    else {
      componentsCache = produce(componentsCache, (draft) => {
        return draft;
      });
      //make map[id: Component]
      const componentsServerMap = arrayToRecord(componentsSever, "id");

      for (const componentCache of componentsCache) {
        //1. update to server if updated
        if (isKomponentDiff(componentCache, componentsServerMap[componentCache.id])) {
          //update
          await updateComponentToServer(mapComponentToArgs(componentCache));
        }
      }

      return componentsCache;
    }
  };
}
