import { Link, useDisclosure } from "@chakra-ui/react";
import { Ref, forwardRef } from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { AutopayDisclaimerModal } from "./AutopayDisclaimerModal";

type Props = {
  onAgree?: () => void;
  onClick?: () => void;
};

export const AutopayDisclaimerText = forwardRef(({ onAgree, onClick }: Props, ref: Ref<HTMLAnchorElement>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const analytics = useGroupAnalytics();

  return (
    <>
      <AutopayDisclaimerModal
        isOpen={isOpen}
        onOk={() => {
          analytics?.track("TP_AUTOPAY_DISCLAIMER_AGREED");
          onClose();
          onAgree?.();
        }}
        onClose={() => {
          analytics?.track("TP_AUTOPAY_DISCLAIMER_DISMISSED");
          onClose();
        }}
      />
      <Link
        ref={ref}
        onClick={() => {
          analytics?.track("TP_AUTOPAY_DISCLAIMER_CLICKED");
          onOpen();
          onClick?.();
        }}
        fontSize="sm"
        color="gray.500"
      >
        Important terms regarding autopay
      </Link>
    </>
  );
});
