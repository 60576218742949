import { gql } from "@apollo/client";
import { Spacer, Text } from "@chakra-ui/react";
import { OptionsField, PrimaryButton, TextField, useIsMobile } from "@doorstead/components";
import { Status } from "@doorstead/components/fields/TextField";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import { BaseCard } from "src/components/Onboarding/steps/MakePaymentStep/BaseCard";
import { useAuth } from "src/contexts/auth";
import { AccountType, TaxpayerType, useCreateVendorCounterparty } from "src/hooks/useCreateVendorCounterparty";
import { useError } from "src/hooks/useError";
import { usePageView } from "src/hooks/usePageView";
import { useSuccess } from "src/hooks/useSuccess";
import { isValidEIN } from "src/utils/isValidEIN";
import { isValidRoutingNumber } from "src/utils/isValidRoutingNumber";
import { isValidSSN } from "src/utils/isValidSSN";

interface Counterparty {
  counterpartyId: string;
  name: string | null;
  accountType: AccountType | null;
  accountNumberMasked: string | null;
  routingNumber: string | null;
  taxpayerType: TaxpayerType | null;
  taxpayerIdentifier: string | null;
  mtCounterpartyId: string | null;
}

interface Vendor {
  vendorId: string;
  name: string;
  email: string | null;
  phone: string | null;
  address: string | null;
  counterparty: Counterparty | null;
}

interface VendorResult {
  vendor: Vendor | null;
}

export function VendorOnboarding() {
  const analytics = usePageView("VENDOR_ONBOARDING_VIEWED");
  const isMobile = useIsMobile();
  const { gqlClient } = useAuth();
  const success = useSuccess();
  const error = useError();
  const [params] = useSearchParams();
  const vendorId = params.get("vendorId");
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [accountType, setAccountType] = useState<AccountType>(AccountType.CHECKING);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [routingNumberError, setRoutingNumberError] = useState<string>("");
  const [taxpayerType, setTaxpayerType] = useState<TaxpayerType>(TaxpayerType.INDIVIDUAL);
  const [taxpayerName, setTaxpayerName] = useState<string>("");
  const [taxpayerIdentifier, setTaxpayerIdentifier] = useState<string>("");
  const [taxpayerIdentifierError, setTaxpayerIdentifierError] = useState<string>("");
  const { createVendorCounterparty, loading } = useCreateVendorCounterparty();
  const editable = !vendor?.counterparty?.mtCounterpartyId;

  const checkTaxpayerIdentifier = (type: TaxpayerType, tin: string) => {
    if (type === TaxpayerType.INDIVIDUAL && !isValidSSN(tin)) {
      setTaxpayerIdentifierError("Invalid SSN");
    } else if (type === TaxpayerType.BUSINESS && !isValidEIN(tin)) {
      setTaxpayerIdentifierError("Invalid EIN");
    } else {
      setTaxpayerIdentifierError("");
    }
  };

  const fetchVendor = async () => {
    const { data } = await gqlClient.query<VendorResult | null>({
      query: gql`
        query GetVendor($vendorId: UUID) {
          vendor(vendorId: $vendorId) {
            vendorId
            name
            email
            phone
            address
            counterparty {
              counterpartyId
              name
              accountType
              accountNumberMasked
              routingNumber
              taxpayerType
              taxpayerIdentifier
              mtCounterpartyId
            }
          }
        }
      `,
      variables: { vendorId },
    });
    if (data?.vendor) {
      setVendor(data?.vendor);
      if (data.vendor.counterparty) {
        const { accountType, accountNumberMasked, routingNumber, taxpayerType, name, taxpayerIdentifier } =
          data.vendor.counterparty;
        setAccountType(accountType || AccountType.CHECKING);
        setAccountNumber(`****${accountNumberMasked}` || "");
        setRoutingNumber(routingNumber || "");
        setTaxpayerType(taxpayerType || TaxpayerType.INDIVIDUAL);
        setTaxpayerName(name || "");
        setTaxpayerIdentifier(taxpayerIdentifier || "");
      }
    }
  };

  useEffect(() => {
    if (!vendorId) {
      return;
    }

    fetchVendor();
  }, [vendorId]);

  if (!vendor || !vendorId) {
    return null;
  }

  return (
    <BaseCard title="Submit bank account information">
      <Helmet>
        <title>Doorstead vendor onboarding</title>
        <meta name="description" content="Doorstead vendor onboarding" />
      </Helmet>
      <TextField
        id="name"
        label="Name"
        value={vendor.name}
        disabled
        onChange={() => {
          //
        }}
      />
      {vendor.email && (
        <TextField
          id="email"
          label="Email"
          value={vendor.email}
          disabled
          onChange={() => {
            //
          }}
        />
      )}
      {vendor.phone && (
        <TextField
          id="phone"
          label="Phone"
          value={vendor.phone}
          disabled
          onChange={() => {
            //
          }}
        />
      )}
      {vendor.address && (
        <TextField
          id="address"
          label="Address"
          value={vendor.address}
          disabled
          onChange={() => {
            //
          }}
        />
      )}
      <Text color="gray.600" fontSize="md" pt={6}>
        Bank account information
      </Text>
      <OptionsField
        forceVertical={false}
        gap={2}
        disabled={!editable}
        options={[
          {
            id: AccountType.CHECKING,
            value: AccountType.CHECKING,
            label: (
              <Text color="gray.800" fontWeight="700">
                Checking
              </Text>
            ),
          },
          {
            id: AccountType.SAVINGS,
            value: AccountType.SAVINGS,
            label: (
              <Text color="gray.800" fontWeight="700">
                Savings
              </Text>
            ),
          },
        ]}
        value={accountType}
        onChange={(value) => setAccountType(value as AccountType)}
      />
      <TextField
        id="accountNumber"
        label="Account Number"
        disabled={!editable}
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        status={accountNumber ? Status.Correct : Status.Default}
      />
      <TextField
        id="routingNumber"
        label="Routing Number"
        disabled={!editable}
        value={routingNumber}
        onChange={(e) => {
          setRoutingNumber(e.target.value);
          if (!isValidRoutingNumber(e.target.value)) {
            setRoutingNumberError("Invalid routing number");
          } else {
            setRoutingNumberError("");
          }
        }}
        errorMessage={routingNumberError}
        status={routingNumber ? (routingNumberError ? Status.Error : Status.Correct) : Status.Default}
      />
      <Text color="gray.600" fontSize="md" pt={6}>
        Tax information
      </Text>
      <OptionsField
        forceVertical={false}
        gap={2}
        disabled={!editable}
        options={[
          {
            id: TaxpayerType.INDIVIDUAL,
            value: TaxpayerType.INDIVIDUAL,
            label: (
              <Text color="gray.800" fontWeight="700">
                Individual
              </Text>
            ),
          },
          {
            id: TaxpayerType.BUSINESS,
            value: TaxpayerType.BUSINESS,
            label: (
              <Text color="gray.800" fontWeight="700">
                Business
              </Text>
            ),
          },
        ]}
        value={taxpayerType}
        onChange={(value) => {
          setTaxpayerType(value as TaxpayerType);
          checkTaxpayerIdentifier(value as TaxpayerType, taxpayerIdentifier);
        }}
      />
      <TextField
        id="taxpayerName"
        label="Name"
        disabled={!editable}
        value={taxpayerName}
        onChange={(e) => setTaxpayerName(e.target.value)}
        status={taxpayerName ? Status.Correct : Status.Default}
      />
      <TextField
        id="taxpayerIdentifier"
        label={taxpayerType === TaxpayerType.INDIVIDUAL ? "SSN" : "EIN"}
        disabled={!editable}
        value={taxpayerIdentifier}
        onChange={(e) => {
          setTaxpayerIdentifier(e.target.value);
          checkTaxpayerIdentifier(taxpayerType, e.target.value);
        }}
        errorMessage={taxpayerIdentifierError}
        status={taxpayerIdentifier ? (taxpayerIdentifierError ? Status.Error : Status.Correct) : Status.Default}
      />
      <Spacer pt={3} />
      <PrimaryButton
        disabled={
          loading ||
          !editable ||
          !accountNumber ||
          !routingNumber ||
          !!routingNumberError ||
          !taxpayerName ||
          !taxpayerIdentifier ||
          !!taxpayerIdentifierError
        }
        isLoading={loading}
        isFullWidth={isMobile}
        onClick={async () => {
          analytics?.track("CREATE_VENDOR_COUNTERPARTY_CLICKED", {
            vendor,
            accountType,
            taxpayerName,
            taxpayerType,
          });
          await createVendorCounterparty(
            {
              vendorId,
              w9Entity: taxpayerName,
              taxpayerType,
              taxpayerIdentifier: taxpayerIdentifier,
              bankAccountNumber: accountNumber,
              bankRoutingNumber: routingNumber,
              bankAccountType: accountType,
            },
            {
              onSuccess() {
                analytics?.track("CREATE_VENDOR_COUNTERPARTY_SUCCESS", {
                  vendor,
                  accountType,
                  taxpayerName,
                  taxpayerType,
                });
                success({ title: "Bank account information submitted" });
                fetchVendor();
              },
              onError(e: any) {
                console.error(e);
                analytics?.track("CREATE_VENDOR_COUNTERPARTY_FAILED", {
                  vendor,
                  accountType,
                  taxpayerName,
                  taxpayerType,
                });
                const errorMessage =
                  e.graphQLErrors && e.graphQLErrors.length !== 0 ? e.graphQLErrors[0].message : null;
                error({ title: "Failed to submit bank account information", description: errorMessage });
              },
            }
          );
        }}
      >
        Submit
      </PrimaryButton>
    </BaseCard>
  );
}
