import { UploadButton as DoorsteadUploadButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useError } from "src/hooks/useError";
import { useAddApplicantFile } from "../../../../../hooks/applicants/useAddApplicantFile";
import { useApplicantAnalytics } from "../../../../../hooks/applicants/useApplicantAnalytics";
import { useIsApplicantEditable } from "../../../../../hooks/applicants/useIsApplicantEditable";
import { useUploadFile } from "../../../../../hooks/applicants/useUploadFile";
import { getUniqueIdFromFile } from "./getUniqueFileId";
import { useInternalState } from "./useInternalState";

export function ToUploadButton() {
  const applicantAnalytics = useApplicantAnalytics();
  const disabled = !useIsApplicantEditable();
  const { mutate: addApplicantFile } = useAddApplicantFile();
  const { applicantFileType, setUploadingFilesMap } = useInternalState();
  const error = useError();
  const { mutate: uploadIdCard } = useUploadFile(
    ({ file, progress }) => {
      setUploadingFilesMap((prev) => {
        return produce(prev, (draft) => {
          const fileId = getUniqueIdFromFile(file);
          draft[fileId].progress = progress;
        });
      });
    },
    async ({ file, key, bucket }) => {
      const fileId = getUniqueIdFromFile(file);
      applicantAnalytics?.track(`TP_${applicantFileType}_FILE_UPLOADED`, { fileId });

      //3. remove the file from the map
      setUploadingFilesMap((prev) => {
        return produce(prev, (draft) => {
          delete draft[fileId];
        });
      });

      await addApplicantFile({
        fileName: file.name,
        key,
        bucket,
        contentType: file.type,
        type: applicantFileType,
        objectSizeInBytes: file.size,
        lastModifiedTimestamp: new Date(file.lastModified).toISOString(),
      });
    }
  );

  return (
    <DoorsteadUploadButton
      isMultiple
      disabled={disabled}
      accept=".pdf"
      maxSize={20 * 1024 * 1024} // 20MB
      onExceedMaxFize={(files) => {
        if (files.length > 0) {
          error({
            title: "File size too large",
            description: `The file size of ${files[0].name} is too large. Please upload files less than 20MB.`,
          });
        }
      }}
      onSelectFiles={(files) => {
        applicantAnalytics?.track(`TP_${applicantFileType}_FILES_SELECTED`, { numberOfFiles: files.length });
        files.forEach((file) => {
          // 1. set the map for uploading
          setUploadingFilesMap((prev) => {
            return produce(prev, (draft) => {
              const id = getUniqueIdFromFile(file);
              draft[id] = {
                id,
                progress: 0,
                file,
                cancelTokenSource: null,
              };
            });
          });

          //2. upload the file to s3
          uploadIdCard({
            file,
            fileType: applicantFileType,
            setCancelSourceToken(cToken) {
              setUploadingFilesMap((prev) => {
                return produce(prev, (draft) => {
                  const id = getUniqueIdFromFile(file);
                  draft[id].cancelTokenSource = cToken;
                });
              });
            },
          });
        });
      }}
    />
  );
}
