import { Box, Divider, Grid, GridItem, VStack } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { Repair } from "../../../@types/RepairList/Repair";
import { WhiteBlock } from "../WhiteBlock";

type Props = {
  isActionNeeded?: boolean;
  tip: ReactNode;
  repairs: Repair[];
  title: string;
  columns: string[];
  templateColumns: string;
  TableRow: (repair: Repair) => ReactElement;
};

function Column({ children }: { children: string }) {
  return (
    <GridItem color="gray.500" fontSize="sm">
      {children}
    </GridItem>
  );
}

export function DesktopView({ repairs, title, columns, templateColumns, TableRow, tip, isActionNeeded = true }: Props) {
  return (
    <WhiteBlock title={title} isActionNeeded={isActionNeeded} topRight={tip}>
      <Grid templateColumns={templateColumns} w="100%" gap={4}>
        {columns.map((e) => (
          <Column key={e}>{e}</Column>
        ))}
      </Grid>
      <Divider />
      <VStack w="100%">
        {repairs.length === 0 && <Box>No repairs yet</Box>}
        {repairs.map((repairNode) => (
          <TableRow key={repairNode.id} {...repairNode} />
        ))}
      </VStack>
    </WhiteBlock>
  );
}
