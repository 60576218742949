import { Image, Photo } from "../../@types/RentReady/Photo";
import { dataUrlToThumbnailFile } from "../../utils/dataUrToThumbnailFile";
import { dataURLtoFile } from "../../utils/dataUrlToFile";
import { getAwsUrl } from "../../utils/getAwsUrl";
import { useUploadToS3 } from "../useUploadToS3";
import { useGenerateAllS3UrlForRentReady } from "./useGenerateAllS3UrlForRentReady";
import { useOnFileProgress, useOnFileUploadFailed } from "./useUploadProgress";

export enum Prefix {
  FrontendDoorKeys = "frontend-door-keys",
  MailBoxKeys = "mailbox-keys",
  CommunityKeys = "community-keys",
  GarageRemotes = "garage-remotes",
  KeyPads = "keypads",
  AdditionalKeys = "additionalKeys",
  LockBox = "lockbox",
  LockBoxLocation = "lockbox-location",
  StorageFullTenant = "storage-fulltenant",
  AdditionalKeyStorage = "AdditionalKeyStorage",
  Items = "items",
  Components = "components",
  Areas = "areas",
  AreasPersonalItemRemovals = "AreasPersonalItemRemovals",
  AreasSmokeDetectors = "AreasSmokeDetectors",
  AreasHeatSource = "AreasHeatSource",
  SmokeDetector = "SmokeDetector",
  CarbonMonoxideDetectors = "CarbonMonoxideDetectors",
  WalkthroughItem = "WalkthroughItem",
  FlexWalkthroughReport = "FlexWalkthroughReport",
}

const bucket = "doorstead-rent-ready-app";

export type ImageWithPrefix = Image & {
  isSrc: boolean;
  photo: Photo;
};

function useActualUpload() {
  const uploadToS3 = useUploadToS3();
  const fileProgress = useOnFileProgress();
  const onFileUploadFailed = useOnFileUploadFailed();

  return async (uploadUrl: string, photo: PhotoWithPrefix, file: File, isSrc: boolean) => {
    return new Promise((resolve) => {
      const { id, toUploadFile, prefix } = photo;
      if (toUploadFile === null) return;

      const fileName = isSrc ? id : `${id}_s`;
      const key = `${prefix}/${fileName}`;

      if (file === null) return;
      uploadToS3({
        url: uploadUrl,
        file,
        onProgress({ progress }) {
          fileProgress({ group: prefix, progress, fileName });
        },
      })
        .then(() => {
          const url = getAwsUrl({ bucket, key });
          if (isSrc) {
            photo.src = {
              bucket,
              key,
              fileName,
              url,
            };

            if (photo.thumbnail.url !== "") {
              photo.isUploaded = true;
              photo.toUploadFile = null;
            }
          } else {
            photo.thumbnail = {
              bucket,
              key,
              fileName,
              url,
            };
            if (photo.src.url !== "") {
              photo.isUploaded = true;
              photo.toUploadFile = null;
            }
          }
          resolve({});
        })
        .catch(() => {
          onFileUploadFailed({
            group: prefix,
            fileName,
          });
        });
    });
  };
}

type UrlKey = {
  photo: PhotoWithPrefix;
  isSrc: boolean;
  key: string;
  fileName: string;
};

export type PhotoWithPrefix = Photo & { prefix: Prefix };

function useGetS3UploadUrlMap() {
  const generateAllS3UrlForRentReady = useGenerateAllS3UrlForRentReady();

  return async (photos: PhotoWithPrefix[]): Promise<Map<UrlKey, string>> => {
    const originalKeys = photos.map((e) => `${e.prefix}/${e.id}`);
    const thumbnailKeys = photos.map((e) => `${e.prefix}/${e.id}_s`);
    const keys = originalKeys.concat(thumbnailKeys);
    const awsUploadUrls = await generateAllS3UrlForRentReady(keys);
    // build the map
    const map = new Map<UrlKey, string>();
    awsUploadUrls.forEach((url, index) => {
      const isSrc = index < originalKeys.length;
      const photoIndex = isSrc ? index : index - originalKeys.length;
      const photo = photos[photoIndex];
      const key = `${photo.prefix}/${index < originalKeys.length ? photo.id : `${photo.id}_s`}`;
      const fileName = isSrc ? photo.id : `${photo.id}_s`;

      map.set(
        {
          photo,
          isSrc,
          key,
          fileName,
        },
        url
      );
    });

    return map;
  };
}

function usePrefixUploadList() {
  const actualUpload = useActualUpload();

  return (urlMap: Map<UrlKey, string>, thumbnailMap: Map<string, File>): Promise<ImageWithPrefix>[] => {
    const ps: Promise<any>[] = [];

    for (const key of Array.from(urlMap.keys())) {
      const { isSrc, photo, fileName } = key;
      if (urlMap.has(key)) {
        const url = urlMap.get(key)!;
        const toUploadFile = photo.toUploadFile;

        if (toUploadFile) {
          const file = isSrc ? dataURLtoFile({ dataUrl: toUploadFile.dataUrl, fileName }) : thumbnailMap.get(photo.id);
          if (file) {
            ps.push(actualUpload(url, photo, file, isSrc));
          }
        }
      }
    }

    return ps;
  };
}

async function getThumbnailMap(photos: Photo[]): Promise<Map<string, File>> {
  const thumbnailMap: Map<string, File> = new Map();
  for (const { toUploadFile, id } of photos) {
    if (toUploadFile) {
      const fileName = `${id}_s`;
      const thumbnail = await dataUrlToThumbnailFile({ dataUrl: toUploadFile.dataUrl, fileName });
      thumbnailMap.set(id, thumbnail);
    }
  }
  return thumbnailMap;
}

export function useUpload() {
  const getS3UploadUrlMap = useGetS3UploadUrlMap();
  const prefixUploadList = usePrefixUploadList();

  return async (photos: PhotoWithPrefix[]): Promise<Promise<ImageWithPrefix>[]> => {
    if (photos.length === 0) return [];

    const thumbnailMap = await getThumbnailMap(photos);
    const s3UploadUrlMap = await getS3UploadUrlMap(photos);
    return prefixUploadList(s3UploadUrlMap, thumbnailMap);
  };
}
