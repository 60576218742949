import { useMutation } from "react-query";
import { ApplicantOnboardingStage } from "./useFetchApplicationMe/Applicant";
import { useUpdateApplicantField } from "./useUpdateApplicantField";

export function useUpdateApplicantOnboardingStage() {
  const { mutate: updateApplicantField } = useUpdateApplicantField();

  return useMutation(async (onboardingStage: ApplicantOnboardingStage) => {
    return await updateApplicantField({
      onboardingStage,
    });
  });
}
