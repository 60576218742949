import { Heading } from "@chakra-ui/react";

export type Props = {
  children: string;
};

export function CardTitle({ children }: Props) {
  return (
    <Heading variant="h4" fontSize={32} fontWeight={600}>
      {children}
    </Heading>
  );
}
