import { Flex, HStack, StackProps, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { View } from "../../../hooks/rentReady/useView";
import { Header } from "./Header";

type Props = {
  headerName: string;
  backView: View;
  children: ReactNode;
  backButton?: ReactNode;
  nextButton?: ReactNode;
  contentProps?: StackProps;
  onBack?: () => void;
};

export function FullScreen({
  headerName,
  backView,
  backButton = null,
  nextButton = null,
  contentProps = {},
  onBack,
  children,
}: Props) {
  return (
    <Flex flexDirection="column" w="100%">
      <Header backView={backView} name={headerName} onBack={onBack} />
      <VStack flex={1} w="100%">
        <Flex w="100%" h="100%" flexDirection="column">
          <VStack {...contentProps} w="100%" alignItems="flex-start" flex={1}>
            {children}
          </VStack>
          {(backButton !== null || nextButton !== null) && (
            <HStack
              pos="sticky"
              bottom={0}
              bgColor="white"
              as="footer"
              w="100%"
              justifyContent="space-between"
              mt={2}
              spacing={5}
              padding={5}
              zIndex={1}
            >
              {backButton}
              {nextButton}
            </HStack>
          )}
        </Flex>
      </VStack>
    </Flex>
  );
}
