import produce from "immer";
import { useMutation } from "react-query";
import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { readRRGraphQLResultCache, writeRRGraphQLResult } from "../../databases/RentReadyDatabase";
import { isOnline } from "../../utils/isOnline";
import { updateKeyDetailCache } from "../../utils/rentReady/updateKeyDetailCache";
import { useInspectionReportId } from "./useInspectionReportId";
import { useMapKeyDetailToArgs, useUpdateKeyDetailToServer } from "./useUpdateKeyDetailToServer";
import { PhotoWithPrefix, Prefix, useUpload } from "./useUpload";

function getPhotos(keyDetailState: KeyDetail): PhotoWithPrefix[] {
  const photos: PhotoWithPrefix[] = [];
  keyDetailState.frontDoorKeys.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.FrontendDoorKeys;
    photos.push(photo as PhotoWithPrefix);
  });
  keyDetailState.mailboxKeys.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.MailBoxKeys;
    photos.push(photo as PhotoWithPrefix);
  });
  keyDetailState.communityKeys.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.CommunityKeys;
    photos.push(photo as PhotoWithPrefix);
  });
  keyDetailState.garageRemotes.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.GarageRemotes;
    photos.push(photo as PhotoWithPrefix);
  });
  keyDetailState.keyPads.photos.forEach((photo) => {
    (photo as PhotoWithPrefix).prefix = Prefix.KeyPads;
    photos.push(photo as PhotoWithPrefix);
  });

  return photos;
}

function useGetUploadedKeyDetail() {
  const upload = useUpload();

  return async (keyDetailState: KeyDetail): Promise<KeyDetail> => {
    return produce(keyDetailState, async (draft) => {
      await Promise.all(await upload(getPhotos(draft)));
    });
  };
}

export function useSaveKeyDetailSubPage() {
  const inspectionReportId = useInspectionReportId();
  const updateKeyDetailToServer = useUpdateKeyDetailToServer();
  const mapKeyDetailToArgs = useMapKeyDetailToArgs();
  const getUploadedKeyDetail = useGetUploadedKeyDetail();

  return useMutation(async (keyDetailState: KeyDetail) => {
    await updateKeyDetailCache(inspectionReportId, keyDetailState);

    if (isOnline()) {
      const newKeyDetailState = await getUploadedKeyDetail(keyDetailState);
      const {
        data: {
          updateInspectionReportKeyDetail: { success, merged, keyDetail: returnedKeyDetail },
        },
      } = await updateKeyDetailToServer(mapKeyDetailToArgs(newKeyDetailState));
      if (success || merged) {
        //save back to cache
        const cache = await readRRGraphQLResultCache(inspectionReportId);
        if (cache !== null) {
          cache.inspectionReport.additionalKeys = returnedKeyDetail.additionalKeys;
          cache.inspectionReport.additionalKeysWorking = returnedKeyDetail.additionalKeysWorking;
          cache.inspectionReport.additionalKeysNotes = returnedKeyDetail.additionalKeysNotes;
          cache.inspectionReport.additionalKeysPhotoUrlsJson = returnedKeyDetail.additionalKeysPhotoUrlsJson;
          cache.inspectionReport.frontDoorKeys = returnedKeyDetail.frontDoorKeys;
          cache.inspectionReport.frontDoorKeysWorking = returnedKeyDetail.frontDoorKeysWorking;
          cache.inspectionReport.frontDoorKeysNotes = returnedKeyDetail.frontDoorKeysNotes;
          cache.inspectionReport.frontDoorKeysPhotoUrlsJson = returnedKeyDetail.frontDoorKeysPhotoUrlsJson;
          cache.inspectionReport.mailboxKeys = returnedKeyDetail.mailboxKeys;
          cache.inspectionReport.mailboxKeysWorking = returnedKeyDetail.mailboxKeysWorking;
          cache.inspectionReport.mailboxKeysNotes = returnedKeyDetail.mailboxKeysNotes;
          cache.inspectionReport.mailboxKeysPhotoUrlsJson = returnedKeyDetail.mailboxKeysPhotoUrlsJson;
          cache.inspectionReport.communityKeys = returnedKeyDetail.communityKeys;
          cache.inspectionReport.communityKeysWorking = returnedKeyDetail.communityKeysWorking;
          cache.inspectionReport.communityKeysNotes = returnedKeyDetail.communityKeysNotes;
          cache.inspectionReport.communityKeysPhotoUrlsJson = returnedKeyDetail.communityKeysPhotoUrlsJson;
          cache.inspectionReport.garageRemotes = returnedKeyDetail.garageRemotes;
          cache.inspectionReport.garageRemotesWorking = returnedKeyDetail.garageRemotesWorking;
          cache.inspectionReport.garageRemotesNotes = returnedKeyDetail.garageRemotesNotes;
          cache.inspectionReport.garageRemotesPhotoUrlsJson = returnedKeyDetail.garageRemotesPhotoUrlsJson;
          cache.inspectionReport.keypads = returnedKeyDetail.keypads;
          cache.inspectionReport.keypadsWorking = returnedKeyDetail.keypadsWorking;
          cache.inspectionReport.keypadsNotes = returnedKeyDetail.keypadsNotes;
          cache.inspectionReport.keypadsPhotoUrlsJson = returnedKeyDetail.keypadsPhotoUrlsJson;
          cache.inspectionReport.updatedAtKeyDetail = returnedKeyDetail.updatedAtKeyDetail;

          //write back to cache
          await writeRRGraphQLResult(inspectionReportId, cache);
        }
      }
    }
  });
}
