import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicantLeaseNegotiationStartDatePage } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage";
import { useApplicantId } from "../../hooks/applicants/useApplicantId";
import { useFetchApplicationMe } from "../../hooks/applicants/useFetchApplicationMe";
import { useFetchedApplicationRequest } from "../../hooks/applicants/useFetchedApplicationRequest";
import { useIsNegotiated } from "../../hooks/applicants/useIsNegotiated";
import { ApplicantPortalProvider } from "../applicants/ApplicantPortalProvider";
import { NegotiationLinkProvider } from "./NegotiationLinkProvider";

function Content() {
  const { refetch } = useFetchApplicationMe();
  const primaryApplicantId = useFetchedApplicationRequest().primaryApplicant.applicantId;
  const isNegotiated = useIsNegotiated();
  const navigate = useNavigate();
  const applicantId = useApplicantId();
  const isPrimaryApplicant = primaryApplicantId === applicantId;

  useEffect(() => {
    if (!isNegotiated || !isPrimaryApplicant) {
      navigate(`/application/${applicantId}`);
    }
  }, [applicantId, isNegotiated, isPrimaryApplicant]);

  return (
    <ApplicantLeaseNegotiationStartDatePage
      pageViewEventNamePrefix="TP_LEASE_RENEGOTIATION_"
      submitButtonText="Submit lease start date"
      onSuccess={async () => {
        const latestMe = await refetch();
        if ((latestMe.data?.applicantById?.applicationRequest?.appSubmittedAt || null) === null) {
          navigate(`/application/${applicantId}`);
        } else {
          window.location.reload();
        }
      }}
    />
  );
}

export function LeaseNegotiationOnePage() {
  return (
    <NegotiationLinkProvider>
      <ApplicantPortalProvider>
        <Content />
      </ApplicantPortalProvider>
    </NegotiationLinkProvider>
  );
}
