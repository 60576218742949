import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";
import { ApplicantAnimalNode } from "./useFetchApplicationMe/Applicant";

export function useUpdateAnimal() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    async (animal: Partial<ApplicantAnimalNode> & { applicantAnimalId: ApplicantAnimalNode["applicantAnimalId"] }) => {
      queryClient.setQueryData(
        queryKey,
        produce(queryClient.getQueryData(queryKey), (draft: Result) => {
          draft.applicantById.animals.edges.forEach((edge) => {
            if (edge.node.applicantAnimalId === animal.applicantAnimalId) {
              edge.node = Object.assign(edge.node, animal);
            }
          });
        })
      );

      await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantAnimal: ApplicantAnimalUpdateData) {
            updateApplicantAnimal(applicantAnimal: $applicantAnimal) {
              count
            }
          }
        `,
        variables: {
          applicantAnimal: {
            ...animal,
            applicantAnimalId: animal.applicantAnimalId,
          },
        },
      });
    }
  );
}
