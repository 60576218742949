import type { Operation, RemoveOperation } from "fast-json-patch";
import { useMutation } from "react-query";
import { Area as RemoteArea } from "../../../../@types/RentReady/Area";
import { useAddAreas } from "../../../../hooks/rentReady/useAddAreas";
import { useRemoveAreas } from "../../../../hooks/rentReady/useRemoveAreas";
import { useSyncServerToLocalDb } from "../../../../hooks/rentReady/useSyncServerToLocalDb";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useSuccess } from "../../../../hooks/useSuccess";
import { getNowISOStringWithoutZ } from "../../../../utils/getNowISOStringWithoutZ";
import { Record as RecordType } from "../RecordListEditView/Record";

export function useOnSaveAreaList() {
  const { setView } = useView();
  const success = useSuccess();
  const addAreas = useAddAreas();
  const removeAreas = useRemoveAreas();
  const syncServerToLocalDb = useSyncServerToLocalDb();

  return useMutation(
    async ({ diff, records }: { diff: Operation[]; records: RecordType[] }) => {
      const idName: Record<string, string> = {};
      records.forEach((e) => {
        idName[e.id] = e.name;
      });

      const toAdds: { id: string; name: string }[] = [];
      diff
        .filter((e) => e.op === "add")
        .map((e) => {
          const id = e.path.split("/")[1];
          records
            .filter((e) => e.id === id)
            .forEach((e) => {
              toAdds.push({
                id: e.id,
                name: e.name,
              });
            });
        });

      const removeAreaOperationList = diff.filter((e) => e.op === "remove") as RemoveOperation[];
      const isRemoveDiff = removeAreaOperationList.length > 0;

      if (toAdds.length > 0) {
        await addAreas({
          areas: toAdds.map((e) => {
            const area: RemoteArea = {
              id: e.id,
              name: e.name,
              uiName: e.name,
              isThroughoutProperty: false,
              notes: "",
              photos: [],
              itemRemovalPhotos: [],
              cleanLight: false,
              cleanDeep: false,
              cleanOdors: false,
              cleanPersonalItemRemoval: false,
              cleaningNotes: "",
              items: [],
              painting: false,
              itemRemovalNotes: "",
              paintingNotes: "",
              smokeDetectorIsOneWorkingDetectorFound: null,
              smokeDetectorNotes: "",
              smokeDetectorStatus: null,
              smokeDetectorPhotos: [],
              heatSourceNotes: "",
              heatSourceStatus: null,
              heatSourcePhotos: [],
              createDate: new Date(),
              updateDate: getNowISOStringWithoutZ(),
            };
            return area;
          }),
        });
      }
      if (isRemoveDiff) {
        await removeAreas({
          areaIds: removeAreaOperationList.map((e) => e.path.substring(1)),
        });
      }
    },
    {
      async onSuccess() {
        await syncServerToLocalDb();
        success({
          title: "Saved",
        });
        setView(View.AreaListView);
      },
    }
  );
}
