import { Flex, FlexProps, VStack } from "@chakra-ui/react";
import { GrayText } from "@doorstead/components";
import React from "react";
import { MoneyText } from "../../MoneyText";

export type Props = FlexProps & {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
};

export function IconBox({ icon, title, subTitle, ...flexProps }: Props) {
  return (
    <Flex {...flexProps} alignItems="center" gap={5} h="44px">
      <Flex
        borderRadius="6px"
        bgColor="gray.100"
        padding={3}
        alignItems="center"
        justifyContent="center"
        w="44px"
        h="44px"
      >
        {icon}
      </Flex>
      <VStack w="100%" alignItems="flex-start">
        <MoneyText>{title}</MoneyText>
        <GrayText>{subTitle}</GrayText>
      </VStack>
    </Flex>
  );
}
