import { Item } from "../../@types/RentReady/Item";
import { useClearItem } from "./useClearItem";
import { useIsEditable } from "./useIsEditable";
import { useOpenClearDefectDrawer } from "./useOpenClearDefectDrawer";

export function useRemoveItemClick(item: Item) {
  const { mutate: clearItem } = useClearItem();
  const openClearDefectDrawer = useOpenClearDefectDrawer();
  const isEditable = useIsEditable();

  return () => {
    if (isEditable) {
      openClearDefectDrawer({
        onConfirm() {
          return clearItem({ item });
        },
      });
    }
  };
}
