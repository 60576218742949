import {
  Box,
  Flex,
  ListItem,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useContext } from "react";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

function Paragraph({ title, contents }: { title: string; contents: string[] }) {
  return (
    <>
      <Box color="gray.800" fontWeight="bold">
        {title}:
      </Box>
      <Box pl={3} color="gray.500">
        <UnorderedList>
          {contents.map((e) => (
            <ListItem key={e}>{e}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    </>
  );
}

export function EstimatesGuidelinesModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);
  const isMobile = useIsMobile();

  return (
    <Modal size={isMobile ? "full" : "4xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box paddingX={4} paddingY={8}>
          <Flex fontWeight="bold" fontSize="2xl" color="gray.700" justifyContent="flex-start" alignItems="center">
            Estimate and Work Approval Guidelines
          </Flex>
          <VStack spacing={5} mt={3}>
            <Box color="gray.500">
              You’ll be notified once we’ve acquired and selected estimates for your repairs. Once notified, please
              review and approve the estimates in a timely manner in order to book vendors at their earliest
              availability.
            </Box>
            <VStack alignItems="flex-start">
              <Paragraph
                title="Approving estimates"
                contents={[
                  "Sending Doorstead photos of completed repairs via the DIY Punchlist",
                  "Informing Doorstead of repair completion dates",
                  "Informing your vendors that all repairs will need to pass quality assurance",
                ]}
              />
              <Paragraph
                title="Adding notes"
                contents={[
                  "Share any additional pertinent details (like the location of spare parts) in the 'Notes' section.",
                ]}
              />
              <Paragraph
                title="Submitting approval"
                contents={[
                  "Once you’ve submitted your approvals, we’ll schedule the vendor to complete the work based on their next availability.",
                ]}
              />
            </VStack>
            <Box color="gray.500">
              While the Initial Evaluation captures most key repairs, we&lsquo;ll consult you if vendors identify extra
              issues that may change the scope of work. Doorstead’s protocol requires a move-in cleaning (ranging
              $200-$450) to sanitize the house before tenant move-in which will be deducted from your first month’s
              rent.
            </Box>
          </VStack>
        </Box>
        <ModalFooter>
          <Flex justifyContent="center" alignItems="center" w="100%">
            <PrimaryButton size="md" onClick={onClose}>
              Back to the list
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function useEstimatesGuidelinesModal() {
  return useContext(context);
}
