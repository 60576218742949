import { gql } from "@apollo/client";
import { useQuery } from "react-query";
import { useAnalytics } from "src/contexts/analytics";
import { ApplicantTypeV2 } from "../../components/applicants/AddCotenantsAndGuarantorsPageV2/NewApplicantsCardV2/typings";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";

export const queryKey = "useFetchApplicationMeApplicants";

export type Result = {
  applicantById: {
    applicationRequestId: string | null;
    doorsteadUser: {
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      doorsteadUserId: string | null;
    };
    applicationRequest: {
      primaryApplicant: {
        applicantId: string;
      };
      applicants: {
        edges: {
          node: {
            applicantId: string;
            type: ApplicantTypeV2 | null;
            createdAt: string;
            doorsteadUser: {
              firstName: string | null;
              lastName: string | null;
              email: string | null;
            };
          };
        }[];
      } | null;
    } | null;
  };
};

const query = gql`
  query ($applicantId: UUID) {
    applicantById(applicantId: $applicantId) {
      applicationRequestId
      doorsteadUser {
        firstName
        lastName
        email
        doorsteadUserId
      }
      applicationRequest {
        primaryApplicant {
          applicantId
          __typename
        }
        applicants {
          edges {
            node {
              applicantId
              type
              createdAt
              doorsteadUser {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

export function useFetchApplicationMeApplicants() {
  const { gqlClient } = useAuth();
  const analytics = useAnalytics();
  const applicantId = useApplicantId();

  return useQuery<unknown, unknown, Result>(queryKey, async () => {
    const result: Result = await gqlClient.query({ query, variables: { applicantId } }).then((e) => e.data);

    const applicant = result?.applicantById;
    const user = applicant?.doorsteadUser;

    analytics?.identify(user.email, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      doorsteadUserId: user?.doorsteadUserId,
      applicantId,
      applicationRequestId: applicant?.applicationRequestId,
    });

    return result;
  });
}
