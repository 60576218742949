import { useMutation } from "react-query";
import { useCreateActionCenterConversionEvent } from "../../../hooks/rr-project/useCreateActionCenterConversionEvent";
import { useCreateRrProjectEvent } from "../../../hooks/rr-project/useCreateRrProjectEvent";
import { OwnerDecision, RepairNode, RepairStage, useFetchRRProject } from "../../../hooks/rr-project/useFetchRRProject";
import { useFetchedRRProject } from "../../../hooks/rr-project/useFetchedRRProject";
import { useIsSurveyFilled } from "../../../hooks/rr-project/useIsSurveyFilled";
import { useRRProjectId } from "../../../hooks/rr-project/useRRProjectId";
import { useRepairNodes } from "../../../hooks/rr-project/useRepairNodes";
import { useRepairs } from "../../../hooks/rr-project/useRepairs";
import { useShareSurveyModal } from "../../../hooks/rr-project/useShareSurveyModal";
import { useSubmitOwnerEstimateApproval } from "../../../hooks/rr-project/useSubmitOwnerEstimateApproval";
import { Repair, useUpdateRepairs } from "../../../hooks/rr-project/useUpdateRepairs";
import { useUpdateVendorRepairs } from "../../../hooks/rr-project/useUpdateVendorRepairs";
import { useToUpdateRepairEstimateApprovedStore } from "../stores/useToUpdateRepairEstimateApprovedStore";
import { useToUpdateRepairOwnerDecisionsStore } from "../stores/useToUpdateRepairOwnerDecisionsStore";

function estimateToApprovedToStage(estimateApproved: boolean | null): RepairNode["stage"] {
  if (estimateApproved === null) {
    return RepairStage.OwnerEstimatesApproved;
  }
  return estimateApproved ? RepairStage.OwnerEstimatesApproved : RepairStage.OwnerEstimatesRejected;
}

function useGetStage() {
  const serverRepairs = useRepairs();
  const { getEstimateApprove, hasEstimateApprove } = useToUpdateRepairEstimateApprovedStore();

  return (repairNode: RepairNode): RepairNode["stage"] => {
    const { repairId } = repairNode;
    const hasToUpdateEstimateApproved = hasEstimateApprove(repairId);
    const toUpdateEstimateApproved = getEstimateApprove(repairId);
    const originalEstimateApproved =
      serverRepairs.find((e) => e.id === repairId)?.chosenVendorRepair?.estimateApproved ?? null;

    return hasToUpdateEstimateApproved
      ? estimateToApprovedToStage(toUpdateEstimateApproved)
      : estimateToApprovedToStage(originalEstimateApproved);
  };
}

function useGetOwnerDecision() {
  const { hasOwnerDecision, getOwnerDecision } = useToUpdateRepairOwnerDecisionsStore();

  return (repairNode: RepairNode): RepairNode["ownerDecision"] => {
    const { repairId } = repairNode;

    return hasOwnerDecision(repairId) ? getOwnerDecision(repairId) : repairNode.ownerDecision || OwnerDecision.Yes;
  };
}

function useUpdateRepairsOwnerDecisionAndStage() {
  const serverRepairs = useRepairNodes();
  const updateRepairs = useUpdateRepairs();
  const getOwnerDecision = useGetOwnerDecision();
  const getStage = useGetStage();

  return async () => {
    const repairs: Repair[] = serverRepairs.map((repair) => {
      const { repairId } = repair;
      const ownerDecision = getOwnerDecision(repair);
      const stage = getStage(repair);

      return {
        repairId,
        ownerDecision,
        stage,
      };
    });
    await updateRepairs({ repairs });
  };
}

function useUpdateRepairsVendors() {
  const { getEstimateApprove, hasEstimateApprove } = useToUpdateRepairEstimateApprovedStore();
  const serverRepairs = useRepairs();
  const updateVendorRepairs = useUpdateVendorRepairs();

  return () => {
    return updateVendorRepairs({
      vendorRepairs: serverRepairs
        .filter(
          ({ chosenVendorRepair, ownerDecision }) =>
            chosenVendorRepair !== null && (ownerDecision === OwnerDecision.Yes || ownerDecision === OwnerDecision.DIY)
        )
        .map(({ id, chosenVendorRepair }) => {
          const hasToUpdateEstimateApproved = hasEstimateApprove(id);
          const toUpdateEstimateApproved = getEstimateApprove(id);
          const originalEstimateApproved = chosenVendorRepair!.estimateApproved ?? true;
          const estimateApproved = hasToUpdateEstimateApproved ? toUpdateEstimateApproved : originalEstimateApproved;

          return {
            vendorRepairId: chosenVendorRepair!.id,
            estimateApproved,
          };
        }),
    });
  };
}

export function useOnSubmit() {
  const { refetch } = useFetchRRProject();
  const serverRepairs = useRepairNodes();
  const { clear: clearOwnerDecision, hasOwnerDecision, getOwnerDecision } = useToUpdateRepairOwnerDecisionsStore();
  const updateRepairsOwnerDecisionAndStage = useUpdateRepairsOwnerDecisionAndStage();
  const { onOpen: openSurveyModal } = useShareSurveyModal();
  const createRrProjectEvent = useCreateRrProjectEvent();
  const updateRepairsVendors = useUpdateRepairsVendors();
  const createActionCenterConversionEvent = useCreateActionCenterConversionEvent();
  const { clear: clearEstimateApprove } = useToUpdateRepairEstimateApprovedStore();
  const rrProject = useFetchedRRProject();
  const rrProjectId = useRRProjectId();
  const redirectedFrom = new URLSearchParams(location.search).get("from") || "";
  const isSurveyFilled = useIsSurveyFilled();
  const submitOwnerEstimateApproval = useSubmitOwnerEstimateApproval();
  const hasDIYRepairs = serverRepairs.some(({ repairId, ownerDecision }) => {
    return (
      ownerDecision === OwnerDecision.DIY ||
      (hasOwnerDecision(repairId) && getOwnerDecision(repairId) === OwnerDecision.DIY)
    );
  });
  const hasRejectRepairs = serverRepairs.some(({ repairId, ownerDecision }) => {
    return (
      ownerDecision === OwnerDecision.No ||
      (hasOwnerDecision(repairId) && getOwnerDecision(repairId) === OwnerDecision.No)
    );
  });

  return useMutation(async () => {
    await updateRepairsOwnerDecisionAndStage();
    await updateRepairsVendors();
    const event = "OWNER_SUBMIT_ESTIMATE_APPROVAL";
    await createRrProjectEvent({
      event,
    });

    await createActionCenterConversionEvent({
      propertyId: rrProject.property?.propertyId || "",
      email: rrProject.property?.owner?.doorsteadUser?.email || "",
      action: event,
      data: JSON.stringify({ rrProjectId, url: window.location.href }),
      surface: "WEB_APP",
      redirectedFrom: redirectedFrom,
    });

    await submitOwnerEstimateApproval();

    if (!isSurveyFilled && (hasDIYRepairs || hasRejectRepairs)) {
      openSurveyModal();
    }

    clearOwnerDecision();
    clearEstimateApprove();
    await refetch();
  });
}
