import { Item } from "../../../../../@types/RentReady/Item";
import { useIsItemCrossIcon } from "../../../../../hooks/rentReady/useIsItemCrossIcon";
import { useRemoveItemClick } from "../../../../../hooks/rentReady/useRemoveItemClick";
import { Bar } from "../Bar";
import { ItemCircle } from "./ItemCircle";

export function NonInlineItemRow(item: Item) {
  const removeItemClick = useRemoveItemClick(item);
  const isShowIcon = useIsItemCrossIcon(item);

  return (
    <Bar title={item.uiName} isShowAction={isShowIcon} onActionClick={removeItemClick}>
      <ItemCircle {...item} />
    </Bar>
  );
}
