import { HStack } from "@chakra-ui/react";
import { Variant } from "@doorstead/components/Chip";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { GoodMissingFixStatus } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { StatusButton } from "./StatusButton";

type Props = {
  value: GoodMissingFixStatus | null;
  onChange: (status: GoodMissingFixStatus) => void;
};

export function GoodMissingFixButtons({ value, onChange }: Props) {
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  return (
    <HStack spacing={2}>
      <StatusButton
        label={"Good"}
        isSelecting={value === GoodMissingFixStatus.Good}
        variant={Variant.Green}
        onSelect={() => {
          if (value !== GoodMissingFixStatus.Good) {
            onChange(GoodMissingFixStatus.Good);
          }
        }}
        isReadOnly={useIsReadOnly()}
      />
      <StatusButton
        label={shouldUseSimplifiedTemplate ? "Not Present" : "Missing"}
        variant={Variant.Orange}
        isSelecting={value === GoodMissingFixStatus.Missing}
        onSelect={() => {
          if (value !== GoodMissingFixStatus.Missing) {
            onChange(GoodMissingFixStatus.Missing);
          }
        }}
        isReadOnly={useIsReadOnly()}
      />
      <StatusButton
        label={shouldUseSimplifiedTemplate ? "Condition" : "Fix"}
        variant={Variant.Red}
        isSelecting={value === GoodMissingFixStatus.Fix}
        onSelect={() => {
          if (value !== GoodMissingFixStatus.Fix) {
            onChange(GoodMissingFixStatus.Fix);
          }
        }}
        isReadOnly={useIsReadOnly()}
      />
    </HStack>
  );
}
