import { Decision, Estimate } from "../../../@types/RentReady/Estimate";
import { getDollarByCents } from "../../../utils/getDollarByCents";
import { mapPhotoUrlsJsonToPhotos } from "../../../utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { EstimateNode } from "../useFetchInspectionReport/EstimateNode";

export enum CannotEstimateReason {
  ToBeDetermined = "To be determined",
  OutOfScope = "Out of scope",
}

function getDecision(estimateNode: EstimateNode): Decision | null {
  if (estimateNode.cannotEstimate === null) return null;

  return estimateNode.cannotEstimate ? Decision.CanNotEstimate : Decision.AddEstimates;
}

function getPrice(estimateNode: EstimateNode, field: "lowEstimateCostCents" | "highEstimateCostCents"): number {
  const decision = getDecision(estimateNode);
  if (decision === Decision.CanNotEstimate) return 0;

  const value = estimateNode[field];
  if (value === null) return 0;

  return getDollarByCents(value) || 0;
}

function getIsRange(estimateNode: EstimateNode): boolean {
  const decision = getDecision(estimateNode);
  const notDecided = decision === null;
  if (notDecided) return false;

  return estimateNode.lowEstimateCostCents !== estimateNode.highEstimateCostCents;
}

function getGroupUiName(
  { estimateThroughoutPropertyType, estimateItem }: EstimateNode,
  estimateNodes: EstimateNode[]
): string {
  const matchedEstimates = estimateNodes.filter(
    (e) => !e.isGrouped && e.estimateThroughoutPropertyType === estimateThroughoutPropertyType
  );

  switch (estimateThroughoutPropertyType) {
    case "Keys": {
      const keyLength = matchedEstimates.filter(({ estimateDataType }) => estimateDataType === "Keys").length;
      return `${estimateItem} - ${keyLength} Keys`;
    }
    default: {
      const areasLength = new Set(matchedEstimates.map(({ estimateSection }) => estimateSection).filter((e) => !!e))
        .size;
      const isAreaPlural = areasLength > 1;
      const itemsLength = matchedEstimates.length;
      const isItemPlural = itemsLength > 1;
      return `${estimateItem} - ${areasLength} Area${isAreaPlural ? "s" : ""}, ${itemsLength} Item${
        isItemPlural ? "s" : ""
      }`;
    }
  }
}

function getUiName(estimateNode: EstimateNode, estimateNodes: EstimateNode[]): string {
  if (estimateNode.isGrouped) {
    return getGroupUiName(estimateNode, estimateNodes);
  } else {
    const names = [];
    if ((estimateNode.estimateItem || "").trim() !== "") {
      names.push(estimateNode.estimateItem);
    }

    if ((estimateNode.estimateSection || "").trim() !== "") {
      names.push(estimateNode.estimateSection);
    }

    return names.join(" - ");
  }
}

export function mapEstimateNodesToEstimates(estimateNodes: EstimateNode[]): Estimate[] {
  return estimateNodes.map((estimateNode) => {
    return {
      id: estimateNode.estimateId,
      item: estimateNode.estimateItem || "",
      section: estimateNode.estimateSection || "",
      uiName: getUiName(estimateNode, estimateNodes),
      description: estimateNode.estimateDescription || "",
      photos: mapPhotoUrlsJsonToPhotos(estimateNode.estimatePhotoUrlsJson),
      isRange: getIsRange(estimateNode),
      fromPrice: getPrice(estimateNode, "lowEstimateCostCents"),
      toPrice: getPrice(estimateNode, "highEstimateCostCents"),
      isOutOfScope: estimateNode.cannotEstimateReason === CannotEstimateReason.OutOfScope,
      isToBeDetermined: estimateNode.cannotEstimateReason === CannotEstimateReason.ToBeDetermined,
      note: estimateNode.estimateNotes || "",
      decision: getDecision(estimateNode),
      isGrouped: estimateNode.isGrouped || false,
      throughoutPropertyType: estimateNode.estimateThroughoutPropertyType || "",
      dataType: estimateNode.estimateDataType || "",
      createDate: estimateNode.estimateCreatedAt === null ? new Date() : new Date(estimateNode.estimateCreatedAt),
    };
  });
}
