import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components/buttons";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { useCreateInspectionReportData } from "../../../../hooks/rentReady/useCreateInspectionReportData";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useInspectionReport } from "../../../../hooks/rentReady/useInspectionReport";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsPropertyDetailsCompleted } from "../../../../hooks/rentReady/useIsPropertyDetailsCompleted";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { InspectionReportPropertyBox } from "../../InspectionReportPropertyBox";

function RightArrow() {
  return <ArrowIcon color="white" size={2} direction={Direction.Right} />;
}

export function PropertyDetailView() {
  const { setView } = useView();
  const { bedrooms, fullBathrooms, halfBathrooms } = useInspectionReport();
  const { mutate, isLoading } = useCreateInspectionReportData();
  const isPropertyDetailsCompleted = useIsPropertyDetailsCompleted();
  const isEditable = useIsEditable();
  const isReadOnly = useIsReadOnly();
  useDisplayInstruction(View.PropertyDetailView);

  return (
    <Flex flexDirection="column" justifyContent="space-between" w="100%" px={5} pb={5}>
      <VStack flex={1} w="100%" spacing={5} mt={5} alignItems="flex-start">
        <Flex fontWeight="400" color="gray.700" justifyContent="space-between" w="100%">
          <Box>Property Details</Box>
          <Box
            as="button"
            onClick={() => {
              setView(View.PropertyDetailEditView);
            }}
          >
            {isEditable ? "Edit" : "Detail"}
          </Box>
        </Flex>
        <Divider />
        <InspectionReportPropertyBox />
      </VStack>
      {!isPropertyDetailsCompleted && (
        <Flex
          as="footer"
          justifyContent="center"
          alignItems="center"
          pos="sticky"
          bgColor="white"
          borderTopRadius="6px"
          bottom={0}
          p={5}
          zIndex={1}
        >
          <PrimaryButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => {
              mutate(
                {
                  numBathrooms: fullBathrooms || 0,
                  numHalfBathrooms: halfBathrooms || 0,
                  numBedrooms: bedrooms || 0,
                },
                {
                  onSuccess() {
                    setView(View.AreaListView);
                  },
                }
              );
            }}
          >
            Continue to evaluation <RightArrow />
          </PrimaryButton>
        </Flex>
      )}
      {isReadOnly && (
        <PrimaryButton
          onClick={() => {
            setView(View.AreaListView);
          }}
        >
          Next <RightArrow />
        </PrimaryButton>
      )}
    </Flex>
  );
}
