import {
  FlexWalkthroughGraphQLResultCache,
  writeFlexWalkthroughGraphQLResult,
} from "../../../databases/FlexWalkthroughDatabase";
import { useFlexWalkthroughReportId } from "../useFlexWalkthroughReportId";
import { mergeFlexWalkthroughItemsCache } from "./mergeFlexWalkthroughItemsCache";
import { mergeFlexWalkthroughReportCache } from "./mergeFlexWalkthroughReportCache";
import { FlexWalkthroughGraphQLResult } from "./useFetchFlexWalkthroughReport";

export function useMergeCacheWithServer() {
  const flexWalkthroughReportId = useFlexWalkthroughReportId();

  return async (
    flexWalkthroughGraphQLResultCache: FlexWalkthroughGraphQLResultCache | null,
    flexWalkthroughGraphQLResultServer: FlexWalkthroughGraphQLResult
  ) => {
    if (flexWalkthroughGraphQLResultCache && flexWalkthroughGraphQLResultCache.flexWalkthroughReport) {
      let newFlexWalkthroughGraphQLResultCache = mergeFlexWalkthroughReportCache(
        flexWalkthroughGraphQLResultCache,
        flexWalkthroughGraphQLResultServer
      );

      newFlexWalkthroughGraphQLResultCache = mergeFlexWalkthroughItemsCache(
        newFlexWalkthroughGraphQLResultCache,
        flexWalkthroughGraphQLResultServer
      );

      await writeFlexWalkthroughGraphQLResult(flexWalkthroughReportId, newFlexWalkthroughGraphQLResultCache);
    }
  };
}
