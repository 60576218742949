import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

type Props = {
  failureEmails: string[];
  setFailureEmails: Dispatch<SetStateAction<string[]>>;
};

const context = createContext<Props>({
  failureEmails: [],
  setFailureEmails: console.log,
});

export function FailureEmailsProvider({ children }: { children: ReactNode }) {
  const [failureEmails, setFailureEmails] = useState<string[]>([]);

  return (
    <context.Provider
      value={{
        failureEmails,
        setFailureEmails,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useFailureEmails() {
  return useContext(context);
}
