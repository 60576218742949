import { Area } from "../../@types/RentReady/Area";
import { useAreaId } from "./useAreaId";
import { useAreasCache } from "./useAreasCache";

export function useArea(): Area | null {
  const areas = useAreasCache();
  const { areaId } = useAreaId();

  if (areas.length === 0) return null;

  return areas.find((area) => area.id === areaId) || null;
}
