import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { useArea } from "../../../../../hooks/rentReady/useArea";
import { useSyncSingleAreaFieldToCacheAndServer } from "../../../../../hooks/rentReady/useSyncSingleAreaFieldToCacheAndServer";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { ArrowOpenIcon } from "../../../../ArrowOpenIcon";
import { AreaAutoSyncPhotosField } from "./AreaAutoSyncPhotosField";
import { AreaAutoSyncTextField } from "./AreaAutoSyncTextField";
import { NeedControlBox } from "./NeedControlBox";

export function AreaPersonalItemRemovalBox() {
  const { cleanPersonalItemRemoval, itemRemovalPhotos, itemRemovalNotes } = useArea()!;
  const syncSingleFieldToCacheAndServer = useSyncSingleAreaFieldToCacheAndServer();
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({ defaultIsOpen: cleanPersonalItemRemoval });

  return (
    <VStack w="100%">
      <Flex w="100%" justifyContent="center" alignItems="center">
        <NeedControlBox
          isLoading={false}
          label="Personal Items Removal"
          value={cleanPersonalItemRemoval}
          onOpen={onOpen}
          onClose={onClose}
          onChange={async (value) => {
            await syncSingleFieldToCacheAndServer("areaSelectedItemRemoval", "selectedItemRemoval", value, value);
          }}
        />
        <ArrowOpenIcon isOpen={isOpen} onClick={onToggle} />
      </Flex>
      {isOpen && (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Box>
            <AreaAutoSyncPhotosField
              cacheField="areaItemRemovalPhotoUrlsJson"
              serverField="itemRemovalPhotoUrlsJson"
              prefix={Prefix.AreasPersonalItemRemovals}
              values={itemRemovalPhotos}
            />
          </Box>
          <Box>
            <AreaAutoSyncTextField
              value={itemRemovalNotes}
              cacheField="areaItemRemovalNotes"
              serverField="itemRemovalNotes"
            />
          </Box>
        </Flex>
      )}
    </VStack>
  );
}
