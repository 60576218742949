import { Box, Flex, VStack } from "@chakra-ui/react";
import { Modal, OutlineButton, PrimaryButton } from "@doorstead/components";
import { useAgreeAutopayDisclaimer } from "../../../hooks/useAgreeAutopayDisclaimer";
import { useHasAgreedAutopayDisclaimer } from "../../../hooks/useHasAgreedAutopayDisclaimer";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  defaultShowIAgreeButton?: boolean;
};

export function AutopayDisclaimerModal({ isOpen, onClose, onOk, defaultShowIAgreeButton = true }: Props) {
  const hasAgreedAutopayDisclaimer = useHasAgreedAutopayDisclaimer();
  const { isLoading, mutate: agreeAutopayDisclaimer } = useAgreeAutopayDisclaimer();
  const showIAgreeButton = defaultShowIAgreeButton && !hasAgreedAutopayDisclaimer;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      <Box paddingX={4} paddingY={8}>
        <VStack align="start" spacing={4}>
          <Box fontSize="2xl">Autopay disclaimer</Box>
          <Box>
            Auto-Debit for Recurring Payments. You hereby authorize that each month to charge your credit card, debit
            card, or bank account on your lease due date for the total balance on your account (including fees). Your
            authorization applies to the credit card on file and any future information provided by your bank, or by you
            through the Tenant Online Portal. You understand that if your payment is rejected, refused, returned,
            disputed, or reversed by your financial institution or card issuer for any reason, then we have the right to
            charge a returned item fee and to assess late fees.
          </Box>
          <Box>
            You have authorized us to treat your electronic signature as evidence of your consent to initiate recurring
            electronic payment transactions from your credit card, debit card, or bank account on a monthly basis. This
            authorization via electronic signature has the same effect as a handwritten signature on a paper-formatted
            contract. Your consent only applies to the initiation of the recurring electronic credit card, debit card,
            and bank account debits.
          </Box>
          <Box>
            You may withdraw your consent to the monthly recurring payment transactions by contacting us as the signer,
            you represent and warrant that you are authorized to provide the instructions and authorizations above on
            the Tenants behalf and to bound the Tenant to this document. Doorstead may rely on your authority without
            further inquiry. Doorstead may rely on this document until it has received written notification of its
            revocation from the Tenant and Doorstead has a reasonable opportunity to act on such revocation.
          </Box>
        </VStack>
        <Flex justifyContent={showIAgreeButton ? "space-between" : "center"} mt={4}>
          {showIAgreeButton && (
            <PrimaryButton
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                agreeAutopayDisclaimer(
                  { achDisclaimerAt: new Date() },
                  {
                    onSuccess: onOk,
                  }
                );
              }}
            >
              I agree
            </PrimaryButton>
          )}
          {!isLoading && <OutlineButton onClick={onClose}>Close</OutlineButton>}
        </Flex>
      </Box>
    </Modal>
  );
}
