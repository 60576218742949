import { Box, VStack } from "@chakra-ui/react";
import { GrayText } from "@doorstead/components";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useAddress } from "../../../../../hooks/useAddress";
import { useGoogleMapKey } from "../../../../../hooks/useGoogleMapKey";
import { useSegmentedAddress } from "../../../../../hooks/useSegmentedAddress";
import { Card } from "../../Card";
import { MoneyRentIconBox } from "../../MoneyRentIconBox";
import { PaymentDueDateIconBox } from "../../PaymentDueDateIconBox";
import { OwnerBox } from "./OwnerBox";

export function Mobile() {
  const address = useAddress();
  const segmentedAddress = useSegmentedAddress();
  const googleMapKey = useGoogleMapKey();

  return (
    <Card>
      <GoogleMapImage borderRadius={20} width={322} height={220} address={address} googleKey={googleMapKey} />
      <GrayText mt={5}>Lease address</GrayText>
      <Box color="gray.700" fontSize="2xl" mt={3}>
        <Box>{segmentedAddress[0]}</Box>
        <Box>{segmentedAddress[1]}</Box>
      </Box>
      <VStack gap={3} alignItems="left" mt={5}>
        <MoneyRentIconBox />
        <PaymentDueDateIconBox />
        <OwnerBox />
      </VStack>
    </Card>
  );
}
