import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";

type Variables = {
  propertyId: string;
  email: string;
  action: string;
  data: string;
  surface: string;
  redirectedFrom: string;
};

export function useCreateActionCenterConversionEvent() {
  const { gqlClient } = useAuth();

  return ({ propertyId, email, action, data, surface, redirectedFrom }: Variables) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($event: ActionCenterEvent!) {
          createActionCenterConversionEvent(actionCenterEvent: $event) {
            success
          }
        }
      `,
      variables: {
        event: {
          propertyId,
          email,
          action,
          data,
          surface,
          redirectedFrom,
        },
      },
    });
  };
}
