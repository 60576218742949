import { gql } from "@apollo/client";
import { useAuth } from "../contexts/auth";
import { getFromInLocationSearch } from "../utils/getFromInLocationSearch";

type Args = {
  email: string;
  propertyId: string;
  action: string;
  data: any;
  surface: string;
};

export function useLogClickEvent() {
  const { gqlClient } = useAuth();

  return ({ email, propertyId, action, data, surface }: Args) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($event: EventObject) {
          logClickEvent(event: $event) {
            success
          }
        }
      `,
      variables: {
        event: {
          email,
          propertyId,
          action,
          data: JSON.stringify(data),
          surface,
          redirectedFrom: getFromInLocationSearch(),
          userAgent: window.navigator.userAgent,
        },
      },
    });
  };
}
