import { InspectionReportNode } from "./useFetchInspectionReport/InspectionReportNode";
import { useRRGraphQLResultCache } from "./useRRGraphQLResultCache";

let prevData: InspectionReportNode | null = null;
export function useInspectionReportCache(): InspectionReportNode | null {
  const data = useRRGraphQLResultCache();

  if (data === undefined) {
    return prevData;
  } else {
    prevData = data?.inspectionReport || null;
    return prevData;
  }
}
