import { ThroughoutPropertyCleaning } from "../../@types/RentReady/ThroughoutPropertyCleaning";
import { InspectionReportNode } from "../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";

export function mapInspectionReportNodeToThroughoutPropertyCleaning(
  inspectionReportNode: InspectionReportNode
): ThroughoutPropertyCleaning {
  return {
    cleanIsNeeded: inspectionReportNode.cleanIsNeeded,
    cleanSelectedOptions: inspectionReportNode.cleanSelectedOptions || [],
    cleanIsOdorRemoval: inspectionReportNode.cleanIsOdorRemoval || false,
    cleanNotes: inspectionReportNode.cleanNotes || "",
  };
}
