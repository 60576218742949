import { Box } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { Item } from "../../../../../@types/RentReady/Item";
import { useOnInCompleteItemClick } from "../../../../../hooks/rentReady/useOnInCompleteItemClick";

type Props = {
  item: Item;
};

export function ItemInCompleteBoxV2({ item }: Props) {
  const onInCompleteItemClick = useOnInCompleteItemClick(item);

  return (
    <Box onClick={onInCompleteItemClick}>
      <Chip label={item.cosmeticDefect} variant={Variant.Gray} />
    </Box>
  );
}
