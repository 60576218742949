import produce from "immer";

type Item = {
  name: string;
  isCore: boolean;
};

export function attachIsCore<T extends Item>(items: T[]): T[] {
  return produce(items, (draft) => {
    const coreMap: Map<string, number> = new Map();

    draft.forEach((item) => {
      if (item.isCore) {
        coreMap.set(item.name, (coreMap.get(item.name) || 0) + 1);
        item.isCore = coreMap.get(item.name) === 1;
      }
    });
  });
}
