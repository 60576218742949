import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useRRProjectId } from "./useRRProjectId";

type Variables = {
  surveyOptions: string[];
  surveyNotes: string;
  surveyOtherNotes: string;
};

export function useCreateSurvey() {
  const rrProjectId = useRRProjectId();
  const { gqlClient } = useAuth();

  return useMutation(({ surveyOptions, surveyNotes, surveyOtherNotes }: Variables) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($surveyNotes: String, $surveyOptions: [String], $surveyOtherNotes: String, $rrProjectId: UUID!) {
          createRrProjectSurvey(
            surveyNotes: $surveyNotes
            surveyOptions: $surveyOptions
            surveyOtherNotes: $surveyOtherNotes
            rrProjectId: $rrProjectId
          ) {
            success
          }
        }
      `,
      variables: {
        surveyOptions,
        surveyOtherNotes,
        surveyNotes,
        rrProjectId,
      },
    });
  });
}
