import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { AutoPay } from "../@types/AutoPay/typings";
import { useAuth } from "../contexts/auth";
import { useFetchMe } from "./useFetchMe";
import { useTenantGroupId } from "./useTenantGroupId";

const mutation = gql`
  mutation ($amount: Float!, $date: AutopayDate!, $paymentMethodId: UUID!, $tenantGroupId: UUID!) {
    createAutopay(amount: $amount, date: $date, paymentMethodId: $paymentMethodId, tenantGroupId: $tenantGroupId) {
      autopayId
      amount
      date
    }
  }
`;

export function useCreateAutoPay() {
  const { refetch, isLoading: isMeLoading } = useFetchMe();
  const tenantGroupId = useTenantGroupId();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: createAutoPay } = useMutation(async ({ amount, date, paymentMethodId }: AutoPay) => {
    await gqlClient.mutate({ mutation, variables: { amount, date, paymentMethodId, tenantGroupId } });
    await refetch();
  });

  return {
    isLoading: isLoading || isMeLoading,
    createAutoPay,
  };
}
