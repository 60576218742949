import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { RRProjectNodeStatus } from "./useFetchRRProject";
import { useRRProjectId } from "./useRRProjectId";

export function useUpdateRRProjectStatus() {
  const { gqlClient } = useAuth();
  const rrProjectId = useRRProjectId();

  return useMutation((status: RRProjectNodeStatus) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($rrProjectId: UUID, $status: String) {
          updateRrProjectStatus(rrProjectId: $rrProjectId, status: $status) {
            success
          }
        }
      `,
      variables: {
        rrProjectId,
        status,
      },
    });
  });
}
