import { Box, Flex } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components";
import React from "react";
import { SetupAutopayBox } from "src/components/SetupAutopayBox";
import { PAY_ACCOUNT_BALANCE_AMOUNT } from "src/components/SetupAutopayBox/SetupAutopayBox";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { getValue } from "../../../@types/AutoPay/getValue";
import { AutoPayDate } from "../../../@types/AutoPay/typings";
import { useCreateAutoPay } from "../../../hooks/useCreateAutoPay";
import { useRemoveAutoPay } from "../../../hooks/useRemoveAutoPay";
import { useUpdateAutoPay } from "../../../hooks/useUpdateAutoPay";
import { AddValue, EditValue } from "./typings";

type AddProps = {
  isEdit: false;
  initValue: AddValue;
};

type EditProps = {
  isEdit: true;
  initValue: EditValue;
};

export enum Action {
  Update = "update",
  Create = "create",
  Remove = "remove",
}

type Props = (AddProps | EditProps) & {
  onDone: (action: Action) => void;
  onCancel?: () => void;
  hasRemoveButton?: boolean;
  onDisableClick?: () => void;
};

export function UpsertAutoPayCard({
  isEdit,
  initValue,
  onDone,
  onCancel,
  hasRemoveButton = true,
  onDisableClick,
}: Props) {
  const [paymentMethodId, setPaymentMethodId] = React.useState(initValue.paymentMethodId);
  const [payOn, setPayOn] = React.useState<AutoPayDate | "">(initValue.payOn);
  const { isLoading: isUpdatingAutoPay, updateAutoPay } = useUpdateAutoPay();
  const { isLoading: isRemovingAutoPay, removeAutoPay } = useRemoveAutoPay();
  const { createAutoPay, isLoading: isCreatingAutoPay } = useCreateAutoPay();
  const isEditValueUpdated = isEdit && (paymentMethodId !== initValue.paymentMethodId || payOn !== initValue.payOn);
  const analytics = useGroupAnalytics();
  const submitDisabled = payOn === "" || paymentMethodId === "";

  return (
    <Flex flexDirection="column" mt={5} gap={5} alignItems="flex-start">
      <SetupAutopayBox
        paymentMethodId={paymentMethodId}
        setPaymentMethodId={setPaymentMethodId}
        payOn={payOn}
        setPayOn={setPayOn}
      />
      {onDisableClick && (
        <Box _hover={{ textDecoration: "underline" }} fontSize="sm" role="button" onClick={onDisableClick}>
          Disable autopay
        </Box>
      )}
      <Flex w="100%" justifyContent="space-between">
        {isEdit ? (
          <>
            <PrimaryButton
              disabled={submitDisabled || isUpdatingAutoPay || !isEditValueUpdated}
              isLoading={isUpdatingAutoPay || isCreatingAutoPay}
              onClick={async () => {
                await updateAutoPay(
                  {
                    autopayId: (initValue as EditValue).id,
                    amount: PAY_ACCOUNT_BALANCE_AMOUNT,
                    date: getValue(payOn as AutoPayDate),
                    paymentMethodId,
                  },
                  {
                    onSuccess: () => {
                      analytics?.track("TP_AUTOPAY_SAVED", {
                        autopayId: (initValue as EditValue).id,
                        paymentMethodId,
                      });
                      onDone(Action.Update);
                    },
                  }
                );
              }}
            >
              Save changes
            </PrimaryButton>
            {onCancel && <OutlineButton onClick={onCancel}>Cancel</OutlineButton>}
            {hasRemoveButton && (
              <OutlineButton
                disabled={isRemovingAutoPay}
                isLoading={isRemovingAutoPay}
                onClick={async () => {
                  if (window.confirm("Are you sure to remove this autopay?")) {
                    await removeAutoPay((initValue as EditValue).id, {
                      onSuccess() {
                        analytics?.track("TP_AUTOPAY_REMOVED", {
                          autopayId: (initValue as EditValue).id,
                          paymentMethodId,
                        });
                        onDone(Action.Remove);
                      },
                    });
                  }
                }}
              >
                Remove
              </OutlineButton>
            )}
          </>
        ) : (
          <PrimaryButton
            disabled={submitDisabled || isCreatingAutoPay}
            isLoading={isCreatingAutoPay}
            onClick={async () => {
              analytics?.track("TP_AUTOPAY_ADD_CLICKED");
              await createAutoPay(
                {
                  amount: PAY_ACCOUNT_BALANCE_AMOUNT,
                  paymentMethodId,
                  date: getValue(payOn as AutoPayDate),
                },
                {
                  onSuccess() {
                    analytics?.track("TP_AUTOPAY_ADDED");
                    onDone(Action.Create);
                  },
                }
              );
            }}
          >
            Save settings
          </PrimaryButton>
        )}
      </Flex>
    </Flex>
  );
}
