import { FlexProps } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";
import { MoneyHouseIcon } from "@doorstead/components/icons";
import { useMonthlyRent } from "../../../hooks/useMonthlyRent";
import { IconBox } from "./IconBox";

type Props = FlexProps;

export function MoneyRentIconBox({ ...flexProps }: Props) {
  const monthlyRent = useMonthlyRent();

  return (
    <IconBox
      {...flexProps}
      icon={<MoneyHouseIcon size={20} color="gray.700" />}
      title={moneyString(monthlyRent)}
      subTitle="Monthly rent"
    />
  );
}
