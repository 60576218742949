import { Item } from "../../@types/RentReady/Item";
import { isItemCompleteCosmeticOptions } from "./isItemCompleteCosmeticOptions";
import { isItemFullPainted } from "./isItemFullPainted";

export function isItemComplete(item: Item, isAreaPainting: boolean, simplifiedR2V2Template: boolean): boolean {
  if (simplifiedR2V2Template) {
    return true;
  }

  const hasPhotos = item.photos.length > 0;
  const fullPainted = isItemFullPainted(item, isAreaPainting);

  return fullPainted || (hasPhotos && isItemCompleteCosmeticOptions(item));
}
