import { getUserName } from "../utils/getUserName";
import { useFetchedLease } from "./useFetchedLease";

type Name = string | null;

type DoorsteadUserNode = {
  firstName: Name;
  lastName: Name;
};

export function useTenants(): string[] {
  const doorsteadUsers: DoorsteadUserNode[] = useFetchedLease().tenantGroup.tenantGroupMembers.edges.map(
    (e) => e.node.tenant.doorsteadUser
  );
  return doorsteadUsers.map(({ firstName, lastName }) => getUserName({ firstName, lastName }));
}
