import produce from "immer";

export function sortByField<T>(items: T[], name: keyof T): T[] {
  return produce(items, (draft) => {
    (draft as T[]).sort((a, b) => {
      if (a[name] < b[name]) {
        return -1;
      }
      if (a[name] > b[name]) {
        return 1;
      }
      return 0;
    });
  });
}
