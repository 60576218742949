import { Estimate } from "../../@types/RentReady/Estimate";
import { useEstimateId } from "./useEstimateId";
import { useEstimates } from "./useEstimates";

export function useEstimate(): Estimate {
  const { estimateId } = useEstimateId();
  const estimates = useEstimates();

  return estimates.find((e) => e.id === estimateId)!;
}
