import { Item } from "../../@types/RentReady/Item";
import { isItemCabinets } from "./isItemCabinets";
import { isItemCosmeticDefectPaint } from "./isItemCosmeticDefectPaint";

export function isItemFullPainted(item: Item, isAreaPainting: boolean) {
  const isCosmeticDefectPaint = isItemCosmeticDefectPaint(item);
  const isCabinets = isItemCabinets(item);

  return isAreaPainting && isCosmeticDefectPaint && !isCabinets;
}
