import { Box, Flex, HStack } from "@chakra-ui/react";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";
import { useIsUploading } from "../../../hooks/rentReady/useIsUploading";
import { View, useView } from "../../../hooks/rentReady/useView";
import { useIsAppMutating } from "../../../hooks/useIsAppMutating";
import { InstructionIcon } from "../InstructionIcon";

type Props = {
  name: string;
  backView: View;
  onBack?: () => void;
};

export function Header({ name, backView, onBack }: Props) {
  const { view, setView } = useView();
  const isAppMutating = useIsAppMutating();
  const { isUploading } = useIsUploading();
  const disabled = isAppMutating || isUploading;

  return (
    <HStack as="header" w="100%" minH={12} position="sticky" zIndex={1} top={0} bgColor="white">
      <Box
        as="button"
        w="72px"
        cursor={disabled ? "not-allowed" : "pointer"}
        onClick={() => {
          if (!disabled) {
            if (onBack) {
              onBack();
            }
            setView(backView);
          }
        }}
      >
        <ArrowIcon color={disabled ? "gray.400" : "black"} size={2} direction={Direction.Left} />
      </Box>

      <Flex alignItems="center" justifyContent="center" flex={1} fontSize="xl" fontWeight="bold">
        <HStack>
          <Box>{name}</Box>
          <InstructionIcon view={view} />
        </HStack>
      </Flex>
      <Box w="72px" />
    </HStack>
  );
}
