import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useError } from "../useError";
import { useSuccess } from "../useSuccess";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

const mutation = gql`
  mutation ($applicantId: UUID!, $inquiryId: String!) {
    updateApplicantFromPersona(applicantId: $applicantId, inquiryId: $inquiryId) {
      applicantId
    }
  }
`;

export function useUpdateApplicantFromPersona() {
  const { refetch } = useFetchApplicationMe();
  const success = useSuccess();
  const error = useError();
  const { gqlClient } = useAuth();
  const applicantId = useApplicantId();

  return useMutation(
    ({ inquiryId }: { inquiryId: string }) =>
      gqlClient.mutate({
        mutation,
        variables: {
          applicantId,
          inquiryId,
        },
      }),
    {
      async onSuccess() {
        await refetch();
        success({ title: "Uploaded photo ID successfully" });
      },
      async onError() {
        error({ title: "Failed to upload photo ID", description: "" });
      },
    }
  );
}
