import { Box, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { Modal } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import React, { useContext, useState } from "react";
import { NoteIcon } from "../../components/rr-project/NoteIcon";

type ContextProps = {
  isOpen: boolean;
  isOpenedBefore: boolean;
  setOpenedBefore: (value: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  isOpenedBefore: false,
  setOpenedBefore() {
    console.log("setOpenedBefore");
  },
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function RejectEstimateModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenedBefore, setOpenedBefore] = useState(false);

  return (
    <context.Provider
      value={{
        isOpen,
        isOpenedBefore,
        setOpenedBefore,
        onClose,
        onOpen() {
          setOpenedBefore(true);
          onOpen();
        },
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VStack>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <NoteIcon />
        </Flex>
        <Box fontWeight="bold">Please note</Box>
        <VStack color="gray.500">
          <Box>
            We typically acquire quotes from 2-3 of our trusted vendors and choose the best option that balances price
            and availability. By selecting “Reject”, you acknowledge that Doorstead must continue the search for lower
            cost options, which will delay the process to get your property rent ready.
          </Box>
          <PrimaryButton size="md" onClick={onClose}>
            Acknowledge
          </PrimaryButton>
        </VStack>
      </VStack>
    </Modal>
  );
}

export function useRejectEstimateModal() {
  return useContext(context);
}
