import produce from "immer";
import { KeyStorage } from "../../@types/RentReady/KeyStorage";
import { RRGraphQLResultCache } from "../../databases/RentReadyDatabase";
import { mapPhotosToPhotoUrlsJson } from "./mapPhotosToPhotoUrlsJson";

export function mapKeyStorageFromRRGraphQLResultCache(
  result: RRGraphQLResultCache,
  keyStorage: KeyStorage
): RRGraphQLResultCache {
  return produce(result, (draft) => {
    draft.inspectionReport.additionalKeyStorageNotes = keyStorage.additionalKeyStorage.note || "";
    draft.inspectionReport.additionalKeyStoragePhotoUrlsJson = mapPhotosToPhotoUrlsJson(
      keyStorage.additionalKeyStorage.photos
    );

    draft.inspectionReport.lockboxCode = keyStorage.lockbox.code;
    draft.inspectionReport.lockboxCodePhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyStorage.lockbox.photos);
    draft.inspectionReport.lockboxLocation = keyStorage.lockboxLocation.location;
    draft.inspectionReport.lockboxLocationPhotoUrlsJson = mapPhotosToPhotoUrlsJson(keyStorage.lockboxLocation.photos);
  });
}
