import { Box, Divider, Stack, StackProps, Text, VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton, useIsMobile } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { useEffect, useMemo } from "react";
import { isNullOrEmptyString } from "src/utils/isNullOrEmptyString";
import { isValidEmail } from "src/utils/isValidEmail";
import { useError } from "../../../../hooks/useError";
import { CardTitle } from "../../CardTitle";
import { emailErrorClassName } from "../NewApplicantsCardV2/Content";
import { ApplicantTypeV2, NewApplicantWithIdV2 } from "../NewApplicantsCardV2/typings";
import { getDuplicatedEmails } from "../getDuplicatedEmails";
import { getIsValid } from "../getIsValid";
import { getToAddApplicants } from "../getToAddApplicants";
import { useDirty } from "../useDirty";
import { useFailureEmails } from "../useFailureEmails";
import { NewMemberInfo, useInviteCoTenantsAndGuarantorsV2 } from "../useInviteCoTenantsAndGuarantorsV2";

type Props = {
  coTenants: NewApplicantWithIdV2[];
  guarantors: NewApplicantWithIdV2[];
  borderRadius?: number;
  onBackClick: () => void;
  onSuccess: () => void;
  isShowSubmitButton: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => boolean;
} & StackProps;

const filterApplicants = (applicants: NewApplicantWithIdV2[]) => {
  return applicants.filter(
    ({ firstName, lastName, email }) =>
      !isNullOrEmptyString(firstName) &&
      !isNullOrEmptyString(lastName) &&
      !isNullOrEmptyString(email) &&
      isValidEmail(email)
  );
};

function getSubmitButtonLabel({
  coTenants,
  guarantors,
}: {
  coTenants: NewApplicantWithIdV2[];
  guarantors: NewApplicantWithIdV2[];
}) {
  const toAddCoTenants = getToAddApplicants(coTenants);
  const toAddGuarantors = getToAddApplicants(guarantors);
  if (toAddCoTenants.length >= 1 || toAddGuarantors.length >= 1) {
    return "Invite & Continue";
  }
  return "Continue";
}

export function InviteCardV2({
  coTenants,
  guarantors,
  onBackClick,
  onSuccess,
  isShowSubmitButton,
  ...stackProps
}: Props) {
  const error = useError();
  const { isDirty, setIsDirty } = useDirty();
  const isMobile = useIsMobile();
  const { failureEmails, setFailureEmails } = useFailureEmails();
  const isValid = getIsValid({ coTenants, guarantors, failureEmails });
  const { mutate: inviteCoTenantsAndGuarantors, isLoading } = useInviteCoTenantsAndGuarantorsV2();
  const filteredCoTenants = useMemo(() => getToAddApplicants(filterApplicants(coTenants)), [coTenants]);
  const filteredGuarantors = useMemo(() => getToAddApplicants(filterApplicants(guarantors)), [guarantors]);
  const submitDisabled = isLoading || !isValid;
  const submitButtonLabel = getSubmitButtonLabel({ coTenants, guarantors });
  const duplicatedEmails = getDuplicatedEmails({ coTenants, guarantors });
  useEffect(() => {
    if (isDirty) {
      window.document.querySelector(`.${emailErrorClassName}`)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [failureEmails, duplicatedEmails]);

  return (
    <Card {...stackProps} padding={isMobile ? 4 : 7} width="100%">
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Send invite</CardTitle>
        <Text mt={3} color="gray.500" fontFamily="inter" fontWeight="500" fontSize="16px">
          Please check that the below information is accurate. We will send each individual an email with a link to
          their own application to fill out.
        </Text>
        {filteredCoTenants.length > 0 && (
          <>
            <Divider pt={6} />
            <Text mt={2} fontSize="xl" color="gray.800" fontWeight="semibold">
              Co-tenants
            </Text>
            {filteredCoTenants.map(({ id, firstName, lastName, email }) => (
              <Box key={id}>
                <Text mt={2} color="gray.800" fontWeight="medium" fontSize="md">
                  {firstName} {lastName}
                </Text>
                <Text color="gray.500" fontWeight="normal" fontSize="sm">
                  {email}
                </Text>
              </Box>
            ))}
          </>
        )}
        {filteredGuarantors.length > 0 && (
          <>
            <Divider pt={6} />
            <Text mt={2} fontSize="xl" color="gray.800" fontWeight="semibold">
              Guarantors
            </Text>
            {filteredGuarantors.map(({ id, firstName, lastName, email }) => (
              <Box key={id}>
                <Text mt={2} color="gray.800" fontWeight="medium" fontSize="md">
                  {firstName} {lastName}
                </Text>
                <Text color="gray.500" fontWeight="normal" fontSize="sm">
                  {email}
                </Text>
              </Box>
            ))}
          </>
        )}
        {isShowSubmitButton({ coTenants, guarantors }) && (
          <Stack pt={8} direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
            <PrimaryButton
              isLoading={isLoading}
              isFullWidth={isMobile}
              disabled={submitDisabled}
              onClick={() => {
                if (!isValid) {
                  setIsDirty(true);
                } else {
                  const toAddTenants = getToAddApplicants(coTenants);
                  const toAddGuarantors = getToAddApplicants(guarantors);
                  const newApplicants = toAddTenants.concat(toAddGuarantors).map((e) => {
                    const type = (e.type === ApplicantTypeV2.TENANT ? "TENANT" : "GUARANTOR") as NewMemberInfo["type"];
                    return {
                      type,
                      firstName: e.firstName || "",
                      lastName: e.lastName || "",
                      email: e.email || "",
                    };
                  });
                  inviteCoTenantsAndGuarantors(newApplicants, {
                    async onSuccess(data) {
                      const failureEmails = data.data?.inviteCoTenantsAndGuarantors?.failureEmails || [];
                      if (failureEmails.length === 0) {
                        setFailureEmails([]);
                        onSuccess();
                      } else {
                        setFailureEmails(failureEmails);
                        setIsDirty(true);
                      }
                    },
                    onError() {
                      error({ title: "Failed to invite co-applicants", description: "" });
                    },
                  });
                }
              }}
            >
              {submitButtonLabel}
            </PrimaryButton>
            <OutlineButton disabled={isLoading} isFullWidth={isMobile} onClick={onBackClick}>
              Skip
            </OutlineButton>
          </Stack>
        )}
      </VStack>
    </Card>
  );
}
