import produce from "immer";
import { arrayToMap } from "../../../utils/arrayToMap";
import { getMergedPhotos } from "../../../utils/rentReady/getMergedPhotos";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { ComponentNode } from "./ComponentNode";
import { ItemNode } from "./ItemNode";

export function mergeComponentsCache(cacheItem: ItemNode, serverItem: ItemNode): ComponentNode[] {
  const cacheComponents = cacheItem.componentsData || [];
  const serverComponents = serverItem.componentsData || [];
  const cacheComponentMap = arrayToMap(cacheComponents, "componentDataId");
  const serverComponentMap = arrayToMap(serverComponents, "componentDataId");

  const toRemoveIds: Set<string> = new Set();
  let newCacheComponents = produce(cacheComponents, (draft) => {
    draft.forEach((cacheComponent) => {
      if (serverComponentMap.has(cacheComponent.componentDataId)) {
        const serverComponent = serverComponentMap.get(cacheComponent.componentDataId)!;

        if (
          shouldInvalidateCache({
            cacheTime: cacheComponent.componentDataUpdatedAt,
            serverTime: serverComponent.componentDataUpdatedAt,
          })
        ) {
          //merge the component
          cacheComponent.componentSelectedComponentOptions = serverComponent.componentSelectedComponentOptions;
          cacheComponent.componentSelectedRepair = serverComponent.componentSelectedRepair;
          cacheComponent.componentSelectedReplace = serverComponent.componentSelectedReplace;
          cacheComponent.componentSelectedRepairOptions = serverComponent.componentSelectedRepairOptions;
          cacheComponent.componentSelectedReplaceOptions = serverComponent.componentSelectedReplaceOptions;
          cacheComponent.componentPhotoUrlsJson = getMergedPhotos(
            cacheComponent.componentPhotoUrlsJson,
            serverComponent.componentPhotoUrlsJson
          );
          cacheComponent.componentNotes = serverComponent.componentNotes;
          cacheComponent.componentDataUpdatedAt = serverComponent.componentDataUpdatedAt;
        }

        //merge the structure
        if (
          shouldInvalidateCache({
            cacheTime: cacheComponent.componentUpdatedAt,
            serverTime: serverComponent.componentUpdatedAt,
          })
        ) {
          cacheComponent.componentShouldRender = serverComponent.componentShouldRender;
          cacheComponent.componentUpdatedAt = serverComponent.componentUpdatedAt;
          cacheComponent.componentName = serverComponent.componentName;
          cacheComponent.componentOptions = serverComponent.componentOptions;
          cacheComponent.componentOptionSelectionType = serverComponent.componentOptionSelectionType;
          cacheComponent.componentOptionShowRepairOptions = serverComponent.componentOptionShowRepairOptions;
          cacheComponent.componentOptionShowReplaceOptions = serverComponent.componentOptionShowReplaceOptions;
          cacheComponent.componentHasRepairOption = serverComponent.componentHasRepairOption;
          cacheComponent.componentHasReplaceOption = serverComponent.componentHasReplaceOption;
          cacheComponent.componentRepairOptions = serverComponent.componentRepairOptions;
          cacheComponent.componentReplaceOptions = serverComponent.componentReplaceOptions;
        }
      } else {
        toRemoveIds.add(cacheComponent.componentDataId);
      }
    });
  });

  if (
    shouldInvalidateCache({
      cacheTime: cacheItem.itemDataComponentsUpdatedAt,
      serverTime: serverItem.itemDataComponentsUpdatedAt,
    })
  ) {
    newCacheComponents = newCacheComponents.filter((e) => !toRemoveIds.has(e.componentDataId));

    for (const serverComponent of Array.from(serverComponentMap.values())) {
      if (!cacheComponentMap.has(serverComponent.componentDataId)) {
        newCacheComponents.push(serverComponent);
      }
    }
  }

  return newCacheComponents;
}
