import { PaymentMethodType } from "src/hooks/useFetchMe";

export function getPaymentMethodLabel({
  type,
  bankAccountName,
  bankAccountMask,
  cardBrand,
  cardLastFourDigits,
}: {
  type: PaymentMethodType;
  bankAccountName: string | null;
  bankAccountMask: string | null;
  cardBrand: string | null;
  cardLastFourDigits: string | null;
}): string {
  if (type === PaymentMethodType.Card) {
    return `${cardBrand} **** **** **** ${cardLastFourDigits}`;
  }
  if (type === PaymentMethodType.ACH) {
    return `${bankAccountName} **${bankAccountMask}`;
  }
  return "";
}
