import { Box } from "@chakra-ui/react";
import { TextArea } from "@doorstead/components";

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  isValid?: boolean;
  disabled: boolean;
};

export function TextAreaField({ label, value, onChange, placeholder = "", isValid = true, disabled }: Props) {
  return (
    <>
      <Box>{label}</Box>
      <Box w="100%">
        <TextArea isValid={isValid} disabled={disabled} value={value} onChange={onChange} placeholder={placeholder} />
      </Box>
    </>
  );
}
