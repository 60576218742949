import { AutoPayDate } from "../../../../../@types/AutoPay/typings";
import { useAnalytics } from "../../../../../contexts/analytics";
import { usePaymentMethods } from "../../../../../hooks/usePaymentMethods";
import { UpsertAutoPayCard } from "../../../../evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { EverGreenModal } from "../../../../evergreens/EverGreenModal/EverGreenModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function AddAutopayModal({ isOpen, onClose }: Props) {
  const analytics = useAnalytics();
  const paymentMethods = usePaymentMethods();
  const firstPaymentMethod = paymentMethods.length === 0 ? null : paymentMethods[0];

  return (
    <EverGreenModal
      title="Add autopay"
      isOpen={isOpen}
      onClose={() => {
        analytics?.track("TP_AUTOPAY_DISMISSED");
        onClose();
      }}
    >
      <UpsertAutoPayCard
        isEdit={false}
        initValue={{
          paymentMethodId: firstPaymentMethod?.paymentMethodId || "",
          payOn: AutoPayDate.TWENTY_EIGHTH,
          nextScheduleDate: null,
        }}
        onDone={onClose}
      />
    </EverGreenModal>
  );
}
