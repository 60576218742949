import { Item } from "../../../../@types/RentReady/Item";
import { isComponentDone } from "../../../../utils/rentReady/isComponentDone";
import { isItemFullPainted } from "../../../../utils/rentReady/isItemFullPainted";

export function hasItemGroupData(item: Item, isAreaFullPaint: boolean): boolean {
  const { components, photos } = item;
  const isSomeComponentDone = components.map(isComponentDone).some((e) => e);
  const itemFullPainted = isItemFullPainted(item, isAreaFullPaint);
  return photos.length > 0 || itemFullPainted || isSomeComponentDone;
}
