import Dexie, { Table } from "dexie";
import { RRGraphQLResult } from "../hooks/rentReady/useFetchInspectionReport/useFetchInspectionReport";

export type RRGraphQLResultCache = RRGraphQLResult & {
  inspection_report_id: string;
};

export type RRItemOpen = {
  item_data_id: string;
  is_open: boolean;
};

export class RentReadyDatabase extends Dexie {
  rr_graphql_result!: Table<RRGraphQLResultCache, string>;
  rr_item_open!: Table<RRItemOpen, string>;

  public constructor() {
    super("InspectionReport");
    this.version(18).stores({
      rr_graphql_result: "++inspection_report_id",
      rr_item_open: "++item_data_id",
    });
  }
}

export const db = new RentReadyDatabase();

export async function readRRGraphQLResultCache(inspectionReportId: string): Promise<RRGraphQLResultCache | null> {
  return (await db.rr_graphql_result.where("inspection_report_id").equals(inspectionReportId).first()) || null;
}

export async function writeRRGraphQLResult(inspectionReportId: string, rrGraphQLResultCache: RRGraphQLResultCache) {
  await db.rr_graphql_result.put(rrGraphQLResultCache);
}

export async function writeRRItemOpen(item_data_id: string, is_open: boolean) {
  await db.rr_item_open.put({ item_data_id, is_open });
}
