import { usePageView } from "src/hooks/usePageView";
import withAuth from "src/utils/withAuth";
import { TransactionHistoryTable } from "../components/TransactionHistoryTable";
import { useTransactionHistories } from "../hooks/useTransactionHistories";
import { TenantPortalProvider } from "../providers/TenantPortalProvider";

function Content() {
  const rows = useTransactionHistories();
  usePageView("TP_TXN_HISTORY_VIEWED");

  return <TransactionHistoryTable fontSize="4xl" rows={rows} />;
}

export default withAuth(() => {
  return (
    <TenantPortalProvider>
      <Content />
    </TenantPortalProvider>
  );
});
