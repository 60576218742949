import { Flex, StackProps } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublishableKey } from "src/config";
import { useCreateCardPaymentMethodFromStripe } from "src/hooks/useCreateCardPaymentMethodFromStripe";
import { useRefetchPaymentMethods } from "src/hooks/useRefetchPaymentMethods";
import { useFetchedMe } from "../../hooks/useFetchedMe";
import { SetupForm } from "./SetupForm";

export type Props = StackProps & {
  onDone: () => void;
};

export function SetupCardBox({ onDone, ...stackProps }: Props) {
  const me = useFetchedMe();
  const stripePromise = loadStripe(stripePublishableKey());
  const { refetchPaymentMethods } = useRefetchPaymentMethods();
  const { createCardPaymentMethodFromStripe } = useCreateCardPaymentMethodFromStripe();

  return (
    <Flex flexDirection="column" gap={6} alignItems="flex-start" {...stackProps}>
      <Elements
        options={{
          mode: "setup",
          currency: "usd",
          paymentMethodTypes: ["card"],
          appearance: {
            theme: "stripe",
            labels: "floating",
          },
        }}
        stripe={stripePromise}
      >
        <SetupForm
          onDone={async (setupIntentId: string) => {
            await createCardPaymentMethodFromStripe(me.doorsteadUserId, setupIntentId);
            await refetchPaymentMethods();
            onDone();
          }}
        />
      </Elements>
    </Flex>
  );
}
