import { Action } from "../components/evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { useError } from "./useError";
import { useSuccess } from "./useSuccess";

function getSuccessDescription(action: Action): string {
  switch (action) {
    case Action.Create:
      return "Created successfully";
    case Action.Update:
      return "Updated successfully";
    case Action.Remove:
      return "Removed successfully";
    default:
      return "Succeeded";
  }
}

function getFailDescription(action: Action): string {
  switch (action) {
    case Action.Create:
      return "Failed to create";
    case Action.Update:
      return "Failed to update";
    case Action.Remove:
      return "Failed to remove";
    default:
      return "Failed";
  }
}

export function useAutopayMessage() {
  const success = useSuccess();
  const error = useError();

  return (action: Action, fail = false) => {
    if (fail) {
      error({
        title: "Autopay",
        description: getFailDescription(action),
      });
    } else {
      success({
        title: "Autopay",
        description: getSuccessDescription(action),
      });
    }
  };
}
