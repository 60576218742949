import { HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";

type Props = {
  disabled?: boolean;
  isPresent: boolean | null;
  onChange: (isPresent: boolean | null) => void;
};

export function OptionPresentButtons({ isPresent: optionIsPresent, onChange, disabled = false }: Props) {
  const MissingButton = optionIsPresent !== false ? OutlineButton : PrimaryButton;
  const PresentButton = optionIsPresent !== true ? OutlineButton : PrimaryButton;

  return (
    <HStack spacing={2}>
      <MissingButton
        size="sm"
        isFullWidth
        disabled={disabled}
        onClick={() => {
          onChange(false);
        }}
      >
        Missing
      </MissingButton>
      <PresentButton
        size="sm"
        isFullWidth
        disabled={disabled}
        onClick={() => {
          onChange(true);
        }}
      >
        Present
      </PresentButton>
    </HStack>
  );
}
