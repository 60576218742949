import { Props as DateFieldProps } from "@doorstead/components/fields/DateField";

export function getYearMonthAndDateFromDateString(value: string | null): DateFieldProps["value"] {
  if (value === null) {
    return null;
  }
  const arr = value.split("-");
  if (arr.length !== 3) {
    return null;
  }
  return {
    year: parseInt(arr[0], 10),
    month: parseInt(arr[1], 10),
    date: parseInt(arr[2], 10),
  };
}
