import { useFetchedApplicant } from "./useFetchedApplicant";

export function useFetchedApplicationMonthlyRent(): number {
  const {
    applicationRequest: {
      negotiatedRent,
      listing: { price: listingPrice },
    },
  } = useFetchedApplicant();
  const monthlyRent = negotiatedRent || listingPrice || 0;

  return monthlyRent;
}
