import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { ContractType } from "./useFetchApplicationMe/ApplicationRequest";

export function useIsZtpo() {
  const { isLoading, data } = useFetchApplicationMe();
  if (isLoading) return false;

  return (
    data?.applicantById?.applicationRequest?.listing?.renewal?.contract?.type === ContractType.TPO &&
    data?.applicantById?.applicationRequest?.listing?.renewal?.contract?.contractRequest?.multistepFlowType ===
      "tpo_zillow"
  );
}
