import { Box, Flex } from "@chakra-ui/react";
import { useArea } from "../../../../hooks/rentReady/useArea";
import { useAutoFocusHashPosition } from "../../../../hooks/rentReady/useAutoFocusHashPosition";
import { useDisplayInstruction } from "../../../../hooks/rentReady/useDisplayInstruction";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { FullScreen } from "../../FullScreen";
import { TitleRow } from "../../TitleRow";
import { AreaRow } from "./AreaRow";
import { ItemRows } from "./ItemRows";

const backView = View.AreaListView;

function useIsShowUpsertItemButton(): boolean {
  const { isThroughoutProperty, name } = useArea()!;
  const isEditable = useIsEditable();
  const isOnline = useIsOnline();

  if (isThroughoutProperty && name === "Utilities, Heating & Cooling") {
    return true;
  }
  return !isThroughoutProperty && isOnline && isEditable;
}

function Content() {
  const isV2 = useIsV2UiFeatures();
  const { uiName, items } = useArea()!;
  const { setView } = useView();
  const isShowUpsertItemButton = useIsShowUpsertItemButton();
  useAutoFocusHashPosition("area_one");
  useDisplayInstruction(View.AreaOneView);

  return (
    <FullScreen headerName={uiName} backView={backView}>
      <AreaRow />

      <Flex flexDirection="column" w="100%">
        <TitleRow
          text="Items"
          right={
            isShowUpsertItemButton && (
              <Box
                as="button"
                fontWeight="bold"
                onClick={() => {
                  setView(View.ItemListEditView);
                }}
              >
                {isV2 ? "+ Add items" : "Edit item list"}
              </Box>
            )
          }
        />
        <ItemRows items={items} />
      </Flex>
    </FullScreen>
  );
}

export function AreaOneView() {
  const area = useArea();
  if (area === null) return null;

  return <Content />;
}
