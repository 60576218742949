import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsEvaluationCompleted } from "../../../../hooks/rentReady/useIsEvaluationCompleted";
import { useIsInspectionReportSubmitted } from "../../../../hooks/rentReady/useIsInspectionReportSubmitted";
import { useIsShouldShowEstimatesStep } from "../../../../hooks/rentReady/useIsShouldShowEstimatesStep";
import { useSubmitAreaList } from "../../../../hooks/rentReady/useSubmitAreaList";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useIsOffline } from "../../../../hooks/useIsOffline";
import { AreaListSubmitEstimateButton } from "./ContinueToEstimateButton";
import { useIsAreaListSubmitDisabled } from "./useIsAreaListSubmitDisabled";

export function AreaListSubmitButton() {
  const { setView } = useView();
  const error = useError();
  const isEvaluationCompleted = useIsEvaluationCompleted();
  const isInspectionReportSubmitted = useIsInspectionReportSubmitted();
  const isShouldShowEstimatesStep = useIsShouldShowEstimatesStep();
  const disabled = useIsAreaListSubmitDisabled();
  const isOffline = useIsOffline();
  const { mutate: submitAreaList, isLoading } = useSubmitAreaList();

  if (isOffline) return null;

  if (isEvaluationCompleted) {
    if (isShouldShowEstimatesStep) {
      return (
        <PrimaryButton
          isFullWidth
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => {
            if (isInspectionReportSubmitted) {
              setView(View.EstimateListView);
            } else {
              submitAreaList({} as any, {
                onSuccess() {
                  setView(View.EstimateListView);
                },
                onError() {
                  error({ title: "Failed to submit", description: "" });
                },
              });
            }
          }}
        >
          Next
        </PrimaryButton>
      );
    } else {
      return null;
    }
  } else {
    return <AreaListSubmitEstimateButton />;
  }
}
