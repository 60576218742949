import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { useUseApplicationFlowV2 } from "./useUseApplicationFlowV2";

export function useIsCoTenantsInviteV2Feature(): boolean {
  const useApplicationFlowV2 = useUseApplicationFlowV2();
  const { isLoading, data } = useFetchApplicationMe();
  if (isLoading) return false;

  return !!data?.applicantById.applicationRequest.property.isCoTenantsInviteV2Feature || useApplicationFlowV2;
}
