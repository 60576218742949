import { Box, Checkbox, Collapse, Divider, Flex, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

type Row = {
  id: string;
  title: string;
  component: React.FC;
};

type Props = {
  title: string;
  rows: Row[];
  defaultChecked?: boolean;
  checkboxAction?: (isChecked: boolean) => void;
};

export function EditRow({ title, rows, defaultChecked = false, checkboxAction }: Props) {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const handleCheckboxOnClick = () => {
    setIsChecked(!isChecked);
    if (checkboxAction) {
      checkboxAction(!isChecked);
    }
  };

  return (
    <VStack
      bgColor="gray.50"
      color="gray.800"
      lineHeight="24px"
      padding={5}
      w="100%"
      alignItems="flex-start"
      spacing={5}
      borderRadius="6px"
    >
      <Flex
        w="100%"
        direction="row"
        cursor="pointer"
        onClick={(e) => {
          e.preventDefault();
          handleCheckboxOnClick();
        }}
      >
        <Checkbox isChecked={isChecked} />
        <Box pl="2">{title}</Box>
      </Flex>
      <Box as={Collapse} in={isChecked} w="100%">
        <VStack spacing={5} w="100%">
          <Divider />
          {rows.map(({ id, title, component }) => (
            <Flex key={id} w="100%" justifyContent="space-between" alignItems="center">
              <Box>{title}</Box>
              <Box>{component({})}</Box>
            </Flex>
          ))}
        </VStack>
      </Box>
    </VStack>
  );
}
