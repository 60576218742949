import { VStack } from "@chakra-ui/react";
import { LeftRightBox, moneyString } from "@doorstead/components";
import { MoneyText } from "../MoneyText";

export type Props = {
  paid: number;
  subTotalDue: number;
};

export function SubtotalSection({ subTotalDue, paid }: Props) {
  return (
    <VStack spacing={3} w="100%">
      {paid !== 0 && (
        <LeftRightBox left="Paid" right={<MoneyText color="green.500">{moneyString(paid, true)}</MoneyText>} />
      )}
      {subTotalDue !== 0 && (
        <LeftRightBox left="Subtotal due" right={<MoneyText>{moneyString(subTotalDue)}</MoneyText>} />
      )}
    </VStack>
  );
}
