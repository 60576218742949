import { EmptyLayout } from "../components/EmptyLayout/EmptyLayout";
import { Error } from "../components/Error";

function MaintenancePage() {
  return (
    <EmptyLayout logoLink="/">
      <Error title="Scheduled Maintenance Underway" subTitle="We'll Be Back Shortly. Thanks for Your Patience!" />
    </EmptyLayout>
  );
}

export default MaintenancePage;
