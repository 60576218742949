import { WalletIcon } from "@doorstead/components/icons";
import { get, isEmpty } from "lodash";
import { PaymentMethodEdgeNode, PaymentMethodType } from "src/hooks/useFetchMe";
import { useAutoPay } from "../../../../../hooks/useAutoPay";
import { usePaymentMethods } from "../../../../../hooks/usePaymentMethods";
import { IconBox } from "./IconBox";

export function PaymentAccountIconBox() {
  const autoPay = useAutoPay();
  const paymentMethods = usePaymentMethods();
  const firstPaymentMethod = paymentMethods.length === 0 ? null : paymentMethods[0];

  const renderPaymentAccount = (paymentMethod: PaymentMethodEdgeNode) => {
    if (isEmpty(paymentMethod)) {
      return "";
    }
    const paymentType: string = get(paymentMethod, "type_") || PaymentMethodType.ACH;
    if (paymentType === PaymentMethodType.Card) {
      return `${paymentMethod.cardBrand} *${paymentMethod.cardLastFourDigits}`;
    }
    return `${paymentMethod.bankAccountName} *${paymentMethod.bankAccountMask}`;
  };

  if (autoPay === null) {
    if (firstPaymentMethod !== null) {
      return (
        <IconBox title="Payment account" subTitle={renderPaymentAccount(firstPaymentMethod)}>
          <WalletIcon color="gray.900" />
        </IconBox>
      );
    } else {
      return null;
    }
  }
  return (
    <IconBox title="Payment account" subTitle={renderPaymentAccount(autoPay?.paymentMethod)}>
      <WalletIcon color="gray.900" />
    </IconBox>
  );
}
