import React from "react";
import { useNavigate } from "react-router-dom";
import { EmptyScreen } from "../components/EmptyScreen";
import { useAuth } from "../contexts/auth";
import withAuth from "../utils/withAuth";

function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  React.useEffect(() => {
    logout().then(() => {
      navigate("/login");
    });
  }, []);

  return <EmptyScreen>Log out</EmptyScreen>;
}

export default withAuth(LogoutPage);
