import { useEffect, useRef } from "react";
import { useLogImpressionEvent } from "./useLogImpressionEvent";

type Args = {
  data: Record<string, any>;
  email: string;
  propertyId: string;
  action: string;
  surface: string;
};

export function useTrackImpressionEvent({ data, email, propertyId, action, surface }: Args) {
  const ref = useRef(false);
  const logImpressionEvent = useLogImpressionEvent();

  useEffect(() => {
    if (!ref.current) {
      logImpressionEvent({
        email,
        propertyId,
        data,
        action,
        surface,
        userAgent: window.navigator.userAgent,
      });
      ref.current = true;
    }
  }, [data, logImpressionEvent]);
}
