import { Flex, HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Photo } from "../../../../@types/RentReady/Photo";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { RequiredLabel } from "../../RequiredLabel";
import { PhotosField } from "../../fields/PhotosField";
import { QuestionIcon } from "./QuestionIcon";

type Props = {
  label: string;
  hasValue: boolean;
  onQuestionClick?: () => void;
  photos: Photo[];
  onPhotosChange: (photos: Photo[]) => void;
  prefix: Prefix;
  right: ReactNode;
};

export function BoxLayout({ hasValue, label, prefix, photos, onPhotosChange, right, onQuestionClick }: Props) {
  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack spacing={1}>
        <RequiredLabel hasValue={hasValue}>{label}</RequiredLabel>
        {onQuestionClick && <QuestionIcon onClick={onQuestionClick} />}
      </HStack>
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <PhotosField prefix={prefix} values={photos} label="" onChange={onPhotosChange} />
        <VStack alignItems="flex-start" w={150}>
          {right}
        </VStack>
      </Flex>
    </VStack>
  );
}
