import withAuth from "src/utils/withAuth";
import { EverGreenPaymentMethods } from "../components/evergreens/EverGreenPaymentMethods";
import { TenantPortalProvider } from "../providers/TenantPortalProvider";

export default withAuth(() => {
  return (
    <TenantPortalProvider>
      <EverGreenPaymentMethods />
    </TenantPortalProvider>
  );
});
