import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { TitleRow } from "src/components/rent-ready/TitleRow";
import { useFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useFlexWalkthroughReport";
import { useIsReadOnly } from "src/hooks/flexWalkthrough/useIsReadOnly";
import { useSyncServerToLocalDb } from "src/hooks/flexWalkthrough/useSyncServerToLocalDb";
import { useUpdateFlexWalkthroughReport } from "src/hooks/flexWalkthrough/useUpdateFlexWalkthroughReport";
import { Prefix } from "src/hooks/rentReady/useUpload";
import { mapPhotoUrlsJsonToPhotos } from "src/utils/rentReady/mapPhotoUrlsJsonToPhotos";
import { ActionButtons } from "./ActionButtons";
import { FlexSafetySection } from "./FlexSafetySection";
import { FlexSection } from "./FlexSection";
import { FlexWalkthroughSubmitButton } from "./FlexWalkthroughSubmitButton";
import { InstructionIcon } from "./InstructionIcon";
import { ReportAutoSyncPhotosField } from "./ReportAutoSyncPhotosField";
import { ReportAutoSyncTextField } from "./ReportAutoSyncTextField";

export function FlexWalkthroughView() {
  const { items, propertyIsPhotoReady, propertyOverviewPhotoUrlsJson, propertyOverviewNotes } =
    useFlexWalkthroughReport();
  const { mutate: updateFlexWalkthroughReport } = useUpdateFlexWalkthroughReport();
  const serverToLocalDb = useSyncServerToLocalDb();
  const isReadOnly = useIsReadOnly();

  const propertyOverviewPhotos = mapPhotoUrlsJsonToPhotos(propertyOverviewPhotoUrlsJson) || [];

  return (
    <Flex flexDirection="column" w="100%">
      <VStack w="100%" p={4}>
        <Flex w="100%" justifyContent="space-between">
          <Flex flex={1} fontSize="md">
            <HStack>
              <Box>Property is photo-ready </Box>
              <InstructionIcon
                text={
                  "If property is in decent condition, continue to next steps. If property is in very poor condition and cannot be professionally photographed, select ‘no’."
                }
              />
            </HStack>
          </Flex>
          <HStack>
            <ActionButtons
              response={propertyIsPhotoReady ? "Yes" : propertyIsPhotoReady === false ? "No" : ""}
              positiveActionText={"Yes"}
              negativeActionText={"No"}
              onChange={async (toResponse) => {
                await updateFlexWalkthroughReport(
                  {
                    propertyIsPhotoReady: toResponse === "Yes" ? true : false,
                  },
                  {
                    onSuccess: serverToLocalDb,
                  }
                );
              }}
            />
          </HStack>
        </Flex>
      </VStack>
      {propertyIsPhotoReady ? (
        <>
          <FlexSection section="General" items={items || []} />
          <FlexSection section="Utilities" items={items || []} />
          <FlexSafetySection />
          <FlexSection section="Habitability" items={items || []} />
        </>
      ) : (
        <Box flex={1}>
          <TitleRow text={"Overview"} />
          <VStack w="100%" spacing={5}>
            <Flex flexDirection="column" w="100%">
              <VStack spacing={1}>
                <VStack w="100%" p={4}>
                  <Flex w="100%" justifyContent="space-between">
                    <Box>
                      Since the property is not in decent condition, please do NOT take professional photos. Instead,
                      take a couple overview photos of each room and exterior, and describe the condition in your notes
                    </Box>
                  </Flex>
                  <Flex display={"flex"} justifyContent="space-between" alignItems="center" w="100%" p={2}>
                    <Box>
                      <ReportAutoSyncPhotosField
                        prefix={`${Prefix.FlexWalkthroughReport}propertyOverviewPhotoUrlsJson` as Prefix}
                        values={propertyOverviewPhotos}
                        isValid={propertyOverviewPhotos.length > 0}
                        cacheField="propertyOverviewPhotoUrlsJson"
                        serverField="propertyOverviewPhotoUrlsJson"
                      />
                    </Box>
                    <Box>
                      <ReportAutoSyncTextField
                        isValid={!!propertyOverviewNotes && propertyOverviewNotes?.trim() !== ""}
                        defaultValue={propertyOverviewNotes || ""}
                        cacheField="propertyOverviewNotes"
                        serverField="propertyOverviewNotes"
                      />
                    </Box>
                  </Flex>
                </VStack>
              </VStack>
            </Flex>
          </VStack>
        </Box>
      )}
      <HStack p={5} spacing={4} as="footer" pos="sticky" bottom={0} bgColor="white">
        {!isReadOnly && <FlexWalkthroughSubmitButton />}
      </HStack>
    </Flex>
  );
}
