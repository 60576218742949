export function isValidRoutingNumber(routingNumber: string): boolean {
  if (routingNumber.length !== 9 || !/^\d+$/.test(routingNumber)) {
    return false;
  }

  const weights = [3, 7, 1]; // Weights for the checksum calculation
  let checksum = 0;

  for (let i = 0; i < routingNumber.length; i++) {
    checksum += parseInt(routingNumber[i]) * weights[i % 3];
  }

  return checksum % 10 === 0;
}
