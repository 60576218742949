import { gql } from "@apollo/client";
import { Komponent } from "../../@types/RentReady/Komponent";
import { useAuth } from "../../contexts/auth";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";

type Args = {
  componentDataId: string;
  notes: string;
  photoUrlsJson: string | null;
  selectedComponentOptions: string[];
  selectedRepair: boolean;
  selectedRepairOptions: string[];
  selectedReplace: boolean;
  selectedReplaceOptions: string[];
  updatedDate: string | null;
};

type Result = {
  data: {
    updateInspectionReportComponent: {
      success: boolean;
      merged: boolean;
      data: {
        componentDataId: string;
        selectedComponentOptions: string[] | null;
        selectedRepair: boolean | null;
        selectedReplace: boolean | null;
        selectedRepairOptions: string[] | null;
        selectedReplaceOptions: string[] | null;
        photoUrlsJson: string | null;
        notes: string | null;
        componentDataUpdatedAt: string | null;
      };
    };
  };
};

export function mapComponentToArgs(component: Komponent): Args {
  const isRepair = component.repairType.selected;
  const isReplace = !isRepair;
  const types = isRepair ? component.repairType.types : component.replaceType.types;
  const selectedRepairOptions = isRepair ? types.filter((e) => e.selected).map((e) => e.id) : [];
  const selectedReplaceOptions = isReplace ? types.filter((e) => e.selected).map((e) => e.id) : [];
  const selectedComponentOptions = component.options.filter((e) => e.selected).map((e) => e.id);
  const photoUrlsJson = isRepair
    ? mapPhotosToPhotoUrlsJson(component.repairType.photos)
    : mapPhotosToPhotoUrlsJson(component.repairType.photos);
  const notes = isRepair ? component.repairType.note : component.replaceType.note;

  return {
    componentDataId: component.id,
    notes,
    photoUrlsJson,
    selectedComponentOptions,
    selectedRepair: isRepair,
    selectedRepairOptions,
    selectedReplace: isReplace,
    selectedReplaceOptions,
    updatedDate: component.updatedDate,
  };
}

export function useUpdateComponentToServer() {
  const { gqlClient } = useAuth();

  return async ({
    componentDataId,
    selectedComponentOptions,
    selectedRepair,
    selectedRepairOptions,
    selectedReplace,
    selectedReplaceOptions,
    notes,
    photoUrlsJson,
    updatedDate,
  }: Args) => {
    return (await gqlClient.mutate({
      mutation: gql`
        mutation (
          $componentDataId: UUID!
          $notes: String
          $photoUrlsJson: String
          $selectedComponentOptions: [String]
          $selectedRepair: Boolean
          $selectedRepairOptions: [String]
          $selectedReplace: Boolean
          $selectedReplaceOptions: [String]
          $componentDataUpdatedAt: DateTime
        ) {
          updateInspectionReportComponent(
            componentDataId: $componentDataId
            selectedComponentOptions: $selectedComponentOptions
            selectedRepair: $selectedRepair
            selectedRepairOptions: $selectedRepairOptions
            selectedReplace: $selectedReplace
            selectedReplaceOptions: $selectedReplaceOptions
            notes: $notes
            photoUrlsJson: $photoUrlsJson
            componentDataUpdatedAt: $componentDataUpdatedAt
          ) {
            success
            merged
            data {
              componentDataId
              selectedComponentOptions
              selectedRepair
              selectedReplace
              selectedRepairOptions
              selectedReplaceOptions
              photoUrlsJson
              notes
              componentDataUpdatedAt
            }
          }
        }
      `,
      variables: {
        componentDataId,
        selectedComponentOptions,
        selectedRepair,
        selectedRepairOptions,
        selectedReplace,
        selectedReplaceOptions,
        notes,
        photoUrlsJson,
        componentDataUpdatedAt: updatedDate,
      },
    })) as Result;
  };
}
