import { gql } from "@apollo/client";
import { Area } from "../../@types/RentReady/Area";
import { useAuth } from "../../contexts/auth";
import { useInspectionReportId } from "./useInspectionReportId";

type Args = {
  areas: Area[];
};

export function useAddAreas() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return ({ areas }: Args) => {
    return gqlClient.mutate({
      mutation: gql`
        mutation ($areaOptionIds: [UUID]!, $inspectionReportId: UUID!) {
          addInspectionReportAreas(areaOptionIds: $areaOptionIds, inspectionReportId: $inspectionReportId) {
            count
          }
        }
      `,
      variables: {
        areaOptionIds: areas.map((e) => e.id),
        inspectionReportId,
      },
    });
  };
}
