export function useScrollIntoView() {
  return (id: string, behavior: ScrollBehavior = "smooth") => {
    const element = document.getElementById(id);
    const header = document.querySelector("header");

    if (element) {
      if (header) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - header.offsetHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior,
        });
      } else {
        element.scrollIntoView({ behavior });
      }
    }
  };
}
