import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { MeResult, queryKey } from "../../hooks/useFetchMe";
import { useFetchedTenant } from "../../hooks/useFetchedTenant";
import { useAuth } from "../auth";
import { Step } from "./typings";

const UPDATE_TENANT = gql`
  mutation ($tenant: TenantUpdateData) {
    updateTenant(tenant: $tenant) {
      count
    }
  }
`;

export function useSetStep() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();
  const tenant = useFetchedTenant();

  const mutation = useMutation(
    (onboardingStage: Step) =>
      gqlClient.mutate({
        mutation: UPDATE_TENANT,
        variables: {
          tenant: {
            tenantId: tenant.tenantId,
            onboardingStage,
          },
        },
      }),
    {
      async onSuccess(_, onboardingStage: Step) {
        queryClient.setQueryData(
          queryKey,
          produce(queryClient.getQueryData(queryKey), (draft: MeResult) => {
            draft.me.tenant.onboardingStage = onboardingStage;
          })
        );
      },
    }
  );

  return {
    isLoading: mutation.isLoading,
    setStep: mutation.mutate,
  };
}
