import { gql } from "@apollo/client";
import React from "react";
import { useAuth } from "../contexts/auth";

const CREATE_PLAID_LINK_TOKEN = gql`
  mutation ($doorsteadUserId: UUID!) {
    createPlaidLinkToken(doorsteadUserId: $doorsteadUserId) {
      linkToken
      requestId
      expiration
    }
  }
`;

type CreatePlaidLinkTokenType = {
  linkToken: string;
  requestId: string;
  expiration: string;
};

type ReturnType = {
  createPlaidToken: (doorsteadUserId: string) => Promise<CreatePlaidLinkTokenType>;
  isOpening: boolean;
};

export function useCreatePlaidLinkToken(): ReturnType {
  const [isOpening, setIsOpening] = React.useState(false);

  const { gqlClient } = useAuth();

  const createPlaidToken = async (doorsteadUserId: string): Promise<CreatePlaidLinkTokenType> => {
    setIsOpening(true);
    const res = await gqlClient.mutate({
      mutation: CREATE_PLAID_LINK_TOKEN,
      variables: {
        doorsteadUserId,
      },
    });
    setIsOpening(false);
    return res.data?.createPlaidLinkToken;
  };

  return {
    createPlaidToken,
    isOpening,
  };
}
