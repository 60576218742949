import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Photo } from "../../../../@types/RentReady/Photo";
import { useOnSelectFiles } from "../../../../hooks/rentReady/useOnSelectFiles";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { isPhotosDiff } from "../../../../utils/rentReady/isPhotosDiff";
import { RequiredLabel } from "../../RequiredLabel";
import { PhotoEditorDialog } from "../../views/PhotoEditorDialog";
import { Mode } from "../../views/PhotoEditorDialog/Mode";
import { Photos } from "./Photos";

type Props = {
  label?: string;
  prefix: Prefix;
  values: Photo[];
  onChange: (photos: Photo[]) => void;
  isValid?: boolean;
};

export function PhotosField({ label = "Photos", prefix, values: propsValues, onChange, isValid = true }: Props) {
  const [mode, setMode] = useState<Mode>(Mode.THUMBNAILS);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [values, setValues] = useState(propsValues);
  const onSelectFiles = useOnSelectFiles();
  // Update parent when values change
  useEffect(() => {
    if (isPhotosDiff(propsValues, values)) {
      onChange(values);
    }
  }, [values]);

  // Update values when props change
  useEffect(() => {
    if (isPhotosDiff(propsValues, values)) {
      setValues(propsValues);
    }
  }, [JSON.stringify(propsValues)]);

  return (
    <>
      <PhotoEditorDialog
        mode={mode}
        setMode={setMode}
        prefix={prefix}
        photos={values}
        setPhotos={setValues}
        isOpen={isOpen}
        onClose={onClose}
      />
      {label !== "" && (
        <RequiredLabel hasValue={values.length > 0} w="100%" textAlign="left" pr={2}>
          {label}
        </RequiredLabel>
      )}
      <Photos
        isValid={isValid}
        prefix={prefix}
        values={values}
        onThumbnailClick={() => {
          setMode(Mode.THUMBNAILS);
          onOpen();
        }}
        onSelectFiles={async (files) => await onSelectFiles({ files, setValues })}
      />
    </>
  );
}
