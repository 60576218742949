import { Item } from "../../../../../@types/RentReady/Item";
import { Komponent } from "../../../../../@types/RentReady/Komponent";
import { RepairOrReplace } from "../../../../../hooks/rentReady/useComponentId";
import { ComponentRepairReplaceBox } from "./ComponentRepairReplaceBox";

type Props = {
  item: Item;
  component: Komponent;
};

export function ComponentRepairBox({ item, component }: Props) {
  const { replaceType, repairType } = component;

  return (
    <ComponentRepairReplaceBox
      item={item}
      component={component}
      type={repairType}
      otherType={replaceType}
      repairOrReplace={RepairOrReplace.Repair}
    />
  );
}
