import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { Action } from "../components/evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { useAuth } from "../contexts/auth";
import { useAutopayMessage } from "./useAutopayMessage";
import { useFetchMe } from "./useFetchMe";
import { useRefetchAutoPay } from "./useRefetchAutoPay";

const mutation = gql`
  mutation ($autopayId: UUID!) {
    deleteAutopay(autopayId: $autopayId) {
      result
    }
  }
`;

export function useRemoveAutoPay() {
  const autopayMessage = useAutopayMessage();
  const { refetch, isLoading: isMeLoading } = useFetchMe();
  const { isLoading: isLoadingAutoPay, refetchAutoPay } = useRefetchAutoPay();
  const { gqlClient } = useAuth();
  const { isLoading, mutate: removeAutoPay } = useMutation(
    async (autopayId: string) => {
      await gqlClient.mutate({ mutation, variables: { autopayId } });
      await refetchAutoPay();
      await refetch();
    },
    {
      onSuccess() {
        autopayMessage(Action.Remove);
      },
    }
  );

  return {
    isLoading: isLoading || isLoadingAutoPay || isMeLoading,
    removeAutoPay,
  };
}
