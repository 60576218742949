import { Flex } from "@chakra-ui/react";
import { MainLayout } from "@doorstead/components";
import React from "react";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useAuthUserName } from "../../hooks/useAuthUserName";
import { useFooter } from "../../hooks/useFooter";
import { logoutLink } from "../../hooks/useHeader";

type Props = {
  children: React.ReactNode;
};

export function EmptyScreen({ children }: Props) {
  const avatarName = useAuthUserName();
  const analytics = useGroupAnalytics();
  const header = {
    logoLink: "/",
    links: [
      {
        ...logoutLink,
        onClick: () => {
          analytics?.track("TP_MENU_LOGOUT_CLICKED");
        },
      },
    ],
    avatarName,
  };
  const footer = useFooter();

  return (
    <MainLayout header={header} footer={footer}>
      <Flex justifyContent="center" alignItems="center" w="100%" minH="calc(100vh - 118px - 110px)">
        {children}
      </Flex>
    </MainLayout>
  );
}
