import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
};

const context = createContext<Props>({
  isUploading: false,
  setIsUploading(isUploading) {
    console.log(isUploading);
  },
});

export function Provider({ children }: { children: ReactNode }) {
  const [isUploading, setIsUploading] = useState(false);

  return (
    <context.Provider
      value={{
        isUploading,
        setIsUploading,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useIsUploading() {
  return useContext(context);
}
