import create from "zustand";
import { ChosenVendorRepair, Repair } from "../../../@types/RepairList/Repair";

interface State {
  estimateApproveds: Record<string, ChosenVendorRepair["estimateApproved"]>;
  getEstimateApprove: (repairId: Repair["id"]) => ChosenVendorRepair["estimateApproved"];
  hasEstimateApprove: (repairId: Repair["id"]) => boolean;
  onChangeEstimateApprove: (
    repairId: Repair["id"],
    toUpdateValue: ChosenVendorRepair["estimateApproved"],
    serverValue: ChosenVendorRepair["estimateApproved"]
  ) => void;
  clear: () => void;
  isEmpty: () => boolean;
}

export const useToUpdateRepairEstimateApprovedStore = create<State>((set, getState) => ({
  estimateApproveds: {},
  getEstimateApprove: (repairId) => {
    return getState().estimateApproveds[repairId];
  },
  hasEstimateApprove: (repairId) => {
    return repairId in getState().estimateApproveds;
  },
  onChangeEstimateApprove: (repairId, toUpdateValue, serverValue) => {
    //if the current estimate approval in the store is the same as the server estimate approval
    if (toUpdateValue === serverValue) {
      set((state) => {
        const updatedApprovals = { ...state.estimateApproveds };
        delete updatedApprovals[repairId];
        return { estimateApproveds: updatedApprovals };
      });
    } else {
      set((state) => ({
        estimateApproveds: {
          ...state.estimateApproveds,
          [repairId]: toUpdateValue,
        },
      }));
    }
  },
  clear: () => set(() => ({ estimateApproveds: {} })),
  isEmpty: () => {
    return Object.keys(getState().estimateApproveds).length === 0;
  },
}));
