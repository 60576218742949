import { Select } from "@doorstead/components";
import { useDIYModal } from "../../hooks/rr-project/useDIYModal";
import { OwnerDecision, getOwnerDecisionLabel } from "../../hooks/rr-project/useFetchRRProject";
import { useRejectEstimateModal } from "../../hooks/rr-project/useRejectEstimateModal";
import { useRepairs } from "../../hooks/rr-project/useRepairs";
import { useToUpdateRepairOwnerDecisionsStore } from "./stores/useToUpdateRepairOwnerDecisionsStore";

type Props = {
  repairId: string;
  ownerDecision: OwnerDecision | null;
};

export function RepairOwnerDecisionField({ repairId, ownerDecision: defaultOwnerDecision }: Props) {
  const repairs = useRepairs();
  const { onOpen: onOpenDIYModal, isOpenedBefore: isDIYOpened } = useDIYModal();
  const { onOpen: onOpenRejectModal, isOpenedBefore: isRejectOpened } = useRejectEstimateModal();
  const { getOwnerDecision, hasOwnerDecision, onChangeOwnerDecision } = useToUpdateRepairOwnerDecisionsStore();
  const ownerDecision = getOwnerDecision(repairId);

  const value = hasOwnerDecision(repairId) ? ownerDecision : defaultOwnerDecision;

  return (
    <Select
      id={`decision_${repairId}`}
      label=""
      size="md"
      value={value || OwnerDecision.Yes}
      options={[
        { name: getOwnerDecisionLabel(OwnerDecision.Yes), value: OwnerDecision.Yes },
        { name: getOwnerDecisionLabel(OwnerDecision.No), value: OwnerDecision.No },
        { name: OwnerDecision.DIY, value: OwnerDecision.DIY },
      ]}
      onChange={(e) => {
        const toChangeOwnerDecision = e.target.value as OwnerDecision;
        if (!isDIYOpened && toChangeOwnerDecision === OwnerDecision.DIY) {
          onOpenDIYModal();
        } else if (!isRejectOpened && toChangeOwnerDecision === OwnerDecision.No) {
          onOpenRejectModal();
        }
        const serverDecision = repairs.find((e) => e.id === repairId)!.ownerDecision;
        onChangeOwnerDecision(repairId, toChangeOwnerDecision, serverDecision);
      }}
    />
  );
}
