import { VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useEffect, useState } from "react";
import { ThroughoutPropertyDetector } from "../../../../@types/RentReady/ThroughoutPropertyDetector";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useSaveThroughoutPropertyDetectorSubPage } from "../../../../hooks/rentReady/useSaveThroughoutPropertyDetectorSubPage";
import { useThroughoutPropertyDetectorCache } from "../../../../hooks/rentReady/useThroughoutPropertyDetectorCache";
import { Prefix } from "../../../../hooks/rentReady/useUpload";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { DetectorBox } from "./DetectorBox";
import { useIsThroughoutPropertyDetectorDisabled } from "./useIsThroughoutPropertyDetectorDisabled";

function Content({
  throughoutPropertyDetector: initThroughoutPropertyDetector,
}: {
  throughoutPropertyDetector: ThroughoutPropertyDetector;
}) {
  const { setView } = useView();
  const isEditable = useIsEditable();
  const error = useError();
  const success = useSuccess();
  const [state, setState] = useState<ThroughoutPropertyDetector>(initThroughoutPropertyDetector);
  const { isLoading, mutate } = useSaveThroughoutPropertyDetectorSubPage();
  const isDisabled = useIsThroughoutPropertyDetectorDisabled({ value: state });

  useEffect(() => {
    setState(initThroughoutPropertyDetector);
  }, [JSON.stringify(initThroughoutPropertyDetector)]);

  return (
    <FullScreen
      headerName="Smoke & CO Detectors: Throughout Property"
      backView={View.AreaListView}
      contentProps={{ p: 5 }}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(View.AreaListView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={async () => {
              await mutate(state, {
                onSuccess() {
                  success({ title: "Updated!" });
                  setView(View.AreaListView);
                },
                onError() {
                  error({
                    title: "Error updating",
                    description: "There was an error updating. Please try again.",
                  });
                },
              });
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <VStack spacing={10}>
        <DetectorBox
          title="Smoke Detectors"
          prefix={Prefix.SmokeDetector}
          photos={state.detectorSmokePhotos}
          onChangePhotos={(detectorSmokePhotos) => setState({ ...state, detectorSmokePhotos })}
          yesNo1Title="At least 1 smoke detector outside of bedrooms?*"
          yesNo1Value={state.detectorSmokeIsAtLeastOneOutsideBedrooms}
          onChangeYesNo1={(detectorSmokeIsAtLeastOneOutsideBedrooms) =>
            setState({ ...state, detectorSmokeIsAtLeastOneOutsideBedrooms })
          }
          yesNo2Title="At least 1 smoke detector on each floor?*"
          yesNo2Value={state.detectorSmokeIsAtLeastOneEachFloor}
          onChangeYesNo2={(detectorSmokeIsAtLeastOneEachFloor) =>
            setState({ ...state, detectorSmokeIsAtLeastOneEachFloor })
          }
        />
        <DetectorBox
          title="Carbon Monoxide Detectors"
          prefix={Prefix.CarbonMonoxideDetectors}
          photos={state.detectorCmPhotos}
          onChangePhotos={(detectorCmPhotos) => setState({ ...state, detectorCmPhotos })}
          yesNo1Title="At least 1 CO detector outside of the bedrooms?*"
          yesNo1Value={state.detectorCmIsAtLeastOneCoOutsideBedrooms}
          onChangeYesNo1={(detectorCmIsAtLeastOneCoOutsideBedrooms) =>
            setState({ ...state, detectorCmIsAtLeastOneCoOutsideBedrooms })
          }
          yesNo2Title="At least 1 CO detector on each floor?*"
          yesNo2Value={state.detectorCmIsAtLeastOneCoEachFloor}
          onChangeYesNo2={(detectorCmIsAtLeastOneCoEachFloor) =>
            setState({ ...state, detectorCmIsAtLeastOneCoEachFloor })
          }
        />
      </VStack>
    </FullScreen>
  );
}

export function ThroughoutPropertyDetectorEditView() {
  const throughoutPropertyDetectorCache = useThroughoutPropertyDetectorCache();

  if (!throughoutPropertyDetectorCache) return null;

  return <Content throughoutPropertyDetector={throughoutPropertyDetectorCache} />;
}
