import { Item } from "../../../../../@types/RentReady/Item";
import { useOnCompleteItemClick } from "../../../../../hooks/rentReady/useOnCompleteItemClick";
import { PurpleCircle } from "../../../PurpleCircle";

type Props = {
  item: Item;
};

export function ItemCompleteCircle({ item }: Props) {
  const onCompleteItemClick = useOnCompleteItemClick(item);

  return <PurpleCircle name={item.cosmeticDefect} onClick={onCompleteItemClick} />;
}
