import { Box, Flex, HStack, useDisclosure } from "@chakra-ui/react";
import { Modal, OutlineButton } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import React, { useContext } from "react";
import { useUpdateFlexWalkthroughReportSubmittedTime } from "src/hooks/flexWalkthrough/useUpdateFlexWalkthroughReportSubmittedTime";
import { useError } from "src/hooks/useError";
import { useSuccess } from "src/hooks/useSuccess";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function ConfirmSubmittedModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);
  const success = useSuccess();
  const error = useError();
  const { mutate: updateFlexWalkthroughReportSubmittedTime, isLoading } = useUpdateFlexWalkthroughReportSubmittedTime();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box paddingX={4} paddingY={8}>
        <Flex fontSize="xl" justifyContent="center" alignItems="center" color="gray.700" fontWeight="bold">
          Confirm Submission
        </Flex>
        <Box color="gray.500" mt={3}>
          Please confirm that you would like to submit the walkthrough. Once submitted, you won’t be able to edit it
          again.
        </Box>
      </Box>
      <Flex mt={3} justifyContent="center" alignItems="center" w="100%">
        <HStack spacing={2}>
          <OutlineButton size="md" onClick={onClose}>
            Cancel
          </OutlineButton>
          <PrimaryButton
            size="md"
            isLoading={isLoading}
            onClick={async () => {
              updateFlexWalkthroughReportSubmittedTime({} as any, {
                onSuccess() {
                  success({
                    title: "Submitted Successfully",
                  });
                  onClose();
                },
                onError() {
                  error({ title: "Failed to Submit, please try again!", description: "" });
                },
              });
            }}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      </Flex>
    </Modal>
  );
}

export function useConfirmSubmittedModal() {
  return useContext(context);
}
