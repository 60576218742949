import { Box, Flex, Img, useDisclosure } from "@chakra-ui/react";
import { Modal } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import React, { useContext } from "react";

type ContextProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function ApprovalsSubmittedModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box paddingX={4} paddingY={8}>
        <Flex justifyContent="center" alignItems="center">
          <Img src="/resources/rr-project/marked.svg" />
        </Flex>
        <Flex fontSize="xl" justifyContent="center" alignItems="center" color="gray.700" fontWeight="bold">
          Approvals Submitted
        </Flex>
        <Box color="gray.500" mt={3}>
          Thank you for submitting your repair list approval! We are now working to get estimates for your repairs which
          should take 2-3 business days. Once we have your estimates, you will receive an email to review and approve
          them here.
        </Box>
      </Box>
      <Flex mt={3} justifyContent="center" alignItems="center" w="100%">
        <PrimaryButton size="md" onClick={onClose}>
          Done
        </PrimaryButton>
      </Flex>
    </Modal>
  );
}

export function useApprovalsSubmittedModal() {
  return useContext(context);
}
