import axios, { CancelTokenSource } from "axios";

type Args = {
  url: string;
  file: File;
  onProgress: (args: { file: File; progress: number }) => void;
};

type Return = {
  cancelSource: CancelTokenSource;
};

export function useUploadToS3() {
  return async ({ url, file, onProgress }: Args): Promise<Return> => {
    const cancelSource = axios.CancelToken.source();
    await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress(evt) {
        const progress = Math.round((evt.loaded * 100) / (evt.total || 1));
        onProgress({ file, progress });
      },
      cancelToken: cancelSource.token,
    });
    return {
      cancelSource,
    };
  };
}
