import { useToast } from "@chakra-ui/react";

export function useError() {
  const toast = useToast();
  return ({ title, description }: { title: string; description: string }) => {
    toast({
      title,
      description,
      status: "error",
      duration: 1500,
      isClosable: true,
    });
  };
}
