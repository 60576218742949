import produce from "immer";
import { FlexWalkthroughGraphQLResultCache } from "../../../databases/FlexWalkthroughDatabase";
import { FlexWalkthroughGraphQLResult } from "./useFetchFlexWalkthroughReport";

export function mergeFlexWalkthroughReportCache(
  flexWalkthroughGraphQLResultCache: FlexWalkthroughGraphQLResultCache,
  flexWalkthroughGraphQLResultServer: FlexWalkthroughGraphQLResult
): FlexWalkthroughGraphQLResultCache {
  return produce(flexWalkthroughGraphQLResultCache, (cache) => {
    cache.flexWalkthroughReport.propertyIsPhotoReady =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.propertyIsPhotoReady;
    cache.flexWalkthroughReport.propertyOverviewPhotoUrlsJson =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.propertyOverviewPhotoUrlsJson;
    cache.flexWalkthroughReport.propertyOverviewNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.propertyOverviewNotes;
    cache.flexWalkthroughReport.smokeDetectorPhotoUrlsJson =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorPhotoUrlsJson;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneInsideEachBedroom =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneInsideEachBedroom;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneInsideEachBedroomNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneInsideEachBedroomNotes;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneOutsideOfBedrooms =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneOutsideOfBedrooms;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneOutsideOfBedroomsNotes;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneEachFloor =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneEachFloor;
    cache.flexWalkthroughReport.smokeDetectorIsAtLeastOneEachFloorNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.smokeDetectorIsAtLeastOneEachFloorNotes;
    cache.flexWalkthroughReport.coDetectorPhotoUrlsJson =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.coDetectorPhotoUrlsJson;
    cache.flexWalkthroughReport.coDetectorIsAtLeastOneOutsideOfBedrooms =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.coDetectorIsAtLeastOneOutsideOfBedrooms;
    cache.flexWalkthroughReport.coDetectorIsAtLeastOneOutsideOfBedroomsNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.coDetectorIsAtLeastOneOutsideOfBedroomsNotes;
    cache.flexWalkthroughReport.coDetectorIsAtLeastOneEachFloor =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.coDetectorIsAtLeastOneEachFloor;
    cache.flexWalkthroughReport.coDetectorIsAtLeastOneEachFloorNotes =
      flexWalkthroughGraphQLResultServer.flexWalkthroughReport.coDetectorIsAtLeastOneEachFloorNotes;
  });
}
