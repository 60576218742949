import { Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { useArea } from "../../../../../hooks/rentReady/useArea";
import { PaintingOptions } from "../../../../../hooks/rentReady/useAreasCache/mapAreaNodesToAreas";
import { useSyncSingleAreaFieldToCacheAndServer } from "../../../../../hooks/rentReady/useSyncSingleAreaFieldToCacheAndServer";
import { ArrowOpenIcon } from "../../../../ArrowOpenIcon";
import { AreaAutoSyncTextField } from "./AreaAutoSyncTextField";
import { NeedControlBox } from "./NeedControlBox";

export function AreaFullAreaPaintingBox() {
  const { paintingNotes, painting } = useArea()!;
  const syncSingleFieldToCacheAndServer = useSyncSingleAreaFieldToCacheAndServer();
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
    defaultIsOpen: painting,
  });

  return (
    <VStack w="100%">
      <Flex w="100%" justifyContent="center" alignItems="center">
        <NeedControlBox
          isLoading={false}
          label="Full Area Painting"
          value={painting}
          onOpen={onOpen}
          onClose={onClose}
          onChange={async (value) => {
            const toSaveValue = value ? [PaintingOptions.FullPainting] : [];
            await syncSingleFieldToCacheAndServer(
              "areaSelectedPaintingOptions",
              "selectedPaintingOptions",
              toSaveValue,
              toSaveValue
            );
          }}
        />
        <ArrowOpenIcon isOpen={isOpen} onClick={onToggle} />
      </Flex>

      {isOpen && (
        <AreaAutoSyncTextField value={paintingNotes} cacheField="areaPaintingNotes" serverField="paintingNotes" />
      )}
    </VStack>
  );
}
