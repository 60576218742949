import { VStack } from "@chakra-ui/react";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useEffect } from "react";
import { useFetchedApplicant } from "../../../../../../hooks/applicants/useFetchedApplicant";
import { useUpdateApplicantField } from "../../../../../../hooks/applicants/useUpdateApplicantField";
import { CardTitle } from "../../../../CardTitle";
import { ResidenceCard } from "../ResidenceCard";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
  onContinue: () => void;
};

export const CurrentAddressCard = React.forwardRef(({ padding, width, onContinue, ...props }: Props, ref) => {
  const applicant = useFetchedApplicant()!;
  const isMobile = useIsMobile();
  const { mutate: updateApplicantField } = useUpdateApplicantField();

  useEffect(() => {
    if (!applicant.country) {
      updateApplicantField({ country: "US" });
    }
  }, [applicant.country, updateApplicantField]);

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Current Address</CardTitle>
        <GrayText>Please provide details about your current primary residence.</GrayText>
        <ResidenceCard
          padding={0}
          width="100%"
          streetAddress={applicant.addressLine1}
          unitNumber={applicant.addressLine2}
          state={applicant.state}
          city={applicant.city}
          country={applicant.country}
          zipCode={applicant.zipcode}
          moveInDate={applicant.moveInDate}
          moveOutDate={applicant.moveOutDate}
          landlordName={applicant.landlordName}
          landlordPhone={applicant.landlordPhone}
          landlordEmail={applicant.landlordEmail}
          onStreetAddressChange={(addressLine1) => {
            updateApplicantField({ addressLine1 });
          }}
          onUnitNumberChange={(addressLine2) => {
            updateApplicantField({ addressLine2 });
          }}
          onStateChange={(state) => {
            updateApplicantField({ state });
          }}
          onCityChange={(city) => {
            updateApplicantField({ city });
          }}
          onCountryChange={(country) => {
            updateApplicantField({ country });
          }}
          onZipCodeChange={(zipcode) => {
            updateApplicantField({ zipcode });
          }}
          onMoveInDateChange={(moveInDate) => {
            updateApplicantField({ moveInDate });
          }}
          onMoveOutDateChange={(moveOutDate) => {
            updateApplicantField({ moveOutDate });
          }}
          onLandlordNameChange={(landlordName) => {
            updateApplicantField({ landlordName });
          }}
          onLandlordPhoneChange={(landlordPhone) => {
            updateApplicantField({ landlordPhone });
          }}
          onLandlordEmailChange={(landlordEmail) => {
            updateApplicantField({ landlordEmail });
          }}
        />
        <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
          Continue
        </PrimaryButton>
      </VStack>
    </Card>
  );
});
