import { Box } from "@chakra-ui/react";
import { ApplicantOccupantNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { ValuesCard } from "../../ValuesCard";
import { Content } from "./Content";
import { Props } from "./typings";

export function OccupantsCard({ id, onChange, onRemove, hasAddMoreButton, ...valuesProps }: Props) {
  return (
    <ValuesCard
      {...valuesProps}
      id={id}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      idField="applicantOccupantId"
      eventName="Occupants"
      title="Additional occupants"
      subTitle={
        <Box>
          {`Please list all other persons under the age of 18 who will live in the property. Please note that all other
          occupants are required to fill out their own application as a co-tenant.`}
          <b>Please answer on behalf of all tenants.</b>
        </Box>
      }
      noLabel="No additional occupants"
      yesLabel="Add an occupant"
      numberLabel="Occupant"
      addLabel="Add more occupants"
      content={(_ignored, value) => <Content value={value as ApplicantOccupantNode} onChange={onChange} />}
      hasAddMoreButton={hasAddMoreButton}
      onRemove={(_ignored, value) => {
        onRemove((value as ApplicantOccupantNode).applicantOccupantId);
      }}
    />
  );
}
