import { Photo } from "../../@types/RentReady/Photo";

export function urlsToPhotos(urls: string[]): Photo[] {
  return urls.map((url) => ({
    id: url,
    src: {
      bucket: "",
      key: "",
      fileName: "",
      url,
    },
    thumbnail: {
      bucket: "",
      key: "",
      fileName: "",
      url,
    },
    isUploaded: false,
    toUploadFile: {
      dataUrl: url,
    },
  }));
}
