import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";

export function useGenerateS3PresignedUrl() {
  const { gqlClient } = useAuth();
  return useMutation(async ({ key, bucket }: { key: string; bucket: string }) => {
    return await gqlClient.mutate({
      mutation: gql`
        mutation ($bucket: String, $key: String) {
          generateS3PresignedUrl(bucket: $bucket, key: $key) {
            url
          }
        }
      `,
      variables: {
        key,
        bucket,
      },
    });
  });
}
