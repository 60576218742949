import { Box, Flex, ListItem, UnorderedList, VStack, useDisclosure } from "@chakra-ui/react";
import { Modal } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import React, { useContext, useState } from "react";
import { NoteIcon } from "../../components/rr-project/NoteIcon";

type ContextProps = {
  isOpen: boolean;
  isOpenedBefore: boolean;
  setOpenedBefore: (value: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
};

const context = React.createContext<ContextProps>({
  isOpen: false,
  isOpenedBefore: false,
  setOpenedBefore() {
    console.log("setOpenedBefore");
  },
  onOpen() {
    console.log("onOpen");
  },
  onClose() {
    console.log("onClose");
  },
});

export function DIYModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenedBefore, setOpenedBefore] = useState(false);

  return (
    <context.Provider
      value={{
        isOpen,
        isOpenedBefore,
        setOpenedBefore,
        onClose,
        onOpen() {
          setOpenedBefore(true);
          onOpen();
        },
      }}
    >
      <Component />
      {children}
    </context.Provider>
  );
}

function Component() {
  const { isOpen, onClose } = useContext(context);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VStack>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <NoteIcon />
        </Flex>
        <Box fontWeight="bold">Acknowledgement of DIY responsibilities</Box>
        <VStack color="gray.500">
          <Box>
            By selecting &quot;DIY&quot;, you are agreeing to take full responsibility for the completion of the repair
            including:
          </Box>
          <UnorderedList>
            <ListItem>Sending Doorstead photos of completed repairs via the DIY Punchlist</ListItem>
            <ListItem>Informing Doorstead of repair completion dates</ListItem>
            <ListItem>Informing your vendors that all repairs will need to pass quality assurance</ListItem>
          </UnorderedList>
          <Box>Doorstead will send a team member to quality assure repairs once they have been completed.</Box>
          <PrimaryButton size="md" onClick={onClose}>
            Acknowledge
          </PrimaryButton>
        </VStack>
      </VStack>
    </Modal>
  );
}

export function useDIYModal() {
  return useContext(context);
}
