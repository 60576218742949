import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApplicantAnalytics } from "../../hooks/applicants/useApplicantAnalytics";

export default function ApplicantLogoutCallbackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const applicantAnalytics = useApplicantAnalytics();
  const applicantId = searchParams.get("applicantId");

  useEffect(() => {
    applicantAnalytics?.track("TP_APPLICANT_LOGOUT_SUCCESS", { applicantId });
    navigate(`/application/${applicantId}`);
  }, []);

  return null;
}
