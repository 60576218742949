import { RepairOrReplace, useComponentId } from "./useComponentId";
import { useItemId } from "./useItemId";
import { View, useView } from "./useView";

type Args = {
  itemId: string;
  componentId: string;
};

export function useOnCompleteComponentClick({ itemId, componentId }: Args) {
  const { setItemId } = useItemId();
  const { setValue } = useComponentId();
  const { setView } = useView();

  return (repairOrReplace: RepairOrReplace) => {
    setItemId(itemId);
    setValue({
      componentId,
      repairOrReplace,
    });
    setView(View.ComponentEditView);
  };
}
