import Dexie, { Table } from "dexie";
import { FlexWalkthroughGraphQLResult } from "src/hooks/flexWalkthrough/useFetchFlexWalkthroughReport/useFetchFlexWalkthroughReport";

export type FlexWalkthroughGraphQLResultCache = FlexWalkthroughGraphQLResult & {
  flex_walkthrough_report_id: string;
};

export class FlexWalkthroughDatabase extends Dexie {
  flex_walkthrough_report_graphql_result!: Table<FlexWalkthroughGraphQLResultCache, string>;

  public constructor() {
    super("FlexWalkthroughReport");
    this.version(1).stores({
      flex_walkthrough_report_graphql_result: "++flex_walkthrough_report_id",
    });
  }
}

export const db = new FlexWalkthroughDatabase();

export async function readFlexWalkthroughGraphQLResultCache(
  flexWalkthroughReportId: string
): Promise<FlexWalkthroughGraphQLResultCache | null> {
  return (
    (await db.flex_walkthrough_report_graphql_result
      .where("flex_walkthrough_report_id")
      .equals(flexWalkthroughReportId)
      .first()) || null
  );
}

export async function writeFlexWalkthroughGraphQLResult(
  flexWalkthroughReportId: string,
  flexWalkthroughGraphQLResultCache: FlexWalkthroughGraphQLResultCache
) {
  await db.flex_walkthrough_report_graphql_result.put(flexWalkthroughGraphQLResultCache);
}
