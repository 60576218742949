import { Box, HStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { ArrowIcon, Direction } from "@doorstead/components/icons/ArrowIcon";

type Props = {
  onClick: () => void;
  isOpen: boolean;
};

export function ExpandButton({ isOpen, onClick }: Props) {
  return (
    <OutlineButton size="sm" isFullWidth onClick={onClick}>
      <HStack>
        <ArrowIcon color="gray.700" size={1} direction={isOpen ? Direction.Top : Direction.Bottom} />;
        <Box>Show more</Box>
      </HStack>
    </OutlineButton>
  );
}
