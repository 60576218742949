import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { useFetchedRRProject } from "../../hooks/rr-project/useFetchedRRProject";
import { WhiteBlock } from "./WhiteBlock";

export function NoticeCard(flexProps: FlexProps) {
  const rrProject = useFetchedRRProject();

  return (
    <Flex {...flexProps} justifyContent="center" alignItems="center" w="100%">
      <WhiteBlock title={rrProject.property?.fullAddress || ""}>
        <Box color="gray.500">
          Please note that the recommended approvals are optional, and you are free to approve only the repairs and
          improvements that you feel are necessary. However, keep in mind that approving all the recommendations can
          greatly benefit you in the long run.
        </Box>
      </WhiteBlock>
    </Flex>
  );
}
