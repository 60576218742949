import { Box, Divider, Flex } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useEffect, useState } from "react";
import { useIsReadOnly } from "src/hooks/rentReady/useIsReadOnly";
import { ThroughoutPropertyCleaning } from "../../../../@types/RentReady/ThroughoutPropertyCleaning";
import { useIsEditable } from "../../../../hooks/rentReady/useIsEditable";
import { useSaveThroughoutPropertyCleaningSubPage } from "../../../../hooks/rentReady/useSaveThroughoutPropertyCleaningSubPage";
import { useThroughoutPropertyCleaningCache } from "../../../../hooks/rentReady/useThroughoutPropertyCleaningCache";
import { View, useView } from "../../../../hooks/rentReady/useView";
import { useError } from "../../../../hooks/useError";
import { useSuccess } from "../../../../hooks/useSuccess";
import { FullScreen } from "../../FullScreen";
import { RequiredLabel } from "../../RequiredLabel";
import { YesNoButtons } from "../../YesNoButtons";
import { TextAreaField } from "../../fields/TextAreaField";
import { CleaningIsOrderRemoval } from "./CleaningIsOrderRemoval";
import { CleaningSelects } from "./CleaningSelects";
import { useIsThroughoutPropertyCleaningDisabled } from "./useIsThroughoutPropertyCleaningDisabled";

function Content({
  throughoutPropertyCleaning: initThroughoutPropertyCleaning,
}: {
  throughoutPropertyCleaning: ThroughoutPropertyCleaning;
}) {
  const { setView } = useView();
  const isEditable = useIsEditable();
  const error = useError();
  const success = useSuccess();
  const [throughoutPropertyCleaning, setThroughoutPropertyCleaning] =
    useState<ThroughoutPropertyCleaning>(initThroughoutPropertyCleaning);
  const { isLoading, mutate } = useSaveThroughoutPropertyCleaningSubPage();
  const isDisabled = useIsThroughoutPropertyCleaningDisabled({ value: throughoutPropertyCleaning });

  useEffect(() => {
    setThroughoutPropertyCleaning(initThroughoutPropertyCleaning);
  }, [JSON.stringify(initThroughoutPropertyCleaning)]);

  return (
    <FullScreen
      headerName="Cleaning: Throughout Property"
      backView={View.AreaListView}
      contentProps={{ p: 5 }}
      backButton={
        <OutlineButton
          isFullWidth
          onClick={() => {
            setView(View.AreaListView);
          }}
        >
          Cancel
        </OutlineButton>
      }
      nextButton={
        isEditable && (
          <PrimaryButton
            isFullWidth
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={async () => {
              await mutate(throughoutPropertyCleaning, {
                onSuccess() {
                  success({ title: "Updated!" });
                  setView(View.AreaListView);
                },
                onError() {
                  error({
                    title: "Error updating",
                    description: "There was an error updating. Please try again.",
                  });
                },
              });
            }}
          >
            Save
          </PrimaryButton>
        )
      }
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%" fontWeight="bold" mb={8}>
        <RequiredLabel hasValue={throughoutPropertyCleaning.cleanIsNeeded !== null}>Is Cleaning Needed?</RequiredLabel>
        <Box>
          <YesNoButtons
            value={throughoutPropertyCleaning.cleanIsNeeded}
            onChange={(cleanIsNeeded) => {
              setThroughoutPropertyCleaning({ ...throughoutPropertyCleaning, cleanIsNeeded });
            }}
          />
        </Box>
      </Flex>
      <CleaningSelects state={throughoutPropertyCleaning} setState={setThroughoutPropertyCleaning} />
      <CleaningIsOrderRemoval
        value={throughoutPropertyCleaning.cleanIsOdorRemoval}
        onChange={(cleanIsOdorRemoval) => {
          setThroughoutPropertyCleaning({ ...throughoutPropertyCleaning, cleanIsOdorRemoval });
        }}
      />
      <Divider />
      <TextAreaField
        label="Notes"
        value={throughoutPropertyCleaning.cleanNotes}
        placeholder="Optional notes on cleaning details"
        onChange={(cleanNotes) => {
          setThroughoutPropertyCleaning({ ...throughoutPropertyCleaning, cleanNotes });
        }}
        disabled={useIsReadOnly()}
      />
    </FullScreen>
  );
}

export function ThroughoutPropertyCleaningEditView() {
  const throughoutPropertyCleaning = useThroughoutPropertyCleaningCache();

  if (throughoutPropertyCleaning === null) return null;

  return <Content throughoutPropertyCleaning={throughoutPropertyCleaning} />;
}
