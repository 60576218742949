import { StyleProps, Text } from "@chakra-ui/react";

export type Props = StyleProps & {
  children: string;
};

export function CardText({ children, ...styleProps }: Props) {
  return (
    <Text {...styleProps} fontSize="4xl" color="gray.700" fontWeight="bold">
      {children}
    </Text>
  );
}
