import { Box } from "@chakra-ui/react";
import { QuestionMarkFilledIcon } from "@doorstead/components/icons/QuestionMarkFilledIcon";

type Props = {
  onClick: () => void;
};

export function QuestionIcon({ onClick }: Props) {
  return (
    <Box onClick={onClick}>
      <QuestionMarkFilledIcon color="purple.500" />
    </Box>
  );
}
