import { useIsAdditionalKeyStorageComplete } from "./useIsAdditionalKeyStorageComplete";
import { useIsFullTenantSetsComplete } from "./useIsFullTenantSetsComplete";
import { useIsLockboxComplete } from "./useIsLockboxComplete";

export function useIsKeyStorageComplete(): boolean {
  const isLockboxComplete = useIsLockboxComplete();
  const isFullTenantSetsComplete = useIsFullTenantSetsComplete();
  const isAdditionalKeyStorageComplete = useIsAdditionalKeyStorageComplete();

  return isFullTenantSetsComplete && isAdditionalKeyStorageComplete && isLockboxComplete;
}
