import { GridProps } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";

export function useGridProps() {
  const isMobile = useIsMobile();
  const gridProps: GridProps = {
    h: "100%",
  };

  if (isMobile) {
    return {
      ...gridProps,
      gridTemplateRows: "min-content minmax(550px, 100%)",
    };
  } else {
    return {
      ...gridProps,
      gridTemplateColumns: {
        md: "auto 550px",
        "2xl": "auto 796px",
      },
    };
  }
}
