import { Flex, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { OutlineButton, useIsMobile } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddress } from "src/hooks/applicants/useAddress";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useStartApplicant } from "src/hooks/applicants/useStartApplicant";
import { useApplicantOnboardingStage } from "../../../../hooks/applicants/useApplicantOnboardingStage";
import { useApplicantPageView } from "../../../../hooks/applicants/useApplicantPageView";
import { ApplicantNodeStatus } from "../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useIsShowInvitedCoTenantLink } from "../../../../hooks/applicants/useIsShowInvitedCoTenantLink";
import { getTargetId } from "../../../../hooks/applicants/useSteps";
import { EmploymentAndIncomeSection } from "../ApplicantInCompleteCard/EmploymentAndIncomeSection/EmploymentAndIncomeSection";
import { IdentityVerificationSection } from "../ApplicantInCompleteCard/IdentityVerificationSection/IdentityVerificationSection";
import { PropertyInfoCard } from "../PropertyInfoCard";
import { SubmitAndPaySection } from "./SubmitAndPaySection";
import { Timeline } from "./Timeline";
import { YourInformationSection } from "./YourInformationSection";

function BreadCrumb({ onBack }: { onBack: () => void }) {
  const address = useAddress();

  return (
    <Text color="gray.500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
      <Link onClick={onBack} color="purple.600" fontSize={16} fontWeight={500}>
        My Application
      </Link>{" "}
      / {address}
    </Text>
  );
}

export function OnboardingSteps({ onBack }: { onBack: () => void }) {
  const isMobile = useIsMobile();
  const applicantOnboardingStage = useApplicantOnboardingStage();
  const path = getTargetId(applicantOnboardingStage);
  const { mutate: startApplication } = useStartApplicant();
  const { status } = useFetchedApplicant();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "auto",
    });
  }, [path]);

  useEffect(() => {
    if (status === ApplicantNodeStatus.NEW) {
      startApplication();
    }
  }, [status]);

  return (
    <Flex w="100%" pos="relative" flexDirection={isMobile ? "column" : "row"} gap={6}>
      {isMobile && <BreadCrumb onBack={onBack} />}
      <Timeline />
      <VStack spacing={8} alignItems="flex-start">
        {!isMobile && <BreadCrumb onBack={onBack} />}
        {path === "/0" && <YourInformationSection />}
        {path === "/1" && <IdentityVerificationSection />}
        {path === "/2" && <EmploymentAndIncomeSection />}
        {path === "/3" && <SubmitAndPaySection />}
      </VStack>
    </Flex>
  );
}

type Props = {
  isLandingPage: boolean;
  onContinue: () => void;
  onBack: () => void;
};

export function ApplicantInCompleteCard({ isLandingPage, onContinue, onBack }: Props) {
  const applicantAnalytics = useApplicantPageView("TP_APPLICANT_INCOMPLETE_VIEWED");
  const isMobile = useIsMobile();
  const applicantId = useApplicantId();
  const navigate = useNavigate();
  const isShowInvitedCoTenantLink = useIsShowInvitedCoTenantLink();

  if (isLandingPage) {
    return (
      <PropertyInfoCard
        action={
          <Stack direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
            <PrimaryButton
              isFullWidth={isMobile}
              onClick={() => {
                applicantAnalytics?.track("TP_CONTINUE_APPLICATION_CLICKED");
                onContinue();
              }}
            >
              Continue Application
            </PrimaryButton>
            {isShowInvitedCoTenantLink && (
              <OutlineButton
                isFullWidth={isMobile}
                onClick={() => {
                  applicantAnalytics?.track("TP_INVITE_COTENANT_CLICKED");
                  navigate(`/application/${applicantId}/invite`);
                }}
              >
                Invite Co-tenant
              </OutlineButton>
            )}
          </Stack>
        }
      />
    );
  } else {
    return <OnboardingSteps onBack={onBack} />;
  }
}
