type Args = {
  dataUrl: string;
  fileName: string;
  defaultMimeType?: string;
};

export function dataURLtoFile({ dataUrl, fileName, defaultMimeType = "image/png" }: Args): File | null {
  const arr = dataUrl.split(",");
  if (arr.length === 0) {
    return null;
  }
  const mime = arr[0]?.match(/:(.*?);/)?.[1] ?? defaultMimeType;
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}
