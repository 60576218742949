import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { DoorsteadLogo } from "@doorstead/components";
import { PrimaryButton } from "@doorstead/components/buttons";
import { InspectionReportType } from "../../../../hooks/rentReady/useFetchInspectionReport/InspectionReportNode";
import { useInspectionReport } from "../../../../hooks/rentReady/useInspectionReport";
import { useSyncServerToLocalDb } from "../../../../hooks/rentReady/useSyncServerToLocalDb";
import { useUpdateInspectionReport } from "../../../../hooks/rentReady/useUpdateInspectionReport";
import { useIsOnline } from "../../../../hooks/useIsOnline";
import { InspectionReportPropertyBox } from "../../InspectionReportPropertyBox";

export function StartView() {
  const isOnline = useIsOnline();
  const { propertyAddress, inspectionType } = useInspectionReport();
  const { mutate: updateInspectionReport, isLoading } = useUpdateInspectionReport();
  const serverToLocalDb = useSyncServerToLocalDb();

  return (
    <Flex flexDirection="column" w="100%" pos="relative">
      <VStack flex={1} w="100%" p={5}>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <DoorsteadLogo width={200} href="" />
        </Flex>
        <VStack pt={10}>
          <Box fontWeight="bold" fontSize="xl">
            {inspectionType == InspectionReportType.MOVE_IN_INSPECTION
              ? "Move In Evaluation"
              : "Property Evaluation & Estimates Tool"}
          </Box>
          <Box>for</Box>
          <Box fontWeight="semibold">{propertyAddress}</Box>
          <Box>
            <Divider />
          </Box>
          <InspectionReportPropertyBox />
        </VStack>
      </VStack>
      <VStack as="footer" pos="sticky" bottom={0} spacing={0}>
        <Box color="purple.400" fontWeight="bold" p={5}>
          Once you arrive at the property, please hit Start
        </Box>
        <Divider />
        {isOnline && (
          <Box w="100%" p={5}>
            <PrimaryButton
              isFullWidth
              disabled={isLoading}
              isLoading={isLoading}
              onClick={async () => {
                await updateInspectionReport(
                  {
                    startTime: new Date().toISOString(),
                  },
                  {
                    onSuccess: serverToLocalDb,
                  }
                );
              }}
            >
              Start
            </PrimaryButton>
          </Box>
        )}
      </VStack>
    </Flex>
  );
}
