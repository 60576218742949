import { gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useInspectionReportId } from "./useInspectionReportId";

type Result = {
  data: {
    createInspectionReportEstimatesDataV2: {
      success: boolean;
      completedEvalTime: string;
    };
  };
};

export function useCreateInspectionReportEstimatesDataV2() {
  const { gqlClient } = useAuth();
  const inspectionReportId = useInspectionReportId();

  return () => {
    return gqlClient.mutate({
      mutation: gql`
        mutation createInspectionReportEstimatesDataV2($inspectionReportId: UUID!) {
          createInspectionReportEstimatesDataV2(inspectionReportId: $inspectionReportId) {
            success
            completedEvalTime
          }
        }
      `,
      variables: {
        inspectionReportId,
      },
    }) as unknown as Result;
  };
}
