import { Box, Flex, HStack } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { useState } from "react";
import { useIsReadOnly } from "../../../../../hooks/rentReady/useIsReadOnly";

type Props = {
  isLoading: boolean;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
};

export function NeedControlBox({ isLoading, label, value: defaultValue, onChange, onOpen, onClose }: Props) {
  const isReadOnly = useIsReadOnly();
  const [value, setValue] = useState(defaultValue);

  return (
    <Flex w="100%" justifyContent="space-between" alignItems="center">
      <Box flex={1} color="gray.900" fontSize="14px" fontWeight="bold">
        {label}
      </Box>
      <HStack>
        <Box
          onClick={() => {
            if (!isReadOnly && !isLoading && value) {
              onChange(false);
              setValue(false);
              onClose();
            }
          }}
        >
          <Chip label="Not Needed" variant={value !== null && !value ? Variant.Green : Variant.Gray} />
        </Box>
        <Box
          onClick={() => {
            if (!isReadOnly && !isLoading && !value) {
              onChange(true);
              setValue(true);
              onOpen();
            }
          }}
        >
          <Chip label="Needed" variant={value !== null && value ? Variant.Red : Variant.Gray} />
        </Box>
      </HStack>
    </Flex>
  );
}
