import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialog as ChakraAlertDialog,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components/buttons";
import { ReactNode, useRef } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  submitButton: ReactNode;
};

export function AlertDialog({ isOpen, onClose, title, description, submitButton }: Props) {
  const cancelRef = useRef<any>();

  return (
    <ChakraAlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Flex justifyContent="center" alignItems="center">
              {title}
            </Flex>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex w="100%" justifyContent="center">
              <Flex maxW="300px" justifyContent="center">
                {description}
              </Flex>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack w="100%" spacing={5}>
              <OutlineButton isFullWidth onClick={onClose}>
                Cancel
              </OutlineButton>
              {submitButton}
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
}
