import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { Table } from "@doorstead/components/containers/Table";
import { cells } from "./cells";
import { headers } from "./headers";
import { Props, Row } from "./typing";

export function TransactionHistoryTable({ fontSize, rows, onViewClick }: Props) {
  return (
    <VStack w="100%" h="100%" padding={5} borderRadius={20} bgColor="white" alignItems="center" justifyContent="center">
      <VStack w="100%" spacing={3}>
        <Flex w="100%" justifyContent="space-between">
          <Text color="gray.800" fontSize={fontSize} fontWeight="bold">
            Transaction history
          </Text>
          {onViewClick && (
            <Box
              _hover={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              color="purple.500"
              fontWeight="semibold"
              onClick={onViewClick}
            >
              View all
            </Box>
          )}
        </Flex>
        <Box w="100%">
          <Table<Row> headers={headers} cells={cells} rows={rows} />
        </Box>
      </VStack>
    </VStack>
  );
}
