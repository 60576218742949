import { useEffect, useState } from "react";

export function useHashTag() {
  const [hashTag, setHashTag] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setHashTag(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return hashTag;
}
