import { Box } from "@chakra-ui/react";
import { Modal } from "@doorstead/components";
import React from "react";
import { CardText } from "../../CardText";

type Props = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export function EverGreenModal({ title, children, isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box paddingX={4} paddingY={8}>
        <CardText lineHeight={1}>{title}</CardText>
        {children}
      </Box>
    </Modal>
  );
}
