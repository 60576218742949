import produce from "immer";
import { useMutation } from "react-query";
import { Item } from "../../@types/RentReady/Item";
import {
  RRGraphQLResultCache,
  readRRGraphQLResultCache,
  writeRRGraphQLResult,
} from "../../databases/RentReadyDatabase";
import { getNowISOStringWithoutZ } from "../../utils/getNowISOStringWithoutZ";
import { isOnline } from "../../utils/isOnline";
import { mapPhotosToPhotoUrlsJson } from "../../utils/rentReady/mapPhotosToPhotoUrlsJson";
import { useInspectionReportId } from "./useInspectionReportId";
import { useUpdateItemToServer } from "./useUpdateItemToServer";

function useMapItemStateFromRRGraphQLResultCache() {
  return (result: RRGraphQLResultCache, { itemId }: { itemId: string }): RRGraphQLResultCache => {
    return produce(result, (draft) => {
      draft.inspectionReport.data?.forEach((areaCache) => {
        areaCache.itemsData?.forEach((item) => {
          if (item.itemDataId === itemId) {
            item.itemNotes = "";
            item.itemPhotoUrlsJson = mapPhotosToPhotoUrlsJson([]);
            item.itemSelectedCosmeticOptions = [];
          }
        });
      });
    });
  };
}

export function useClearItem() {
  const inspectionReportId = useInspectionReportId();
  const mapItemStateFromRRGraphQLResultCache = useMapItemStateFromRRGraphQLResultCache();
  const updateItemToServer = useUpdateItemToServer();

  return useMutation(async ({ item }: { item: Item }) => {
    const graphQLCacheResult = await readRRGraphQLResultCache(inspectionReportId);
    if (graphQLCacheResult !== null) {
      const result = mapItemStateFromRRGraphQLResultCache(graphQLCacheResult, { itemId: item.id });
      await writeRRGraphQLResult(inspectionReportId, result);

      if (isOnline()) {
        await updateItemToServer({
          itemDataId: item.id,
          selectedCosmeticOptions: [],
          notes: "",
          photoUrlsJson: "[]",
          status: item.status,
          updatedDate: getNowISOStringWithoutZ(),
        });
      }
    }
  });
}
