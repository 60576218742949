import { Img } from "@chakra-ui/react";

type Props = {
  src: string;
  onClick: () => void;
};

export function PhotoThumbnail({ src, onClick }: Props) {
  return <Img w={75} h={75} alt={src} src={src} onClick={onClick} />;
}
