import { Box } from "@chakra-ui/react";
import { Chip, Variant } from "@doorstead/components/Chip";
import { Item } from "../../../../../@types/RentReady/Item";
import { useOnCompleteItemClick } from "../../../../../hooks/rentReady/useOnCompleteItemClick";

type Props = {
  item: Item;
};

export function ItemCompleteBoxV2({ item }: Props) {
  const onCompleteItemClick = useOnCompleteItemClick(item);

  return (
    <Box onClick={onCompleteItemClick}>
      <Chip label={item.cosmeticDefect} variant={Variant.Red} />
    </Box>
  );
}
