import { KeyStorage } from "../../../../../@types/RentReady/KeyStorage";
import { Scale, getScaleString } from "../../../../../@types/RentReady/Scale";
import { Prefix } from "../../../../../hooks/rentReady/useUpload";
import { ScaleField } from "../../../ScaleField";
import { BoxLayout } from "../BoxLayout";
import { useOnFullTenantSetTooltipClick } from "./useOnFullTenantSetTooltipClick";

type Props = {
  value: KeyStorage["fullTenants"];
  onChange: (field: keyof KeyStorage["fullTenants"], value: any) => void;
};

export function FullTenantSetsBox({ value, onChange }: Props) {
  const onFullTenantSetTooltipClick = useOnFullTenantSetTooltipClick();

  return (
    <BoxLayout
      label="Full Tenant Sets"
      hasValue={value.number !== Scale.NotProvided}
      onQuestionClick={onFullTenantSetTooltipClick}
      prefix={Prefix.StorageFullTenant}
      photos={value.photos}
      onPhotosChange={(value) => onChange("photos", value)}
      right={
        <ScaleField
          options={[
            {
              name: getScaleString(Scale.Zero),
              value: Scale.Zero,
            },
            {
              name: getScaleString(Scale.One),
              value: Scale.One,
            },
            {
              name: getScaleString(Scale.Two),
              value: Scale.Two,
            },
            {
              name: getScaleString(Scale.Three),
              value: Scale.Three,
            },
            {
              name: getScaleString(Scale.Four),
              value: Scale.Four,
            },
          ]}
          value={value.number}
          onChange={(value) => onChange("number", value)}
        />
      }
    />
  );
}
