import produce from "immer";
import { RRGraphQLResultCache } from "../../../databases/RentReadyDatabase";
import { shouldInvalidateCache } from "../../../utils/rentReady/shouldInvalidateCache";
import { RRGraphQLResult } from "./useFetchInspectionReport";

export function mergeThroughPropertyCleaningCache(
  rrGraphQLResultCache: RRGraphQLResultCache,
  rrGraphQLResultServer: RRGraphQLResult
): RRGraphQLResultCache {
  return produce(rrGraphQLResultCache, (cache) => {
    if (
      shouldInvalidateCache({
        cacheTime: cache.inspectionReport.updatedAtThroughoutPropertyCleaning,
        serverTime: rrGraphQLResultServer.inspectionReport.updatedAtThroughoutPropertyCleaning,
      })
    ) {
      cache.inspectionReport.cleanIsNeeded = rrGraphQLResultServer.inspectionReport.cleanIsNeeded;
      cache.inspectionReport.cleanSelectedOptions = rrGraphQLResultServer.inspectionReport.cleanSelectedOptions;
      cache.inspectionReport.cleanIsOdorRemoval = rrGraphQLResultServer.inspectionReport.cleanIsOdorRemoval;
      cache.inspectionReport.cleanNotes = rrGraphQLResultServer.inspectionReport.cleanNotes;
      cache.inspectionReport.updatedAtThroughoutPropertyCleaning =
        rrGraphQLResultServer.inspectionReport.updatedAtThroughoutPropertyCleaning;
    }
  });
}
