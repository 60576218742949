import { Estimate } from "../../@types/RentReady/Estimate";
import { sortByField } from "../../utils/rentReady/sortByField";
import { useEstimates } from "./useEstimates";

export function useEstimateList(): Estimate[] {
  const estimates = useEstimates();
  const throughoutPropertyTypeSet = new Set(estimates.filter((e) => e.isGrouped).map((e) => e.throughoutPropertyType));

  const groupedEstimates = estimates.filter(({ isGrouped }) => isGrouped);
  const nonGroupedEstimates = estimates.filter(
    ({ isGrouped, throughoutPropertyType }) => !isGrouped && !throughoutPropertyTypeSet.has(throughoutPropertyType)
  );

  const allEstimates = [...groupedEstimates, ...nonGroupedEstimates];

  return sortByField(allEstimates, "uiName");
}
