import { Item } from "../../@types/RentReady/Item";
import { isDateDiff } from "../isDateDiff";
import { isCosmeticOptionsDiff, sortCosmeticOptionsById } from "./isCosmeticOptionsDiff";
import { isPhotosDiff, sortPhotosById } from "./isPhotosDiff";

export type ComparedItem = Omit<Item, "components">;

export function isItemDiff(data1: ComparedItem, data2: ComparedItem): boolean {
  // Sort the photos in both items by ID
  const sortedPhotos1 = sortPhotosById(data1.photos);
  const sortedPhotos2 = sortPhotosById(data2.photos);

  // Sort the cosmetic options in both items by ID
  const sortedCosmeticOptions1 = sortCosmeticOptionsById(data1.cosmeticOptions);
  const sortedCosmeticOptions2 = sortCosmeticOptionsById(data2.cosmeticOptions);

  // Compare the properties of the items
  if (
    data1.id !== data2.id ||
    data1.uiId !== data2.uiId ||
    data1.name !== data2.name ||
    data1.cosmeticDefect !== data2.cosmeticDefect ||
    data1.selectionType !== data2.selectionType ||
    data1.note !== data2.note ||
    data1.isPresent !== data2.isPresent ||
    data1.isCore !== data2.isCore ||
    data1.uiName !== data2.uiName ||
    isDateDiff(data1.createDate, data2.createDate) ||
    data1.updatedDate !== data2.updatedDate
  ) {
    return true;
  }

  // Compare the sorted photos in the items
  if (isPhotosDiff(sortedPhotos1, sortedPhotos2)) {
    return true;
  }

  // Compare the sorted cosmetic options in the items
  return isCosmeticOptionsDiff(sortedCosmeticOptions1, sortedCosmeticOptions2);
}
