import { Text, VStack } from "@chakra-ui/react";

export function NoticeBox({ title, subTitle }: { title: string; subTitle: string }) {
  return (
    <VStack spacing={2} display="flex" alignItems="start">
      <Text color="gray.700" fontSize={28} fontFamily="Inter" fontStyle="normal" fontWeight={700} lineHeight="34px">
        {title}
      </Text>
      <Text color="gray.500" fontSize="md">
        {subTitle}
      </Text>
    </VStack>
  );
}
