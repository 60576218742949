import { Box, VStack } from "@chakra-ui/react";
import { Title } from "./Title";

type Props = {
  address: string;
  fromDate: string;
  toDate: string;
  tenants: string[];
};

export function AddressAndTenantsBox({ address, fromDate, toDate, tenants }: Props) {
  return (
    <VStack w="100%" spacing={2} display="flex" alignItems="start">
      <Title>{address}</Title>
      <Box color="gray.700" fontSize="sm" fontWeight={400} fontFamily="Inter">
        <Box>
          {fromDate} - {toDate}
        </Box>
        <Box>{tenants.join(", ")}</Box>
      </Box>
    </VStack>
  );
}
