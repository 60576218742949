import { Flex } from "@chakra-ui/react";
import { BinIcon } from "@doorstead/components/icons/BinIcon";

type Props = {
  isShow?: boolean;
  onClick: () => void;
};

export function DeleteIcon({ isShow = true, onClick }: Props) {
  return (
    <Flex justifyContent="center" alignItems="center" width={10} h={10} onClick={onClick}>
      {isShow && <BinIcon color="gray.600" />}
    </Flex>
  );
}
