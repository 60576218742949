import { Box, Divider, Flex, Link, VStack } from "@chakra-ui/react";
import { LeftRightBox, PrimaryButton, moneyString, useIsMobile } from "@doorstead/components";
import { useEffect, useState } from "react";
import { HoldingDepositHintBox } from "src/components/HoldingDepositHintBox";
import { SubtotalSection } from "src/components/SubtotalSection";
import { TotalChargeSection } from "src/components/TotalChargeSection";
import { Step } from "src/contexts/StepContext";
import { useSetStep } from "src/contexts/StepContext/useSetStep";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { useHoldingDeposit } from "src/hooks/useHoldingDeposit";
import { useHoldingDepositAmountDue } from "src/hooks/useHoldingDepositAmountDue";
import { usePageView } from "src/hooks/usePageView";
import { usePaidHoldingDeposit } from "src/hooks/usePaidHoldingDeposit";
import { usePayHoldingDeposit } from "src/hooks/usePayHoldingDeposit";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { useDefaultPaymentMethodId } from "../../../../../hooks/useDefaultPaymentMethodId";
import { useFetchMe } from "../../../../../hooks/useFetchMe";
import { getCents } from "../../../../../utils/getCents";
import { MoneyText } from "../../../../MoneyText";
import { PayNowDisclaimerText } from "../../../../PayNowDisclaimerText";
import { PaymentAmountField } from "../../../../PaymentAmountField";
import { PaymentMethodField } from "../../../../PaymentMethodField";
import { StepCard } from "../../../StepCard";

export function PayHoldingDepositCard() {
  const holdingDeposit = useHoldingDeposit();
  const holdingDepositAmountDue = useHoldingDepositAmountDue();
  const isMobile = useIsMobile();
  const { payHoldingDeposit, loading: isPayingHoldingDeposit } = usePayHoldingDeposit();
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(defaultPaymentMethodId);
  const selectedPaymentMethod = useSelectedPaymentMethod(paymentMethodId);
  const { refetch, isRefetching } = useFetchMe();
  const paidHoldingDeposit = usePaidHoldingDeposit();
  const analytics = usePageView("TP_OB_PAY_HOLDING_DEP_VIEWED");
  const [amount, setAmount] = useState(holdingDepositAmountDue);
  const isAmountInRange = 0.5 <= amount && amount <= holdingDepositAmountDue;
  const { chargeAmount, cardTransactionFee, isFetching } = useChargeBreakdown(amount, selectedPaymentMethod?.type_);
  const { setStep } = useSetStep();

  useEffect(() => {
    setAmount(holdingDepositAmountDue);
  }, [holdingDepositAmountDue]);

  return (
    <StepCard title="Pay holding deposit">
      <VStack spacing={3} w="100%" paddingTop={10}>
        <VStack spacing={3} w="100%">
          <LeftRightBox left="Holding deposit" right={<MoneyText>{moneyString(holdingDeposit)}</MoneyText>} />
        </VStack>
        <Divider mt={3} mb={3} />
        <SubtotalSection subTotalDue={holdingDepositAmountDue} paid={-1 * paidHoldingDeposit} />
        <HoldingDepositHintBox />
        <Flex w="100%" justifyContent="flex-end">
          <Link
            textDecoration="underline"
            color="gray.700"
            fontSize="sm"
            onClick={() => {
              setStep(Step.PAYMENT_METHODS);
            }}
          >
            Change payment method
          </Link>
        </Flex>
        <VStack w="100%" spacing={5} alignItems="flex-start">
          <PaymentMethodField
            id="payment_method"
            label="Payment method"
            value={paymentMethodId || ""}
            onChange={setPaymentMethodId}
          />
          <PaymentAmountField min={0.5} max={holdingDepositAmountDue} value={amount} onChange={setAmount} />
        </VStack>
        <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} />
        <Box w="100%">
          <PrimaryButton
            isFullWidth={isMobile}
            disabled={!isAmountInRange || !paymentMethodId || isPayingHoldingDeposit || isRefetching || isFetching}
            isLoading={isPayingHoldingDeposit || isRefetching || isFetching}
            onClick={async () => {
              if (paymentMethodId !== null) {
                analytics?.track("TP_OB_PAY_HOLDING_DEP_CLICKED", {
                  paymentMethodId,
                });
                await payHoldingDeposit(
                  {
                    amountInCents: getCents(chargeAmount),
                    paymentMethodId,
                  },
                  {
                    async onSuccess() {
                      await refetch();
                    },
                  }
                );
              }
            }}
          >
            Pay now
          </PrimaryButton>
        </Box>
        <PayNowDisclaimerText />
      </VStack>
    </StepCard>
  );
}
