import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import produce from "immer";
import { Fragment } from "react";
import { useIsReadOnly } from "../../../../hooks/rentReady/useIsReadOnly";

export type Value = {
  id: string;
  name: string;
  selected: boolean;
};

type Props<T extends Value> = {
  id?: string;
  values: T[];
  onChange: (values: T[]) => void;
  isMultiple?: boolean;
};

export function CheckboxesField<T extends Value>({ id: checkboxId, values, onChange, isMultiple = true }: Props<T>) {
  const isReadOnly = useIsReadOnly();
  return (
    <VStack w="100%" spacing={3}>
      {values.map((type, index) => {
        const { id, selected, name } = type;
        const uniqueId = `${checkboxId}_${id}`;
        return (
          <Fragment key={uniqueId}>
            <HStack w="100%">
              <Checkbox
                id={uniqueId}
                value={selected}
                disabled={isReadOnly}
                onChange={(checked) => {
                  onChange(
                    produce(values, (draft) => {
                      if (!isMultiple) {
                        draft.forEach((item) => {
                          item.selected = false;
                        });
                      }

                      const itemIndex = draft.findIndex((e) => e.id === id);
                      if (itemIndex !== -1) {
                        draft[index].selected = checked;
                      }
                    })
                  );
                }}
              />
              <Box as="label" color="gray.500" htmlFor={uniqueId}>
                {name}
              </Box>
            </HStack>
            {index !== values.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </VStack>
  );
}
