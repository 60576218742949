import { Item } from "../../../../../@types/RentReady/Item";
import { useIsItemPurpleCircle } from "../../../../../hooks/rentReady/useIsItemPurpleCircle";
import { ItemCompleteCircle } from "./ItemCompleteCircle";
import { ItemInCompleteCircle } from "./ItemInCompleteCircle";

export function ItemCircle(item: Item) {
  const isItemComplete = useIsItemPurpleCircle(item);

  return isItemComplete ? <ItemCompleteCircle item={item} /> : <ItemInCompleteCircle item={item} />;
}
