import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { ExclamationIcon, LeftRightBox, moneyString } from "@doorstead/components";
import { CardTransactionFeeTooltip } from "../CardTransactionFeeTooltip";
import { MoneyText } from "../MoneyText";

export type Props = {
  cardTransactionFee: number;
  chargeAmount: number;
};

export function TotalChargeSection({ cardTransactionFee, chargeAmount }: Props) {
  return (
    <VStack spacing={3} w="100%">
      {cardTransactionFee !== 0 && (
        <LeftRightBox
          left={
            <HStack>
              <Box>Card transaction fee</Box>
              <CardTransactionFeeTooltip>
                <span>
                  <ExclamationIcon />
                </span>
              </CardTransactionFeeTooltip>
            </HStack>
          }
          right={<MoneyText>{moneyString(cardTransactionFee, true)}</MoneyText>}
        />
      )}
      <LeftRightBox
        left={
          <Text fontWeight="bold" fontSize="xl">
            Charge amount
          </Text>
        }
        right={<MoneyText>{moneyString(chargeAmount, true)}</MoneyText>}
      />
    </VStack>
  );
}
