import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  fromPromise,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import auth0, { Auth0Result, WebAuth } from "auth0-js";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Auth0Config, apiServerUrl } from "../config";
import { useAnalytics } from "./analytics";

interface Auth {
  loading: boolean;
  webAuth: WebAuth;
  user: User | null;
  getUser: ({ accessToken }: { accessToken: string }) => Promise<void>;
  loginWithPassword: ({ email, password }: { email: string; password: string }) => Promise<void>;
  loginWithGoogle: () => Promise<auth0.Auth0Result>;
  loginWithFacebook: () => Promise<auth0.Auth0Result>;
  logout: () => Promise<void>;
  signup: ({ email, password }: { email: string; password: string }) => Promise<auth0.Auth0Result>;
  changePassword: ({ email }: { email: string }) => Promise<auth0.Auth0Result>;
  gqlClient: ApolloClient<NormalizedCacheObject>;
}

export interface User {
  doorsteadUserId: string;
  email: string;
  firstName: string;
  lastName: string;
}

const AuthContext = createContext<Auth>({} as any);

interface Props extends PropsWithChildren {
  auth0Config: Auth0Config;
  baseUrl: string;
}

const AuthProvider: FC<Props> = ({ auth0Config, baseUrl, ...props }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const analytics = useAnalytics();

  // To get the latest token in apollo client auth link, we use useRef here.
  // https://stackoverflow.com/a/68886546/3748807
  const accessTokenRef = useRef<string | null>(null);
  accessTokenRef.current = accessToken;

  const { domain, clientID, audience, dbConnection } = auth0Config;
  const responseType = "token";
  const redirectUri = `${baseUrl}/login/callback?${urlSearchParams.toString()}`;

  const webAuth = new auth0.WebAuth({
    domain,
    clientID,
    audience,
    responseType,
    redirectUri,
    scope: "openid profile email",
  });

  const checkSession = useCallback(async () => {
    return new Promise<Auth0Result>((resolve, reject) => {
      webAuth.checkSession({}, (err, authResult) => {
        console.log("checkSession", { err, authResult });
        if (err) {
          reject(err);
        } else {
          resolve(authResult);
        }
      });
    });
  }, []);

  const gqlClient = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      console.log("errorLink", {
        graphQLErrors,
        networkError,
        operation,
        forward,
      });
      if (graphQLErrors && graphQLErrors[0].message?.includes("Token expired")) {
        return fromPromise(
          checkSession()
            .then((authResult) => {
              if (authResult.accessToken) {
                setAccessToken(authResult.accessToken);
                return authResult.accessToken;
              }
            })
            .catch((error) => {
              console.log(error);
            })
        ).flatMap(() => {
          return forward(operation);
        });
      }
    });

    const authLink = setContext((_, { headers }) => {
      console.log({ accessToken: accessTokenRef.current });
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessTokenRef.current}`,
        },
      };
    });

    const httpLink = createHttpLink({
      uri: `${apiServerUrl()}/gql`,
    });

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: "cache-first",
        errorPolicy: "ignore",
        nextFetchPolicy: "cache-only",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
    };

    return new ApolloClient({
      link: errorLink.concat(authLink).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }, []);

  const getUser = useCallback(async ({ accessToken }: { accessToken: string }) => {
    accessTokenRef.current = accessToken;
    const { data, errors } = await gqlClient.query({
      query: gql`
        query getMe {
          me {
            doorsteadUserId
            email
            firstName
            lastName
          }
        }
      `,
    });
    if (errors) {
      throw errors;
    } else if (!data?.me) {
      throw new Error("User not found");
    }

    console.log("getUser", { data });
    setAccessToken(accessToken);
    setUser(data.me);
    analytics?.identify(data.me?.email, {
      email: data.me?.email,
      doorsteadUserId: data.me?.doorsteadUserId,
    });
  }, []);

  const logout = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    // Clear apollo client cache.
    await gqlClient.clearStore();
    // Clear analytics identity.
    analytics?.reset();

    // Redirect to auth0 logout url

    let returnTo = `${baseUrl}/login`;
    if (!searchParams.entries().next().done) {
      returnTo += `?${searchParams.toString()}`;
    }

    webAuth.logout({
      clientID,
      returnTo,
    });
  }, []);

  const signup = useCallback(async ({ email, password }: { email: string; password: string }) => {
    return new Promise<Auth0Result>((resolve, reject) => {
      webAuth.signup(
        {
          email,
          password,
          connection: dbConnection,
          scope: "openid profile email",
        },
        (err, authResult) => {
          console.log("signup", { err, authResult });
          if (err) {
            reject(err);
          } else {
            resolve(authResult);
          }
        }
      );
    });
  }, []);

  const loginWithPassword = useCallback(async ({ email, password }: { email: string; password: string }) => {
    return new Promise<void>((resolve, reject) => {
      webAuth.login(
        {
          email,
          password,
          realm: dbConnection,
        },
        (err) => {
          // Called only when an authentication error, like invalid username or password, occurs. Otherwise, redirect to `redirectUri`.
          console.log("loginWithPassword", { err });
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  }, []);

  const loginWithGoogle = useCallback(async () => {
    return new Promise<Auth0Result>((resolve, reject) => {
      webAuth.popup.authorize(
        {
          connection: "google-oauth2",
          domain,
          redirectUri,
          responseType,
        },
        (err, authResult) => {
          console.log("loginWithGoogle", { err, authResult });
          if (err) {
            reject(err);
          } else {
            resolve(authResult);
          }
        }
      );
    });
  }, []);

  const loginWithFacebook = useCallback(async () => {
    return new Promise<Auth0Result>((resolve, reject) => {
      // TODO: not requesting email
      webAuth.popup.authorize(
        {
          connection: "facebook",
          domain,
          redirectUri,
          responseType,
        },
        (err, authResult) => {
          console.log("loginWithFacebook", { err, authResult });
          if (err) {
            reject(err);
          } else {
            resolve(authResult);
          }
        }
      );
    });
  }, []);

  const changePassword = useCallback(async ({ email }: { email: string }) => {
    return new Promise<Auth0Result>((resolve, reject) => {
      webAuth.changePassword(
        {
          connection: dbConnection,
          email,
        },
        (err, authResult) => {
          console.log("changePassword", { err, authResult });
          if (err) {
            reject(err);
          } else {
            resolve(authResult);
          }
        }
      );
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { accessToken } = await checkSession();
        if (accessToken) {
          await getUser({ accessToken });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        webAuth,
        user,
        getUser,
        loginWithPassword,
        loginWithGoogle,
        loginWithFacebook,
        logout,
        signup,
        changePassword,
        gqlClient,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

function processAuthError(err: auth0.Auth0Error): {
  email: string | null;
  password: string | null;
  general: string | null;
} {
  console.log("processAuthError", { err });
  if (Array.isArray(err) && err[0]?.message) {
    // Apollo graphql error
    const message = err[0].message;
    if (message.includes("Unrecognized email")) {
      return {
        email:
          "The email address is not recognized. Please use the email address where you received the portal invite.",
        password: null,
        general: null,
      };
    } else if (message.includes("Email not found")) {
      return {
        email:
          "Your Facebook account doesn't have an email address. Please create a new account or log in with Google.",
        password: null,
        general: null,
      };
    } else {
      return {
        email: null,
        password: null,
        general: message,
      };
    }
  } else if (err.code) {
    // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
    switch (err.code) {
      case "error in email - email format validation failed: ": {
        return {
          email:
            "The email address is not recognized. Please use the email address where you received the portal invite.",
          password: null,
          general: null,
        };
      }
      case "invalid_password":
        return {
          email: null,
          password:
            "Your password must be at least 8 characters and include a lower-case letter, an upper-case letter, and a number",
          general: null,
        };
      case "invalid_signup":
        return {
          email: null,
          password: "Please try login instead or contact Doorstead support.",
          general: null,
        };
      case "String (password) is too short (0 chars), minimum 1":
        return {
          email: null,
          password: "Please enter the password",
          general: null,
        };
      case "invalid_request":
      case "access_denied":
        return {
          email: null,
          password: null,
          general: "Wrong email or password.",
        };
      default:
        return {
          email: null,
          password: null,
          general: err.code,
        };
    }
  } else if (err.original === "User closed the popup window") {
    return {
      email: null,
      password: null,
      general: null,
    };
  } else {
    return {
      email: null,
      password: null,
      general: `Unexpected error. Please contact Doorstead support. ${JSON.stringify(err)}`,
    };
  }
}

export { AuthProvider, useAuth, processAuthError };
