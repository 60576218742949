import { HStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useIsReadOnly } from "../../hooks/rentReady/useIsReadOnly";

type Props = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
};

export function YesNoButtons({ value, onChange }: Props) {
  const isReadOnly = useIsReadOnly();
  const NoButton = value !== false ? OutlineButton : PrimaryButton;
  const YesButton = value !== true ? OutlineButton : PrimaryButton;

  return (
    <HStack spacing={2}>
      <NoButton
        size="sm"
        isFullWidth
        disabled={isReadOnly}
        onClick={() => {
          onChange(value === false ? null : false);
        }}
      >
        No
      </NoButton>
      <YesButton
        size="sm"
        isFullWidth
        disabled={isReadOnly}
        onClick={() => {
          onChange(value === true ? null : true);
        }}
      >
        Yes
      </YesButton>
    </HStack>
  );
}
