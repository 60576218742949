import { Stack, Text, VStack, useToken } from "@chakra-ui/react";
import { moneyString } from "@doorstead/components";
import { useMemo, useState } from "react";
import { getUuid } from "src/utils/getUuid";
import { LeaseStartDateSuboption, Suboption } from "../LeaseStartDateSuboption";
import { OptionDate } from "./OptionDate";
import { OptionDescription } from "./OptionDescription";
import { Props } from "./typings";
import { getDatesBetween, getOptionType } from "./utils";

export const LeaseStartDateOption = ({
  startDate,
  endDate,
  price,
  listingPrice,
  selected,
  onClick,
  onSelect,
}: Props) => {
  const color = useToken("colors", [selected ? "purple.400" : "gray.50"])[0];
  const suboptions: Suboption[] = useMemo(
    () => getDatesBetween(startDate, endDate).map((d) => ({ id: getUuid(), date: d })),
    []
  );
  const [suboption, setSuboption] = useState<Suboption | null>(null);
  const type = getOptionType(price, listingPrice);

  return (
    <VStack
      gap={8}
      width="100%"
      p={8}
      backgroundColor="white"
      borderRadius={11}
      boxShadow={`inset 0px 0px 0px ${selected ? 3 : 0}px ${color}`}
      onClick={() => {
        if (!selected) {
          onClick();
          setSuboption(null);
        }
      }}
      _hover={{ cursor: "pointer" }}
    >
      <Stack spacing={0} gap={4} width="100%" direction="row" flexWrap="wrap" justifyContent="space-between">
        <VStack align="start" gap={2}>
          <OptionDate startDate={startDate} endDate={endDate} />
          <OptionDescription type={type} price={price} listingPrice={listingPrice} />
        </VStack>
        <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="end">
          <Text whiteSpace="nowrap" color="gray.700" fontWeight="bold" fontSize="2xl">
            {moneyString(price, false)}
          </Text>
          <Text whiteSpace="nowrap" color="gray.500" fontWeight="medium" fontSize="md">
            /month
          </Text>
        </Stack>
      </Stack>
      {selected && (
        <Stack
          width="100%"
          display="grid"
          gridTemplateColumns="repeat(auto-fill,minmax(160px, 1fr))"
          spacing={0}
          gap={4}
        >
          {suboptions.map((subopt: Suboption) => (
            <LeaseStartDateSuboption
              key={subopt.id}
              id={subopt.id}
              date={subopt.date}
              selected={suboption?.id === subopt.id}
              onClick={() => {
                setSuboption(subopt);
                onSelect(subopt.date, price);
              }}
            />
          ))}
        </Stack>
      )}
    </VStack>
  );
};
