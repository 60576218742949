import { CosmeticOption } from "../../../@types/RentReady/Item";
import { OtherOption } from "../../../components/rent-ready/otherOption";
import { ItemNode } from "../useFetchInspectionReport/ItemNode";

export function mapCosmeticOptions({ itemCosmeticOptions, itemSelectedCosmeticOptions }: ItemNode): CosmeticOption[] {
  const options: string[] = itemSelectedCosmeticOptions || [];
  if (itemCosmeticOptions === null || itemCosmeticOptions.length === 0) return [];

  const arr: CosmeticOption[] = [];

  itemCosmeticOptions.forEach((e) => {
    arr.push({
      id: e,
      name: e,
      selected: options.includes(e),
    });
  });

  if (itemSelectedCosmeticOptions === null) {
    arr.push({ ...OtherOption, selected: false });
  } else {
    arr.push({ ...OtherOption, selected: itemSelectedCosmeticOptions.some((e) => e === OtherOption.id) });
  }

  return arr;
}
