import { Divider, Flex } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useProratedRentDue } from "src/hooks/useProratedRentDue";
import { useNextStep } from "../../../../../contexts/StepContext/useNextStep";
import { PaidOffBox } from "../../../../PaidOffBox";
import { BaseCard } from "../BaseCard";

export function PaidCard() {
  const isMobile = useIsMobile();
  const { nextStep, isLoading } = useNextStep();
  const analytics = useGroupAnalytics();
  const balanceDue = useProratedRentDue();

  return (
    <BaseCard title="Make payments">
      <PaidOffBox balanceDue={balanceDue} />
      <Divider />
      <Flex w="100%" justifyContent="space-between">
        <PrimaryButton
          disabled={isLoading}
          isLoading={isLoading}
          isFullWidth={isMobile}
          onClick={() => {
            analytics?.track("TP_OB_PAY_RENT_CONTINUED");
            nextStep();
          }}
        >
          Continue
        </PrimaryButton>
      </Flex>
    </BaseCard>
  );
}
