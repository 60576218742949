import { useFetchFlexWalkthroughReport } from "./useFetchFlexWalkthroughReport";
import { FlexWalkthroughItemNode } from "./useFetchFlexWalkthroughReport/FlexWalkthroughItemNode";
import { useUpdatePartialWalkthroughItemToCache } from "./useUpdatePartialWalkthroughItemToCache";
import { ArgsItem, useUpdatePartialWalkthroughItemToServer } from "./useUpdatePartialWalkthroughItemToServer";

export function useSyncSingleWalkthroughItemFieldToCacheAndServer() {
  const updatePartialItemToServer = useUpdatePartialWalkthroughItemToServer();
  const updatePartialItemToCache = useUpdatePartialWalkthroughItemToCache();
  const { refetch } = useFetchFlexWalkthroughReport();

  return async (
    item: FlexWalkthroughItemNode,
    cacheField: keyof FlexWalkthroughItemNode,
    serverField: keyof ArgsItem,
    cacheValue: any,
    serverValue: any
  ) => {
    await updatePartialItemToCache({
      id: item.itemDataId,
      field: cacheField,
      value: cacheValue,
    });
    await updatePartialItemToServer({
      items: [
        {
          id: item.itemDataId,
          [serverField]: serverValue,
        },
      ],
    });
    await refetch();
  };
}
