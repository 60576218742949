import { Box, VStack } from "@chakra-ui/react";
import { Spinner } from "@doorstead/components/Spinner";

type Props = {
  progress: number;
};

export function ProgressBox({ progress }: Props) {
  return (
    <VStack>
      <Spinner />
      <Box>{progress}%</Box>
    </VStack>
  );
}
