import { useToast } from "@chakra-ui/react";

export function useSuccess() {
  const toast = useToast();
  return ({ title, description = "" }: { title: string; description?: string }) => {
    toast({
      title,
      description,
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };
}
