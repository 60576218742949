import { Divider, VStack } from "@chakra-ui/react";
import { Fragment } from "react";
import { useShouldUseSimplifiedTemplate } from "src/hooks/rentReady/useShouldUseSimplifiedTemplate";
import { Item } from "../../../../@types/RentReady/Item";
import { useArea } from "../../../../hooks/rentReady/useArea";
import { useIsV2UiFeatures } from "../../../../hooks/rentReady/useIsV2UiFeatures";
import { useRRItemsOpenCache } from "../../../../hooks/rentReady/useRRItemsOpenCache";
import { arrayToMap } from "../../../../utils/arrayToMap";
import { isThroughoutPropertyItemDefaultOpen } from "../../../../utils/rentReady/isThroughoutPropertyItemDefaultOpen";
import { isV2ItemDefaultOpen } from "../../../../utils/rentReady/isV2ItemDefaultOpen";
import { ItemRow } from "./ItemRow";
import { ItemRowThroughoutProperty } from "./ItemRowThroughoutProperty";
import { ItemRowV2 } from "./ItemRowV2";

type Props = {
  items: Item[];
};

export function ItemRows({ items }: Props) {
  const { isThroughoutProperty } = useArea()!;
  const isV2 = useIsV2UiFeatures();
  const res = useRRItemsOpenCache(items.map(({ id }) => id));
  const map = arrayToMap(res || [], "item_data_id");
  const shouldUseSimplifiedTemplate = useShouldUseSimplifiedTemplate();

  if (items.length === 0 || res === null) return null;

  return (
    <VStack spacing={1}>
      {items.map((item, index) => {
        const showDivider = index !== items.length - 1 && item.components.length > 0;
        const isOpenedBefore = map.get(item.id)?.is_open;

        if (isThroughoutProperty) {
          return (
            <ItemRowThroughoutProperty
              key={item.id}
              item={item}
              defaultOpen={
                isOpenedBefore ||
                isThroughoutPropertyItemDefaultOpen({
                  name: item.name,
                  photos: item.photos,
                  status: item.status,
                  note: item.note,
                  shouldUseSimplifiedTemplate,
                })
              }
            />
          );
        } else if (isV2) {
          return (
            <Fragment key={item.uiId}>
              <ItemRowV2
                item={item}
                defaultOpen={
                  isOpenedBefore ||
                  isV2ItemDefaultOpen({
                    ...item,
                    shouldUseSimplifiedTemplate,
                  })
                }
              />
              {showDivider && <Divider w="90%" />}
            </Fragment>
          );
        } else {
          return (
            <Fragment key={item.uiId}>
              <ItemRow item={item} defaultOpen={isOpenedBefore || false} />
              {showDivider && isOpenedBefore && <Divider w="90%" />}
            </Fragment>
          );
        }
      })}
    </VStack>
  );
}
