import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { isPhotosDiff } from "./isPhotosDiff";

export function isKeyDetailDiff(keyDetailCache: KeyDetail, keyDetailServer: KeyDetail): boolean {
  return (
    keyDetailCache.frontDoorKeys.number !== keyDetailServer.frontDoorKeys.number ||
    keyDetailCache.frontDoorKeys.isWorking !== keyDetailServer.frontDoorKeys.isWorking ||
    keyDetailCache.frontDoorKeys.note !== keyDetailServer.frontDoorKeys.note ||
    isPhotosDiff(keyDetailCache.frontDoorKeys.photos, keyDetailServer.frontDoorKeys.photos) ||
    keyDetailCache.mailboxKeys.number !== keyDetailServer.mailboxKeys.number ||
    keyDetailCache.mailboxKeys.isWorking !== keyDetailServer.mailboxKeys.isWorking ||
    keyDetailCache.mailboxKeys.note !== keyDetailServer.mailboxKeys.note ||
    isPhotosDiff(keyDetailCache.mailboxKeys.photos, keyDetailServer.mailboxKeys.photos) ||
    keyDetailCache.communityKeys.number !== keyDetailServer.communityKeys.number ||
    keyDetailCache.communityKeys.isWorking !== keyDetailServer.communityKeys.isWorking ||
    keyDetailCache.communityKeys.note !== keyDetailServer.communityKeys.note ||
    isPhotosDiff(keyDetailCache.communityKeys.photos, keyDetailServer.communityKeys.photos) ||
    keyDetailCache.garageRemotes.number !== keyDetailServer.garageRemotes.number ||
    keyDetailCache.garageRemotes.isWorking !== keyDetailServer.garageRemotes.isWorking ||
    keyDetailCache.garageRemotes.note !== keyDetailServer.garageRemotes.note ||
    isPhotosDiff(keyDetailCache.garageRemotes.photos, keyDetailServer.garageRemotes.photos) ||
    keyDetailCache.keyPads.number !== keyDetailServer.keyPads.number ||
    keyDetailCache.keyPads.isWorking !== keyDetailServer.keyPads.isWorking ||
    keyDetailCache.keyPads.note !== keyDetailServer.keyPads.note ||
    isPhotosDiff(keyDetailCache.keyPads.photos, keyDetailServer.keyPads.photos)
  );
}
