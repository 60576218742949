import { Photo } from "../../@types/RentReady/Photo";

export function isPhotosDiff(photos1: Photo[], photos2: Photo[]): boolean {
  // Check if the lengths of the arrays are different
  if (photos1.length !== photos2.length) {
    return true;
  }

  // Sort both arrays by ID
  const sortedPhotos1 = sortPhotosById(photos1);
  const sortedPhotos2 = sortPhotosById(photos2);

  // Iterate over each photo in the sorted arrays
  for (let i = 0; i < sortedPhotos1.length; i++) {
    const photo1 = sortedPhotos1[i];
    const photo2 = sortedPhotos2[i];

    // Compare the properties of the photos
    if (!arePhotosEqual(photo1, photo2)) {
      return true;
    }
  }

  // If no differences were found, return false
  return false;
}

export function sortPhotosById(photos: Photo[]): Photo[] {
  return [...photos].sort((a, b) => a.id.localeCompare(b.id));
}

function arePhotosEqual(photo1: Photo, photo2: Photo): boolean {
  // Compare the src properties of the photos
  if (
    photo1.src.bucket !== photo2.src.bucket ||
    photo1.src.key !== photo2.src.key ||
    photo1.src.fileName !== photo2.src.fileName ||
    photo1.src.url !== photo2.src.url
  ) {
    return false;
  }

  // Compare the thumbnail properties of the photos
  if (
    photo1.thumbnail.bucket !== photo2.thumbnail.bucket ||
    photo1.thumbnail.key !== photo2.thumbnail.key ||
    photo1.thumbnail.fileName !== photo2.thumbnail.fileName ||
    photo1.thumbnail.url !== photo2.thumbnail.url
  ) {
    return false;
  }

  // Compare the isUploaded properties of the photos
  if (photo1.isUploaded !== photo2.isUploaded) {
    return false;
  }

  // Compare the toUploadFile properties of the photos
  if (
    (photo1.toUploadFile === null && photo2.toUploadFile !== null) ||
    (photo1.toUploadFile !== null && photo2.toUploadFile === null)
  ) {
    return false;
  }

  if (photo1.toUploadFile !== null && photo2.toUploadFile !== null) {
    if (photo1.toUploadFile.dataUrl !== photo2.toUploadFile.dataUrl) {
      return false;
    }
  }

  return true;
}
