import { Box, StyleProps } from "@chakra-ui/react";
import React from "react";

export type Props = StyleProps & {
  children: React.ReactNode;
};

export function EverGreenCard({ children, ...styleProps }: Props) {
  return (
    <Box {...styleProps} bgColor="white" w="100%" borderRadius={12} padding={10}>
      {children}
    </Box>
  );
}
