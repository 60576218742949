import { ThroughoutPropertyDetector } from "../../@types/RentReady/ThroughoutPropertyDetector";
import { useThroughoutPropertyDetectorCache } from "./useThroughoutPropertyDetectorCache";

export function isComplete(value: ThroughoutPropertyDetector): boolean {
  return (
    value.detectorSmokePhotos.length > 0 &&
    value.detectorSmokeIsAtLeastOneOutsideBedrooms !== null &&
    value.detectorSmokeIsAtLeastOneEachFloor !== null &&
    value.detectorCmPhotos.length > 0 &&
    value.detectorCmIsAtLeastOneCoOutsideBedrooms !== null &&
    value.detectorCmIsAtLeastOneCoEachFloor !== null
  );
}

export function useIsThroughoutPropertyDetectorComplete(): boolean {
  const value = useThroughoutPropertyDetectorCache();
  if (value === null) return false;

  return isComplete(value);
}
