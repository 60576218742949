import { KeyDetail } from "../../@types/RentReady/KeyDetail";
import { Scale } from "../../@types/RentReady/Scale";
import { useIsKeyDetailsAdditionalKeysFeature } from "./useIsKeyDetailsAdditionalKeysFeature";
import { useKeyDetailCache } from "./useKeyDetailCache";

export function isComplete(keyDetail: KeyDetail, column: keyof KeyDetail): boolean {
  switch (keyDetail[column].number) {
    case Scale.NotProvided:
      return false;
    case Scale.NA:
      return true;
    case Scale.Zero:
    case Scale.One:
    case Scale.Two:
    case Scale.Three:
    case Scale.Four:
      return keyDetail[column].photos.length > 0;
  }
}

function useIsKeyDetailsAdditionalKeysComplete() {
  const keyDetail = useKeyDetailCache();
  const isKeyDetailsAdditionalKeysFeature = useIsKeyDetailsAdditionalKeysFeature();
  if (!keyDetail) return false;

  if (!isKeyDetailsAdditionalKeysFeature) return true;

  return isComplete(keyDetail, "additionalKeys");
}

export function useIsKeyDetailComplete(): boolean {
  const keyDetail = useKeyDetailCache();
  const isKeyDetailsAdditionalKeysComplete = useIsKeyDetailsAdditionalKeysComplete();
  if (!keyDetail) {
    return false;
  } else {
    return (
      isKeyDetailsAdditionalKeysComplete &&
      isComplete(keyDetail, "frontDoorKeys") &&
      isComplete(keyDetail, "mailboxKeys") &&
      isComplete(keyDetail, "communityKeys") &&
      isComplete(keyDetail, "garageRemotes") &&
      isComplete(keyDetail, "keyPads")
    );
  }
}
