import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { useApplicantId } from "./useApplicantId";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useAddOccupant() {
  const applicantId = useApplicantId();
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async () => {
      return await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantOccupant: ApplicantOccupantCreateData) {
            createApplicantOccupant(applicantOccupant: $applicantOccupant) {
              applicantOccupantId
            }
          }
        `,
        variables: {
          applicantOccupant: {
            applicantId,
            fullName: "",
          },
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
