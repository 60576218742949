import { PaymentMethodType } from "src/hooks/useFetchMe";

export function getPaymentMethodTitle({ type }: { type: PaymentMethodType }): string {
  if (type === PaymentMethodType.ACH) {
    return "ACH";
  }
  if (type === PaymentMethodType.Card) {
    return "Card";
  }
  return "";
}
